import {EXPERIENCETYPE_DETAILS_REQUEST,
EXPERIENCETYPE_DETAILS_SUCCESS,
EXPERIENCETYPE_DETAILS_FAIL
} from "../constant/experienceType";

export const ExperienceDetailsReducer = (state = { experienceTypeDetails:{}},action) => {
    switch(action.type){
        case EXPERIENCETYPE_DETAILS_REQUEST:
            return {...state,loading:true,experienceTypeDetails:{}}
        case EXPERIENCETYPE_DETAILS_SUCCESS:
            return {...state,loading:false,experienceTypeDetails:action.payload}
        case EXPERIENCETYPE_DETAILS_FAIL:
            return {...state,loading:false,experienceTypeDetails:{},error:action.payload}        
        default:
            return state;
    }
}