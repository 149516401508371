import React, { useState } from "react";
import "./login.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField, FormControl , InputLabel, OutlinedInput ,InputAdornment , IconButton} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAction } from "actions/forgotPassword/forgotPasswordAction";

const ChangePassword = ({ 
    handleClickShowPassword ,
    setScreen,
   
    showPassword,
    loading,
    handleClickShowReNewPassword,
    showReNewPassword

}) => {

  const dispatch = useDispatch();
  const email = useSelector(state => state.forgotpassword.email)

  const [passwordState,setPasswordState] = useState('');
  const [confirmPasswordState,setConfirmPasswordState] = useState('');

  const onchangeHanlderPassword = (event) => {
    setPasswordState(event.target.value)
  }
  const onChangeHandlerConfrimPassword = (event) => {
    setConfirmPasswordState(event.target.value);
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const adminLoginHandler = () => {
    if(passwordState === confirmPasswordState){
      dispatch(changePasswordAction(passwordState,email));
      setScreen('login')
    }
  }

  return (
        <div className="login-content">
          <h3 className="login-content-title">Change Password</h3>
  
             <FormControl style={{width:"100%", marginTop:10}}  variant="outlined">
                <InputLabel style={{lineHeight:"14px"}} htmlFor="outlined-adornment-password">New Password</InputLabel>
                <OutlinedInput
                      sx={{
                        '& fieldset': {
                          borderRadius: '12px',
                        },
                        '& input': {
                          padding: '11.5px 14px',
                        },

                      }}
                variant="outlined"
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={passwordState}
                    name="passwordState"
                    onChange={onchangeHanlderPassword}
                    style={{borderRadius:12}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="New Password"
                />
            </FormControl>


            <FormControl style={{width:"100%", marginTop:20}}  variant="outlined">
                    <InputLabel style={{lineHeight:"14px"}} htmlFor="outlined-adornment-password">Repeat New Password</InputLabel>
                    <OutlinedInput
                          sx={{
                            '& fieldset': {
                              borderRadius: '12px',
                            },
                            '& input': {
                              padding: '11.5px 14px',
                            },

                          }}
                    variant="outlined"
                        id="outlined-adornment-password"
                        type={showReNewPassword ? 'text' : 'password'}
                        value={confirmPasswordState}
                        name="confirmPassword"
                        onChange={onChangeHandlerConfrimPassword}
                        style={{borderRadius:12}}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowReNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showReNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
            label="PasRepeat New Passwordsword"
          />
            </FormControl>

          <div className="loginbutton">
            <button onClick={adminLoginHandler} disabled={loading}>{loading? 'Loading...' : 'Change Password'}</button>
          </div>
        </div>
  );
};

export default ChangePassword;
