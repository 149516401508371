import React from "react";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import "./OrganizationsTable.style.css";
import { withRouter, Link } from "react-router-dom";

const OrganizationsProjectItem = ({ item }) => {
  return (
    <TableRow>
      <TableCell style={{ borderBottom: 0 }}>
        <Checkbox
          color="default"
          size="small"
          checked={true}
          onChange={() => {}}
          value={""}
        />
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <img
          src={item.image}
          alt="asternuts"
          width="37"
          height="37"
          style={{ borderRadius: 50 }}
        />
      </TableCell>
      <TableCell style={{ borderBottom: 0, maxWidth: 205 }}>
        <Link
          style={{ color: "#495057", fontSize: 15, textDecoration: "none" }}
          to={`/admin/organization/project/${item._id}`}
        >
          {item.name}
        </Link>
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {item.project_type}{" "}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {item.condition}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {item.status}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>---</TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>---</TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>---</TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {item.reward_system ? "Yes" : "No"}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {item.survey_system ? "Yes" : "No"}
      </TableCell>
    </TableRow>
  );
};

export default withRouter(OrganizationsProjectItem);
