import React from "react";
import {
  HomeBox,
  HomeBoxImage,
  HomeBoxBottom,
  HomeBoxBottomNameContent,
  HomeBoxBottomTitle,
  HomeBoxBottomProjectText,
  HomeBoxBottomStudentNumber,
  HomeBoxBottomIconContainer,
  HomeBoxBottomIconShop,
  HomeBoxBottomIconSurvay,
  HomeBoxBottomStudentContainer,
} from "../../home.style";
import shopIcon from "assets/image/shopIcon.png";
import survayIcon from "assets/image/survayIcon.png";

const HomeItem = ({ image, name, type, studentLength, onProjectClick }) => {
  return (
    <>
      <HomeBox onClick={onProjectClick}>
        <HomeBoxImage
          style={{ width: "50%" }}
          src={image}
          alt="gradegy"
        ></HomeBoxImage>
        <HomeBoxBottom>
          <HomeBoxBottomNameContent>
            <HomeBoxBottomTitle> {name} </HomeBoxBottomTitle>
            <HomeBoxBottomProjectText>{type}</HomeBoxBottomProjectText>
          </HomeBoxBottomNameContent>
          <HomeBoxBottomStudentContainer>
            <HomeBoxBottomStudentNumber>
              {studentLength} Students
            </HomeBoxBottomStudentNumber>
            <HomeBoxBottomIconContainer>
              <HomeBoxBottomIconShop
                src={shopIcon}
                alt="gradegy"
              ></HomeBoxBottomIconShop>
              <HomeBoxBottomIconSurvay
                src={survayIcon}
                alt="gradegy"
              ></HomeBoxBottomIconSurvay>
            </HomeBoxBottomIconContainer>
          </HomeBoxBottomStudentContainer>
        </HomeBoxBottom>
      </HomeBox>
    </>
  );
};

export default HomeItem;
