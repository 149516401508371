import styled from  'styled-components';

export const SaveButton = styled.button`

    color:${({theme}) => theme.colors.white};
    background-color:${({theme}) => theme.colors.buttonBlue};
    border:none;
    border-radius:50px;
    padding: 10px 46px;
    font-family:${({theme}) => theme.fonts.Poppins};
    font-size:17px;
    font-weight:600

`;