import {
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_FAIL,
} from "../constant/team";

import { gradify } from "../axios.config";
export const getTeamList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_LIST_REQUEST,
    });

    const { data } = await gradify.get(`teams`, {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });

    dispatch({
      type: TEAM_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {}
};
