import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAppAllOrganizations } from "actions";
import HomeContainer from "containers/Home";

const Home = ({ history }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppAllOrganizations());
  }, [dispatch]);
  return <HomeContainer history={history} />;
};

export default Home;
