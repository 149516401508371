import {
  SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
  SET_RAFFLE_TICKET_DETAIL,
  RAFFLE_TICKET_DETAIL_TOAST_MESSAGE,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const updateRaffleTicketAction =
  (input, currentNumWinner) => async (dispatch) => {
    const numberOfAdd = Number(input.number_winner) - currentNumWinner;
    let query;
    const raffleTicketDetail =
      store.getState().raffleTicketDetail.raffleTicketDetail;
    try {
      const token = store.getState().auth.token;
      const raffleTicketId =
        store.getState().raffleTicketDetail.raffleTicketDetail?._id;
      if (raffleTicketId) {
        dispatch({
          type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
          payload: "UPDATE_SAVING",
        });
        let inventoryRaffleData;
        if (numberOfAdd < 0) {
          await gradify.post(
            "inventories/deletegroup",
            {
              numberOfRemove: Math.abs(numberOfAdd),
              status: { raffelTicket: raffleTicketId },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (numberOfAdd > 0) {
          query = "inventories/addgroup";
          let inventories = [];
          for (let i = 0; i < numberOfAdd; i++) {
            inventories.push({ raffelTicket: raffleTicketId });
          }

          inventoryRaffleData = {
            inventories,
          };
          await gradify.post(query, inventoryRaffleData, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        await gradify.put(`raffletickets/${raffleTicketId}`, input, {
          headers: { Authorization: `Bearer ${token}` },
        });
        dispatch({
          type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
          payload: "RAFFLE_MESSAGE_POPUP",
        });
        dispatch({
          type: RAFFLE_TICKET_DETAIL_TOAST_MESSAGE,
          payload: {
            message: "RAFFLE TICKET UPDATE SUCCESSFULY",
            hasError: false,
          },
        });

        const { data: raffleTicketData } = await gradify.get(
          `raffletickets/${raffleTicketId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        dispatch({
          type: SET_RAFFLE_TICKET_DETAIL,
          payload: raffleTicketData.data,
        });
        setTimeout(() => {
          dispatch({
            type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
            payload: "DEFAULT",
          });
          dispatch({
            type: RAFFLE_TICKET_DETAIL_TOAST_MESSAGE,
            payload: { message: "", hasError: false },
          });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
        payload: "DEFAULT",
      });
      dispatch({
        type: SET_RAFFLE_TICKET_DETAIL,
        payload: raffleTicketDetail,
      });
      // what's in error?
    }
  };
