import React, { useState, useCallback } from "react";
import AddAdminComponent from "components/admin/AddAdmin";
import { useSelector, useDispatch } from "react-redux";
import { gradify } from "axios.config";
import { SUPER_ADMIN_LEVEL, ULTRA_ADMIN_LEVEL } from "constants/index";
import {
  adminAddPhotoSelector,
  allAppOrganizationsSelector,
} from "store/selectors";
import { uploadPhoto, changePhotoUrl, getOrganizationProject } from "actions";
const AddAdminContainer = ({
  addAdminHandler,
  onCancelClick,
  isAddAdminLoading,
}) => {
  const dispatch = useDispatch();
  //selectors
  const organizations = useSelector(allAppOrganizationsSelector);
  const token = useSelector((state) => state.auth.token);
  const loggedInUser = useSelector((state) => state.loggedInUser.user);

  const adminPhoto = useSelector(adminAddPhotoSelector);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhonNumber] = useState();
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [adminLevel, setAdminLevel] = useState("");
  const [schoolLink, setSchoolLink] = useState(true);
  const [category, setCategory] = useState("...");
  const [highSchool, setHighSchool] = useState();
  const [university, setUniversity] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState(
    organizations[0]
  );
  const [selectedProject, setSelectedProject] = useState();
  const handleChangeImgPic = useCallback(
    (e) => {
      uploadPhoto(dispatch, e.target.files[0]);
      // add photo api call here and save url then return it
    },
    [dispatch]
  );
  const handleAdminLevelChange = useCallback((e) => {
    setAdminLevel(e.target.value);
  }, []);
  const handleOrganizationChange = useCallback(
    (e) => {
      const selectedOrg = organizations.find(
        (org) => org._id === e.target.value
      );
      setSelectedOrganization(selectedOrg);
      setSelectedProject();
      dispatch(getOrganizationProject(selectedOrg?._id, loggedInUser));
    },
    [dispatch, loggedInUser, organizations]
  );
  const handleProjectChange = useCallback(
    (e) => {
      const selectedPro = selectedOrganization?.projects.find(
        (Pro) => Pro._id === e.target.value
      );
      setSelectedProject(selectedPro);
    },
    [selectedOrganization?.projects]
  );
  const handlePhoneValueChange = useCallback((e) => {
    setPhonNumber(e.target.value);
  }, []);
  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);
  const handleJobTitleChange = useCallback((e) => {
    setJobTitle(e.target.value);
  }, []);

  const handleLoadUniversityOptions = async (inputText, callback) => {
    const res = await gradify.get(`universities/search?term=${inputText}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    callback(
      res.data.map((item) => {
        return {
          label: item.instnm,
          value: item._id,
        };
      })
    );
  };
  const handleLoadHighSchoolOptions = async (inputText, callback) => {
    const res = await gradify.get(
      `categories/search?term=${inputText}&project=${selectedProject?._id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    callback(
      res.data.map((item) => {
        return {
          label: item.name,
          value: item.highSchool,
        };
      })
    );
  };
  const HandleSchoolChange = useCallback(
    async (selectedSchool) => {
      const { data: categoryResult } = await gradify.get(
        `categories?project=${selectedProject?._id}&name=${selectedSchool.label}
      `,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCategory(categoryResult?.data[0]);
      setHighSchool(selectedSchool?.value);
      if (categoryResult.count > 0) {
        setCategory(categoryResult?.data[0]);
        setHighSchool(selectedSchool?.value);
      } else {
        setHighSchool(selectedSchool?.value);
      }
    },
    [selectedProject?._id, token]
  );
  const handleUniversityChange = useCallback(
    async (selectedUniversity) => {
      const { data: projectResult } = await gradify.get(
        `projects?name=${selectedUniversity.label}
      `,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSelectedProject(projectResult?.data[0]);
      setUniversity(selectedUniversity?.value);
      if (projectResult.count > 0) {
        setSelectedProject(projectResult?.data[0]);
        setUniversity(selectedUniversity?.value);
      } else {
        setUniversity(selectedUniversity?.value);
      }
    },
    [token]
  );
  const handleSaveAddeAdminClick = useCallback(() => {
    const adminInfo = {
      firstName,
      lastName,
      role: adminLevel,
      profilePicture: adminPhoto?.url,
      phone: phoneNumber,
      email,
      jobTitle,
      highSchool,
      university: { universityID: university },
      ...(schoolLink &&
        selectedOrganization?.organization_type === "Secondary" &&
        highSchool && {
          schoolDistrictPermissionEdit: [
            {
              organization: selectedOrganization._id,
              project: selectedProject?._id,
              category:
                schoolLink &&
                selectedOrganization?.organization_type === "Secondary"
                  ? category?._id
                  : null,
              experienceCards: false,
              store: false,
              surveys: false,
              users: false,
            },
          ],
        }),
      ...(selectedOrganization?.organization_type === "Post Secondary" &&
        selectedProject?._id &&
        schoolLink && {
          universityPermissionEdit: [
            {
              organization: selectedOrganization._id,
              project:
                schoolLink &&
                selectedOrganization?.organization_type === "Post Secondary"
                  ? selectedProject?._id
                  : null,
              category: null,
              experienceCards: false,
              store: false,
              surveys: false,
              users: false,
            },
          ],
        }),
      system: [
        {
          organization: selectedOrganization._id,
          project:
            !schoolLink &&
            selectedOrganization?.organization_type === "Post Secondary"
              ? null
              : selectedProject?._id,
          category:
            schoolLink &&
            selectedOrganization?.organization_type === "Secondary"
              ? category?._id
              : null,
          subCategory: null,
        },
      ],
    };

    addAdminHandler(adminInfo);
    setPhonNumber("");
    setEmail("");
    setJobTitle("");
    setFirstName("");
    setLastName("");
    setSelectedOrganization("");
    changePhotoUrl(dispatch, null);
  }, [
    addAdminHandler,
    adminLevel,
    adminPhoto?.url,
    category?._id,
    dispatch,
    email,
    firstName,
    highSchool,
    jobTitle,
    lastName,
    phoneNumber,
    schoolLink,
    selectedOrganization._id,
    selectedOrganization?.organization_type,
    selectedProject,
    university,
  ]);
  const handleCancelClick = useCallback(() => {
    setPhonNumber("");
    setEmail("");
    setJobTitle("");
    setFirstName("");
    setLastName("");
    setSelectedOrganization("");
    changePhotoUrl(dispatch, null);
    onCancelClick();
  }, [onCancelClick, dispatch]);
  return (
    <AddAdminComponent
      firstName={firstName}
      onFirstNameChange={(e) => {
        setFirstName(e.target.value);
      }}
      lastName={lastName}
      onLastNameChange={(e) => {
        setLastName(e.target.value);
      }}
      AdminLevelOptions={
        loggedInUser.role === "ultraAdmin"
          ? ULTRA_ADMIN_LEVEL
          : SUPER_ADMIN_LEVEL
      }
      adminPhoto={adminPhoto}
      onChangeImgPic={handleChangeImgPic}
      onAdminLevelChange={handleAdminLevelChange}
      organizations={organizations}
      selectedOrganization={selectedOrganization}
      selectedProject={selectedProject}
      onOrganizationChange={handleOrganizationChange}
      onProjectChange={handleProjectChange}
      phoneNumber={phoneNumber}
      onPhoneValueChange={handlePhoneValueChange}
      email={email}
      onEmailChange={handleEmailChange}
      jobTitle={jobTitle}
      onJobTitleChange={handleJobTitleChange}
      category={category?.name || "..."}
      loadHighSchoolOptions={handleLoadHighSchoolOptions}
      loadUniversityOptions={handleLoadUniversityOptions}
      onSchoolChange={HandleSchoolChange}
      onUniversityChange={handleUniversityChange}
      schoolLink={schoolLink}
      setSchoolLink={(value) => setSchoolLink(value)}
      onSaveAddeAdminClick={handleSaveAddeAdminClick}
      onCancelAdminClick={handleCancelClick}
      isAddAdminLoading={isAddAdminLoading}
    />
  );
};
export default AddAdminContainer;
