import React, { useEffect, useState } from "react";
import { Checkbox } from "@material-ui/core";
import SelectComponent from "../control.component/select.component";
import {
  CreateOrganizationFormCol,
  CreateOrganizationContainer,
  CreateOrganizationForm,
  CreateOrganizationFormRow,
  OrganizationInput,
  LabelOrganization,
  OrganizationImage,
  OrganizationEditButtton,
  OrganizationEditButttonText,
  OrganizationEditButttonIcon,
  OrgainzationsListContainer,
  OrgainzationsListCloseProject,
  OrgainzationsListProject,
  OrganizationButon,
} from "./AddOrganization.style";

import { FaWindowClose } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import {
  PROJECT_TYPE,
  CONDITION,
  STATUS,
  rewardSystem,
  surveySystem,
  COLLABORATIVE,
  chartView,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationAction } from "../../actions/organizationActions";
import WarningPopup from "../warningPopup/WarningPopup";
import {
  resetUpdateProject,
  updateProjectAction,
} from "../../actions/projectListAction";
import { useRef } from "react";
import axios from "axios";
const EditProjectPopup = ({ close, project }) => {
  const fileRef = useRef(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [projectInfo, setProjectInfo] = useState(project);
  const [collaborative, setCollaborative] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [selectedOrganzation, setSelectedOrganization] = useState([
    project?._id,
  ]);
  const [chartViewState, setChartViewState] = useState("Standard");
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.organization);
  const { loading, error, updateProject } = useSelector(
    (state) => state.organizationProjectList
  );
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.loggedInUser);
  const [showFormWarning, setShowFormWarning] = useState(false);
  useEffect(() => {
    dispatch(getOrganizationAction(user));
  }, [dispatch]);

  useEffect(() => {
    if (updateProject) {
      close();
      dispatch(resetUpdateProject());
    }
  }, [updateProject]);

  useEffect(() => {
    if (organizations.length > 0) {
      const result = organizations.map((item) => {
        return {
          _id: item?._id,
          name: item?.title,
        };
      });

      setOrganizationList(result);
    }
  }, [organizations]);

  useEffect(() => {
    if (selectedOrganzation.length > 1) {
      setCollaborative(true);
    }
  }, [selectedOrganzation]);

  useEffect(() => {
    if (projectInfo.organization.length > 0) {
      const result = projectInfo.organization.map((item) => {
        return organizations.find((org) => org._id === item);
      });

      setSelectedOrganization(result);
    } else {
      const result = organizations?.find((org) => org._id === project?._id);
      setSelectedOrganization([result]);
    }
  }, [projectInfo.organization]);

  const closeWarningForm = () => {
    setShowFormWarning(false);
    close();
  };
  const closeWarning = () => {
    setShowFormWarning(false);
  };

  const closeForm = (e) => {
    setShowFormWarning(true);
  };

  return (
    <CreateOrganizationContainer className="form-Organization-add">
      <CreateOrganizationForm>
        <CreateOrganizationFormRow style={{ paddingLeft: 40 }}>
          <CreateOrganizationFormCol
            alignment="flex-start"
            style={{ paddingLeft: 0, position: "relative" }}
          >
            <OrganizationImage src={projectInfo?.image} alt="gradegy" />
            <input
              type="file"
              ref={fileRef}
              style={{
                display: "none",
              }}
              onChange={async (e) => {
                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                const res = await axios.post(
                  `https://apigrad.azurewebsites.net/api/v2/photos`,
                  formData,
                  { headers: { Authorization: "Bearer " + token } }
                );
                setProjectInfo({
                  ...projectInfo,
                  image: `https://apigrad.azurewebsites.net${res.data.url}`,
                });
              }}
            />
            <OrganizationEditButtton
              onClick={() => {
                fileRef.current.click();
              }}
            >
              <OrganizationEditButttonText>Edit</OrganizationEditButttonText>
              <OrganizationEditButttonIcon>
                <MdEdit color="#34C38F" size={12} />
              </OrganizationEditButttonIcon>
            </OrganizationEditButtton>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol
            Direction="column"
            style={{ alignItems: "center", width: "100%" }}
          >
            <CreateOrganizationFormCol
              Direction="row"
              alignment="sttart"
              style={{ alignItems: "center" }}
            >
              <OrganizationInput
                Width="485px"
                Height="39px"
                FontFamily="Rajdhani"
                Size="32px"
                Weight="700"
                name="ProjectName"
                placeholder="Name of Project"
                value={projectInfo.name}
                onChange={(e) => {
                  setProjectInfo({ ...projectInfo, name: e.target.value });
                }}
              />
            </CreateOrganizationFormCol>
            <CreateOrganizationFormCol
              Direction="column"
              style={{ paddingLeft: 0, width: 485 }}
            ></CreateOrganizationFormCol>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
        <div style={{ display: "flex" }}>
          <div
            style={{ display: "flex", maxWidth: 575, flexDirection: "column" }}
          >
            <CreateOrganizationFormRow
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                marginTop: 16,
                flexWrap: "wrap",
              }}
            >
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Project Type</LabelOrganization>
                <p>{projectInfo?.project_type}</p>
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Condition</LabelOrganization>
                <p>{projectInfo?.condition}</p>
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Status</LabelOrganization>
                <SelectComponent
                  options={STATUS}
                  selectedValue={STATUS[0]?._id}
                  onChange={(e) => {
                    setProjectInfo({ ...projectInfo, status: e.target.value });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Reward System</LabelOrganization>

                <SelectComponent
                  options={rewardSystem}
                  selectedValue={
                    projectInfo.reward_system
                      ? rewardSystem[0]?._id
                      : rewardSystem[1]?._id
                  }
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      reward_system: e.target.value === "Yes" ? true : false,
                    });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Localized Rewards</LabelOrganization>
                <Checkbox
                  color="default"
                  size="small"
                  style={{ width: 36 }}
                  checked={projectInfo.localized_reward}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      localized_reward: !projectInfo.localized_reward,
                    });
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Survey System</LabelOrganization>
                <SelectComponent
                  options={surveySystem}
                  selectedValue={
                    projectInfo.survey_system
                      ? surveySystem[0]?._id
                      : surveySystem[1]?._id
                  }
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      survey_system: e.target.value === "Yes" ? true : false,
                    });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Localized Survey</LabelOrganization>
                <Checkbox
                  color="default"
                  size="small"
                  style={{ width: 36 }}
                  checked={projectInfo.localize_survey}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      localize_survey: !projectInfo.localize_survey,
                    });
                  }}
                  value={""}
                />
              </CreateOrganizationFormCol>
            </CreateOrganizationFormRow>
            <CreateOrganizationFormRow
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                marginTop: 10,
                flexWrap: "wrap",
              }}
            >
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Chart View</LabelOrganization>
                <SelectComponent
                  options={chartView}
                  selectedValue={projectInfo.chartView}
                  onChange={(e) => {
                    setChartViewState(e.target.value);
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
            </CreateOrganizationFormRow>
          </div>
          <div
            style={{ width: 1, backgroundColor: "#707070", height: 160 }}
          ></div>
          <div style={{ display: "flex", maxWidth: 350 }}>
            <CreateOrganizationFormRow
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                marginTop: 20,
                flexWrap: "wrap",
              }}
            >
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Collaborative</LabelOrganization>

                <SelectComponent
                  options={COLLABORATIVE}
                  selectedValue={
                    collaborative
                      ? COLLABORATIVE[0]?._id
                      : COLLABORATIVE[1]?._id
                  }
                  onChange={(e) => {
                    e.target.value === "Yes"
                      ? setCollaborative(true)
                      : setCollaborative(false);
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              {collaborative && (
                <CreateOrganizationFormCol Direction="column">
                  <LabelOrganization>Organization</LabelOrganization>
                  <SelectComponent
                    name="Organization"
                    selected={true}
                    options={[{ name: " ", _id: "" }, ...organizationList]}
                    onChange={(e) => {
                      if (e.target?._id !== "") {
                        const existItem = projectInfo.organization.find(
                          (item) => item === e.target.value
                        );

                        if (!existItem) {
                          setProjectInfo({
                            ...projectInfo,
                            organization: [
                              ...projectInfo.organization,
                              e.target.value,
                            ],
                          });
                        }
                      }
                    }}
                    style={{
                      marginBottom: 15,
                      paddingTop: 6,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 6,
                      minWidth: 129,
                      fontSize: 10,
                      backgroundPosition: "bottom 10px right 6px",
                    }}
                  />
                </CreateOrganizationFormCol>
              )}

              <CreateOrganizationFormCol
                Direction="column"
                style={{ width: "94%" }}
              >
                <LabelOrganization>Organizations</LabelOrganization>
                <OrgainzationsListContainer>
                  {selectedOrganzation.map((item) => (
                    <OrgainzationsListProject key={item?._id}>
                      <span>{item?.title}</span>
                      <OrgainzationsListCloseProject
                        onClick={() => {
                          if (projectInfo.organization[0] !== item?._id) {
                            setProjectInfo({
                              ...projectInfo,
                              organization: projectInfo.organization.filter(
                                (id) => id !== item?._id
                              ),
                            });
                          }
                        }}
                      >
                        <FaWindowClose spaceLeft="18px" color={"#707070"} />
                      </OrgainzationsListCloseProject>
                    </OrgainzationsListProject>
                  ))}
                </OrgainzationsListContainer>
              </CreateOrganizationFormCol>
            </CreateOrganizationFormRow>
          </div>
        </div>
        <CreateOrganizationFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 48,
            marginBottom: 30,
            justifyContent: "center",
          }}
        >
          <CreateOrganizationFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <OrganizationButon
              onClick={() => {
                const projectInofDetails = {
                  ...projectInfo,
                  chartView: chartViewState,
                };
                dispatch(
                  updateProjectAction(projectInfo?._id, projectInofDetails)
                );
              }}
              disabled={loading}
            >
              {" "}
              {loading ? "Sending..." : "SAVE"}
            </OrganizationButon>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol style={{ paddingLeft: 30 }}>
            <OrganizationButon onClick={closeForm}>Cancel</OrganizationButon>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
      </CreateOrganizationForm>
      {showFormWarning ? (
        <WarningPopup
          close={closeWarningForm}
          text={"Clear All Changes"}
          reject={closeWarning}
          accept={() => {}}
        />
      ) : null}
      {false && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {"error"}
        </div>
      )}
    </CreateOrganizationContainer>
  );
};

export default EditProjectPopup;
