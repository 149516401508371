import { CHANGE_HIGHSCHOOL_ACCESS_PERMISSION } from "constant";
export const changeHighSchoolAccessPermissionAction = (
  dispatch,
  accessName,
  accessValue
) => {
  dispatch({
    type: CHANGE_HIGHSCHOOL_ACCESS_PERMISSION,
    payload: { accessName, accessValue },
  });
};
