import React, { useCallback, useEffect, useState } from "react";
import {
  raffleListSelector,
  rafflePageStatusSelector,
  selectedAppProjectIdSelector,
  selectedAppOrganizationIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
  raffleToastMessageSelector,
  selectedRaffleTotalSelector,
  allAppCategoriesSelector,
} from "store/selectors";
import ReactPaginate from "react-paginate";
import { addRaffleItemAction, getALLRaffleItemListAction } from "actions";
import { canEditAccessCheckShopRaffle } from "helpers";
import { useSelector, useDispatch } from "react-redux";
import {
  RaffleContainer,
  RaffleContentContainer,
} from "components/styled.components/raffle.style";
import Confirm from "components/display.component/confirm.components";
import AnimationCheck from "components/animation.icon/animation.icon.components";
import AnimationError from "components/animation.icon/animation.error";
import RaffleHeaderContainer from "containers/Raffle/RaffleHeader";
import RaffleListContainer from "containers/Raffle/RaffleList";
import RaffleAddItemPopupContainer from "containers/Raffle/AddRaffleItem";
import { gradify } from "axios.config";

const RafflePage = ({ history }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await gradify.get(`raffletickets/pickwinner`);
    })();
  });
  //states
  const [searchRaffleInput, setSearchRaffleInput] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [sortType, setSortType] = useState({
    type: "titleSort",
    isReversed: false,
  });
  //selectors
  const user = useSelector((state) => state.loggedInUser?.user);
  const rafflePageStatus = useSelector(rafflePageStatusSelector);
  const toastMessage = useSelector(raffleToastMessageSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const raffeleTotal = useSelector(selectedRaffleTotalSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  const raffleList = useSelector(raffleListSelector);
  const categories = useSelector(allAppCategoriesSelector);

  //actions
  const searchInputChangedHandler = useCallback((e) => {
    console.log(e.target.value);
    setSearchRaffleInput(e.target.value);
    setPageCount(1);
    dispatch(getALLRaffleItemListAction({}, 1, e.target.value));
  }, []);

  const handlePageClick = (event) => {
    dispatch(getALLRaffleItemListAction({}, event.selected + 1));
    setPageCount(event.selected + 1);
  };

  const editButtonClickedHandler = useCallback(
    (id) => {
      history.push(`/admin/raffleItem/${id}`);
    },
    [history]
  );
  const handleSaveRaffleItemClicked = useCallback(
    (raffleItemInfo) => {
      const raffleInfo = {
        ...raffleItemInfo,
        organization: selectedOrganizationId,
        project: selectedProjectId,
        // category: selectedCategoryId,
        // subCategory: selectedSubCategoryId,
      };
      dispatch(addRaffleItemAction(raffleInfo));
    },
    [
      dispatch,
      selectedCategoryId,
      selectedOrganizationId,
      selectedProjectId,
      selectedSubCategoryId,
    ]
  );
  const handleSortShop = useCallback(
    (field) => {
      if (field === sortType?.type) {
        dispatch(
          getALLRaffleItemListAction({
            type: field,
            isReversed: !sortType.isReversed,
          })
        );
        setSortType({ type: field, isReversed: !sortType.isReversed });
      } else {
        dispatch(
          getALLRaffleItemListAction({ type: field, isReversed: false })
        );
        setSortType({ type: field, isReversed: false });
      }
    },
    [dispatch, sortType.isReversed, sortType?.type]
  );
  return (
    <>
      <RaffleHeaderContainer
        searchRaffleInput={searchRaffleInput}
        setSearchRaffleInput={setSearchRaffleInput}
        haveAccess={canEditAccessCheckShopRaffle(user)}
        onSearchInputChanged={searchInputChangedHandler}
      />
      <RaffleContainer>
        <RaffleContentContainer
          style={{ width: "90%", marginLeft: 50, padding: 0 }}
        >
          {console.log(raffleList)}
          <RaffleListContainer
            haveAccess={canEditAccessCheckShopRaffle(user)}
            onEditButtonClick={editButtonClickedHandler}
            raffleList={raffleList.raffleItemList}
            onSortRaffleList={handleSortShop}
            sortType={sortType}
          />
        </RaffleContentContainer>
      </RaffleContainer>

      {raffeleTotal !== 0 && raffeleTotal > 25 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={Math.ceil(raffeleTotal / 25)}
          forcePage={pageCount - 1}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}
      {rafflePageStatus === "ADD_RAFFLE_ITEM_POPUP" && (
        <RaffleAddItemPopupContainer
          onSaveRaffleItemClicked={handleSaveRaffleItemClicked}
          categories={categories}
        />
      )}
      {rafflePageStatus === "RAFFLE_TICKET_MESSAGE_POPUP" &&
        !toastMessage?.hasError && (
          <Confirm message={toastMessage?.message}>
            <AnimationCheck />
          </Confirm>
        )}
      {rafflePageStatus === "RAFFLE_TICKET_MESSAGE_POPUP" &&
        toastMessage?.hasError && (
          <Confirm message={toastMessage?.message}>
            <AnimationError />
          </Confirm>
        )}
    </>
  );
};

export default RafflePage;
