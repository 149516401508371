import React from "react";
import {
  StudentInfoChartContainer,
  StudentInfoChartContain,
  StudentInfoChartTitle,
  StudentInfoPersonaText,
  StudentInfoChartTitleText,
  StudentInfoChartBox,
  StudentInfoChartItems,
  StudentInfoChart,
  StudentInfoMatchFit,
  StudentInfoMatchFitText,
  StudentInfoMatchFitBox,
  StudentInfoMatchFitBoxTitle,
  StudentInfoMatchFitBoxDescription,
  StudentInfoBoosterHeader,
  StudentBoosterTitle,
  StudentBoosterImage,
  StudentInfoBoosterDesc,
  StudentInfoBooster,
} from "./student.style";
import { useSelector } from "react-redux";
import MatchFitBack from "../../assets/image/match-back.png";
import ExplorBack from "../../assets/image/Exploring-back.png";
import DiVer from "../../assets/image/diver.png";
import GoGeer from "../../assets/image/geo.png";
import BoosterIcon from "../../assets/image/booster-icon.png";
import BrainIcon from "../../assets/image/brainIcon.png";
import EnfpIcon from "../../assets/image/enfpIcon.png";
import GenIcon from "../../assets/image/genIcon.png";
import MetroIcon from "../../assets/image/metroIcon.png";
import RadarChart from "components/charts/radarChart";
import { userSystemAction } from "actions/userSystemAction";
const StudentAboutChart = () => {
  const { user } = useSelector((state) => state.userDetails);

  return (
    <StudentInfoChartContainer>
      <StudentInfoChartContain>
        <StudentInfoChartTitle>
          <StudentInfoPersonaText>Persona</StudentInfoPersonaText>

          <StudentInfoChartTitleText
            Color={
              user.persona === "Explorer"
                ? "#34C38F"
                : user.persona === "DIY-er"
                ? "#EC5929"
                : "#2D6ABB"
            }
          >
            {user.persona}
          </StudentInfoChartTitleText>
        </StudentInfoChartTitle>

        <StudentInfoChartBox
          Background={
            user.persona === "Explorer"
              ? ExplorBack
              : user.persona === "DIY-er"
              ? DiVer
              : GoGeer
          }
        >
          <StudentInfoChartItems>
            <div>
              {user.firstGen ? (
                <div className="st-info-about-box st-info-first-generation">
                  <h5>
                    1<sup>st</sup>Gen
                  </h5>
                  <img src={GenIcon} alt="" />
                </div>
              ) : null}

              {user.personality_type || user.personality_type != "" ? (
                <div className="st-info-about-box st-info-about-enfp">
                  <h5>{user.personality_type}</h5>
                  <img src={EnfpIcon} alt="" />
                </div>
              ) : null}

              {user.grit_score ? (
                <div className="st-info-about-box st-info-about-grit">
                  <h5>
                    <sub>Grit</sub>
                    {user.grit_score}
                  </h5>
                  <img src={MetroIcon} alt="" />
                </div>
              ) : null}

              {user.burns_score ? (
                <div className="st-info-about-box st-info-about-burns">
                  <h5>
                    <sub>Burns</sub>
                    {user.burns_score}
                  </h5>
                  <img src={BrainIcon} alt="" />
                </div>
              ) : null}

              <StudentInfoBooster>
                <StudentInfoBoosterHeader>
                  <StudentBoosterTitle>Boosters</StudentBoosterTitle>

                  <StudentBoosterImage src={BoosterIcon} alt="gradegy" />
                </StudentInfoBoosterHeader>

                <StudentInfoBoosterDesc>
                  {user.booster.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })}
                </StudentInfoBoosterDesc>
              </StudentInfoBooster>
            </div>
          </StudentInfoChartItems>

          <StudentInfoChart>
            <RadarChart
              color={
                user.persona === "Explorer"
                  ? "#34C38F"
                  : user.persona === "DIY-er"
                  ? "#EC5929"
                  : "#2D6ABB"
              }
              futureFocused={user.future_focused}
              socialInfluence={user.social_influence}
              meaning={user.meaning}
              ownership={user.ownership}
              tribe={user.tribe}
              accomplishment={user.accomplishment}
              risk={user.risk}
              self_efficacy={user.self_efficacy}
            />
          </StudentInfoChart>
        </StudentInfoChartBox>
      </StudentInfoChartContain>

      <StudentInfoChartContain style={{ marginTop: 17 }}>
        <StudentInfoMatchFit Background={MatchFitBack}>
          <StudentInfoMatchFitText>Match Fit</StudentInfoMatchFitText>

          <StudentInfoMatchFitBox>
            <StudentInfoMatchFitBoxTitle>
              Reach School
            </StudentInfoMatchFitBoxTitle>

            <StudentInfoMatchFitBoxDescription>
              {user.reach_school.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </StudentInfoMatchFitBoxDescription>
          </StudentInfoMatchFitBox>

          <StudentInfoMatchFitBox>
            <StudentInfoMatchFitBoxTitle>
              Match School
            </StudentInfoMatchFitBoxTitle>

            <StudentInfoMatchFitBoxDescription>
              {user.match_school.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </StudentInfoMatchFitBoxDescription>
          </StudentInfoMatchFitBox>

          <StudentInfoMatchFitBox>
            <StudentInfoMatchFitBoxTitle>
              Safety School
            </StudentInfoMatchFitBoxTitle>

            <StudentInfoMatchFitBoxDescription>
              {user.safety_school.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </StudentInfoMatchFitBoxDescription>
          </StudentInfoMatchFitBox>
        </StudentInfoMatchFit>
      </StudentInfoChartContain>
    </StudentInfoChartContainer>
  );
};

export default StudentAboutChart;
