import React, { useEffect, useState } from "react";
import "./polygon.style.css";
const PolygonCharts = ({studentPic,XP,style,color}) => {
  const [progress,setProgress] = useState(null);
  useEffect(() => {
      const result = XP / 3500;
      const level = Math.floor(result);
      const percent = (result - level).toFixed(2) * 100;
      setProgress((835 * (percent / 100)) + 834);
  },[XP]);
  return (
    
    <div style={style ? style : null} className="polygonChartContainer">
      <svg className="svg-polygon">
        <polygon
          points="140 14,15 75,15 210,140 285,265 210,265 75"
          style={{borderRadius:30}}
          className="round"
        />
        <polygon
          points="140 14,15 75,15 210,140 285,265 210,265 75"
          style={{borderRadius:30}}
          className="round"
        />
        <polygon
          points="140 14,15 75,15 210,140 285,265 210,265 75"
          style={{borderRadius:30,strokeDashoffset:progress,stroke:color}}
          className="round progress"
        />
      </svg>
      <img src={studentPic} alt="" className="profilepic" />

    </div>
  );
};

export default PolygonCharts;
