import React, { useCallback, useEffect, useState } from "react";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import { RowTr, RowTd, RowWrapper } from "./index.style";
import { useDispatch } from "react-redux";
import { updateDigitalCodeAciton } from "actions/inventory/inventory.action";
import moment from "moment/moment";
const StudentRow = ({
  _id,
  name,
  purchasedDate,
  isPickedup,
  pickupDate,
  stockId,
  onChangeToggle,
  shopItem,
  raffelTicket,
}) => {
  const dispatch = useDispatch();
  const [pickedUp, setPickUp] = useState(isPickedup);
  const handleChangeToggle = useCallback(
    (_id) => {
      setPickUp(!pickedUp);
      onChangeToggle(_id, !pickedUp);
      dispatch(
        updateDigitalCodeAciton(
          { pickup: !pickedUp },
          stockId,
          shopItem,
          raffelTicket
        )
      );
    },
    [onChangeToggle, pickedUp]
  );
  useEffect(() => {
    setPickUp(isPickedup);
  }, [isPickedup]);

  return (
    <RowTr key={_id}>
      <RowTd>{name}</RowTd>

      <RowTd>{purchasedDate}</RowTd>
      <RowTd>
        <CustomSwitch
          isToggle={pickedUp}
          onChangeToggle={() => handleChangeToggle(_id)}
        />
      </RowTd>
      <RowTd>{pickedUp ? new Date(pickupDate).toISOString() : ""}</RowTd>
      <RowWrapper>
        <RowTd>{stockId}</RowTd>
      </RowWrapper>
    </RowTr>
  );
};

export default StudentRow;
