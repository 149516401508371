import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Row,
  Title,
  Button,
  Column,
  EditButtonText,
  EditButtonIcon,
} from "assets/style/General.style";
import { EditButtonAccess } from "./access.style";
import { MdEdit } from "react-icons/md";
import SummarySections from "containers/Admin/AdminDetail/SummarySection";
import SpecialProjects from "containers/Admin/AdminDetail/SpecialProjects";
import University from "containers/Admin/AdminDetail/University";
import SchoolDistrictContainer from "containers/Admin/AdminDetail/SchoolDistrict";
import Tags from "components/admin/adminDetail/Access/EditSections/Tags";
import { adminDetailPageStatusSelector } from "store/selectors";

const Access = ({
  isAccessEditting,
  onEditAccessClick,
  onCancelEditAccessClick,
  organizationType,
  onSaveEditAdminClick,
  organizationSchoolDistName,
}) => {
  const pageStatus = useSelector(adminDetailPageStatusSelector);
  return (
    <Box style={{ flexDirection: "column", marginTop: 17 }}>
      <Row>
        <Column Width="45%" alignment="flex-start" style={{ paddingLeft: 20 }}>
          <Title>Access</Title>
          <EditButtonAccess
            isAccessEditting={isAccessEditting}
            style={{ marginLeft: "33px" }}
            onClick={onEditAccessClick}
          >
            <EditButtonText>Edit</EditButtonText>
            <EditButtonIcon>
              <MdEdit color="#34C38F" size={12} />
            </EditButtonIcon>
          </EditButtonAccess>
        </Column>
        <Column
          style={{ visibility: `${!isAccessEditting ? "hidden" : "visible"}` }}
          Width="35%"
          alignment="center"
        >
          <Title HideTablet >Can View</Title>
        </Column>
        <Column
          style={{ visibility: `${!isAccessEditting ? "hidden" : "visible"}` }}
          Width="30%"
          alignment="center"
        >
          <Title HideTablet>Can Edit</Title>
        </Column>
      </Row>
      {isAccessEditting ? (
        <>
          <Row Direction="column">
            {organizationType === "Secondary" && <SchoolDistrictContainer />}
            {organizationType === "Post Secondary" && <University />}
            <SpecialProjects />
            {false && <Tags />}
          </Row>
          <Row Direction="center" style={{ paddingTop: 30 }}>
            <Button onClick={onSaveEditAdminClick}>
              {pageStatus === "SAVING_EDIT" ? "SAVING..." : "SAVE"}
            </Button>
            <Button
              style={{ marginLeft: 60 }}
              onClick={onCancelEditAccessClick}
            >
              Cancel
            </Button>
          </Row>
        </>
      ) : (
        <SummarySections organizationType={organizationType} />
      )}
    </Box>
  );
};

export default Access;
