import React, { useState } from "react";
import "./login.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { forgotPasswordAction } from "actions/forgotPassword/forgotPasswordAction";

const ForgotPassword = ({ 
    
    
    username,
    loading,
    setScreen

}) => {

  const dispatch = useDispatch();

  const [email,setEmail] = useState('');
  const onchangeHanlder = (event) => {
    setEmail(event.target.value);
  }
  const adminLoginHandler = (event) => {
      dispatch(forgotPasswordAction(email));
      setScreen('verification-code')
  }

  return (
        <div className="login-content">
          <h3 className="login-content-title">Forgot Password</h3>
          <TextField 
                sx={{
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '& input': {
                    padding: '11.5px 14px',
                  },
                  '& label': {
                    lineHeight: '14px',
                  },
                }}
          style={{width:"100%"}} id="outlined-basic" label="Your Email/Username" variant="outlined"  name="email" value={email} onChange={onchangeHanlder} />

          <div className="loginbutton">
            <button onClick={adminLoginHandler} disabled={loading}>{loading? 'Loading...' : 'Get New Password'}</button>
          </div>

          <p className="forgot-password">You’ll receive an email in your inbox with verification code. If you have any problems, contact us!</p>
        </div>
  );
};

export default ForgotPassword;
