import React, { useMemo } from "react";
import SelectComponent from "components/control.component/select.component";
import StudentRow from "./StudentRow";
import {
  Wrapper,
  Header,
  StatusWrapper,
  Title,
  SearchBox,
  ListWrapper,
  StatusText,
  HeadTr,
  HeadTh,
} from "./index.style";

const RightColumn = ({
  studentsList,
  searchStudentValue,
  onSearchInputChanged,
  selectedStatusId,
  onStatusChanged,
  onChangeToggle,
  shopItem,
  raffelTicket
}) => {
  const renderStudents = useMemo(
    () =>
      studentsList &&
      Object.values(studentsList).map((student) => {
        const { _id, name, purchasedDate, isPickedup, pickupDate, stockId } =
          student;
        return (
          <StudentRow
            key={_id}
            _id={_id}
            name={name}
            isPickedup={isPickedup}
            purchasedDate={purchasedDate}
            pickupDate={pickupDate}
            stockId={stockId}
            onChangeToggle={onChangeToggle}
            shopItem={shopItem}
            raffelTicket={raffelTicket}
          />
        );
      }),
    [onChangeToggle, studentsList]
  );
  return (
    <Wrapper>
      <Header>
        <SearchBox
          value={searchStudentValue}
          onChange={onSearchInputChanged}
          placeholder="Search Student"
        />
        <Title>Students</Title>
        <StatusWrapper>
          <StatusText>Status</StatusText>
          <SelectComponent
            options={[
              { id: 0, name: "All" },
              { id: 1, name: "Not picked up" },
              { id: 2, name: "Picked up" },
            ]}
            selectedValue={selectedStatusId}
            name="Status"
            selected={false}
            onChange={onStatusChanged}
            style={{ minWidth: 130 }}
          />
        </StatusWrapper>
      </Header>
      <ListWrapper>
        <HeadTr>
          <HeadTh>Student</HeadTh>
          <HeadTh>Date Purchased</HeadTh>
          <HeadTh>Picked up?</HeadTh>
          <HeadTh>Date Picked up</HeadTh>
          <HeadTh>Stock ID</HeadTh>
        </HeadTr>
        {renderStudents}
      </ListWrapper>
    </Wrapper>
  );
};

export default RightColumn;
