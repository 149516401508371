import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConstantLabel, ConstantValue } from "../assets/style/General.style";
import { useParams } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { StudentDetailsContainer } from "../components/styled.components";
import PolygonCharts from "../components/charts/PolygonCharts";
import CircleChart from "../components/charts/CircleChart";
import StudentXp from "../components/charts/StudentXp";
import Gems from "../components/Gem/Gems";
import Fuses from "../components/fuses/Fuses";
import Crystals from "../components/crystals/Crystals";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsAction } from "../actions/userDetailsAction";
import LeaderBoard from "../components/leaderBoard/LeaderBoard";
import AreaChart from "../components/charts/AreaChart";
import CustomSwitch from "../components/control.component/switch/CustomSwitch";
import AwardedTable from "../components/students/Student.awarded/AwardedTable";
import EditStudentAbout from "../components/students/student.about.edit";
import StudentDetailFilter from "components/students/StudentDetailFilter";
import EditStudentInfo from "../components/students/student.info.edit";
import { addXpToUserAction } from "../actions/userListAction";
import { StudentButton } from "../components/styled.components";
import { FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useProgress } from "../hooks/useProgress";

import { calulateCircleChartPercent } from "../helpers/calculatePercent";
import { updateXpDetails } from "../actions/userDetailsAction";
import { getLeaderBoard } from "../actions/userListAction";
import { getAllUserLogin } from "../actions/loginActions";
import StudentAboutChart from "components/students/student.about.chart";
import Spinner from "components/spinner/spinner";
import {
  getAllUserXpByOrganizationProject,
  getAllUserXpsProject,
  getAllXpsBounes,
} from "../actions/xpsAction";
import {
  getUserExperienceList,
  getUserExperiencePassDueListAction,
  getUserExperienceCompleteListAction,
} from "../actions/experienceAction";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  LabelStudent,
  StudentProjectContainer,
  StudentProject,
  StudentCloseProject,
} from "../components/styled.components/create.student";
import SelectComponent from "../components/control.component/select.component";
import { userSystemAction } from "../actions/userSystemAction";
import ExperienceItem from "../components/experienceItem/ExperienceItem";
import { KeyScoreContainer } from "containers/key-factor-chart/key-factor-chart.container";
import { DevAreaScoreContainer } from "containers/dev-area-score/dev-area-score.container";
import { CollegeConnectionContainer } from "containers/collage-score.container.js/college-score.container";
import { StudentScore } from "containers/student-score/student-score.container";
import { ProgressChartContainer } from "components/progress-chart-container/progress-chart-container.component";
import { MomentumChartContianer } from "components/momentum-chart/momemtum-chart-container.component";
import { getExperienceListAction } from "actions/experienceListAction";
import { progressAction } from "actions/pmChartAction";
import { newMomentumAction } from "actions/momentumAction";
import {
  normalizeSelectedStudentDetails,
  systemLoader,
} from "helpers/system/systemLoader";
import { getScoresAction } from "actions/scores";
import { selectProgressData, selectProgressPercent } from "store/selectors";
import { getCoinsAmountAction } from "actions/coins";

const StudentDetails = () => {
  const { percent } = useSelector((state) => selectProgressData(state));
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userState, setUserState] = useState({});

  const today = new Date();
  const [xpPanel, setXpPanel] = useState(false);

  const [showEditInfoForm, setShowEditInfoForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [expState, setExpState] = useState([]);
  const [userOrganization, setUserOrganization] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState(null);
  const [runUser, setRunUser] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, user, error } = useSelector((state) => state.userDetails);
  const { logins, loginCount } = useSelector((state) => state.login);
  const { xps } = useSelector((state) => state.xpsBounes);
  const { xpList } = useSelector((state) => state.xpsListProject);
  const { experienceList } = useSelector((state) => state.experienceLists);
  const { experiencePassDueList } = useSelector(
    (state) => state.experiencePassDueList
  );
  const { experienceCompleteList } = useSelector(
    (state) => state.experienceCompleteList
  );
  const { token } = useSelector((state) => state.auth);
  const { organization, project, category, subCategory } = useSelector(
    (state) => state.userSystem
  );
  const { totalAmount } = useSelector((state) => state.xpsBounes);
  const { coins } = useSelector((state) => state.coins);

  const { leaderBoards } = useSelector((state) => state.leaderBoard);
  const { scores } = useSelector((state) => state.scores);
  const { progressCalc } = useSelector((state) => state.Progress);

  const [progressPercent, setProgressPercent] = useState(percent);

  // useEffect(() => {
  //   if (current) {
  //   }
  // }, [current]);
  const {
    isSuccess,
    isPending,
    experienceList: expRealList,
  } = useSelector((state) => state.experienceListRefactor);
  const [totalAmountState, setTotalAmountState] = useState(0);
  const [xpInfo, setXpInfo] = useState({
    description: "",
    amount: "",
    addByAdmin: true,
    user: user._id,
  });
  const closeEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const closeEditInfoForm = () => {
    setShowEditInfoForm(!showEditInfoForm);
  };

  const changeHandler = (e) => {
    setXpInfo({ ...xpInfo, [e.target.name]: e.target.value });
  };
  const changeXpHandler = (e) => {
    setXpInfo({
      ...xpInfo,
      amount: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value),
    });
  };
  const addXpHandler = () => {
    const result = {
      ...xpInfo,
      organization: organization?._id ? organization?._id : null,
      project: project?._id ? project?._id : null,
    };
    dispatch(addXpToUserAction(result));
    setXpPanel(false);

    dispatch(getLeaderBoard(project?._id));
    setTotalAmountState(totalAmountState + result.amount);
    setRunUser(true);
    dispatch(getAllXpsBounes(id, organization?._id, project?._id));
    dispatch(updateXpDetails(result.amount));
  };

  useEffect(() => {
    dispatch(userDetailsAction(id));
    dispatch(getAllUserLogin(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.firstName && !runUser) {
      setXpInfo({ ...xpInfo, user: user._id });
      const result = user.system.map((item) => {
        return {
          name: `${item?.organization?.title ?? ""} - ${
            item?.project?.name ?? ""
          } - ${item?.category?.name ?? ""} - ${item?.subCategory?.name ?? ""}`,
          _id: item?._id,
          value: item.organization?._id,
        };
      });

      setUserOrganization(result);
      setSelectOrganization(
        JSON.parse(
          normalizeSelectedStudentDetails(systemLoader(user.system))[0]._id
        )
      );
      dispatch(userSystemAction(user.system[0]));
    } else {
    }
  }, [user, runUser]);

  useEffect(() => {
    console.log("********************", totalAmountState);

    if (totalAmount > 0) {
      setTotalAmountState(totalAmount);
    } else {
      setTotalAmountState(0);
    }
  }, [totalAmount]);

  useEffect(() => {
    console.log("percent student details", percent);

    if (!isNaN(percent) && selectOrganization) {
      setProgressPercent(percent);
      dispatch(
        getScoresAction(
          user?._id,
          selectOrganization?.project ? selectOrganization?.project : null,
          percent,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          selectOrganization?.category ? selectOrganization?.category : null,
          selectOrganization?.categories ? selectOrganization?.categories : []
        )
      );
    }
  }, [percent, selectOrganization]);

  useEffect(() => {
    if (selectOrganization) {
      console.log("this is tsessssss", selectOrganization);
      dispatch(
        getLeaderBoard(
          selectOrganization?.project ? selectOrganization.project : null,
          selectOrganization?.category ? selectOrganization?.category : null,
          selectOrganization?.categories ? selectOrganization?.categories : []
        )
      );
      dispatch(
        getAllUserXpByOrganizationProject(
          id,
          selectOrganization?.project?._id,
          selectOrganization?.category ? selectOrganization?.category : null,
          selectOrganization?.categories ? selectOrganization?.categories : []
        )
      );

      dispatch(
        getCoinsAmountAction(
          user?._id,
          selectOrganization?.project?._id,
          selectOrganization?.category
            ? selectOrganization?.category?._id
            : null,
          selectOrganization?.categories ? selectOrganization?.categories : []
        )
      );

      // dispatch(
      //   getAllXpsBounes(id, organization?._id, selectOrganization?.project?._id)
      // );
      dispatch(
        progressAction(
          user?._id,
          selectOrganization?.project?._id,
          selectOrganization?.category
            ? selectOrganization?.category?._id
            : null,
          "2022-01-01",
          selectOrganization.categories ? selectOrganization.categories : []
        )
      );
      dispatch(
        newMomentumAction(
          user?._id,
          project?._id,
          category ? category?._id : null,
          15,
          selectOrganization.categories ? selectOrganization.categories : []
        )
      );
      dispatch(
        getExperienceListAction(
          selectOrganization.project ? selectOrganization.project : null,
          selectOrganization.category ? selectOrganization.category : null,
          user?._id,
          selectOrganization.categories ? selectOrganization.categories : []
        )
      );
    } else {
      console.log(" ");
    }
  }, [selectOrganization]);

  setTimeout(() => {
    setShowProgress(true);
  }, 7000);
  return (
    <StudentDetailsContainer>
      {user.firstName ? (
        <>
          <CreateStudentFormRow
            style={{
              paddingRight: 30,
              marginTop: -10,
              marginBottom: 35,
              flexWrap: "wrap",
            }}
          >
            <CreateStudentFormCol Direction="column">
              <LabelStudent>
                Organization-Project-Category-SubCategory
              </LabelStudent>
              <SelectComponent
                options={[
                  {
                    id: JSON.parse(
                      normalizeSelectedStudentDetails(
                        systemLoader(user.system)
                      )[0]._id
                    ),
                    name: "Select Account",
                  },
                  ...normalizeSelectedStudentDetails(systemLoader(user.system)),
                ]}
                name="Organization"
                selectedValue={selectOrganization}
                onChange={(e) => {
                  const selectValueSystem = JSON.parse(e.target.value);
                  // const userSystem = user.system.find(
                  //   (item) => item._id === e.target.value
                  // );
                  setSelectOrganization(selectValueSystem);
                }}
                style={{ minWidth: 600, fontWeight: 500, fontSize: 15 }}
              />
            </CreateStudentFormCol>

            {/* <CreateStudentFormCol
              Direction="column"
              style={{
                marginLeft: "20px",
              }}
            >
              <LabelStudent>Project</LabelStudent>
              <p>{project?.name}</p>
            </CreateStudentFormCol> */}

            {/* <CreateStudentFormCol
              Direction="column"
              style={{
                marginLeft: "20px",
              }}
            >
              <LabelStudent>Category</LabelStudent>
              <p>{category?.name}</p>
            </CreateStudentFormCol> */}

            {/* <CreateStudentFormCol
              Direction="column"
              style={{
                marginLeft: "20px",
              }}
            >
              <LabelStudent>SubCategory</LabelStudent>
              <p>{subCategory?.name}</p>
            </CreateStudentFormCol> */}
          </CreateStudentFormRow>
          <Paper elevation={3} className="st-info">
            <div className="student-container">
              <div className="student-name">
                <h2>{user.firstName}</h2>
                <h2 style={{ paddingLeft: 15 }}>{user.lastName}</h2>
              </div>
              <PolygonCharts
                XP={totalAmount}
                studentPic={user.profilePicture}
                color={user?.team.color}
              />
            </div>
            <div className="student-cahrt-filter-container">
              {progressPercent && !isNaN(progressPercent) && (
                <StudentDetailFilter
                  user={user}
                  selectOrganization={selectOrganization && selectOrganization}
                  percent={progressPercent}
                />
              )}

              <div style={{ display: "flex" }}>
                <div
                  className="fourth-student-info forth-student-score"
                  style={{ paddingLeft: "8%" }}
                >
                  {console.log("Score", scores)}
                  {scores && (
                    <>
                      <StudentScore
                        scale={1}
                        title="Student Score"
                        left={1}
                        top={1}
                      />
                    </>
                  )}
                </div>
                <div
                  className="fourth-student-info forth-student-scores"
                  style={{
                    width: "49%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {scores && (
                    <>
                      <KeyScoreContainer
                        textWeight={200}
                        scale={0.8}
                        left={-73}
                        top={-35}
                        title="Key Persistent Factors Status"
                      />
                      <DevAreaScoreContainer
                        textWeight={200}
                        className="development-chart"
                        scale={0.8}
                        top={-35}
                        left={-143}
                        title="Developmental Areas"
                      />
                      <CollegeConnectionContainer
                        textWeight={200}
                        className="collage"
                        scale={0.8}
                        left={-240}
                        top={-35}
                        title="College Connection and Engagement"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </Paper>
          <Paper
            elevation={3}
            className="st-info st-info-container"
            style={{ height: "max-content" }}
          >
            <div style={{ display: "flex" }}>
              <h3 className="st-info-title">Info</h3>
              <button
                className="awarded-edit-button"
                onClick={() => setShowEditInfoForm(true)}
              >
                <p className="awarded-edit-button-text">Edit</p>
                <div className="awarded-edit-button-icon">
                  <MdEdit color="#34C38F" size={12} />
                </div>
              </button>
              {showEditInfoForm ? (
                <EditStudentInfo close={closeEditInfoForm} student={user} />
              ) : null}
            </div>

            <div className="info-col-container" style={{ display: "flex" }}>
              <div className="info-col">
                <div>
                  <ConstantLabel>Phone</ConstantLabel>
                  <ConstantValue>{user.phone}</ConstantValue>
                </div>
                <div>
                  <ConstantLabel>email</ConstantLabel>
                  <ConstantValue>{user.email}</ConstantValue>
                </div>
                <div>
                  <ConstantLabel>Preferred Contact</ConstantLabel>
                  <ConstantValue>{user?.contactManner}</ConstantValue>
                </div>
              </div>
              <div className="info-col">
                <div
                  style={{
                    width: "100%",
                    alignItems: "left",
                    textAlign: "left",
                  }}
                >
                  <ConstantLabel style={{ marginBottom: 0 }}>
                    Class Of
                  </ConstantLabel>
                  <ConstantValue style={{ fontSize: 17 }}>2021</ConstantValue>
                </div>
                <div>
                  <ConstantLabel>School District</ConstantLabel>
                  <ConstantValue>
                    {user?.schoolDistrict?.schoolDistrictID.agancy_name}
                  </ConstantValue>
                </div>
                <div>
                  <ConstantLabel>District ID</ConstantLabel>
                  <ConstantValue>
                    {user?.schoolDistrict?.schoolDistrictId}
                  </ConstantValue>
                </div>
                <div>
                  <ConstantLabel>District email</ConstantLabel>
                  <ConstantValue>
                    {user?.schoolDistrict?.schoolDistrictEmail}
                  </ConstantValue>
                </div>
                <div>
                  <ConstantLabel>High School</ConstantLabel>
                  <ConstantValue>{user.highSchool?.school_name}</ConstantValue>
                </div>
              </div>
              <div className="info-col">
                <div>
                  <ConstantLabel>University</ConstantLabel>
                  <ConstantValue>...</ConstantValue>
                </div>
                <div>
                  <ConstantLabel>University ID</ConstantLabel>
                  {/* <p>{user?.schoolDistrict.schoolDistrictID}</p> */}
                </div>

                <div>
                  <ConstantLabel>University email</ConstantLabel>
                  <ConstantValue>{user?.universityEmail}</ConstantValue>
                </div>
              </div>

              <div class="info-col">
                <CreateStudentFormCol
                  Direction="column"
                  style={{
                    width: "100%",
                    position: "relative",
                    top: -15,
                  }}
                >
                  <h3 class="info-student-project-title">Projects</h3>
                  <StudentProjectContainer style={{ border: "none" }}>
                    {user?.system?.map((item) => (
                      <StudentProject
                        key={item._id}
                        className="item_student_project"
                      >
                        <span>{item.organization?.title} </span>
                        <span>{item.project?.name} </span>
                        <span>{item.category?.name} </span>
                        <span>{item.subCategory?.name} </span>
                      </StudentProject>
                    ))}
                  </StudentProjectContainer>
                </CreateStudentFormCol>
              </div>

              <div className="info-col">
                {/* <div className="student-info-tag-container" style={{width:"100%"}}>
                <h3>Tags</h3>
                <div className="student-info-tags" > */}
                {/* <p>CareerSprings</p>
                  <p>KTC (KIPP)</p> */}
                {/* </div>
              </div> */}
              </div>
            </div>
          </Paper>
          <Paper
            elevation={3}
            style={{ marginTop: 10, borderRadius: 12, marginBottom: 40 }}
          >
            <div className="st-info" style={{ paddingBottom: 0 }}>
              <div style={{ marginBottom: 20 }}>
                <div style={{ display: "flex" }}>
                  <h3 className="st-info-title">About</h3>
                  <button
                    className="awarded-edit-button"
                    onClick={() => setShowEditForm(true)}
                  >
                    <p className="awarded-edit-button-text">Edit</p>
                    <div className="awarded-edit-button-icon">
                      <MdEdit color="#34C38F" size={12} />
                    </div>
                  </button>
                  {showEditForm ? (
                    <EditStudentAbout close={closeEditForm} student={user} />
                  ) : null}
                </div>
                <StudentAboutChart />
              </div>
              <div className="st-info-about-content">
                <div className="st-info-about-items">
                  <div className="st-info-about-item">
                    <h3>Work</h3>
                    {user.working ? <p>{user.workingWhere}</p> : null}
                  </div>
                  <div className="st-info-about-item">
                    <h3>Age</h3>
                    <p>{user.age}</p>
                    <p>11/11</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Gender</h3>
                    <p>{user.gender}</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Home</h3>
                    <p>...</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Career Field</h3>
                    <p>....</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Ethnicity</h3>
                    {user.ethnicity ? <p>{user.ethnicity}</p> : null}
                  </div>
                  <div className="st-info-about-item">
                    <h3>College Major</h3>
                    <p>{user.college_major}</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Transportation</h3>
                    <p>....</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Background</h3>
                    <p>{user.background}</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Pathway</h3>
                    <p>...</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>Pronouns</h3>
                    <p>{user.pronouns}</p>
                  </div>
                  <div className="st-info-about-item">
                    <h3>FAFSA/TAFSA</h3>
                    <p>
                      {user.FAFSA_TAFSA ? (
                        <span style={{ color: "#009812" }}>Completed</span>
                      ) : (
                        <span style={{ color: "red" }}>Not Compeleted</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="st-info-about-right-side">
                  <div className="st-info-about-social-media">
                    <h3>Social Media</h3>
                    <div className="st-info-about-social-media-content">
                      {user.socials &&
                        user.socials.map((item) => (
                          <div className="st-info-about-social-media-item">
                            {item}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="st-info-about-interests">
                    <h3>Interests</h3>
                    <div className="st-info-about-interests-content">
                      {/* <div className="st-info-about-interests-item">...</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
          <Paper elevation={3} style={{ marginTop: 10, borderRadius: 12 }}>
            <div
              className="st-info st-info-game-status"
              style={{ minHeight: 467 }}
            >
              <div className="st-info-team-container">
                <div style={{ display: "flex" }}>
                  <h3 className="st-info-title">Game Status</h3>
                  <StudentButton
                    style={{
                      borderRadius: 50,
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      setXpPanel(!xpPanel);
                    }}
                  >
                    <FaPlus style={{ marginRight: 5 }} size={27} />{" "}
                    <span>Award Points</span>{" "}
                  </StudentButton>
                </div>
                <div
                  className="st-info-circlechart-container"
                  style={{ display: "flex", width: 460 }}
                >
                  {showProgress ? (
                    <>
                      <ProgressChartContainer
                        textWeight={"100"}
                        chartPercentCal={60}
                        title="Progress"
                        description="Score"
                      />
                      <MomentumChartContianer
                        textWeight={"200"}
                        chartPercentCal={495 - 85}
                        title="Momentum"
                        description="Score"
                      />
                    </>
                  ) : (
                    <Spinner style={{ width: 50, height: 50, top: 20 }} />
                  )}
                </div>
                {xpPanel ? (
                  <>
                    <div
                      className="xpContainer"
                      onClick={() => setXpPanel(false)}
                    ></div>
                    <div className="xpPanle" style={{ top: 130, left: 85 }}>
                      <div className="xpPanel_header">Add XP</div>
                      <div className="xp_description">
                        <input
                          type="text"
                          name="description"
                          placeholder="Description"
                          className="xpDescription"
                          value={xpInfo.description}
                          onChange={changeHandler}
                        />
                      </div>
                      <div className="xp_description">
                        <input
                          type="text"
                          name="amount"
                          placeholder="Xp Amount"
                          className="xpDescription"
                          value={xpInfo.amount}
                          onChange={changeXpHandler}
                        />
                        <button className="addxpbutton" onClick={addXpHandler}>
                          +
                        </button>
                      </div>
                      <div className="xpDate">
                        <p className="date_text">Date</p>
                        <p className="date_content">
                          {today.getMonth() + 1}/{today.getDate()}/
                          {today.getFullYear()}
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="st-info-team-content">
                <h4 className="st-info-team-content-title">Team</h4>
                <PolygonCharts
                  style={{ transform: "scale(0.5)", top: -50, left: -10 }}
                  color={user?.team.color}
                  XP={totalAmountState}
                  studentPic={user.profilePicture}
                />
              </div>
              <div className="st-info-team-data">
                <StudentXp xp={totalAmount} />
                <Gems coinsNumber={coins} />
                <Crystals crystalNumber={0} />
                <Fuses fusesNumber={0} />
              </div>
              <div className="st-info-leader-board">
                <div className="st-info-leader-board-title">Leader Board</div>
                <LeaderBoard items={leaderBoards} project={project?._id} />
              </div>
            </div>
          </Paper>
          <Paper
            elevation={3}
            style={{
              marginTop: 10,
              borderRadius: 12,
              padding: "25px 22px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <h3 className="st-info-title">Usage</h3>
              <div className="student-usage ">
                <div className="st-usage-item">
                  <h3>Logins</h3>
                  <p>{loginCount}</p>
                </div>

                <div className="st-usage-item">
                  <h3>Posts</h3>
                  <p>0</p>
                </div>

                <div className="st-usage-item">
                  <h3>Reactions</h3>
                  <p>-</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <CircleChart
                  scale={1}
                  numbericCircle={3}
                  numbericCircleColor={"#6A81ED"}
                  title="Influencer"
                  description="Usage Score"
                  level={"_"}
                  percent={"-"}
                />
              </div>
            </div>
            <div style={{ display: "flex", flex: 1 }}>
              <div style={{ width: "100%", alignSelf: "end" }}>
                <AreaChart logins={logins} loginCount={loginCount} />
              </div>
            </div>
          </Paper>

          <Paper
            elevation={3}
            style={{
              marginTop: 10,
              borderRadius: 12,
              padding: "25px 22px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="st-info-experience-box">
              <div className="st-info-experience-box-container">
                <h4>Completion</h4>
                <h4>Item</h4>
                <h4>Description</h4>
                <h4>Completed</h4>
              </div>
              <div>
                {console.log("explist", expRealList)}
                {isSuccess &&
                  expRealList.map((item, index) => (
                    <ExperienceItem
                      key={index}
                      item={item}
                      user={user}
                      system={selectOrganization}
                    />
                  ))}
              </div>
            </div>

            <AwardedTable
              xps={xps}
              organization={organization?._id}
              project={project?._id}
              totalAmount={totalAmountState}
              setTotal={setTotalAmountState}
              setRunUser={setRunUser}
              user={user}
            />
          </Paper>
        </>
      ) : null}
    </StudentDetailsContainer>
  );
};

export default StudentDetails;
