import styled from "styled-components";

export const HomeSearchStudentContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10;
    width: 65%;
    @media (max-width: 1300px) {
        margin-top:-10px;
      }
`;
