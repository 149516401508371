import CircleChart from "components/charts/CircleChart";
import { connect } from "react-redux";
import { selectStudentKeyPersistanFactoryStatus } from "../../store/selectors/Students";
import { calulateCircleChartPercent } from "../../helpers/calculatePercent";
import MomentumChart from "components/momentum-chart/momentum-chart.component";
const mapStateToProps = (state, ownProps) => {
  const percent = selectStudentKeyPersistanFactoryStatus(state);
  const chartPercentCal = calulateCircleChartPercent(495, percent);

  return {
    percent,
    chartPercentCal,
  };
};

export const KeyScoreContainer = connect(mapStateToProps)(MomentumChart);
