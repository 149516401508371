import {
  RAFFLE_TICKET_DETAIL_TOAST_MESSAGE,
  SET_RAFFLE_TICKET_LIST,
  SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
  SET_RAFFLE_TICKET_PAGE_STATUS,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const deleteRaffleTicketAction = (id, history) => async (dispatch) => {
  const selectedOrganizationId = store.getState().app.selectedOrganizationId;
  const selectedProjectId = store.getState().app.selectedProjectId;

  try {
    const token = store.getState().auth.token;
    const raffleTicketId =
      store.getState().raffleTicketDetail.raffleTicketDetail?._id;
    if (raffleTicketId) {
      await gradify.delete(`raffletickets/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
        payload: "RAFFLE_MESSAGE_POPUP",
      });
      dispatch({
        type: RAFFLE_TICKET_DETAIL_TOAST_MESSAGE,
        payload: {
          message: "DELETE ITEM UPDATE SUCCESSFULY",
          hasError: false,
        },
      });

      const { data } = await gradify.get(
        `raffletickets?limit=1000${
          selectedOrganizationId
            ? `&organization=${selectedOrganizationId}`
            : ""
        }${selectedProjectId ? `&project=${selectedProjectId}` : ""}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: SET_RAFFLE_TICKET_LIST,
        payload: data.data,
      });
      dispatch({
        type: SET_RAFFLE_TICKET_PAGE_STATUS,
        payload: "DEFAULT",
      });
      setTimeout(() => {
        dispatch({
          type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
          payload: "DEFAULT",
        });
        dispatch({
          type: RAFFLE_TICKET_DETAIL_TOAST_MESSAGE,
          payload: { message: "", hasError: false },
        });
        history.push("/admin/raffle");
      }, 2000);
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
      payload: "DEFAULT",
    });
    // what's in error?
  }
};
