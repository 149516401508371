import {
  COUNT_RANGE_EXP_FAIL,
  COUNT_RANGE_EXP_SUCCESS,
  COUNT_RANGE_EXP_REQUIRE,
} from "../constant/pmChart";
import { gradify } from "../axios.config";
import { P_P, P_S } from "constant/progress";
export const pmChartInfoAction =
  (startDate, endDate, organization, project, category, subCategory, userId) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: COUNT_RANGE_EXP_REQUIRE,
      });
      if (!project) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&user=${userId}&startDate=${startDate}&startDate=${endDate}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&startDate=${startDate}&startDate=${endDate}&user=${userId}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_EXP_SUCCESS,
          payload: {
            experienceRangeCount: data.count + studentComplete.count,
            completeRangeCount: studentComplete.count,
          },
        });
      } else if (!category) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&user=${userId}&startDate=${startDate}&startDate=${endDate}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&startDate=${startDate}&startDate=${endDate}&user=${userId}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_EXP_SUCCESS,
          payload: {
            experienceRangeCount: data.count + studentComplete.count,
            completeRangeCount: studentComplete.count,
          },
        });
      } else if (!subCategory) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&user=${userId}&startDate=${startDate}&startDate=${endDate}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&startDate=${startDate}&startDate=${endDate}&user=${userId}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_EXP_SUCCESS,
          payload: {
            experienceRangeCount: data.count + studentComplete.count,
            completeRangeCount: studentComplete.count,
          },
        });
      } else {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&subCategory=${subCategory?._id}&user=${userId}&startDate=${startDate}&startDate=${endDate}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&subCategory=${subCategory?._id}&startDate=${startDate}&startDate=${endDate}&user=${userId}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_EXP_SUCCESS,
          payload: {
            experienceRangeCount: data.count + studentComplete.count,
            completeRangeCount: studentComplete.count,
          },
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
      dispatch({
        type: COUNT_RANGE_EXP_FAIL,
        payload: error.message,
      });
    }
  };

export const progressAction =
  (user, project, category, date, categories) => async (dispatch, getState) => {
    dispatch({
      type: P_P,
    });

    let query;
    if (category) {
      if (categories.length < 1) {
        query = `experiencecards/progresscalc?project=${project}&category=${category}&user=${user}&date=${date}`;
      }
      if (categories.length > 0) {
        const categoriesIds = categories.map((i) => i._id);
        query = `experiencecards/progresscalc?project=${project}&category=${categoriesIds}&user=${user}&date=${date}`;
      }
    } else {
      query = `experiencecards/progresscalc?project=${project}&user=${user}&date=${date}`;
    }
    const progress = await gradify.get(query);
    console.log("progress", progress.data.data);

    dispatch({
      type: P_S,
      payload: progress.data.data,
    });
  };
