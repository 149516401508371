import { UNIVERSITY_LIST_FAIL,UNIVERSITY_LIST_REQUSET,UNIVERSITY_LIST_SUCCESS  } from '../constant/university'

export const universityListReducer = (state={universities:[]},action) => {
    switch(action.type){
        case UNIVERSITY_LIST_REQUSET:
            return {loading:true,universities:[]}
        case UNIVERSITY_LIST_SUCCESS:
            return {loading:false,universities:action.payload}
        case UNIVERSITY_LIST_FAIL:
            return {loading:false,universities:[],error:action.payload}
        default:
            return state            
    }
}