import {
  SET_APP_ORGANIZATION_OPTIONS,
  SET_APP_SELECTED_ORGANIZATION_ID,
} from "constant";
import { getAppProjectsByIdAction } from "actions";
import store from "Store";
import { gradify } from "axios.config";
export const getAppAllOrganizations = () => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    const user = store.getState().loggedInUser?.user;
    let query;
    switch (user.role) {
      case "ultraAdmin":
        query = "organization";
        break;
      case "superAdmin":
        query = `organization?title=${user.system[0].organization.title}`;
        break;
      case "admin":
        query = `organization?title=${user.system[0].organization.title}`;
        break;
      //no default
    }
    const { data } = await gradify.get(query, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: SET_APP_ORGANIZATION_OPTIONS,
      payload: data.data,
    });
    if (user.role !== "ultraAdmin") {
      dispatch({
        type: SET_APP_SELECTED_ORGANIZATION_ID,
        payload: user.system[0].organization._id,
      });
      dispatch(getAppProjectsByIdAction(user.system[0].organization._id));
    }
  } catch (error) {
    dispatch({
      type: SET_APP_ORGANIZATION_OPTIONS,
      payload: [],
    });
  }
};
