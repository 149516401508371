import React from "react";
import {
  ContentHeader,
  ContentHeaderTitle,
  StudentHeaderControle,
  SearchStudent,
  StudentButton,
  UploadStudentButton,
  SearchStudentContainer,
  CreateStudentAbsoluteRow,
} from "components/styled.components";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  LabelStudent,
} from "components/styled.components/create.student";

import { MdAddCircle } from "react-icons/md";
import { FaFileUpload } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";

import SelectComponent from "components/control.component/select.component";
import { getALLUserList } from "actions";
import { useDispatch } from "react-redux";

const StudentHeader = ({
  categoryOptions,
  selectedCategoryId,
  onCategoryChanged,
  subCategoryOptions,
  selectedSubCategoryId,
  onSubCategoryChanged,
  searchStudentInput,
  onSearchStudentList,
  onAddStudentClick,
  onAwardPointsClicked,
  statusOptions,
  onStatusChanged,
  selectedStatusId,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <ContentHeader>
        <ContentHeaderTitle>STUDENT </ContentHeaderTitle>
      </ContentHeader>
      <CreateStudentFormRow>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Category</LabelStudent>
          <SelectComponent
            options={categoryOptions}
            noShowSlectText
            name="All"
            selectedValue={selectedCategoryId}
            selected={true}
            onChange={onCategoryChanged}
            style={{ minWidth: 180 }}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Sub Category</LabelStudent>

          <SelectComponent
            options={subCategoryOptions}
            noShowSlectText
            name="All"
            selected={true}
            selectedValue={selectedSubCategoryId}
            onChange={onSubCategoryChanged}
            style={{ minWidth: 180 }}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Status</LabelStudent>
          <SelectComponent
            options={statusOptions}
            noShowSlectText
            name="All"
            onChange={onStatusChanged}
            style={{ minWidth: 130 }}
          />
        </CreateStudentFormCol>
        <CreateStudentAbsoluteRow>
          <CreateStudentFormCol
            style={{
              borderLeftWidth: 2,
              borderColor: "#959393",
              // borderLeftStyle: "solid",
            }}
            Direction="column"
          >
            <LabelStudent>Tag</LabelStudent>
            <SelectComponent
              options={""}
              name="tag"
              onChange={() => {}}
              style={{ minWidth: 180, right: 0 }}
            />
          </CreateStudentFormCol>
        </CreateStudentAbsoluteRow>
      </CreateStudentFormRow>

      <StudentHeaderControle style={{ maxWidth: 1380 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            width: "65%",
          }}
        >
          <SearchStudentContainer>
            <BiSearchAlt
              spaceLeft="18px"
              style={{ position: "absolute", top: 11, left: 18 }}
              color={"#707070"}
            />
            <SearchStudent
              type="text"
              value={searchStudentInput}
              placeholder="Search Student"
              onChange={onSearchStudentList}
            />
          </SearchStudentContainer>
          <StudentButton
            style={{
              paddingRight: 4,
              paddingTop: 6,
              paddingBottom: 6,
              fontSize: 16,
            }}
            spaceLeft="50px"
            onClick={onAddStudentClick}
          >
            <span style={{ paddingRight: 18 }}>Add Student</span>{" "}
            <MdAddCircle size={32} />
          </StudentButton>
          <UploadStudentButton spaceLeft="30px">
            <span style={{ paddingRight: 25, whiteSpace: "nowrap" }}>
              Upload Students
            </span>
            <FaFileUpload size={28} />
          </UploadStudentButton>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            justifyContent: "flex-end",
            width: "32%",
          }}
        >
          <StudentButton
            style={{ borderRadius: 50, marginRight: 10, marginLeft: 10 }}
            onClick={onAwardPointsClicked}
          >
            <FaPlus style={{ marginRight: 5 }} size={27} />
            <span>Award Points</span>
          </StudentButton>
        </div>
      </StudentHeaderControle>
    </>
  );
};

export default StudentHeader;
