import produce from "immer";
import {
  Exp_LIST_PENDING,
  Exp_LIST_SUCCESS,
  Exp_LIST_ERROR,
} from "../../constant/experienceList";
const INITIAL_STATE = {
  isPending: false,
  isError: false,
  isSuccess: false,
  experienceList: [],
};

export const experienceListRefactorReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case Exp_LIST_PENDING:
        draft.isPending = true;
        break;
      case Exp_LIST_SUCCESS:
        draft.isPending = false;
        draft.isSuccess = true;
        draft.experienceList = action.payload;
        break;
      case Exp_LIST_ERROR:
        draft.isPending = false;
        draft.isSuccess = false;
        draft.isError = action.payload;
        draft.experienceList = [];
        break;
    }
  });
