import React, { useEffect, useState, useRef } from "react";
import { Checkbox } from "@material-ui/core";
import SelectComponent from "../control.component/select.component";
import {
  CreateOrganizationFormCol,
  CreateOrganizationContainer,
  CreateOrganizationForm,
  CreateOrganizationFormRow,
  OrganizationInput,
  LabelOrganization,
  SwitchLabelOrganization,
  OrganizationImage,
  OrganizationEditButtton,
  OrganizationEditButttonText,
  OrganizationEditButttonIcon,
  OrgainzationsListContainer,
  OrgainzationsListCloseProject,
  OrgainzationsListProject,
  OrganizationButon,
} from "./AddOrganization.style";
import WarningPopup from "../warningPopup/WarningPopup";
import { FaWindowClose } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import organizationImage from "../../assets/image/organization-image.png";

import {
  PROJECT_TYPE,
  CONDITION,
  STATUS,
  rewardSystem,
  surveySystem,
  COLLABORATIVE,
  chartView,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationAction } from "../../actions/organizationActions";
import {
  addOrganizationProject,
  resetProject,
} from "../../actions/projectListAction";
import axios from "axios";
import AdvanceSelect from "../../components/control.component/advenceSelect";
import { gradify } from "../../axios.config";
import Confirm from "components/display.component/confirm.components";
import AnimationError from "components/animation.icon/animation.error";
const AddProjectPopup = ({ close, id }) => {
  const fileRef = useRef(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [projectInfo, setProjectInfo] = useState({
    name: "",
    project_type: PROJECT_TYPE[0]._id,
    condition: CONDITION[0]._id,
    status: STATUS[0]._id,
    reward_system: false,
    localized_reward: false,
    survey_system: false,
    localize_survey: false,

    active: false,
    image: organizationImage,
    organization: [id],

    schoolDistrict: null,

    university: null,
  });
  const [collaborative, setCollaborative] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [selectedOrganzation, setSelectedOrganization] = useState([id]);
  const [chartViewState, setChartViewState] = useState("Standard");
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.loggedInUser);
  const { organizations } = useSelector((state) => state.organization);
  const { loading, error, addProject } = useSelector(
    (state) => state.organizationProjectList
  );
  const [showFormWarning, setShowFormWarning] = useState(false);
  useEffect(() => {
    dispatch(getOrganizationAction(user));
  }, [dispatch]);

  useEffect(() => {
    if (addProject) {
      close();
      dispatch(resetProject());
    }
  }, [addProject]);

  useEffect(() => {
    if (organizations.length > 0) {
      const result = organizations.map((item) => {
        return {
          _id: item._id,
          name: item.title,
        };
      });

      setOrganizationList(result);
    }
  }, [organizations]);

  useEffect(() => {
    if (projectInfo.organization.length > 0) {
      const result = projectInfo.organization.map((item) => {
        return organizations.find((org) => org._id === item);
      });

      setSelectedOrganization(result);
    } else {
      const result = organizations.find((org) => org._id === id);
      setSelectedOrganization([result]);
    }
  }, [projectInfo.organization]);

  const closeWarningForm = () => {
    setShowFormWarning(false);
    close();
  };
  const closeWarning = () => {
    setShowFormWarning(false);
  };

  const closeForm = (e) => {
    setShowFormWarning(true);
  };

  const closeEditForm = () => {
    setShowEditForm(!showEditForm);
  };
  const loadOptionHandler = async (inputText, callback) => {
    const res = await gradify.get(`schooldistricts/search?term=${inputText}`);

    callback(
      res.data.map((item) => {
        return {
          label: item.agancy_name,
          value: item._id,
        };
      })
    );
  };

  return (
    <CreateOrganizationContainer className="form-Organization-add">
      <CreateOrganizationForm>
        <CreateOrganizationFormRow style={{ paddingLeft: 40 }}>
          <CreateOrganizationFormCol
            alignment="flex-start"
            style={{ paddingLeft: 0, position: "relative" }}
          >
            <OrganizationImage src={projectInfo.image} alt="gradegy" />
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileRef}
              onChange={async (e) => {
                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                const res = await axios.post(
                  `https://apigrad.azurewebsites.net/api/v2/photos`,
                  formData,
                  { headers: { Authorization: "Bearer " + token } }
                );
                setProjectInfo({
                  ...projectInfo,
                  image: `https://apigrad.azurewebsites.net${res.data.url}`,
                });
              }}
            />
            <OrganizationEditButtton
              onClick={() => {
                fileRef.current.click();
              }}
            >
              <OrganizationEditButttonText>Edit</OrganizationEditButttonText>
              <OrganizationEditButttonIcon>
                <MdEdit color="#34C38F" size={12} />
              </OrganizationEditButttonIcon>
            </OrganizationEditButtton>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol
            Direction="column"
            style={{ alignItems: "center", width: "100%" }}
          >
            <CreateOrganizationFormCol
              Direction="row"
              alignment="sttart"
              style={{ alignItems: "center" }}
            >
              <OrganizationInput
                Width="485px"
                Height="39px"
                FontFamily="Rajdhani"
                Size="32px"
                Weight="700"
                name="ProjectName"
                placeholder="Name of Project"
                value={projectInfo.name}
                onChange={(e) => {
                  setProjectInfo({ ...projectInfo, name: e.target.value });
                }}
              />
            </CreateOrganizationFormCol>
            <CreateOrganizationFormCol
              Direction="column"
              style={{ paddingLeft: 0, width: 485 }}
            ></CreateOrganizationFormCol>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
        <div style={{ display: "flex" }}>
          <div
            style={{ display: "flex", maxWidth: 575, flexDirection: "column" }}
          >
            <CreateOrganizationFormRow
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                marginTop: 16,
                flexWrap: "wrap",
              }}
            >
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Project Type</LabelOrganization>
                <SelectComponent
                  options={PROJECT_TYPE}
                  selectedValue={PROJECT_TYPE[0]._id}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      project_type: e.target.value,
                    });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              {projectInfo.project_type == "Secondary" ? (
                <CreateOrganizationFormCol Direction="column">
                  <LabelOrganization>School District</LabelOrganization>
                  <AdvanceSelect
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="schoolDistrict"
                    loadOptions={loadOptionHandler}
                    onChange={(e) =>
                      setProjectInfo({
                        ...projectInfo,
                        schoolDistrict: e ? e.value : null,
                      })
                    }
                  />{" "}
                </CreateOrganizationFormCol>
              ) : null}

              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Condition</LabelOrganization>
                <SelectComponent
                  options={CONDITION}
                  selectedValue={CONDITION[0]._id}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      condition: e.target.value,
                    });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Status</LabelOrganization>
                <SelectComponent
                  options={STATUS}
                  selectedValue={STATUS[0]._id}
                  onChange={(e) => {
                    setProjectInfo({ ...projectInfo, status: e.target.value });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Reward System</LabelOrganization>
                <SelectComponent
                  options={rewardSystem}
                  selectedValue={rewardSystem[1]._id}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      reward_system: e.target.value === "Yes" ? true : false,
                    });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Localized Rewards</LabelOrganization>
                <Checkbox
                  color="default"
                  size="small"
                  style={{ width: 36 }}
                  checked={projectInfo.localized_reward}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      localized_reward: !projectInfo.localized_reward,
                    });
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Survey System</LabelOrganization>
                <SelectComponent
                  options={surveySystem}
                  selectedValue={surveySystem[1]._id}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      survey_system: e.target.value === "Yes" ? true : false,
                    });
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Localized Survey</LabelOrganization>
                <Checkbox
                  color="default"
                  size="small"
                  style={{ width: 36 }}
                  checked={projectInfo.localize_survey}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      localize_survey: !projectInfo.localize_survey,
                    });
                  }}
                  value={""}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Localized Survey</LabelOrganization>
                <Checkbox
                  color="default"
                  size="small"
                  style={{ width: 36 }}
                  checked={projectInfo.localize_survey}
                  onChange={(e) => {
                    setProjectInfo({
                      ...projectInfo,
                      localize_survey: !projectInfo.localize_survey,
                    });
                  }}
                  value={""}
                />
              </CreateOrganizationFormCol>
            </CreateOrganizationFormRow>
            <CreateOrganizationFormRow
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                marginTop: 10,
                flexWrap: "wrap",
              }}
            >
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Chart View</LabelOrganization>
                <SelectComponent
                  options={chartView}
                  selectedValue={chartView[0]._id}
                  onChange={(e) => {
                    setChartViewState(e.target.value);
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
            </CreateOrganizationFormRow>
          </div>
          <div
            style={{ width: 1, backgroundColor: "#707070", height: 160 }}
          ></div>
          <div style={{ display: "flex", maxWidth: 350 }}>
            <CreateOrganizationFormRow
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                marginTop: 20,
                flexWrap: "wrap",
              }}
            >
              <CreateOrganizationFormCol Direction="column">
                <LabelOrganization>Collaborative</LabelOrganization>
                <SelectComponent
                  options={COLLABORATIVE}
                  selectedValue={COLLABORATIVE[1]._id}
                  onChange={(e) => {
                    e.target.value === "Yes"
                      ? setCollaborative(true)
                      : setCollaborative(false);
                  }}
                  style={{
                    paddingTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 6,
                    minWidth: 129,
                    fontSize: 10,
                    backgroundPosition: "bottom 10px right 6px",
                  }}
                />
              </CreateOrganizationFormCol>
              {collaborative && (
                <CreateOrganizationFormCol Direction="column">
                  <LabelOrganization>Organization</LabelOrganization>
                  <SelectComponent
                    options={organizationList}
                    selected={true}
                    name="Organization"
                    onChange={(e) => {
                      if (e.target._id !== "") {
                        setProjectInfo({
                          ...projectInfo,
                          organization: [
                            ...projectInfo.organization,
                            e.target.value,
                          ],
                        });
                      }
                    }}
                    style={{
                      marginBottom: 15,
                      paddingTop: 6,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 6,
                      minWidth: 129,
                      fontSize: 10,
                      backgroundPosition: "bottom 10px right 6px",
                    }}
                  />
                </CreateOrganizationFormCol>
              )}

              <CreateOrganizationFormCol
                Direction="column"
                style={{ width: "94%" }}
              >
                <LabelOrganization>Organizations</LabelOrganization>
                <OrgainzationsListContainer>
                  {selectedOrganzation.map(
                    (item) =>
                      item && (
                        <OrgainzationsListProject key={item._id}>
                          <span>{item.title}</span>
                          <OrgainzationsListCloseProject
                            onClick={() => {
                              setProjectInfo({
                                ...projectInfo,
                                organization: projectInfo.organization.filter(
                                  (id) => id !== item._id
                                ),
                              });
                            }}
                          >
                            <FaWindowClose spaceLeft="18px" color={"#707070"} />
                          </OrgainzationsListCloseProject>
                        </OrgainzationsListProject>
                      )
                  )}
                </OrgainzationsListContainer>
              </CreateOrganizationFormCol>
            </CreateOrganizationFormRow>
          </div>
        </div>
        <CreateOrganizationFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 30,
            marginBottom: 30,
            justifyContent: "center",
          }}
        >
          <CreateOrganizationFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <OrganizationButon
              onClick={() => {
                const projectInfoDetails = {
                  ...projectInfo,
                  chartView: chartViewState,
                };
                dispatch(addOrganizationProject(projectInfoDetails));
              }}
              disabled={loading}
            >
              {" "}
              {loading ? "Sending..." : "SAVE"}
            </OrganizationButon>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol style={{ paddingLeft: 30 }}>
            <OrganizationButon onClick={closeForm}>Cancel</OrganizationButon>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
      </CreateOrganizationForm>
      {showFormWarning ? (
        <WarningPopup
          close={closeWarningForm}
          text={"Clear All Changes"}
          reject={closeWarning}
          accept={() => {}}
        />
      ) : null}
      {false && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {"error"}
        </div>
      )}
      {error ? (
        <>
          {" "}
          <Confirm message={error}>
            <AnimationError />
          </Confirm>
        </>
      ) : null}
    </CreateOrganizationContainer>
  );
};

export default AddProjectPopup;
