import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gradify } from "../../axios.config";
import { updateCompletionExperienceUserAction } from "../../actions/experienceUserActions";
import { listUserExperienceActions } from "../../actions/listUserExperienceAction";
import CustomSwitch from "../../components/control.component/switch/CustomSwitch";
import { userSystemAction } from "../../actions/userSystemAction";
import { useEffect } from "react";

import { pmChartInfoAction, progressAction } from "../../actions/pmChartAction";
import {
  momentumAction,
  newMomentumAction,
} from "../../actions/momentumAction";
import {
  getUserExperienceCompleteListAction,
  getUserExperienceList,
  getUserExperiencePassDueListAction,
} from "actions/experienceAction";
import {
  getAllUserXpByOrganizationProject,
  getAllXpsBounes,
} from "actions/xpsAction";
import { getScoresAction } from "actions/scores";
const ExperienceItem = ({ item, user, system }) => {
  const [completeState, setCompleteState] = useState(false);
  const [chagneState, setChagneState] = useState(false);
  const [changeExpState, setChangeExpState] = useState(null);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { organization, project, category, subCategory } = useSelector(
    (state) => state.userSystem
  );

  useEffect(() => {
    // const exist = item.users?.find((it) => it.user == user._id);
    // if (exist) {
    //   if (exist.complete) {
    //     setCompleteState(true);
    //   } else {
    //     setCompleteState(false);
    //   }
    // } else {
    //   setCompleteState(false);
    // }
    if (item) {
      setCompleteState(
        item.experienceList ? item.experienceList.complete : false
      );
      setChangeExpState(item.experienceList);
    }
  }, [item]);
  useEffect(() => {
    if (organization) {
      if (system) {
        dispatch(userSystemAction(system));
      }
    }
  }, [organization, category, project, subCategory, system]);

  useEffect(() => {
    (async () => {
      if (chagneState) {
        if (completeState) {
          await gradify.post(
            `xp`,
            {
              amount: item.xpCompletion,
              user: user?._id,
              experience: item._id,
              organization: organization,
              project: project,
              category: category,
              subCategory: subCategory,
            },
            {
              headers: { Authorization: "Bearer " + token },
            }
          );
          // add coins
          await gradify.post(
            `coins`,
            { amount: item.coins, user: user._id, experience: item._id },
            {
              headers: { Authorization: "Bearer " + token },
            }
          );

          dispatch(
            getAllUserXpByOrganizationProject(
              user._id,
              organization?._id,
              project?._id
            )
          );
        } else {
          //if toggle is false the xp and coins should be deleted
          await gradify.delete(`xp/${user._id}/${item._id}`, {
            headers: { Authorization: "Bearer " + token },
          });
          await gradify.delete(`coins/${user._id}/${item._id}`, {
            headers: { Authorization: "Bearer " + token },
          });
          dispatch(
            progressAction(
              user._id,
              project?._id,
              category ? category._id : null,
              "2022-01-01"
            )
          );
          dispatch(
            getAllUserXpByOrganizationProject(
              user._id,
              organization?._id,
              project?._id
            )
          );
        }
      }
    })();
  }, [completeState, chagneState]);

  const changeCompeleteSwitchHandler = async () => {
    const updateComplete = await gradify.put(
      `experiencestudentcomplete/updateexpstudent?experience=${item._id}&user=${
        user._id
      }&complete=${!completeState}`
    );
    dispatch(
      progressAction(
        user._id,
        project?._id,
        category ? category._id : null,
        "2022-01-01"
      )
    );
    dispatch(
      newMomentumAction(
        user?._id,
        project?._id,
        category ? category?._id : null,
        15
      )
    );

    dispatch(
      getScoresAction(
        user?._id,
        project ? project : null,
        70,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100,
        100
      )
    );

    setCompleteState(!completeState);
    setChangeExpState(updateComplete.data.data);
    setChagneState(true);
  };

  return (
    <div className="st-info-experience-box-container">
      <div>
        <CustomSwitch
          isToggle={completeState}
          onChangeToggle={changeCompeleteSwitchHandler}
        />
      </div>

      <p
        style={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {item.title}
      </p>
      <p
        style={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {item.description}
      </p>
      <p>
        {changeExpState
          ? new Date(changeExpState.updatedAt).toLocaleDateString()
          : "---"}
      </p>
    </div>
  );
};

export default ExperienceItem;
