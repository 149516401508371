import {
  SET_SELECTED_ADMIN_DETAIL,
  SET_ADMIN_DETAIL_PAGE_STATUS,
  SET_ADMIN_DETAIL_SCHOOL_DISTRICT_OPTIONS,
  SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID,
  SET_ADMIN_DETAIL_HIGHSCHOOL_LIST,
  ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL,
  DELETE_ADMIN_DETAIL_HIGHSCHOOL,
  CHANGE_HIGHSCHOOL_ACCESS_PERMISSION,
  SET_ADMIN_DETAIL_SPECIAL_PROJECTS_OPTIONS,
  SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID,
  DELETE_ADMIN_DETAIL_SPECIAL_PROJECT,
  CHANGE_SPECIAL_PROJECT_ACCESS_PERMISSION,
  SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST,
  ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT,
  CHANGE_UNIVERSITY_ACCESS_PERMISSION,
  SET_ADMIN_DETAIL_UNIVERSITY_OPTIONS,
  SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID,
  SET_ADMIN_DETAIL_UNIVERSITY_LIST,
  ADD_ADMIN_DETAIL_NEW_UNIVERSITY,
  DELETE_ADMIN_DETAIL_UNIVERSITY,
  SET_EDIT_PHOTO_URL_LOADING,
  SET_EDIT_PHOTO_URL_SUCCESS,
  PHOTO_EDIT_URL_FAIL,
} from "constant";
import produce from "immer";
import organizationImage from "assets/image/defualt-icon.png";
const INITIAL_STATE = {
  adminDetail: {},
  adminDetailStatus: "DEFAULT",
  schoolDistrictOptions: [],
  specialProjectsOptions: [],
  universityOptions: [],
  selectedHighSchoolId: "",
  highSchoolsList: {},
  specialProjectList: {},
  selectedSpecialProjectId: "",
  universityList: {},
  selectedUniversityId: "",
  adminDetailPhoto: { isLoading: false, url: organizationImage },
};

const adminDetailReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_EDIT_PHOTO_URL_LOADING:
        draft.adminDetailPhoto.isLoading = action.payload.isLoading;
        break;
      case SET_EDIT_PHOTO_URL_SUCCESS:
        draft.adminDetailPhoto = { isLoading: false, url: action.payload.url };
        break;
      case PHOTO_EDIT_URL_FAIL:
        draft.adminDetailPhoto = { isLoading: false, url: null };
        break;
      case SET_SELECTED_ADMIN_DETAIL:
        draft.adminDetail = action.payload;
        break;
      case SET_ADMIN_DETAIL_PAGE_STATUS:
        draft.adminDetailStatus = action.payload;
        break;
      case SET_ADMIN_DETAIL_SCHOOL_DISTRICT_OPTIONS:
        draft.schoolDistrictOptions = action.payload;
        break;
      case SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID:
        draft.selectedHighSchoolId = action.payload;
        break;
      case SET_ADMIN_DETAIL_HIGHSCHOOL_LIST:
        draft.highSchoolsList = action.payload;
        break;
      case ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL:
        draft.highSchoolsList[action.payload.newHighschool._id] =
          action.payload.newHighschool;
        break;
      case DELETE_ADMIN_DETAIL_HIGHSCHOOL:
        delete draft.highSchoolsList[action.payload];
        break;
      case CHANGE_HIGHSCHOOL_ACCESS_PERMISSION:
        draft.highSchoolsList[draft.selectedHighSchoolId].access = {
          ...draft.highSchoolsList[draft.selectedHighSchoolId]?.access,
          [action.payload.accessName]: action.payload.accessValue,
        };
        break;
      case SET_ADMIN_DETAIL_SPECIAL_PROJECTS_OPTIONS:
        draft.specialProjectsOptions = action.payload;
        break;
      case SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID:
        draft.selectedSpecialProjectId = action.payload;
        break;
      case SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST:
        draft.specialProjectList = action.payload;
        break;
      case ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT:
        draft.specialProjectList[action.payload.newSpecialProject._id] =
          action.payload.newSpecialProject;
        break;
      case DELETE_ADMIN_DETAIL_SPECIAL_PROJECT:
        delete draft.specialProjectList[action.payload];
        break;
      case CHANGE_SPECIAL_PROJECT_ACCESS_PERMISSION:
        draft.specialProjectList[draft.selectedSpecialProjectId].access = {
          ...draft.specialProjectList[draft.selectedSpecialProjectId]?.access,
          [action.payload.accessName]: action.payload.accessValue,
        };
        break;
      case SET_ADMIN_DETAIL_UNIVERSITY_OPTIONS:
        draft.universityOptions = action.payload;
        break;
      case SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID:
        draft.selectedUniversityId = action.payload;
        break;
      case SET_ADMIN_DETAIL_UNIVERSITY_LIST:
        draft.universityList = action.payload;
        break;
      case ADD_ADMIN_DETAIL_NEW_UNIVERSITY:
        draft.universityList[action.payload.newUniversity._id] =
          action.payload.newUniversity;
        break;
      case DELETE_ADMIN_DETAIL_UNIVERSITY:
        delete draft.universityList[action.payload];
        break;
      case CHANGE_UNIVERSITY_ACCESS_PERMISSION:
        draft.universityList[draft.selectedUniversityId].access = {
          ...draft.universityList[draft.selectedUniversityId]?.access,
          [action.payload.accessName]: action.payload.accessValue,
        };
        break;
      // no default
    }
  });
export default adminDetailReducer;
