import {
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_REQUEST,
  CATEGORY_PROJECT_LIST_REQUEST,
  CATEGORY_PROJECT_LIST_SUCCESS,
  CATEGORY_PROJECT_LIST_FAIL,
  CATEGORY_PROJECT_ADD_FAIL,
  CATEGORY_PROJECT_ADD_REQUEST,
  CATEGORY_PROJECT_ADD_SUCCESS,
  CATEGORY_PROJECT_DELETE_FAIL,
  CATEGORY_PROJECT_DELETE_REQUEST,
  CATEGORY_PROJECT_DELETE_SUCCESS,
  CATEGORY_PROJECT_UPDATE_PENDING,
  CATEGORY_PROJECT_UPDATE_SUCCESS,
  CATEGORY_PROJECT_UPDATE_FAIL,
} from "../constant/category";

export const categoryListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { ...state, loading: true, categories: [] };
    case CATEGORY_LIST_SUCCESS:
      return { ...state, loading: false, categories: action.payload.data };
    case CATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        categories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const categoryProjectListReducer = (
  state = { categories: [] },
  action
) => {
  switch (action.type) {
    case CATEGORY_PROJECT_LIST_REQUEST:
      return { loading: true, categories: [] };
    case CATEGORY_PROJECT_LIST_SUCCESS:
      return { loading: false, categories: action.payload };
    case CATEGORY_PROJECT_LIST_FAIL:
      return { loading: false, categories: [], error: action.payload };
    case CATEGORY_PROJECT_ADD_REQUEST:
      return { ...state, loading: true };
    case CATEGORY_PROJECT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: [...state.categories, action.payload],
      };
    case CATEGORY_PROJECT_ADD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CATEGORY_PROJECT_DELETE_REQUEST:
      return { ...state, loading: true };
    case CATEGORY_PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter(
          (item) => item._id !== action.payload
        ),
      };
    case CATEGORY_PROJECT_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CATEGORY_PROJECT_UPDATE_PENDING:
      return { ...state, loading: true };
    case CATEGORY_PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.map((item) =>
          item._id === action.payload._id ? (item = action.payload) : item
        ),
      };

    case CATEGORY_PROJECT_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
