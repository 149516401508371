import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_CREATE_SUCCESS,
  USER_CREATE_NEW,
  USER_EXPERIENCE_LIST_FAIL,
  USER_EXPERIENCE_LIST_SUCCESS,
  USER_EXPERIENCE_LIST_REQUEST,
  USER_LEADERBOARD_REQUEST,
  USER_LEADERBOARD_SUCCESS,
  USER_SET_SORT_TYPE,
  ADD_USER_XP,
  USER_LEADERBOARD_FAIL,
} from "constant/user";
import store from "Store";
import { gradify } from "axios.config";
export const setStudentSortType = (dispatch, sort) => {
  dispatch({
    type: USER_SET_SORT_TYPE,
    payload: sort,
  });
};
export const createNewUser =
  (userInformation) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      await gradify.post("users", userInformation, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      const { data } = await gradify.get("users?role=user&active=true", {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
      dispatch({
        type: USER_CREATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const changeCreateNew = () => (dispatch) => {
  dispatch({
    type: USER_CREATE_NEW,
  });
};
export const getALLUserList = (params) => async (dispatch) => {
  const user = store.getState().loggedInUser?.user;
  const token = store.getState().auth.token;
  const selectedProjectId = store.getState().app.selectedProjectId;
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    console.log(
      "url: ",
      `userread/getUsersByProjectPaginated?projectId=${selectedProjectId}&${params}`
    );
    const { data } = await gradify.get(
      `userread/getUsersByProjectPaginated?projectId=${selectedProjectId}&${params}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data.data[0],
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllUsersByProject =
  (projectId) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST });
      const { data } = await gradify.get(`users?project=${projectId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//leader board
export const getLeaderBoard =
  (project, category, categories) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LEADERBOARD_REQUEST,
      });

      let query;
      if (!category) {
        query = `users/leaderboardaccountbase?project=${project?._id}`;
      } else if (category && categories.length < 1) {
        query = `users/leaderboardaccountbase?project=${project?._id}&category=${category?._id}`;
      } else if (category && categories.length > 0) {
        const categoriesIds = categories.map((i) => i._id);
        query = `users/leaderboardaccountbase?project=${project?._id}&category=${categoriesIds}`;
      }
      const { data } = await gradify.get(query);

      // dispatch({
      //   type: LEADER_BOARD_ACCOUNT_BASE_SUCCESS,
      //   payload: formatLeaderBoardList(data.data),
      // });

      // const { data } = await gradify.get(
      //   `users?system.project=${projectId._id}&role=user`,
      //   {
      //     headers: { Authorization: "Bearer " + getState().auth.token },
      //   }
      // );

      dispatch({
        type: USER_LEADERBOARD_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      console.log("======= error ====", error);
      // dispatch({
      //   type: USER_LEADERBOARD_FAIL,
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
    }
  };

export const getAllUsersByCategory =
  (categoryId) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST });
      const { data } = await gradify.get(`users?category=${categoryId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllUsersBySubCategory =
  (subCategoryId) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST });
      const { data } = await gradify.get(`users?subCategory=${subCategoryId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllExperienceUserListAction =
  (project, category, subCategory) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_EXPERIENCE_LIST_REQUEST,
      });
      let res;
      if (!project && !category && !subCategory) {
        res = await gradify.get(`users?role=user&active=true`, {
          headers: { Authorization: "Bearer " + getState().auth.token },
        });
      }
      if (project && !category && !subCategory) {
        res = await gradify.get(
          `users?project=${project}&role=user&active=true`,
          {
            headers: { Authorization: "Bearer " + getState().auth.token },
          }
        );
      }
      if (project && category && !subCategory) {
        res = await gradify.get(
          `users?project=${project}&category=${category}&role=user&active=true`,
          {
            headers: { Authorization: "Bearer " + getState().auth.token },
          }
        );
      }
      if (project && category && subCategory) {
        res = await gradify.get(
          `users?project=${project}&category=${category}&subCategory=${subCategory}&role=user&active=true`,
          {
            headers: { Authorization: "Bearer " + getState().auth.token },
          }
        );
      }

      dispatch({
        type: USER_EXPERIENCE_LIST_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: USER_EXPERIENCE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addXpToUserAction = (xpInfo) => async (dispatch, getState) => {
  try {
    const { data } = await gradify.post("xp", xpInfo, {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });
    dispatch({
      type: ADD_USER_XP,
      payload: data.data,
    });
  } catch (error) {
    console.log(error.message);
  }
};
