import { GET_LOGIN_LOGGED } from '../constant/loginlogged';
import {gradify} from '../axios.config';
export const getAllUserLogin = (userId) => async (dispatch,getState) => {
    try {
        const {data} = await gradify.get(`login?user=${userId}`,{headers:{"Authorization": "Bearer "+ getState().auth.token }});
        dispatch({
            type:GET_LOGIN_LOGGED,
            payload:{
                logins:data.data,
                loginCount:data.count
            }
        })
    } catch (error) {
        
    }
}