import {
    EXPERIENCETYPE_LIST_REQUEST,
    EXPERIENCETYPE_LIST_FAIL,
    EXPERIENCETYPE_LIST_SUCCESS,

    EXPERIENCETYPE_DETAILS_REQUEST,
    EXPERIENCETYPE_DETAILS_SUCCESS,
    EXPERIENCETYPE_DETAILS_FAIL
  } from "../constant/experienceType";
  import { gradify } from "../axios.config";
  
  export const getExperienceTypeList = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCETYPE_LIST_REQUEST,
      });
      const { data } = await gradify.get("experiencetype", {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: EXPERIENCETYPE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCETYPE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const getExperienceTypeDetails = (id) => async (dispatch,getState) => {
    // dispatch(getExperienceTypeList());
     try {
      dispatch({
        type:EXPERIENCETYPE_DETAILS_REQUEST,
      
    })
    const {data:{data}} = await gradify.get(`experiencetype/${id}`, {
      headers: { Authorization: "Bearer " + getState().auth.token }
    })
    dispatch({
      type:EXPERIENCETYPE_DETAILS_SUCCESS,
      payload:data
    })
     } catch (error) {
      dispatch({
        type: EXPERIENCETYPE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
     }
  }