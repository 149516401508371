import styled from 'styled-components';


export const EditButtonContainer = styled.button`
    display: flex;
    height: 40px;
    border-radius: 4px;
    width: 80px;
    background-color: #34C38F;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
    border: none;

`;


export const EditButtonIcon = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;

`;

export const EditButtonText = styled.p`
font-family: 'Poppins';
font-weight: 400;
font-size: 15px;
color:#fff;
margin: 0;
padding-right: 5px;

`;