import { ADD_ADMIN_DETAIL_NEW_UNIVERSITY } from "constant";
import store from "Store";
export const addNewUniversityAction = (dispatch, newUniversity) => {
  const adminDetail = store.getState().adminDetail.adminDetail;
  const universityList = store.getState().adminDetail.universityList;

  const data = {
    ...newUniversity,
    organizationId: adminDetail.system[0].organization._id,
  };
  const existedUniversity = Object.values(universityList).filter(
    (item) => item.projectId === newUniversity.projectId
  );

  if (!existedUniversity.length)
    dispatch({
      type: ADD_ADMIN_DETAIL_NEW_UNIVERSITY,
      payload: { newUniversity: data },
    });
};
