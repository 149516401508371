import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 430px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  z-index: 1;
  background-color: white;
  flex: 2;
  margin-right: 26px;
  overflow: scroll;
  @media (max-width: 1650px) {
    width: 360px;
  }
  @media (max-width: 1500px) {
    width: 90%;
    min-width:300px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;
export const SearchBox = styled.input`
  width: 227px;
  height: 37px;
  border-radius: 30px;
  border: 1px solid #ced4da;
  text-align: center;
  color: #74788d;
  font-size: 13px;
  font-weight: bold;
  font-family: "Rajdhani";
  &::placeholder {
    text-align: center;
    color: #74788d;
  }
  margin-top: 21px;
  margin-left: 18px;
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  font-family: "Rajdhani";
  line-height: 45px;
  text-align: center;
`;
export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StatusText = styled.div`
  margin-top: 21px;
  margin-right: 76px;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: center;
`;
export const ListWrapper = styled.table`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
`;
export const HeadTr = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  padding: 20px 0 0 30px;
`;
export const HeadTh = styled.th`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  color: #000000;
  margin-bottom: 12px;
  flex: 1;
`;
