import {
  SET_SHOP_LIST,
  SET_SHOP_PAGE_STATUS,
  SHOP_SET_SEARCH_INPUT,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const getALLShopItemListAction =
  (sortType, page, searchInput, category) => async (dispatch) => {
    try {
      const token = store.getState().auth.token;
      const selectedOrganizationId =
        store.getState().app.selectedOrganizationId;
      const selectedProjectId = store.getState().app.selectedProjectId;

      dispatch({
        type: SET_SHOP_PAGE_STATUS,
        payload: "SHOPLIST_LOADING",
      });
      const sort = sortType?.isReversed ? `-${sortType?.type}` : sortType?.type;
      let query;
      if (searchInput) {
        if (category) {
          query = `shopitems/search?term=${searchInput}${
            selectedProjectId ? `&project=${selectedProjectId}` : ""
          }&category=${category}`;
        } else {
          query = `shopitems/search?term=${searchInput}${
            selectedProjectId ? `&project=${selectedProjectId}` : ""
          }`;
        }
      } else {
        if (category && category !== "All") {
          console.log("category", category);
          query = `shopitems?${
            selectedOrganizationId
              ? `&organization=${selectedOrganizationId}`
              : ""
          }${
            selectedProjectId ? `&project=${selectedProjectId}` : ""
          }&category=${category}&sort=${sort}&page=${page}`;
        } else if (category === "All" || category == undefined) {
          console.log("s category", category);
          query = `shopitems?${
            selectedOrganizationId
              ? `&organization=${selectedOrganizationId}`
              : ""
          }${
            selectedProjectId ? `&project=${selectedProjectId}` : ""
          }&sort=${sort}&page=${page}`;
        }
      }

      const { data } = await gradify.get(query, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("shop", data);

      dispatch({
        type: SET_SHOP_LIST,
        payload: data,
      });
      dispatch({
        type: SET_SHOP_PAGE_STATUS,
        payload: "DEFAULT",
      });
    } catch (error) {
      console.log(error, "errorrrrr");
      // what's in error?
    }
  };
