import React, { useState, useEffect, useContext } from "react";
import "./photosubmission.css";
import CustomSwitch from "../control.component/switch/CustomSwitch";
import { IconButton } from "@material-ui/core";
import { FaTrashAlt } from "react-icons/fa";
import PolygonCharts from "../charts/PolygonCharts";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { gradify } from "../../axios.config";
import {
  getAllExperienceUserActions,
  updateCompletionExperienceUserAction,
} from "../../actions/experienceUserActions";
import { getExperienceDetailsItemAction } from "actions/experienceAction";
import { progressAction } from "actions/pmChartAction";
import { newMomentumAction } from "actions/momentumAction";
import { getAllUserXpByOrganizationProject } from "actions/xpsAction";
import { changeCompleteReivewAction } from "actions/newExperienceReview/newExpereinceReview.action";
const PhotoItem = ({ item, experience, index , imageClicked }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [togglePhotoState, setTogglePhotoState] = useState(false);

  useEffect(() => {
    if (!!item) {
      setTogglePhotoState(item.complete?.complete);
    }
  }, [item]);

  const changeToggelHandler = async () => {
    const updateComplete = await gradify.put(
      `experiencestudentcomplete/updateexpstudent?experience=${
        experience?._id
      }&user=${item?._id}&complete=${!togglePhotoState}`
    );
    dispatch(
      progressAction(
        item._id,
        experience.project,
        experience.category ? experience.category : null,
        "2022-01-01"
      )
    );
    dispatch(
      newMomentumAction(
        item._id,
        experience.project,
        experience.category ? experience.category : null,
        15
      )
    );
    if (!togglePhotoState) {
      await gradify.post(
        `xp`,
        {
          amount: experience.xpCompletion,
          user: item?._id,
          experience: experience?._id,
          organization: experience.organization,
          project: experience.project,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      await gradify.post(
        `coins`,
        {
          amount: experience.coins,
          user: item._id,
          experience: experience?._id,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      dispatch(
        getAllUserXpByOrganizationProject(
          item._id,
          experience.organization,
          experience.project
        )
      );
    } else {
      await gradify.delete(`xp/${item._id}/${experience._id}`, {
        headers: { Authorization: "Bearer " + token },
      });
      await gradify.delete(`coins/${item._id}/${experience?._id}`, {
        headers: { Authorization: "Bearer " + token },
      });
      dispatch(
        getAllUserXpByOrganizationProject(
          item._id,
          experience.organization,
          experience.project
        )
      );
    }
    setTogglePhotoState(!togglePhotoState);
    dispatch(changeCompleteReivewAction(item._id, updateComplete.data.data));


  };

  return (
    <div className="photo-item-container" >
      <img src={`${item?.photos?.photo}`} alt="photo submisson"  onClick={imageClicked} />
        {/* <div className="photo-item-overlay"  ></div> */}
        <div className="photo-item-control">
          <CustomSwitch
            style={{ position: "relative", top: 12, left: 8 }}
            isToggle={togglePhotoState}
            onChangeToggle={changeToggelHandler}
          />
          <IconButton
            onClick={async () => {
              const resCompletion = await gradify.delete(
                `experiencestudentcomplete/detelePhoto/${item._id}/${experience._id}`,
                { user: item._id, experience: experience._id },
                {
                  headers: { Authorization: "Bearer " + token },
                }
              );
              console.log(resCompletion)
              const resDeletePhoto = await gradify.delete(
                `experiencephoto/deletePhoto/${item._id}/${experience._id}`,
                { user: item._id, experience: experience._id },
                {
                  headers: { Authorization: "Bearer " + token },
                }
              );
              dispatch(getExperienceDetailsItemAction(experience._id));
            }}
          >
            <FaTrashAlt color="#fff" />
          </IconButton>
        </div>
        <div className="photo-item-profile">
          <PolygonCharts
            studentPic={item?.profilePicture}
            alt="student picture"
            style={{
              transform: "scale(0.2)",
              position: "absolute",
              top: -80,
              left: -100,
            }}
          />
          <p>{item?.firstName + " " + item.lastName}</p>
        </div>
      </div>
      
  );
};

export default PhotoItem;