import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 430px;
  flex-direction: column;
  box-shadow:0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  gap: 12px;
  background-color: white;
  @media (max-width: 1650px) {
    width: 360px;
  }
  @media (max-width: 1500px) {
    width: 43%;
  }
`;
export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 390px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  flex: 2;
  margin-top: 12px;
  @media (max-width: 1650px) {
    width: 340px;
  }
`;
export const InfoTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  text-align: center;
  @media (max-width: 1300px) {
    font-size: 24px;
  }
`;
export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 13px;
`;
export const InfoText = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 12px;
  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;
export const InfoTextArea = styled.input`
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  height: 30px;
  margin-right: 12px;
  text-align: center;
  width: 66px;
  border: 1px solid gray;
  border-radius: 5px;
  text-align: center;
  font-size:13px;
  & ::placeholder {
    text-align: center;
  }
`;
export const AvailableSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 390px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  flex: 1;
  @media (max-width: 1650px) {
    width: 340px;
  }
`;
export const AvailableTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 13px;
  @media (max-width: 1300px) {
    font-size: 20px;
  }
`;
export const AvailableItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;
export const AvailableText = styled.div`
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  width: 50%;
`;
export const InventorySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 390px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  flex: 2;
  margin-bottom: 25px;
  @media (max-width: 1650px) {
    width: 340px;
  }
`;
export const InventoryTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 13px;
  @media (max-width: 1300px) {
    font-size: 20px;
  }
`;
export const InventoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2px;
  padding: 0px 20px 10px;
`;
export const InventoryText = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 12px;
  color: #495057;
  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;
export const InventoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
export const InventoryTextArea = styled.input`
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  height: 30px;
  margin-right: 12px;
  text-align: center;
  width: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  text-align: center;
  font-size:15px;
  & ::placeholder {
    text-align: center;
  }
`;
export const InventoryAdd = styled.button`
  width: 28px;
  height: 28px;
  background-color: #34c38f;
  color: white;
  border-radius: 50%;
  border: none;
  outline: none;
`;
export const EditInventoryBtn = styled.button`
  width: 175px;
  height: 32px;
  background-color: #34c38f;
  color: white;
  border-radius: 30px;
  padding: 0 10px;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-weight: 600;
`;