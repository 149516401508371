import React, { Component } from "react";
// import cn from 'classnames/bind'
import "./countdown.css";

//const cx = cn.bind(styles)

class CountDownTimer extends Component {
  constructor(props) {
    super(props);
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      secounds: 0,
      time_up: false,
    };
    this.x = null;
    this.deadline = null;
    this.time = "";
  }

  count() {
    var now = new Date().getTime();
    var t = this.deadline - now;
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((t % (1000 * 60)) / 1000);
    this.setState({ days, minutes, hours, seconds });
    if (t < 0) {
      clearInterval(this.x);
      this.setState({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
        time_up: true,
      });
    }
  }
  componentDidMount() {
    const { time } = this.props;
    this.deadline = time;
    this.x = setInterval(this.count, 1000);
  }

  render() {
    const { days, seconds, hours, minutes, time_up } = this.state;
    return (
      <div>
        {!time_up && (
          <div id="clockdiv">
            <div>
              <span className="days" id="day">
                {days}
              </span>
              <div className="smalltext">days</div>
            </div>
            <div>
              <span className="hours" id="hour">
                {hours}
              </span>
              <div className="smalltext">hours</div>
            </div>
            <div>
              <span className="minutes" id="minute">
                {minutes}
              </span>
              <div className="smalltext">minutes</div>
            </div>
          </div>
        )}
        {time_up && <p id="demo">Time Ended</p>}
      </div>
    );
  }
}

export default CountDownTimer;
