import { calulateCircleChartPercent } from "helpers/circle-chart/circle-chart";
import { connect, useSelector } from "react-redux";
import { selectProgressData, selectProgressPercent } from "store/selectors";
import ProgressChart from "./progress-chart.component";

const mapStateToProps = (state, ownProps) => {
  const { percent } = selectProgressData(state);
  console.log("Percent", percent);

  // const { current, last } = progressCalc;
  const chartPercentCal = calulateCircleChartPercent(495, percent);
  console.log("cartPercentCal", chartPercentCal);
  const lastchartPercentCal = calulateCircleChartPercent(495, 2);
  return {
    percent,
    lastPercent: 0,
    chartPercentCal: chartPercentCal,
    lastchartPercentCal: lastchartPercentCal,
  };
};

export const ProgressChartContainer = connect(mapStateToProps)(ProgressChart);
