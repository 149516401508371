//shopList
export const ADD_SHOP_ITEM_IN_SHOPLIST = "ADD_SHOP_ITEM_IN_SHOPLIST";
export const SHOP_TOAST_MESSAGE = "SHOP_TOAST_MESSAGE";
export const SET_SHOP_LIST = "SET_SHOP_LIST";
export const SET_SHOP_PAGE_STATUS = "SET_SHOP_PAGE_STATUS";
export const SHOP_SET_SEARCH_INPUT = "SHOP_SET_SEARCH_INPUT";
export const SET_SHOP_ITEM_PHOTO = "SET_SHOP_ITEM_PHOTO";
// shop details
export const SET_SHOP_ITEM_DETAIL = "SET_SHOP_ITEM_DETAIL";
export const SET_SHOPITEM_DETAIL_PAGE_STATUS =
  "SET_SHOPITEM_DETAIL_PAGE_STATUS";
export const SET_SHOPITEM_STUDENT_LIST = "SET_SHOPITEM_STUDENT_LIST";
export const SHOPITEM_DETAIL_TOAST_MESSAGE = "SHOPITEM_DETAIL_TOAST_MESSAGE";
export const SET_PICKUP_STUDENT_SHOPITEM = "SET_PICKUP_STUDENT_SHOPITEM";
export const SET_SHOP_ITEM_DETAIL_IMAGE = "SET_SHOP_ITEM_DETAIL_IMAGE";
