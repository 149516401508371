import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import SummarySections from "components/admin/adminDetail/Access/SummarySections";
import { ConstantValue } from "assets/style/General.style";
import {
  adminDetailHighSchoolList,
  adminDetailUniversityListSelector,
  adminDetailSpecialProjectListSelector,
} from "store/selectors";
const SummarySectionContainer = ({ organizationType }) => {
  const highSchoolList = useSelector(adminDetailHighSchoolList);
  const universityList = useSelector(adminDetailUniversityListSelector);
  const specialProjectList = useSelector(adminDetailSpecialProjectListSelector);
  const renderSchoolDistrictName = useMemo(
    () =>
      highSchoolList && Object.keys(highSchoolList).length !== 0 ? (
        Object.values(highSchoolList).map((schoolItem, index) => {
          return (
            <ConstantValue key={index}>
              {schoolItem?.schoolDistrictName || "..."}
            </ConstantValue>
          );
        })
      ) : (
        <ConstantValue>...</ConstantValue>
      ),
    [highSchoolList]
  );
  const renderSchoolName = useMemo(
    () =>
      highSchoolList && Object.keys(highSchoolList).length !== 0 ? (
        Object.values(highSchoolList).map((schoolItem, index) => {
          return (
            <ConstantValue key={index}>
              {schoolItem?.schoolName || "..."}
            </ConstantValue>
          );
        })
      ) : (
        <ConstantValue>...</ConstantValue>
      ),
    [highSchoolList]
  );
  const renderUniversityName = useMemo(
    () =>
      universityList && Object.values(universityList).length !== 0 ? (
        Object.values(universityList).map((universityItem, index) => {
          return (
            <ConstantValue key={index}>
              {universityItem?.title || "..."}
            </ConstantValue>
          );
        })
      ) : (
        <ConstantValue>...</ConstantValue>
      ),
    [universityList]
  );
  const renderSpecialProjectsName = useMemo(
    () =>
      specialProjectList && Object.values(specialProjectList).length !== 0 ? (
        Object.values(specialProjectList).map((specialProjectItem, index) => {
          return (
            <ConstantValue key={index}>
              {specialProjectItem?.projectName || "..."}
            </ConstantValue>
          );
        })
      ) : (
        <ConstantValue>...</ConstantValue>
      ),
    [specialProjectList]
  );
  const renderCategoryName = useMemo(
    () =>
      specialProjectList && Object.values(specialProjectList).length !== 0 ? (
        Object.values(specialProjectList).map((specialProjectItem, index) => {
          return (
            <ConstantValue key={index}>
              {specialProjectItem?.categoryName || "..."}
            </ConstantValue>
          );
        })
      ) : (
        <ConstantValue>...</ConstantValue>
      ),
    [specialProjectList]
  );
  const renderSubCategoryName = useMemo(
    () =>
      specialProjectList && Object.values(specialProjectList).length !== 0 ? (
        Object.values(specialProjectList).map((specialProjectItem, index) => {
          return (
            <ConstantValue key={index}>
              {specialProjectItem?.subCategoryName || "..."}
            </ConstantValue>
          );
        })
      ) : (
        <ConstantValue>...</ConstantValue>
      ),
    [specialProjectList]
  );
  return (
    <SummarySections
      schoolDistrictItems={renderSchoolDistrictName}
      schoolItems={renderSchoolName}
      universityNames={renderUniversityName}
      specialProjectsNames={renderSpecialProjectsName}
      categoryNames={renderCategoryName}
      subCategoryNames={renderSubCategoryName}
      organizationType={organizationType}
    />
  );
};

export default memo(SummarySectionContainer);
