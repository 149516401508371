import { gradify } from "axios.config";
import {
  GET_COINS_FAILD,
  GET_COINS_SUCCESS,
  GET_COINS_REQUEST,
} from "../../constant/coins";

export const getCoinsAmountAction =
  (user, project, category, categories) => async (dispatch) => {
    console.log("COIN USER", user);
    dispatch({
      type: GET_COINS_REQUEST,
    });

    try {
      if (!project) {
        const { data } = await gradify.get(
          `coins/getCoinsbyAccount?user=${user}`
        );

        dispatch({
          type: GET_COINS_SUCCESS,
          payload: data.coinProject,
        });
      } else if (!category) {
        const { data } = await gradify.get(
          `coins/getCoinsbyAccount?user=${user}`
          // &project=${project}`
        );
        console.log("coins", data.coinProject);
        dispatch({
          type: GET_COINS_SUCCESS,
          payload: data.coinProject,
        });
      } else if (category && categories?.length < 1) {
        const { data } = await gradify.get(
          `coins/getCoinsbyAccount?user=${user}`
          // &project=${project}&category=${category}`
        );
        console.log("coins", data.coinProject);
        dispatch({
          type: GET_COINS_SUCCESS,
          payload: data.coinProject,
        });
      } else if (category && categories?.length > 0) {
        const categoriesIds = categories.map((i) => i._id);
        const { data } = await gradify.get(
          `coins/getCoinsbyAccount?user=${user}`
          // &project=${project}&category=${categoriesIds}`
        );
        console.log("coins", data.coinProject);
        dispatch({
          type: GET_COINS_SUCCESS,
          payload: data.coinProject,
        });
      }
    } catch (error) {}
  };
