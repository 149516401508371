
import {
    TEAM_LIST_REQUEST,
    TEAM_LIST_SUCCESS,
    TEAM_LIST_FAIL
} from '../constant/team';


export const teamListReducer = (state = { teams:[]},action) => {
    switch(action.type){
        case TEAM_LIST_REQUEST:
            return {...state,loading:true,teams:[]}
        case TEAM_LIST_SUCCESS:
            return {...state,loading:false,teams:action.payload.data}
        case TEAM_LIST_FAIL:
            return {...state,loading:false,teams:[],error:action.payload}        
        default:
            return state
    }
}