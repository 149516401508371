export const theme = {
    colors:{
        dark:'#050200',
        primary:'#34C38F',
        main:'#F7F7FA',
        lightdark:'#CED4DA',
        white:'#FFFFFF',
        black:'#000000',
        gray:'#707070',
        darkgray:"#495057",
        red:'#F70606',
        lightBlue:'#A6B0CF',
        buttonBlue:'#0078FF',
        experienceBlue:'#1069C1'
    
    },
    fonts:{
        Poppins: 'Poppins',
        Rajdhani: 'Rajdhani'
    },
    fontSizes:{
        psmall:'13px',
        plSmall:'14px',
        pmedium:'15px',
        plMed:'16px',
        plarge:'19px',
        pexlarge:'22px',
        xxxl:"45px"

    },
    weights:{
        reqular:400,
        semiBold:700,
        bold:900
    }
}