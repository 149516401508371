import { ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL } from "constant";
import store from "Store";
export const addNewHighSchoolAction = (dispatch, newHighschool) => {
  const adminDetail = store.getState().adminDetail.adminDetail;
  const highSchoolList = store.getState().adminDetail.highSchoolsList;
  const existedHighSchool = Object.values(highSchoolList).filter(
    (item) => item._id === newHighschool?._id
  );
  const existedProject = Object.values(highSchoolList).filter(
    (item) => item.projectId === newHighschool?.projectId && !item?.categoryId
  );
  const data = {
    ...newHighschool,
    organizationId: adminDetail.system[0].organization._id,
    projectId: newHighschool.projectId,
    categoryId: newHighschool?.categoryId,
  };
  if (!(existedProject.length || existedHighSchool.length))
    dispatch({
      type: ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL,
      payload: { newHighschool: data },
    });
};
