import { selectStudentScore } from "../../store/selectors/Students";
import { calulateCircleChartPercent } from "../../helpers/calculatePercent";
import { connect } from "react-redux";
import CircleChart from "components/charts/CircleChart";
import { useSelector } from "react-redux";
import MomentumChart from "components/momentum-chart/momentum-chart.component";

const mapStateToProps = (state, ownProps) => {
  const percent = selectStudentScore(state);
  console.log(percent);
  const chartPercentCal = calulateCircleChartPercent(495, percent);
  return {
    percent,
    chartPercentCal,
    title: ownProps.title,
  };
};

export const StudentScore = connect(mapStateToProps)(MomentumChart);
