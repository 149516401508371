import {
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUBCATEGORY_LIST_FAIL,
  SUBCATEGORY_PROJECT_ADD_FAIL,
  SUBCATEGORY_PROJECT_ADD_REQUEST,
  SUBCATEGORY_PROJECT_ADD_SUCCESS,
  SUBCATEGORY_PROJECT_DELETE_FAIL,
  SUBCATEGORY_PROJECT_DELETE_REQUEST,
  SUBCATEGORY_PROJECT_DELETE_SUCCESS,
  SUBCATEGORY_PROJECT_LIST_FAIL,
  SUBCATEGORY_PROJECT_LIST_REQUEST,
  SUBCATEGORY_PROJECT_LIST_SUCCESS,
} from "../constant/subcategory";
import { gradify } from "../axios.config";
export const getSubCategoryList =
  (categoryId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBCATEGORY_LIST_REQUEST,
      });

      const { data } = await gradify.get(
        `categories/${categoryId}/subcategories?limit=100&sort=name`,
        { headers: { Authorization: "Bearer " + getState().auth.token } }
      );
      dispatch({
        type: SUBCATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {}
  };

export const addsubCategoryProjectAction =
  (categoryId, subCategoryInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBCATEGORY_PROJECT_ADD_REQUEST,
      });

      const { data } = await gradify.post(
        `categories/${categoryId}/subcategories`,
        subCategoryInfo,
        { headers: { Authorization: "Bearer " + getState().auth.token } }
      );

      dispatch({
        type: SUBCATEGORY_PROJECT_ADD_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: SUBCATEGORY_PROJECT_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getsubCategoryProjectAction =
  (categoryId, user) => async (dispatch, getState) => {
    let query;
    switch (user.role) {
      case "ultraAdmin":
        query = `subcategories/category/${categoryId}`;
        break;
      case "superAdmin":
        query = `subcategories?category=${categoryId}`;
        const superAdminAccessCategory = [
          ...user.schoolDistrictPermissionEdit,
          ...user.spicalProjectPermissionEdit,
        ];
        superAdminAccessCategory.forEach((item) => {
          if (item.subCategory) {
            query += `&name=${item.subCategory.name}`;
          }
        });
      //no default
    }

    try {
      dispatch({
        type: SUBCATEGORY_PROJECT_LIST_REQUEST,
      });

      const { data } = await gradify.get(query, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: SUBCATEGORY_PROJECT_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: SUBCATEGORY_PROJECT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deletesubCategoryProjctAction =
  (subCategoryId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBCATEGORY_PROJECT_DELETE_REQUEST,
      });

      const { data } = await gradify.delete(`subcategories/${subCategoryId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: SUBCATEGORY_PROJECT_DELETE_SUCCESS,
        payload: subCategoryId,
      });
    } catch (error) {
      dispatch({
        type: SUBCATEGORY_PROJECT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
