import React from "react";
import SettingHeader from "containers/setting/SettingHeader";
import SettingMain from "containers/setting/SettingMain";

const Setting = () =>{
  return (
    <>
      <SettingHeader 
          titleName="Setting"/>
      <SettingMain  
          FirstTab="Drivers"
          SecondTab="Charts: Learner"
           />
    </>
  );
};

export default Setting;
