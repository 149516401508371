import React, { useState, useCallback } from "react";
import University from "components/admin/adminDetail/Access/EditSections/University";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDetailUniversityOptionsSelector,
  adminDetailUniversityListSelector,
  adminDetailSelectedUniversityId,
} from "store/selectors";
import {
  removeSelectedUniversity,
  addNewUniversityAction,
  setSelectedUniversityId,
} from "actions";
const UniversityContainer = () => {
  const dispatch = useDispatch();
  //selectors
  const universityOptions = useSelector(adminDetailUniversityOptionsSelector);
  const universityList = useSelector(adminDetailUniversityListSelector);
  const selectedUniversityId = useSelector(adminDetailSelectedUniversityId);
  //states
  const [selectedUniversityOption, setSelectedUniversityOption] = useState();
  const universityChangeHandler = useCallback(
    (event) => {
      const universityId = event.target.value;
      const selectedUniversity = universityOptions.find(
        (pro) => pro._id === universityId
      );
      if (universityId === "Select university") {
        setSelectedUniversityOption();
      }
      if (universityId && universityId !== "Select university") {
        setSelectedUniversityOption({
          _id: selectedUniversity?._id,
          title: selectedUniversity?.title || selectedUniversity?.name,
          projectId: selectedUniversity?._id,
        });
      }
    },
    [universityOptions]
  );
  const deleteUniversityItemHandler = useCallback(
    (selectedUniversityId) => {
      removeSelectedUniversity(dispatch, selectedUniversityId);
    },
    [dispatch]
  );
  const universityClickHandler = useCallback(
    (selectedUniversityId) => {
      setSelectedUniversityId(dispatch, selectedUniversityId);
    },
    [dispatch]
  );
  const handleAddUniversityClick = useCallback(() => {
    if (selectedUniversityOption?._id) {
      addNewUniversityAction(dispatch, {
        ...selectedUniversityOption,
        access: {
          experienceCards: false,
          store: false,
          surveys: false,
          users: false,
        },
      });
    }
  }, [dispatch, selectedUniversityOption]);
  return (
    <University
      universityAccess
      universityOptions={universityOptions}
      selectedUniversityOption={selectedUniversityOption}
      onUniversityChange={universityChangeHandler}
      onDeleteUniversityItem={deleteUniversityItemHandler}
      onUniversityClick={universityClickHandler}
      universityList={universityList}
      selectedUniversity={universityList[selectedUniversityId]}
      onAddUniversityClick={handleAddUniversityClick}
    />
  );
};

export default UniversityContainer;
