import React from "react";
import {
  ContentHeader,
  ContentHeaderTitle,
} from "components/styled.components";
import {
  CreateRaffleFormCol,
  CreateRaffleFormRow,
  LabelRaffle,
  RaffleHeaderControle,
  SearchRaffle,
  RaffleButton,
  SearchRaffleContainer,
} from "components/styled.components/raffle.style";
import SelectComponent from "components/control.component/select.component";
import { MdAddCircle } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";
const RaffleHeader = ({
  categoryOptions,
  selectedCategoryId,
  onCategoryChanged,
  subCategoryOptions,
  selectedSubCategoryId,
  onSubCategoryChanged,
  haveAccess,
  searchRaffleInput,
  onSearchInputChanged,
  onAddRaffleItemClick,
}) => {
  return (
    <>
        <ContentHeader>
          <ContentHeaderTitle>Raffle ITEM LIST </ContentHeaderTitle>
        </ContentHeader>
        <CreateRaffleFormRow
          style={{ padding: 10,
                   height: 40,
                   marginTop: -40,
                   flexWrap: "wrap" ,
                   marginLeft: 35  }}
        >
          <CreateRaffleFormCol Direction="column">
            <LabelRaffle>Category</LabelRaffle>
            <SelectComponent
              options={categoryOptions}
              selectedValue={selectedCategoryId}
              noShowSlectText
              name="All"
              selected="Family"
              onChange={onCategoryChanged}
              style={{ minWidth: 180 }}
            />
          </CreateRaffleFormCol>
          <CreateRaffleFormCol Direction="column">
            <LabelRaffle>Sub Category</LabelRaffle>
            <SelectComponent
              options={subCategoryOptions}
              noShowSlectText
              name="All"
              selectedValue={selectedSubCategoryId}
              selected={true}
              onChange={onSubCategoryChanged}
              style={{ minWidth: 180 }}
            />
          </CreateRaffleFormCol>
          {/* <CreateRaffleFormCol
            style={{
              borderLeftWidth: 2,
              borderColor: "#959393",
              borderLeftStyle: "solid",
              paddingLeft: 10,
            }}
            Direction="column"
          >
            <LabelRaffle>Collaborative Projec</LabelRaffle>
            <SelectComponent
              options={""}
              name="tag"
              onChange={() => {}}
              style={{ minWidth: 180 }}
            />
          </CreateRaffleFormCol> */}
        </CreateRaffleFormRow>
      <RaffleHeaderControle style={{ maxWidth: 1380 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            width: "65%",
          }}
        >
          <SearchRaffleContainer>
            <BiSearchAlt
              spaceLeft="18px"
              style={{ position: "absolute", top: 11, left: 18 }}
              color={"#707070"}
            />
            <SearchRaffle
              type="text"
              value={searchRaffleInput}
              placeholder="Search Item"
              onChange={(e) => onSearchInputChanged(e)}
            />
          </SearchRaffleContainer>
          {haveAccess && (
            <RaffleButton
              style={{
                paddingRight: 4,
                paddingTop: 6,
                paddingBottom: 6,
                fontSize: 16,
              }}
              spaceLeft="50px"
              onClick={onAddRaffleItemClick}
            >
              <MdAddCircle size={32} />
              <span style={{ paddingRight: 18 }}>New Raffle</span>
            </RaffleButton>
          )}
        </div>
      </RaffleHeaderControle>
    </>
  );
};

export default RaffleHeader;
