import styled from "styled-components";

export const HorizontallContaner = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    max-width:300px;
    scroll-behavior: smooth;
    &::-webkit-scrollbar{
        display: none;
    }
`;
