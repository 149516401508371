import React, { useCallback, useState } from "react";
import ManuallyAdd from "../../../components/shop/shopSystem/inventory/ManuallyAdd";
import { useDispatch, useSelector } from "react-redux";
import { addAnInventory } from "actions/inventory/inventory.action";
const manuallyFieldDefault = {
  serialNumber: "",
  digitalCode: "",
  shopItem: null,
  raffelTicket: null,
};
const ManuallyInventoryContainer = ({ shopItem, raffelTicket }) => {
  const [manuallyField, setManuallyField] = useState({
    serialNumber: "",
    digitalCode: "",
    shopItem: shopItem ? shopItem : null,
    raffelTicket: raffelTicket ? raffelTicket : null,
  });
  const dispatch = useDispatch();
  const addInventory = useCallback(() => {
    dispatch(addAnInventory(manuallyField, shopItem, raffelTicket));
  }, [manuallyField]);
  return (
    <ManuallyAdd
      manuallyField={manuallyField}
      setManuallyField={setManuallyField}
      onAddInventory={addInventory}
    />
  );
};

export default ManuallyInventoryContainer;
