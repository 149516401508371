import styled from "styled-components";

export const HomeBox = styled.button`
    width:347px;
    min-height:293px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    border-radius:12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right:15px;
    margin-bottom:15px;
    border: none;
    background-color: white;
`;

export const HomeBoxImage = styled.img`
        width:100%;
        width: 174px;
        height: 174px;
        object-fit: contain;
`;

export const HomeBoxBottom = styled.div`
    border: 3px solid #fff;
    width: 97%;
    margin-top: -23px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
`;

export const HomeBoxBottomNameContent = styled.div`
    background-color: #3E3F5E;
    border-radius: 12px;

`;

export const HomeBoxBottomTitle = styled.p`
    font-family: "Rajdhani";
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    line-height: 24px;
    margin: 0;
    padding: 6px 12px;
`;

export const HomeBoxBottomProjectText = styled.p`
    background-color:#0BC4B8;
    font-family: "Rajdhani";
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    line-height: 15px;
    margin: 0;
    padding: 6px 12px;

`;

export const HomeBoxBottomStudentNumber = styled.div`
    font-family: "Rajdhani";
    font-size: 26px;
    font-weight: medium;
    color: ##3E3F5E;
    line-height: 26px;
    margin: 0;
    padding-right:20px;
`;

export const HomeBoxBottomIconContainer = styled.div`
    
`;

export const HomeBoxBottomIconShop = styled.img`
    padding-right:18px;
`;

export const HomeBoxBottomIconSurvay = styled.img`

`;

export const HomeBoxBottomStudentContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 75px;
    justify-content: center;
    align-content: center;
    text-align: center;
`;

export const HomeTitle = styled.h3`
    font-family: 'Poppins';
    color: #495057;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    padding: 10px 0;

`;

export const HomeRow = styled.div`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 50px;
  @media (max-width: 1300px) {
    padding: 15px 40px;
  }
`;

export const HomeColumn = styled.div`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  flex-wrap:wrap;
`;