import Moment from "moment";
export const formatRaffleList = (data) =>
  data.map((raffleItem) => {
    return {
      _id: raffleItem._id,
      prize: raffleItem?.raffle_title,
      description: raffleItem?.description,
      imageUrl: raffleItem?.raffle_image,
      startDate: `${Moment(raffleItem?.start_date).format(
        "MM/DD/YYYY"
      )}  ${Moment(raffleItem?.start_date).format("LT")}`,
      closeDate: `${Moment(raffleItem?.end_date).format("MM/DD/YYYY")} ${Moment(
        raffleItem?.end_date
      ).format("LT")}`,
      drawDate: `${Moment(raffleItem?.draw_date).format("MM/DD/YYYY")} ${Moment(
        raffleItem?.draw_date
      ).format("LT")}`,
    };
  });
const filterRaffleSystemById = (raffleList, filterItem, selectedId) => {
  const filtered = [];
  raffleList.map((raffle) => {
    if (
      raffle[filterItem]?._id === selectedId ||
      raffle[filterItem] === selectedId
    ) {
      filtered.push(raffle);
      return filtered;
    }
    return filtered;
  });
  return filtered;
};
export const filterRaffleList = (
  raffleList,
  organizationId,
  projectId,
  categoryId,
  subCategoryId
) => {
  const x = organizationId
    ? filterRaffleSystemById(raffleList, "organization", organizationId)
    : raffleList;
  const y =
    organizationId && projectId
      ? filterRaffleSystemById(x, "project", projectId)
      : x;
  const z =
    organizationId && projectId && categoryId
      ? filterRaffleSystemById(y, "category", categoryId)
      : y;
  const w =
    organizationId && projectId && categoryId && subCategoryId
      ? filterRaffleSystemById(z, "subCategory", subCategoryId)
      : z;
  return w;
};
export const sortRaffleListByTitle = (raffleData, filterSearch) => {
  let result = raffleData;
  if (raffleData) {
    if (filterSearch) {
      result = raffleData.filter((item) => {
        return item?.prize?.toLowerCase()?.match(filterSearch?.toLowerCase());
      });
      return result;
    }
    return result;
  }
  return result;
};
export const filterRaffleStudentsById = (studentList, selectedId) => {
  let filtered = {};
  Object.values(studentList).map((student) => {
    switch (selectedId) {
      case "All":
        filtered = studentList;
        break;
      case "Not picked up":
        if (!student.isPickedup) {
          filtered[student._id] = student;
        }
        break;
      case "Picked up":
        if (student.isPickedup) {
          filtered[student._id] = student;
        }
        break;
      default:
        filtered = studentList;
    }
    return filtered;
  });
  return filtered;
};
export const formatRaffleStudents = (students) => {
  const content = {};
  const counts = {};
  students.map((student) => {
    counts[student.student._id] = (counts[student.student._id] || 0) + 1;
    content[student.student._id] = {
      _id: student._id,
      studentId: student?.student?._id,
      name: `${student.student.firstName} ${student.student.lastName}`,
      purchasedDate: new Date(student.createdAt).toISOString().split("T")[0],
      isPickedup: student.pickup,
      isWinner: student.isWinner ? student.isWinner : false,
      pickupDate: student.pickup
        ? `${new Date(student.updatedAt).getDate()}-${new Date(
            student.updatedAt
          ).getMonth()}-${new Date(student.updatedAt).getFullYear()}`
        : "",
      ticketsNumber: counts[student.student._id],
    };
    return content;
  });
  return content;
};
export const searchRaffleStudentsByName = (studentData, searchInp) => {
  let result = {};
  if (studentData) {
    if (searchInp) {
      Object.values(studentData).map((item) => {
        if (item.name.toLowerCase().match(searchInp.toLowerCase())) {
          result[item._id] = item;
        }
        return result;
      });
      return result;
    } else {
      result = studentData;
      return result;
    }
  }
  return result;
};
export const formatRaffleTicketDetail = (data) => ({
  _id: data._id,
  image: data?.raffle_image,
  title: data?.raffle_title,
  gemsNumber: data?.gem_raffle,
  description: data?.description,
  winnerNumber: data?.number_winner || 0,
  isStore: data?.in_store || false,
  isPrizeWheel: data?.in_prize_wheel || false,
  soldNum: data?.students?.length || 0,
  startDate:
    data?.start_date && new Date(data?.start_date).toISOString().split("T")[0],
  startTime: Moment(data?.start_date).format("HH:mm"),
  closeDate:
    data?.end_date && new Date(data?.end_date).toISOString().split("T")[0],
  closeTime: Moment(data?.end_date).format("HH:mm"),
  drawDate:
    data?.draw_date && new Date(data?.draw_date).toISOString().split("T")[0],
  drawTime: Moment(data?.draw_date).format("HH:mm"),
  leftTime:
    new Date(data?.end_date).getTime() - new Date(data?.start_date).getTime(),
  category: data?.category ? data?.category : "All",
  purchase_limit_state: data?.purchase_limit_state,
  purchase_limit: data?.purchase_limit,
});

export const setDefaultCloseDate = (startDate) => {
  const start = new Date(startDate);
  start.setDate(start.getDate() + 7);

  return Moment(start).format().split("T")[0];
};
