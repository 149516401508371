import styled from "styled-components";

export const RowTr = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  padding: 0 0 0 30px;
`;
export const RowTd = styled.td`
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;
export const RowWrapper = styled.td`
  display: flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
