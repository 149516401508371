import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  StudentContainer,
  StudentContentContainer,
} from "components/styled.components";
import {
  selectedAppOrganizationIdSelector,
  selectedAppProjectIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
  studentCountSelector,
} from "store/selectors";
import StudentHeaderContainer from "containers/Student/StudentHeader";
import AddStudentContainer from "containers/Student/AddStudentPopup";
import Confirm from "components/display.component/confirm.components";
import AnimationCheck from "components/animation.icon/animation.icon.components";
import StudentList from "components/students/StudentList";
import { useDispatch, useSelector } from "react-redux";
import { studentListSelector } from "store/selectors";
import { changeCreateNew } from "actions/userListAction";
import { getALLUserList, addXpToUserAction } from "actions/userListAction";
import XpPanel from "components/students/StudentList/XpPanel";
import ReactPaginate from "react-paginate";
import { studentFilterTransformer } from "helpers";
import "./assets/css/pagination.css";
import ProgressBar from "components/charts/ProgressBar";
import { alertClasses } from "@mui/material";
import { useLocation } from "react-router-dom";

const Student = () => {
  const search = useLocation().search;
  const studentCountNumber = new URLSearchParams(search).get("studentCount");

  const dispatch = useDispatch();
  //Refs
  const filtersRef = useRef();

  //states
  const [xpInfo, setXpInfo] = useState({
    description: "",
    amount: "",
    addByAdmin: true,
  });
  const [xpPanel, setXpPanel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [sortType, setSortType] = useState({
    type: "fullName",
    isReversed: false,
  });
  const [searchStudentInput, setSearchStudentInput] = useState("");
  const [pageCount, setPageCount] = useState(1);
  //selectors
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const studentCount = useSelector(studentCountSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  const studentList = useSelector(studentListSelector);
  const { loading: userLoading, error } = useSelector(
    (state) => state.userList
  );
  const { user } = useSelector((state) => state.loggedInUser);
  const [selectedUsers, setSelectedUsers] = useState([]);

  //this 4 state is for loading
  const [loadingCompleted, setLoadingCompleted] = useState(0);
  const [hasLoading, setHasLoading] = useState(true);
  const [loadingDuration, setLoadingDuration] = useState(7);
  const [firstPartLoading, setFirstPartLoading] = useState(false);

  useEffect(() => {
    setLoadingCompleted(80);
  }, []);

  //this useEffect is for loading
  useEffect(() => {

    if(studentCountNumber > 100) {
      setTimeout(() => {
        setFirstPartLoading(true);
      }, 7000);
     
    }else{
      setTimeout(() => {
        setFirstPartLoading(true);
      }, 2000);
     
    }

    if (!userLoading && firstPartLoading) {
      setLoadingCompleted(100);
      setLoadingDuration(3);
      setTimeout(() => {
        setHasLoading(false);
      }, 2000);
    }
  }, [userLoading, loadingCompleted, firstPartLoading]);

  useEffect(() => {
    dispatch(getALLUserList(`filters=[{"field":"role","value":"user"}]&`));
  }, []);
  useEffect(() => {
    if (showConfirm) {
      setShowConfirm(true);
      setTimeout(() => {
        setShowConfirm(false);
      }, 4000);
    }
  }, [showConfirm]);

  const changeShowConfirm = () => {
    setShowConfirm(true);
    dispatch(getALLUserList('filters=[{"field":"role","value":"user"}]&'));
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const changeHandler = (e) => {
    setXpInfo({ ...xpInfo, description: e.target.value });
  };
  const changeXpHandler = (e) => {
    setXpInfo({ ...xpInfo, amount: parseInt(e.target.value) });
  };

  const ChagneSelectedUser = (userId) => {
    const findUser = selectedUsers.find((item) => item === userId);

    if (findUser) {
      const usersListSelected = selectedUsers.filter((item) => item !== userId);
      setSelectedUsers(usersListSelected);
    } else {
      const resultSelectUser = [...selectedUsers, userId];
      setSelectedUsers(resultSelectUser);
    }
  };

  const changeAllSelected = (studentList) => {
    setSelectedUsers(studentList);
  };
  const searchStudentListHandler = useCallback(
    (e) => {
      setSearchStudentInput(e.target.value);

      if (e.target.value) {
        filtersRef.current = {
          ...filtersRef.current,
          searchWord: e.target.value,
        };
      } else {
        delete filtersRef?.current?.searchWord;
      }
      setPageCount(1);
      delete filtersRef?.current?.page;
      dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
    },
    [dispatch]
  );
  const handlePageClick = (event) => {
    if (event.selected + 1) {
      filtersRef.current = {
        ...filtersRef.current,
        page: event.selected + 1,
      };

      console.log("page filter", filtersRef.current);
    } else {
      delete filtersRef?.current?.page;
    }
    dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
    setPageCount(event.selected + 1);
  };
  const categoryChangeHandler = useCallback(
    (categoryId) => {
      if (categoryId !== "All") {
        filtersRef.current = {
          ...filtersRef.current,
          category: categoryId,
        };
        delete filtersRef?.current?.subCategory;
      } else {
        delete filtersRef?.current?.category;
        delete filtersRef?.current?.subCategory;
      }
      setPageCount(1);
      delete filtersRef?.current?.page;
      dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
    },
    [dispatch]
  );
  const subCategoryChangeHandler = useCallback(
    (subCategoryId) => {
      if (filtersRef) {
        if (subCategoryId !== "All") {
          filtersRef.current = {
            ...filtersRef.current,
            subCategory: subCategoryId,
          };
        } else {
          delete filtersRef?.current?.subCategory;
        }
        setPageCount(1);
        delete filtersRef?.current?.page;
        dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
      }
    },
    [dispatch]
  );

  const handleSortStudents = useCallback(
    (field) => {
      if (field) {
        filtersRef.current = {
          ...filtersRef.current,
          sort: { field, order: !sortType.isReversed },
        };
        setPageCount(1);
        delete filtersRef?.current?.page;
      } else {
        delete filtersRef?.current?.sort;
      }
      dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
      setSortType({ type: field, isReversed: !sortType.isReversed });
    },
    [dispatch, sortType.isReversed]
  );

  const statusChangeHandler = (e) => {
    console.log("selected active", e.target.value);
    if (e.target.value == 1) {
      filtersRef.current = {
        ...filtersRef.current,
      };

      if (filtersRef.current.active) {
        delete filtersRef?.current?.active;
      }

      console.log("Filter ref", filtersRef.current);
      dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
    } else if (e.target.value == 2) {
      filtersRef.current = {
        ...filtersRef.current,
        active: "true",
      };
      console.log("Filter ref", filtersRef.current);

      dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
    } else if (e.target.value == 3) {
      console.log("run thsi");
      filtersRef.current = {
        ...filtersRef.current,
        active: "false",
      };
      console.log("Filter ref", filtersRef.current);
      dispatch(getALLUserList(studentFilterTransformer(filtersRef.current)));
    }
  };
  return (
    <>
    
      <StudentHeaderContainer
        user={user}
        onAddStudentClick={() => {
          dispatch(changeCreateNew());
          setShowForm(true);
        }}
        searchStudentInput={searchStudentInput}
        onSearchStudentChanged={searchStudentListHandler}
        onAwardPointsClicked={() => {
          setXpPanel(!xpPanel);
        }}
        onCategoryChange={categoryChangeHandler}
        onSubCategoryChange={subCategoryChangeHandler}
        onStatusChange={statusChangeHandler}
      />
      <StudentContainer>
        <StudentContentContainer style={{ width: "100%" }}>
          {hasLoading ? (
              studentCountNumber > 100 ?
            <ProgressBar
              bgcolor={"#34C38F"}
              completed={loadingCompleted}
              duration={loadingDuration}
            /> :  
            <ProgressBar
            bgcolor={"#34C38F"}
            completed={loadingCompleted}
            duration={2}
          />
          ) : error ? (
            console.log(error)
          ) : (
            <StudentList
              organizationId={selectedOrganizationId}
              projectId={selectedProjectId}
              category={selectedCategoryId}
              subCategory={selectedSubCategoryId}
              users={studentList?.data}
              changeAllSelected={changeAllSelected}
              ChagneSelectedUser={ChagneSelectedUser}
              onSortStudents={handleSortStudents}
              sortType={sortType}
            />
          )}
        </StudentContentContainer>
      </StudentContainer>
      {showForm ? (
        <AddStudentContainer
          close={closeForm}
          changeShowConfirm={changeShowConfirm}
        />
      ) : null}
      {showConfirm ? (
        <Confirm message="STUDENT ADDED SUCCESSFULY">
          <AnimationCheck />
        </Confirm>
      ) : null}
      {xpPanel && (
        <>
          <XpPanel
            xpInfo={xpInfo}
            onXpContainerClick={() => {
              setXpPanel(false);
              setXpInfo({
                description: "",
                amount: "",
                addByAdmin: true,
              });
            }}
            onDescriptionChange={changeHandler}
            onXpChange={changeXpHandler}
            onAddXpClick={() => {
              selectedUsers.forEach((item) => {
                const resultUserXp = {
                  ...xpInfo,
                  user: item,
                  organization: { _id: selectedOrganizationId },
                  project: { _id: selectedProjectId },
                  category: selectedCategoryId,
                  subCategory: selectedSubCategoryId,
                };
                dispatch(addXpToUserAction(resultUserXp));
                setXpInfo({
                  description: "",
                  amount: "",
                  addByAdmin: true,
                });
                setXpPanel(false);
              });
            }}
          />
        </>
      )}
      {studentCount > 25 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={Math.ceil(studentCount / 25)}
          forcePage={pageCount - 1}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}
    </>
  );
};

export default Student;
