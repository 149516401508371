import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../control.component/select.component";
import {
  CreateOrganizationFormCol,
  CreateOrganizationContainer,
  CreateOrganizationForm,
  CreateOrganizationFormRow,
  OrganizationInput,
  LabelOrganization,
  SwitchLabelOrganization,
  OrganizationImage,
  OrganizationEditButtton,
  OrganizationEditButttonText,
  OrganizationEditButttonIcon,
  OrganizationButon,
 

} from "./AddOrganization.style";

import WarningPopup from "../warningPopup/WarningPopup";
import { MdEdit } from "react-icons/md";

import {
  organizationType,
  ufcsMember,
  paid,
  rewardSystem,
  surveySystem,
} from "../../constants";
import axios from "axios";



import {leadContactOrganization} from '../../actions/leadContactOrganizationAction';
import { resetUpdateOrganization, updateOrganizationAction } from "../../actions/organizationActions";

const EditOrganizationComponent = ({ close,organization }) => {
  const fileRef = useRef(null);
  const {token} = useSelector(state => state.auth);
  const [organizationInfo, setOrganizationInfo] = useState(organization);
  const [showEditForm, setShowEditForm] = useState(false);
  const [leadContactState,setLeadContactState] = useState([]);
  const [showFormWarning, setShowFormWarning] = useState(false);
  const dispatch = useDispatch();
  const {leadContacts} = useSelector(state => state.leadContactOrganizationList);
  const {organizations,updateOrganization,loading} = useSelector(state => state.organization)
  useEffect(() => {
    if(organization){
      setOrganizationInfo(organization);
      dispatch(leadContactOrganization());
    }
  },[organization])

  useEffect(() => {
    if(leadContacts.length > 0){
      const result = leadContacts.map(item => {
        return {
          _id:item._id,
          name:item.firstName + " " +item.lastName 
        }
      });

     setOrganizationInfo({...organizationInfo,lead_contact:result[0]._id})
      setLeadContactState(result);
    }
  },[leadContacts]);

  const closeWarningForm = () => {
    setShowFormWarning(false);
    close();
  };
  const closeWarning = () => {
    setShowFormWarning(false);
  };

  const closeForm = (e) => {
    setShowFormWarning(true);

  };

  useEffect(() => {
    if(updateOrganization){
      close();
      dispatch(resetUpdateOrganization());
    }
  },[updateOrganization])

  const closeEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  return (
    <CreateOrganizationContainer className="form-Organization-add" >
      <CreateOrganizationForm>

        <CreateOrganizationFormRow style={{ paddingLeft: 40 }}>
          <CreateOrganizationFormCol
            alignment="flex-start"
            style={{ paddingLeft: 0, position:"relative" }}
          >
            <OrganizationImage  src={organizationInfo?.image} alt="gradegy" />
            <input type="file" ref={fileRef} style={{display:"none"}} onChange={async (e) => {
                 const formData = new FormData();
                 formData.append('file',e.target.files[0]);
                const res = await axios.post(`https://apigrad.azurewebsites.net/api/v2/photos`,formData,{headers:{"Authorization": "Bearer "+ token }})
               setOrganizationInfo({...organizationInfo,image:`https://apigrad.azurewebsites.net${res.data.url}`})
            }}/>
            <OrganizationEditButtton  onClick={() => {
              fileRef.current.click();
            }}>
              <OrganizationEditButttonText>Edit</OrganizationEditButttonText>
              <OrganizationEditButttonIcon><MdEdit color="#34C38F" size={12} /></OrganizationEditButttonIcon>
            </OrganizationEditButtton>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol  Direction="column" alignment="sttart" style={{alignItems:"center"}}>
            <OrganizationInput
              Width="485px"
              Height="39px"
              FontFamily="Rajdhani"
              Size="32px"
              Weight="700"
              name="OrganizatioName"
              placeholder="Name of Organization here"
              value={organizationInfo?.title}
              onChange={(e)=>{ setOrganizationInfo({...organizationInfo,title:e.target.value}) }}
            />
            <OrganizationInput
              Width="120px"
              Height="33px"
              FontFamily="Rajdhani"
              Size="13px"
              Weight="400"
              name="ShortFormName"
              placeholder="Short Form Name"
              value={organizationInfo?.short_form_name}
              onChange={(e)=>{ setOrganizationInfo({...organizationInfo,short_form_name:e.target.value}) }}
              style={{marginTop:5}}
            />

          </CreateOrganizationFormCol>
         
        </CreateOrganizationFormRow>
        
        <CreateOrganizationFormRow
          style={{ paddingRight: 20, paddingLeft: 20, marginTop: 16 }}
        >
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Organization Type</LabelOrganization>
                <p>{organizationInfo?.organization_type}</p>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>School District</LabelOrganization>
                <p>{organizationInfo?.schoolDistrict}</p>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>UFCS Member</LabelOrganization>
            <SelectComponent
              options={ufcsMember}
              name="ufcsMember"
              selectedValue={organizationInfo?.ufcs_member ? ufcsMember[0]._id : ufcsMember[1]._id}
              onChange={(e)=>{ setOrganizationInfo({...organizationInfo,ufcs_member:e.target.value === "Yes"?true:false}) }}
              style={{paddingTop:6, paddingLeft:10,paddingRight:10,paddingBottom:6 , minWidth:129, fontSize:10 ,backgroundPosition:"bottom 10px right 6px"}}
            />
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Lead Contact</LabelOrganization>
            <SelectComponent
              options={leadContactState}
              name="leadContent"
              selectedValue={organizationInfo?.lead_contact}
              onChange={(e)=>{ setOrganizationInfo({...organizationInfo,lead_contact:e.target.value}) }}
              style={{paddingTop:6, paddingLeft:10,paddingRight:10,paddingBottom:6 , minWidth:129, fontSize:10 ,backgroundPosition:"bottom 10px right 6px"}}
            />
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>

        <CreateOrganizationFormRow
          style={{ paddingRight: 20, paddingLeft: 20, marginTop: 20 }}
        >
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Paid</LabelOrganization>
            <SelectComponent
              options={paid}
              name="paid"
              selectedValue={organizationInfo?.paid}
              onChange={(e)=>{ setOrganizationInfo({...organizationInfo,paid:e.target.value}) }}
              style={{paddingTop:6, paddingLeft:10,paddingRight:10,paddingBottom:6 , minWidth:129, fontSize:10 ,backgroundPosition:"bottom 10px right 6px"}}
            />
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Reward System</LabelOrganization>
            <SelectComponent
              options={rewardSystem}
              name="rewardSystem"
              selectedValue={organizationInfo?.reward_system ? "Yes" : "No"}
              onChange={(e)=>{ setOrganizationInfo({...organizationInfo,reward_system:e.target.value==="Yes"?true:false}) }}
              style={{paddingTop:6, paddingLeft:10,paddingRight:10,paddingBottom:6 , minWidth:129, fontSize:10 ,backgroundPosition:"bottom 10px right 6px"}}
            />
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Survey System</LabelOrganization>
            <SelectComponent
              options={surveySystem}
              name="surveySystem"
              selectedValue={organizationInfo?.survey_system ? "Yes" : "No"}
              onChange={(e)=>{ setOrganizationInfo({...organizationInfo,survey_system:e.target.value==="Yes"?true:false}) }}
              style={{paddingTop:6, paddingLeft:10,paddingRight:10,paddingBottom:6 , minWidth:129, fontSize:10 ,backgroundPosition:"bottom 10px right 6px"}}
            />
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>

        <CreateOrganizationFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 48,
            marginBottom:30,
            justifyContent: "center",
          }}
        >
          <CreateOrganizationFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <OrganizationButon onClick={()=>{
              dispatch(updateOrganizationAction(organizationInfo._id,organizationInfo))
            }} disabled={loading}>
              {" "}
              {loading ? "Sending..." : "SAVE"}
            </OrganizationButon>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol style={{ paddingLeft: 30 }}>
            <OrganizationButon onClick={closeForm} >Cancel</OrganizationButon>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
      </CreateOrganizationForm>
      {showFormWarning ? (
              <WarningPopup
                close={closeWarningForm}
                text={'Clear All Changes'}
                reject={closeWarning}
                accept={()=>{}}
              />
            ) : null}
      {false && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {'error'}
        </div>
      )}
    </CreateOrganizationContainer>
  );
};

export default EditOrganizationComponent;
