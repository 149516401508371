import { gradify } from "../axios.config";
import {
  GET_XP_BOUNES,
  GET_XP_USER_ORGANIZATION_PROJECT,
  GET_XP_USER_ORGANIZATION_PROJECT_LIST,
} from "../constant/xp";

export const getAllXpsBounes =
  (userId, organization, project) => async (dispatch, getState) => {
    console.log(userId, organization, project);
    try {
      const { data } = await gradify.get(
        `xp?user=${userId}&addByAdmin=true&organization=${organization}&project=${project}`,
        {
          headers: { Authorization: "Bearer " + getState().auth.token },
        }
      );

      dispatch({
        type: GET_XP_BOUNES,
        payload: data.data,
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

export const getAllUserXpByOrganizationProject =
  (userId, project, category, categories) => async (dispatch, getState) => {
    try {
      console.log("this is running", categories?.length);
      console.log("category progile", category);
      if (!category) {
        console.log(`xp/projectxp/${userId}/${project}`);
        const { data } = await gradify.get(
          `xp/projectxp/${userId}/${project}`,
          {
            headers: { Authorization: "Bearer " + getState().auth.token },
          }
        );

        dispatch({
          type: GET_XP_USER_ORGANIZATION_PROJECT,
          payload: data.amountProject,
        });
      }
      if (category && categories?.length < 1) {
        const { data } = await gradify.get(
          `xp/projectxp/${userId}/${project}?category=${category._id}`,
          {
            headers: { Authorization: "Bearer " + getState().auth.token },
          }
        );
        console.log("**** data.amoutn", data.amountProject);
        dispatch({
          type: GET_XP_USER_ORGANIZATION_PROJECT,
          payload: data.amountProject,
        });
      }

      if (category && categories?.length > 0) {
        const categoriesIds = categories.map((i) => i._id);
        const { data } = await gradify.get(
          `xp/projectxp/${userId}/${project}?category=${categoriesIds}`,
          {
            headers: { Authorization: "Bearer " + getState().auth.token },
          }
        );
        console.log("**** data.amoutn", data.amountProject);
        dispatch({
          type: GET_XP_USER_ORGANIZATION_PROJECT,
          payload: data.amountProject,
        });
      }
    } catch (error) {
      console.log(error);
    }

    // try {
    //   const { data } = await gradify.get(
    //     `xp?user=${userId}&project=${project}`,
    //     {
    //       headers: { Authorization: "Bearer " + getState().auth.token },
    //     }
    //   );

    //   dispatch({
    //     type: GET_XP_USER_ORGANIZATION_PROJECT,
    //     payload: data.data.reduce((acc, item) => acc + item.amount, 0),
    //   });
    // } catch (error) {}
  };

export const getAllUserXpsProject =
  (userId, project) => async (dispatch, getState) => {
    try {
      const { data } = await gradify.get(
        `xp?user=${userId}&project=${project}`,
        {
          headers: { Authorization: "Bearer " + getState().auth.token },
        }
      );

      dispatch({
        type: GET_XP_USER_ORGANIZATION_PROJECT_LIST,
        payload: data.data,
      });
    } catch (error) {}
  };
