import {
  EXPERIENCE_USER_COMPLETE_REQUEST,
  EXPERIENCE_USER_COMPLETE_SUCCESS,
  EXPERIENCE_USER_COMPLETE_FAIL,
  EXPERIENCE_USER_COMPLETE_UPDATE,
} from "../constant/experienceUser";

export const experienceUserListReducer = (
  state = { experienceUserList: {} },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_USER_COMPLETE_REQUEST:
      return { ...state, loading: true, experienceUserList: {} };
    case EXPERIENCE_USER_COMPLETE_SUCCESS:
      return { ...state, loading: false, experienceUserList: action.payload };
    case EXPERIENCE_USER_COMPLETE_FAIL:
      return {
        ...state,
        loading: false,
        experienceUserList: {},
        error: action.payload,
      };
    case EXPERIENCE_USER_COMPLETE_UPDATE:
      console.log('action.payload',action.payload)
      return {
        ...state,
        loading: false,
        experienceUserList: {
            ...state.experienceUserList,
            data: state.experienceUserList.data.map((item) =>
              item.experience?._id === action.payload.experience?._id &&
              item.user?._id === action.payload.user?._id
                ? (item = action.payload)
                : item
            ),
          },
      };
    default:
      return state;
  }
};
