import {
  HIGHSCHOOL_LIST_FAIL,
  HIGHSCHOOL_LIST_REQUEST,
  HIGHSCHOOL_LIST_SUCCESS,
} from "../constant/highschool";

export const highSchoolListReducer = (
  state = { highschools: [] },
  action
) => {
  switch (action.type) {
    case HIGHSCHOOL_LIST_REQUEST:
      return { ...state, loading: true, highschools: [] };
    case HIGHSCHOOL_LIST_SUCCESS:
      return { ...state, loading: false, highschools: action.payload.data };
    case HIGHSCHOOL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        highschools: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
