import React, { useEffect, useState } from "react";
import ExperienceCard from "components/experience/ExperienceAddCard";
import "views/view.css";
import {
  selectedAppOrganizationIdSelector,
  selectedAppProjectIdSelector,
} from "store/selectors";
import { withRouter } from "react-router-dom";
import {
  getCompletionList,
  getCompletionDetails,
} from "actions/completionAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getExperienceTypeList,
  getExperienceTypeDetails,
} from "actions/experienceTypeAction";
import { getAllExperienceImageList } from "actions/experienceImageAction";
import { getCategoryProjectAction } from "actions/categoryListAction";
import { getsubCategoryProjectAction } from "actions/subCategoryListAction";
import { addNewExperience } from "actions/experienceAction";
import {
  EXPERIENCE_TAGS_DRIVER_1,
  EXPERIENCE_TAGS_DRIVER_2,
} from "constants/index";

const ExperienceAddCardContainer = ({ history }) => {
  const dispatch = useDispatch();
  //states
  const [experienceTypeId, setExperienceTypeId] = useState("");
  const [showError, setShowError] = useState(false);
  const [completionId, setCompletionId] = useState("");
  const [categoryStateId, setCategoryStateId] = useState(null);
  const [subCategoryStateId, setSubCategoryStateId] = useState(null);
  const [educationalPhase, setEducationalPhase] = useState("HS-9");
  const [textLinkTitle, setTextLinkTitle] = useState("");
  const [textLink, setTextLink] = useState(false);
  const [urlTextLink, setUrlTextLink] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [completeState, setCompeleteState] = useState(false);
  const [expImage, setExpImage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [buttonLink, setButtonLink] = useState("");
  let endDateExp = new Date();
  let endDate = endDateExp.setDate(endDateExp.getDate() + 1);
  console.log("enddate", new Date(endDate).toISOString());
  const [experience, setExperience] = useState({
    title: "Title",
    subTitle: "Subtitle",
    description:
      "Description of the experience item to inform the students what they need to do for this task.  You have 150 characters.",
    xpCompletion: 0,
    xpView: 0,
    coins: 0,
    startNotify: true,
    midNotify: false,
    passNotify: true,
    autoCompletion: true,
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date(endDate).toISOString().split("T")[0],
    startTime: "06:00:00",
    endTime: "23:59:00",
  });
  console.log(experience.endDate);
  const [driverDefult, setDriverDefult] = useState(EXPERIENCE_TAGS_DRIVER_1);
  const [secoundDriverDefult, setSecoundDriverDefult] = useState(
    EXPERIENCE_TAGS_DRIVER_2
  );
  const [firstDriver, setFirstDriver] = useState("");
  const [secondDriver, setSecondDriver] = useState("");
  const [secondDriverVal, setSecondDriverVal] = useState(
    EXPERIENCE_TAGS_DRIVER_2
  );
  const [firstDriverVal, setFirstDriverVal] = useState(
    EXPERIENCE_TAGS_DRIVER_1
  );
  const [progressionLevel, setProgressionLevel] = useState("");
  const [persona, setPersona] = useState("");
  //selectors
  const { experienceType } = useSelector((state) => state.experienceTypeList);
  const { experienceTypeDetails } = useSelector(
    (state) => state.experienceTypeDetails
  );
  const { user } = useSelector((state) => state.loggedInUser);
  const { completionDetails } = useSelector((state) => state.completionDeatils);
  const { completions } = useSelector((state) => state.completionList);
  const {
    loading: experienceImageLoading,
    experienceImages,
    error: experienceImageError,
  } = useSelector((state) => state.experienceImageList);
  const { categories } = useSelector((state) => state.categoryProjectList);
  const { subCategories } = useSelector(
    (state) => state.subCategoryProjectList
  );
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  useEffect(() => {
    dispatch(getExperienceTypeList());
    dispatch(getCompletionList());
    setExperienceTypeId("");
  }, []);
  useEffect(() => {
    if (experienceType.data) {
      setExperienceTypeId(experienceType.data[0]._id);
    }
  }, [experienceType]);
  useEffect(() => {
    if (experienceTypeId !== "") {
      dispatch(getExperienceTypeDetails(experienceTypeId));
    }
  }, [experienceTypeId]);

  useEffect(() => {
    if (completionId !== "") {
      dispatch(getCompletionDetails(completionId));
      if (completionId === "61b433279675acfece006154") {
        setExperience({ ...experience, autoCompletion: false });
      } else {
        setExperience({ ...experience, autoCompletion: true });
      }
    }
  }, [completionId]);
  useEffect(() => {
    if (completions.data) {
      setCompletionId(completions.data[0]._id);
    }
  }, [completions]);
  useEffect(() => {
    if (categoryStateId) {
      dispatch(getsubCategoryProjectAction(categoryStateId, user));
    }
  }, [categoryStateId]);

  useEffect(() => {
    if (completeState) {
      setShowConfirm(true);
      setTimeout(() => {
        setShowConfirm(false);
        history.push("/admin/experiences");
      }, 4000);
    }
  }, [completeState]);
  useEffect(() => {
    if (experienceTypeDetails.name) {
      console.log(experienceTypeDetails.name);
      setExpImage(experienceTypeDetails.name);
    }
  }, [experienceTypeDetails]);
  useEffect(() => {
    if (experienceImages.data) {
      setImgUrl(experienceImages.data[0]?.url);
    }
  }, [experienceImages]);
  useEffect(() => {
    if (expImage !== "") {
      dispatch(getAllExperienceImageList(expImage));
    }
  }, [expImage]);
  useEffect(() => {
    if (selectedProjectId) {
      dispatch(getCategoryProjectAction(selectedProjectId, user));
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (firstDriver !== "") {
      const secoundDriverItems = secoundDriverDefult.filter(
        (item) => item._id !== firstDriver
      );

      setSecondDriverVal(secoundDriverItems);
    }
  }, [firstDriver]);

  useEffect(() => {
    if (secondDriver !== "" && firstDriver === "") {
      const firstDriverItems = driverDefult.filter(
        (item) => item._id !== secondDriver
      );

      setFirstDriverVal(firstDriverItems);
    }
  }, [secondDriver]);

  const changeExperienceTypeHandler = (e) => {
    setExperienceTypeId(e.target.value);
  };
  const submitAsPublishHandler = () => {
    const endDateCurrectTime = new Date(experience.endDate).getTime();
    const startDateCurrentTime = new Date(experience.startDate).getTime();

    if (
      selectedOrganizationId &&
      selectedProjectId &&
      endDateCurrectTime >= startDateCurrentTime
    ) {
      if (endDateCurrectTime == startDateCurrentTime) {
        if (experience.endTime > experience.startTime) {
          const experienceInfo = {
            ...experience,
            experienceType: experienceTypeId,
            completionType: completionId,
            expSave: false,
            experienceCardPic: imgUrl,
            publish: true,
            organization: selectedOrganizationId,
            project: selectedProjectId,
            category: categoryStateId ? categoryStateId : null,
            subCategory: subCategoryStateId ? subCategoryStateId : null,
            sendToAll: selectedOrganizationId ? false : true,
            educationalPhase: educationalPhase,
            text_url: textLinkTitle,
            link_url: urlTextLink,
            button_link_url: buttonLink,
            tags: { driver_one: firstDriver, driver_two: secondDriver },
          };

          dispatch(addNewExperience(experienceInfo));
          setCompeleteState(true);
        } else {
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 4000);
        }
      } else {
        const experienceInfo = {
          ...experience,
          experienceType: experienceTypeId,
          completionType: completionId,
          expSave: false,
          experienceCardPic: imgUrl,
          publish: true,
          organization: selectedOrganizationId,
          project: selectedProjectId,
          category: categoryStateId ? categoryStateId : null,
          subCategory: subCategoryStateId ? subCategoryStateId : null,
          sendToAll: selectedOrganizationId ? false : true,
          educationalPhase: educationalPhase,
          text_url: textLinkTitle,
          link_url: urlTextLink,
          button_link_url: buttonLink,
          tags: { driver_one: firstDriver, driver_two: secondDriver },
        };

        dispatch(addNewExperience(experienceInfo));
        setCompeleteState(true);
      }
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  };

  const submitAsSaveHandler = () => {
    const endDateCurrectTime = new Date(experience.endDate).getTime();
    const startDateCurrentTime = new Date(experience.startDate).getTime();

    if (
      selectedOrganizationId &&
      selectedProjectId &&
      endDateCurrectTime >= startDateCurrentTime
    ) {
      if (endDateCurrectTime == startDateCurrentTime) {
        if (experience.endTime > experience.startTime) {
          const experienceInfo = {
            ...experience,
            experienceType: experienceTypeId,
            completionType: completionId,
            expSave: true,
            experienceCardPic: imgUrl,
            publish: false,
            organization: selectedOrganizationId,
            project: selectedProjectId,
            category: categoryStateId ? categoryStateId : null,
            subCategory: subCategoryStateId ? subCategoryStateId : null,
            sendToAll: selectedOrganizationId ? false : true,
            educationalPhase: educationalPhase,
            text_url: textLinkTitle,
            link_url: urlTextLink,
            button_link_url: buttonLink,
            tags: { driver_one: firstDriver, driver_two: secondDriver },
          };

          dispatch(addNewExperience(experienceInfo));
          setCompeleteState(true);
        } else {
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 4000);
        }
      } else {
        const experienceInfo = {
          ...experience,
          experienceType: experienceTypeId,
          completionType: completionId,
          expSave: true,
          experienceCardPic: imgUrl,
          publish: false,
          organization: selectedOrganizationId,
          project: selectedProjectId,
          category: categoryStateId ? categoryStateId : null,
          subCategory: subCategoryStateId ? subCategoryStateId : null,
          sendToAll: selectedOrganizationId ? false : true,
          educationalPhase: educationalPhase,
          text_url: textLinkTitle,
          link_url: urlTextLink,
          button_link_url: buttonLink,
          tags: { driver_one: firstDriver, driver_two: secondDriver },
        };

        dispatch(addNewExperience(experienceInfo));
        setCompeleteState(true);
      }
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  };
  const experienceImageChangeHandler = (url) => {
    setImgUrl(url);
  };

  const changeButtonLinkClickHandler = (event) => {
    const { value } = event.target;
    setButtonLink(value);
  };

  return (
    <ExperienceCard
      showError={showError}
      experienceType={experienceType.data}
      onChangeExperienceType={changeExperienceTypeHandler}
      onStartDateChange={(e) =>
        setExperience({ ...experience, startDate: e.target.value })
      }
      startTime={experience.startTime}
      onStartTimeChange={(e) =>
        setExperience({ ...experience, startTime: e.target.value })
      }
      endDate={experience.endDate}
      onEndDateChange={(e) =>
        setExperience({ ...experience, endDate: e.target.value })
      }
      endTime={experience.endTime}
      onEndTimeChange={(e) =>
        setExperience({ ...experience, endTime: e.target.value })
      }
      categoryOptions={categories}
      onCategoryChanged={(e) => setCategoryStateId(e.target.value)}
      subCategoryOptions={subCategories}
      onSubCategoryChanged={(e) => setSubCategoryStateId(e.target.value)}
      onEducationPhaseChange={(e) => setEducationalPhase(e.target.value)}
      title={experience.title}
      onTitleChange={(e) =>
        setExperience({ ...experience, title: e.target.value })
      }
      subTitle={experience.subTitle}
      onSubTitleChange={(e) =>
        setExperience({ ...experience, subTitle: e.target.value })
      }
      description={experience.description}
      onDescriptionChange={(e) =>
        setExperience({
          ...experience,
          description: e.target.value,
        })
      }
      textLinkTitle={textLinkTitle}
      onTextLinkChange={(e) => setTextLinkTitle(e.target.value)}
      imgUrl={imgUrl}
      xpCompletion={experience.xpCompletion}
      experienceTypeDetailsName={experienceTypeDetails.name}
      experienceTypeDetailsIcon={experienceTypeDetails.icon}
      experienceTypeDetailsColor={experienceTypeDetails.color}
      completionDetailsMessage={completionDetails.message}
      completionDetailsButtonTitle={completionDetails.buttonTitle}
      completionDetailsName={completionDetails?.name}
      onCompletionTypeChange={(e) => setCompletionId(e.target.value)}
      completionsTypeOptions={completions.data}
      onXpCompletionChange={(e) =>
        setExperience({
          ...experience,
          xpCompletion: e.target.value,
        })
      }
      xpView={experience.xpView}
      onXpViewChange={(e) =>
        setExperience({ ...experience, xpView: e.target.value })
      }
      coins={experience.coins}
      onCoinsChange={(e) =>
        setExperience({ ...experience, coins: e.target.value })
      }
      autoCompletion={experience.autoCompletion}
      onAutoCompletionChange={() =>
        setExperience({
          ...experience,
          autoCompletion: !experience.autoCompletion,
        })
      }
      onToggleTextLinkChange={() => {
        setTextLink((prevState) => {
          setTextLink(!prevState);
          if (prevState === false) {
            setTextLinkTitle("");
            setUrlTextLink("");
          }
        });
      }}
      urlTextLink={urlTextLink}
      textLink={textLink}
      onUrlTextLinkChange={(event) => setUrlTextLink(event.target.value)}
      onSubmitAsPublishClick={submitAsPublishHandler}
      onSubmitAsSaveClick={submitAsSaveHandler}
      experienceImagesData={experienceImages.data}
      onExperienceImageChange={experienceImageChangeHandler}
      experienceImageLoading={experienceImageLoading}
      experienceImageError={experienceImageError}
      buttonLink={buttonLink}
      onChangeButtonLinkClick={changeButtonLinkClickHandler}
      showConfirm={showConfirm}
      firstDriverOption={firstDriverVal}
      setFirstDriverOption={(e) => setFirstDriver(e.target.value)}
      secondDriverOption={secondDriverVal}
      setSecondDriverOption={(e) => setSecondDriver(e.target.value)}
      levelOfProgressionOption={progressionLevel}
      setLevelOfProgressionOption={(e) => setProgressionLevel(e.target.value)}
      personaOption={persona}
      setPersonaOption={(e) => setPersona(e.target.value)}
    />
  );
};

export default withRouter(ExperienceAddCardContainer);
