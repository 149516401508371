import { createSelector } from "reselect";

const experienceStudentListSelector = (state) => state.experienceStudentList;

export const experienceStudentListSelectorCache = createSelector(
  experienceStudentListSelector,
  (experienceStudentListSelector) => experienceStudentListSelector
);

export const calcChartPercentageCompleted = createSelector(
  experienceStudentListSelector,
  (experienceStudentListSelector) => {
    const total = experienceStudentListSelector.studenetList.length;
    const completedCount = experienceStudentListSelector.studenetList.filter(
      (item) => item?.complete?.complete === true
    ).length;
    const viewCont = experienceStudentListSelector.studenetList.filter(
      (item) => !!item.complete === true
    ).length;
    return {
      total,
      completedCount,
      notCompleted: total - completedCount,
      viewCount: viewCont,
    };
  }
);

export const clacExpereinceAnalizeChartSelector = createSelector(
  (state) => state.analize,
  (analize) => {
    return {
      total: analize.total,
      completedTask: analize.completed,
      notCompletedTask: analize.notCompleted,
      views: analize.views,
    };
  }
);
