import styled from "styled-components";

export const CreateStudentContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display:flex;
  justify-content:center;
  align-items:center;
  
`;

export const CreateStudentForm = styled.div`
    padding-bottom: 40px;
    margin-left: 200px;
    padding-top:40px;
    height: fit-content;
    background:${({theme}) => theme.colors.white};
    border-radius:10px;
    width: 70%;
    box-shadow: 1px 2px 14px -1px rgba(0,0,0,0.82);

    @media (max-width: 1300px) {
      margin-left: 100px;
      padding-top: 20px;
      padding-bottom: 20px;
      width:911px;
    }

`;

export const CreateStudentFormRow = styled.div`
     width:95%;
     height:40px;
     margin-top:-40px;
     margin-left:35px;
     display:flex;
     flex-wrap:wrap;
     padding:10px;
     position:relative;
     @media (max-width: 1300px) {
      margin-top:0;
      margin-left:20px;
    }

`;


export const CreateStudentFormCol = styled.div`
  display:flex;
  justify-content:${({alignment}) => alignment};
  text-align:left;
  flex-direction:${({Direction}) => Direction};
  padding:0 10px;
  
`;


export const CreateStudentAbsoluteRow = styled.div`
  top: 10px;
  right: 60px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  padding-left: 10;

`;

export const StudentInput = styled.input`
    width:${({Width}) => Width ? Width : "190px"};
    height:${({Height}) => Height ? Height : "32px"};
    text-align:center;
    font-family:${({FontFamily}) => FontFamily};
    font-size:${({Size}) => Size};
    font-weight:${({Weight}) => Weight};
    padding:${({SpacingTB}) => SpacingTB} ${({SpacingLR}) => SpacingLR};
    border-radius:5px;
    border:1px solid ${({theme}) => theme.colors.lightdark};
    color:#070708;
    max-width:${({Width}) => Width ? Width : "235px"};

`;

export const LabelStudent = styled.label`
  font-family:${({theme}) => theme.fonts.Poppins};
  font-size:15px;
  color:${({theme}) => theme.colors.gray};


`;

export const SwitchLabelStudent = styled.label`
  font-family:${({theme}) => theme.fonts.Poppins};
  font-weight:400;
  font-size:13px;
  color:#74788D;
  padding-right:7px;

`;

export const StudentTagContainer = styled.div`
  width: 100%;
  margin-top:10px;
  max-width:900px;
  border:1px solid #CED4DA;
  border-radius:22px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  padding:20px;
  justify-content: flex-start;
`;
export const StudentTag = styled.div`
  width: 175px;
  height: 42px;
  border:1px solid #CED4DA;
  border-radius:12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin:5px;
  position:relative;
  
`;
export const StudentCloseTag = styled.div`
position: absolute;
top: -3px;
right: 7px;
color: #313539;
font-family: system-ui;
font-size: 14px;
font-weight: 600;
cursor: pointer;
`;



export const AddTagRow = styled.div`
width: 100%;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 9999;
backdrop-filter: blur(15px);
display:flex;
justify-content:center;
align-items:center;
  
`;
export const AddTagPopup = styled.div`
  width: 445px;
  height: 277px;
  background:${({theme}) => theme.colors.white};
  border-radius:10px;
  box-shadow: 1px 2px 14px -1px rgba(0,0,0,0.82);
`;
export const AddTagColumn = styled.div`
  flex:1;
  display:flex;
  justify-content:${({alignment}) => alignment};
  text-align:left;
  flex-direction:${({Direction}) => Direction};
  padding:0 10px;
  position:relative;
  
`;
export const AddTagTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 32px;
  font-weight:600
  
`;
export const AddTagCloseIcon = styled.div`
position:absolute;
font-size: 32px;
right:5px;
`;


export const StudentProjectContainer = styled.div`
  width: 98%;
  margin-top:10px;
  max-width:835px;
  border:1px solid #CED4DA;
  border-radius:22px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  padding:20px;
  justify-content: flex-start;
  margin-right: 20px;
  max-height: 185px;
  overflow: overlay;
`;
export const StudentProject = styled.div`
  border:1px solid #CED4DA;
  border-radius:12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin:5px;
  position:relative;
  color: #74788D;
    font-size: 13px;
    flex-direction: column;
    border-radius: 5px;
    min-width: 122px;
    min-height: 68px;
  
`;
export const StudentCloseProject = styled.div`
position: absolute;
top: -3px;
right: 7px;
color: #313539;
font-family: system-ui;
font-size: 14px;
font-weight: 600;
cursor: pointer;
`;

export const AddProjectPopup = styled.div`
  min-width: 735px;
  min-height: 68%;
  background:${({theme}) => theme.colors.white};
  border-radius:2px;
 box-shadow: 1px 2px 14px -1px rgba(0,0,0,0.82);
`;


export const AddProjectTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 32px;
  font-weight:600
  
`;
export const AddProjectRow = styled.div`
width: 100%;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 9999;
backdrop-filter: blur(15px);
display:flex;
justify-content:center;
align-items:center;
  
`;

export const AddProjectColumn = styled.div`
  flex:1;
  display:flex;
  justify-content:${({alignment}) => alignment};
  text-align:left;
  flex-direction:${({Direction}) => Direction};
  padding:0 10px;
  position:relative;

`;

export const AddProjectCloseIcon = styled.div`
position:absolute;
font-size: 32px;
right:5px;
`;


export const AddProjectAddButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  height: 30px;
    width: 30px;
    margin-top: 21px;
    justify-content: center;
  box-shadow:0px 3px 6px rgba(0,0,0,0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight:300;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left:20px;
  } ;
  span{
    white-space: nowrap;
  }
`;

export const BoxTitle  = styled.h3`
    font-size: 32px;
    color: #495057;
    font-family: 'Poppins';
    font-weight: 500;
    padding-right: 15px;
`;