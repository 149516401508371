import styled from 'styled-components';


export const StudentDetailsContainer = styled.div`
    padding:13px;
    padding-top:35px;

`;
export const RadarChartContainer = styled.div`
  svg g > polygon:first-child{
    stroke:${({Color}) => Color ? Color : "#34C38F"};
    stroke-width: 3px;
  }
`;