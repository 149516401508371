import React from "react";
import ShopSystemHeader from "./header/index";
import LeftColumn from "components/shop/shopSystem/leftColumn";
import CenterColumn from "./centerColumn/index";
import RightColumnContainer from "containers/Shop/ShopSystem/RightColumn";
import {
  Wrapper,
  ColumnWrapper,
  ButtonWrapper,
  AddBtn,
  UpdateBtn,
  DeleteBtn,
} from "./index.style";
import TrashIcon from "assets/image/trashIcon.png";

const ShopSystemItem = ({
  image,
  titleInputRef,
  title,
  onTitleInputChange,
  description,
  onDescriptionChange,
  gemsNumber,
  unlockLevel,
  leftItemNumber,
  costDollar,
  onCostDollarChange,
  onGemsNumberChange,
  onUnlockLevelChange,
  isStore,
  onStoreChange,
  isPrizeWheel,
  onPrizeWheelChange,
  initialStock,
  currentStock,
  addedInventoryNum,
  onAddedInventoryNumChange,
  onAddStockClick,
  pickedUpNum,
  notPickedUpNum,
  soldNum,
  pageStatus,
  onAddClick,
  onDeleteItemClick,
  onUpdateItemClick,
  onEditInventory,
  onChangeItemImg,
  shopItem,
  raffelTicket,
  setPickUpNumState,
  purchaseLimitState,
  purchaseLimitCount,
  onPurchaseLimitChange,
  purchaseLimitCountChage,
  categories,
  onChangeCategory,
  selectCategory,
}) => {
  return (
    <Wrapper>
      <ShopSystemHeader
        inStockNum={currentStock}
        pickedUpNum={pickedUpNum}
        notPickedUpNum={notPickedUpNum}
        soldNum={soldNum}
        headerTitle="Shop"
        currentStock={currentStock}
        shopItem={shopItem}
        raffelTicket={raffelTicket}
        categoryOptions={categories}
        onCategoryChanged={onChangeCategory}
        selectCategory={selectCategory}
      />
      <ColumnWrapper>
        <LeftColumn
          image={image}
          title={title}
          onTitleInputChange={onTitleInputChange}
          description={description}
          onDescriptionChange={onDescriptionChange}
          titleInputRef={titleInputRef}
          gemsNumber={gemsNumber}
          onChangeItemImg={onChangeItemImg}
          unlockLevel={unlockLevel}
          leftItemNumber={leftItemNumber}
        />
        <CenterColumn
          costDollar={costDollar}
          onCostDollarChange={onCostDollarChange}
          gemsNumber={gemsNumber}
          onGemsNumberChange={onGemsNumberChange}
          unlockLevel={unlockLevel}
          onUnlockLevelChange={onUnlockLevelChange}
          isStore={isStore}
          onStoreChange={onStoreChange}
          isPrizeWheel={isPrizeWheel}
          onPrizeWheelChange={onPrizeWheelChange}
          initialStock={initialStock}
          currentStock={currentStock}
          addedInventoryNum={addedInventoryNum}
          onAddedInventoryNumChange={onAddedInventoryNumChange}
          onAddStockClick={onAddStockClick}
          onEditInventory={onEditInventory}
          purchaseLimitState={purchaseLimitState}
          purchaseLimitCount={purchaseLimitCount}
          onPurchaseLimitChange={onPurchaseLimitChange}
          purchaseLimitCountChage={purchaseLimitCountChage}
        />
        <RightColumnContainer
          setPickUpNumState={setPickUpNumState}
          shopItem={shopItem}
          raffelTicket={raffelTicket}
        />
      </ColumnWrapper>
      <ButtonWrapper>
        <AddBtn onClick={onAddClick}>
          {pageStatus === "ADD_STOCK_SAVING" ? "Loading" : "Add"}
        </AddBtn>
        <DeleteBtn onClick={onDeleteItemClick}>
          <img src={TrashIcon} alt="trash"></img>
        </DeleteBtn>
        <UpdateBtn onClick={onUpdateItemClick}>
          {pageStatus === "UPDATE_SAVING" ? "Updating" : "Update"}
        </UpdateBtn>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ShopSystemItem;
