import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import "./OrganizationsTable.style.css";
import { useDispatch, useSelector } from "react-redux";

import { getOrganizationProject } from "../../actions/projectListAction";
import OrganizationProjectItem from "./OrganizationProjectItem";
import Spinner from '../spinner/spinner'

const OrganizationsProjectsTable = ({ id }) => {
  const dispatch = useDispatch();
  const { projects, loading } = useSelector(
    (state) => state.organizationProjectList
  );
  const { user } = useSelector((state) => state.loggedInUser);
  useEffect(() => {
    dispatch(getOrganizationProject(id, user));
  }, [dispatch, id, user]);

  return (
    <>
      {loading ? (
        <Spinner style={{width:50,height:50, top:20 }}/>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 60 }}>
                <Checkbox
                  color="default"
                  size="small"
                  checked={true}
                  onChange={() => {}}
                />
              </TableCell>
              <TableCell style={{ width: 60 }}></TableCell>
              <TableCell>Name</TableCell>
              <TableCell style={{ maxWidth: 48 }}>Project Type</TableCell>
              <TableCell style={{ paddingLeft: 20 }}>Condition</TableCell>
              <TableCell style={{ maxWidth: 80 }}>Status</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell>Subcategories</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Reward System</TableCell>
              <TableCell>Survey System</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((item) => (
              <OrganizationProjectItem key={item._id} item={item} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default OrganizationsProjectsTable;
