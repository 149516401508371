import React, { useCallback } from "react";
import {
  allAppCategoriesSelector,
  allAppSubCategoriesSelector,
  selectedAppProjectIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import { useDispatch, useSelector } from "react-redux";
import ShopHeader from "components/shop/ShopHeader";
import {
  getALLShopItemListAction,
  getAppCategoriesAction,
  getAppSubCategoriesAction,
  setAppSelectedSubCategoryId,
  setShopPageStatus,
} from "actions";
import { useEffect } from "react";
const ShopHeaderContainer = ({
  searchShopInput,
  onSearchInputChanged,
  haveAccess,
}) => {
  const dispatch = useDispatch();
  //selectors
  const categories = useSelector(allAppCategoriesSelector);
  const subCategories = useSelector(allAppSubCategoriesSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);

  useEffect(() => {
    dispatch(getAppCategoriesAction(selectedProjectId));
  }, [dispatch, selectedProjectId]);
  //actions
  const handleAddShopItemClick = useCallback(() => {
    setShopPageStatus(dispatch, "ADD_SHOP_ITEM_POPUP");
  }, [dispatch]);
  return (
    <>
      <ShopHeader
        categoryOptions={categories}
        selectedCategoryId={selectedCategoryId}
        onCategoryChanged={(e) => {
          dispatch(getAppSubCategoriesAction(e.target.value));
          dispatch(getALLShopItemListAction("title", 1, null, e.target.value));
        }}
        subCategoryOptions={subCategories}
        selectedSubCategoryId={selectedSubCategoryId}
        onSubCategoryChanged={(e) => {
          setAppSelectedSubCategoryId(dispatch, e.target.value);
        }}
        haveAccess={haveAccess}
        searchShopInput={searchShopInput}
        onSearchInputChanged={onSearchInputChanged}
        onAddShopItemClick={handleAddShopItemClick}
      />
    </>
  );
};

export default ShopHeaderContainer;
