import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirm from "components/display.component/DeleteConfirm";
import {
  getShopItemDetailAction,
  addStockShopItemAction,
  updateShopItemAction,
  setShopDetailPageStatus,
  deleteShopItemAction,
  editShopItemImage,
} from "actions";

import Confirm from "components/display.component/confirm.components";
import AnimationCheck from "components/animation.icon/animation.icon.components";
import ShopSystemItem from "components/shop/shopSystem";
import {
  shopItemDetailPageStatusSelector,
  shopItemDetailSelector,
  shopItemDetailToastMessageSelector,
  shopDetailItemImageSelector,
  allAppCategoriesSelector,
} from "store/selectors";
const ShopDetailContainer = ({ history, itemId, onEditInventory, type }) => {
  const dispatch = useDispatch();
  const titleInputRef = useRef();
  // selectors
  const pageStatus = useSelector(shopItemDetailPageStatusSelector);
  const shopItemDetail = useSelector(shopItemDetailSelector);

  const toastMessage = useSelector(shopItemDetailToastMessageSelector);
  const shopItemImage = useSelector(shopDetailItemImageSelector);
  const categories = useSelector(allAppCategoriesSelector);
  //states
  const [title, setTitle] = useState(shopItemDetail?.title);
  const [description, setDescription] = useState(shopItemDetail?.description);
  const [costDollar, setCostDollar] = useState(shopItemDetail?.costDollar);
  const [gemsNumber, setGemsNumber] = useState(shopItemDetail?.gemsNumber);
  const [unlockLevel, setUnLockLevel] = useState(shopItemDetail?.unlockLevel);
  const [isStore, setIsStore] = useState(shopItemDetail?.isStore);
  const [pickedUpNumState, setPickUpNumState] = useState(false);
  const [purchaseLimitState, setPurchaseLimitState] = useState(
    shopItemDetail?.purchaseLimitState
  );
  const [category, setCategory] = useState("All");
  const [selectCategoryShop, setSelectCategoryShop] = useState(
    shopItemDetail?.category
  );
  const [purchaseLimitCount, setPurchaseLimitCount] = useState(
    shopItemDetail?.purchaseLimitCount ? shopItemDetail?.purchaseLimitCount : 1
  );

  const [isPrizeWheel, setIsPrizeWheel] = useState(
    shopItemDetail?.isPrizeWheel
  );
  const [addedInventoryNum, setAddedInventoryNum] = useState(0);
  useEffect(() => {
    dispatch(getShopItemDetailAction(itemId));
  }, [dispatch, itemId]);
  useEffect(() => {
    setIsPrizeWheel(shopItemDetail?.isPrizeWheel);
    setIsStore(shopItemDetail?.isStore);
    setUnLockLevel(shopItemDetail?.unlockLevel);
    setGemsNumber(shopItemDetail?.gemsNumber);
    setCostDollar(shopItemDetail?.costDollar);
    setDescription(shopItemDetail?.description);
    setTitle(shopItemDetail?.title);
    setPurchaseLimitState(shopItemDetail?.purchaseLimitState);
    setSelectCategoryShop(shopItemDetail?.category);

    setPurchaseLimitCount(shopItemDetail?.purchaseLimitCount);
  }, [
    itemId,
    shopItemDetail?.costDollar,
    shopItemDetail?.description,
    shopItemDetail?.gemsNumber,
    shopItemDetail?.isPrizeWheel,
    shopItemDetail?.isStore,
    shopItemDetail?.title,
    shopItemDetail?.unlockLevel,
    shopItemDetail?.purchase_limit_state,
    shopItemDetail?.purchase_limit,
    shopItemDetail?.category,
  ]);

  //actions
  const titleInputChangeHandler = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const descriptionChangeHandler = useCallback((e) => {
    setDescription(e.target.value);
  }, []);
  const costDollarChangeHandler = useCallback((e) => {
    setCostDollar(e.target.value);
  }, []);
  const gemsNumberChangeHandler = useCallback((e) => {
    setGemsNumber(e.target.value);
  }, []);
  const unlockLevelChangeHandler = useCallback((e) => {
    setUnLockLevel(e.target.value);
  }, []);
  const storeChangeHandler = useCallback(() => {
    setIsStore(!isStore);
  }, [isStore]);
  const prizeWheelChangeHandler = useCallback(() => {
    setIsPrizeWheel(!isPrizeWheel);
  }, [isPrizeWheel]);
  const addedInventoryNumChangeHandler = useCallback((e) => {
    setAddedInventoryNum(e.target.value);
  }, []);
  const addStockClickedHandler = useCallback(() => {
    if (addedInventoryNum > 0) {
      const input = {
        current_stock:
          parseInt(shopItemDetail.currentStock) + parseInt(addedInventoryNum),
      };
      dispatch(addStockShopItemAction(input, addedInventoryNum));
      setAddedInventoryNum(0);
    }
  }, [addedInventoryNum, dispatch, shopItemDetail?.currentStock]);

  const changeItemImgHandler = useCallback(
    (e) => {
      editShopItemImage(dispatch, e.target.files[0]);
    },
    [dispatch]
  );

  const onPurchaseLimitStateChange = useCallback(
    (e) => {
      console.log(purchaseLimitState);
      setPurchaseLimitState(!purchaseLimitState);
    },
    [purchaseLimitState]
  );

  const onPurchaseLimitCountChange = useCallback(
    (e) => {
      setPurchaseLimitCount(parseInt(e.target.value));
    },
    [purchaseLimitCount]
  );
  const addClickedHandler = useCallback(() => {
    if (addedInventoryNum > 0) {
      const input = {
        current_stock:
          parseInt(shopItemDetail.currentStock) + parseInt(addedInventoryNum),
      };
      dispatch(addStockShopItemAction(input));
      setAddedInventoryNum(0);
    }
  }, [addedInventoryNum, dispatch, shopItemDetail?.currentStock]);
  const updateClickedHandler = useCallback(() => {
    let itemInfo;
    if (category === "All") {
      itemInfo = {
        title,
        unlock_level: unlockLevel,
        description,
        cost_per_item: costDollar,
        store: isStore,
        prize_wheel: isPrizeWheel,
        gem: gemsNumber,
        image: shopItemImage?.url,
        purchase_limit_state: purchaseLimitState,
        purchase_limit: purchaseLimitCount,
      };
    } else {
      itemInfo = {
        title,
        unlock_level: unlockLevel,
        description,
        cost_per_item: costDollar,
        store: isStore,
        prize_wheel: isPrizeWheel,
        gem: gemsNumber,
        image: shopItemImage?.url,
        purchase_limit_state: purchaseLimitState,
        purchase_limit: purchaseLimitCount,
        category: category,
      };
    }

    console.log("iteminfo", purchaseLimitCount);
    dispatch(updateShopItemAction(itemInfo));
  }, [
    costDollar,
    description,
    dispatch,
    gemsNumber,
    isPrizeWheel,
    isStore,
    shopItemImage?.url,
    title,
    unlockLevel,
    purchaseLimitCount,
    purchaseLimitState,
  ]);
  const deleteClickedHandler = useCallback(() => {
    dispatch(deleteShopItemAction(itemId, history));
  }, [dispatch, history, itemId]);

  const onChangeCategory = (value) => {
    console.log(value);
    setCategory(value);
  };
  return (
    <>
      {console.log("selectShop", selectCategoryShop)}
      {type === "shopItem" ? (
        <ShopSystemItem
          image={shopItemImage}
          title={title}
          onTitleInputChange={titleInputChangeHandler}
          description={description}
          onDescriptionChange={descriptionChangeHandler}
          titleInputRef={titleInputRef}
          gemsNumber={gemsNumber}
          unlockLevel={unlockLevel}
          leftItemNumber={shopItemDetail?.currentStock}
          costDollar={costDollar}
          onCostDollarChange={costDollarChangeHandler}
          onGemsNumberChange={gemsNumberChangeHandler}
          onUnlockLevelChange={unlockLevelChangeHandler}
          isStore={isStore}
          onStoreChange={storeChangeHandler}
          isPrizeWheel={isPrizeWheel}
          onPrizeWheelChange={prizeWheelChangeHandler}
          initialStock={shopItemDetail?.initialStock}
          currentStock={shopItemDetail?.currentStock}
          addedInventoryNum={addedInventoryNum}
          onAddedInventoryNumChange={addedInventoryNumChangeHandler}
          pickedUpNum={shopItemDetail?.pickedUpNum}
          setPickUpNumState={setPickUpNumState}
          notPickedUpNum={
            shopItemDetail?.soldNum - shopItemDetail?.pickedUpNum || 0
          }
          soldNum={shopItemDetail?.soldNum}
          onAddStockClick={addStockClickedHandler}
          onAddClick={addClickedHandler}
          onDeleteItemClick={() => {
            setShopDetailPageStatus(dispatch, "DELETE_ITEM_POPUP");
          }}
          onUpdateItemClick={updateClickedHandler}
          pageStatus={pageStatus}
          onEditInventory={onEditInventory}
          onChangeItemImg={changeItemImgHandler}
          shopItem={itemId}
          purchaseLimitState={purchaseLimitState}
          purchaseLimitCount={purchaseLimitCount}
          onPurchaseLimitChange={onPurchaseLimitStateChange}
          purchaseLimitCountChage={onPurchaseLimitCountChange}
          categories={categories}
          onChangeCategory={onChangeCategory}
          selectCategory={selectCategoryShop}
        />
      ) : (
        <ShopSystemItem
          image={shopItemImage}
          title={title}
          onTitleInputChange={titleInputChangeHandler}
          description={description}
          onDescriptionChange={descriptionChangeHandler}
          titleInputRef={titleInputRef}
          gemsNumber={gemsNumber}
          unlockLevel={unlockLevel}
          leftItemNumber={shopItemDetail?.currentStock}
          costDollar={costDollar}
          onCostDollarChange={costDollarChangeHandler}
          onGemsNumberChange={gemsNumberChangeHandler}
          onUnlockLevelChange={unlockLevelChangeHandler}
          isStore={isStore}
          onStoreChange={storeChangeHandler}
          isPrizeWheel={isPrizeWheel}
          onPrizeWheelChange={prizeWheelChangeHandler}
          initialStock={shopItemDetail?.initialStock}
          currentStock={shopItemDetail?.currentStock}
          addedInventoryNum={addedInventoryNum}
          onAddedInventoryNumChange={addedInventoryNumChangeHandler}
          pickedUpNum={shopItemDetail?.pickedUpNum}
          setPickUpNumState={setPickUpNumState}
          notPickedUpNum={
            shopItemDetail?.soldNum - shopItemDetail?.pickedUpNum || 0
          }
          soldNum={shopItemDetail?.soldNum}
          onAddStockClick={addStockClickedHandler}
          onAddClick={addClickedHandler}
          onDeleteItemClick={() => {
            setShopDetailPageStatus(dispatch, "DELETE_ITEM_POPUP");
          }}
          onUpdateItemClick={updateClickedHandler}
          pageStatus={pageStatus}
          onEditInventory={onEditInventory}
          onChangeItemImg={changeItemImgHandler}
          raffelTicket={itemId}
        />
      )}

      {pageStatus === "SHOP_MESSAGE_POPUP" && !toastMessage?.hasError && (
        <Confirm message={toastMessage?.message}>
          <AnimationCheck />
        </Confirm>
      )}
      {pageStatus === "DELETE_ITEM_POPUP" && (
        <DeleteConfirm
          onDelete={deleteClickedHandler}
          close={() => {
            setShopDetailPageStatus(dispatch, "DEFAULT");
          }}
        />
      )}
    </>
  );
};

export default ShopDetailContainer;
