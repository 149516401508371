import styled from "styled-components";

export const StudentInfoChartContainer = styled.div`

`;

export const StudentInfoChartContain = styled.div`
    border:4px solid #000;
    border-radius:12px;
`;

export const StudentInfoChartTitle = styled.div`
    border-bottom:4px solid #000;
    position:relative;
    justify-content: center;
`;

export const StudentInfoPersonaText = styled.div`
        position:absolute;
        left:7px;
        top:0px;
        color:#302F45;
        font-size:30px;
        font-weight:600
`;

export const StudentInfoChartTitleText = styled.div`
        color: ${({ Color }) => (Color ? Color : "#000")};
        -webkit-text-stroke: 2px #000;
        font-size:60px;
        font-family:"Rajdhani";
        font-weight:700;
        text-align: center;
        line-height: 75px;

`;

export const StudentInfoChartBox = styled.div`
    display:flex;
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    background-image: url(${({ Background }) => (Background ? Background : "")});
`;

export const StudentInfoChartItems = styled.div`
        min-width: 178px;
        margin: 0 10px;
`;

export const StudentInfoChart = styled.div`

`;

export const StudentInfoMatchFit = styled.div`
        display:flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size:cover;
        background-image: url(${({ Background }) => (Background ? Background : "")});
        justify-content: space-around;
        padding: 10px 0;

`;

export const StudentInfoMatchFitText = styled.div`
        transform: rotate(-90deg);
        font-size:33px;
        font-family: "Rajdhani";
        font-weight: bold;
        color:#000;
        text-align: center;
        justify-content: center;
        align-content: center;
        width: 85px;
        height: 76px;
        align-self: center;
        line-height:38px;
`;

export const StudentInfoMatchFitBox = styled.div`
        background-color:#fff;
        border:4px solid #707070;
        isplay: flex;
        justify-content: space-around;
        margin: 10px 0;
        border-radius:12px;
        padding: 15px 8px;

`;

export const StudentInfoMatchFitBoxTitle = styled.div`
        font-size:22px;
        color:#302F45;
        font-family: "Rajdhani";
        font-weight: bold;
`;

export const StudentInfoMatchFitBoxDescription = styled.div`
        font-size:13px;
        color:#495057;
        font-family: "Rajdhani";
        font-weight: 600;
        div{
                padding:2px 0;
        }
`;

export const StudentInfoBoosterHeader = styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 8px 0 10px;
`;

export const StudentBoosterTitle = styled.div`
        font-weight: 700;
        font-size:22px;
        color:#302F45;
`;

export const StudentBoosterImage = styled.img`

`;

export const StudentInfoBoosterDesc = styled.div`
        padding: 3px 8px 10px 10px;
        font-size: 13px;
        div{
                padding:3px 0;
        }
`;

export const StudentInfoBooster = styled.div`
        border:4px solid #000;
        border-radius:14px
`;


