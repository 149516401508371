import React from "react";

const XpPanel = ({
  xpInfo,
  onXpContainerClick,
  onDescriptionChange,
  onXpChange,
  onAddXpClick,
}) => {
  const today = new Date();
  return (
    <>
      <div className="xpContainer" onClick={onXpContainerClick}></div>
      <div className="xpPanle" style={{ top: "200px" }}>
        <div className="xpPanel_header">Add XP</div>
        <div className="xp_description">
          <input
            type="text"
            name="description"
            placeholder="Description"
            className="xpDescription"
            value={xpInfo.description}
            onChange={onDescriptionChange}
          />
        </div>
        <div className="xp_description">
          <input
            type="text"
            name="amount"
            placeholder="Xp Amount"
            className="xpDescription"
            value={xpInfo.amount}
            onChange={onXpChange}
          />
          <button className="addxpbutton" onClick={onAddXpClick}>
            +
          </button>
        </div>
        <div className="xpDate">
          <p className="date_text">Date</p>
          <p className="date_content">
            {today.getMonth() + 1}/{today.getDate()}/{today.getFullYear()}
          </p>
        </div>
      </div>
    </>
  );
};

export default XpPanel;
