import React from "react";
import {
  ContentHeader,
  ContentHeaderTitle,
} from "components/styled.components";
import RaffleSystemContainer from "containers/Raffle/RaffleSystem";
import ShopSystemInventory from "components/shop/shopSystem/inventory";
import { setRafflePageStatus } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { rafflePageStatusSelector } from "store/selectors";
import { LOADING_INVENTORY } from "constant/inventory";
const RaffleSystemPage = ({ match, history }) => {
  const pageStatus = useSelector(rafflePageStatusSelector);

  const dispatch = useDispatch();

  const onEditInventoryHandler = () => {
    setRafflePageStatus(dispatch, "INVENTORY");
    dispatch({
      type: LOADING_INVENTORY,
    });
  };
  const onBackHandler = () => {
    setRafflePageStatus(dispatch, "DEFAULT");
    dispatch({
      type: LOADING_INVENTORY,
    });
  };
  return (
    <>
      <ContentHeader>
        <ContentHeaderTitle>RAFFLE TICKET</ContentHeaderTitle>
        {pageStatus === "INVENTORY" ? (
          <ShopSystemInventory
            itemId={match.params.id}
            onEditInventoryHandler={onEditInventoryHandler}
            onBackItemClick={onBackHandler}
            type="raffelTicket"
            history={history}
          />
        ) : (
          <RaffleSystemContainer
            raffleId={match.params.id}
            history={history}
            onEditInventoryHandler={onEditInventoryHandler}
            type="raffelTicket"
          />
        )}
      </ContentHeader>
    </>
  );
};

export default RaffleSystemPage;
