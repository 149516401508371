import React, { useMemo, useState } from "react";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import UploadIcon from "assets/image/uploadIcon.png";
import {
  Wrapper,
  Header,
  Title,
  ListWrapper,
  HeadTr,
  HeadTh,
  UploadItemBtn,
  UploadItemIcon,
} from "./index.style";
import RowTable from "components/row-td/row-td.component";

const InventoryList = ({ InventoryList,shopItem,raffelTicket }) => {
  const renderInventory = useMemo(
    () =>
      InventoryList &&
      Object.values(InventoryList).map((inventory) => {
        const {
          stock_id,
          sold,
          serialNumber,
          digitalCode,
          date_Purchased,
          pickup,
        } = inventory;

        return (
          <RowTable
            stock_id={stock_id}
            sold={sold}
            serialNumber={serialNumber}
            digitalCode={digitalCode}
            date_Purchased={date_Purchased}
            pickup={pickup}
            shopItem={shopItem}
            raffelTicket={raffelTicket}
          />
        );
      }),
    [InventoryList]
  );
  return (
    <Wrapper>
      <Header>
        <Title>Inventory</Title>
        <UploadItemBtn>
          Upload Items
          <UploadItemIcon src={UploadIcon} />
        </UploadItemBtn>
      </Header>
      <ListWrapper style={{ height: "100%" }}>
        <HeadTr>
          <HeadTh>Stock_id</HeadTh>
          <HeadTh>Serial Number</HeadTh>
          <HeadTh>Digital code</HeadTh>
          <HeadTh>Date Purchased </HeadTh>
          <HeadTh>Purchased?</HeadTh>
          <HeadTh>Picked up </HeadTh>
        </HeadTr>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            marginBottom: "6%",
            overFlowX: "hidden",
          }}
        >
          {renderInventory}
        </div>
      </ListWrapper>
    </Wrapper>
  );
};

export default InventoryList;
