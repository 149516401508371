import React, { useRef, useCallback } from "react";
import {
  Wrapper,
  Header,
  HeaderImg,
  Footer,
  BuyButton,
  CancelButton,
  ButtonText,
  DescTextbox,
  MiddleWrapper,
  TopInput,
  TopWrapper,
  BottomWrapper,
  LeftItem,
  GemWrapper,
  GemCount,
  LockWrapper,
  LockCount,
  Lock,
  Gem,
  ShopEditButton,
  ShopEditButtonText,
  ShopEditButtonIcon,
  Spinner,
} from "./index.style";
import GradegyTshirt from "assets/image/gradegyTshirt.png";
import LockIcon from "assets/image/lockIcon.png";
import GemIcon from "assets/image/gemIcon.png";
import { MdEdit } from "react-icons/md";

const LeftColumn = ({
  image,
  title,
  onChangeItemImg,
  titleInputRef,
  onTitleInputChange,
  gemsNumber,
  unlockLevel,
  leftItemNumber,
  description,
  onDescriptionChange,
}) => {
  const shopItemImgInputRef = useRef();
  const handleOnEditImgClicked = useCallback(() => {
    if (shopItemImgInputRef) shopItemImgInputRef.current.click();
  }, []);
  return (
    <Wrapper>
      <Header style={{ position: "relative" }}>
        {image?.isLoading ? (
          <Spinner />
        ) : (
          <HeaderImg src={image?.url || GradegyTshirt} />
        )}
        <input
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          ref={shopItemImgInputRef}
          onChange={onChangeItemImg}
        />
        <ShopEditButton onClick={handleOnEditImgClicked}>
          <ShopEditButtonText>Edit</ShopEditButtonText>
          <ShopEditButtonIcon>
            <MdEdit color="#34C38F" size={12} />
          </ShopEditButtonIcon>
        </ShopEditButton>
      </Header>
      <MiddleWrapper style={{ zIndex: 2 }}>
        <TopWrapper>
          <TopInput
            ref={titleInputRef}
            onChange={onTitleInputChange}
            value={title}
          />
        </TopWrapper>
        <BottomWrapper>
          <LeftItem>{leftItemNumber} left </LeftItem>
          <GemWrapper>
            <GemCount>{gemsNumber}</GemCount>
            <Gem src={GemIcon} />
          </GemWrapper>
          <LockWrapper>
            <Lock src={LockIcon} />
            <LockCount>{unlockLevel}</LockCount>
          </LockWrapper>
        </BottomWrapper>
      </MiddleWrapper>
      <DescTextbox value={description} onChange={onDescriptionChange} />
      <Footer>
        <BuyButton>
          <ButtonText>Buy</ButtonText>
        </BuyButton>
        <CancelButton>
          <ButtonText>Cancel</ButtonText>
        </CancelButton>
      </Footer>
    </Wrapper>
  );
};

export default LeftColumn;
