import React from "react";
import {
  ContentHeader,
  ContentHeaderTitle,
  StudentHeaderControle,
  SearchStudent,
  SearchStudentContainer,
} from "components/styled.components";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  LabelStudent,
} from "components/styled.components/create.student";
import { HomeSearchStudentContainer } from "assets/style/Home.style";
import { BiSearchAlt } from "react-icons/bi";
import SelectComponent from "components/control.component/select.component";
// import Select2 from 'react-select2-wrapper';
import GradegyDropdown from "components/gradegyDropDown";

const Header = ({
  userRole,
  organizations,
  selectedOrganization,
  onOrganizationChange,
  searchProjectInput,
  onSearchProjectChange,
}) => {
  return (
    <>
      <ContentHeader>
        <ContentHeaderTitle
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
            top: -68,
            zIndex: 2,
          }}
        >
          Home
          <CreateStudentFormCol Direction="column" style={{ marginLeft: 40 }}>
            {userRole === "ultraAdmin" ? (
              <>
                <LabelStudent style={{ fontWeight: 400 }}>
                  Organizations
                </LabelStudent>

                <SelectComponent
                  options={organizations}
                  name="Organization"
                  selected={true}
                  selectedValue={selectedOrganization}
                  onChange={(e) => onOrganizationChange(e.target.value)}
                  style={{ minWidth: 180 }}
                />
              </>
            ) : (
              <LabelStudent style={{ fontWeight: 400 }}>
                {organizations?.find((org) => {
                  return org?._id === selectedOrganization;
                })?.title ?? ""}
              </LabelStudent>
            )}
          </CreateStudentFormCol>
        </ContentHeaderTitle>
        <CreateStudentFormRow
          style={{
            paddingRight: 30,
            marginTop: 0,
            flexWrap: "wrap",
            flexDirection: "row",
            height: "auto",
          }}
        >
          <StudentHeaderControle
            style={{ maxWidth: 1380, width: "40%", paddingTop: 0, height: 1 }}
          >
            <HomeSearchStudentContainer>
              <SearchStudentContainer>
                <BiSearchAlt
                  spaceLeft="18px"
                  style={{ position: "absolute", top: 11, left: 18 }}
                  color={"#707070"}
                />
                <SearchStudent
                  type="text"
                  value={searchProjectInput}
                  placeholder="Search Project"
                  onChange={onSearchProjectChange}
                />
              </SearchStudentContainer>
            </HomeSearchStudentContainer>
          </StudentHeaderControle>
        </CreateStudentFormRow>
      </ContentHeader>
      <div className="App"></div>
    </>
  );
};

export default Header;
