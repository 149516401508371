import { gradify } from "axios.config";
import {
  EXPERIENCE_PHOTO_PENDING,
  EXPERIENCE_PHOTO_SUCCESS,
  EXPERIENCE_PHOTO_FAIL,
  EXPERIENCE_PHOTO_CHANGE,
} from "../../constant/photo.experience";

export const photoExperineceAction =
  (experienceId, page) => async (dispatch, getState) => {
    dispatch({
      type: EXPERIENCE_PHOTO_PENDING,
    });

    try {
      const { data } = await gradify.get(
        `experiencephoto/getphotosbyexperience?experienceId=${experienceId}&page=${page}`
      );
      dispatch({
        type: EXPERIENCE_PHOTO_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_PHOTO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const changePhotoToggleAction =
  (experience, user, complete) => (dispatch) => {
    dispatch({
      type: EXPERIENCE_PHOTO_CHANGE,
      payload: {
        user,
        experience,
        complete,
      },
    });
  };
