import { SET_APP_FILTERED_SYSTEM_ID } from "constant";
import store from "Store";
export const setAppSelectedProjectIdFilter = (dispatch, projectId) => {
  dispatch({
    type: SET_APP_FILTERED_SYSTEM_ID,
    payload: {
      projectId: projectId,
      categoryId: undefined,
      subCategoryId: undefined,
    },
  });
};
export const setAppSelectedCategoryId = (dispatch, categoryId) => {
  const selectedProjectIdFilter =
    store.getState().app.filteredSystemId?.projectId;
  if (categoryId !== "All" && selectedProjectIdFilter) {
    dispatch({
      type: SET_APP_FILTERED_SYSTEM_ID,
      payload: {
        projectId: selectedProjectIdFilter,
        categoryId,
        subCategoryId: undefined,
      },
    });
  }
};
export const setAppSelectedSubCategoryId = (dispatch, subCategoryId) => {
  const selectedProjectIdFilter =
    store.getState().app.filteredSystemId?.projectId;
  const selectedCategoryId = store.getState().app.filteredSystemId?.categoryId;
  if (
    subCategoryId !== "All" &&
    selectedCategoryId &&
    selectedProjectIdFilter
  ) {
    dispatch({
      type: SET_APP_FILTERED_SYSTEM_ID,
      payload: {
        projectId: selectedProjectIdFilter,
        categoryId: selectedCategoryId,
        subCategoryId,
      },
    });
  } else {
    dispatch({
      type: SET_APP_FILTERED_SYSTEM_ID,
      payload: {
        projectId: selectedProjectIdFilter,
        categoryId: selectedCategoryId,
        subCategoryId: undefined,
      },
    });
  }
};
export const clearAppSelectedFilterIds = (dispatch) => {
  dispatch({
    type: SET_APP_FILTERED_SYSTEM_ID,
    payload: {
      categoryId: undefined,
      subCategoryId: undefined,
    },
  });
};
