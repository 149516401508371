import {
  COMPELTION_LIST_FAIL,
  COMPELTION_LIST_REQUEST,
  COMPELTION_LIST_SUCCESS,

  COMPLETION_DETAILS_FAIL,
  COMPLETION_DETAILS_REQUEST,
  COMPLETION_DETAILS_SUCCESS
} from "../constant/completion";
import { gradify } from "../axios.config";

export const getCompletionList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPELTION_LIST_REQUEST,
    });
    const { data } = await gradify.get("completiontype", {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });
    dispatch({
      type: COMPELTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPELTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCompletionDetails = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type:COMPLETION_DETAILS_REQUEST
    
  })
  const {data:{data}} = await gradify.get(`completiontype/${id}`, {
    headers: { Authorization: "Bearer " + getState().auth.token }
  })
  dispatch({
    type:COMPLETION_DETAILS_SUCCESS,
    payload:data
  })
   } catch (error) {
    dispatch({
      type: COMPLETION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
   }
  
}
