import React, { useEffect, useState } from "react";
import organizationImage from "../assets/image/organization-image.png";
import { Paper } from "@material-ui/core";
import PolygonCharts from "../components/charts/PolygonCharts";
import OrganizationsProjectsTable from "../components/organizations/OrganizationProjectsTable";
import EditOrganizationComponent from "../components/organizations/EditOrganization";
import AddProjectPopup from "../components/organizations/AddProject";
import { StudentButton } from "../components/styled.components";

import { MdEdit } from "react-icons/md";
import {
  OrganizationProjectEditButtton,
  OrganizationEditButttonText,
  OrganizationEditButttonIcon,
  OrganizationDetailsContainer,
  OrganizationPic,
  OrganizationInfo,
} from "../components/organizations/AddOrganization.style";
import { BiSearchAlt } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";

import {
  SearchOrganizations,
  SearchOrganizationsContainer,
  OrganizationsButton,
} from "../components/styled.components";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsOrganizationAction } from "../actions/organizationActions";

const OrganizattionDetails = ({ match }) => {
  const id = match.params.id;

  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddProjectForm, setShowAddProjectForm] = useState(false);
  const dispatch = useDispatch();
  const { organization, loading, error } = useSelector(
    (state) => state.getDetailsOrganization
  );

  useEffect(() => {
    dispatch(getDetailsOrganizationAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (organization) {
    }
  }, [organization]);
  const closeEditForm = () => {
    setShowEditForm(false);
  };
  const closeAddProjectForm = () => {
    setShowAddProjectForm(false);
  };

  return (
    <OrganizationDetailsContainer>
      {true ? (
        <>
          <Paper
            elevation={3}
            className="st-info"
            style={{ flexDirection: "column" }}
          >
            <OrganizationInfo>
              <OrganizationPic>
                <PolygonCharts
                  XP={3499}
                  studentPic={organization?.image}
                  color="#000"
                  style={{ transform: "scale(0.35)", top: -98, left: -17 }}
                />
              </OrganizationPic>
              <div style={{ paddingLeft: 40 }}>
                <h1>{organization?.title}</h1>
                <OrganizationProjectEditButtton
                  onClick={() => {
                    setShowEditForm(true);
                  }}
                >
                  <OrganizationEditButttonText>
                    Edit
                  </OrganizationEditButttonText>
                  <OrganizationEditButttonIcon>
                    <MdEdit color="#34C38F" size={12} />
                  </OrganizationEditButttonIcon>
                </OrganizationProjectEditButtton>
                {showEditForm ? (
                  <EditOrganizationComponent
                    close={closeEditForm}
                    organization={organization}
                  />
                ) : null}
              </div>
            </OrganizationInfo>
            <div style={{ display: "flex", paddingTop: 25 }}>
              <div
                className="student-info first-col"
                style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
              >
                <div style={{ width: "12%" }}>
                  <h3>Organization Type</h3>
                  <p>{organization?.organization_type}</p>
                </div>
                <div style={{ width: "12%" }}>
                  <h3>Organization ID </h3>
                  <p>{organization?._id}</p>
                </div>
                <div style={{ width: "12%" }}>
                  <h3>Lead contact</h3>
                  <p>
                    {organization?.lead_contact?.firstName +
                      " " +
                      organization?.lead_contact?.lastName}
                  </p>
                </div>
                <div style={{ width: "12%" }}>
                  <h3>UFCS Member</h3>
                  <p>{organization?.ufcs_member ? "Yes" : "No"}</p>
                </div>
                <div style={{ width: "12%" }}>
                  <h3>Paid</h3>
                  <p>{organization?.paid}</p>
                </div>
                <div style={{ width: "12%" }}>
                  <h3>School district</h3>
                  <p>{organization?.schoolDistrict}</p>
                </div>
                <div style={{ width: "12%" }}>
                  <h3>University</h3>
                  <p>
                    {organization?.university
                      ? organization?.university
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </Paper>

          <Paper elevation={3} style={{ marginTop: 20, borderRadius: 12 }}>
            <div
              className="st-info st-info-game-status"
              style={{
                minHeight: 467,
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div className="st-info-team-container">
                <div style={{ display: "flex" }}>
                  <h3 className="st-info-title">Projects</h3>
                  <SearchOrganizationsContainer>
                    <BiSearchAlt
                      spaceLeft="18px"
                      style={{ position: "absolute", top: 15, left: 48 }}
                      color={"#707070"}
                    />
                    <SearchOrganizations
                      type="text"
                      placeholder="Search Organizations"
                    />
                  </SearchOrganizationsContainer>
                  <OrganizationsButton
                    style={{
                      marginLeft: 85,
                      height: 42,
                      width: 240,
                      position: "relative",
                      justifyContent: "start",
                    }}
                    onClick={() => {
                      setShowAddProjectForm(true);
                    }}
                  >
                    <span style={{ paddingLeft: 12, fontSize: 17 }}>
                      {" "}
                      Add Project
                    </span>
                    <MdAddCircle
                      size={34}
                      style={{ position: "absolute", right: 3, top: 6 }}
                    />
                  </OrganizationsButton>

                  {showAddProjectForm ? (
                    <AddProjectPopup close={closeAddProjectForm} id={id} />
                  ) : null}
                </div>
              </div>
              <div className="st-info-team-container gradegy-organization">
                <OrganizationsProjectsTable id={id} />
              </div>
            </div>
          </Paper>
        </>
      ) : null}
    </OrganizationDetailsContainer>
  );
};

export default OrganizattionDetails;
