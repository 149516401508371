export const SUBCATEGORY_LIST_REQUEST = "SUBCATEGORY_LIST_REQUEST"
export const SUBCATEGORY_LIST_SUCCESS = "SUBCATEGORY_LIST_SUCCESS"
export const SUBCATEGORY_LIST_FAIL = "SUBCATEGORY_LIST_FAIL"


export const SUBCATEGORY_PROJECT_LIST_REQUEST = "SUBCATEGORY_PROJECT_LIST_REQUEST";
export const SUBCATEGORY_PROJECT_LIST_SUCCESS = "SUBCATEGORY_PROJECT_LIST_SUCCESS";
export const SUBCATEGORY_PROJECT_LIST_FAIL = "SUBCATEGORY_PROJECT_LIST_FAIL"


export const SUBCATEGORY_PROJECT_ADD_REQUEST = "SUBCATEGORY_PROJECT_ADD_REQUEST";
export const SUBCATEGORY_PROJECT_ADD_SUCCESS = "SUBCATEGORY_PROJECT_ADD_SUCCESS";
export const SUBCATEGORY_PROJECT_ADD_FAIL = "SUBCATEGORY_PROJECT_ADD_FAIL"


export const SUBCATEGORY_PROJECT_DELETE_REQUEST = "SUBCATEGORY_PROJECT_DELETE_REQUEST";
export const SUBCATEGORY_PROJECT_DELETE_SUCCESS = "SUBCATEGORY_PROJECT_DELETE_SUCCESS";
export const SUBCATEGORY_PROJECT_DELETE_FAIL = "SUBCATEGORY_PROJECT_DELETE_FAIL"
