import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background-color: white;
`;
export const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  gap: 26px;
  flex-direction: row;
  @media (max-width: 1650px) {
    gap: 16px;
  }
  @media (max-width: 1500px) {
    flex-wrap:wrap;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: row;
  margin-left: 12px;
  margin-top: 15px;
`;
export const AddBtn = styled.button`
  width: 149px;
  height: 46px;
  background-color: #0078ff;
  color: white;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  border: none;
  outline: none;
  border-radius: 20px;
`;
export const UpdateBtn = styled.button`
  width: 149px;
  height: 46px;
  background-color: #34C38F;
  color: white;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  border: none;
  outline: none;
  border-radius: 20px;
`;
export const DeleteBtn = styled.button`
  width: 57px;
  height: 57px;
  background-color: #0078ff;
  color: white;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  border: none;
  outline: none;
  border-radius: 50%;
`;