import * as React from 'react'

function StarSvg({stroke , fill , width , height}) {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 27.666 26.512">
      <defs>
        <linearGradient id="linear-gradient" x1="0.383" y1="0.263" x2="0.825" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#626060"/>
          <stop offset="1" stopColor="#f7f7f7"/>
        </linearGradient>
        </defs>
        <g id="Group_3765" dataName="Group 3765" transform="translate(17668.018 18352)">
        <g id="Component_99_1" dataName="Component 99 – 1" transform="translate(-17667.5 -18351.5)">
          <path id="Icon_awesome-star"
           dataName="Icon awesome-star" d="M13.328.886l-3.25,6.591L2.805,8.537a1.594,1.594,0,0,0-.881,2.718l5.261,5.127L5.941,23.624A1.592,1.592,0,0,0,8.251,25.3l6.506-3.42,6.506,3.42a1.593,1.593,0,0,0,2.31-1.677l-1.244-7.243,5.261-5.127a1.594,1.594,0,0,0-.881-2.718l-7.272-1.06L16.185.886a1.594,1.594,0,0,0-2.857,0Z" 
           transform="translate(-1.441 0.001)" 
        //    stroke="#7b7777"
           stroke={stroke}
           strokeWidth="1" 
        //    fill="url(#linear-gradient)"
           fill={fill}
           />
         </g>
      </g>
</svg>

  )
}

export default StarSvg