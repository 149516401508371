import  { selectStudentDevAreaScore } from '../../store/selectors/Students'
import {calulateCircleChartPercent} from '../../helpers/calculatePercent';
import { connect } from 'react-redux';
import MomentumChart from "components/momentum-chart/momentum-chart.component";
const mapStateToProps = (state) => {
    const percent = selectStudentDevAreaScore(state);
    const chartPercentCal = calulateCircleChartPercent(495,percent);
    
    return{
          percent,
          chartPercentCal  
    }
}

export const DevAreaScoreContainer = connect(mapStateToProps)(MomentumChart);