import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import CustomSwitch from "../control.component/switch/CustomSwitch";
import "./experienceStudentList.css";
import {
  getAllExperienceUserActions,
  updateCompletionExperienceUserAction,
} from "../../actions/experienceUserActions";
import { useDispatch, useSelector } from "react-redux";
import { gradify } from "../../axios.config";
import { listUserExperienceAction } from "../../actions/listUserExperienceAction";
import Spinner from "../spinner/spinner";
import { progressAction } from "actions/pmChartAction";
import { newMomentumAction } from "actions/momentumAction";
import { getAllUserXpByOrganizationProject } from "actions/xpsAction";
import { expereincePhotoChangeCompleteAction } from "actions/experienceStudentList/experienceStudentList.action";
import { changePhotoToggleAction } from "actions/photo-experience";
import { changeCompleteReivewAction } from "actions/newExperienceReview/newExpereinceReview.action";
const ExperienceStudentListItem = ({
  item,
  select,
  groupSelect,
  experience,
}) => {
  const [selectState, setSelectState] = useState(select);
  const [completeText, setCompleteText] = useState("Not Complete");
  const dispatch = useDispatch();
  const { loading, error, experienceUserList } = useSelector(
    (state) => state.experienceUserList
  );

  const { token } = useSelector((state) => state.auth);
  const [toggleState, setToggleState] = useState(false);

  useEffect(() => {
    if (item) {
      if (item.complete) {
        console.log(item.complete);
        setToggleState(item.complete.complete);
      }
    }
  }, [item]);

  const changeToggleStateHanlder = async () => {
    const updateComplete = await gradify.put(
      `experiencestudentcomplete/updateexpstudent?experience=${
        experience?._id
      }&user=${item?._id}&complete=${!toggleState}`
    );
    dispatch(
      progressAction(
        item._id,
        experience.project,
        experience.category ? experience.category : null,
        "2022-01-01"
      )
    );
    dispatch(
      newMomentumAction(
        item._id,
        experience.project,
        experience.category ? experience.category : null,
        15
      )
    );
    if (!toggleState) {
      await gradify.post(
        `xp`,
        {
          amount: experience.xpCompletion,
          user: item?._id,
          experience: experience?._id,
          organization: experience.organization,
          project: experience.project,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      await gradify.post(
        `coins`,
        {
          amount: experience.coins,
          user: item?._id,
          experience: experience?._id,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      dispatch(
        getAllUserXpByOrganizationProject(
          item?._id,
          experience.organization,
          experience.project
        )
      );
    } else {
      await gradify.delete(`xp/${item?._id}/${experience?._id}`, {
        headers: { Authorization: "Bearer " + token },
      });
      await gradify.delete(`coins/${item?._id}/${experience?._id}`, {
        headers: { Authorization: "Bearer " + token },
      });
      dispatch(
        getAllUserXpByOrganizationProject(
          item?._id,
          experience.organization,
          experience.project
        )
      );
    }

    setToggleState(!toggleState);
    console.log("updateComplete", updateComplete.data.data);
    dispatch(changeCompleteReivewAction(item._id, updateComplete.data.data));
    // dispatch(
    //   expereincePhotoChangeCompleteAction(
    //     experience._id,
    //     item?._id,
    //     !toggleState
    //   )
    // );
    // dispatch(changePhotoToggleAction(experience._id, item?._id, !toggleState));
  };

  return (
    <>
      {loading ? (
        <Spinner style={{ width: 50, height: 50, top: 20 }} />
      ) : error ? (
        console.log(error)
      ) : (
        <TableRow>
          <TableCell>
            {" "}
            <Checkbox
              checked={selectState}
              // onChange={() => setSelectState(!selectState)}
            />
          </TableCell>
          <TableCell>
            {" "}
            <p className="item-student-name-ex">
              {item.firstName + " " + item.lastName}
            </p>{" "}
          </TableCell>
          <TableCell>
            <span
              className={
                item.complete
                  ? item.complete.complete
                    ? "student-list-ex-com"
                    : "student-list-ex-com-not"
                  : "student-list-ex-com-not"
              }
            >
              {item.complete
                ? item.complete.complete
                  ? "Compeleted"
                  : "Not Compeleted"
                : "Not Compeleted"}
            </span>
          </TableCell>
          <TableCell>
            {item?.complete
              ? new Date(item.complete.updatedAt).toLocaleDateString()
              : "---"}
          </TableCell>
          <TableCell>
            <CustomSwitch
              isToggle={toggleState}
              onChangeToggle={changeToggleStateHanlder}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ExperienceStudentListItem;
