import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Column,
  Row,
  InnerCol,
  SubTitle,
  Label,
  SwitchLabel,
  ConstantValue,
} from "assets/style/General.style";
import VerticalText from "components/general/verticalText";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import SelectComponent from "components/control.component/select.component";
import CircleButton from "components/general/circleButton";
import List from "components/general/list";
import { changeSpecialProjectAccessPermissionAction } from "actions";
const SpecialProjects = ({
  onSpecialProjectOptionChange,
  specialProjectsOption,
  specialProjectList,
  selectedSpecialProject,
  onSpecialProjectClick,
  onDeleteSpecialProjectItem,
  organizationName,
  onAddSpecialProjectClick,
  categoriesOptions,
  onCategoryOptionChange,
  subCategoriesOptions,
  onSubCategoryOptionChange,
  selectedSpecialProjectOption,
}) => {
  const dispatch = useDispatch();
  return (
    <Box elevation={3} className="st-info" style={{ flexDirection: "row" }}>
      <Row Direction="column" Width="40%" tabletWidth={"100%"}>
        <Column style={{ marginBottom: -10 }}>
          <SubTitle>Special Projecs</SubTitle>
        </Column>
        <Column style={{ alignItems: "end" }}>
          <InnerCol Direction="column">
            <Label>Organization</Label>
            <ConstantValue SpacingLR={"0"} title={organizationName}>
              {organizationName}
            </ConstantValue>
          </InnerCol>
          <InnerCol Direction="column">
            <Label>Project</Label>
            <SelectComponent
              options={specialProjectsOption}
              name="project"
              selected={true}
              selectedValue={selectedSpecialProjectOption?.projectId}
              onChange={onSpecialProjectOptionChange}
            />
          </InnerCol>
          <InnerCol Direction="column">
            <Label>Category</Label>
            <SelectComponent
              options={categoriesOptions}
              name="category"
              selected={true}
              selectedValue={selectedSpecialProjectOption?.categoryId}
              onChange={onCategoryOptionChange}
            />
            <Label>Sub Category</Label>
            <SelectComponent
              options={subCategoriesOptions}
              name="subCategory"
              selected={true}
              selectedValue={selectedSpecialProjectOption?.subCategoryId}
              onChange={onSubCategoryOptionChange}
            />
          </InnerCol>
          <InnerCol style={{ padding: "0px 10px 5px 0" }} align="center">
            <CircleButton onClick={onAddSpecialProjectClick} />
          </InnerCol>
        </Column>
      </Row>

      <Row Width="30%" tabletWidth={"50%"}>
        <Column>
          <List
            listArray={Object.values(specialProjectList)}
            selectedId={selectedSpecialProject?._id}
            ItemClick={onSpecialProjectClick}
            CloseItemClick={onDeleteSpecialProjectItem}
          />
        </Column>
      </Row>

      <Row Width="20%" tabletWidth={"40%"} minWidth={"240px"}>
        {selectedSpecialProject && (
          <>
            <Column Width="fit-content" style={{ justifyContent: "end" }}>
              <VerticalText text={selectedSpecialProject?.title} width="20px" />
            </Column>
            <Column Direction="column" style={{ minWidth: "210px" }}>
              <InnerCol alignment="space-between">
                <SwitchLabel>Users</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedSpecialProject.access?.users || false}
                  onChangeToggle={() => {
                    changeSpecialProjectAccessPermissionAction(
                      dispatch,
                      "users",
                      !selectedSpecialProject.access?.users
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>Experience cards</SwitchLabel>
                <CustomSwitch
                  isToggle={
                    selectedSpecialProject.access?.experienceCards || false
                  }
                  onChangeToggle={() => {
                    changeSpecialProjectAccessPermissionAction(
                      dispatch,
                      "experienceCards",
                      !selectedSpecialProject.access?.experienceCards
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>surveys</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedSpecialProject.access?.surveys || false}
                  onChangeToggle={() => {
                    changeSpecialProjectAccessPermissionAction(
                      dispatch,
                      "surveys",
                      !selectedSpecialProject.access?.surveys
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>stores</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedSpecialProject.access?.store || false}
                  onChangeToggle={() => {
                    changeSpecialProjectAccessPermissionAction(
                      dispatch,
                      "store",
                      !selectedSpecialProject.access?.store
                    );
                  }}
                />
              </InnerCol>
            </Column>
          </>
        )}
      </Row>
    </Box>
  );
};

export default SpecialProjects;
