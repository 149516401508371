import { gradify } from "axios.config";
import {
  SCORES_REQUEST,
  SCORES_SUCCESS,
  SCORES_FAILD,
} from "../../constant/scores";

export const getScoresAction =
  (
    user,
    project,
    progressScore,
    selfEfficacySRscore,
    futureDevSRscore,
    connectSRScore,
    emoSRScore,
    phq_9Score,
    socialSRScore,
    situationalSRScore,
    situationalDataScore,
    academicSRScore,
    academicDataScore,
    financialSRScore,
    financialDataScore,
    gritScore,
    category,
    categories
  ) =>
  async (dispatch) => {
    dispatch({
      type: SCORES_REQUEST,
    });

    try {
      let query = "";
      if (!category) {
        query = `scores?project=${project._id}&user=${user}&progressScore=${progressScore}&selfEfficacySRscore=${selfEfficacySRscore}&futureDevSRscore=${futureDevSRscore}&connectSRScore=${connectSRScore}&emoSRScore=${emoSRScore}&phq_9Score=${phq_9Score}&socialSRScore=${socialSRScore}&situationalSRScore=${situationalSRScore}&situationalDataScore=${situationalDataScore}&academicSRScore=${academicSRScore}&academicDataScore=${academicDataScore}&financialSRScore=${financialSRScore}&financialDataScore=${financialDataScore}&gritScore=${gritScore}`;
      } else if (category && categories?.length < 1) {
        query = `scores?project=${project._id}&user=${user}&progressScore=${progressScore}&selfEfficacySRscore=${selfEfficacySRscore}&futureDevSRscore=${futureDevSRscore}&connectSRScore=${connectSRScore}&emoSRScore=${emoSRScore}&phq_9Score=${phq_9Score}&socialSRScore=${socialSRScore}&situationalSRScore=${situationalSRScore}&situationalDataScore=${situationalDataScore}&academicSRScore=${academicSRScore}&academicDataScore=${academicDataScore}&financialSRScore=${financialSRScore}&financialDataScore=${financialDataScore}&category=${category._id}&gritScore=${gritScore}`;
      } else if (category && categories?.length > 0) {
        const categoriesIds = categories.map((i) => i._id);
        query = `scores?project=${project._id}&user=${user}&progressScore=${progressScore}&selfEfficacySRscore=${selfEfficacySRscore}&futureDevSRscore=${futureDevSRscore}&connectSRScore=${connectSRScore}&emoSRScore=${emoSRScore}&phq_9Score=${phq_9Score}&socialSRScore=${socialSRScore}&situationalSRScore=${situationalSRScore}&situationalDataScore=${situationalDataScore}&academicSRScore=${academicSRScore}&academicDataScore=${academicDataScore}&financialSRScore=${financialSRScore}&financialDataScore=${financialDataScore}&category=${categoriesIds}&gritScore=${gritScore}`;
      }
      const { data } = await gradify.get(query);
      dispatch({
        type: SCORES_SUCCESS,
        payload: data.data,
      });
    } catch (error) {}
  };
