import React from "react";
import {
  ContentHeader,
  ContentHeaderTitle,
} from "components/styled.components";
import ShopSystemContainer from "containers/Shop/ShopSystem";
import ShopSystemInventory from "../../../components/shop/shopSystem/inventory/index";
import { useDispatch, useSelector } from "react-redux";
import { setShopDetailPageStatus } from "actions";
import { shopItemDetailPageStatusSelector } from "store/selectors";
import { LOADING_INVENTORY } from "constant/inventory";

const ShopSystemPage = ({ match, history }) => {
  const pageStatus = useSelector(shopItemDetailPageStatusSelector);
  const dispatch = useDispatch();
  const onEditInventoryHandler = () => {
    setShopDetailPageStatus(dispatch, "INVENTORY");
    dispatch({
      type:LOADING_INVENTORY
    })
  };
  const onBackHandler = () => {
    setShopDetailPageStatus(dispatch, "DEFAULT");
    dispatch({
      type:LOADING_INVENTORY
    })
  };

  return (
    <>
      <ContentHeader>
        <ContentHeaderTitle>SHOP ITEM</ContentHeaderTitle>
        {pageStatus === "INVENTORY" ? (
          <ShopSystemInventory itemId={match.params.id} onEditInventoryHandler={onEditInventoryHandler} onBackItemClick={onBackHandler} type="shopItem"  history={history}/>
        ) : (
          
          <ShopSystemContainer
            history={history}
            itemId={match.params.id}
            onEditInventory={onEditInventoryHandler}
            type="shopItem"
          />
        )}
      </ContentHeader>
    </>
  );
};

export default ShopSystemPage;
