import React, { useState } from "react";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import UnlockIcon from "assets/image/unlockIcon.png";
import {
  Wrapper,
  InfoSection,
  InfoTitle,
  InfoItem,
  InfoText,
  InfoTextArea,
  AvailableSection,
  AvailableSectionTitle,
  AvailableSectionItem,
  AvailableText,
  DigitalCodeSection,
  DigitalCodeTitle,
  DigitalCodeItem,
  DigitalCodeAddBtn,
} from "./index.style";

const CenterColumn = ({
  gemsNumber,
  onGemsNumberChange,
  winnerNumber,
  onWinnerNumberChange,
  isStore,
  onStoreChange,
  isPrizeWheel,
  onPrizeWheelChange,
  isDigitalCode,
  onDigitalCodeChange,
  onEditInventoryHandler,
  purchaseState,
  purchaseLimit,
  onChangePurchaseState,
  onChagnePurchaseLimit,
}) => {
  const goToInventoryHandler = () => {
    onEditInventoryHandler();
  };

  const [purchaseLimitToggel, setPurchaseLimitToggel] = useState(false);

  return (
    <Wrapper>
      <InfoSection style={{ height: 180 }}>
        <InfoTitle>Info</InfoTitle>
        <InfoItem>
          <InfoText>Price in Gems</InfoText>
          <InfoTextArea
            value={gemsNumber}
            onChange={onGemsNumberChange}
            placeholder={50 - 500}
            style={{ width: "80px" }}
          />
        </InfoItem>
        <InfoItem>
          <InfoText>Number of Winners</InfoText>
          <InfoTextArea
            value={winnerNumber}
            onChange={onWinnerNumberChange}
            placeholder={2}
            style={{ width: "80px", marginRight: "-60px" }}
          />
          <img src={UnlockIcon} alt="unlock"></img>
        </InfoItem>
        <InfoItem>
          <InfoText
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Purchase limit
            <CustomSwitch
              style={{ marginLeft: 10 }}
              isToggle={purchaseState}
              onChangeToggle={(e) => onChangePurchaseState(!purchaseState)}
            />
          </InfoText>
          {purchaseState && (
            <InfoTextArea
              style={{ width: "80px", marginRight: "11px" }}
              value={purchaseLimit}
              onChange={(e) => onChagnePurchaseLimit(e.target.value)}
            />
          )}
        </InfoItem>
      </InfoSection>
      <AvailableSection>
        <AvailableSectionTitle>Available in</AvailableSectionTitle>
        <AvailableSectionItem>
          <AvailableText>Store</AvailableText>
          <CustomSwitch isToggle={isStore} onChangeToggle={onStoreChange} />
        </AvailableSectionItem>
        <AvailableSectionItem>
          <AvailableText>Prize Wheel</AvailableText>
          <CustomSwitch
            isToggle={isPrizeWheel}
            onChangeToggle={onPrizeWheelChange}
          />
        </AvailableSectionItem>
      </AvailableSection>
      <DigitalCodeSection>
        <DigitalCodeTitle>Inventory</DigitalCodeTitle>
        <DigitalCodeAddBtn onClick={goToInventoryHandler}>
          Edit Full Inventory
        </DigitalCodeAddBtn>
      </DigitalCodeSection>
    </Wrapper>
  );
};

export default CenterColumn;
