import { gradify } from "axios.config";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const LeaderBoardItem = ({ item, index, project }) => {
  return (
    <div className="leader-board-item">
      <div className="leader-board-item-content">
        <div className="leader-board-item-index">{index}</div>
      </div>
      <div className="leader-board-item-content leader-board-item-xp-container">
        <p className="leader-board-item-name">{item.fullName + " "}</p>
        <div className="leader-board-item-xp-content">
          <p className="leader-board-item-xp">
            {item.totalAmount ? item.totalAmount : 0}
          </p>
          <p className="leader-board-item-xp-text">XP</p>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardItem;
