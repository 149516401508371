import { connect } from "react-redux";
import {
  calculatePastDate,
  calulateCircleChartPercent,
} from "helpers/circle-chart/circle-chart";

import { selectMomentumData, selectProgressPercent } from "store/selectors";
import MomentumChart from "./momentum-chart.component";

const mapStateToProps = (state, ownProps) => {
  const { percent, lastPercent } = selectMomentumData(state);
  const chartPercentCal = calulateCircleChartPercent(495, percent);
  const lastChartPercentCal = calulateCircleChartPercent(495, lastPercent);
  return {
    percent,
    lastPercent,
    chartPercentCal,
    lastChartPercentCal,
  };
};

export const MomentumChartContianer = connect(mapStateToProps)(MomentumChart);
