import React from "react";
import "./leaderBoard.style.css";
import LeaderBoardItem from "./LeaderBoardItem";

const LeaderBoard = ({ items, project }) => {
  return (
    <div className="leaderBoard-container">
      {items.map((item, index) => (
        <LeaderBoardItem
          key={item._id}
          item={item}
          index={index + 1}
          project={project}
        />
      ))}
    </div>
  );
};

export default LeaderBoard;
