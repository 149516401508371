import styled from "styled-components";
import arrow from "../../assets/image/ArrowShape.png";

export const AdminHeader = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 35px;
  position: relative;
`;

export const AdminHeaderTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkgray};
  position: absolute;
  top: -45px;
  left: 55px;
  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AdminContainer = styled.div`
  width: calc(100% - 50px);
  margin: 10px 25px;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100vh - 140px);
  margin-top:10;
`;

export const AdminHeaderControle = styled.div`
  width: 100%;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1770px) {
  
  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
`;

export const SearchAdmin = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.lightdark};
  padding-left: 35px;
  text-indent: 10px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 13px;

  &:focus {
    outline: none;
  }
`;

export const SearchAdminContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
  margin-top: 18px;
`;

export const AdminButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 6px 10px 5px 37px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 300;
  margin-top: 10px;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
  }
`;
export const AddAdminButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.buttonBlue};
  border: none;
  border-radius: 50px;
  padding: 11px 46px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 19px;
  font-weight: 500;
`;

export const UploadAdminButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 200px;
  padding: 8px 7px 8px 21px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 13px;
    width: 220px;
  }
  margin-top: 10px;
  white-space: nowrap;
`;

export const AdminContentContainer = styled.div`
  padding-top: 10px;
  max-width: 1618px;
  padding-right: 7.3%;
  @media (max-width: 1770px) {
    
  }
`;
export const CreateAdminFormCol = styled.div`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 7px;
`;

export const CreateAdminFormRow = styled.div`
  width: fit-content;
  display: flex;
  padding-top: 1px;
  @media (max-width: 1390px) {
    flex-wrap: wrap;
  }
`;
export const LabelAdmin = styled.label`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 15px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const CreateAdminContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateAdminForm = styled.div`
  padding-bottom: 10px;
  margin-left: 200px;
  padding-top: 20px;
  width: fit-content;
  height: fit-content;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 1px 2px 14px -1px rgba(0, 0, 0, 0.82);
  min-width: 954px;
  @media (max-width: 1300px) {
    margin-left: 100px;
  }
`;

export const AdminInput = styled.input`
  width: ${({ Width }) => (Width ? Width : "190px")};
  height: ${({ Height }) => (Height ? Height : "32px")};
  text-align: center;
  font-family: ${({ FontFamily }) => FontFamily};
  font-size: ${({ Size }) => Size};
  font-weight: ${({ Weight }) => Weight};
  padding: ${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightdark};
  color: #070708;
  margin-left: 10px;
`;

export const SwitchLabelAdmin = styled.label`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
  font-size: 13px;
  color: #74788d;
  padding-right: 7px;
`;

export const AdminImage = styled.img`
  border-radius: 50%;
  width: 144px;
  height: 144px;
`;

export const AdminHexImage = styled.img`
  position: absolute;
  top: -12px;
  left: -4px;
`;

export const AdminEditButtton = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border: none;
  position: absolute;
  right: -50px;
  bottom: -12px;
`;

export const AdminProjectEditButtton = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border: none;
`;

export const AdminEditButttonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  margin: 0;
  padding-right: 5px;
`;

export const AdminTitle = styled.h3`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  color: #003267;
  margin: 0;
  padding-right: 5px;
`;

export const AdminEditButttonIcon = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;

export const AdminDetailsContainer = styled.div`
  padding: 27px;
  padding-top: 35px;
`;

export const AdminPic = styled.div`
  width: 125px;
  height: 110px;
`;

export const AdminInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const AdminListContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  max-width: 835px;
  border: 1px solid #ced4da;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 17px;
  justify-content: flex-start;
  margin-right: 20px;
`;
export const AdminListProject = styled.div`
  border: 1px solid #ced4da;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 46%;
  white-space: nowrap;
  margin: 5px;
  position: relative;
  color: #74788d;
  font-size: 13px;
  flex-direction: column;
  border-radius: 5px;
  min-width: 110px;
  min-height: 25px;
  padding: 0 25px 0 7px;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const AdminListCloseProject = styled.div`
  position: absolute;
  top: -1px;
  right: 7px;
  color: #313539;
  font-family: system-ui;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const AdminLinkInput = styled.div`
  position: absolute;
  right: 0;
  top: 22px;
`;
export const AdminLink = styled.div`
  position: absolute;
  right: -7px;
  top: 22px;
`;
