import React from "react";
import { ListClose, ListContainer, ListItem } from "./index.style";
import { FaWindowClose, FaLock } from "react-icons/fa";

const List = ({
  listArray,
  itemClassName,
  ItemClick,
  CloseItemClick,
  isDisabled,
  selectedId,
  spicialItems,
}) => {
  return (
    <ListContainer isDisabled={isDisabled}>
      {listArray &&
        listArray.map((item) => (
          <ListItem
            selected={selectedId === item?._id}
            className={itemClassName}
            key={item?._id}
            onClick={() => ItemClick(item._id)}
          >
            <span
              style={{
                maxWidth: 100,
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                textAlign: "start",
              }}
              data-categoryId={item?.category?._id}
            >
              {/* {console.log("itemssss", item)}
              {!item?.subCategory
                ? !item?.category
                  ? item?.project?.name
                  : item?.category?.name
                : item?.subCategory?.name} */}
              {item?.title || item?.name}
            </span>
            <ListClose onClick={() => CloseItemClick(item?._id)}>
              <FaWindowClose spaceLeft="18px" color={"#707070"} />
            </ListClose>
          </ListItem>
        ))}
      {isDisabled && (
        <FaLock
          style={{ position: "absolute", bottom: "6px", right: "6px" }}
          color={"#000"}
        />
      )}
    </ListContainer>
  );
};

List.defaultProps = {
  listArray: [
    { _id: "123", title: "title1" },
    { _id: "456", title: "title2" },
  ],
  itemClassName: "",
  ItemClick: () => {},
  CloseItemClick: () => {},
};
export default List;
