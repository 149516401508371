import { gradify } from "axios.config";
import {
  EXPREIENCE_REVIEW_PENDING,
  EXPREIENCE_REVIEW_SUCCESS,
  EXPREIENCE_REVIEW_FAIL,
  EXPERIENCE_REIVEW_COMPLETE_CHAGNE,
} from "../../constant/newExperienceReview";

export const newExperienceReivewAction =
  (project, category, experience, page, sort, completed = "All" , searchInput) =>
  async (dispatch, getState) => {
    dispatch({
      type: EXPREIENCE_REVIEW_PENDING,
    });
      console.log(searchInput , 'searchInput')
    try {
      let query;
      if (searchInput) {
        query = `users/searchExperience?term=${searchInput}&project=${project}&category=${category}&experience=${experience}`;
      }else{
        if (sort) {
          query = `users/getuserexperiencedata?project=${project}&category=${category}&experience=${experience}&page=${page}&sort=${sort}&completed=${completed}`;
        } else {
          query = `users/getuserexperiencedata?project=${project}&category=${category}&experience=${experience}&page=${page}&completed=${completed}`;
        }
      }


      const { data } = await gradify.get(query);

      dispatch({
        type: EXPREIENCE_REVIEW_SUCCESS,
        payload: data.data[0],
      });
    } catch (error) {
      dispatch({
        type: EXPREIENCE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const changeCompleteReivewAction = (userId, complete) => (dispatch) => {
  dispatch({
    type: EXPERIENCE_REIVEW_COMPLETE_CHAGNE,
    payload: {
      _id: userId,
      complete,
    },
  });
};
