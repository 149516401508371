import store from "Store";
import { SET_PICKUP_STUDENT_SHOPITEM } from "constant";
import { gradify } from "axios.config";

export const togglePickupShopItemAction =
  (id, isPickedup,setPickup) => async (dispatch) => {
    try {
      const token = store.getState().auth.token;
      const shopItemId = store.getState().shopItemDetail.shopItemDetail?._id;
      if (shopItemId) {
        
        await gradify.put(
          `shopitemstudents/shop/${id}`,
          { pickup: isPickedup, updatedAt: new Date().toISOString() },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        dispatch({
          type: SET_PICKUP_STUDENT_SHOPITEM,
          payload: { id, isPickedup, pickupDate: new Date().toISOString() },
        });
        setPickup(isPickedup)
      }
    } catch (error) {
      dispatch({
        type: SET_PICKUP_STUDENT_SHOPITEM,
        payload: {
          id,
          isPickedup: !isPickedup,
        },
      });
      console.log(error);
      // what's in error?
    }
  };
