import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Column,
  Row,
  InnerCol,
  SubTitle,
  SwitchLabel,
} from "assets/style/General.style";
import VerticalText from "components/general/verticalText";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import SelectComponent from "components/control.component/select.component";

import CircleButton from "components/general/circleButton";
import List from "components/general/list";
import { changeUniversityAccessPermissionAction } from "actions";
const University = ({
  onUniversityChange,
  universityAccess,
  selectedUniversity,
  onDeleteUniversityItem,
  universityList,
  onUniversityClick,
  onAddUniversityClick,
  selectedUniversityOption,
  universityOptions,
}) => {
  const dispatch = useDispatch();
  return (
    <Box elevation={3} className="st-info" style={{ flexDirection: "row" }}>
      <Row Direction="column" Width="40%" tabletWidth={"100%"}>
        <Column>
          <SubTitle>University</SubTitle>
        </Column>
        <Column style={{ flexWrap: "wrap" }}>
          <InnerCol Direction="column" style={{ paddingTop: 20 }}>
            <SelectComponent
              options={universityOptions}
              name="university"
              selected={true}
              selectedValue={selectedUniversityOption?._id}
              onChange={onUniversityChange}
            />
          </InnerCol>
          <InnerCol style={{ padding: "15px 10px 0 0" }} align="center">
            <CircleButton
              isDisabled={!universityAccess}
              onClick={onAddUniversityClick}
            />
          </InnerCol>
        </Column>
      </Row>
      <Row Width="30%" tabletWidth={"50%"}>
        <Column>
          <List
            listArray={Object.values(universityList)}
            selectedId={selectedUniversity?._id}
            ItemClick={onUniversityClick}
            CloseItemClick={onDeleteUniversityItem}
            isDisabled={!universityAccess}
          />
        </Column>
      </Row>

      <Row Width="20%" tabletWidth={"40%"} minWidth={"240px"}>
        {selectedUniversity && (
          <>
            <Column Width="fit-content" style={{ justifyContent: "end" }}>
              <VerticalText text={selectedUniversity?.title} width="20px" />
            </Column>
            <Column Direction="column" style={{ minWidth: "210px" }}>
              <InnerCol alignment="space-between">
                <SwitchLabel>Users</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedUniversity.access?.users || false}
                  onChangeToggle={() => {
                    changeUniversityAccessPermissionAction(
                      dispatch,
                      "users",
                      !selectedUniversity.access?.users
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>Experience cards</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedUniversity.access?.experienceCards || false}
                  onChangeToggle={() => {
                    changeUniversityAccessPermissionAction(
                      dispatch,
                      "experienceCards",
                      !selectedUniversity.access?.experienceCards
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>surveys</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedUniversity.access?.surveys || false}
                  onChangeToggle={() => {
                    changeUniversityAccessPermissionAction(
                      dispatch,
                      "surveys",
                      !selectedUniversity.access?.surveys
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>stores</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedUniversity.access?.store || false}
                  onChangeToggle={() => {
                    changeUniversityAccessPermissionAction(
                      dispatch,
                      "store",
                      !selectedUniversity.access?.store
                    );
                  }}
                />
              </InnerCol>
            </Column>
          </>
        )}
      </Row>
    </Box>
  );
};

export default University;
