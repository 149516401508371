import { createSelector } from "reselect";

export const selectStudentKeyPersistanFactoryStatus = (state) =>
  state.scores.scores?.keyPersistScore;

export const selectStudentDevAreaScore = (state) =>
  state.scores.scores?.perDevScore;

export const selectStudentCollegeConnection = (state) =>
  state.scores.scores?.ceScore;

export const selectStudentScore = (state) => state.scores.scores?.studentScore;

export const studentListSelector = createSelector(
  (state) => state?.userList?.users,
  (studentList) => studentList
);
export const studentCountSelector = createSelector(
  (state) => state?.userList?.users.info,
  (info) => (info ? info[0]?.count : 0)
);
export const studentSortTypeSelector = createSelector(
  (state) => state?.userList?.sort,
  (sort) => sort
);
