import {
  EXPERIENCE_LIST_FAIL,
  EXPERIENCE_LIST_SUCCESS,
  EXPERIENCE_LIST_REQUEST,
  EXPERIENCE_SET_SORT_TYPE,
  EXPERIENCE_ITEM_REQUEST,
  EXPERIENCE_ITEM_SUCCESS,
  EXPERIENCE_ITEM_FAIL,
  EXPERIENCE_UPDATE_SUCCESS,
  EXPERIENCE_USER_COMPLETE_LIST_FAIL,
  EXPERIENCE_USER_COMPLETE_LIST_SUCCESS,
  EXPERIENCE_USER_COMPLETE_LIST_REQUEST,
  EXPERIENCE_USER_PASSDUE_LIST_FAIL,
  EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
  EXPERIENCE_USER_PASSDUE_LIST_REQUEST,
  EXPERIENCE_USER_LIST_FAIL,
  EXPERIENCE_USER_LIST_SUCCESS,
  EXPERIENCE_USER_LIST_REQUEST,
  EXPERIENCE_USER_CHANGE_LIST,
  EXPERIENCE_SET_SEARCH_INPUT,
  EXPERIENCE_SET_TYPE_FILTER,
} from "constant/experience";

export const getExperienceListReducer = (
  state = {
    experienceList: { data: [] },
    loading: true,
    filter: {
      sort: { type: "titleSort", isReversed: false },
      searchInput: "",
      type: "publish",
    },
  },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_SET_SORT_TYPE:
      console.log("action sort", action.payload);
      return { ...state, filter: { ...state.filter, sort: action.payload } };
    case EXPERIENCE_SET_SEARCH_INPUT:
      return {
        ...state,
        filter: { ...state.filter, searchInput: action.payload },
      };
    case EXPERIENCE_SET_TYPE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, type: action.payload },
      };
    case EXPERIENCE_LIST_REQUEST:
      return { ...state, loading: true, experienceList: { data: [] } };
    case EXPERIENCE_LIST_SUCCESS:
      return { ...state, loading: false, experienceList: action.payload };
    case EXPERIENCE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        experienceList: { data: [] },
        error: action.payload,
      };
    case EXPERIENCE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        experienceList: {
          ...state.experienceList,
          data: state.experienceList.data.map((item) =>
            item._id === action.payload._id ? (item = action.payload) : item
          ),
        },
      };
    default:
      return state;
  }
};

export const experienceItemReducer = (
  state = { experienceDetails: {}, loading: true },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_ITEM_REQUEST:
      return { ...state, loading: true, experienceDetails: {} };
    case EXPERIENCE_ITEM_SUCCESS:
      return { ...state, loading: false, experienceDetails: action.payload };
    case EXPERIENCE_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        experienceDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export const experienceListReducer = (
  state = { experienceList: { data: [] } },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_USER_LIST_REQUEST:
      return { ...state, loading: true, experienceList: { data: [] } };
    case EXPERIENCE_USER_LIST_SUCCESS:
      return { ...state, loading: false, experienceList: action.payload };
    case EXPERIENCE_USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        experienceList: { data: [] },
        error: action.payload,
      };
    case EXPERIENCE_USER_CHANGE_LIST:
      return {
        ...state,
        loading: false,
        experienceList: state.experienceList.data.map((exp) =>
          exp._id === action.payload.experience
            ? (exp = { ...exp, users: [...exp.users, action.type.user] })
            : exp
        ),
      };
    default:
      return state;
  }
};

export const experiencePassDueListReducer = (
  state = { experiencePassDueList: { data: [] } },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_USER_PASSDUE_LIST_REQUEST:
      return { ...state, loading: true, experiencePassDueList: { data: [] } };
    case EXPERIENCE_USER_PASSDUE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        experiencePassDueList: action.payload,
      };
    case EXPERIENCE_USER_PASSDUE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        experiencePassDueList: { data: [] },
        error: action.payload,
      };

    default:
      return state;
  }
};

export const experienceCompleteListReducer = (
  state = { experienceCompleteList: { data: [] } },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_USER_COMPLETE_LIST_REQUEST:
      return { ...state, loading: true, experienceCompleteList: { data: [] } };
    case EXPERIENCE_USER_COMPLETE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        experienceCompleteList: action.payload,
      };
    case EXPERIENCE_USER_COMPLETE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        experienceCompleteList: { data: [] },
        error: action.payload,
      };

    default:
      return state;
  }
};
