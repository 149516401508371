export const SET_RAFFLE_TICKET_LIST = "SET_RAFFLE_TICKET_LIST";
export const SET_RAFFLE_TICKET_PAGE_STATUS = "SET_RAFFLE_TICKET_PAGE_STATUS";
export const ADD_RAFFLE_ITEM_IN_RAFFLE_TICKET_LIST =
  "ADD_RAFFLE_ITEM_IN_RAFFLE_TICKET_LIST";
export const SET_RAFFLE_TICKET_ITEM_PHOTO = "SET_RAFFLE_TICKET_ITEM_PHOTO";
export const RAFFLE_TICKET_TOAST_MESSAGE = "RAFFLE_TICKET_TOAST_MESSAGE";
export const ADD_RAFFLE_IN_RAFFLELIST = "ADD_RAFFLE_IN_RAFFLELIST";

//rafflet ticket detail
export const SET_RAFFLE_TICKET_DETAIL = "SET_RAFFLE_TICKET_DETAIL";
export const SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS =
  "SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS";
export const SET_RAFFLE_TICKET_STUDENT_LIST = "SET_RAFFLE_TICKET_STUDENT_LIST";
export const RAFFLE_TICKET_DETAIL_TOAST_MESSAGE =
  "RAFFLE_TICKET_DETAIL_TOAST_MESSAGE";
export const SET_PICKUP_STUDENT_RAFFLE_TICKET =
  "SET_PICKUP_STUDENT_RAFFLE_TICKET";
export const SET_RAFFLE_TICKET_DETAIL_IMAGE = "SET_RAFFLE_TICKET_DETAIL_IMAGE";
