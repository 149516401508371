import {
  SET_SELECTED_ADMIN_DETAIL,
  SET_ADMIN_DETAIL_PAGE_STATUS,
  SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID,
  SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID,
  SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID,
} from "constant";
import store from "Store";
import { formatInputEditAdminDetail } from "helpers";
import { gradify } from "axios.config";

export const editAdminDetailAction = () => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    const adminId = store.getState().adminDetail.adminDetail?._id;
    const highSchoolList = store.getState().adminDetail.highSchoolsList;
    const specialProjectList = store.getState().adminDetail.specialProjectList;
    const universityList = store.getState().adminDetail.universityList;
    const input = formatInputEditAdminDetail(
      highSchoolList,
      specialProjectList,
      universityList
    );
    if (adminId) {
      dispatch({
        type: SET_ADMIN_DETAIL_PAGE_STATUS,
        payload: "SAVING_EDIT",
      });
      const { data } = await gradify.put(`users/${adminId}`, input, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: SET_SELECTED_ADMIN_DETAIL,
        payload: data.data,
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID,
        payload: "",
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID,
        payload: "",
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID,
        payload: "",
      });
      dispatch({
        type: SET_ADMIN_DETAIL_PAGE_STATUS,
        payload: "DEFAULT",
      });
      const { data: adminDetailData } = await gradify.get(`users/${adminId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: SET_SELECTED_ADMIN_DETAIL,
        payload: adminDetailData.data,
      });
    }
  } catch (error) {
    console.log(error);
    // what's in error?
  }
};
