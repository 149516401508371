import {
  COUNT_RANGE_MOMENTUM_FAIL,
  COUNT_RANGE_MOMENTUM_SUCCESS,
  COUNT_RANGE_MOMENTUM_REQUIRE,
} from "../constant/pmChart";


export const momentumReducer= (state={completeExperience:0,totalExperience:0 },action) => {
    switch(action.type){
        case COUNT_RANGE_MOMENTUM_REQUIRE:
            return{loading:true}
        case COUNT_RANGE_MOMENTUM_SUCCESS:
            return{loading:false,completeExperience:action.payload.completeExperience,totalExperience:action.payload.totalExperience}
        case COUNT_RANGE_MOMENTUM_FAIL:
            return{
                loading:false,
                completeExperience:0,
                totalExperience:0,
                error:action.payload
            }
        default:
            return state            
    }
}