import styled, { keyframes } from "styled-components";

export const CreateOrganizationFormCol = styled.div`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 10px 0 0;
`;
export const AdminInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const AdminDetailPic = styled.div`
  width: 125px;
  height: 110px;
`;

export const AdminDetailSubTitle = styled.h5`
  color: #556ee6;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  white-space: nowrap;
`;
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div`
  width: 144px;
  height: 144px;
  ::after {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 2px solid #d3d3d3;
    background: transparent;
    width: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    height: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    border-radius: 50%;
    align-self: center;
  }
`;
