import React, { useCallback, useEffect, useState } from "react";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import { RowTr, RowTd } from "./index.style";
import { useDispatch } from "react-redux";
import { updateDigitalCodeAciton } from "actions/inventory/inventory.action";
import moment from "moment/moment";
const StudentRow = ({
  _id,
  studentId,
  name,
  purchasedDate,
  ticketsNumber,
  isWinner,
  isPickedup,
  pickupDate,
  onChangeToggle,
  raffleId,
}) => {
  const [pickedUp, setPickUp] = useState(isPickedup);
  const handleChangeToggle = useCallback(
    (_id, studentId) => {
      setPickUp(!pickedUp);
      onChangeToggle(_id, studentId, !pickedUp);
      //dispatch(updateDigitalCodeAciton({pickup:!pickedUp},stockId,shopItem,raffleId))
    },
    [onChangeToggle, pickedUp]
  );
  useEffect(() => {
    setPickUp(isPickedup);
  }, [isPickedup]);

  return (
    <RowTr key={_id}>
      <RowTd>{name}</RowTd>
      <RowTd>{purchasedDate}</RowTd>
      <RowTd>{ticketsNumber}</RowTd>
      <RowTd>{isWinner ? "Yes" : "No"}</RowTd>
      <RowTd>
        <CustomSwitch
          isToggle={isPickedup}
          onChangeToggle={() => handleChangeToggle(_id, studentId)}
        />
      </RowTd>
      <RowTd>{pickedUp ? pickupDate : ""}</RowTd>
    </RowTr>
  );
};

export default StudentRow;
