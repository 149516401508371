export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS"
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL"



export const USER_DETAILS_UPDATE_REQUEST = "USER_DETAILS_UPDATE_REQUEST"
export const USER_DETAILS_UPDATE_SUCCESS = "USER_DETAILS_UPDATE_SUCCESS"
export const USER_DETAILS_UPDATE_FAIL = "USER_DETAILS_UPDATE_FAIL"




export const ADD_XP_USER = "ADD_XP_USER";
export const UPDATE_XP_USER = "UPDATE_XP_USER";

export const FIRST_GEN = [
    {name:"YES",_id:"YES"},
    {name:"NO",_id:"NO"}
]

export const PERSONALITY_TYPE = [
    {_id:"", name:"Select Personlaity"} ,
    {name:"STJ",_id:"STJ"},
    {name:"ISFJ",_id:"ISFJ"} ,
    {name:"INFJ",_id:"INFJ"} ,
    {name:"INTJ",_id:"INTJ"} ,
    {name:"ISTP",_id:"ISTP"} ,
    {name:"ISFP",_id:"ISFP"} ,
    {name:"INFP",_id:"INFP"} ,
    {name:"INTP",_id:"INTP"} ,
    {name:"ESTP",_id:"ESTP"},
    {name:"ESFP",_id:"ESFP"} ,
    {name:"ENFP",_id:"ENFP"} ,
    {name:"ENTP",_id:"ENTP"} ,
    {name:"ESTJ",_id:"ESTJ"},
    {name:"ESFJ",_id:"ESFJ"} ,
    {name:"ENFJ",_id:"ENFJ"} ,
    {name:"ENTJ",_id:"ENTJ"} ,
  ]

 export const WORKING = [
      {name:"YES",_id:"YES"},
      {name:"NO",_id:"NO"}
  ]

  export const GENDER = [
      {name:"Male",_id:"Male"},
      {name:"Female",_id:"Female"},
      {name:"Other",_id:"Other"}
    
  ]