import { createSelector } from "reselect";
import {
  formatRaffleTicketDetail,
  searchRaffleStudentsByName,
  filterRaffleStudentsById,
} from "helpers";

export const raffleTicketDetailSelector = createSelector(
  (state) => state.raffleTicketDetail.raffleTicketDetail,
  (raffleTicketDetail) =>
    raffleTicketDetail ? formatRaffleTicketDetail(raffleTicketDetail) : {}
);
export const raffleTicketDetailImageSelector = createSelector(
  (state) => state.raffleTicketDetail?.raffleTicketImage,
  (raffleTicketImage) => raffleTicketImage
);
export const raffleTicketDetailPageStatusSelector = createSelector(
  (state) => state.raffleTicketDetail.pageStatus,
  (pageStatus) => pageStatus
);
export const raffleTicketStudentsSelector = (
  searchStudentInput,
  selectedFilterId
) =>
  createSelector(
    (state) => state.raffleTicketDetail.raffleTicketStudents,
    (raffleTicketStudents) => {
      return filterRaffleStudentsById(
        searchRaffleStudentsByName(raffleTicketStudents, searchStudentInput),
        selectedFilterId
      );
    }
  );
export const raffleTicketDetailToastMessageSelector = createSelector(
  (state) => state.raffleTicketDetail.toastMessage,
  (toastMessage) => toastMessage
);
