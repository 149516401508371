import React from 'react';
import { 
  SettingBox,
  SettingTitleRow,
  SettingText
 } from 'components/styled.components';
import Gamification from "./learner";
import Drivers from './drivers';
import Learner from './learner';

const SettingTabs = ({condition}) => {
    let showBox = null;
     switch(condition){
       case 1:
         showBox = (
            <Drivers   
            // categoryOptions=""
            // onCategoryChanged=""
            // onStartDateChange=""
            // onEndDateChange=""
            // endDate=""
            // selfTags=""
            // onSelfTagsChange=""
            // futureTags=""
            // onFutureTagsChange=""
            // connectionTags=""
            // onConnectionTagsChange=""
            // emotionalTags=""
            // onEmotionalTagsChange=""
            // socialTags=""
            // onSocialTagsChange=""
            // financialTags=""
            // onChangeFinancialTags=""
            // academicTags=""
            // onAcademicTagsChange=""
            // situationalTags=""
            // onSituationalTags=""

       />
        )
       break;
       case 2: 
          showBox = (
            <Learner   
            // categoryOptions=""
            // onCategoryChanged=""
            // onStartDateChange=""
            // onEndDateChange=""
            // endDate=""
            // selfTags=""
            // onSelfTagsChange=""
            // futureTags=""
            // onFutureTagsChange=""
            // connectionTags=""
            // onConnectionTagsChange=""
            // emotionalTags=""
            // onEmotionalTagsChange=""
            // socialTags=""
            // onSocialTagsChange=""
            // financialTags=""
            // onChangeFinancialTags=""
            // academicTags=""
            // onAcademicTagsChange=""
            // situationalTags=""
            // onSituationalTags=""

              />
           )
       break;
  
       break;
       default:
           showBox = (
             <SettingTitleRow>
                <h1>Hi</h1>
                <SettingText>Hey Im page 1 !</SettingText>
             </SettingTitleRow>
            )
   
     }
  return (
      <SettingBox>
         {showBox}
      </SettingBox>
  )
}

export default SettingTabs;