import {
  LEAD_CONTACT_ORGANIZATION_FAIL,
  LEAD_CONTACT_ORGANIZATION_SUCCESS,
  LEAD_CONTACT_ORGANIZATION_REQUEST,
} from "../constant/leadContact";


export  const leadContactOrganizationListReducer = (state={leadContacts:[]},action) => {
    switch(action.type){
        case LEAD_CONTACT_ORGANIZATION_REQUEST:
            return {loading:true,leadContacts:[]}
        case LEAD_CONTACT_ORGANIZATION_SUCCESS:
            return {loading:false,leadContacts:action.payload}
        case LEAD_CONTACT_ORGANIZATION_FAIL:
            return {loading:false,leadContacts:[],error:action.payload}
        default:
            return state            
    }
}