import React, { useEffect, useState } from "react";
import "./picstyle.css";

const PickExperiencePicture = ({ data, changeImage, typeExper, close }) => {
  const [imageSource, setImageSource] = useState("");
  useEffect(() => {
    const allImages = document.querySelectorAll(".pic-item");

    allImages[0].style.border = "3px solid green";
    allImages[0].style.borderRadius = "5px";

    setImageSource(allImages[0].children[0].src);
  }, []);

  const clickImage = (e) => {
    changeImage(imageSource);
    close();
  };

  const selectImage = (e) => {
    const allImages = document.querySelectorAll(".pic-item");
    allImages.forEach((pic) => (pic.style.border = "0px solid green"));
    e.target.parentElement.style.border = "3px solid green";
    e.target.parentElement.style.borderRadius = "5px";
    setImageSource(e.target.src);
  };

  const cancelHandler = () => {
    close();
  };
  return (
    <div className="pic-exp-container">
      <div className="pic-panel">
        <h1 className="pic-panel-header">Card Images</h1>

        {data.map((imgs) => (
          <div key={imgs._id} className="pic-item">
            <img
              className="picexp"
              src={imgs.url}
              alt={imgs._id}
              onClick={selectImage}
            />
          </div>
        ))}
      </div>
      <div className="image-control">
        <button className="save" onClick={clickImage}>
          Save
        </button>
        <button className="cancel" onClick={cancelHandler}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PickExperiencePicture;
