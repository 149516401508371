import React, { memo, useEffect, useState } from "react";
import organizationImage from "../assets/image/organization-image.png";
import { Paper } from "@material-ui/core";
import PolygonCharts from "../components/charts/PolygonCharts";
import EditOrganizationComponent from "../components/organizations/EditOrganization";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import {
  OrganizationProjectEditButtton,
  OrganizationEditButttonText,
  OrganizationEditButttonIcon,
  OrganizationDetailsContainer,
  OrganizationPic,
  OrganizationInfo,
} from "../components/organizations/AddOrganization.style";
import {
  ProjectEditRowBox,
  SettingTitleRow,
  SpanSidebar,
} from "components/styled.components/index";
import CustomSwitch from "../components/control.component/switch/CustomSwitch";
import EditProjectPopup from "../components/organizations/EditProject";
import {
  ProjectFormCol,
  ProjectInput,
  ProjectLabel,
  ProjectBox,
  ProjectSection,
  ProjectCircleIcon,
  ProjectCatListContainer,
  ProjectCatList,
  ProjectCatListClose,
  ProjectBorderBox,
  ProjectButton,
  DefultOptions,
  DefultOption,
} from "../components/project/project.style";

import { FaWindowClose } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectDetailsAction,
  updateProjectAction,
} from "../actions/projectListAction";
import {
  addCategoryProjectAction,
  deleteCategoryProjctAction,
  getCategoryProjectAction,
  updateCateoryProjectAction,
} from "../actions/categoryListAction";
import {
  addsubCategoryProjectAction,
  getsubCategoryProjectAction,
  deletesubCategoryProjctAction,
} from "../actions/subCategoryListAction";
import { getOrganizationAction } from "../actions/organizationActions";
import ToggleBtn from "components/buttons/experience/experienceReview/ToggleBtn";
import { useCallback } from "react";
import { useMemo } from "react";

const ProjectDetail = ({ match }) => {
  const id = match.params.id;
  const dispatch = useDispatch();
  const { project, loading, error } = useSelector(
    (state) => state.projectDetails
  );
  const {
    categories,

    loading: categoryLoading,
    error: categoryError,
  } = useSelector((state) => state.categoryProjectList);

  const { subCategories } = useSelector(
    (state) => state.subCategoryProjectList
  );
  const { user } = useSelector((state) => state.loggedInUser);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddProjectForm, setShowAddProjectForm] = useState(false);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [settingTitle, setSettingTitle] = useState(null);
  const [categoryInfo, setCategoryInfo] = useState({
    name: "",
    label: "",
    project: id,
  });
  const [displayProject, setDisplayProject] = useState(false);

  const [subCategoryInfo, setSubCategoryInfo] = useState({
    name: "",
    label: "",
    category: id,
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [localizedState, setLocalizedState] = useState(false);
  const { organizations } = useSelector((state) => state.organization);
  const [categoryLabel, setCategoryLabel] = useState("");
  const [subCategoryLabel, setSubCategoryLabel] = useState("");

  const [changeCategoryDefaultList, setChangeCategoryDefaultList] =
    useState(null);

  const closeEditForm = () => {
    setShowEditForm(false);
  };
  const closeAddProjectForm = () => {
    setShowAddProjectForm(false);
  };

  useEffect(() => {
    dispatch(getProjectDetailsAction(id));
    dispatch(getCategoryProjectAction(id, user));
    dispatch(getOrganizationAction(user));
  }, [dispatch, id, user]);

  useEffect(() => {
    if (project) {
      setCategory(categories);
      setCategoryLabel(project.category_label);
      setSubCategoryLabel(project.subCategory_label);
      setSelectedCategory(
        selectedCategory ? selectedCategory : categories[0]?._id
      );
      dispatch(getsubCategoryProjectAction(categories[0]?._id, user));
      setCategoryLabel(project.category_label);
      setSubCategoryLabel(project.subCategory_label);
      setLocalizedState(project?.localized_reward);
      setSettingTitle(settingTitle ? settingTitle : categories[0]?.name);
    }
  }, [project, categories]);

  useEffect(() => {
    setSubCategory(subCategories);
  }, [subCategories]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(getsubCategoryProjectAction(selectedCategory, user));
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (changeCategoryDefaultList) {
      const result = categories.filter(
        (item) => item.school_level == changeCategoryDefaultList
      );
      setCategory(result);
    }
  }, [changeCategoryDefaultList]);

  const [showProjectEdit, setShowProjectEdit] = useState(true);
  const [showProjectDetail, setShowProjectDetail] = useState(true);
  const showProjectEditHandler = () => {
    setShowProjectEdit(!showProjectEdit);
  };
  const showProjectDetailHandler = () => {
    setShowProjectDetail(!showProjectDetail);
  };
  const classes = ["d-none"];
  const chagneHandlerDisplay = () => {
    dispatch(
      updateCateoryProjectAction(selectedCategory, {
        displayProject: !displayProject,
      })
    );
    setDisplayProject(!displayProject);
  };
  return (
    <OrganizationDetailsContainer className="">
      {/* {true ? ( */}
      <Paper
        elevation={3}
        className="st-info"
        style={{ flexDirection: "column", padding: 20 }}
      >
        <ToggleBtn
          onClick={showProjectDetailHandler}
          condition={showProjectDetail}
        />
        {!showProjectDetail ? (
          <span
            style={{
              position: "absolute",
              top: 8,
              left: 10,
              color: "black",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            Project detail
          </span>
        ) : null}
        {showProjectDetail ? (
          <OrganizationInfo>
            <OrganizationPic>
              <PolygonCharts
                XP={3499}
                studentPic={project?.image}
                color="#000"
                style={{ transform: "scale(0.35)", top: -98, left: -17 }}
              />
            </OrganizationPic>
            <div style={{ paddingLeft: 40 }}>
              <h1>{project?.name}</h1>
              <OrganizationProjectEditButtton
                onClick={() => {
                  setShowEditForm(true);
                }}
              >
                <OrganizationEditButttonText>Edit</OrganizationEditButttonText>
                <OrganizationEditButttonIcon>
                  <MdEdit color="#34C38F" size={12} />
                </OrganizationEditButttonIcon>
              </OrganizationProjectEditButtton>
              {showEditForm ? (
                <EditProjectPopup close={closeEditForm} project={project} />
              ) : null}
            </div>
          </OrganizationInfo>
        ) : null}
        {showProjectDetail ? (
          <div style={{ display: "flex", paddingTop: 25 }}>
            <div
              className="student-info first-col"
              style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
            >
              <div style={{ width: "12%" }}>
                <h3>Project Type</h3>
                <p>{project?.project_type}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Project ID </h3>
                <p>{project?._id}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Condition</h3>
                <p>{project?.condition}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Status</h3>
                <p>{project?.status}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Students</h3>
                <p>0</p>
              </div>
              <div style={{ borderRight: 0, position: "relative" }}>
                <h3 style={{ maxWidth: 235, position: "absolute", top: -15 }}>
                  Organizations - Collaboration
                </h3>
                <ProjectCatListContainer
                  style={{
                    width: "max-content",
                    padding: 12,
                    display: "flex",
                    overflow: "auto",
                    maxHeight: 125,
                    minWidth: 325,
                  }}
                >
                  {project?.organization?.map((item) => (
                    <ProjectCatList
                      key={item}
                      style={{
                        marginBottom: 6,
                        display: "flex",
                        alignItems: "self-start",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingLeft: 8,
                        }}
                      >
                        {organizations?.find((x) => x._id === item)?.title}
                      </span>
                      <ProjectCatListClose
                        style={{ borderRight: 0 }}
                        onClick={() => {}}
                      >
                        <FaWindowClose spaceLeft="18px" color={"#707070"} />
                      </ProjectCatListClose>
                    </ProjectCatList>
                  ))}
                </ProjectCatListContainer>
              </div>
            </div>
          </div>
        ) : null}
      </Paper>

      <Paper
        elevation={3}
        style={{
          marginTop: 20,
          borderRadius: 12,
          padding: 20,
          position: "relative",
        }}
      >
        <ToggleBtn
          onClick={showProjectEditHandler}
          condition={showProjectEdit}
        />
        {!showProjectEdit ? (
          <span
            style={{
              position: "absolute",
              top: 8,
              left: 10,
              color: "black",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            Project edit
          </span>
        ) : null}
        {showProjectEdit ? (
          <ProjectEditRowBox style={{ justifyContent: "space-around" }}>
            <ProjectBox style={{ maxWidth: 390 }}>
              <div style={{ display: "flex" }}>
                <ProjectFormCol Direction="column">
                  <ProjectSection
                    style={{ display: "block", margin: "0 auto" }}
                  >
                    <h3 className="st-info-title text-center">Category</h3>
                    <div style={{ display: "flex", flexDirection: "wrap" }}>
                      <ProjectLabel htmlFor="survey" style={{ marginTop: 5 }}>
                        Change Label
                      </ProjectLabel>
                      <ProjectInput
                        type="text"
                        name="category"
                        id="category"
                        value={categoryLabel}
                        onChange={(e) => {
                          setCategoryLabel(e.target.value);
                        }}
                        style={{
                          marginLeft: 5,
                        }}
                      />
                    </div>
                  </ProjectSection>
                  <ProjectSection style={{ marginTop: 45 }}>
                    <ProjectInput
                      type="text"
                      name="category"
                      id="category"
                      value={categoryInfo.name}
                      onChange={(e) => {
                        setCategoryInfo({
                          ...categoryInfo,
                          name: e.target.value,
                        });
                      }}
                    />
                    <ProjectCircleIcon
                      style={{ marginLeft: 12, cursor: "pointer" }}
                      onClick={() => {
                        if (project?.condition != "Default") {
                          dispatch(addCategoryProjectAction(id, categoryInfo));
                        } else {
                          alert("you can not add category to defualt project");
                        }
                      }}
                    >
                      <IoMdAdd color="#fff" size={14} />
                    </ProjectCircleIcon>
                    {project?.condition === "Default" ||
                    project?.project_type === "Secondary" ? (
                      <DefultOptions>
                        <DefultOption
                          className={""}
                          onClick={(e) => {
                            setChangeCategoryDefaultList("High");
                          }}
                        >
                          HS
                        </DefultOption>
                        <DefultOption
                          onClick={(e) => {
                            setChangeCategoryDefaultList("Middle");
                          }}
                        >
                          MS
                        </DefultOption>
                        <DefultOption
                          onClick={(e) => {
                            setChangeCategoryDefaultList("Elementary");
                          }}
                        >
                          Elementary
                        </DefultOption>
                      </DefultOptions>
                    ) : null}
                  </ProjectSection>
                  <ProjectSection>
                    <ProjectCatListContainer>
                      {category.map((item) => (
                        <ProjectCatList
                          className={`${
                            selectedCategory === item._id && "selectedActive"
                          }`}
                          key={item?._id}
                          onClick={() => {
                            setSelectedCategory(item?._id);
                            setDisplayProject(!!item.displayProject);
                            setSettingTitle(item?.name);
                          }}
                        >
                          <span
                            style={{
                              maxWidth: 100,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                              textAlign: "start",
                            }}
                            title={
                              item?.school_name ? item.school_name : item?.name
                            }
                          >
                            {item?.school_name ? item.school_name : item?.name}
                          </span>
                          <ProjectCatListClose
                            onClick={() => {
                              dispatch(deleteCategoryProjctAction(item._id));
                            }}
                          >
                            <FaWindowClose spaceLeft="18px" color={"#707070"} />
                          </ProjectCatListClose>
                        </ProjectCatList>
                      ))}
                    </ProjectCatListContainer>
                  </ProjectSection>
                </ProjectFormCol>
              </div>
            </ProjectBox>
            <ProjectBox style={{ maxWidth: 400 }}>
              <div style={{ display: "flex" }}>
                <ProjectFormCol Direction="column">
                  <ProjectSection
                    style={{ display: "block", margin: "0 auto" }}
                  >
                    <h3 className="st-info-title text-center">SubCategory</h3>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <ProjectLabel htmlFor="survey" style={{ marginTop: 5 }}>
                        Change Label
                      </ProjectLabel>
                      <ProjectInput
                        type="text"
                        id="subcategiry"
                        value={subCategoryLabel}
                        onChange={(e) => {
                          setSubCategoryLabel(e.target.value);
                        }}
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </ProjectSection>
                  <ProjectSection style={{ marginTop: 45 }}>
                    <ProjectInput
                      type="text"
                      name="category"
                      id="category"
                      value={subCategoryInfo.name}
                      onChange={(e) => {
                        setSubCategoryInfo({
                          ...subCategoryInfo,
                          name: e.target.value,
                        });
                      }}
                    />
                    <ProjectCircleIcon
                      style={{ marginLeft: 12 }}
                      onClick={() => {
                        if (project?.condition !== "Default") {
                          dispatch(
                            addsubCategoryProjectAction(
                              selectedCategory,
                              subCategoryInfo
                            )
                          );
                        }
                      }}
                    >
                      <IoMdAdd color="#fff" size={14} />
                    </ProjectCircleIcon>
                  </ProjectSection>
                  <ProjectSection>
                    <ProjectCatListContainer>
                      {subCategory?.map((item) => (
                        <ProjectCatList key={item?._id}>
                          <span
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item?.name}
                          </span>
                          <ProjectCatListClose
                            onClick={() => {
                              dispatch(deletesubCategoryProjctAction(item._id));
                              setSettingTitle(item?.name);
                            }}
                          >
                            <FaWindowClose spaceLeft="18px" color={"#707070"} />
                          </ProjectCatListClose>
                        </ProjectCatList>
                      ))}
                    </ProjectCatListContainer>
                  </ProjectSection>
                </ProjectFormCol>
              </div>
            </ProjectBox>
            <ProjectBox style={{ maxWidth: 350 }}>
              <h3
                className="st-info-title"
                style={{ textAlign: "center", marginBottom: 105 }}
              >
                Setting
              </h3>
              <ProjectCatListContainer
                style={{
                  padding: 0,
                  marginTop: -20,
                  minHeight: "529px",
                }}
              >
                {project?.reward_system ? (
                  <>
                    <h2
                      style={{
                        background: "grey",
                        width: "100%",
                        textAlign: "center",
                        padding: "5px",
                        color: "white",
                      }}
                    >
                      {settingTitle}
                    </h2>
                    <div
                      className="visibility"
                      style={{ width: "100%", padding: 10, scroll: "none" }}
                    >
                      <h5>Visibility</h5>
                      <ProjectBorderBox
                        style={{ flexDirection: "column", width: "100%" }}
                      >
                        <ProjectSection alignment="space-between">
                          <p>Display with project</p>
                          <CustomSwitch
                            isToggle={displayProject}
                            onChangeToggle={chagneHandlerDisplay}
                          />
                        </ProjectSection>
                        {/* {localizedState && (
                          <ProjectSection alignment="space-between">
                            <p>Coins</p>
                            <CustomSwitch isToggle={false} />
                          </ProjectSection>
                        )}

                        <ProjectSection>
                          <p>Store</p>
                          <CustomSwitch isToggle={false} />
                        </ProjectSection>  */}
                      </ProjectBorderBox>
                    </div>
                    <div
                      className="reward-system"
                      style={{ width: "100%", padding: 10 }}
                    >
                      <h5>Reward system</h5>
                      <ProjectBorderBox
                        style={{ flexDirection: "column", width: "100%" }}
                      >
                        <ProjectSection alignment="space-between">
                          <p>Localized Rewards</p>
                          <CustomSwitch
                            isToggle={localizedState}
                            onChangeToggle={() =>
                              setLocalizedState(!localizedState)
                            }
                          />
                        </ProjectSection>
                        <ProjectSection alignment="space-between">
                          <p style={{ marginLeft: 30 }}>Coins</p>
                          <CustomSwitch
                            isToggle={localizedState}
                            // onChangeToggle={() =>
                            //   setLocalizedState(!localizedState)
                            // }
                          />
                        </ProjectSection>
                        <ProjectSection alignment="space-between">
                          <p style={{ marginLeft: 50 }}>Store</p>
                          <CustomSwitch
                            isToggle={localizedState}
                            // onChangeToggle={() =>
                            //   setLocalizedState(!localizedState)
                            // }
                          />
                        </ProjectSection>
                      </ProjectBorderBox>
                    </div>
                    <div
                      className="reward-system"
                      style={{ width: "100%", padding: 10 }}
                    >
                      <h5>Reward system</h5>
                      <ProjectBorderBox
                        style={{ flexDirection: "column", width: "100%" }}
                      >
                        <ProjectSection alignment="space-between">
                          <p>Localized Rewards</p>
                          <CustomSwitch
                            isToggle={localizedState}
                            onChangeToggle={() =>
                              setLocalizedState(!localizedState)
                            }
                          />
                        </ProjectSection>
                      </ProjectBorderBox>
                    </div>
                  </>
                ) : null}
              </ProjectCatListContainer>
            </ProjectBox>
          </ProjectEditRowBox>
        ) : null}
      </Paper>
      <ProjectFormCol
        alignment={"center"}
        style={{
          justifyContent: "space-around",
          marginTop: "5%",
          marginBottom: "5%",
          marginLeft: "35%",
          marginRight: "35%",
        }}
      >
        <ProjectButton
          onClick={() =>
            dispatch(
              updateProjectAction(id, {
                category_label: categoryLabel,
                subCategory_label: subCategoryLabel,
              })
            )
          }
        >
          SAVE
        </ProjectButton>
        <ProjectButton>Cancel</ProjectButton>
      </ProjectFormCol>
    </OrganizationDetailsContainer>
  );
};

export default memo(ProjectDetail);
