import produce from "immer";
import {
  EXPERIENCE_PHOTO_PENDING,
  EXPERIENCE_PHOTO_SUCCESS,
  EXPERIENCE_PHOTO_FAIL,
  EXPERIENCE_PHOTO_CHANGE,
} from "../../constant/photo.experience";

const INITIAL_STATE = {
  isPending: false,
  isSuccess: false,
  errors: null,
  photoList: [],
  pagination: null,
};

export const photoExperienceReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EXPERIENCE_PHOTO_PENDING:
        draft.isPending = true;
        break;
      case EXPERIENCE_PHOTO_SUCCESS:
        const result = draft.photoList.concat(action.payload[0].data);
        draft.isPending = false;
        draft.isSuccess = true;
        draft.photoList = [...new Map(result.map((m) => [m._id, m])).values()];
        draft.photoList.concat(action.payload[0].data);
        draft.pagination = action.payload[0].metadata;
        break;

      case EXPERIENCE_PHOTO_FAIL:
        draft.isPending = false;
        draft.isSuccess = false;
        draft.errors = action.payload;
        draft.pagination = null;
        draft.photoList = null;
        break;

      case EXPERIENCE_PHOTO_CHANGE:
        draft.photoList = draft.photoList.map((item) => {
          if (
            item.user?._id === action.payload.user &&
            item?.experience === action.payload.experience
          ) {
            item.complete = action.payload.complete;
            return item;
          } else {
            return item;
          }
        });
    }
  });
