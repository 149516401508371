import {
  SET_ADD_ADMIN_LOADING,
  ADD_ADMIN_IN_ADMINLIST,
  SET_ADMIN_LIST,
  ADMIN_TOAST_MESSAGE,
  SET_ADMIN_PAGE_STATUS,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const setAddAdminLoading = (dispatch, isLoading) => {
  dispatch({
    type: SET_ADD_ADMIN_LOADING,
    payload: { isLoading: isLoading },
  });
};
export const addAdminAction = (adminInformation) => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    const user = store.getState().loggedInUser?.user;
    dispatch({
      type: SET_ADD_ADMIN_LOADING,
      payload: { isLoading: true },
    });
    // api add admin
    const { data: addedAdmin } = await gradify.post("users", adminInformation, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: SET_ADD_ADMIN_LOADING,
      payload: { isLoading: false },
    });

    dispatch({
      type: ADD_ADMIN_IN_ADMINLIST,
      payload: addedAdmin.data,
    });
    dispatch({
      type: SET_ADMIN_PAGE_STATUS,
      payload: "ADMIN_MESSAGE_POPUP",
    });
    dispatch({
      type: ADMIN_TOAST_MESSAGE,
      payload: { message: "ADMIN ADDED SUCCESSFULY" },
    });
    setTimeout(() => {
      dispatch({
        type: ADMIN_TOAST_MESSAGE,
        payload: { message: "" },
      });
      dispatch({
        type: SET_ADMIN_PAGE_STATUS,
        payload: "DEFAULT",
      });
    }, 2000);

    //api All admin
    const { data } = await gradify.get(
      `users?${
        user.role === "ultraAdmin"
          ? "role=ultraAdmin&role=superAdmin&role=admin"
          : ""
      }${
        user.role === "superAdmin"
          ? `role=superAdmin&role=admin&system.organization=${
              user?.system[0]?.organization?._id
            }${
              user?.system[0]?.project?._id
                ? `&system.project=${user?.system[0]?.project?._id}`
                : ""
            }${
              user?.system[0]?.category?._id
                ? `&system.category=${user?.system[0]?.category?._id}`
                : ""
            }`
          : ""
      }${
        user.role === "admin"
          ? `role=admin&system.organization=${
              user?.system[0]?.organization?._id
            }${
              user?.system[0]?.project?._id
                ? `&system.project=${user?.system[0]?.project?._id}`
                : ""
            }${
              user?.system[0]?.category?._id
                ? `&system.category=${user?.system[0]?.category?._id}`
                : ""
            }`
          : ""
      }&limit=1000`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch({
      type: SET_ADMIN_LIST,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    const oldAdminList = store.getState().admin.adminList;
    dispatch({
      type: SET_ADD_ADMIN_LOADING,
      payload: { isLoading: false },
    });
    dispatch({
      type: SET_ADMIN_LIST,
      payload: oldAdminList,
    });
    dispatch({
      type: SET_ADMIN_PAGE_STATUS,
      payload: "DEFAULT",
    });
  }
};
