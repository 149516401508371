import {
    SCHOOLDISTRICT_LIST_REQUEST,
    SCHOOLDISTRICT_LIST_SUCCESS,
    SCHOOLDISTRICT_LIST_FAIL
} from '../constant/schoolDistrict';

import {gradify} from '../axios.config';
import axios from 'axios';
export const getSchoolDistrictList = () => async (dispatch,getState) =>{

    try {
        dispatch({
            type:SCHOOLDISTRICT_LIST_REQUEST
        })
       
      
        
        dispatch({
            type:SCHOOLDISTRICT_LIST_SUCCESS,
            payload:[]
        })
    } catch (error) {
        
    }
}