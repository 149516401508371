import React, { useEffect } from "react";
import ExperienceHeader from "components/experience/ExperienceHeader";
import {
  clearAppSelectedFilterIds,
  getAppCategoriesAction,
  getAppSubCategoriesAction,
  setAppSelectedSubCategoryId,
} from "actions";
import {
  allAppCategoriesSelector,
  allAppSubCategoriesSelector,
  selectedAppProjectIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getAllExperienceList } from "actions/experienceAction";
const ExperienceHeaderContainer = ({
  onCreateExpCardBtnClick,
  searchExperienceInput,
  onSearchExperienceChanged,
}) => {
  const dispatch = useDispatch();
  //selectors
  const categories = useSelector(allAppCategoriesSelector);
  const subCategories = useSelector(allAppSubCategoriesSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  useEffect(() => {
    dispatch(getAppCategoriesAction(selectedProjectId));
    return () => {
      clearAppSelectedFilterIds(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ExperienceHeader
        categoryOptions={[
          ...categories,
          { name: "project level", id: "project level" },
        ]}
        // selectedCategoryId={selectedCategoryId}
        onCategoryChanged={(e) => {
          dispatch(getAppSubCategoriesAction(e.target.value));
          console.log("------ start exp list ------------");
          dispatch(
            getAllExperienceList(
              1,
              25,
              { type: "titleSort", isReversed: false },
              null,
              "publish",
              e.target.value,
              selectedSubCategoryId
            )
          );
          // setSelect
        }}
        subCategoryOptions={subCategories}
        selectedSubCategoryId={selectedSubCategoryId}
        onSubCategoryChanged={(e) => {
          setAppSelectedSubCategoryId(dispatch, e.target.value);
        }}
        searchExperienceInput={searchExperienceInput}
        onSearchExperienceChanged={onSearchExperienceChanged}
        onCreateExpCardBtnClick={onCreateExpCardBtnClick}
      />
    </>
  );
};

export default ExperienceHeaderContainer;
