import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../control.component/select.component";
import Select from "react-select";
import AdvanceSelect from "../control.component/advenceSelect";
import { gradify } from "../../axios.config";
import {
  CreateStudentFormCol,
  CreateStudentContainer,
  CreateStudentForm,
  CreateStudentFormRow,
  StudentInput,
  LabelStudent,
  StudentTagContainer,
  StudentTag,
  AddTagRow,
  AddTagPopup,
  AddTagColumn,
  AddTagTitle,
  AddTagCloseIcon,
  StudentCloseTag,
  StudentProjectContainer,
  StudentProject,
  StudentCloseProject,
  AddProjectRow,
  AddProjectPopup,
  AddProjectColumn,
  AddProjectTitle,
  AddProjectCloseIcon,
  AddProjectAddButton,
} from "../styled.components/create.student";
import { StudentButton } from "../styled.components";
import { FaPlus } from "react-icons/fa";
import { SaveButton } from "../styled.components";
import {
  getOrganizationProject,
  getProjectList,
} from "../../actions/projectListAction";
import {
  getCategoryList,
  getCategoryProjectAction,
} from "../../actions/categoryListAction";
import {
  getSubCategoryList,
  getsubCategoryProjectAction,
} from "../../actions/subCategoryListAction";
import { getTeamList } from "../../actions/teamListAction";
import { EDUCATIONAL_PHASES } from "../../constants";
import { getSchoolDistrictList } from "../../actions/schoolDistrictlistActions";
import { getHighSchoolList } from "../../actions/highSchoolListAction";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { updateUserDetailsInfoAction } from "../../actions/userDetailsAction";
import { getOrganizationAction } from "../../actions/organizationActions";
import { uuid } from "uuidv4";
import WarningPopup from "../warningPopup/WarningPopup";
import { CreateOrganizationFormRow } from "components/organizations/AddOrganization.style";
const EditStudentInfo = ({ close, changeShowConfirm, student }) => {
  const [projectPanel, setProjectPanel] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [tagPanel, setTagPanel] = useState(false);
  const [project, setProject] = useState("");
  const [category, setCategory] = useState("");
  const [team, setTeam] = useState("");
  const [subCategory, setSubCategory] = useState(null);
  const [schoolDistrict, setSchoolDistrict] = useState("");
  const [highschoolState, setHighSchoolState] = useState("");
  const [educational, setEducational] = useState("HS-9");
  const [textMe, setTextMe] = useState(false);
  const [firstGen, setFirstGen] = useState(false);
  const [genderState, setGenderState] = useState("Male");
  const [organizationsState, setOrganizationsState] = useState([]);
  const [projectsState, setProjectState] = useState([]);
  const [categoriesState, setCategoriesState] = useState([]);
  const [subCategoriesState, setSubCategoriesState] = useState([]);
  const [schoolDistrictState, setSchoolDistrictState] = useState({
    schoolDistrictEmail: "",
    schoolDistrictId: "",
  });
  const [systemState, setSystemState] = useState({
    organization: null,
    project: null,
    category: null,
    subCategory: null,
  });

  const [systemOrganization, setSystemOrganization] = useState(null);
  const [systemProject, setSystemProject] = useState(null);
  const [systemCategory, setSystemCategory] = useState(null);
  const [systemSubCategory, setSystemSubCategory] = useState(null);

  const [schoolDistrictSelected, setSchoolDistrictSelected] = useState(null);
  const [highSchoolSelected, setHighschoolSelected] = useState(null);
  const [systemOrganizationName, setSystemOrganizationName] = useState(null);
  const [systemProjectName, setSystemProjectName] = useState(null);
  const [systemCategoryName, setSystemCategoryName] = useState(null);
  const [systemSubCategoryName, setSystemSubCategoryName] = useState(null);
  const [showFormWarning, setShowFormWarning] = useState(false);

  const dispatch = useDispatch();

  const { teams } = useSelector((state) => state.teamList);
  const { highschools } = useSelector((state) => state.highSchoolList);
  const { schoolDistricts } = useSelector((state) => state.schoolDistrictList);
  const { loading, error, addedConfirm } = useSelector(
    (state) => state.userList
  );
  const { organizations } = useSelector((state) => state.organization);
  const { projects } = useSelector((state) => state.organizationProjectList);
  const { categories } = useSelector((state) => state.categoryProjectList);
  const { subCategories } = useSelector(
    (state) => state.subCategoryProjectList
  );
  const { user } = useSelector((state) => state.loggedInUser);

  const closeWarningForm = () => {
    setShowFormWarning(false);
    close();
  };
  const closeWarning = () => {
    setShowFormWarning(false);
  };

  useEffect(() => {
    if (student) {
      setUserInfo(student);
      dispatch(getTeamList());
      dispatch(getSchoolDistrictList());
      dispatch(getOrganizationAction(user));
    }
  }, [student]);

  useEffect(() => {
    if (project !== "") {
      dispatch(getCategoryList(project));
    }
  }, [project]);

  useEffect(() => {
    if (schoolDistricts.length > 0) {
      setSchoolDistrict(schoolDistricts[0]._id);
    }
  }, [schoolDistricts]);
  useEffect(() => {
    if (schoolDistrict !== "") {
      dispatch(getHighSchoolList(schoolDistrict));
    }
  }, [schoolDistrict]);
  useEffect(() => {
    if (categories.length > 0) {
      setCategory(categories[0]._id);
    }
  }, [categories]);
  useEffect(() => {
    if (category !== "") {
      dispatch(getSubCategoryList(category));
    }
  }, [category]);
  useEffect(() => {
    if (subCategories.length > 0) {
      setSubCategory(subCategories[0]._id);
    }
  }, [subCategories]);
  useEffect(() => {
    if (teams.length === 0) {
    }
  }, [teams]);
  useEffect(() => {
    if (highschools.length > 0) {
      setHighSchoolState(highschools[0]._id);
    }
  }, [highschools]);

  useEffect(() => {
    if (addedConfirm) {
      changeShowConfirm();
      close();
    }
  }, [addedConfirm]);

  useEffect(() => {
    if (organizations) {
      const result = organizations.map((item) => {
        return {
          name: item.title,
          _id: item._id,
          value: item._id,
        };
      });

      setOrganizationsState(result);
    }
  }, [organizations]);

  useEffect(() => {
    if (projects) {
      const result = projects.map((item) => {
        return {
          name: item.name,
          _id: item._id,
          value: item._id,
        };
      });
      setProjectState(result);
    }
  }, [projects]);

  useEffect(() => {
    if (categories) {
      const result = categories.map((item) => {
        return {
          name: item.name,
          _id: item._id,
          value: item._id,
        };
      });
      setCategoriesState(result);
    }
  }, [categories]);

  useEffect(() => {
    if (subCategories) {
      const result = subCategories.map((item) => {
        return {
          name: item.name,
          _id: item._id,
          value: item._id,
        };
      });
      setSubCategoriesState(result);
    }
  }, [subCategories]);

  const closeForm = (e) => {
    setShowFormWarning(true);
  };

  const changeText = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const onChangeGender = (e) => {
    setGenderState(e.target.value);
  };
  const changeProjectHandler = (e) => {
    //setStudent({ ...student, [e.target.name]: selected });
    setProject(e.target.value);
  };
  const changeCategoryHandler = (e) => {
    setCategory(e.target.value);
  };
  const changeTeamHandler = (e) => {
    setTeam(e.target.value);
  };

  const changeSchoolDistrictHandler = (e) => {
    setSchoolDistrictState({
      ...schoolDistrictState,
      schoolDistrictEmail: e.target.value,
    });
  };

  const changeHighSchoolHandler = (e) => {
    setHighSchoolState(e.target.value);
  };
  const saveStudent = async () => {
    //calling dispatch() for update student information

    dispatch(updateUserDetailsInfoAction(userInfo));
    close();
  };

  const changeUserInfoHandler = (e) => {
    if (e.target.name === "schoolDistrict.schoolDistrictEmail") {
      setUserInfo({
        ...userInfo,
        schoolDistrict: {
          ...userInfo.schoolDistrict,
          schoolDistrictEmail: e.target.value,
        },
      });
    } else if (e.target.name === "schoolDistrict.schoolDistrictID") {
      setUserInfo({
        ...userInfo,
        schoolDistrict: {
          ...userInfo.schoolDistrict,
          schoolDistrictID: e.target.value,
        },
      });
    } else if (e.target.name === "schoolDistrict.schoolDistrictId") {
      setUserInfo({
        ...userInfo,
        schoolDistrict: {
          ...userInfo.schoolDistrict,
          schoolDistrictId: e.target.value,
        },
      });
    } else {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    }
  };

  const loadOptionHandler = async (inputText, callback) => {
    const res = await gradify.get(`schooldistricts/search?term=${inputText}`);

    setSchoolDistrictSelected(res.data);
    callback(
      res.data.map((item) => {
        return {
          label: item.agancy_name,
          value: item._id,
        };
      })
    );
  };

  const loadHighschoolHanlder = async (inputText, callback) => {
    const res = await gradify.get(
      `highschools/search?term=${inputText}&agancy=${schoolDistrict.label}`
    );
    setHighschoolSelected(res.data);
    callback(
      res.data.map((item) => {
        return {
          label: item.school_name,
          value: item._id,
        };
      })
    );
  };

  return (
    <CreateStudentContainer className="form-student-add">
      <CreateStudentForm 
            style={{
               
               maxWidth: 1100 ,
               margin:"0 auto",
               height: "fit-content",
               margin: "0 auto",
               
               }}>
        <CreateStudentFormRow 
              style={{
                justifyContent:"center",

              }}
              >
          <CreateStudentFormCol
            alignment="center"
            style={{ paddingRight: 15  , justifyContent:"center"}}
          >
            <StudentInput
              Width="329px"
              Height="57px"
              FontFamily="Rajdhani"
              Size="45px"
              Weight="900"
              name="firstName"
              style={{color:"grey"}}
              placeholder="FirstName"
              value={userInfo.firstName}
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol
            alignment="flex-start"
            style={{ paddingLeft: 15 }}
          >
            <StudentInput
              Width="329px"
              Height="57px"
              FontFamily="Rajdhani"
              Size="45px"
              Weight="900"
              name="lastName"
              style={{color:"grey"}}
              placeholder="LastName"
              value={userInfo.lastName}
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol
            alignment="flex-start"
            style={{ paddingLeft: 30, flexDirection: "column" }}
          >
            <LabelStudent>User_ID</LabelStudent>
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 13,
                color: "#74788D",
              }}
            >
              1117
            </p>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{ 
            marginTop: 30,
            padding: 10 
            }}
        >
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="email">email</LabelStudent>
            <StudentInput
              type="text"
              id="email"
              name="email"
              value={userInfo.email}
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>

          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="phone">phone</LabelStudent>
            <StudentInput
              type="text"
              name="phone"
              id="phone"
              value={userInfo.phone}
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent>Team</LabelStudent>
            <SelectComponent
              options={teams}
              name="team"
              selectedValue={
                userInfo.team?._id ? userInfo.team?._id : teams[0]?._id
              }
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent>Educational Phases</LabelStudent>
            <SelectComponent
              options={EDUCATIONAL_PHASES}
              name="educationalPhase"
              selectedValue={userInfo ? userInfo.educationalPhase : "HS-9"}
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateOrganizationFormRow
          style={{ 
             alignItems: "center",
             marginTop: 20 }}
             >
          <CreateStudentFormCol Direction="column">
            <StudentButton
              style={{ 
                borderRadius: 50,
                marginTop: 10,
                marginRight: 10,
                marginLeft: 20,
                }}
              onClick={() => {
                setProjectPanel(!projectPanel);
              }}
            >
              <FaPlus style={{ marginRight: 12 }} size={27} />{" "}
              <span>Add Project</span>{" "}
            </StudentButton>
            {projectPanel ? (
              <>
                <AddProjectRow>
                  <AddProjectPopup>
                    <AddProjectColumn alignment={"center"}>
                      <AddProjectTitle
                        style={{ marginTop: 8, marginBottom: 20 }}
                      >
                        Projects
                      </AddProjectTitle>
                    </AddProjectColumn>
                    <AddProjectColumn
                      style={{ marginLeft: "5%" }}
                      Direction="column"
                    >
                      <LabelStudent htmlFor="project">
                        Organizatiion
                      </LabelStudent>
                      <SelectComponent
                        style={{ marginRight: 10, width: 180 }}
                        options={organizationsState}
                        name="Organization"
                        selected={true}
                        onChange={(e) => {
                          dispatch(
                            getOrganizationProject(e.target.value, user)
                          );
                          setSystemOrganization(e.target.value);
                          setSystemOrganizationName(
                            e.target.options[e.target.selectedIndex].text
                          );
                          setSystemState({
                            ...systemState,
                            organization: e.target.value,
                          });
                        }}
                      />
                    </AddProjectColumn>
                    <AddProjectColumn
                      style={{ marginLeft: "5%", paddingTop: 18 }}
                    >
                      <AddProjectColumn
                        style={{ paddingLeft: 0 }}
                        Direction="column"
                        alignment={"center"}
                      >
                        <LabelStudent htmlFor="project">Project</LabelStudent>
                        <SelectComponent
                          style={{ marginRight: 10, width: 180 }}
                          options={projects}
                          name="project"
                          selected={true}
                          onChange={(e) => {
                            dispatch(
                              getCategoryProjectAction(e.target.value, user)
                            );
                            setSystemProject(e.target.value);
                            setSystemProjectName(
                              e.target.options[e.target.selectedIndex].text
                            );
                            setSystemState({
                              ...systemState,
                              project: e.target.value,
                            });
                          }}
                        />
                      </AddProjectColumn>
                      <AddProjectColumn Direction="column" alignment={"center"}>
                        <LabelStudent htmlFor="category">Category</LabelStudent>
                        <SelectComponent
                          style={{ marginRight: 10, width: 180 }}
                          options={categoriesState}
                          name="category"
                          selected={true}
                          onChange={(e) => {
                            dispatch(
                              getsubCategoryProjectAction(e.target.value, user)
                            );
                            setSystemCategory(e.target.value);
                            setSystemCategoryName(
                              e.target.options[e.target.selectedIndex].text
                            );
                            setSystemState({
                              ...systemState,
                              category: e.target.value,
                            });
                          }}
                        />
                      </AddProjectColumn>
                      <AddProjectColumn Direction="column" alignment={"center"}>
                        <LabelStudent htmlFor="subCategory">
                          Sub Category
                        </LabelStudent>
                        <SelectComponent
                          style={{ marginRight: 10, width: 180 }}
                          options={subCategoriesState}
                          name="subCategory"
                          selected={true}
                          onChange={(e) => {
                            setSystemState({
                              ...systemState,
                              subCategory: e.target.value,
                            });
                            setSystemSubCategory(e.target.value);
                            setSystemSubCategoryName(
                              e.target.options[e.target.selectedIndex].text
                            );
                          }}
                        />
                      </AddProjectColumn>
                      <AddProjectColumn>
                        <AddProjectAddButton
                          style={{ borderRadius: 50, marginLeft: 10 }}
                          onClick={() => {
                            const system = {
                              id: uuid(),
                              organization: organizations.find(
                                (item) => item._id == systemOrganization
                              ),
                              //organizationName:systemOrganizationName,
                              project: projects.find(
                                (item) => item._id == systemProject
                              ),
                              //projectName:systemProjectName,
                              category: categories.find(
                                (item) => item._id == systemCategory
                              ),
                              //categoryName:systemCategoryName,
                              subCategory: subCategories.find(
                                (item) => item._id == systemSubCategory
                              ),
                              //subCategoryName:systemSubCategoryName
                            };

                            const existSystem = userInfo.system.find(
                              (x) =>
                                x.organization._id ===
                                  system.organization._id &&
                                x.project._id === system.project._id &&
                                x.category._id === system.category._id &&
                                x.subCategory._id === system.subCategory._id
                            );

                            if (!existSystem) {
                              setUserInfo({
                                ...userInfo,
                                system: [...userInfo.system, system],
                              });
                            }

                            setSystemOrganization(null);
                            setSystemCategoryName(null);
                            setSystemProject(null);
                            setSystemProjectName(null);
                            setSystemCategory(null);
                            setSystemCategoryName(null);
                            setSystemSubCategory(null);
                            setSystemSubCategoryName(null);
                          }}
                        >
                          <FaPlus size={10} />
                        </AddProjectAddButton>
                      </AddProjectColumn>
                    </AddProjectColumn>
                    <AddProjectColumn
                      Direction="column"
                      style={{ marginLeft: "5%", marginRight: "3%" }}
                    >
                      <LabelStudent
                        htmlFor="subCategory"
                        style={{
                          marginTop: 20,
                          marginLeft: 5,
                          fontSize: 18,
                          color: "#003267",
                        }}
                      >
                        Projects added
                      </LabelStudent>
                      <StudentProjectContainer>
                        {userInfo?.system?.map((item, index) => (
                          <StudentProject
                            key={index + 1}
                            className="item_student_project"
                          >
                            <span>
                              Organization:{" "}
                              {item.organizationName
                                ? item.organizationName
                                : item.organization?.title}{" "}
                            </span>
                            <span>
                              Project:{" "}
                              {item.projectName
                                ? item.projectName
                                : item.project?.name}{" "}
                            </span>
                            <span>
                              Category:{" "}
                              {item.categoryName
                                ? item.categoryName
                                : item.category?.name}{" "}
                            </span>
                            <span>
                              Subcategory:{" "}
                              {item.subCategoryName
                                ? item.subCategoryName
                                : item.subCategory?.name}
                            </span>
                            <StudentCloseProject
                              onClick={() => {
                                const result = userInfo?.system?.filter(
                                  (x) => x._id != item._id
                                );
                                setUserInfo({ ...userInfo, system: result });
                              }}
                            >
                              <AiOutlineCloseCircle
                                spaceLeft="18px"
                                color={"#707070"}
                              />
                            </StudentCloseProject>
                          </StudentProject>
                        ))}
                      </StudentProjectContainer>
                    </AddProjectColumn>

                    <AddProjectColumn
                      alignment={"center"}
                      style={{
                        justifyContent: "space-around",
                        marginTop: "2%",
                        marginBottom: "2%",
                        marginLeft: "25%",
                        marginRight: "25%",
                      }}
                    >
                      <SaveButton
                        onClick={() => {
                          setProjectPanel(!projectPanel);
                        }}
                      >
                        SAVE
                      </SaveButton>
                    </AddProjectColumn>
                  </AddProjectPopup>
                </AddProjectRow>
              </>
            ) : null}
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" style={{ width: "70%" }}>
            <StudentProjectContainer>
              {userInfo?.system?.map((item, index) => (
                <StudentProject
                  key={index + 1}
                  className="item_student_project"
                >
                  <span>
                    {item.organizationName
                      ? item.organizationName
                      : item.organization?.title}{" "}
                  </span>
                  <span>
                    {item.projectName ? item.projectName : item.project?.name}{" "}
                  </span>
                  <span>
                    {item.categoryName
                      ? item.categoryName
                      : item.category?.name}{" "}
                  </span>
                  <span>
                    {item.subCategoryName
                      ? item.subCategoryName
                      : item.subCategory?.name}
                  </span>
                  <StudentCloseProject
                    onClick={() => {
                      const result = userInfo?.system?.filter(
                        (x) => x._id != item._id
                      );
                      setUserInfo({ ...userInfo, system: result });
                    }}
                  >
                    <AiOutlineCloseCircle spaceLeft="18px" color={"#707070"} />
                  </StudentCloseProject>
                </StudentProject>
              ))}
            </StudentProjectContainer>
          </CreateStudentFormCol>
        </CreateOrganizationFormRow>
        {/* <CreateStudentFormRow
          style={{
                   paddingRight: 30,
                   paddingLeft: 30,
                  padding:10,
                  justifyContent:"start",
                  marginTop: 20 
                  }}
        ></CreateStudentFormRow> */}
        <CreateStudentFormRow
          style={{
              marginTop: 20,
              padding: 10,
              justifyContent: "start",
              }}
        >
          <CreateStudentFormCol Direction="column">
            <LabelStudent>School District</LabelStudent>

            <AdvanceSelect
              value={{
                label: userInfo?.schoolDistrict?.schoolDistrictID.agancy_name,
                value: userInfo?.schoolDistrict?.schoolDistrictID._id,
              }}
              onChange={(selectedSchoolDistriect) => {
                setSchoolDistrict(selectedSchoolDistriect);
                setUserInfo({
                  ...userInfo,
                  schoolDistrict: {
                    ...userInfo.schoolDistrict,
                    schoolDistrictID: schoolDistrictSelected.find(
                      (item) => item._id == selectedSchoolDistriect.value
                    ),
                  },
                });
              }}
              loadOptions={loadOptionHandler}
            />
          </CreateStudentFormCol>

          <CreateStudentFormCol Direction="column">
            <LabelStudent>HighSchool</LabelStudent>
            <AdvanceSelect
              value={{
                label: userInfo?.highSchool?.school_name,
                value: userInfo?.highSchool?._id,
              }}
              onChange={(selectedHighSchool) => {
                console.log(
                  "highschool",
                  highSchoolSelected.find(
                    (item) => item._id == selectedHighSchool.value
                  )
                );
                setHighSchoolState(selectedHighSchool);
                setUserInfo({
                  ...userInfo,
                  highSchool: highSchoolSelected.find(
                    (item) => item._id == selectedHighSchool.value
                  ),
                });
              }}
              loadOptions={loadHighschoolHanlder}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="schoolDistrictId">
              School District ID
            </LabelStudent>
            <StudentInput
              type="text"
              id="schoolDistrictId"
              name="schoolDistrict.schoolDistrictId"
              value={userInfo.schoolDistrict?.schoolDistrictId}
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="schoolDistrictEmail">
              School District Email
            </LabelStudent>
            <StudentInput
              type="text"
              id="schoolDistrict"
              name="schoolDistrict.schoolDistrictEmail"
              value={userInfo.schoolDistrict?.schoolDistrictEmail}
              onChange={changeUserInfoHandler}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{ 
            marginTop: 30,
            paddingTop: 10,
            }}
        >
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="university">University</LabelStudent>
            <SelectComponent
              options={""}
              name="university"
              value={""}
              onChange={() => {}}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="universityId">University ID</LabelStudent>
            <StudentInput
              type="number"
              id="universityId"
              name="universityId"
              value={""}
              onChange={() => {}}
            />
          </CreateStudentFormCol>
          {/* <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="universityCode">
              University Code
            </LabelStudent>
            <StudentInput
              type="number"
              id="universityCode"
              name="universityCode"
              value={""}
              onChange={() => {}}
            />
          </CreateStudentFormCol> */}
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="universityEmail">
              University email
            </LabelStudent>
            <StudentInput
              type="number"
              id="universityEmail"
              name="universityEmail"
              value={""}
              onChange={() => {}}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{
            marginTop: 20,
            padding: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <CreateStudentFormCol Direction="column">
            <StudentButton
              style={{ 
                 borderRadius: 50,
                 marginRight: 10,
                 marginTop:5 ,
                 marginLeft: 10 
                }}
              onClick={() => {
                setTagPanel(!tagPanel);
              }}
            >
              <FaPlus style={{ marginRight: 5 }} size={27} />{" "}
              <span>Add Tag</span>{" "}
            </StudentButton>
            {tagPanel ? (
              <>
                <AddTagRow>
                  <AddTagPopup>
                    <AddTagColumn alignment={"center"}>
                      <AddTagTitle style={{ marginTop: 18 }}>
                        Add Tag
                      </AddTagTitle>
                      <AddTagCloseIcon
                        onClick={() => {
                          setTagPanel(!tagPanel);
                        }}
                      >
                        <AiOutlineCloseCircle
                          spaceLeft="18px"
                          color={"#707070"}
                        />
                      </AddTagCloseIcon>
                    </AddTagColumn>
                    <AddTagColumn Direction="column" alignment={"center"}>
                      <LabelStudent htmlFor="specialProject">
                        Special Project
                      </LabelStudent>
                      <SelectComponent
                        style={{ marginLeft: 10, marginRight: 10 }}
                        options={""}
                        name="specialProject"
                        value={""}
                        onChange={() => {}}
                      />
                    </AddTagColumn>
                    <AddTagColumn
                      alignment={"center"}
                      style={{
                        justifyContent: "space-around",
                        marginTop: "13%",
                      }}
                    >
                      <SaveButton>SAVE</SaveButton>
                      <SaveButton>Cancel</SaveButton>
                    </AddTagColumn>
                  </AddTagPopup>
                </AddTagRow>
              </>
            ) : null}
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" style={{ width: "70%" }}>
            <StudentTagContainer>
              {/* <StudentTag>
                CareerSprings
                <StudentCloseTag onClick={() => {}}>
                  <AiOutlineCloseCircle spaceLeft="18px" color={"#707070"} />
                </StudentCloseTag>
              </StudentTag> */}
            </StudentTagContainer>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 50,
            padding: 0,
            justifyContent: "center",

          }}
        >
          <CreateStudentFormCol
            style={{ justifyContent: "flex-end", paddingRight: 30 }}
          >
            <SaveButton onClick={saveStudent} disabled={loading}>
              {" "}
              {loading ? "Sending..." : "Save"}
            </SaveButton>
          </CreateStudentFormCol>
          <CreateStudentFormCol style={{ paddingLeft: 30 }}>
            <SaveButton onClick={closeForm}>Cancel</SaveButton>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
      </CreateStudentForm>
      {error && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {error}
        </div>
      )}

      {showFormWarning ? (
        <WarningPopup
          close={closeWarningForm}
          text={"Clear All Changes"}
          reject={closeWarning}
          accept={() => {}}
        />
      ) : null}
    </CreateStudentContainer>
  );
};

export default EditStudentInfo;
