import React from 'react';
import './fuses.style.css';
import fusescoins from '../../assets/image/fuses.png';
const Fuses = ({fusesNumber}) => {
    return (
        <div className="fuses-student">
            <img src={fusescoins} alt="" />
            <p className="fuses-number">{fusesNumber}</p>
            <p className="fuses">Focus Fuses</p>
        </div>
    )
}

export default Fuses
