import StudentList from "components/raffle/RaffleSystem/StudentList";
import { EXPERIENCE_ITEM_FAIL } from "constant/experience";
import produce from "immer";
import {
  EXPERIENCE_STUDENT_PENDING,
  EXPERIENCE_STUDENT_SUCCESS,
  EXPERIENCE_STUDENT_FAIL,
  EXPERIENCE_STUDENT_CHANGE,
} from "../../constant/experienceStudentList";

const INITIAL_STATE = {
  isPending: false,
  isSuccess: false,
  errors: null,
  pagination: null,
  studenetList: [],
};

export const experienceStudentListReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EXPERIENCE_STUDENT_PENDING:
        draft.isPending = true;
        break;
      case EXPERIENCE_STUDENT_SUCCESS:
        const result = draft.studenetList.concat(action.payload[0].data);
        draft.isPending = false;
        draft.isSuccess = true;
        draft.pagination = action.payload[0].metadata;
        draft.studenetList = [
          ...new Map(result.map((m) => [m._id, m])).values(),
        ];

        break;
      case EXPERIENCE_STUDENT_FAIL:
        draft.isPending = false;
        draft.errors = action.payload;
        break;

      case EXPERIENCE_STUDENT_CHANGE:
        draft.studenetList = draft.studenetList.map((item) => {
          if (!item.complete && item._id === action.payload.user) {
            item = { ...item, complete: action.payload };
            return item;
          }
          if (
            item.complete?.user === action.payload.user &&
            item.complete?.experience === action.payload.experience
          ) {
            item.complete = {
              ...item.complete,
              complete: action.payload.complete,
              updatedAt: action.payload.updatedAt,
            };
            return item;
          } else {
            return item;
          }
        });
    }
  });
