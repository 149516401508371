import produce from "immer";
import {
  GET_ANALIZE_PENDING,
  GET_ANALIZE_SUCCESS,
  GET_ANALIZE_FAIL,
} from "../../constant/analize-experience";

const INITIAL_STATE = {
  loading: false,
  total: 0,
  completed: 0,
  notCompleted: 0,
  views: 0,
  error: null,
};

export const analizeReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ANALIZE_PENDING:
        draft.loading = true;
        break;
      case GET_ANALIZE_SUCCESS:
        draft.loading = false;
        draft.total = action.payload.total;
        draft.completed = action.payload.completed;
        draft.notCompleted = action.payload.notCompleted;
        draft.views = action.payload.views;
        break;
      case GET_ANALIZE_FAIL:
        draft.error = action.payload;
        break;
    }
  });
