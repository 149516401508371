import { gradify } from "axios.config";
import {
  ADD_AN_INVENTORY,
  GET_ALL_INVENTORY,
  LOADING_INVENTORY,
  SUCCEED_INVENTORY,
  UPDATE_INVENTORY,
  UPDATE_SERIAL_NUMBER,
} from "constant/inventory";
import store from "../../Store";
import {
  formatInventoryList,
  formatInvetory,
} from "helpers/inventory/inventory.helper";
export const getInventoriesAction =
  (shopItem = null, raffelTicket = null) =>
  async (dispatch) => {
    const token = store.getState().auth.token;

    dispatch({
      type: LOADING_INVENTORY,
    });
    let query = "";
    if (shopItem) {
      query = `inventories?shopItem=${shopItem}&limit=1000`;
    }
    if (raffelTicket) {
      query = `inventories?raffelTicket=${raffelTicket}&limit=1000`;
    }

    const { data } = await gradify.get(query, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: GET_ALL_INVENTORY,
      payload: formatInventoryList(data.data, shopItem, raffelTicket),
    });
    dispatch({
      type: SUCCEED_INVENTORY,
    });
  };

export const addAnInventory =
  (inventory, shopItem = null, raffelTicket = null) =>
  async (dispatch) => {
    const token = store.getState().auth.token;
    try {
      dispatch({
        type: LOADING_INVENTORY,
      });
      if (inventory.serialNumber !== "") {
        store.dispatch(
          updateGroupInventory(inventory.serialNumber, shopItem, raffelTicket)
        );
      }
      const { data } = await gradify.post(`inventories`, inventory, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (raffelTicket) {
        await gradify.put(
          `raffletickets/${raffelTicket}`,
          { number_winner: data.count },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }

      dispatch({
        type: ADD_AN_INVENTORY,
        payload: formatInvetory(data.data, shopItem, raffelTicket),
      });
      dispatch({
        type: SUCCEED_INVENTORY,
      });
    } catch (error) {
      alert(error?.message);
      console.log(error?.response?.data?.message);
    }
  };

export const updateGroupInventory =
  (serialNumber, shopItem = null, raffelTicket = null) =>
  async (dispatch) => {
    const token = store.getState().auth.token;
    const inventory = {
      serialNumber,
      shopItem,
      raffelTicket,
    };

    dispatch({
      type: LOADING_INVENTORY,
    });

    const { data } = await gradify.put("inventories/updategroup", inventory, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: UPDATE_SERIAL_NUMBER,
      payload: serialNumber,
    });
    dispatch({
      type: SUCCEED_INVENTORY,
    });
  };

export const updateDigitalCodeAciton =
  (inventoy, stock_id, shopItem = null, raffelTicket = null) =>
  async (dispatch) => {
    const token = store.getState().auth.token;
    dispatch({
      type: LOADING_INVENTORY,
    });

    const { data } = await gradify.put(`inventories/${stock_id}`, inventoy, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(data.data);
    dispatch({
      type: UPDATE_INVENTORY,
      payload: formatInvetory(data.data, shopItem, raffelTicket),
    });

    dispatch({
      type: SUCCEED_INVENTORY,
    });
  };
