import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_PROJECT_LIST_REQUEST,
  CATEGORY_PROJECT_LIST_SUCCESS,
  CATEGORY_PROJECT_LIST_FAIL,
  CATEGORY_PROJECT_ADD_REQUEST,
  CATEGORY_PROJECT_ADD_SUCCESS,
  CATEGORY_PROJECT_ADD_FAIL,
  CATEGORY_PROJECT_DELETE_FAIL,
  CATEGORY_PROJECT_DELETE_SUCCESS,
  CATEGORY_PROJECT_DELETE_REQUEST,
  CATEGORY_PROJECT_UPDATE_PENDING,
  CATEGORY_PROJECT_UPDATE_SUCCESS,
  CATEGORY_PROJECT_UPDATE_FAIL,
} from "../constant/category";
import { gradify } from "../axios.config";
import { ConstantLabel } from "assets/style/General.style";
export const getCategoryList = (projectId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_LIST_REQUEST,
    });

    const { data } = await gradify.get(`projects/${projectId}/categories`, {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });
    dispatch({
      type: CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addCategoryProjectAction =
  (projectId, categoryInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORY_PROJECT_ADD_REQUEST,
      });

      const { data } = await gradify.post(
        `projects/${projectId}/categories`,
        categoryInfo,
        { headers: { Authorization: "Bearer " + getState().auth.token } }
      );

      dispatch({
        type: CATEGORY_PROJECT_ADD_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CATEGORY_PROJECT_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getCategoryProjectAction =
  (projectId, user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORY_PROJECT_LIST_REQUEST,
      });

      const { data } = await gradify.get(`categories/project/${projectId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      switch (user.role) {
        case "ultraAdmin":
          dispatch({
            type: CATEGORY_PROJECT_LIST_SUCCESS,
            payload: data.data,
          });
          break;
        case "superAdmin":
          const joinSchoolSpecial = [
            ...user.schoolDistrictPermissionEdit,
            ...user.spicalProjectPermissionEdit,
          ];
          const result = joinSchoolSpecial.find(
            (item) => item.project?._id === projectId
          );

          if (result.category === null) {
            dispatch({
              type: CATEGORY_PROJECT_LIST_SUCCESS,
              payload: data.data,
            });
          } else {
            const resultarr = data.data.filter((item) => {
              return joinSchoolSpecial.find(
                (a) => a.category?._id === item._id
              );
            });
            dispatch({
              type: CATEGORY_PROJECT_LIST_SUCCESS,
              payload: resultarr,
            });
          }
          break;
        case "admin":
          const joinSchoolSpecialAdmin = [
            ...user.schoolDistrictPermissionEdit,
            ...user.spicalProjectPermissionEdit,
          ];
          const resultAdmin = joinSchoolSpecialAdmin.find(
            (item) => item.project?._id === projectId
          );

          if (resultAdmin.category === null) {
            dispatch({
              type: CATEGORY_PROJECT_LIST_SUCCESS,
              payload: data.data,
            });
          } else {
            const resultarr = data.data.filter((item) => {
              return joinSchoolSpecialAdmin.find(
                (a) => a.category?._id === item._id
              );
            });
            dispatch({
              type: CATEGORY_PROJECT_LIST_SUCCESS,
              payload: resultarr,
            });
          }
          break;
        //no default
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: CATEGORY_PROJECT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteCategoryProjctAction =
  (categoryId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORY_PROJECT_DELETE_REQUEST,
      });

      const { data } = await gradify.delete(`categories/${categoryId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: CATEGORY_PROJECT_DELETE_SUCCESS,
        payload: categoryId,
      });
    } catch (error) {
      dispatch({
        type: CATEGORY_PROJECT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCateoryProjectAction =
  (categoryId, categoryInfo) => async (dispatch, getState) => {
    dispatch({
      type: CATEGORY_PROJECT_UPDATE_PENDING,
    });

    try {
      const { data } = await gradify.put(
        `categories/${categoryId}`,
        categoryInfo,
        {
          headers: { Authorization: "Bearer " + getState().auth.token },
        }
      );

      dispatch({
        type: CATEGORY_PROJECT_UPDATE_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CATEGORY_PROJECT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
