import { gradify } from "axios.config";
import {
  GET_ANALIZE_PENDING,
  GET_ANALIZE_SUCCESS,
  GET_ANALIZE_FAIL,
} from "../../constant/analize-experience";

export const analizeAction =
  (project, category, experience) => async (dispatch) => {
    dispatch({
      type: GET_ANALIZE_PENDING,
    });

    try {
      const { data } = await gradify.get(
        `users/analizeExperienceUser?project=${project}&category=${category}&experience=${experience}`
      );

      dispatch({
        type: GET_ANALIZE_SUCCESS,
        payload: {
          total: data.data[0].total[0].total,
          completed: data.data[0].completed[0].completed,
          notCompleted: data.data[0].notCompleted[0].notCompleted,
          views: data.data[0].views[0].views,
        },
      });
    } catch (error) {}
  };
