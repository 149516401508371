import styled from "styled-components";

export const ListContainer = styled.div`
  width: 95%;
  margin-top: 10px;
  max-width: 386px;
  border: 1px solid #ced4da;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 17px;
  justify-content: space-around;
  margin-right: 20px;
  max-height: 450px;
  overflow: auto;
  position: relative;
`;

export const ListItem = styled.div`
  border: 1px solid #ced4da;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 5px;
  position: relative;
  color: #74788d;
  cursor: pointer;
  max-width: 46%;
  font-size: 13px;
  flex-direction: column;
  border-radius: 5px;
  min-width: 110px;
  min-height: 25px;
  padding: 0 25px 0 7px;
  background-color: ${({ selected }) => (selected ? "#4ed8a6" : "transparent")};
  &:hover {
    background-color: #4ed8a6;
  }
  &:active {
    background-color: #4ed8a6;
  }
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ListClose = styled.div`
  position: absolute;
  top: -1px;
  right: 7px;
  color: #313539;
  font-family: system-ui;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;
