//
import store from "Store";
import { SET_PICKUP_STUDENT_RAFFLE_TICKET } from "constant";
import { gradify } from "axios.config";

export const togglePickupRaffleAction =
  (id, studentId, isPickedup) => async (dispatch) => {
    try {
      const token = store.getState().auth.token;
      const raffleId =
        store.getState().raffleTicketDetail.raffleTicketDetail?._id;
      if (raffleId) {
        dispatch({
          type: SET_PICKUP_STUDENT_RAFFLE_TICKET,
          payload: {
            id: studentId,
            isPickedup,
            pickupDate: new Date().toISOString(),
          },
        });
        //should change pickup in all numbers
        await gradify.put(
          `shopitemstudents/raffleticket/${id}`,
          { pickup: isPickedup, updatedAt: new Date().toISOString() },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      dispatch({
        type: SET_PICKUP_STUDENT_RAFFLE_TICKET,
        payload: {
          id: studentId,
          isPickedup: !isPickedup,
        },
      });
      console.log(error);
      // what's in error?
    }
  };
