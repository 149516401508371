import {
  SET_SELECTED_ADMIN_DETAIL,
  SET_ADMIN_DETAIL_PAGE_STATUS,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const editInfoAdminDetailAction = (input) => async (dispatch) => {
  const adminDetail = store.getState().adminDetail.adminDetail;
  try {
    const token = store.getState().auth.token;
    const adminId = store.getState().adminDetail.adminDetail?._id;
    if (adminId) {
      dispatch({
        type: SET_ADMIN_DETAIL_PAGE_STATUS,
        payload: "INFO_SAVING",
      });
      const { data } = await gradify.put(`users/admin/${adminId}`, input, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: SET_SELECTED_ADMIN_DETAIL,
        payload: data.data,
      });
      dispatch({
        type: SET_ADMIN_DETAIL_PAGE_STATUS,
        payload: "DEFAULT",
      });
      const { data: adminDetailData } = await gradify.get(`users/${adminId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: SET_SELECTED_ADMIN_DETAIL,
        payload: adminDetailData.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_ADMIN_DETAIL_PAGE_STATUS,
      payload: "DEFAULT",
    });
    dispatch({
      type: SET_SELECTED_ADMIN_DETAIL,
      payload: adminDetail,
    });
    // what's in error?
  }
};
