import {
  SET_SELECTED_ADMIN_DETAIL,
  SET_ADMIN_DETAIL_PAGE_STATUS,
  SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID,
  SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID,
  SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID,
  SET_ADMIN_DETAIL_HIGHSCHOOL_LIST,
  SET_ADMIN_DETAIL_UNIVERSITY_LIST,
  SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST,
} from "constant";
import store from "Store";
import {
  formatHighSchoolList,
  formatSpecialProjectList,
  formatUniversityList,
} from "helpers";
import { gradify } from "axios.config";

export const cancelAdminEditAction = () => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    const adminId = store.getState().adminDetail.adminDetail?._id;
    if (adminId) {
      const { data } = await gradify.get(`users/${adminId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      dispatch({
        type: SET_SELECTED_ADMIN_DETAIL,
        payload: data.data,
      });
      dispatch({
        type: SET_ADMIN_DETAIL_HIGHSCHOOL_LIST,
        payload: formatHighSchoolList(
          data.data.schoolDistrictPermissionEdit,
          data.data.system[0]
        ),
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST,
        payload: formatSpecialProjectList(
          data.data.spicalProjectPermissionEdit,
          data.data.system[0]
        ),
      });
      // set universityList here
      dispatch({
        type: SET_ADMIN_DETAIL_UNIVERSITY_LIST,
        payload: formatUniversityList(
          data.data.universityPermissionEdit,
          data.data.system[0]
        ),
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID,
        payload: "",
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID,
        payload: "",
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID,
        payload: "",
      });
      dispatch({
        type: SET_ADMIN_DETAIL_PAGE_STATUS,
        payload: "DEFAULT",
      });
    }
  } catch (error) {
    console.log(error);
    // what's in error?
  }
};
