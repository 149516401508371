import { Switch, Route, Redirect } from "react-router-dom";
import { getRoutes } from "../routes";

export const switchRoute = (user) => (
  <Switch>
    {getRoutes(user).map((props, key) => {
      if (props.layout === "/admin") {
        return (
          <Route
            exact
            key={key}
            path={props.layout + props.path}
            component={props.component}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/home" />
  </Switch>
);
