import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { authReducer, loggedInUserReducer } from "./reducers/atuhReducers";
import {
  categoryListReducer,
  categoryProjectListReducer,
} from "./reducers/categoryListReducer";
import { completionListReducer } from "./reducers/completionListReducer";
import { experienceTypeListReducer } from "./reducers/experienceTypeListReducer";
import { highSchoolListReducer } from "./reducers/highschoolListReducer";
import {
  organizationProjectListReducer,
  projectListReducer,
} from "./reducers/projectListReducer";
import { schoolDistrictListReducer } from "./reducers/schoolDistrictListReducer";
import {
  subCategoryListReducer,
  subCategoryProjectListReducer,
} from "./reducers/subcategoryListReducer";
import { teamListReducer } from "./reducers/teamListReducer";
import { userDetailsReducer } from "./reducers/userDetailsReducer";
import {
  leaderBoardReducer,
  userExperienceListReduer,
  userListReducer,
} from "./reducers/userListReducer";
import { exprienceImageListReducer } from "./reducers/experienceImageListReducer";
import {
  experienceCompleteListReducer,
  experienceListReducer,
  experiencePassDueListReducer,
  getExperienceListReducer,
} from "./reducers/experienceListReducer";
import { ExperienceDetailsReducer } from "./reducers/experieneTypeDetailsReducer";
import { completionDetailsReducer } from "./reducers/completionsDetailsReducer";
import { experienceItemReducer } from "./reducers/experienceListReducer";
import { experienceUserListReducer } from "./reducers/experienceUserReducer";
import { listOfUserExperienceReducer } from "./reducers/listOfUserExperienceReducer";
import { pmChartInfoReducer } from "./reducers/pmChartInfoReducers";
import { momentumReducer } from "./reducers/momentumReducer";
import { loginReducer } from "./reducers/loginReducers";
import { xpsBounesReducer, xpsListProject } from "./reducers/xpReducers";
import { universityListReducer } from "./reducers/univeristyRedcuders";
import { leadContactOrganizationListReducer } from "./reducers/leadContactReducers";
import {
  getDetailsOrganizationReducer,
  organizationReducer,
} from "./reducers/organizationResucres";
import { projectDetailsReducer } from "./reducers/projectListReducer";
import { userSystemReducer } from "./reducers/systemReducers";
import adminReducer from "reducers/Admin";
import adminDetailReducer from "reducers/AdminDetail";
import shopReducer from "reducers/Shop";
import shopItemDetailReducer from "reducers/ShopItemDetail";
import appReducer from "reducers/App";
import raffleReducer from "reducers/RaffleTicket";
import raffleTicketDetailReducer from "reducers/RaffleTicketDetail";
import { inventoryReudcer } from "reducers/inventory/inventory.reducer";
import { forgotPassowrdReducer } from "reducers/forgotpassword/forgotPassword";
import { experienceListRefactorReducer } from "reducers/experienceList";
import { ProgressReducer } from "reducers/progress";
import { newMomentumReducer } from "reducers/momemtum";
import { newExperienceItemReducer } from "reducers/experienceItem/experienceItem.reducer";
import { photoExperienceReducer } from "reducers/photo-experience/photo-experience.reducer";
import { experienceStudentListReducer } from "reducers/experienceStudentList/experienceStudentList.reducer";
import { newExperienceReviewReducer } from "reducers/experince-Review/experience-review-reducer";
import { analizeReducer } from "reducers/analize/analize.reducer";
import { timeScoreReducer } from "reducers/timescore.reducer.js";
import { scoresReducers } from "reducers/scores";
import { timingRangeStudentScoreReducer } from "reducers/timing/timingReducer";
import { progressSettingReducer } from "reducers/progressSeting";
import { coinsReducer } from "reducers/coins";
const reducers = combineReducers({
  auth: authReducer,
  loggedInUser: loggedInUserReducer,
  projectList: projectListReducer,
  categoryList: categoryListReducer,
  subcategoryList: subCategoryListReducer,
  teamList: teamListReducer,
  schoolDistrictList: schoolDistrictListReducer,
  highSchoolList: highSchoolListReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  completionList: completionListReducer,
  experienceTypeList: experienceTypeListReducer,
  experienceImageList: exprienceImageListReducer,
  experienceList: getExperienceListReducer,
  experienceTypeDetails: ExperienceDetailsReducer,
  completionDeatils: completionDetailsReducer,
  experienceItem: experienceItemReducer,
  experienceUserList: experienceUserListReducer,
  userExperienceList: userExperienceListReduer,
  listOfUserExperience: listOfUserExperienceReducer,
  pmChartInfo: pmChartInfoReducer,
  momentum: momentumReducer,
  leaderBoard: leaderBoardReducer,
  login: loginReducer,
  xpsBounes: xpsBounesReducer,
  experienceLists: experienceListReducer,
  experiencePassDueList: experiencePassDueListReducer,
  experienceCompleteList: experienceCompleteListReducer,
  universityList: universityListReducer,
  leadContactOrganizationList: leadContactOrganizationListReducer,
  organization: organizationReducer,
  organizationProjectList: organizationProjectListReducer,
  projectDetails: projectDetailsReducer,
  getDetailsOrganization: getDetailsOrganizationReducer,
  categoryProjectList: categoryProjectListReducer,
  subCategoryProjectList: subCategoryProjectListReducer,
  userSystem: userSystemReducer,
  admin: adminReducer,
  adminDetail: adminDetailReducer,
  xpsListProject: xpsListProject,
  shop: shopReducer,
  shopItemDetail: shopItemDetailReducer,
  app: appReducer,
  raffle: raffleReducer,
  raffleTicketDetail: raffleTicketDetailReducer,
  inventory: inventoryReudcer,
  forgotpassword: forgotPassowrdReducer,
  experienceListRefactor: experienceListRefactorReducer,
  Progress: ProgressReducer,
  momentumState: newMomentumReducer,
  newExperienceItem: newExperienceItemReducer,
  photoExperience: photoExperienceReducer,
  experienceStudentList: experienceStudentListReducer,
  newExperienceReview: newExperienceReviewReducer,
  analize: analizeReducer,
  timeScore: timeScoreReducer,
  scores: scoresReducers,
  studentTimeScore: timingRangeStudentScoreReducer,
  progressSetting: progressSettingReducer,
  coins: coinsReducer,
});
const middlewares = [thunk];
const getTokenFromStorage = localStorage.getItem("gradifyToken")
  ? localStorage.getItem("gradifyToken")
  : null;

const initialState = {
  auth: {
    token: getTokenFromStorage,
  },
};
const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
