import React, { useCallback, useRef, useState } from "react";
import {
  CreateShopFormCol,
  CreateShopContainer,
  CreateShopForm,
  CreateShopFormRow,
  ShopInput,
  LabelShop,
  SwitchLabelShop,
  ShopImage,
  ShopEditButtton,
  ShopEditButttonText,
  ShopEditButttonIcon,
  SuggestShopText,
  ShopButon,
  Spinner,
  ImagContainer,
} from "./index.style";
import { MdEdit } from "react-icons/md";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import SelectComponent from "components/control.component/select.component";
const ShopAddItemPopup = ({
  shopImage,
  onChangeItemImg,
  title,
  onTitleChanged,
  initialStock,
  onInitialStockChanged,
  unLockLevel,
  onUnLockLevelChanged,
  dollarCost,
  onDollarCostChanged,
  gemsNumber,
  onGemsNumberChanged,
  description,
  onDescriptionChanged,
  isPrizeWheel,
  onPrizeWheelChange,
  isStore,
  onStoreChange,
  onCancelSaveItemClicked,
  onSaveShopItemClicked,
  categories,
  onChangeCategory,
}) => {
  const shopItemImgInputRef = useRef();

  const handleOnEditImgClicked = useCallback(() => {
    if (shopItemImgInputRef) shopItemImgInputRef.current.click();
  }, []);
  return (
    <CreateShopContainer className="form-Shop-add">
      <CreateShopForm>
        <div style={{ display: "flex" }}>
          <CreateShopFormRow
            style={{ paddingLeft: 20, width: "auto", flexDirection: "column" }}
          >
            <CreateShopFormCol
              alignment="flex-start"
              style={{ paddingLeft: 0, position: "relative" }}
            >
              <ImagContainer>
                {shopImage.isLoading ? (
                  <Spinner />
                ) : (
                  <ShopImage
                    src={
                      shopImage?.url ||
                      "https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg"
                    }
                    alt="gradegy"
                  />
                )}
              </ImagContainer>
              <input
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                ref={shopItemImgInputRef}
                onChange={onChangeItemImg}
              />
              <ShopEditButtton onClick={handleOnEditImgClicked}>
                <ShopEditButttonText>Edit</ShopEditButttonText>
                <ShopEditButttonIcon>
                  <MdEdit color="#34C38F" size={12} />
                </ShopEditButttonIcon>
              </ShopEditButtton>
            </CreateShopFormCol>

            <CreateShopFormCol
              Direction="column"
              style={{ paddingBottom: 10, paddingTop: 20 }}
            >
              <LabelShop style={{ fontSize: 18 }}>Available in:</LabelShop>
            </CreateShopFormCol>
            <CreateShopFormCol
              Direction="row"
              style={{ flexDirection: "column", paddingLeft: 25 }}
            >
              <CreateShopFormCol
                Direction="row"
                style={{
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  paddingBottom: 10,
                }}
              >
                <SwitchLabelShop>Store</SwitchLabelShop>
                <CustomSwitch
                  isToggle={isStore}
                  onChangeToggle={onStoreChange}
                />
              </CreateShopFormCol>
              <CreateShopFormCol
                Direction="row"
                style={{ justifyContent: "space-between", maxWidth: "100%" }}
              >
                <SwitchLabelShop>Prize Wheel</SwitchLabelShop>
                <CustomSwitch
                  isToggle={isPrizeWheel}
                  onChangeToggle={onPrizeWheelChange}
                />
              </CreateShopFormCol>
            </CreateShopFormCol>
          </CreateShopFormRow>

          <CreateShopFormRow style={{ flexDirection: "column" }}>
            <CreateShopFormCol>
              <CreateShopFormCol
                style={{
                  paddingRight: 5,
                  paddingLeft: 12,
                  marginTop: 35,
                  flexWrap: "wrap",
                  maxWidth: 360,
                  height: 180,
                }}
              >
                <CreateShopFormCol Direction="column" style={{ width: "100%" }}>
                  <LabelShop>Item Title</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    FontFamily="Rajdhani"
                    name="itemName"
                    placeholder="item name"
                    value={title}
                    onChange={onTitleChanged}
                  />
                </CreateShopFormCol>

                <CreateShopFormCol
                  Direction="column"
                  style={{ maxWidth: "50%", paddingBottom: 5 }}
                >
                  <LabelShop>Stock</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    FontFamily="Rajdhani"
                    name="itemName"
                    placeholder="Initial stock"
                    value={initialStock}
                    onChange={onInitialStockChanged}
                  />
                </CreateShopFormCol>

                <CreateShopFormCol
                  Direction="column"
                  style={{ maxWidth: "50%", paddingBottom: 5 }}
                >
                  <LabelShop>Unlock Level</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    FontFamily="Rajdhani"
                    name="itemName"
                    placeholder="average user level"
                    value={unLockLevel}
                    onChange={onUnLockLevelChanged}
                  />
                </CreateShopFormCol>

                <CreateShopFormCol Direction="column">
                  <LabelShop>Category</LabelShop>

                  <SelectComponent
                    style={{ width: "100%", height: 35 }}
                    options={categories}
                    name="category"
                    selected={true}
                    onChange={(e) => {
                      onChangeCategory(e.target.value);
                    }}
                  />
                </CreateShopFormCol>

                <CreateShopFormCol Direction="column">
                  <LabelShop>Sub Category</LabelShop>
                  <SelectComponent
                    style={{ width: "85%", height: 35 }}
                    options={""}
                    name="subCategory"
                    selected={true}
                    onChange={(e) => {}}
                  />
                </CreateShopFormCol>
              </CreateShopFormCol>

              <div
                style={{
                  width: 1,
                  backgroundColor: "#707070",
                  height: "92%",
                  paddingTop: 20,
                }}
              ></div>

              <CreateShopFormCol
                style={{
                  paddingRight: 10,
                  paddingLeft: 15,
                  marginTop: 20,
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                <h2 style={{ textAlign: "center", fontSize: 28 }}>Cost</h2>
                <CreateShopFormCol Direction="column">
                  <LabelShop>Cost per Item </LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    FontFamily="Rajdhani"
                    name="cost"
                    placeholder="Dollar amount "
                    value={dollarCost}
                    onChange={onDollarCostChanged}
                  />
                </CreateShopFormCol>

                <SuggestShopText>Suggested Gems</SuggestShopText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    -<sub> Gems</sub>
                  </div>
                </div>

                <CreateShopFormCol Direction="column">
                  <LabelShop>Gems</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    FontFamily="Rajdhani"
                    name="cost"
                    placeholder="Set your own price "
                    value={gemsNumber}
                    onChange={onGemsNumberChanged}
                  />
                </CreateShopFormCol>
              </CreateShopFormCol>
            </CreateShopFormCol>

            <CreateShopFormCol style={{ flexDirection: "column" }}>
              <LabelShop>Description</LabelShop>
              <textarea
                style={{ width: "95%", minHeight: 80, borderColor: "#e5e9ec" }}
                value={description}
                onChange={onDescriptionChanged}
              />
            </CreateShopFormCol>
          </CreateShopFormRow>
        </div>
        <CreateShopFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 30,
            marginBottom: 10,
            justifyContent: "center",
          }}
        >
          <CreateShopFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <ShopButon
              onClick={onSaveShopItemClicked}
              disabled={""}
              style={{ padding: "12px 50px" }}
            >
              save
            </ShopButon>
          </CreateShopFormCol>
          <CreateShopFormCol style={{ paddingLeft: 30 }}>
            <ShopButon
              style={{ padding: "12px 50px" }}
              onClick={onCancelSaveItemClicked}
            >
              Cancel
            </ShopButon>
          </CreateShopFormCol>
        </CreateShopFormRow>
      </CreateShopForm>
    </CreateShopContainer>
  );
};

export default ShopAddItemPopup;
