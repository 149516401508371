import {
  ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT,
  DELETE_ADMIN_DETAIL_SPECIAL_PROJECT,
} from "constant";
import store from "Store";
export const addNewSpecialProjectAction = (dispatch, newSpecialProject) => {
  const adminDetail = store.getState().adminDetail.adminDetail;
  const specialProjectList = store.getState().adminDetail.specialProjectList;
  const data = {
    ...newSpecialProject,
    organizationId: adminDetail.system[0].organization._id,
  };
  const existedProject = Object.values(specialProjectList).filter(
    (item) =>
      item.projectId === newSpecialProject.projectId &&
      !item?.categoryId &&
      !item?.subCategoryId
  );
  const existedCat = Object.values(specialProjectList).filter(
    (item) =>
      item.categoryId === newSpecialProject.categoryId && !item?.subCategoryId
  );
  //project selected
  if (
    newSpecialProject?.projectId &&
    !newSpecialProject?.categoryId &&
    !newSpecialProject?.subCategoryId
  ) {
    const filteredCatSubCat = Object.values(specialProjectList).filter(
      (item) =>
        item.projectId === newSpecialProject.projectId &&
        (item?.categoryId || item?.subCategoryId)
    );
    filteredCatSubCat.forEach((project) => {
      dispatch({
        type: DELETE_ADMIN_DETAIL_SPECIAL_PROJECT,
        payload: project?._id,
      });
    });
    if (!existedProject.length) {
      dispatch({
        type: ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT,
        payload: { newSpecialProject: data },
      });
    }
  }
  //category selected
  if (
    newSpecialProject?.projectId &&
    newSpecialProject?.categoryId &&
    !newSpecialProject?.subCategoryId
  ) {
    const filteredSubCat = Object.values(specialProjectList).filter(
      (item) =>
        item.categoryId === newSpecialProject.categoryId && item?.subCategoryId
    );
    filteredSubCat.forEach((project) => {
      dispatch({
        type: DELETE_ADMIN_DETAIL_SPECIAL_PROJECT,
        payload: project?._id,
      });
    });

    if (!(existedCat.length || existedProject.length)) {
      dispatch({
        type: ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT,
        payload: { newSpecialProject: data },
      });
    }
  }
  if (
    newSpecialProject?.projectId &&
    newSpecialProject?.categoryId &&
    newSpecialProject?.subCategoryId
  ) {
    //subCat selected
    const existedSubCat = Object.values(specialProjectList).filter(
      (item) => item.subCategoryId === newSpecialProject.subCategoryId
    );

    if (!(existedSubCat.length || existedProject.length || existedCat.length)) {
      dispatch({
        type: ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT,
        payload: { newSpecialProject: data },
      });
    }
  }
};
