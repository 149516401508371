import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryProjectAction } from "actions/categoryListAction";
import { getsubCategoryProjectAction } from "actions/subCategoryListAction";
import { getTeamList } from "actions/teamListAction";
import { getSchoolDistrictList } from "actions/schoolDistrictlistActions";
import { createNewUser } from "actions/userListAction";
import { getOrganizationAction } from "actions/organizationActions";
import { gradify } from "axios.config";
import AddStudentPopup from "components/students/AddStudentPopup";
const AddStudentContainer = ({ close, changeShowConfirm }) => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    age: 18,
    firstGen: true,
    contactManner: [],
    working: "YES",
    gender: "Male",
    team: "61addfbeffbcfbbe3ffa84e8",
    educationalPhase: "HS-9",
    system: [],
  });
  const [tagPanel, setTagPanel] = useState(false);
  const [team, setTeam] = useState("");
  const [schoolDistrict, setSchoolDistrict] = useState("");
  const [universityName, setUniversityName] = useState("");
  const [highschoolState, setHighSchoolState] = useState("");
  const [textMe, setTextMe] = useState(false);
  const [callMe, setCallMe] = useState(false);
  const [emailMe, setEmailMe] = useState(false);
  const [showFormWarning, setShowFormWarning] = useState(false);

  const dispatch = useDispatch();

  const { teams } = useSelector((state) => state.teamList);

  const { schoolDistricts } = useSelector((state) => state.schoolDistrictList);
  const { loading, error, addedConfirm } = useSelector(
    (state) => state.userList
  );
  const { user } = useSelector((state) => state.loggedInUser);
  useEffect(() => {
    dispatch(getTeamList());
    dispatch(getSchoolDistrictList());
    dispatch(getOrganizationAction(user));
  }, []);
  useEffect(() => {
    if (schoolDistricts.length > 0) {
      setSchoolDistrict(schoolDistricts[0]._id);
      setUserInfo({
        ...userInfo,
        schoolDistrict: {
          ...userInfo.schoolDistrict,
          schoolDistrictID: schoolDistricts[0]._id,
        },
      });
    }
  }, [schoolDistricts]);

  useEffect(() => {
    if (teams.length > 0) {
      setTeam(teams[0]._id);
    }
  }, [teams]);

  useEffect(() => {
    if (addedConfirm) {
      changeShowConfirm();
      close();
    }
  }, [addedConfirm]);

  const closeWarningForm = () => {
    setShowFormWarning(false);
    close();
  };
  const closeWarning = () => {
    setShowFormWarning(false);
  };

  const closeForm = (e) => {
    setShowFormWarning(true);
  };

  const saveStudent = async () => {
    if (userInfo.system.length > 0) {
      dispatch(createNewUser(userInfo));
    } else {
      alert("Please add the Organizatoin and Project");
    }
  };

  const loadOptionHandler = async (inputText, callback) => {
    const res = await gradify.get(`schooldistricts/search?term=${inputText}`);

    callback(
      res.data.map((item) => {
        return {
          label: item.agancy_name,
          value: item._id,
        };
      })
    );
  };

  const loadUniversityOptionHandler = async (inputText, callback) => {
    const res = await gradify.get(`universities/search?term=${inputText}`);
    callback(
      res.data.map((item) => {
        return {
          label: item.instnm,
          value: item._id,
        };
      })
    );
  };

  const loadHighschoolHanlder = async (inputText, callback) => {
    const res = await gradify.get(
      `highschools/search?term=${inputText}&agancy=${schoolDistrict.label}`
    );

    callback(
      res.data.map((item) => {
        return {
          label: item.school_name,
          value: item._id,
        };
      })
    );
  };
  const changeText = (e) => {
    if (e.target.name === "schoolDistrict.schoolDistrictID") {
      setUserInfo({
        ...userInfo,
        schoolDistrict: {
          ...userInfo.schoolDistrict,
          schoolDistrictID: e.target.value,
        },
      });
    } else if (e.target.name === "project") {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    } else if (e.target.name === "category") {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    } else if (e.target.name === "schoolDistrict.schoolDistrictId") {
      setUserInfo({
        ...userInfo,
        schoolDistrict: {
          ...userInfo.schoolDistrict,
          schoolDistrictId: e.target.value,
        },
      });
    } else if (e.target.name === "schoolDistrict.schoolDistrictEmail") {
      setUserInfo({
        ...userInfo,
        schoolDistrict: {
          ...userInfo.schoolDistrict,
          schoolDistrictEmail: e.target.value,
        },
      });
    } else if (e.target.name === "firstGen") {
      setUserInfo({
        ...userInfo,
        firstGen: e.target.value === "YES" ? true : false,
      });
    } else {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    }
  };
  return (
    <AddStudentPopup
      userInfo={userInfo}
      changeText={changeText}
      teams={teams}
      setUserInfo={setUserInfo}
      schoolDistrict={schoolDistrict}
      setSchoolDistrict={setSchoolDistrict}
      loadOptionHandler={loadOptionHandler}
      highschoolState={highschoolState}
      setHighSchoolState={setHighSchoolState}
      loadHighschoolHanlder={loadHighschoolHanlder}
      saveStudent={saveStudent}
      loading={loading}
      closeForm={closeForm}
      showFormWarning={showFormWarning}
      closeWarningForm={closeWarningForm}
      closeWarning={closeWarning}
      error={error}
      tagPanel={tagPanel}
      setTagPanel={setTagPanel}
      textMe={textMe}
      setTextMe={setTextMe}
      emailMe={emailMe}
      setEmailMe={setEmailMe}
      callMe={callMe}
      setCallMe={setCallMe}
      close={close}
      changeShowConfirm={changeShowConfirm}
      universityOption={loadUniversityOptionHandler}
      setUniversityName={setUniversityName}
      universityName={universityName}
    />
  );
};
export default AddStudentContainer;
