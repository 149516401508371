import {
  ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL,
  DELETE_ADMIN_DETAIL_HIGHSCHOOL,
} from "constant";
import store from "Store";
export const addSchoolDistrictAction = (dispatch, newSchoolDistrict) => {
  const adminDetail = store.getState().adminDetail.adminDetail;
  const highSchoolList = store.getState().adminDetail.highSchoolsList;
  const filteredHighSchool = Object.values(highSchoolList).filter(
    (item) => item.projectId === newSchoolDistrict?._id && item?.categoryId
  );
  const existedProject = Object.values(highSchoolList).filter(
    (item) => item.projectId === newSchoolDistrict?._id && !item?.categoryId
  );
  filteredHighSchool.forEach((school) => {
    dispatch({
      type: DELETE_ADMIN_DETAIL_HIGHSCHOOL,
      payload: school?._id,
    });
  });
  const data = {
    ...newSchoolDistrict,
    organizationId: adminDetail.system[0].organization._id,
    projectId: newSchoolDistrict._id,
    categoryId: null,
  };
  if (!existedProject.length) {
    dispatch({
      type: ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL,
      payload: { newHighschool: data },
    });
  }
};
