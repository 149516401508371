import {
  SET_SHOP_LIST,
  ADD_SHOP_ITEM_IN_SHOPLIST,
  SET_SHOP_PAGE_STATUS,
  SET_SHOP_ITEM_PHOTO,
  SHOP_TOAST_MESSAGE,
  SHOP_SET_SEARCH_INPUT,
} from "constant";
import produce from "immer";

const INITIAL_STATE = {
  shopImage: {
    isLoading: false,
    url: "https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg",
  },
  shopItemList: [],
  pageStatus: "Default",
  toastMessage: {
    message: "",
    hasError: false,
  },
  pagination: null,
  total: 0,
  searchInput: null,
};

const shopReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_SHOP_ITEM_PHOTO:
        draft.shopImage = {
          ...draft.shopImage,
          isLoading: action.payload.isLoading,
          url: action.payload.url,
        };
        break;
      case SHOP_SET_SEARCH_INPUT:
        draft.searchInput = action.payload;
        break;
      case SET_SHOP_LIST:
        draft.shopItemList = action.payload?.data;
        draft.pagination = action.payload?.pagination;
        draft.total = action.payload?.total;
        break;
      case ADD_SHOP_ITEM_IN_SHOPLIST:
        draft.shopItemList = [...draft.shopItemList, action.payload];
        break;
      case SHOP_TOAST_MESSAGE:
        draft.toastMessage = action.payload;
        break;
      case SET_SHOP_PAGE_STATUS:
        draft.pageStatus = action.payload;
        break;
      // no default
    }
  });
export default shopReducer;
