import {
  SUBCATEGORY_LIST_FAIL,
  SUBCATEGORY_LIST_SUCCESS,
  SUBCATEGORY_LIST_REQUEST,

  SUBCATEGORY_PROJECT_ADD_FAIL,
  SUBCATEGORY_PROJECT_ADD_REQUEST,
  SUBCATEGORY_PROJECT_ADD_SUCCESS,

  SUBCATEGORY_PROJECT_DELETE_FAIL,
  SUBCATEGORY_PROJECT_DELETE_REQUEST,
  SUBCATEGORY_PROJECT_DELETE_SUCCESS,

  SUBCATEGORY_PROJECT_LIST_FAIL,
  SUBCATEGORY_PROJECT_LIST_REQUEST,
  SUBCATEGORY_PROJECT_LIST_SUCCESS,
} from "../constant/subcategory";

export const subCategoryListReducer = (
  state = { subCategories: [] },
  action
) => {
  switch (action.type) {
    case SUBCATEGORY_LIST_REQUEST:
      return { ...state, loading: true, subCategories: [] };
    case SUBCATEGORY_LIST_SUCCESS:
      return { ...state, loading: false, subCategories: action.payload.data };
    case SUBCATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        subCategories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const subCategoryProjectListReducer = (
  state = { subCategories: [] },
  action
) => {
  switch (action.type) {
    case SUBCATEGORY_PROJECT_LIST_REQUEST:
      return { loading: true, subCategories: [] };
    case SUBCATEGORY_PROJECT_LIST_SUCCESS:
      return { loading: false, subCategories: action.payload };
    case SUBCATEGORY_PROJECT_LIST_FAIL:
      return { loading: false, subCategories: [], error: action.payload };
    case SUBCATEGORY_PROJECT_ADD_REQUEST:
      return { ...state, loading: true };
    case SUBCATEGORY_PROJECT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategories: [...state.subCategories, action.payload],
      };
    case SUBCATEGORY_PROJECT_ADD_FAIL:
      return { ...state, loading: false };
    case SUBCATEGORY_PROJECT_DELETE_REQUEST:
      return { ...state, loading: true };
    case SUBCATEGORY_PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategories: state.subCategories.filter(
          (item) => item._id !== action.payload
        ),
      };
    case SUBCATEGORY_PROJECT_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
