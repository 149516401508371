import produce from "immer";
import { P_P, P_S, P_E } from "../../constant/progress";

const INITIAL_STATE = {
  isPending: false,
  isSuccess: false,
  isError: false,
  progressCalc: null,
};

export const ProgressReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case P_P:
        draft.isPending = true;
        break;
      case P_S:
        draft.isPending = false;
        draft.isSuccess = true;
        draft.progressCalc = action.payload;
        break;
      case P_E:
        draft.isError = action.payload;
        break;
    }
  });
