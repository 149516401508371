import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Admin from "./main/Admin";

import AuthForm from "./views/authentication/Form";

import { ThemeProvider } from "styled-components";
import { theme } from "./components/styled.components";
import { Provider } from "react-redux";
import store from "./Store";

import "./assets/fonts/Poppins-Bold.ttf";
import "./assets/fonts/Poppins-Light.ttf";
import "./assets/fonts/Poppins-Medium.ttf";
import "./assets/fonts/Poppins-Regular.ttf";
import "./index.css";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";

ReactDOM.render(
  <ClearBrowserCacheBoundary auto fallback="Loading" duration={60000}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Route path="/admin" component={Admin} />
          <Route path="/login" component={AuthForm} />
          <Redirect from="/" to="/admin" />
        </Router>
      </ThemeProvider>
    </Provider>{" "}
  </ClearBrowserCacheBoundary>,
  document.getElementById("root")
);
