import styled from "styled-components";

export const OrganizationsContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  min-height:calc(100vh - 140px);
`;
export const OrganizationsHeaderControle = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  @media (max-width: 1770px) {
    
  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
`;
export const SearchOrganizations = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.lightdark};
  padding-left: 35px;
  text-indent: 10px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 13px;

  &:focus {
    outline: none;
  }
`;
export const SearchOrganizationsContainer = styled.div`
  width: 227px;
  height: 36px;
  position:relative;
  padding-left:30px;
  padding-top:5px;

`;
export const OrganizationsButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 10px 19px 10px 41px;
  box-shadow:0px 3px 6px rgba(0,0,0,0.17);
  margin-left: 55px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight:300;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left:20px;
  } ;
  span{
    white-space: nowrap;
  }
`;
export const OrganizationsContentContainer = styled.div`

  padding-top:2px;
  margin-left: 50px;
  max-width: 1650px;
  @media (max-width:1770px) {
    
  }
`;
