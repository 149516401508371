import produce from "immer";
import {
  GET_COINS_REQUEST,
  GET_COINS_SUCCESS,
  GET_COINS_FAILD,
} from "../../constant/coins";

const INITIAL_STATE = {
  loading: false,
  coins: null,
  error: null,
};

export const coinsReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_COINS_REQUEST:
        draft.loading = true;
        break;

      case GET_COINS_SUCCESS:
        draft.loading = false;
        draft.coins = action.payload;
        break;

      case GET_COINS_FAILD:
        draft.loading = false;
        draft.error = action.payload;
        break;
    }
  });
