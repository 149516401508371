import React, { useEffect, useState } from "react";
import {
  SettingTitleRow,
  SettingText,
  DriverTitle,
  DriverRow,
  DriverColumn,
  DriverLabel,
  DriverTitleBox,
  DriverMainBox,
  DriverMainLeftBox,
  DriverMainTitle,
  DriverSaveButton,
} from "components/styled.components";
import SelectComponent from "components/control.component/select.component";
import GradegyDropdown from "components/gradegyDropDown";
import { useSelector, useDispatch } from "react-redux";
import {
  getTimeRangeStudentScoreAction,
  timeRangeStudentScoreAction,
} from "actions/timeRangeStudent";
const options = [
  { _id: "all", value: "all", title: "All" },
  {
    _id: "dates-for-range",
    value: "dates-for-range",
    title: "Dates for Range",
  },
  { _id: "#-of-days", value: "#-of-days", title: "# of Days" },
];

const Drivers = ({
  categoryOptions,
  onCategoryChanged,
  onStartDateChange,
  onEndDateChange,
  endDate,
  selfTags,
  onSelfTagsChange,
  futureTags,
  onFutureTagsChange,
  connectionTags,
  onConnectionTagsChange,
  emotionalTags,
  onEmotionalTagsChange,
  socialTags,
  onSocialTagsChange,
  financialTags,
  onChangeFinancialTags,
  academicTags,
  onAcademicTagsChange,
  situationalTags,
  onSituationalTags,
}) => {
  const [studentTiming, setStudentTiming] = useState("dates-for-range");

  const [timeRangeValue, setTimeRangeValue] = useState({
    id: "637b8535808db224772b0dae",
    typeTime: studentTiming,
    days: 0,
    startDate: "",
    endDate: "",
  });
  const { loading, errors, timingRange } = useSelector(
    (state) => state.studentTimeScore
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTimeRangeStudentScoreAction());
  }, []);

  useEffect(() => {
    if (timingRange) {
      if (timingRange) {
        setStudentTiming(timingRange.typeTime);
      } else {
        setStudentTiming("All");
      }
    }
  }, [timingRange]);

  useEffect(() => {
    console.log("time range", timingRange);
    if (!loading) {
      if (timingRange) {
        const timeStudentFormat = {
          ...timingRange,
          startDate: timingRange
            ? timingRange?.startDate?.toString().split("T")[0]
            : "",
          endDate: timingRange
            ? timingRange?.endDate?.toString().split("T")[0]
            : "",
          id: timingRange._id,
        };
        console.log("time format", timeStudentFormat);
        setTimeRangeValue(timeStudentFormat);
      }
    }
  }, [loading, timingRange]);
  const onChangeTimeRangeValueHandler = (e) => {
    setTimeRangeValue({
      ...timeRangeValue,
      [e.target.name]: e.target.value,
    });
  };

  const updateTimeRangeHandler = () => {
    const updateData = {
      ...timeRangeValue,
      days: parseInt(timeRangeValue.days),
      typeTime: studentTiming,
    };

    console.log("update driver", updateData);
    dispatch(timeRangeStudentScoreAction(updateData));
    setTimeRangeValue(updateData);
  };

  return (
    <>
      <DriverTitleBox>
        <DriverTitle>Student Score</DriverTitle>
        {/* <DriverColumn>
          <DriverLabel
            style={{
              position: "absolute",
              left: 18,
              top: -10,
            }}
          >
            Category
          </DriverLabel> */}
        {/* <SelectComponent
            name="Select Category"
            options={categoryOptions}
            style={{
              marginRight: 0,
              marginLeft: 10,
              marginTop: 0,
              width: 160,
              height: 30,
            }}
            selected={true}
            onChange={onCategoryChanged}
          /> */}
        {/* </DriverColumn> */}
      </DriverTitleBox>

      <DriverRow style={{ marginTop: -10 }}>
        <DriverColumn>
          <p style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}>
            Timing
          </p>
          <div style={{ minWidth: 120 }}></div>

          <SelectComponent
            options={options}
            name="timing"
            selectedValue={studentTiming}
            style={{ paddingLeft: 5, paddingTop: 5, paddingBottom: 5 }}
            onChange={(e) => {
              console.log("selected Time", e.target, e.target.value);
              setTimeRangeValue({
                ...timeRangeValue,
                typeTime: e.target.value,
              });
              setStudentTiming(e.target.value);
            }}
          />
        </DriverColumn>
        {/* {studentTiming === "education-phase" && (
            <>
                  <DriverColumn>
                  <p style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}>
                  Educational Phases
                  </p>
                  <SelectComponent
                     name="Educational Phases"
                     options={""}
                     style={{
                        marginRight: 0,
                        marginLeft: 10,
                        marginTop: 0,
                        width: 160,
                        height: 30
                        
                     }}
                     selected={true}
                     onChange={()=>{}}
                  />
          
               </DriverColumn>

            </>
          )} */}

        {studentTiming === "#-of-days" && (
          <>
            <DriverColumn>
              <p
                style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}
              >
                Day Ago
              </p>
              <input
                style={{
                  width: 40,
                  textAlign: "center",
                  marginTop: -2,
                  color: "#FF3135",
                }}
                type="text"
                placeholder="15"
                id="days"
                name="days"
                value={timeRangeValue.days}
                onChange={onChangeTimeRangeValueHandler}
              />
            </DriverColumn>
          </>
        )}

        {studentTiming === "dates-for-range" && (
          <>
            <DriverColumn>
              <p
                style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}
              >
                Start Date
              </p>
              <input
                type="date"
                name="startDate"
                id="startDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={timeRangeValue.startDate}
                onChange={onChangeTimeRangeValueHandler}
              />
            </DriverColumn>
            <DriverColumn>
              <p
                style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}
              >
                End Date
              </p>
              <input
                type="date"
                name="endDate"
                id="endDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={timeRangeValue.endDate}
                onChange={onChangeTimeRangeValueHandler}
              />
            </DriverColumn>
          </>
        )}
      </DriverRow>

      <DriverMainBox style={{ marginTop: -20 }}>
        <DriverMainLeftBox>
          <div className="personal">
            <DriverColumn>
              <DriverMainTitle>Personal Develompent Area Score</DriverMainTitle>
              <div className="self-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Self-Efficacy tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={selfTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{
                        width: 40,
                        textAlign: "center",
                        marginTop: -2,
                        color: "#FF3135",
                      }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={selfTags}
                      onChange={onSelfTagsChange}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>
              <div className="future-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Future Development Tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={selfTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", marginTop: -2 }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={futureTags}
                      onChange={onFutureTagsChange}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>
            </DriverColumn>
          </div>

          <div className="connection-score" style={{ marginTop: -20 }}>
            <DriverColumn>
              <DriverMainTitle>Connection Engagements Score</DriverMainTitle>
              <div className="connection-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Connectedness Tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={connectionTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", marginTop: -2 }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={connectionTags}
                      onChange={onConnectionTagsChange}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>
              <div className="emotional-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Emotional Tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={emotionalTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", marginTop: -2 }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={emotionalTags}
                      onChange={onEmotionalTagsChange}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>
              <div className="social-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Social Tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={socialTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", marginTop: -2 }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={socialTags}
                      onChange={onSocialTagsChange}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>
            </DriverColumn>
          </div>
        </DriverMainLeftBox>

        <DriverMainLeftBox>
          <div className="key">
            <DriverColumn>
              <DriverMainTitle>Key Persistence Factors Score</DriverMainTitle>
              <div className="financial-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Financial Tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={financialTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{
                        width: 40,
                        textAlign: "center",
                        marginTop: -2,
                        color: "#02C68C",
                      }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={financialTags}
                      onChange={onChangeFinancialTags}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>
              <div className="accademic-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Academic Tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={academicTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", marginTop: -2 }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={academicTags}
                      onChange={onAcademicTagsChange}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>

              <div className="accademic-tags" style={{ marginLeft: 25 }}>
                <h4 style={{ fontWeight: "bold", fontSize: 20 }}>
                  Situational Tags
                </h4>
                <DriverRow
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: -20,
                    marginTop: -20,
                  }}
                >
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Current Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", border: "none" }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={situationalTags}
                      disabled={true}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Override Total
                    </p>
                    <input
                      style={{ width: 40, textAlign: "center", marginTop: -2 }}
                      type="text"
                      placeholder="-"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={situationalTags}
                      onChange={onSituationalTags}
                    />
                  </DriverColumn>
                </DriverRow>
              </div>
            </DriverColumn>
          </div>
        </DriverMainLeftBox>
      </DriverMainBox>
      <DriverSaveButton onClick={updateTimeRangeHandler}>Save</DriverSaveButton>
    </>
  );
};

export default Drivers;
