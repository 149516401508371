import React from "react";
import "./expreienceHeader.css";
import EditButton from "components/general/editButtton";
import { useHistory } from "react-router-dom";

const ExperienceREviewCardHeader = ({ experience , id }) => {
  let history = useHistory();
  return (
    <div className="review-container">
      <div
        className="review-wrapper"
        style={{ background: experience?.experienceType?.color }}
      >
        <div className="review-edit-button">

        <EditButton
            style={{
           
              borderWidth: 5,
              borderRadius:10,
              width:90,
              height:58,
              borderStyle: "solid",
              borderColor: "#fff",
              fontSize:24
            }}

            onClick={() =>
              history.push(`/admin/experiences/edit/${id}`)
            }
          />
        </div>
        <div className="review_title">
          <h1>{experience?.title}</h1>
          <h3>{experience?.description}</h3>
        </div>

        <div className="review_image">
          <img
            className="review_image_img"
            src={experience?.experienceCardPic}
            alt="experienceCard"
          />
          <div style={{paddingBottom:20}}>
            <span className="xp-content-review">
              {experience?.xpCompletion}
            </span>
            <span className="xp-text">XP</span>
          </div>
        </div>
        <div className="review_icon">
          <div className="review_icon_exp">
            <img src={experience?.experienceType?.icon} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceREviewCardHeader;
