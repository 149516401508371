import styled from "styled-components";

export const CreateRaffleFormCol = styled.div`
  display:flex;
  justify-content:${({alignment}) => alignment};
  text-align:left;
  flex-direction:${({Direction}) => Direction};
  padding:0 10px;
  
`;

export const CreateRaffleFormRow = styled.div`
  width:95%;
  height:40px;
  margin-top:-40px;
  margin-left:35px;
  display:flex;
  flex-wrap:wrap;
  padding:10px;
  position:relative;

`;




export const LabelRaffle = styled.label`
  font-family:${({theme}) => theme.fonts.Poppins};
  font-size:15px;
  color:${({theme}) => theme.colors.gray};


`;

export const SearchRaffleContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
`;

export const RaffleContentContainer = styled.div`
  padding: 0 40px;
  padding-top: 2px;
  max-width: 1720px;
  @media (max-width: 1770px) {

  }
`;

export const RaffleButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 10px 17px 10px 14px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  width: 165px;
  font-weight: 400;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
    width:80%;
  }
`;

export const SearchRaffle = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.lightdark};
  padding-left: 35px;
  text-indent: 10px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 13px;

  &:focus {
    outline: none;
  }
`;

export const RaffleHeaderControle = styled.div`
  width: 95%;
  display: flex;
  height: 100px;
  margin-left: 50px;
  margin-bottom:-20px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1770px) {

  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
`;

export const RaffleContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100vh - 140px);
`;

export const PrizeRaffleButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 30px;
  width: 210px;
  padding: 8px 7px 8px 21px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 15px;
  } ;
`;
