import React, { useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setExperienceTypeFilter } from "actions/experienceAction";

const FilterExperience = () => {
  const dispatch = useDispatch();
  const expActiveRef = useRef(null);
  const publishHandler = useCallback(() => {
    expActiveRef.current.style.transform = "translateX(0)";
    setExperienceTypeFilter(dispatch, "publish");
  }, [dispatch]);

  const savedHandler = useCallback(() => {
    expActiveRef.current.style.transform = "translateX(100%)";
    setExperienceTypeFilter(dispatch, "saved");
  }, [dispatch]);
  return (
    <div className="filter-container">
      <div
        className="publish experineceFilterActive"
        onClick={publishHandler}
      ></div>
      <div className="saved" onClick={savedHandler}></div>
      <div className="expsActive" ref={expActiveRef}></div>
    </div>
  );
};

export default FilterExperience;
