

export const formatInventoryList = (inventories,shopItem=null,raffelTicket=null) => {
   const content = {};
   console.log(shopItem)
    inventories.forEach( inventory => {
        content[inventory._id] = {
            stock_id:inventory._id,
            shopItem: shopItem ? inventory.shopItem : null,
            raffelTicket: raffelTicket ? inventory.raffelTicket : null,
            sold:inventory.sold,
            serialNumber:inventory.serialNumber,
            digitalCode:inventory.digitalCode,
            date_Purchased:inventory.date_Purchased,
            pickup:inventory.pickup

        }
    });

    return content;
}


export const formatInvetory = (inventory,shopItem=null,raffelTicket=null) => {
    const content = {};
    content[inventory._id] = {
        stock_id:inventory._id,
        shopItem: shopItem ? inventory.shopItem : null,
        raffelTicket: raffelTicket ? inventory.raffelTicket : null,
        sold:inventory.sold,
        serialNumber:inventory.serialNumber,
        digitalCode:inventory.digitalCode,
        date_Purchased:inventory.date_Purchased,
        pickup:inventory.pickup
    } ;
    console.log(content)
    return content ;

}