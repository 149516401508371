import React, { useState } from "react";
import styled from "styled-components";
import {
  ExperienceDetailsContainer,
  ExperienceTitle,
  ExperienceDetailsHeader,
  ExperienceSubTitle,
  ExperienceTitleContainer,
  ExperienceDatilsInfo,
  ExperienceDetailsCol,
  ExperienceRow,
  ExperienceCardDetails,
  ExperienceCardDetailsInfo,
  ExperienceCardDetailsAction,
  LabelExperience,
  StarButton,
} from "components/styled.components";
import "./view.css";
import { ShadowBox } from "assets/style/General.style";
import TabName from "views/assets/experience/TabName";
import SelectComponent from "components/control.component/select.component";
import { FaCompass } from "react-icons/fa";
import TitleLinkImage from "assets/image/title-link.png";
import "views/view.css";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import PickExperiencePicture from "components/pickExperiencePicture/PickExperiencePicture";

import Confirm from "components/display.component/confirm.components";
import { withRouter } from "react-router-dom";
import AnimationCheck from "components/animation.icon/animation.icon.components";
import { BsCaretRightFill, BsExclamationSquareFill } from "react-icons/bs";
import AnimationError from "components/animation.icon/animation.error";
import { EDUCATIONAL_PHASES } from "constants/index";
import StarBtn from "components/buttons/experience/StarBtn";
import FireBtn from "components/buttons/experience/FireBtn";
const ExperienceCard = ({
  experienceType,
  onChangeExperienceType,
  onCompletionTypeChange,
  onSubmitAsPublishClick,
  onSubmitAsSaveClick,
  onStartDateChange,
  startTime,
  onStartTimeChange,
  endDate,
  onEndDateChange,
  endTime,
  onEndTimeChange,
  categoryOptions,
  onCategoryChanged,
  subCategoryOptions,
  onSubCategoryChanged,
  onEducationPhaseChange,
  title,
  onTitleChange,
  subTitle,
  onSubTitleChange,
  description,
  onDescriptionChange,
  textLinkTitle,
  onTextLinkChange,
  imgUrl,
  xpCompletion,
  experienceTypeDetailsName,
  experienceTypeDetailsIcon,
  experienceTypeDetailsColor,
  completionDetailsMessage,
  completionDetailsButtonTitle,
  completionDetailsName,
  onXpCompletionChange,
  xpView,
  onXpViewChange,
  coins,
  onCoinsChange,
  completionsTypeOptions,
  autoCompletion,
  onAutoCompletionChange,
  textLink,
  onToggleTextLinkChange,
  urlTextLink,
  onUrlTextLinkChange,
  experienceImagesData,
  onExperienceImageChange,
  experienceImageLoading,
  experienceImageError,
  buttonLink,
  onChangeButtonLinkClick,
  showError,
  showConfirm,
  firstDriverOption,
  setFirstDriverOption,
  secondDriverOption,
  setSecondDriverOption,
  levelOfProgressionOption,
  setLevelOfProgressionOption,
  personaOption,
  setPersonaOption,
}) => {
  const [picCard, setPicCard] = useState(false);

  const [toggleBox, setToggleBox] = useState({
    box1: true,
    box2: false,
    box3: false,
  });
  const [milstone, setMilstone] = useState(true);
  const [showDifficulty, setShowDifficulty] = useState(true);
  const [starRate, setStarRate] = useState(0);
  const [fireRate, setFireRate] = useState(0);
  // STAR CLASSES
  let firstStarClasses = ["#7b7777", "url(#linear-gradient)"];
  let secondStarClasses = ["#7b7777", "url(#linear-gradient)"];
  let thirdStarClasses = ["#7b7777", "url(#linear-gradient)"];
  // FIRE CLASSES
  let firstFireClasses = ["#7b7777", "url(#linear-gradient)"];
  let secondFireClasses = ["#7b7777", "url(#linear-gradient)"];
  let thirdFireClasses = ["#7b7777", "url(#linear-gradient)"];
  // STAR HANDLES
  const firstStarHandle = () => {
    if (starRate == 1) {
      setStarRate(0);
    } else {
      setStarRate(1);
    }
  };
  const secondStarHandle = () => {
    if (starRate == 2) {
      setStarRate(1);
    } else {
      setStarRate(2);
    }
  };
  const thirdStarHandle = () => {
    if (starRate == 3) {
      setStarRate(2);
    } else {
      setStarRate(3);
    }
  };
  if (starRate == 1) {
    firstStarClasses = ["#7b7777", "#FFB100"];
  } else if (starRate == 2) {
    firstStarClasses = ["#7b7777", "#FFB100"];
    secondStarClasses = ["#7b7777", "#FFB100"];
  } else if (starRate == 3) {
    firstStarClasses = ["#7b7777", "#FFB100"];
    secondStarClasses = ["#7b7777", "#FFB100"];
    thirdStarClasses = ["#7b7777", "#FFB100"];
  }

  // FIRE HANDLES
  const firstFireHandle = () => {
    if (fireRate == 1) {
      setFireRate(0);
    } else {
      setFireRate(1);
    }
  };
  const secondFireHandle = () => {
    if (fireRate == 2) {
      setFireRate(1);
    } else {
      setFireRate(2);
    }
  };
  const thirdFireHandle = () => {
    if (fireRate == 3) {
      setFireRate(2);
    } else {
      setFireRate(3);
    }
  };
  if (fireRate == 1) {
    firstFireClasses = ["#7b7777", "#FF0000"];
  } else if (fireRate == 2) {
    firstFireClasses = ["#7b7777", "#FF0000"];
    secondFireClasses = ["#7b7777", "#FF0000"];
  } else if (fireRate == 3) {
    firstFireClasses = ["#7b7777", "#FF0000"];
    secondFireClasses = ["#7b7777", "#FF0000"];
    thirdFireClasses = ["#7b7777", "#FF0000"];
  }

  const today = new Date();
  const defstartDate = today.setDate(today.getDate());
  const defendDate = today.setDate(today.getDate() + 1);
  const defaultStartDate = new Date(defstartDate).toISOString().split("T")[0];
  const defaultEndDate = new Date(defendDate).toISOString().split("T")[0];
  console.log(defaultEndDate);
  return (
    <ExperienceDetailsContainer>
      <ExperienceDetailsHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-start",
          }}
        >
          <SelectComponent
            options={experienceType}
            style={{ minWidth: 170 }}
            onChange={onChangeExperienceType}
          />
        </div>

        <ExperienceTitleContainer>
          <ExperienceTitle>GRADegy</ExperienceTitle>
          <ExperienceSubTitle>Experience Card</ExperienceSubTitle>
        </ExperienceTitleContainer>
        <div style={{ width: 117 }}>
          <FaCompass color="white" size={60} />
        </div>
      </ExperienceDetailsHeader>
      <ExperienceDatilsInfo
        style={{ display: "flex", alignItems: "flex-start" }}
        className="row m-0 w-100"
      >
        <ExperienceDetailsCol
          className="experience-empty-col"
          style={{ width: "26%", padding: 2 }}
        ></ExperienceDetailsCol>
        <ExperienceDetailsCol
          className="experience-responsive-col"
          style={{ justifyContent: "center", width: "22%", padding: 2 , flexWrap:"nowrap"}}
        >
          <div style={{ paddingRight: 20 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}>
              Start Date
            </p>
            <div>
              <input
                type="date"
                name="startDate"
                id="startDate"
                defaultValue={defaultStartDate}
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                onChange={onStartDateChange}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="startTime"
                id="startTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={startTime}
                onChange={onStartTimeChange}
              />
            </div>
          </div>
          <div>
            <p style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}>
              End Date
            </p>
            <div>
              <input
                type="date"
                name="endDate"
                id="endDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                defaultValue={defaultEndDate}
                onChange={onEndDateChange}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="endTime"
                id="endTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={endTime}
                onChange={onEndTimeChange}
              />
            </div>
          </div>
        </ExperienceDetailsCol>
        <ExperienceDetailsCol
          className="experience-responsive-col"
          style={{ width: "24%", padding: 2 }}
        >
          <>
            <ExperienceDetailsCol Direction="column" style={{ padding: 2 }}>
              <LabelExperience>Category</LabelExperience>

              <SelectComponent
                name="Select Category"
                options={categoryOptions}
                style={{
                  marginRight: 0,
                }}
                selected={true}
                onChange={onCategoryChanged}
              />
            </ExperienceDetailsCol>
            <ExperienceDetailsCol Direction="column" style={{ padding: 2 }}>
              <LabelExperience>Subcategory</LabelExperience>
              <SelectComponent
                name="Select Subcategory"
                options={subCategoryOptions}
                style={{
                  marginRight: 0,
                }}
                selected={true}
                onChange={onSubCategoryChanged}
              />
            </ExperienceDetailsCol>
          </>
        </ExperienceDetailsCol>
        <ExperienceDetailsCol
          className="experience-responsive-col"
          style={{ width: "28%", padding: 2 }}
        >
          <ExperienceDetailsCol
            style={{ padding: 11, fontSize: 20, fontWeight: 700 }}
          >
            <div>Or</div>
          </ExperienceDetailsCol>
          <ExperienceDetailsCol
            Direction="column"
            style={{
              padding: 11,
              marginRight: 11,
              borderRightWidth: 2,
              borderRightColor: "#a09696",
              borderRightStyle: "solid",
            }}
          >
            <LabelExperience>Tags</LabelExperience>
            <SelectComponent
              name="Tags"
              options={""}
              style={{
                marginRight: 0,
              }}
              selected={true}
              onChange={() => {}}
            />
          </ExperienceDetailsCol>
          <ExperienceDetailsCol Direction="column" style={{ padding: 2 }}>
            <LabelExperience>Education Phases</LabelExperience>
            <SelectComponent
              name="education"
              options={EDUCATIONAL_PHASES}
              style={{}}
              selectedValue="HS-9"
              onChange={onEducationPhaseChange}
            />
            <LabelExperience>Semester</LabelExperience>
            <SelectComponent
              name="Semester"
              options={""}
              style={{}}
              selected={true}
              onChange={() => {}}
            />
          </ExperienceDetailsCol>
        </ExperienceDetailsCol>
      </ExperienceDatilsInfo>
      <ExperienceRow
        style={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "flex-start",
        }}
        className="row w-100 m-0"
      >
        <>
          <ExperienceDetailsCol
            style={{
              justifyContent: "flex-end",
              width: "26%",
              padding: 0,
              marginTop: 7,
            }}
          >
            <ExperienceCardDetails cardColor={experienceTypeDetailsColor}>
              <ExperienceCardDetailsInfo>
                <div className="experience-title">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={title}
                    onChange={onTitleChange}
                  />
                </div>
                <div className="experience-title-subtitle">
                  <input
                    type="text"
                    name="subTitle"
                    id="subTitle"
                    value={subTitle}
                    onChange={onSubTitleChange}
                  />
                </div>
                <div className="experience-title-description">
                  <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="10"
                    value={description}
                    onChange={onDescriptionChange}
                  ></textarea>
                </div>
                <div className="experience-title-link">
                  {textLink && (
                    <div className="experience-title-link-content">
                      <img src={TitleLinkImage} alt="gradegy" />
                      <p>{textLinkTitle ? textLinkTitle : "Title for link"}</p>
                    </div>
                  )}
                </div>
                <div>
                  {experienceImageLoading ? (
                    <h5>loading</h5>
                  ) : experienceImageError ? (
                    console.log(experienceImageError)
                  ) : (
                    <img
                      src={imgUrl}
                      alt="simple pic"
                      className="pick-pic"
                      onClick={() => setPicCard(true)}
                    />
                  )}
                </div>
              </ExperienceCardDetailsInfo>
              <ExperienceCardDetailsAction>
                <div className="experience-details-action">
                  <div className="xp">
                    <span>{xpCompletion}</span>
                    <span>XP</span>
                  </div>
                  <div className="icon">
                    <img
                      className="exp-icon"
                      src={experienceTypeDetailsIcon}
                      alt="experience item"
                    />
                  </div>
                </div>
                <div className="competion-message">
                  <div className="completion-action-container">
                    <p>{completionDetailsMessage}</p>
                    <button className="completion-button">
                      {completionDetailsButtonTitle}
                    </button>
                  </div>
                </div>
              </ExperienceCardDetailsAction>
            </ExperienceCardDetails>
          </ExperienceDetailsCol>

          <ExperienceDetailsCol
            style={{
              width: "25%",
            }}
            className={`${toggleBox.box1 ? "" : "boxActive"}`}
          >
            <div className="exp-setting motivational">
              <button
                onClick={() => {
                  setToggleBox({ ...toggleBox, box1: !toggleBox.box1 });
                }}
              >
                <TabName
                  name="Motivational Design"
                  left={-60}
                  condition={toggleBox.box1}
                />
                <BsCaretRightFill color="black" size={20} />
              </button>
              <div className="motivational-title">Motivational Design</div>
              <ShadowBox>
                <div className="xp-comp">
                  <span>XP for Completion</span>
                  <input
                    type="text"
                    placeholder="100-300"
                    id="xpCompletion"
                    name="xpCompletion"
                    value={xpCompletion}
                    onChange={onXpCompletionChange}
                  />
                </div>

                <div className="xp-comp">
                  <span>XP for viewing</span>
                  <input
                    type="text"
                    placeholder="0-5"
                    name="xpView"
                    id="xpView"
                    value={xpView}
                    onChange={onXpViewChange}
                  />
                </div>
                <div className="xp-comp">
                  <span>Coins</span>
                  <input
                    type="text"
                    placeholder="-"
                    name="coins"
                    id="coins"
                    value={coins}
                    onChange={onCoinsChange}
                  />
                </div>
              </ShadowBox>
              <ShadowBox diable>
                <div className="submition">
                  <p>
                    Submission <span>(completion types)</span>{" "}
                  </p>
                  <div>
                    <SelectComponent
                      style={{ fontSize: 15 }}
                      options={completionsTypeOptions}
                      onChange={onCompletionTypeChange}
                    />
                  </div>
                </div>
                {completionDetailsName === "Link" && (
                  <input
                    type="text"
                    placeholder="Add URL"
                    name="experienceUrl"
                    id="experienceUrl"
                    className="dark-input"
                    value={buttonLink}
                    onChange={onChangeButtonLinkClick}
                  />
                )}
                <div className="auto-complition">
                  <span className="auto-title">Auto Complition</span>
                  <CustomSwitch
                    isToggle={autoCompletion}
                    onChangeToggle={onAutoCompletionChange}
                  />
                </div>
              </ShadowBox>
              <ShadowBox>
                <div className="text-link" style={{ paddingRight: 50 }}>
                  <span className="text-link-title">Add Link in text</span>
                  <CustomSwitch
                    isToggle={textLink}
                    onChangeToggle={onToggleTextLinkChange}
                  />
                </div>
                {textLink && (
                  <>
                    <input
                      type="text"
                      placeholder="Title for link"
                      name="titleForLink"
                      id="titleForLink"
                      className="dark-input"
                      value={textLinkTitle}
                      onChange={onTextLinkChange}
                    />

                    <input
                      type="text"
                      placeholder="Add URL"
                      name="addTitleUrl"
                      id="addTitleUrl"
                      className="dark-input"
                      value={urlTextLink}
                      onChange={onUrlTextLinkChange}
                    />
                  </>
                )}
              </ShadowBox>
              <ShadowBox disable>
                <div className="notification-Container">
                  <div className="notification-title-container">
                    <span className="notification-title">Notification</span>
                    <CustomSwitch />
                  </div>
                  <div className="notification-content-container">
                    <div className="start">
                      <span className="start-not">Start</span>
                      <CustomSwitch isToggle={false} />
                    </div>
                    <div className="mid">
                      <span className="mid-not">Mid</span>
                      <CustomSwitch isToggle={false} />
                    </div>
                    <div className="pass-due">
                      <span className="pass-due">Pass Due</span>
                      <CustomSwitch isToggle={false} />
                    </div>
                  </div>
                </div>
              </ShadowBox>
            </div>
          </ExperienceDetailsCol>
          <ExperienceDetailsCol
            style={{
              width: "21%",
            }}
            className={`${toggleBox.box2 ? "" : "boxActive"}`}
          >
            <div className="exp-setting motivational">
              <button
                onClick={() => {
                  setToggleBox({ ...toggleBox, box2: !toggleBox.box2 });
                }}
              >
                <TabName name="Tags" left={0} condition={toggleBox.box2} />
                <BsCaretRightFill color="black" size={20} />
              </button>
              <div className="motivational-title">Tags</div>
              <div className="driver">
                <span>Driver</span>
                <SelectComponent
                  style={{ fontSize: 15, marginTop: 5, width: "100%" }}
                  options={firstDriverOption}
                  onChange={setFirstDriverOption}
                />
                <SelectComponent
                  style={{ fontSize: 15, marginTop: 10, width: "100%" }}
                  options={secondDriverOption}
                  onChange={setSecondDriverOption}
                />
              </div>
              <div className="level">
                <span>Level of Progression</span>
                <SelectComponent
                  style={{ fontSize: 15, marginTop: 5, width: "100%" }}
                  options={levelOfProgressionOption}
                  onChange={setLevelOfProgressionOption}
                />
              </div>
              <div className="persona">
                <span>Persona</span>
                <SelectComponent
                  style={{ fontSize: 15, marginTop: 5, width: "100%" }}
                  options={personaOption}
                  onChange={setPersonaOption}
                />
              </div>
              <div className="milestone mt-5">
                <span className="milestone-title">Milestone</span>
                <CustomSwitch
                  style={{ marginTop: 5, marginLeft: 30 }}
                  //  isToggle={milstone}
                  //  onChangeToggle={() =>
                  //    setMilstone(!milstone)
                  //   }
                />
              </div>

              <div className="weight">
                <span>Weight</span>
                <div className="stars">
                  <StarBtn
                    onClick={() => firstStarHandle()}
                    stroke={firstStarClasses[0]}
                    fill={firstStarClasses[1]}
                  />
                  <StarBtn
                    onClick={() => secondStarHandle()}
                    stroke={secondStarClasses[0]}
                    fill={secondStarClasses[1]}
                  />
                  <StarBtn
                    onClick={() => thirdStarHandle()}
                    stroke={thirdStarClasses[0]}
                    fill={thirdStarClasses[1]}
                  />
                </div>
              </div>
              <div className="difficulty">
                <span className="difficulty-title">Difficulty</span>
                <CustomSwitch
                  style={{ marginTop: 5, marginLeft: 30 }}
                  isToggle={showDifficulty}
                  onChangeToggle={() => setShowDifficulty(!showDifficulty)}
                />
              </div>
              {showDifficulty ? (
                <div className="fires">
                  <span className="fires-title">fire</span>
                  <div className="fire">
                    <FireBtn
                      onClick={() => firstFireHandle()}
                      stroke={firstFireClasses[0]}
                      fill={firstFireClasses[1]}
                    />
                    <FireBtn
                      onClick={() => secondFireHandle()}
                      stroke={secondFireClasses[0]}
                      fill={secondFireClasses[1]}
                    />
                    <FireBtn
                      onClick={() => thirdFireHandle()}
                      stroke={thirdFireClasses[0]}
                      fill={thirdFireClasses[1]}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </ExperienceDetailsCol>
          <ExperienceDetailsCol
            style={{
              width: "27%",
            }}
            className={`${toggleBox.box3 ? "" : "boxActive"}`}
          >
            <div className="exp-setting advance-tailoroad">
              <button
                onClick={() => {
                  setToggleBox({ ...toggleBox, box3: !toggleBox.box3 });
                }}
              >
                <TabName
                  name="Lop Tags"
                  left={-20}
                  condition={toggleBox.box3}
                />
                <BsCaretRightFill color="black" size={20} />
              </button>
            </div>
          </ExperienceDetailsCol>
        </>
      </ExperienceRow>
      <div className="control-button">
        <button onClick={onSubmitAsPublishClick}>Publish</button>
        <button onClick={onSubmitAsSaveClick}>Save</button>
      </div>
      {picCard ? (
        <PickExperiencePicture
          data={experienceImagesData}
          close={() => setPicCard(false)}
          typeExper={experienceTypeDetailsName}
          changeImage={onExperienceImageChange}
        />
      ) : null}
      {showConfirm ? (
        <Confirm message="EXPERIENCE CARD IS PUBLISHED SUCCESSFULY">
          <AnimationCheck />
        </Confirm>
      ) : null}
      {showError ? (
        <Confirm message="The Organization and Project Must Be Selected Or Check Time">
          <AnimationError />
        </Confirm>
      ) : null}
    </ExperienceDetailsContainer>
  );
};

export default withRouter(ExperienceCard);
