import React from "react";
import { MdEdit } from "react-icons/md";
import { EditButtonContainer, EditButtonText, EditButtonIcon} from "./index.style";
const EditButton = ({  style, onClick }) => {
  return (
    <EditButtonContainer style={style} onClick={onClick}>
      
      <EditButtonText>Edit</EditButtonText>
      <EditButtonIcon>
        <MdEdit color="#34C38F" size={17} />
      </EditButtonIcon>
    </EditButtonContainer>
  );
};

export default EditButton;
