import React, { useState, useCallback } from "react";
import RightColumn from "components/shop/shopSystem/rightColumn";
import { useDispatch, useSelector } from "react-redux";
import { togglePickupShopItemAction } from "actions";
import { shopItemStudentsSelector } from "store/selectors";
const RightColumnContainer = ({shopItem,raffelTicket,setPickUpNumState}) => {
  const dispatch = useDispatch();
  //states
  const [searchStudentValue, setSearchStudentValue] = useState("");
  const [selectedFilterId, setSelectedFilterId] = useState("All");
  //selectors
  const studentListSelectorCallback = useCallback(
    shopItemStudentsSelector(searchStudentValue, selectedFilterId),
    [searchStudentValue, selectedFilterId]
  );
  const studentsList = useSelector(studentListSelectorCallback);
  //actions
  const searchInputChangedHandler = useCallback((e) => {
    setSearchStudentValue(e.target.value);
  }, []);
  const filterStatusChangedHandler = useCallback((e) => {
    setSelectedFilterId(e.target.value);
  }, []);
  const handlePickUpStudentChange = (id, pickup) => {
    
      dispatch(togglePickupShopItemAction(id, pickup,setPickUpNumState));
      
      
    }
   
  return (
    <RightColumn
      onChangeToggle={handlePickUpStudentChange}
      studentsList={studentsList}
      searchStudentValue={searchStudentValue}
      onSearchInputChanged={searchInputChangedHandler}
      selectedStatusId={selectedFilterId}
      onStatusChanged={filterStatusChangedHandler}
      shopItem={shopItem}
      raffelTicket={raffelTicket}

    />
  );
};

export default RightColumnContainer;
