export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const AUTH_GET_USER_REQUEST = "AUTH_GET_USER_REQUEST";
export const AUTH_GET_USER_SUCCESS = "AUTH_GET_USER_SUCCESS";
export const AUTH_GET_USER_FAIL = "AUTH_GET_USER_FAIL";

export const LOGOUT_USER="LOGOUT_USER";

export const ADD_XP_USER = "ADD_XP_USER";



