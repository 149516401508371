import React,  { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer
} from "@material-ui/core";
import "./awardedTable.style.css";
import AwardedItem from "./AwardedItem";
import {
  StudentButton,
} from "../../../components/styled.components";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addXpToUserAction, getLeaderBoard
} from "../../../actions/userListAction";
import { updateXpDetails } from "actions/userDetailsAction";
import { getAllXpsBounes } from "actions/xpsAction";


  const AwardedTable = ({organization,project,totalAmount,setTotal,user,setRunUser}) => {
    const [selectedUsers,setSelectedUsers] = useState([]);
    const [xpInfo,setXpInfo] = useState({
      description:'',
      amount:'',
      addByAdmin:true,
      
  })
  const { xps } = useSelector((state) => state.xpsBounes);
  const today = new Date();
  
  const dispatch = useDispatch();

  const changeHandler = (e) =>{
    setXpInfo({...xpInfo,[e.target.name]:e.target.value});
  } 
  const changeXpHandler = (e) => {
    setXpInfo({...xpInfo,amount:parseInt(e.target.value)})
  }

  const [xpPanel,setXpPanel] = useState(false);
  
  return (
    <div style={{position:"relative", width:"49%"}}>
      <div className="awarded-table">
        <div style={{display:"flex",alignItems:"center", marginBottom:14}}>
          <h3 className="awarded-title">Manually Awarded Points </h3>
          <StudentButton
                    style={{ borderRadius: 50, marginRight: 10, marginLeft: 10 }}
                    onClick={() => {
                      setXpPanel(!xpPanel)
                    }}
                  >
                    <FaPlus style={{ marginRight: 5 }} size={27} />{" "}
                    <span>Award Points</span>{" "}
          </StudentButton>
        </div>
      <TableContainer style={{ maxHeight: 320 }}>
        <Table className="awarder-container" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Added</TableCell>
              <TableCell>XP</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                
            {xps?.map(item =>  <AwardedItem item={item}/>)}
             
             
          </TableBody>
        </Table>
      </TableContainer>
      </div>
            {xpPanel ? (
              <>
                <div
                  className="xpContainer"
                  onClick={() => setXpPanel(false)}
                  
                ></div>
                <div className="xpPanle" style={{top:100}}>
                    <div className="xpPanel_header">Add XP</div>
                    <div className="xp_description">
                        <input type="text"  name="description" placeholder="Description" className="xpDescription" value={xpInfo.description} onChange={changeHandler} />
                    </div>
                    <div className="xp_description">
                        <input type="text"  name="amount" placeholder="Xp Amount" className="xpDescription" value={xpInfo.amount}  onChange={changeXpHandler} />
                        <button className="addxpbutton" onClick={() => {
                        
                            const result = {...xpInfo,user:user._id,organization:organization,project:project};
                            dispatch(addXpToUserAction(result));
                            setXpPanel(false);
                            dispatch(updateXpDetails(result.amount));
                            dispatch(getLeaderBoard(project));
                            dispatch(getAllXpsBounes(user._id,organization,project));
                            setTotal(totalAmount + result.amount)
                            setRunUser(true);
                         
                        }} >+</button>
                    </div>
                    <div className="xpDate">
                      
                        <p className="date_text">Date</p>
                        <p className="date_content">{today.getMonth() + 1}/{today.getDate()}/{today.getFullYear()}</p>
                    </div>
                </div>
              </>
            ) : null}
   </div>

  );
};

export default AwardedTable;
