import { FaUserAlt, FaCompass, FaBuilding, FaHome } from "react-icons/fa";
import { IoBriefcaseSharp } from "react-icons/io5";
import { FaProjectDiagram } from "react-icons/fa";
import { AiTwotoneSetting } from "react-icons/ai";
import Student from "./views/Student";
import Home from "./views/Home";
import StudentDetails from "./views/StudentDetails";
import Experience from "views/Experience";
import ExperienceCard from "./views/ExperienceCard";
import ExperienceEdit from "./views/ExperienceEdit";
import ExperienceCardReview from "./views/ExperienceCardReview";
import Organization from "./views/Organization";
import OrganizattionDetails from "./views/OrganizationDetails";
import ProjectDetail from "./views/ProjectDetail";
import AdminList from "views/Admin/AdminList";
import AdminDetail from "views/Admin/AdminDetail";
import ShopList from "views/Shop";
import ShopSystem from "views/Shop/ShopSystem";
import RaffleSystem from "views/Raffle/RaffleSystem";
import RaffleList from "views/Raffle";
import Setting from "./views/Setting";
import { canViewShopRaffle, haveAccessMenuItem } from "helpers";

export const getRoutes = (user) => [
  {
    name: "Students",
    path: "/students",
    icon: FaUserAlt,
    component: Student,
    layout: "/admin",
    show: true,
    partOf: "Student",
  },
  {
    name: "Home",
    path: "/home",
    icon: FaHome,
    component: Home,
    layout: "/admin",
    show: true,
    partOf: "Home",
  },

  {
    name: "StudentDetails",
    path: "/students/details/:id",
    icon: null,
    component: StudentDetails,
    layout: "/admin",
    show: false,
    partOf: "Student",
  },
  {
    name: "Experiences",
    path: "/experiences",
    icon: FaCompass,
    component: Experience,
    layout: "/admin",
    show: true,
    partOf: "Student",
  },
  {
    name: "ExperienceCard",
    path: "/experiences/details",
    icon: null,
    component: ExperienceCard,
    layout: "/admin",
    show: false,
    partOf: "Student",
  },
  {
    name: "ExperienceCardEdit",
    path: "/experiences/edit/:id",
    icon: null,
    component: ExperienceEdit,
    layout: "/admin",
    show: false,
    partOf: "Student",
  },
  {
    name: "ExperienceCardReview",
    path: "/experiences/review/:id",
    icon: null,
    component: ExperienceCardReview,
    layout: "/admin",
    show: false,
    partOf: "Student",
  },
  {
    name: "Shop",
    path: "/shop",
    icon: FaUserAlt,
    component: ShopList,
    layout: "/admin",
    show: user && canViewShopRaffle(user),
    partOf: "Student",
  },
  {
    name: "Raffle",
    path: "/raffle",
    icon: FaUserAlt,
    component: RaffleList,
    layout: "/admin",
    show: user && canViewShopRaffle(user),
    partOf: "Student",
  },
  {
    name: "ShopItemDetail",
    path: "/shopItem/:id",
    icon: FaUserAlt,
    component: ShopSystem,
    layout: "/admin",
    show: false,
    partOf: "Student",
  },
  {
    name: "RaffleItemDetail",
    path: "/raffleItem/:id",
    icon: FaUserAlt,
    component: RaffleSystem,
    layout: "/admin",
    show: false,
    partOf: "Student",
  },
  {
    name: "Setting",
    path: "/setting",
    icon: AiTwotoneSetting,
    component: Setting,
    layout: "/admin",
    show: true,
    partOf: "Student"

  },
  {
    name: "Organization",
    path: "/organization",
    icon: FaBuilding,
    component: Organization,
    layout: "/admin",
    show: user && haveAccessMenuItem(user.role, "ultraAdmin"),
    partOf: "Admin",
  },

  {
    name: "OrganizationDetails",
    path: "/organization/detail/:id",
    icon: null,
    component: OrganizattionDetails,
    layout: "/admin",
    show: false,
    partOf: "Admin",
  },
  {
    name: "ProjectDetails",
    path: "/organization/project/:id",
    icon: null,
    component: ProjectDetail,
    layout: "/admin",
    show: false,
    partOf: "Admin",
  },
  {
    name: "Projects",
    path: "/organization/detail/:id",
    icon: FaProjectDiagram,
    component: ProjectDetail,
    layout: "/admin",
    show: user && haveAccessMenuItem(user?.role, "superAdmin"),
    partOf: "Admin",
    params: true,
  },
  {
    name: "Admins",
    path: "/adminList",
    icon: IoBriefcaseSharp,
    component: AdminList,
    layout: "/admin",
    show: user && haveAccessMenuItem(user.role, "superAdmin"),
    partOf: "Admin",
  },
  {
    name: "Admins",
    path: "/adminList",
    icon: IoBriefcaseSharp,
    component: AdminList,
    layout: "/admin",
    show: user && haveAccessMenuItem(user.role, "ultraAdmin"),
    partOf: "Admin",
  },
  {
    name: "AdminDetail",
    path: "/adminDetail/:id",
    icon: null,
    component: AdminDetail,
    layout: "/admin",
    show: false,
    partOf: "Admin",
  },

];
