import React from "react";
import SelectComponent from "components/control.component/select.component.jsx";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  StudentInput,
  LabelStudent,
  SwitchLabelStudent,
} from "components/styled.components/create.student";
import { GENDER } from "constants/index";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import { FIRST_GEN, WORKING } from "constant/userDetails";

const PersonalInfoComponent = ({
  changeText,
  userInfo,
  setUserInfo,
  textMe,
  setTextMe,
  emailMe,
  setEmailMe,
  callMe,
  setCallMe,
}) => {
  return (
    <>
      <CreateStudentFormRow
        style={{
          marginTop: 30,
          padding: 10,
          justifyContent: "start",
        }}
      >
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="firstGen">First Gen?</LabelStudent>
          <SelectComponent
            options={FIRST_GEN}
            name="firstGen"
            onChange={changeText}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <CreateStudentFormCol
            Direction="column"
            style={{ paddingBottom: 10 }}
          >
            <LabelStudent>Preferred Contact</LabelStudent>
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="row">
            <CreateStudentFormCol Direction="row">
              <SwitchLabelStudent>Text</SwitchLabelStudent>
              <CustomSwitch
                isToggle={textMe}
                onChangeToggle={() => {
                  setTextMe(!textMe);
                  setUserInfo({
                    ...userInfo,
                    contactManner: !textMe
                      ? [...userInfo.contactManner, "Text"]
                      : userInfo.contactManner.filter(
                          (item) => item !== "Text"
                        ),
                  });
                }}
              />
            </CreateStudentFormCol>
            <CreateStudentFormCol Direction="row">
              <SwitchLabelStudent>call</SwitchLabelStudent>
              <CustomSwitch
                isToggle={callMe}
                onChangeToggle={() => {
                  setCallMe(!callMe);
                  setUserInfo({
                    ...userInfo,
                    contactManner: !callMe
                      ? [...userInfo.contactManner, "Call"]
                      : userInfo.contactManner.filter(
                          (item) => item !== "Call"
                        ),
                  });
                }}
              />
            </CreateStudentFormCol>
            <CreateStudentFormCol Direction="row">
              <SwitchLabelStudent>Email</SwitchLabelStudent>
              <CustomSwitch
                isToggle={emailMe}
                onChangeToggle={() => {
                  setEmailMe(!emailMe);
                  setUserInfo({
                    ...userInfo,
                    contactManner: !emailMe
                      ? [...userInfo.contactManner, "Email"]
                      : userInfo.contactManner.filter(
                          (item) => item !== "Email"
                        ),
                  });
                }}
              />
            </CreateStudentFormCol>
          </CreateStudentFormCol>
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column" style={{ padding: 0 }}>
          <LabelStudent>Working?</LabelStudent>
          <SelectComponent
            options={WORKING}
            name="working"
            onChange={changeText}
          />
        </CreateStudentFormCol>
      </CreateStudentFormRow>
      <CreateStudentFormRow
        style={{
          marginTop: 30,
          padding: 10,
          justifyContent: "start",
        }}
      >
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="age">Age</LabelStudent>
          <StudentInput
            type="number"
            id="age"
            name="age"
            value={userInfo.age}
            onChange={changeText}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Gender</LabelStudent>
          <SelectComponent
            options={GENDER}
            name="gender"
            value={userInfo.gender}
            onChange={changeText}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="workingWhere">Working Where</LabelStudent>
          <StudentInput
            type="text"
            id="workingWhere"
            name="workingWhere"
            value={userInfo.workingWhere}
            onChange={changeText}
          />
        </CreateStudentFormCol>
      </CreateStudentFormRow>
    </>
  );
};

export default PersonalInfoComponent;
