import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div`
  width: 144px;
  height: 144px;
  ::after {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 2px solid #d3d3d3;
    background: transparent;
    width: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    height: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    border-radius: 50%;
    align-self: center;
  }
`;
