import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { experienceSortTypeSelector } from "store/selectors";
import { setExperienceSortType } from "actions/experienceAction";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ExperienceItem from "components/experience/ExperienceItem";
import Unselected from "assets/image/unselected.png";
import BottomSelected from "assets/image/bottomSelected.png";
import TopSelected from "assets/image/topSelected.png";
import Spinner from "components/spinner/spinner";
import TypeDropdown from "./dropodowns/typeDropdown/TypeDropdown";
import CompletionDropdown from "./dropodowns/completionDropdown/CompletionDropdown";
import { BiDotsVerticalRounded } from "react-icons/bi";
const Experience = ({ experienceListState }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.experienceList);
  const sortType = useSelector(experienceSortTypeSelector);
  const [typeDropdown, setTypeDropdown] = useState(false);
  const [completionDrop, setCompletionDrop] = useState(false);
  const handleSortExperience = useCallback(
    (name) => {
      console.log("sortName", name);
      console.log("sortType", sortType);
      setExperienceSortType(dispatch, {
        type: name,
        isReversed: sortType?.type === name ? !sortType.isReversed : false,
      });
    },
    [dispatch, sortType.isReversed, sortType?.type]
  );
  const typeDropdownHandler = () => {
    const showDropdown = typeDropdown;
    setCompletionDrop(false);
    setTypeDropdown(!showDropdown);
  };
  const completionDropHandler = () => {
    const showDropdown = completionDrop;
    setTypeDropdown(false);
    setCompletionDrop(!showDropdown);
  };
  return (
    <div
      className="experience_card_list_container"
      style={{ width: "100%", marginTop: -40 }}
    >
      <Table style={{ overflow: "visible", position: "relative" }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleSortExperience("titleSort");
              }}
            >
              Title
              {sortType?.type === "titleSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                handleSortExperience("desSort");
              }}
            >
              Description
              {sortType?.type === "desSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleSortExperience("startDate");
              }}
            >
              Duration
              {sortType?.type === "startDate" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell>
              Location
              {sortType?.type === "Location" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                handleSortExperience("xpSort");
              }}
            >
              Xp
              {sortType?.type === "xpSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              style={{
                cursor: "pointer",
                position: "relative",
                overflow: "visible",
              }}
              onClick={() => {
                handleSortExperience("experienceType.name");
              }}
            >
              Type
              {sortType?.type === "experienceType.name" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              style={{
                cursor: "pointer",
                position: "relative",
                overflow: "visible",
              }}
              onClick={() => {
                handleSortExperience("completionType.name");
              }}
            >
              Completion
              {sortType?.type === "completionType.name" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <Spinner style={{ width: 50, height: 50, top: 20 }} />
          ) : (
            experienceListState.map((exp) => (
              <ExperienceItem key={exp._id} experineceCard={exp} />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default Experience;
