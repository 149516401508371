import React from 'react';
import {
    WarningPopupContainer,
    WarningPopupForm,
    WarningPopupFormRow,
    WarningPopupButton,
    WarnigPoupText
  
  } from "./warningPopup.style";
const WarningPopup = ({text,accept,reject,close}) => {

    const closeForm = (e) => {
        if (e.target.classList.contains("warning-popup")) {
            close();
          }
    }  
    const rejectCloseForm = (e) => {
          reject();
      };
      
    const saveCloseForm = (e) => {
        if (e.target.classList.contains("form-warning-save")) {
            accept();
            close();
          }
    }  

    return (
        <WarningPopupContainer  className="warning-popup" onClick={closeForm}>
            <WarningPopupForm>
                <WarningPopupFormRow>
                    <WarnigPoupText>{text}</WarnigPoupText>
                </WarningPopupFormRow>
                <WarningPopupFormRow>
                    <WarningPopupButton style={{backgroundColor:"#CE0014"}} spaceLeft={0} className="form-warning-save" onClick={saveCloseForm}>YES</WarningPopupButton>
                    <WarningPopupButton style={{backgroundColor:"#00C4B8 "}} spaceLeft={'50px'} className="form-warning-popup" onClick={rejectCloseForm}>NO</WarningPopupButton>
                </WarningPopupFormRow>
            </WarningPopupForm>
        </WarningPopupContainer>
    )
}

export default WarningPopup
