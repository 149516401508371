import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  shopPageStatusSelector,
  selectedAppProjectIdSelector,
  selectedAppOrganizationIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
  shopToastMessageSelector,
  shopTotal,
  shopPagination,
  shopListSelector,
  allAppCategoriesSelector,
} from "store/selectors";
import {
  addShopItemAction,
  getALLShopItemListAction,
  setShopSearchInput,
} from "actions";
import { canEditAccessCheckShopRaffle } from "helpers";
import {
  ShopContainer,
  ShopContentContainer,
} from "components/styled.components/shop.style";
import Confirm from "components/display.component/confirm.components";
import AnimationCheck from "components/animation.icon/animation.icon.components";
import AnimationError from "components/animation.icon/animation.error";
import ShopHeaderContainer from "containers/Shop/ShopHeader";
import ShopListContainer from "containers/Shop/ShopList";
import ShopAddItemPopup from "containers/Shop/AddShopItem";
import { useEffect } from "react";

const ShopPage = ({ history }) => {
  const dispatch = useDispatch();
  //states
  const [searchShopInput, setSearchShopInput] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [sortType, setSortType] = useState({
    type: "prize",
    isReversed: false,
  });
  //selectors
  const user = useSelector((state) => state.loggedInUser?.user);
  const shopPageStatus = useSelector(shopPageStatusSelector);
  const shopTotalNumber = useSelector(shopTotal);
  const shopPaginationState = useSelector(shopPagination);
  const toastMessage = useSelector(shopToastMessageSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  const categories = useSelector(allAppCategoriesSelector);
  const shopList = useSelector(shopListSelector);
  const searchInputChangedHandler = useCallback((e) => {
    setSearchShopInput(e.target.value);
    setPageCount(1);
    dispatch(getALLShopItemListAction({}, 1, e.target.value));
  }, []);

  const handlePageClick = (event) => {
    dispatch(getALLShopItemListAction({}, event.selected + 1));
    setPageCount(event.selected + 1);
  };

  const editButtonClickedHandler = useCallback(
    (id) => {
      history.push(`/admin/shopItem/${id}`);
    },
    [history]
  );
  const handleSaveShopItemClicked = useCallback(
    (shopItemInfo) => {
      const shopInfo = {
        ...shopItemInfo,
        organization: selectedOrganizationId,
        project: selectedProjectId,
        // category: selectedCategoryId,
        // subCategory: selectedSubCategoryId,
      };
      dispatch(addShopItemAction(shopInfo));
    },
    [
      dispatch,
      selectedCategoryId,
      selectedOrganizationId,
      // selectedProjectId,
      // selectedSubCategoryId,
    ]
  );
  const handleSortShop = useCallback(
    (field) => {
      if (field === sortType?.type) {
        dispatch(
          getALLShopItemListAction(
            {
              type: field,
              isReversed: !sortType.isReversed,
            },
            1
          )
        );
        setSortType({ type: field, isReversed: !sortType.isReversed });
      } else {
        dispatch(
          getALLShopItemListAction({ type: field, isReversed: false }, 1)
        );
        setSortType({ type: field, isReversed: false });
      }
      setPageCount(1);
    },

    [dispatch, sortType.isReversed, sortType?.type]
  );

  return (
    <>
      <ShopHeaderContainer
        searchShopInput={searchShopInput}
        setSearchShopInput={setSearchShopInput}
        haveAccess={canEditAccessCheckShopRaffle(user)}
        onSearchInputChanged={searchInputChangedHandler}
      />
      <ShopContainer>
        <ShopContentContainer style={{ width: "100%", height: "auto" }}>
          <ShopListContainer
            shopList={shopList.shopItemList}
            haveAccess={canEditAccessCheckShopRaffle(user)}
            onEditButtonClick={editButtonClickedHandler}
            onSortShopList={handleSortShop}
            sortType={sortType}
          />
        </ShopContentContainer>
      </ShopContainer>
      {shopTotalNumber !== 0 && shopTotalNumber > 25 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={Math.ceil(shopTotalNumber / 25)}
          forcePage={pageCount - 1}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}
      {shopPageStatus === "ADD_SHOP_ITEM_POPUP" && (
        <ShopAddItemPopup
          onSaveShopItemClicked={handleSaveShopItemClicked}
          categories={categories}
        />
      )}
      {shopPageStatus === "SHOP_MESSAGE_POPUP" && !toastMessage?.hasError && (
        <Confirm message={toastMessage?.message}>
          <AnimationCheck />
        </Confirm>
      )}
      {shopPageStatus === "SHOP_MESSAGE_POPUP" && toastMessage?.hasError && (
        <Confirm message={toastMessage?.message}>
          <AnimationError />
        </Confirm>
      )}
    </>
  );
};

export default ShopPage;
