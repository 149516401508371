import React, { useState, useCallback } from "react";
import SpecialProjects from "components/admin/adminDetail/Access/EditSections/SpecialProjects";
import { useDispatch, useSelector } from "react-redux";
import { gradify } from "axios.config";
import {
  selectedAdminDetailSelector,
  adminDetailSpecialProjectOptionsSelector,
  adminDetailSpecialProjectListSelector,
  adminDetailSelectedSpecialProjectId,
} from "store/selectors";
import {
  setSelectedSpecialProjectId,
  removeSelectedSpecialProject,
  addNewSpecialProjectAction,
} from "actions";
const SpecialProjectsContainer = () => {
  const dispatch = useDispatch();
  //selectors
  const token = useSelector((state) => state.auth.token);
  const adminDetail = useSelector(selectedAdminDetailSelector);
  const specialProjectsOption = useSelector(
    adminDetailSpecialProjectOptionsSelector
  );
  const specialProjectList = useSelector(adminDetailSpecialProjectListSelector);
  const selectedSpecialProjectId = useSelector(
    adminDetailSelectedSpecialProjectId
  );

  //states
  const [selectedSpecialProjectOption, setSelectedSpecialProjectOption] =
    useState();
  const [categories, setCateories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  // actions
  const specialProjectOptionChangeHandler = useCallback(
    async (event) => {
      const projectId = event.target.value;
      const selectedProject = specialProjectsOption.find(
        (pro) => pro._id === projectId
      );
      if (projectId === "Select project") {
        setSelectedSpecialProjectOption();
        setCateories();
        setSubCategories();
      }
      if (projectId && projectId !== "Select project") {
        setSelectedSpecialProjectOption({
          _id: selectedProject?._id,
          title: selectedProject?.title || selectedProject?.name,
          projectName: selectedProject?.title || selectedProject?.name,
          projectId,
        });
        const { data } = await gradify.get(`categories/project/${projectId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCateories(data.data);
      }
    },
    [specialProjectsOption, token]
  );
  const categoryOptionChangeHandler = useCallback(
    async (event) => {
      const categoryId = event.target.value;
      const selectedCategory =
        categories && categories.find((cat) => cat._id === categoryId);
      if (categoryId === "All") {
        setSelectedSpecialProjectOption({
          _id: selectedSpecialProjectOption?.projectId,
          title: selectedSpecialProjectOption?.projectName,
          projectName: selectedSpecialProjectOption?.projectName,
          projectId: selectedSpecialProjectOption?.projectId,
        });
        setSubCategories();
      }
      if (categoryId && categoryId !== "All") {
        setSelectedSpecialProjectOption({
          _id: selectedCategory?._id,
          title: selectedCategory?.title || selectedCategory?.name,
          projectName: selectedSpecialProjectOption?.projectName,
          projectId: selectedSpecialProjectOption?.projectId,
          categoryName: selectedCategory?.title || selectedCategory?.name,
          categoryId,
        });
        const { data } = await gradify.get(
          `subcategories/category/${categoryId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSubCategories(data.data);
      }
    },
    [categories, selectedSpecialProjectOption, token]
  );
  const subCategoryOptionChangeHandler = useCallback(
    (event) => {
      const subCategoryId = event.target.value;
      const selectedSubCategory =
        subCategories &&
        subCategories.find((subCat) => subCat._id === subCategoryId);
      if (subCategoryId === "Select subCategory") {
        setSelectedSpecialProjectOption({
          _id: selectedSpecialProjectOption?.categoryId,
          title: selectedSpecialProjectOption?.categoryName,
          projectName: selectedSpecialProjectOption?.projectName,
          projectId: selectedSpecialProjectOption?.projectId,
          categoryName: selectedSpecialProjectOption?.categoryName,
          categoryId: selectedSpecialProjectOption?.categoryId,
        });
      }
      if (subCategoryId && subCategoryId !== "Select subCategory") {
        setSelectedSpecialProjectOption({
          _id: selectedSubCategory?._id,
          title: selectedSubCategory?.title || selectedSubCategory?.name,
          subCategoryName:
            selectedSubCategory?.title || selectedSubCategory?.name,
          subCategoryId,
          projectName: selectedSpecialProjectOption?.projectName,
          projectId: selectedSpecialProjectOption?.projectId,
          categoryName: selectedSpecialProjectOption?.categoryName,
          categoryId: selectedSpecialProjectOption?.categoryId,
        });
      }
    },
    [selectedSpecialProjectOption, subCategories]
  );
  const onSpecialProjectClickedHandler = useCallback(
    (selectedSpecialProjectId) => {
      setSelectedSpecialProjectId(dispatch, selectedSpecialProjectId);
    },
    [dispatch]
  );
  const deleteSpecialProjectItemHandler = useCallback(
    (specialProjectId) => {
      removeSelectedSpecialProject(dispatch, specialProjectId);
    },
    [dispatch]
  );
  const addSpecialProjectClickHandler = useCallback(() => {
    if (selectedSpecialProjectOption?._id) {
      addNewSpecialProjectAction(dispatch, {
        ...selectedSpecialProjectOption,
        access: {
          experienceCards: false,
          store: false,
          surveys: false,
          users: false,
        },
      });
    }
  }, [dispatch, selectedSpecialProjectOption]);
  return (
    <SpecialProjects
      selectedSpecialProjectOption={selectedSpecialProjectOption}
      specialProjectsOption={specialProjectsOption}
      specialProjectList={specialProjectList}
      selectedSpecialProjectId={selectedSpecialProjectId}
      selectedSpecialProject={specialProjectList[selectedSpecialProjectId]}
      onSpecialProjectClick={onSpecialProjectClickedHandler}
      organizationName={adminDetail.organizationName}
      onSpecialProjectOptionChange={specialProjectOptionChangeHandler}
      onDeleteSpecialProjectItem={deleteSpecialProjectItemHandler}
      onAddSpecialProjectClick={addSpecialProjectClickHandler}
      categoriesOptions={categories}
      onCategoryOptionChange={categoryOptionChangeHandler}
      subCategoriesOptions={subCategories}
      onSubCategoryOptionChange={subCategoryOptionChangeHandler}
    />
  );
};

export default SpecialProjectsContainer;
