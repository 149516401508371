import { gradify } from "axios.config";
import {
  Exp_LIST_PENDING,
  Exp_LIST_SUCCESS,
  Exp_LIST_ERROR,
} from "../../constant/experienceList";

import {
  NEW_EXPERIENCE_ITEM_PENDING,
  NEW_EXPERIENCE_ITEM_SUCCESS,
  NEW_EXPERIENCE_ITEM_FAIL,
} from "../../constant/experience";

export const getExperienceListAction =
  (project, category, user, categories) => async (dispatch, getState) => {
    console.log(category);
    dispatch({
      type: Exp_LIST_PENDING,
    });

    try {
      let query = "";
      if (!category) {
        query = `experiencecards/experiencelist?project=${project?._id}&user=${user}`;
      } else if (category && categories?.length < 1) {
        query = `experiencecards/experiencelist?project=${project?._id}&category=${category?._id}&user=${user}`;
      } else if (category && categories?.length > 0) {
        const categoriesIds = categories.map((i) => i._id);
        query = `experiencecards/experiencelist?project=${project?._id}&category=${categoriesIds}&user=${user}`;
      }
      const list = await gradify.get(query);

      console.log("expListAction12", list.data.data);
      dispatch({
        type: Exp_LIST_SUCCESS,
        payload: list.data.data,
      });
    } catch (error) {
      dispatch({
        type: Exp_LIST_ERROR,
        payload: error,
      });
    }
  };

export const newGetExperienceItemAction =
  (id) => async (dispatch, getState) => {
    dispatch({
      type: NEW_EXPERIENCE_ITEM_PENDING,
    });

    try {
      const { data } = await gradify.get(
        `experiencecards/getExperienceById?experienceId=${id}`,
        {
          headers: { Authorization: "Bearer " + getState().auth.token },
        }
      );

      dispatch({
        type: NEW_EXPERIENCE_ITEM_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: NEW_EXPERIENCE_ITEM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
