import React, { useState } from "react";
import {
    SettingMainContainer,
    SettingHeaderControle,
    TabButton,
  } from "components/styled.components";
import SettingTabs from "components/setting/tabs";

const SettingMain = ({FirstTab , SecondTab }) =>{
 const [page , setPage] = useState(1);
 let driversCondition = false;
 let thirdCondition = false;
 const firstPageHandler = () =>{
      return setPage(1);  
  }
 const pageSecondHandler = () =>{
      return setPage(2);
  }
 const thirdPageHandler = ()=>{
      return setPage(3);
  }
  if(page == 1){
   driversCondition = true;
  }else if(page == 2){
   thirdCondition = true;
  }
  return (
    <>
      <SettingMainContainer>
         <SettingHeaderControle>
             <TabButton onClick={()=>firstPageHandler()} condition={driversCondition}>{FirstTab}</TabButton>
             <TabButton onClick={()=>pageSecondHandler()} condition={thirdCondition}>{SecondTab}</TabButton>
         </SettingHeaderControle>
         <SettingTabs condition={page}/>
      </SettingMainContainer>
    </>
  );
};

export default SettingMain;
