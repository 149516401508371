import React, { useCallback, useEffect } from "react";
import {
  allAppCategoriesSelector,
  allAppSubCategoriesSelector,
  selectedAppProjectIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  getALLRaffleItemListAction,
  getAppCategoriesAction,
  getAppSubCategoriesAction,
  setAppSelectedSubCategoryId,
  setRafflePageStatus,
} from "actions";
import RaffleHeader from "components/raffle/RaffleHeader";

const RaffleHeaderContainer = ({
  searchRaffleInput,
  onSearchInputChanged,
  haveAccess,
}) => {
  const dispatch = useDispatch();
  //selectors
  const categories = useSelector(allAppCategoriesSelector);
  const subCategories = useSelector(allAppSubCategoriesSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);

  useEffect(() => {
    dispatch(getAppCategoriesAction(selectedProjectId));
  }, [dispatch, selectedProjectId]);
  //actions
  const handleAddRaffleItemClick = useCallback(() => {
    setRafflePageStatus(dispatch, "ADD_RAFFLE_ITEM_POPUP");
  }, [dispatch]);
  return (
    <RaffleHeader
      categoryOptions={categories}
      selectedCategoryId={selectedCategoryId}
      onCategoryChanged={(e) => {
        dispatch(getAppSubCategoriesAction(e.target.value));
        dispatch(
          getALLRaffleItemListAction("raffle_title", 1, null, e.target.value)
        );
      }}
      subCategoryOptions={subCategories}
      selectedSubCategoryId={selectedSubCategoryId}
      onSubCategoryChanged={(e) => {
        setAppSelectedSubCategoryId(dispatch, e.target.value);
      }}
      haveAccess={haveAccess}
      searchRaffleInput={searchRaffleInput}
      onSearchInputChanged={onSearchInputChanged}
      onAddRaffleItemClick={handleAddRaffleItemClick}
    />
  );
};

export default RaffleHeaderContainer;
