import CountUp from 'react-countup';
const ProgressBar = (props) => {
    const { bgcolor, completed , duration } = props;


    const containerStyles = {
      height: 27,
      width: "50%",
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      margin: "50 auto",
      maxWidth:350,
      overflow: "hidden",
      boxShadow:"inset 0px -1px 9px 1px #bcbcbc"
    };
  
    const fillerStyles = {
      height: "100%",
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: "inherit",
      textAlign: "right",
      transition: `width ${duration}s ease-in-out`,

    };
  
    const labelStyles = {
      padding: 5,
      color: "white",
      fontWeight: "bold",
    };
    const progressContainer = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10%",
    }
  
    return (
        <div style={progressContainer}>
            <h2>Loading...</h2>
            <div style={containerStyles}>       
                <div style={fillerStyles}>
                {completed == 80 ? 
                    <span style={labelStyles}>%<CountUp onEnd={() => console.log('Ended! 👏')} end={completed} duration={duration} /></span> : 
                    <span style={labelStyles}>%<CountUp   start={80} end={100}    duration={2} /></span>
                }
                
                </div>
            </div>
        </div>

    );
  };
  
  export default ProgressBar;