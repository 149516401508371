import React, { useEffect, useContext }  from 'react';
import {useState} from "react";
import {BsFillCaretRightFill} from "react-icons/bs";
import {BsFillCaretLeftFill} from "react-icons/bs";
import nextImage from 'assets/image/rightBtn.png';
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import { IconButton } from "@material-ui/core";
import { FaTrashAlt } from "react-icons/fa";
import PolygonCharts from 'components/charts/PolygonCharts';
import DownloadBtn from "components/buttons/experience/experienceReview/DownloadBtn";
import "./index.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { gradify } from "../../../axios.config";
import {
  getAllExperienceUserActions,
  updateCompletionExperienceUserAction,
} from "actions/experienceUserActions";
import { getExperienceDetailsItemAction } from "actions/experienceAction";
import { progressAction } from "actions/pmChartAction";
import { newMomentumAction } from "actions/momentumAction";
import { getAllUserXpByOrganizationProject } from "actions/xpsAction";
import { changeCompleteReivewAction } from "actions/newExperienceReview/newExpereinceReview.action";

const Slider = ({
 item ,
 index,
 backdropHandler,
 nextBtn ,
 preBtn,
 key,
 experience

})=> {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const [togglePhotoState, setTogglePhotoState] = useState(false);
  
    useEffect(() => {
      if (!!item) {
        setTogglePhotoState(item.complete?.complete);
      }
    }, [item]);
  
    const changeToggelHandler = async () => {
      const updateComplete = await gradify.put(
        `experiencestudentcomplete/updateexpstudent?experience=${
          experience?._id
        }&user=${item?._id}&complete=${!togglePhotoState}`
      );
      console.log(updateComplete)
      dispatch(
        progressAction(
          item._id,
          experience.project,
          experience.category ? experience.category : null,
          "2022-01-01"
        )
      );
      dispatch(
        newMomentumAction(
          item._id,
          experience.project,
          experience.category ? experience.category : null,
          15
        )
      );
      if (!togglePhotoState) {
        await gradify.post(
          `xp`,
          {
            amount: experience.xpCompletion,
            user: item?._id,
            experience: experience?._id,
            organization: experience.organization,
            project: experience.project,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
        await gradify.post(
          `coins`,
          {
            amount: experience.coins,
            user: item._id,
            experience: experience?._id,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
  
        dispatch(
          getAllUserXpByOrganizationProject(
            item._id,
            experience.organization,
            experience.project
          )
        );
      } else {
        await gradify.delete(`xp/${item._id}/${experience._id}`, {
          headers: { Authorization: "Bearer " + token },
        });
        await gradify.delete(`coins/${item._id}/${experience?._id}`, {
          headers: { Authorization: "Bearer " + token },
        });
        dispatch(
          getAllUserXpByOrganizationProject(
            item._id,
            experience.organization,
            experience.project
          )
        );
      }
      setTogglePhotoState(!togglePhotoState);
      dispatch(changeCompleteReivewAction(item._id, updateComplete.data.data));
  
  
    };

  return (
     <>
            <div className="backdrop"
            style={backdrop}
            onClick={backdropHandler}
            key={index}
            > </div>
         <div className='imageSlider' style={imageSlider}>
            <div className='imageSlide'style={imageSlide}>
               <img src={item?.photos?.photo} alt='gradify' style={imageStyle}/>
               <div className='photo-buttons' style={slideButtons}>
                 <CustomSwitch
                    style={{ position: "relative", top: 12, left: 8, transform: "scale(1.2)" }}
                    isToggle={togglePhotoState}
                    onChangeToggle={changeToggelHandler}
                  />
          <IconButton
            onClick={async () => {
              const resCompletion = await gradify.delete(
                `experiencestudentcomplete/detelePhoto/${item._id}/${experience._id}`,
                { user: item._id, experience: experience._id },
                {
                  headers: { Authorization: "Bearer " + token },
                }
              );
              const resDeletePhoto = await gradify.delete(
                `experiencephoto/deletePhoto/${item._id}/${experience._id}`,
                { user: item._id, experience: experience._id },
                {
                  headers: { Authorization: "Bearer " + token },
                }
              );
              dispatch(getExperienceDetailsItemAction(experience._id));
              backdropHandler();
            }}
          >
            <FaTrashAlt color="#fff" />
          </IconButton>
                  
              </div>
               <div className="photo-item-profile" style={slideBoxProfile}>
                <div style={slideBoxProfileContent}>
                <PolygonCharts
                        studentPic={item?.profilePicture}
                        alt="student picture"
                        style={{
                        }}
                    />
                    <p style={{fontSize:70,overflow:"visible"}}>{item?.firstName + " " + item.lastName}</p>
                </div>
                <DownloadBtn style={{marginRight: -60}} onClick={()=>{}} />
                </div>
            </div>

            <button className='' style={nextBtnStyle} onClick={()=>nextBtn()}>
               <BsFillCaretRightFill style={{color:"white" , transform:"scale(3)" , marginBottom: 5}}/>
            </button>
            <button className='' style={previousBtnStyle} onClick={()=>preBtn(index)}>
               <BsFillCaretLeftFill style={{color:"white", transform:"scale(3)" , marginBottom: 5}}/>
            </button>
       </div>
     </>
  )
}

export default Slider

const backdrop = {
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10000,
    cursor: "pointer",
    backdropFilter: "blur(15px)"
}
const slideBoxProfile ={
    display: "flex",
    paddingTop: 35,
    position: "absolute",
    justifyContent:"space-around",
    bottom: -10,
    width: "95%",
    alignItems: "center"
}
const imageSlider = {
    width: "54%",
    height: "80%",
    display: "block",
    position: "fixed",
    background: "none",
    alignItems: "center",
    zIndex: 20000,
    left:"23%",
    top: "10%",
    background: "rgba(0,0,0,0.8)",
    backdropFilter: "blur(15px)"
}
const imageSlide = {
   width: "100%",
   height: "calc(100% - 40px)",
   borderRadius: 0,
   margin: "20px auto",
   display: "block",
   textAlign: "center",
   alignItems: "center",
   padding: 20,
   position: "relative",
   zIndex: 1
}
const slideBoxProfileContent = {
  width: "calc(100% - 90px)",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  transform: "scale(0.2)",
  paddingLeft: "30%"
}
const imageStyle = {
    width: "100%",
    height: "92%",
    margin: "0 auto",
    objectFit: "contain",
    position: "relative"
}
const slideButtons = {
    width: "calc(100% - 30px)",
    position:"absolute",
    padding: 15,
    top: -10,
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between"
}
const nextBtnStyle = {
    width: 55,
    height: 55,
    position: "absolute",
    right: 10,
    top: "48%",
    background: "black",
    borderRadius: "5px",
    zIndex: 25000,
    border:"2px solid #fff"
}
const previousBtnStyle = {
    width: 55,
    height: 55,
    position: "absolute",
    left: 10,
    top: "46%",
    background: "black",
    borderRadius: "5px",
    border:"2px solid #fff",
    zIndex: 25000
}