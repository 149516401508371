import React from 'react'
import DownloadIcon from 'assets/image/download.png'
import {MdNotificationsActive} from "react-icons/md"



const Notify = ({onClick}) =>{
  return (
     <>
       <button style={style} onClick={onClick}>
           <span style={{marginRight: 5}}>Notify</span> 
           <MdNotificationsActive style={{width: 15 , height: 15}}/>
           {/* <img src={DownloadIcon} 
                width="30"
                color="white"
                style={{color:"white"}}
            /> */}
       </button>
     </>

  )
}

export default Notify


const style = {
  fontSize: "12px",
  display: "flex",
  marginRight: "5px",
  marginTop: 0,
  alignItems: "center",
  background: "#00C88B",
  borderRadius: "5px",
  boxShadow: "0px 0px 0px",
  borderColor:"#00C88B",
  border: 0,
  width: "90px",
  height: "30px",
  color:"white",
  padding: "15px",
  display: "flex",
  justifyContent: "center",
  margin: "0 auto",
}