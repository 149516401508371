import React from 'react'
import { ConfirmContainer, ConfirmDialog,ConfirmMessage } from '../styled.components'

const Confirm = ({children,message}) => {
    return (
        <ConfirmContainer>
            <ConfirmDialog>
              {children}
             <ConfirmMessage>{message}</ConfirmMessage>
            </ConfirmDialog>
        </ConfirmContainer>
    )
}

export default Confirm
