import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getALLRaffleItemListAction,
  clearAppSelectedFilterIds,
} from "actions";
import {
  RaffleContainer,
  RaffleContentContainer,
} from "components/styled.components/raffle.style";
import RaffleList from "components/raffle/RaffleList";

const RaffleListContainer = ({ 
  onSortRaffleList,
  sortType,
  raffleList,
  onEditButtonClick, 
  haveAccess 
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getALLRaffleItemListAction());

    return () => {
      clearAppSelectedFilterIds(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RaffleContainer>
      <RaffleContentContainer style={{ width: "100%", padding:0 }}>
        <RaffleList
          raffleList={raffleList}
          onEditButtonClick={onEditButtonClick}
          haveAccess={haveAccess}
          onSortRaffleList={onSortRaffleList}
          sortType={sortType}
        />
      </RaffleContentContainer>
    </RaffleContainer>
  );
};

export default RaffleListContainer;
