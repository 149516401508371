import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import "./OrganizationsTable.style.css";

import OrganizationsItem from "./OrganizationsItem";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationAction } from "../../actions/organizationActions";

const OrganizationsTable = () => {
  const dispatch = useDispatch();
  const { organizations, loading, error } = useSelector(
    (state) => state.organization
  );
  const { user } = useSelector((state) => state.loggedInUser);
  useEffect(() => {
    dispatch(getOrganizationAction(user));
  }, [dispatch, user]);

  return (
    <Table style={{ marginTop: 7 }}>
      <TableHead style={{ height: 50 }}>
        <TableRow>
          <TableCell style={{ width: 60 }}>
            <Checkbox
              color="default"
              size="small"
              checked={true}
              onChange={() => {}}
            />
          </TableCell>
          <TableCell style={{ width: 60 }}></TableCell>
          <TableCell>Name</TableCell>
          <TableCell style={{ maxWidth: 48 }}>UFCS Member</TableCell>
          <TableCell style={{ paddingLeft: 20 }}>Paid</TableCell>
          <TableCell style={{ maxWidth: 80 }}>Organization Type</TableCell>
          <TableCell>#PROJECTS</TableCell>
          <TableCell>Reward System</TableCell>
          <TableCell>Survey System</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {organizations.map((item) => (
          <OrganizationsItem item={item} />
        ))}
      </TableBody>
    </Table>
  );
};

export default OrganizationsTable;
