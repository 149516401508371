import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 288px;
  margin-right: 26.8px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 4px;
  margin-bottom: 20px;
`;
export const BlackHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 108;
  width: 100%;
  background-color: black;
  border-radius: 4px;
`;
export const WhiteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 144px;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  flex-direction: row;

  @media (max-width: 1650px) {
    padding-left: 0;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  color: white;
  font-size: 72px;
  font-family: "Rajdhani";
  font-weight: 400;
  line-height: 75px;
  @media (max-width: 1500px) {
    font-size: 45px;
  }
`;
export const Desc = styled.div`
  color: white;
  font-size: 24px;
  font-family: "Rajdhani";
  font-weight: 400;
`;
export const Store = styled.img`
  position: absolute;
  right: 63px;
`;
export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const SectionCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height:100%;
  justify-content:flex-start;
  padding-top:5px;
  @media (max-width: 1650px) {
    justify-content:center;
  }
`;
export const TitleText = styled.div`
  font-size: 50px;
  font-family: "Rajdhani";
  font-weight: 700;
  color: #3e3f5e;
  @media (max-width: 1500px) {
    font-size: 32px;
  }
  @media (max-width: 1300px) {
    font-size: 24px;
  }
`;
export const DetailText = styled.div`
  font-size: 46px;
  font-family: "Rajdhani";
  font-weight: 700;
  color: #b7b7bc;
  line-height:4px;
  @media (max-width: 1500px) {
    font-size: 32px;
  }
    @media (max-width: 1300px) {
    font-size: 24px;
  }
`;
export const PickedUpText = styled.span`
  font-size: 28px;
  font-family: "Rajdhani";
  font-weight: 700;
  color: #3E3F5E;
  @media (max-width: 1300px) {
    font-size: 22px;
    margin-top: 13px;
  }
`;
export const PickedUpValue = styled.div`
  font-size: 26px;
  font-family: "Rajdhani";
  font-weight: 700;
  color: #FFBA31;
  line-height: 10px;
  @media (max-width: 1500px) {
    font-size: 22px;
  }
  @media (max-width: 1300px) {
    font-size: 19px;
  }
`;