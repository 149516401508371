import React, { useEffect } from "react";
import ExperienceREviewCardHeader from "../components/ExperienceReviewCardHeader/ExperienceREviewCardHeader";
import ExperienceStudentList from "../components/experienceStudentList/ExperienceStudentList";
import ExperienceReviewAnayis from "../components/expreiencereviewanalyse/ExperienceReviewAnayis";
import PhotoSubmission from "../components/photoSubmission/PhotoSubmission";
import { useDispatch, useSelector } from "react-redux";
import { getExperienceDetailsItemAction } from "../actions/experienceAction";

import "./view.css";
import { newGetExperienceItemAction } from "actions/experienceListAction";
import { experieceItemSelector } from "reducers/experienceItem/experienceItem.selector";
import { newExperienceReivewAction } from "actions/newExperienceReview/newExpereinceReview.action";
import { analizeAction } from "actions/analize/analize.action";
import { photolistSelector } from "../reducers/experince-Review/experience-review.selector";

const ExperienceCardReview = ({ match, className }) => {
  const id = match.params.id;
  const dispatch = useDispatch();

  const { isPending, isSuccess, isError, experienceItem } = useSelector(
    experieceItemSelector
  );

  const { loading, users, error } = useSelector(
    (state) => state.newExperienceReview
  );

  useEffect(() => {
    // dispatch(getExperienceDetailsItemAction(id));
    dispatch(newGetExperienceItemAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (experienceItem) {
      console.log("new exp item", experienceItem);
      dispatch(
        newExperienceReivewAction(
          experienceItem.project,
          experienceItem.category,
          experienceItem._id,
          1,
          `{"field":"complete.complete","order":"desc"}`
        )
      );

      dispatch(
        analizeAction(
          experienceItem.project,
          experienceItem.category,
          experienceItem._id
        )
      );
    }
  }, [experienceItem]);

  return (
    <div>
      {isPending ? (
        <h1>Loading...</h1>
      ) : !!isError ? (
        console.log(isError)
      ) : (
        <>
          <div className={`${className} container-content`}>
            <div className="container-content-wrapper">
              {experienceItem?.completionType?.name === "Photo" ? (
                <>
                  <div className="experience_type_content">
                    <ExperienceREviewCardHeader experience={experienceItem} id={id}/>

                    <ExperienceStudentList
                      experience={experienceItem}
                      users={users}
                      loading={loading}
                      error={error}
                    />
                  </div>
                  <div className="analystics">
                    <ExperienceReviewAnayis experience={experienceItem} />
                    <PhotoSubmission
                      experience={experienceItem}
                      users={users}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="experience_type_content">

                    <ExperienceREviewCardHeader experience={experienceItem} id={id}/>
                    <ExperienceStudentList
                      experience={experienceItem}
                      users={users}
                      loading={loading}
                      error={error}
                    />
                  </div>
                  <div className="analystics">
                    <ExperienceReviewAnayis experience={experienceItem} />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExperienceCardReview;
