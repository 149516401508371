import React, { useCallback } from "react";
import Recent from "components/home/HomeList/HomeRecent";
import { useSelector, useDispatch } from "react-redux";
import { getListItem } from "helpers";
import {
  setAppSelectedProjectId,
  setAppSelectedOrganizationId,
  getALLUserList,
  getAppProjectsByIdAction,
} from "actions";

const RecentContainer = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.loggedInUser?.user);
  const recentProjects = getListItem(user._id);
  const recentProjectClickHandler = useCallback(
    (projectId) => {
      if (recentProjects[projectId]?.selectedOrganizationId) {
        setAppSelectedOrganizationId(
          dispatch,
          recentProjects[projectId]?.selectedOrganizationId
        );
        setAppSelectedProjectId(dispatch, projectId);
        dispatch(
          getAppProjectsByIdAction(
            recentProjects[projectId]?.selectedOrganizationId
          )
        );

        dispatch(getALLUserList(`filters=[{"field":"role","value":"user"}]&`));
        history.push(
          `/admin/students?studentCount=${recentProjects[projectId].studentCount}`
        );
      }
    },
    [dispatch, history, recentProjects]
  );
  return (
    <Recent
      onProjectClick={recentProjectClickHandler}
      projects={Object.values(recentProjects).reverse()}
    />
  );
};

export default RecentContainer;
