import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import RaffleAddItemPopup from "components/raffle/RaffleAddItemPopup";
import {
  raffleItemImageSelector,
  selectedAppProjectIdSelector,
  selectedAppOrganizationIdSelector,
} from "store/selectors";

import {
  changeRaffleImageUrl,
  setRafflePageStatus,
  uploadRaffleItemImage,
} from "actions";

const RaffleAddItemPopupContainer = ({
  onSaveRaffleItemClicked,
  categories,
}) => {
  const dispatch = useDispatch();
  //states
  const [title, setTitle] = useState("");
  const [gemsNumber, setGemsNumber] = useState();
  const [description, setDescription] = useState();
  const [isPrizeWheel, setIsPrizeWheel] = useState(false);
  const [isStore, setIsStore] = useState(false);
  const [winnerNumber, setWinnerNumber] = useState(0);
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [startTime, setStartTime] = useState();
  const [closeDate, setCloseDate] = useState(new Date().toISOString());
  const [closeTime, setCloseTime] = useState();
  const [drawingDate, setDrawingDate] = useState(new Date().toISOString());
  const [drawingTime, setDrawingTime] = useState();
  const [category, setCategory] = useState("Select category");
  // selectors
  const raffleImage = useSelector(raffleItemImageSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  //actions
  const handleCancelRaffleItemClick = useCallback(() => {
    setIsPrizeWheel(false);
    setIsStore(false);
    setDescription("");
    setTitle("");
    setGemsNumber(null);
    changeRaffleImageUrl(dispatch, null);
    setRafflePageStatus(dispatch, "DEFAULT");
  }, [dispatch]);
  const prizeWheelChangedHandler = useCallback(() => {
    setIsPrizeWheel(!isPrizeWheel);
  }, [isPrizeWheel]);
  const storeChangedHandler = useCallback(() => {
    setIsStore(!isStore);
  }, [isStore]);
  const changeItemImgHandler = useCallback(
    (e) => {
      uploadRaffleItemImage(dispatch, e.target.files[0]);
    },
    [dispatch]
  );
  const saveRaffleItemClickedHandler = useCallback(() => {
    let raffleInfo;
    if (category === "Select category") {
      raffleInfo = {
        raffle_title: title,
        raffle_image:
          raffleImage?.url ||
          "https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg",
        description: description || "-",
        gem_raffle: gemsNumber,
        in_store: isStore || false,
        in_prize_wheel: isPrizeWheel || false,
        start_date:
          new Date(startDate + "T" + startTime).toISOString() ||
          new Date().toISOString(),
        end_date:
          new Date(closeDate + "T" + closeTime).toISOString() ||
          new Date().toISOString(),
        draw_date:
          new Date(drawingDate + "T" + drawingTime).toISOString() ||
          new Date().toISOString(),
        // Todo: time I need to know
        start_time: startTime,
        close_time: closeDate,
        draw_time: drawingTime,
        number_winner: winnerNumber,
        organization: selectedOrganizationId,
        project: selectedProjectId,
      };
    } else {
      raffleInfo = {
        raffle_title: title,
        raffle_image:
          raffleImage?.url ||
          "https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg",
        description: description || "-",
        gem_raffle: gemsNumber,
        in_store: isStore || false,
        in_prize_wheel: isPrizeWheel || false,
        start_date:
          new Date(startDate + "T" + startTime).toISOString() ||
          new Date().toISOString(),
        end_date:
          new Date(closeDate + "T" + closeTime).toISOString() ||
          new Date().toISOString(),
        draw_date:
          new Date(drawingDate + "T" + drawingTime).toISOString() ||
          new Date().toISOString(),
        // Todo: time I need to know
        start_time: startTime,
        close_time: closeDate,
        draw_time: drawingTime,
        number_winner: winnerNumber,
        organization: selectedOrganizationId,
        project: selectedProjectId,
        category: category,
      };
    }

    onSaveRaffleItemClicked(raffleInfo);
    setIsPrizeWheel(false);
    setIsStore(false);
    setDescription("");
    setTitle("");
    setGemsNumber(null);
    changeRaffleImageUrl(dispatch, null);
  }, [
    closeDate,
    description,
    dispatch,
    drawingDate,
    drawingTime,
    gemsNumber,
    isPrizeWheel,
    isStore,
    onSaveRaffleItemClicked,
    raffleImage?.url,
    selectedOrganizationId,
    selectedProjectId,
    startDate,
    startTime,
    title,
    winnerNumber,
  ]);

  const onChangeCategory = (value) => {
    console.log(value);
    setCategory(value);
  };
  return (
    <RaffleAddItemPopup
      raffleImage={raffleImage}
      onChangeItemImg={changeItemImgHandler}
      isPrizeWheel={isPrizeWheel}
      onPrizeWheelChange={prizeWheelChangedHandler}
      isStore={isStore}
      onStoreChange={storeChangedHandler}
      title={title}
      onTitleChanged={(e) => {
        setTitle(e.target.value);
      }}
      gemsNumber={gemsNumber}
      onGemsNumberChanged={(e) => {
        setGemsNumber(e.target.value);
      }}
      winnerNumber={winnerNumber}
      onWinnerNumberChange={(e) => {
        setWinnerNumber(e.target.value);
      }}
      startDate={startDate}
      onStartDateChange={(e) => {
        setStartDate(e.target.value);
      }}
      startTime={startTime}
      onStartTimeChange={(e) => {
        setStartTime(e.target.value);
      }}
      closeDate={closeDate}
      onCloseDateChange={(e) => {
        setCloseDate(e.target.value);
      }}
      closeTime={closeTime}
      onCloseTimeChange={(e) => {
        setCloseTime(e.target.value);
      }}
      drawingTime={drawingTime}
      onDrawingTimeChange={(e) => {
        setDrawingTime(e.target.value);
      }}
      drawingDate={drawingDate}
      onDrawingDateChange={(e) => {
        setDrawingDate(e.target.value);
      }}
      description={description}
      onDescriptionChanged={(e) => {
        setDescription(e.target.value);
      }}
      onSaveRaffleClick={saveRaffleItemClickedHandler}
      onCancelClick={handleCancelRaffleItemClick}
      categories={categories}
      onChangeCategory={onChangeCategory}
    />
  );
};

export default RaffleAddItemPopupContainer;
