import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useContext, useState, useEffect, useMemo } from "react";
import DownloadBtn from "components/buttons/experience/experienceReview/DownloadBtn";
import CustomSwitch from "components/buttons/experience/experienceReview/CheckBox";
import Notify from "components/buttons/experience/experienceReview/Notify";
import {
  SearchExperienceStudentContainer,
  SearchExperienceStudent,
} from "components/styled.components/experience.details.style";
import SelectComponent from "../control.component/select.component";
import CheckBoxInput from "components/buttons/experience/experienceReview/CheckBox";
import "./experienceStudentList.css";
import ExperienceStudentListItem from "./ExperienceStudentListItem";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/spinner/spinner";
import { BiSearchAlt } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { newExperienceReivewAction } from "actions/newExperienceReview/newExpereinceReview.action";
const options = [
  {
    id: "All",
    name: "All",
    value: "All",
  },
  {
    id: "Compeleted",
    name: "Compeleted",
    value: "Compeleted",
  },
  {
    id: "Not Compeleted",
    name: "Not Compeleted",
    value: "Not Compeleted",
  },
];
const ExperienceStudentList = ({ experience, users, loading, error }) => {
  const dispatch = useDispatch();

  const [pageCount, setPageCount] = useState(1);
  const [select, setSelect] = useState(false);
  const [groupSelect, setGroupSelect] = useState(false);
  const [assendingSort, setassendingSort] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectFilter, setSelectFilter] = useState("");
  const [sortState, setSortState] = useState("");
  const onChangeSelect = () => {
    setSelect(!select);
  };

  const changeCompletionList = (e) => {
    //selectCompleteList(e.target.value,expreienceId);
    setSelectFilter(e.target.value);
    dispatch(
      newExperienceReivewAction(
        experience?.project,
        experience?.category,
        experience?._id,
        1,
        null,
        e.target.value
      )
    );
  };
  const searchExperienceList = (e) => {
    setSearchInput(e.target.value);
    dispatch(
      newExperienceReivewAction(
        experience?.project,
        experience?.category,
        experience?._id,
        1,
        null,
        "All",
        e.target.value
      )
    );
  };

  useEffect(() => {
    console.log("select filer", selectFilter);
    setSelectFilter(selectFilter);
  }, [selectFilter]);

  const downloadHandler = () => {
    console.log("download Handler Clicked");
  };
  const notifyHandler = () => {
    console.log("Notify Handler Clicked");
  };
  const handlePageClick = (event) => {
    console.log("ascending state", assendingSort);
    if (assendingSort == false && sortState == "") {
      dispatch(
        newExperienceReivewAction(
          experience?.project,
          experience?.category,
          experience?._id,
          event.selected + 1,
          `{"field":"complete.complete","order":"desc"}`
        )
      );
    } else {
      dispatch(
        newExperienceReivewAction(
          experience?.project,
          experience?.category,
          experience?._id,
          event.selected + 1,

          assendingSort
            ? `{"field":"${sortState}","order":"desc"}`
            : `{"field":"${sortState}","order":"asce"}`
        )
      );
    }
    setPageCount(event.selected + 1);
  };

  if (error) return <h2>{error}</h2>;

  return (
    <div className="ex-student-list-container">
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <SearchExperienceStudentContainer>
          <BiSearchAlt
            spaceLeft="18px"
            style={{ position: "absolute", top: 11, left: 11 }}
            color={"#707070"}
          />
          <SearchExperienceStudent
            type="text"
            style={{ paddingLeft: 20 }}
            placeholder="Search ..."
            value={searchInput}
            onChange={(e) => searchExperienceList(e)}
          />
        </SearchExperienceStudentContainer>
        <h1 className="ex-student-list-title">Students</h1>

        {experience?.completionType?.name === "Photo" && (
          <>
            <DownloadBtn onClick={downloadHandler} />
            <CheckBoxInput
            // onChange={}
            />
          </>
        )}

        <Notify onClick={notifyHandler} />
      </div>
      {loading ? (
        <Spinner style={{ width: 50, height: 50, top: 20 }} />
      ) : (
        <div className="ex-student-list-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox checked={select} onChange={onChangeSelect} />
                </TableCell>
                <TableCell>
                  <span>Students</span>
                  {!assendingSort ? (
                    <FaAngleDown
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"firstName","order":"desc"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("firstName");
                        setPageCount(1);
                      }}
                    />
                  ) : (
                    <FaAngleUp
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"firstName","order":"asce"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("firstName");
                        setPageCount(1);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <SelectComponent
                    options={options}
                    onChange={changeCompletionList}
                    value={selectFilter}
                    style={{
                      padding: "2px 8px",
                      fontSize: 11,
                      fontWeight: 700,
                      width: 118,
                      backgroundPosition: "bottom 11px right 7px",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <span>Date</span>
                  {!assendingSort ? (
                    <FaAngleDown
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"complete.updatedAt","order":"desc"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("complete.updatedAt");
                        setPageCount(1);
                      }}
                    />
                  ) : (
                    <FaAngleUp
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"complete.updatedAt","order":"asce"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("complete.updatedAt");
                        setPageCount(1);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <CustomSwitch
                    isToggle={groupSelect}
                    onChangeToggle={() => setGroupSelect(!groupSelect)}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("users e", users && users)}
              {users &&
                users?.data?.map((item) => (
                  <ExperienceStudentListItem
                    key={item._id}
                    item={item}
                    select={select}
                    groupSelect={groupSelect}
                    experience={experience}
                  />
                ))}
            </TableBody>
          </Table>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {users && users?.metadata[0]?.total > 25 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={Math.ceil(users.metadata[0].total / 25)}
                forcePage={pageCount - 1}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
                breakClassName="page-item"
                breakLinkClassName="page-link"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExperienceStudentList;
