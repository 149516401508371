import styled, { keyframes } from "styled-components";

export const CreateShopFormCol = styled.div`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 10px 0 0;
`;

export const SuggestShopText = styled.h3`
  color: #e83200;
  padding-top: 10px;
  padding-left: 14px;
  font-size: 13px;
`;

export const CreateShopContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateShopForm = styled.div`
  padding-bottom: 10px;
  margin-left: 200px;
  padding-top: 20px;
  width: fit-content;
  height: fit-content;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 1px 2px 14px -1px rgba(0, 0, 0, 0.82);
  min-width: 803px;
  @media (max-width: 1300px) {
    margin-left: 100px;
  }
`;

export const CreateShopFormRow = styled.div`
  width: 100%;
  display: flex;
  padding-top: 1px;
`;

export const ShopInput = styled.input`
  width: ${({ Width }) => (Width ? Width : "190px")};
  height: ${({ Height }) => (Height ? Height : "32px")};
  text-align: center;
  font-family: ${({ FontFamily }) => FontFamily};
  font-size: ${({ Size }) => Size};
  font-weight: ${({ Weight }) => Weight};
  padding: ${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightdark};
  color: #070708;
  max-width: ${({ Width }) => (Width ? Width : "235px")};
`;

export const LabelShop = styled.label`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray};
`;
export const SwitchLabelShop = styled.label`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
  font-size: 14px;
  color: #74788d;
  padding-right: 7px;
`;

export const ShopImage = styled.img`
  width: 200px;
  border-radius: 50px;
  object-fit: contain;
`;
export const ShopEditButtton = styled.button`
  display: flex;
  height: 32px;
  border-radius: 4px;
  width: 60px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border: none;
  position: absolute;
  right: 80px;
  bottom: -14px;
`;

export const ShopProjectEditButtton = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border: none;
`;

export const ShopEditButttonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  margin: 0;
  padding-right: 5px;
`;

export const ShopEditButttonIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;

export const ShopButon = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.buttonBlue};
  border: none;
  border-radius: 50px;
  padding: 5px 31px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 15px;
  font-weight: 500;
`;
export const ShopDetailsContainer = styled.div`
  padding: 27px;
  padding-top: 35px;
`;

export const ShopPic = styled.div`
  width: 125px;
  height: 110px;
`;

export const ShopInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ShopListContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  max-width: 835px;
  border: 1px solid #ced4da;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 17px;
  justify-content: flex-start;
  margin-right: 20px;
`;
export const ShopListProject = styled.div`
  border: 1px solid #ced4da;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 5px;
  position: relative;
  max-width: 46%;
  color: #74788d;
  font-size: 13px;
  flex-direction: column;
  border-radius: 5px;
  min-width: 110px;
  min-height: 25px;
  padding: 0 25px 0 7px;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ShopListCloseProject = styled.div`
  position: absolute;
  top: -1px;
  right: 7px;
  color: #313539;
  font-family: system-ui;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div`
  width: 144px;
  height: 144px;
  ::after {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 2px solid #d3d3d3;
    background: transparent;
    width: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    height: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    border-radius: 50%;
    align-self: center;
  }
`;
export const ImagContainer = styled.div`
  width: 200px;
  height: 263px;
`;
