import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_NEW,
  USER_EXPERIENCE_LIST_REQUEST,
  USER_EXPERIENCE_LIST_SUCCESS,
  USER_EXPERIENCE_LIST_FAIL,
  ADD_USER_XP,
  USER_LEADERBOARD_FAIL,
  USER_LEADERBOARD_SUCCESS,
  USER_LEADERBOARD_REQUEST,
  USER_SET_SORT_TYPE,
  UPDATE_USER_LIST,
} from "../constant/user";

export const userListReducer = (
  state = {
    users: {},
    addedConfirm: false,
    sort: { type: "Name", isReversed: false },
  },
  action
) => {
  switch (action.type) {
    case USER_SET_SORT_TYPE:
      return { ...state, sort: action.payload };
    case USER_LIST_REQUEST:
      return { ...state, loading: true, users: {}, orginalUsers: {} };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orginalUsers: action.payload,
        users: action.payload,
      };

    case USER_CREATE_SUCCESS:
      return { ...state, addedConfirm: true };
    case USER_CREATE_NEW:
      return { ...state, addedConfirm: false };
    case USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        users: {},
        error: action.payload,
        addedConfirm: false,
      };
    case UPDATE_USER_LIST:
      console.log("ac", action.payload);
      console.log("users", state?.users);

      return {
        ...state,
        users: {
          ...state.users,
          data: state?.users?.data?.map((user) =>
            user._id === action.payload.id
              ? (user = { ...user, fullName: action.payload.fullName })
              : user
          ),
        },
      };
    case ADD_USER_XP:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map((item) =>
            item._id === action.payload.user
              ? (item = { ...item, xp: item.xp + action.payload.amount })
              : item
          ),
        },
        orginalUsers: {
          ...state.orginalUsers,
          data: state.orginalUsers.data.map((item) =>
            item._id === action.payload.user
              ? (item = { ...item, xp: item.xp + action.payload.amount })
              : item
          ),
        },
      };
    default:
      return state;
  }
};

export const userExperienceListReduer = (state = { users: {} }, action) => {
  switch (action.type) {
    case USER_EXPERIENCE_LIST_REQUEST:
      return { ...state, loading: true, users: {} };
    case USER_EXPERIENCE_LIST_SUCCESS:
      return { ...state, loading: false, users: action.payload };
    case USER_EXPERIENCE_LIST_FAIL:
      return { ...state, loading: false, users: {}, error: action.payload };
    default:
      return state;
  }
};

export const leaderBoardReducer = (state = { leaderBoards: [] }, action) => {
  switch (action.type) {
    case USER_LEADERBOARD_REQUEST:
      return { loading: true, leaderBoards: [] };

    case USER_LEADERBOARD_SUCCESS:
      return { ...state, loading: false, leaderBoards: action.payload };
    case USER_LEADERBOARD_FAIL:
      return {
        ...state,
        loading: false,
        leaderBoards: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
