import React from 'react'
import {Checkbox } from "@material-ui/core";

const CheckBoxInput = ({onChange}) => {
    return (
        <div 
           style={{
              display:"flex",
              flexFlow:"column",
              margin:"0 auto" ,
              width:"50px", 
              justifyContent:"center"}}>
          <span style={{fontSize:"13px" , marginTop: 5 ,  marginLeft: 10}}>All</span>
          <Checkbox
             color="default"
             size="small"
             style={{right:5 , bottom: 7}}
             checked={true}
             onChange={onChange}
             value={""}
           />
        </div>

    )
}

export default CheckBoxInput
