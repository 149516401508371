export const USER_ROLE = [
  {
    name: "ultraAdmin",
    _id: "ultraAdmin",
  },
  {
    name: "superAdmin",
    _id: "superAdmin",
  },
  {
    name: "admin",
    _id: "admin",
  },
];
export const GENDER = [
  {
    _id: "Male",
    name: "Male",
  },
  {
    _id: "Female",
    name: "Female",
  },
  {
    _id: "Other",
    name: "Other",
  },
];

export const EDUCATIONAL_PHASES = [
  {
    _id: "HS-9",
    name: "HS-9",
  },
  {
    _id: "HS-10",
    name: "HS-10",
  },
  {
    _id: "HS-11",
    name: "HS-11",
  },
  {
    _id: "HS-12",
    name: "HS-12",
  },
  {
    _id: "Transition",
    name: "Transition",
  },
  {
    _id: "OY",
    name: "OY",
  },
  {
    _id: "University-1",
    name: "University-1",
  },
  {
    _id: "University-2",
    name: "University-2",
  },
  {
    _id: "University-3",
    name: "University-3",
  },
  {
    _id: "University-4",
    name: "University-4",
  },
  {
    _id: "Recapture",
    name: "Recapture",
  },
];

export const picExper = [
  {
    id: 1,
    url: "http://localhost:5500/upload/pic1.png",
  },
  {
    id: 2,
    url: "http://localhost:5500/upload/pic2.png",
  },
  {
    id: 3,
    url: "http://localhost:5500/upload/pic3.png",
  },
  {
    id: 4,
    url: "http://localhost:5500/upload/pic4.png",
  },
  {
    id: 5,
    url: "http://localhost:5500/upload/pic5.png",
  },
  {
    id: 6,
    url: "http://localhost:5500/upload/pic6.png",
  },
];

export const organizationType = [
  {
    id: "Secondary",
    name: "Secondary",
  },
  {
    id: "Post Secondary",
    name: "Post Secondary",
  },
  {
    id: "CBO",
    name: "CBO",
  },
  {
    id: "Other",
    name: "Other",
  },
];

export const ufcsMember = [
  {
    id: "Yes",
    name: "Yes",
  },
  {
    id: "No",
    name: "No",
  },
];

export const paid = [
  {
    _id: "Yes",
    name: "Yes",
  },
  {
    _id: "No",
    name: "No",
  },
  {
    _id: "Free",
    name: "Free",
  },
];

export const rewardSystem = [
  {
    _id: "Yes",
    name: "Yes",
  },
  {
    _id: "No",
    name: "No",
  },
];

export const surveySystem = [
  {
    _id: "Yes",
    name: "Yes",
  },
  {
    _id: "No",
    name: "No",
  },
];

export const chartView = [
  {
    _id: "Standard",
    name: "Standard",
  },
  {
    _id: "Academic",
    name: "Academic",
  },
];

export const PROJECT_TYPE = [
  { _id: "Secondary", name: "Secondary" },
  { _id: "Special Project", name: "Special Project" },
  { _id: "Other - secondary", name: "Other - secondary" },
  { _id: "Other - post secondary", name: "Other - post secondary" },
];

export const CONDITION = [
  { _id: "Editable", name: "Editable" },
  { _id: "Locked", name: "Locked" },
];

export const STATUS = [
  { _id: "Active", name: "Active" },
  { _id: "Stasis", name: "Stasis" },
  { _id: "Completed", name: "Completed" },
];

export const COLLABORATIVE = [
  {
    _id: "Yes",
    name: "Yes",
  },
  {
    _id: "No",
    name: "No",
  },
];
export const ULTRA_ADMIN_LEVEL = [
  { name: "Super admin", _id: "superAdmin" },
  { name: "Admin", _id: "admin" },
];
export const SUPER_ADMIN_LEVEL = [{ name: "Admin", _id: "admin" }];

export const EXPERIENCE_TAGS_DRIVER_1 = [
  { name: "Driver 1", _id: null },
  { name: "SelfEfficacyTAG", _id: "SelfEfficacyTAG" },
  { name: "FutureDevTAG", _id: "FutureDevTAG" },
  { name: "ConnectTAG", _id: "ConnectTAG" },
  { name: "EmoTAG", _id: "EmoTAG" },
  { name: "SocialTAG", _id: "SocialTAG" },
  { name: "FinancialTAG", _id: "FinancialTAG" },
  { name: "AcademicTAG", _id: "AcademicTAG" },
  { name: "SituationalTAG", _id: "SituationalTAG" },
];

export const EXPERIENCE_TAGS_DRIVER_2 = [
  { name: "Driver 2", _id: null },
  { name: "SelfEfficacyTAG", _id: "SelfEfficacyTAG" },
  { name: "FutureDevTAG", _id: "FutureDevTAG" },
  { name: "ConnectTAG", _id: "ConnectTAG" },
  { name: "EmoTAG", _id: "EmoTAG" },
  { name: "SocialTAG", _id: "SocialTAG" },
  { name: "FinancialTAG", _id: "FinancialTAG" },
  { name: "AcademicTAG", _id: "AcademicTAG" },
  { name: "SituationalTAG", _id: "SituationalTAG" },
];
