import produce from "immer";
import {
  SCORES_REQUEST,
  SCORES_SUCCESS,
  SCORES_FAILD,
} from "../../constant/scores";

const INITIAL_STATE = {
  loading: false,
  scores: null,
  error: null,
};

export const scoresReducers = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SCORES_REQUEST:
        draft.loading = true;
        break;
      case SCORES_SUCCESS:
        draft.loading = true;
        draft.scores = action.payload;
        break;
      case SCORES_FAILD:
        draft.loading = false;
        draft.error = action.payload;
        break;
    }
  });
