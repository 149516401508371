import React, { useEffect, useState } from "react";
import {
  ExperienceDetailsContainer,
  ExperienceTitle,
  ExperienceDetailsHeader,
  ExperienceSubTitle,
  ExperienceTitleContainer,
  ExperienceDatilsInfo,
  ExperienceDetailsCol,
  ExperienceRow,
  ExperienceCardDetails,
  ExperienceCardDetailsInfo,
  ExperienceCardDetailsAction,
  LabelExperience,
  ExperienceButton
} from "components/styled.components";
import {
  allAppCategoriesSelector,
  allAppSubCategoriesSelector,
} from "store/selectors";
import TabName from "views/assets/experience/TabName";
import SelectComponent from "components/control.component/select.component";
import { FaCompass } from "react-icons/fa";
import { BsTrash, BsCaretRightFill, BsTypeH1 } from "react-icons/bs";
import TitleLinkImage from "assets/image/title-link.png";
import "views/view.css";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import { ShadowBox } from "assets/style/General.style";
import PickExperiencePicture from "components/pickExperiencePicture/PickExperiencePicture";
import { FaRegChartBar } from "react-icons/fa";
import Confirm from "components/display.component/confirm.components";
import { withRouter } from "react-router-dom";
import AnimationCheck from "components/animation.icon/animation.icon.components";
import {
  EXPERIENCE_TAGS_DRIVER_1,
  EXPERIENCE_TAGS_DRIVER_2,
} from "constants/index";
import {
  getCompletionList,
  getCompletionDetails,
} from "actions/completionAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getExperienceTypeList,
  getExperienceTypeDetails,
} from "actions/experienceTypeAction";
import { getAllExperienceImageList } from "actions/experienceImageAction";
import {
  getCategoryList,
  getCategoryProjectAction,
} from "actions/categoryListAction";
import {
  getSubCategoryList,
  getsubCategoryProjectAction,
} from "actions/subCategoryListAction";
import {
  getExperienceDetailsItemAction,
  updateExperience,
} from "actions/experienceAction";
import DeleteConfirm from "components/display.component/DeleteConfirm";
import { gradify } from "axios.config";
import { getOrganizationProject } from "actions/projectListAction";
import moment from "moment";
import { MobileScreenShareTwoTone } from "@material-ui/icons";
import StarBtn from "components/buttons/experience/StarBtn";
import FireBtn from "components/buttons/experience/FireBtn";

const ExperienceEdit = ({ history, match }) => {
  const dispatch = useDispatch();
  //states
  const [experienceTypeId, setExperienceTypeId] = useState("");
  const [experience, setExperience] = useState(null);
  const [deleteconfirm, setDeleteConfirm] = useState(false);
  const [expImage, setExpImage] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [picCard, setPicCard] = useState(false);
  const [completionId, setCompletionId] = useState("");
  const [projectStateId, setProjectStateId] = useState(null);
  const [categoryStateId, setCategoryStateId] = useState(null);
  const [subCategoryStateId, setSubCategoryStateId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [textLink, setTextLink] = useState(false);
  const [toggleBox, setToggleBox] = useState({
    box1: true,
    box2: false,
    box3: false,
  });
  const [milstone, setMilstone] = useState(true);
  const [buttonLink, setButtonLink] = useState("");
  const [textLinkTitle, setTextLinkTitle] = useState("");
  const [urlTextLink, setUrlTextLink] = useState("");
  const [progressionLevel, setProgressionLevel] = useState("");
  const [persona, setPersona] = useState("");
  const [showDifficulty, setShowDifficulty] = useState(true);
  const [starRate, setStarRate] = useState(0);
  const [fireRate, setFireRate] = useState(0);
  //selectors
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.loggedInUser);
  const { experienceType } = useSelector((state) => state.experienceTypeList);
  const { completions } = useSelector((state) => state.completionList);
  const {
    loading: experienceImageLoading,
    experienceImages,
    error: experienceImageError,
  } = useSelector((state) => state.experienceImageList);

  const { categories } = useSelector((state) => state.categoryProjectList);
  const { subCategories } = useSelector(
    (state) => state.subCategoryProjectList
  );
  const { experienceTypeDetails } = useSelector(
    (state) => state.experienceTypeDetails
  );
  const { completionDetails } = useSelector((state) => state.completionDeatils);
  const { experienceDetails } = useSelector((state) => state.experienceItem);
  const [driverDefult, setDriverDefult] = useState(EXPERIENCE_TAGS_DRIVER_1);
  const [secoundDriverDefult, setSecoundDriverDefult] = useState(
    EXPERIENCE_TAGS_DRIVER_2
  );
  const [firstDriver, setFirstDriver] = useState("");
  const [secondDriver, setSecondDriver] = useState("");
  const [secondDriverVal, setSecondDriverVal] = useState(
    EXPERIENCE_TAGS_DRIVER_2
  );
  const [firstDriverVal, setFirstDriverVal] = useState(
    EXPERIENCE_TAGS_DRIVER_1
  );

  useEffect(() => {
    dispatch(getExperienceDetailsItemAction(match.params.id));
  }, [dispatch, match.params.id]);
  useEffect(() => {
    if (experienceDetails.title) {
      setExperience(experienceDetails);
      setImgUrl(experienceDetails.experienceCardPic);
      setExperienceTypeId(experienceDetails.experienceType._id);
      setCompletionId(experienceDetails?.completionType?._id);
      setProjectStateId(experienceDetails.project?._id);
      setCategoryStateId(experienceDetails.category?._id);
      setSubCategoryStateId(experienceDetails.subCategory?._id);
      setButtonLink(experienceDetails?.button_link_url);
      setUrlTextLink(experienceDetails?.link_url);
      setTextLinkTitle(experienceDetails?.text_url);
      setFirstDriver(experienceDetails?.tags?.driver_one);
      setSecondDriver(experienceDetails?.tags?.driver_two);
      setTextLink(experienceDetails?.text_url ? true : false);
      dispatch(
        getAllExperienceImageList(experienceDetails.experienceType.name)
      );
      dispatch(getCategoryProjectAction(experienceDetails?.project?._id, user));
      if (experienceDetails?.category?._id)
        dispatch(
          getsubCategoryProjectAction(experienceDetails?.category?._id, user)
        );
    }
  }, [dispatch, experienceDetails, user]);

  useEffect(() => {
    dispatch(getExperienceTypeList());
    dispatch(getCompletionList());
    setExperienceTypeId("");
  }, [dispatch]);

  useEffect(() => {
    if (experienceTypeId !== "") {
      dispatch(getExperienceTypeDetails(experienceTypeId));
    }
  }, [dispatch, experienceTypeId]);

  useEffect(() => {
    if (experienceTypeDetails.name) {
      setExpImage(experienceTypeDetails.name);
    }
  }, [experienceTypeDetails]);

  useEffect(() => {
    if (expImage !== "") {
      dispatch(getAllExperienceImageList(expImage));
    }
  }, [dispatch, expImage]);

  useEffect(() => {
    if (experienceImages) {
    }
  }, [experienceImages]);

  useEffect(() => {
    if (completionId !== "") {
      dispatch(getCompletionDetails(completionId));
    }
  }, [completionId, dispatch]);

  useEffect(() => {
    if (projectStateId) {
      dispatch(getCategoryList(projectStateId));
    }
  }, [dispatch, projectStateId]);

  useEffect(() => {
    if (categoryStateId) {
      dispatch(getSubCategoryList(categoryStateId));
    }
  }, [categoryStateId, dispatch]);

  // useEffect(() => {
  //   if(experience?.tags?.driver_one !== ""){

  //     const secoundDriverItems = secoundDriverDefult.filter((item) => item._id !== experience?.tags?.driver_one);

  //     setSecondDriverVal(secoundDriverItems);
  //   }
  // }, []);

  useEffect(() => {
    if (firstDriver !== "") {
      const secoundDriverItems = secoundDriverDefult.filter(
        (item) => item._id !== firstDriver
      );

      setSecondDriverVal(secoundDriverItems);
    }
  }, [firstDriver]);

  useEffect(() => {
    if (secondDriver !== "" && firstDriver === "") {
      const firstDriverItems = driverDefult.filter(
        (item) => item._id !== secondDriver
      );

      setFirstDriverVal(firstDriverItems);
    }
  }, [secondDriver]);

  const changeExperienceTypeHandler = (e) => {
    setExperienceTypeId(e.target.value);
  };

  const changeImage = (url) => {
    dispatch(getAllExperienceImageList(experience.experienceType.name));
    setImgUrl(url);
  };
  const deleteCloseHanlder = () => {
    setDeleteConfirm(false);
  };

  const deleteExperienceHandler = async () => {
    await gradify.delete(`experiencecards/${experience._id}`, {
      headers: { Authorization: "Bearer " + token },
    });
    setDeleteConfirm(false);
    setShowConfirm(true);
    setTimeout(() => {
      setShowConfirm(false);
      history.push("/admin/experiences");
    }, 4000);
  };

  const updatePublishHandler = (e) => {
    let updateItem;
    if (e.target.name === "unPublish") {
      updateItem = {
        publish: false,
        expSave: true,
      };
    }
    if (e.target.name === "publish") {
      updateItem = {
        publish: true,
        expSave: false,
      };
    }

    if (e.target.name === "update") {
      updateItem = {
        ...experience,
        experienceCardPic: imgUrl,
        completionType: completionId,
        experienceType: experienceTypeId,
        project: projectStateId,
        category: categoryStateId,
        subCategory: subCategoryStateId,
        text_url: textLinkTitle,
        link_url: urlTextLink,
        button_link_url: buttonLink,
        tags: { driver_one: firstDriver, driver_two: secondDriver },
      };
    }

    dispatch(updateExperience(experience._id, updateItem));
    setShowConfirm(true);
    setTimeout(() => {
      setShowConfirm(false);
      history.push("/admin/experiences");
    }, 4000);
  };

  const onChangeButtonLink = (event) => {
    const { value } = event.target;
    setButtonLink(value);
  };

  const onChangeUrlTextChange = (event) => {
    const { value } = event.target;
    setTextLinkTitle(value);
  };

  const onChangeUrlTextLink = (event) => {
    const { value } = event.target;
    setUrlTextLink(value);
  };
  // STAR CLASSES
  let firstStarClasses = ["#7b7777", "url(#linear-gradient)"];
  let secondStarClasses = ["#7b7777", "url(#linear-gradient)"];
  let thirdStarClasses = ["#7b7777", "url(#linear-gradient)"];
  // FIRE CLASSES
  let firstFireClasses = ["#7b7777", "url(#linear-gradient)"];
  let secondFireClasses = ["#7b7777", "url(#linear-gradient)"];
  let thirdFireClasses = ["#7b7777", "url(#linear-gradient)"];
  // STAR HANDLES
  const firstStarHandle = () => {
    if (starRate == 1) {
      setStarRate(0);
    } else {
      setStarRate(1);
    }
  };
  const secondStarHandle = () => {
    if (starRate == 2) {
      setStarRate(1);
    } else {
      setStarRate(2);
    }
  };
  const thirdStarHandle = () => {
    if (starRate == 3) {
      setStarRate(2);
    } else {
      setStarRate(3);
    }
  };
  if (starRate == 1) {
    firstStarClasses = ["#7b7777", "#FFB100"];
  } else if (starRate == 2) {
    firstStarClasses = ["#7b7777", "#FFB100"];
    secondStarClasses = ["#7b7777", "#FFB100"];
  } else if (starRate == 3) {
    firstStarClasses = ["#7b7777", "#FFB100"];
    secondStarClasses = ["#7b7777", "#FFB100"];
    thirdStarClasses = ["#7b7777", "#FFB100"];
  }

  // FIRE HANDLES
  const firstFireHandle = () => {
    if (fireRate == 1) {
      setFireRate(0);
    } else {
      setFireRate(1);
    }
  };
  const secondFireHandle = () => {
    if (fireRate == 2) {
      setFireRate(1);
    } else {
      setFireRate(2);
    }
  };
  const thirdFireHandle = () => {
    if (fireRate == 3) {
      setFireRate(2);
    } else {
      setFireRate(3);
    }
  };
  if (fireRate == 1) {
    firstFireClasses = ["#7b7777", "#FF0000"];
  } else if (fireRate == 2) {
    firstFireClasses = ["#7b7777", "#FF0000"];
    secondFireClasses = ["#7b7777", "#FF0000"];
  } else if (fireRate == 3) {
    firstFireClasses = ["#7b7777", "#FF0000"];
    secondFireClasses = ["#7b7777", "#FF0000"];
    thirdFireClasses = ["#7b7777", "#FF0000"];
  }

  return (
    <>
      {experience && (
        <ExperienceDetailsContainer>
          <ExperienceDetailsHeader>
            <SelectComponent
              options={experienceType.data}
              selectedValue={experienceTypeId}
              style={{ marginLeft: 69 }}
              onChange={changeExperienceTypeHandler}
            />

            <ExperienceTitleContainer>
              <ExperienceTitle>{experienceTypeDetails.name}</ExperienceTitle>
              <ExperienceSubTitle>Experience Type</ExperienceSubTitle>
            </ExperienceTitleContainer>
            <FaCompass color="white" size={50} />
          </ExperienceDetailsHeader>
          <ExperienceDatilsInfo
            style={{ display: "flex", alignItems: "flex-start" }}
            className="row m-0 w-100"
          >
            <ExperienceDetailsCol
              className="experience-empty-col"
              style={{ width: "27%", padding: 2 }}
            ></ExperienceDetailsCol>
            <ExperienceDetailsCol
              className="experience-responsive-col"
              style={{ justifyContent: "center", width: "21%", padding: 2,flexWrap:"nowrap" }}
            >
              <div style={{ paddingRight: 20 }}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 0,
                  }}
                >
                  Start Date
                </p>
                <div>
                  <input
                    type="date"
                    name="startDate"
                    id="startDate"
                    style={{
                      width: 137,
                      padding: 5,
                      border: "1px solid #CED4DA",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderRadius: 5,
                      color: "#495057",
                    }}
                    value={
                      moment(experience.startDate).utc().format().split("T")[0]
                    }
                    onChange={(e) => {
                      setExperience({
                        ...experience,
                        startDate: e.target.value,
                      });
                    }}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <input
                    type="time"
                    name="startTime"
                    id="startTime"
                    style={{
                      width: 137,
                      padding: 5,
                      border: "1px solid #CED4DA",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderRadius: 5,
                      color: "#495057",
                    }}
                    value={experience.startTime}
                    onChange={(e) =>
                      setExperience({
                        ...experience,
                        startTime: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 0,
                  }}
                >
                  End Date
                </p>
                <div>
                  <input
                    type="date"
                    name="endDate"
                    id="endDate"
                    style={{
                      width: 137,
                      padding: 5,
                      border: "1px solid #CED4DA",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderRadius: 5,
                      color: "#495057",
                    }}
                    value={
                      moment(experience.endDate).utc().format().split("T")[0]
                    }
                    onChange={(e) =>
                      setExperience({ ...experience, endDate: e.target.value })
                    }
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <input
                    type="time"
                    name="endTime"
                    id="endTime"
                    style={{
                      width: 137,
                      padding: 5,
                      border: "1px solid #CED4DA",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderRadius: 5,
                      color: "#495057",
                    }}
                    value={experience.endTime}
                    onChange={(e) =>
                      setExperience({ ...experience, endTime: e.target.value })
                    }
                  />
                </div>
              </div>
            </ExperienceDetailsCol>
            <ExperienceDetailsCol
              className="experience-responsive-col"
              style={{ width: "24%", padding: 2 }}
            >
              <>
                <ExperienceDetailsCol Direction="column" style={{ padding: 2 }}>
                  <LabelExperience>Category</LabelExperience>

                  <SelectComponent
                    name="Select Category"
                    options={categories}
                    style={{
                      marginRight: 0,
                    }}
                    selectedValue={experienceDetails?.category?._id}
                    selected={true}
                    onChange={(e) => setCategoryStateId(e.target.value)}
                  />
                </ExperienceDetailsCol>
                <ExperienceDetailsCol Direction="column" style={{ padding: 2 }}>
                  <LabelExperience>Subcategory</LabelExperience>
                  <SelectComponent
                    name="Select Subcategory"
                    options={subCategories}
                    selectedValue={experienceDetails?.subCategory?._id}
                    style={{
                      marginRight: 0,
                    }}
                    selected={true}
                    onChange={(e) => setSubCategoryStateId(e.target.value)}
                  />
                </ExperienceDetailsCol>
              </>
            </ExperienceDetailsCol>
            <ExperienceDetailsCol
              className="experience-responsive-col"
              style={{ width: "28%", padding: 2 }}
            >
              <ExperienceDetailsCol
                style={{ padding: 11, fontSize: 20, fontWeight: 700 }}
              >
                <div>Or</div>
              </ExperienceDetailsCol>
              <ExperienceDetailsCol
                Direction="column"
                style={{
                  padding: 11,
                  marginRight: 11,
                  borderRightWidth: 2,
                  borderRightColor: "#a09696",
                  borderRightStyle: "solid",
                }}
              >
                <LabelExperience>Tags</LabelExperience>
                <SelectComponent
                  name="Tags"
                  options={""}
                  style={{
                    marginRight: 0,
                  }}
                  selected={true}
                  onChange={() => {}}
                />
              </ExperienceDetailsCol>
              <ExperienceDetailsCol Direction="column" style={{ padding: 2 }}>
                <LabelExperience>Education Phases</LabelExperience>
                <SelectComponent
                  name="education"
                  options={""}
                  style={{}}
                  selected={true}
                  onChange={() => {}}
                />
                <LabelExperience>Semester</LabelExperience>
                <SelectComponent
                  name="Semester"
                  options={""}
                  style={{}}
                  selected={true}
                  onChange={() => {}}
                />
              </ExperienceDetailsCol>
            </ExperienceDetailsCol>
          </ExperienceDatilsInfo>
          <ExperienceRow
            style={{ alignItems: "stretch", justifyContent: "flex-start" }}
          >
            <ExperienceDetailsCol
              style={{
                width: "27%",
                justifyContent: "flex-end",
              }}
            >
              <ExperienceCardDetails cardColor={experienceTypeDetails.color}>
                <ExperienceCardDetailsInfo>
                  <div className="experience-title">
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={experience.title}
                      title={experience.title}
                      style={{
                        width: "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      onChange={(e) =>
                        setExperience({ ...experience, title: e.target.value })
                      }
                    />
                  </div>
                  <div className="experience-title-subtitle">
                    <input
                      type="text"
                      name="subTitle"
                      id="subTitle"
                      value={experience.subTitle}
                      onChange={(e) =>
                        setExperience({
                          ...experience,
                          subTitle: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="experience-title-description">
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="10"
                      value={experience.description}
                      onChange={(e) =>
                        setExperience({
                          ...experience,
                          description: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div className="experience-title-link">
                    {textLink && (
                      <div className="experience-title-link-content">
                        <img src={TitleLinkImage} alt="gradegy" />
                        <p>
                          {textLinkTitle !== ""
                            ? textLinkTitle
                            : "Title for link"}
                        </p>
                      </div>
                    )}
                  </div>

                  <div>
                    {experienceImageLoading ? (
                      <h5>loading</h5>
                    ) : experienceImageError ? (
                      console.log(experienceImageError)
                    ) : (
                      <img
                        src={imgUrl}
                        alt="simple pic"
                        className="pick-pic"
                        onClick={() => {
                          setPicCard(true);
                        }}
                      />
                    )}
                  </div>
                </ExperienceCardDetailsInfo>
                <ExperienceCardDetailsAction>
                  <div className="experience-details-action">
                    <div className="xp">
                      <span>{experience.xpCompletion}</span>
                      <span>XP</span>
                    </div>
                    <div className="icon">
                      <img
                        className="exp-icon"
                        src={experienceTypeDetails.icon}
                        alt="experience item"
                      />
                    </div>
                  </div>
                  <div className="competion-message">
                    <div className="completion-action-container">
                      <p>{completionDetails.message}</p>
                      <button className="completion-button">
                        {completionDetails.buttonTitle}
                      </button>
                    </div>
                  </div>
                </ExperienceCardDetailsAction>
              </ExperienceCardDetails>
            </ExperienceDetailsCol>

            <ExperienceDetailsCol
              style={{ width: "25%" }}
              className={`${toggleBox.box1 ? "" : "boxActive"}`}
            >
              <div className="exp-setting motivational">
                <button
                  onClick={() => {
                    setToggleBox({ ...toggleBox, box1: !toggleBox.box1 });
                  }}
                >
                  <TabName
                    name="Motivational Design"
                    condition={toggleBox.box1}
                    left={-60}
                  />
                  <BsCaretRightFill color="black" size={20} />
                </button>
                <div className="motivational-title">Motivational Design</div>
                <ShadowBox>
                  <div className="xp-comp">
                    <span>XP for Completion</span>
                    <input
                      type="text"
                      placeholder="100-300"
                      id="xpCompletion"
                      name="xpCompletion"
                      value={experience.xpCompletion}
                      onChange={(e) =>
                        setExperience({
                          ...experience,
                          xpCompletion: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="xp-comp">
                    <span>XP for viewing</span>
                    <input
                      type="text"
                      placeholder="0-5"
                      name="xpView"
                      id="xpView"
                      value={experience.xpView}
                      onChange={(e) =>
                        setExperience({ ...experience, xpView: e.target.value })
                      }
                    />
                  </div>
                  <div className="xp-comp">
                    <span>Coins</span>
                    <input
                      type="text"
                      placeholder="-"
                      name="coins"
                      id="coins"
                      value={experience.coins}
                      onChange={(e) =>
                        setExperience({ ...experience, coins: e.target.value })
                      }
                    />
                  </div>
                </ShadowBox>
                <ShadowBox>
                  <div className="submition">
                    <p>
                      Submission <span>(completion type)</span>{" "}
                    </p>
                    <div>
                      <SelectComponent
                        style={{ fontSize: 15 }}
                        options={completions.data}
                        selectedValue={completions.data && completionId}
                        onChange={(e) => setCompletionId(e.target.value)}
                      />
                    </div>
                  </div>
                  {completionDetails?.name === "Link" && (
                    <input
                      type="text"
                      placeholder="Add URL"
                      name="experienceUrl"
                      id="experienceUrl"
                      className="dark-input"
                      value={buttonLink}
                      onChange={onChangeButtonLink}
                    />
                  )}
                  <div className="auto-complition">
                    <span className="auto-title">Auto Complition</span>
                    <CustomSwitch
                      isToggle={experience.autoCompletion}
                      onChangeToggle={() =>
                        setExperience({
                          ...experience,
                          autoCompletion: !experience.autoCompletion,
                        })
                      }
                    />
                  </div>
                </ShadowBox>
                <ShadowBox>
                  <div className="text-link" style={{ paddingRight: 50 }}>
                    <span className="text-link-title">Add Link in text</span>
                    <CustomSwitch
                      isToggle={textLink}
                      onChangeToggle={() => {
                        setTextLink((prevState) => {
                          setTextLink(!prevState);
                          if (prevState === false) {
                            setTextLinkTitle("");
                            setUrlTextLink("");
                          }
                        });
                      }}
                    />
                  </div>
                  {textLink && (
                    <>
                      <input
                        type="text"
                        placeholder="Title for link"
                        name="titleForLink"
                        id="titleForLink"
                        className="dark-input"
                        value={textLinkTitle}
                        onChange={onChangeUrlTextChange}
                      />
                      <input
                        type="text"
                        placeholder="Add URL"
                        name="addTitleUrl"
                        id="addTitleUrl"
                        className="dark-input"
                        value={urlTextLink}
                        onChange={onChangeUrlTextLink}
                      />
                    </>
                  )}
                </ShadowBox>
                <ShadowBox disable>
                  <div className="notification-Container">
                    <div className="notification-title-container">
                      <span className="notification-title">Notification</span>
                      <CustomSwitch />
                    </div>
                    <div className="notification-content-container">
                      <div className="start">
                        <span className="start-not">Start</span>
                        <CustomSwitch isToggle={false} />
                      </div>
                      <div className="mid">
                        <span className="mid-not">Mid</span>
                        <CustomSwitch isToggle={false} />
                      </div>
                      <div className="pass-due">
                        <span className="pass-due">Pass Due</span>
                        <CustomSwitch isToggle={false} />
                      </div>
                    </div>
                  </div>
                </ShadowBox>
              </div>
            </ExperienceDetailsCol>
            <ExperienceDetailsCol
              style={{ width: "21%" }}
              className={`${toggleBox.box2 ? "" : "boxActive"}`}
            >
              <div className="exp-setting motivational">
                <button
                  onClick={() => {
                    setToggleBox({ ...toggleBox, box2: !toggleBox.box2 });
                  }}
                >
                  <TabName name="Tags" left={0} condition={toggleBox.box2} />
                  <BsCaretRightFill color="black" size={20} />
                </button>
                <div className="motivational-title">Tags</div>
                <div className="driver">
                  <span>Driver</span>
                  <SelectComponent
                    style={{ fontSize: 15, marginTop: 5, width: "100%" }}
                    name="firstdriver"
                    options={firstDriverVal}
                    selectedValue={experience?.tags?.driver_one && firstDriver}
                    onChange={(e) => setFirstDriver(e.target.value)}
                  />

                  <SelectComponent
                    style={{ fontSize: 15, marginTop: 10, width: "100%" }}
                    options={secondDriverVal}
                    selectedValue={experience?.tags?.driver_two && secondDriver}
                    onChange={(e) => setSecondDriver(e.target.value)}
                  />
                </div>
                <div className="level">
                  <span>Level of Progresion</span>
                  <SelectComponent
                    style={{ fontSize: 15, marginTop: 5, width: "100%" }}
                    options={progressionLevel}
                    // selectedValue={completions.data && completionId}
                    onChange={(e) => setProgressionLevel(e.target.value)}
                  />
                </div>
                <div className="persona">
                  <span>Persona</span>
                  <SelectComponent
                    style={{ fontSize: 15, marginTop: 5, width: "100%" }}
                    options={persona}
                    //  selectedValue={completions.data && completionId}
                    onChange={(e) => setPersona(e.target.value)}
                  />
                </div>
                <div className="milestone mt-5">
                  <span className="milestone-title">Milestone</span>
                  <CustomSwitch
                    style={{ marginTop: 5, marginLeft: 30 }}
                    //  isToggle={milstone}
                    //  onChangeToggle={() =>
                    //    setMilstone(!milstone)
                    //   }
                  />
                </div>

                <div className="weight">
                  <span>Weight</span>
                  <div className="stars">
                    <StarBtn
                      onClick={() => firstStarHandle()}
                      stroke={firstStarClasses[0]}
                      fill={firstStarClasses[1]}
                    />
                    <StarBtn
                      onClick={() => secondStarHandle()}
                      stroke={secondStarClasses[0]}
                      fill={secondStarClasses[1]}
                    />
                    <StarBtn
                      onClick={() => thirdStarHandle()}
                      stroke={thirdStarClasses[0]}
                      fill={thirdStarClasses[1]}
                    />
                  </div>
                </div>
                <div className="difficulty">
                  <span className="difficulty-title">Difficulty</span>
                  <CustomSwitch
                    style={{ marginTop: 2, marginLeft: 30 }}
                    isToggle={showDifficulty}
                    onChangeToggle={() => setShowDifficulty(!showDifficulty)}
                  />
                </div>
                {showDifficulty ? (
                  <div className="fires">
                    <span className="fires-title">fire</span>
                    <div className="fire">
                      <FireBtn
                        onClick={() => firstFireHandle()}
                        stroke={firstFireClasses[0]}
                        fill={firstFireClasses[1]}
                      />
                      <FireBtn
                        onClick={() => secondFireHandle()}
                        stroke={secondFireClasses[0]}
                        fill={secondFireClasses[1]}
                      />
                      <FireBtn
                        onClick={() => thirdFireHandle()}
                        stroke={thirdFireClasses[0]}
                        fill={thirdFireClasses[1]}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </ExperienceDetailsCol>
            <ExperienceDetailsCol
              style={{ width: "27%" }}
              className={`${toggleBox.box3 ? "" : "boxActive"}`}
            >
              <div className="exp-setting motivational advance-tailoroad">
                <button
                  onClick={() => {
                    setToggleBox({ ...toggleBox, box3: !toggleBox.box3 });
                  }}
                >
                  <TabName
                    left={-20}
                    name="Lop Tags"
                    condition={toggleBox.box3}
                  />
                  <BsCaretRightFill color="black" size={20} />
                </button>
                <div className="motivational-title">Lop Tags</div>
              </div>
            </ExperienceDetailsCol>
          </ExperienceRow>
          <ExperienceRow>
          <div style={{display: "flex",alignItems: "center",backgroundColor: "#fff"}}>              
            <ExperienceButton
                style={{ display: "flex", alignItems: "center", marginLeft: 3, border: "2px solid #fff", fontSize:15 }}
                onClick={() =>
                  history.push(`/admin/experiences/review/${experience._id}`)
                  
                }
              >
                <span style={{ paddingRight: 5 }}>Review</span>
                <FaRegChartBar />
              </ExperienceButton>
           </div>
          <div className="edit_experience">
            {experience.publish ? (
              <button
                className="unpublish"
                name="unPublish"
                onClick={updatePublishHandler}
              >
                UnPublish
              </button>
            ) : (
              <button
                className="unpublish"
                name="publish"
                onClick={updatePublishHandler}
              >
                Publish
              </button>
            )}

            <button className="delete" onClick={() => setDeleteConfirm(true)}>
              <BsTrash color="white" size={28} />
            </button>
            <button
              className="update"
              name="update"
              onClick={updatePublishHandler}
            >
              Update
            </button>
          </div>
          </ExperienceRow>
      
          {picCard ? (
            <PickExperiencePicture
              data={experienceImages.data}
              close={() => setPicCard(false)}
              typeExper={experienceTypeDetails.name}
              changeImage={changeImage}
            />
          ) : null}
          {showConfirm ? (
            <Confirm message="EXPERIENCE CARD IS UPDATED SUCCESSFULY">
              <AnimationCheck />
            </Confirm>
          ) : null}
          {deleteconfirm ? (
            <DeleteConfirm
              onDelete={deleteExperienceHandler}
              id={experience._id}
              close={deleteCloseHanlder}
            />
          ) : null}
        </ExperienceDetailsContainer>
      )}
    </>
  );
};

export default withRouter(ExperienceEdit);
