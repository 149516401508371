import React, { useEffect, useState } from "react";
import {
  SettingTitleRow,
  SettingText,
  DriverText,
  DriverTitle,
  DriverRow,
  DriverColumn,
  DriverLabel,
  DriverTitleBox,
  DriverMainBox,
  DriverMainLeftBox,
  DriverMainTitle,
  DriverSaveButton,
  DriverTextArea,
} from "components/styled.components";
import { chartView } from "../../../../constants";
import SelectComponent from "components/control.component/select.component";
import GradegyDropdown from "components/gradegyDropDown";
import { useSelector, useDispatch } from "react-redux";
import {
  getTimeRangeStudentScoreAction,
  timeRangeStudentScoreAction,
} from "actions/timeRangeStudent";
import {
  getProgerssSettingAction,
  progressSettingAction,
} from "actions/progerssSettingAction";
import { getProjectDetailsAction, updateProjectAction } from "actions";

const options = [
  { _id: "all", value: "all", title: "All" },
  {
    _id: "dates-for-range",
    value: "dates-for-range",
    title: "Dates for Range",
  },
  { _id: "#-of-days", value: "#-of-days", title: "# of Days" },
];

const Learner = ({
  categoryOptions,
  onCategoryChanged,
  onStartDateChange,
  onEndDateChange,
  endDate,
  selfTags,
  onSelfTagsChange,
  futureTags,
  onFutureTagsChange,
  connectionTags,
  onConnectionTagsChange,
  emotionalTags,
  onEmotionalTagsChange,
  socialTags,
  onSocialTagsChange,
  financialTags,
  onChangeFinancialTags,
  academicTags,
  onAcademicTagsChange,
  situationalTags,
  onSituationalTags,
}) => {
  const [studentTiming, setStudentTiming] = useState("dates-for-range");
  const { selectedProjectId } = useSelector((state) => state.app);
  const [timeRangeValue, setTimeRangeValue] = useState({
    project: selectedProjectId,
    typeTime: studentTiming,
    days: 0,
    startDate: "",
    endDate: "",
    progressLabel: "",
    momentumLabel: "this is for momentum_labl",
    momentum_days: 15,
  });
  const { loading, errors, progresSetting } = useSelector(
    (state) => state.progressSetting
  );

  const {
    loading: projectLoading,
    errors: errorLoading,
    project,
  } = useSelector((state) => state.projectDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTimeRangeStudentScoreAction());
    dispatch(getProgerssSettingAction(selectedProjectId));
    dispatch(getProjectDetailsAction(selectedProjectId));
  }, []);

  useEffect(() => {
    console.log("time range", progresSetting);
    if (progresSetting) {
      const timeStudentFormat = {
        ...progresSetting,
        startDate: progresSetting
          ? progresSetting?.startDate?.toString().split("T")[0]
          : "",
        endDate: progresSetting
          ? progresSetting?.endDate?.toString().split("T")[0]
          : "",
        id: progresSetting._id,
      };
      console.log("time format", timeStudentFormat);
      setTimeRangeValue(timeStudentFormat);
      setStudentTiming(progresSetting.typeTime);
    }
  }, [loading, progresSetting]);
  const onChangeTimeRangeValueHandler = (e) => {
    setTimeRangeValue({
      ...timeRangeValue,
      [e.target.name]: e.target.value,
    });
  };

  const saveTimingSetting = async () => {
    console.log("project", selectedProjectId);
    dispatch(
      progressSettingAction({ ...timeRangeValue, project: selectedProjectId })
    );
  };
  return (
    <>
      <DriverMainBox style={{ marginTop: 0 }}>
        <DriverMainLeftBox>
          <div className="personal">
            <DriverTitle>Progress Score </DriverTitle>
            <DriverColumn Direction="row" alignment="flex-start">
              <DriverText>Change Lable </DriverText>
              <DriverTextArea
                value={timeRangeValue.progressLabel}
                onChange={(e) => {
                  setTimeRangeValue({
                    ...timeRangeValue,
                    progressLabel: e.target.value,
                  });
                }}
                placeholder="Progress score"
                style={{ marginLeft: 25, width: 230 }}
              />
            </DriverColumn>
            <DriverRow style={{ marginTop: -10, justifyContent: "flex-start" }}>
              <DriverColumn>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 0,
                  }}
                >
                  Timing
                </p>
                <div style={{ minWidth: 120 }}></div>

                <SelectComponent
                  options={options}
                  name="timing"
                  selectedValue={timeRangeValue.typeTime}
                  style={{ paddingLeft: 5, paddingTop: 5, paddingBottom: 5 }}
                  onChange={(e) => {
                    setTimeRangeValue({
                      ...timeRangeValue,
                      typeTime: e.target.value,
                    });
                    setStudentTiming(e.target.value);
                  }}
                />
              </DriverColumn>

              {studentTiming === "#-of-days" && (
                <>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Day Ago
                    </p>
                    <input
                      style={{
                        width: 40,
                        textAlign: "center",
                        marginTop: -2,
                        color: "#FF3135",
                      }}
                      type="text"
                      placeholder="15"
                      id="days"
                      name="days"
                      value={timeRangeValue.days}
                      onChange={onChangeTimeRangeValueHandler}
                    />
                  </DriverColumn>
                </>
              )}

              {studentTiming === "dates-for-range" && (
                <>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Start Date
                    </p>
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      style={{
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={timeRangeValue.startDate}
                      onChange={onChangeTimeRangeValueHandler}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      End Date
                    </p>
                    <input
                      type="date"
                      name="endDate"
                      id="endDate"
                      style={{
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={timeRangeValue.endDate}
                      onChange={onChangeTimeRangeValueHandler}
                    />
                  </DriverColumn>
                </>
              )}
            </DriverRow>
          </div>

          <div className="connection-score" style={{ marginTop: "18%" }}>
            <DriverColumn style={{ paddingLeft: 0, paddingTop: "10%" }}>
              <DriverTitle>Momentum Score </DriverTitle>
              <DriverColumn Direction="row" alignment="flex-start">
                <DriverText>Change Lable </DriverText>
                <DriverTextArea
                  value={timeRangeValue.momentumLabel}
                  onChange={(e) => {
                    setTimeRangeValue({
                      ...timeRangeValue,
                      momentumLabel: e.target.value,
                    });
                  }}
                  placeholder="Momentum score"
                  style={{ marginLeft: 25, width: 230 }}
                />
              </DriverColumn>
              <DriverColumn style={{ marginLeft: 10 }}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 7,
                  }}
                >
                  Days Ago
                </p>
                <input
                  style={{
                    width: 40,
                    textAlign: "center",
                    marginTop: -2,
                    color: "#9DA4AC",
                  }}
                  type="text"
                  placeholder="-"
                  id="daysAgo"
                  name="daysAgo"
                  value={timeRangeValue.momentum_days}
                  onChange={(e) => {
                    setTimeRangeValue({
                      ...timeRangeValue,
                      momentum_days: parseInt(e.target.value),
                    });
                  }}
                />
              </DriverColumn>
            </DriverColumn>
          </div>
        </DriverMainLeftBox>

        <DriverMainLeftBox>
          <div className="key">
            <DriverColumn>
              <DriverTitle>Charts View </DriverTitle>
              <SelectComponent
                options={chartView}
                selectedValue={project?.chartView}
                onChange={(e) => {
                  dispatch(
                    updateProjectAction(selectedProjectId, {
                      chartView: e.target.value,
                    })
                  );
                }}
                style={{
                  paddingTop: 6,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 6,
                  minWidth: 129,
                  fontSize: 10,
                  backgroundPosition: "bottom 10px right 6px",
                }}
              />
            </DriverColumn>
          </div>
        </DriverMainLeftBox>
      </DriverMainBox>
      <DriverColumn
        Direction="row"
        alignment="center"
        style={{ marginTop: "8%" }}
      >
        <DriverSaveButton
          style={{ marginRight: "5%" }}
          onClick={saveTimingSetting}
        >
          SAVE
        </DriverSaveButton>
        <DriverSaveButton onClick={() => {}}>Cancel</DriverSaveButton>
      </DriverColumn>
    </>
  );
};

export default Learner;
