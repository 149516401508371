import React, { useCallback, useRef } from "react";
import {
  CreateRaffleFormCol,
  CreateRaffleContainer,
  CreateRaffleForm,
  CreateRaffleFormRow,
  RaffleInput,
  LabelRaffle,
  SwitchLabelRaffle,
  RaffleImage,
  RaffleEditButtton,
  RaffleEditButttonText,
  RaffleEditButttonIcon,
  RaffleButon,
  Spinner,
  ImagContainer,
  DateWrapper,
} from "./index.style";
import { MdEdit } from "react-icons/md";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import SelectComponent from "components/control.component/select.component";
const RaffleAddItemPopup = ({
  raffleImage,
  onChangeItemImg,
  isStore,
  onStoreChange,
  isPrizeWheel,
  onPrizeWheelChange,
  title,
  onTitleChanged,
  winnerNumber,
  onWinnerNumberChange,
  startDate,
  onStartDateChange,
  startTime,
  onStartTimeChange,
  closeDate,
  onCloseDateChange,
  closeTime,
  onCloseTimeChange,
  drawingTime,
  onDrawingTimeChange,
  drawingDate,
  onDrawingDateChange,
  gemsNumber,
  onGemsNumberChanged,
  description,
  onDescriptionChanged,
  onSaveRaffleClick,
  onCancelClick,
  categories,
  onChangeCategory,
}) => {
  const raffleItemImgInputRef = useRef();
  const handleOnEditImgClicked = useCallback(() => {
    if (raffleItemImgInputRef) raffleItemImgInputRef.current.click();
  }, []);
  return (
    <CreateRaffleContainer className="form-Raffle-add">
      <CreateRaffleForm>
        <div style={{ display: "flex" }}>
          <CreateRaffleFormRow
            style={{ paddingLeft: 20, width: "auto", flexDirection: "column" }}
          >
            <CreateRaffleFormCol
              alignment="flex-start"
              style={{ paddingLeft: 0, position: "relative" }}
            >
              <ImagContainer>
                {raffleImage?.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <RaffleImage
                      src={
                        raffleImage?.url ||
                        "https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg"
                      }
                      alt="gradegy"
                    />
                  </>
                )}
              </ImagContainer>
              <input
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                ref={raffleItemImgInputRef}
                onChange={onChangeItemImg}
              />
              <RaffleEditButtton onClick={handleOnEditImgClicked}>
                <RaffleEditButttonText>Edit</RaffleEditButttonText>
                <RaffleEditButttonIcon>
                  <MdEdit color="#34C38F" size={12} />
                </RaffleEditButttonIcon>
              </RaffleEditButtton>
            </CreateRaffleFormCol>

            <CreateRaffleFormCol
              Direction="column"
              style={{ paddingBottom: 10, paddingTop: 20 }}
            >
              <LabelRaffle style={{ fontSize: 18 }}>Available in:</LabelRaffle>
            </CreateRaffleFormCol>
            <CreateRaffleFormCol
              Direction="row"
              style={{ flexDirection: "column", paddingLeft: 25 }}
            >
              <CreateRaffleFormCol
                Direction="row"
                style={{
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  paddingBottom: 10,
                }}
              >
                <SwitchLabelRaffle>Store</SwitchLabelRaffle>
                <CustomSwitch
                  isToggle={isStore}
                  onChangeToggle={onStoreChange}
                />
              </CreateRaffleFormCol>
              <CreateRaffleFormCol
                Direction="row"
                style={{ justifyContent: "space-between", maxWidth: "100%" }}
              >
                <SwitchLabelRaffle>Prize Wheel</SwitchLabelRaffle>
                <CustomSwitch
                  isToggle={isPrizeWheel}
                  onChangeToggle={onPrizeWheelChange}
                />
              </CreateRaffleFormCol>
            </CreateRaffleFormCol>
          </CreateRaffleFormRow>

          <CreateRaffleFormRow style={{ flexDirection: "column" }}>
            <CreateRaffleFormCol style={{ justifyContent: "space-between" }}>
              <CreateRaffleFormCol
                style={{
                  paddingRight: 5,
                  paddingLeft: 12,
                  marginTop: 20,
                  flexWrap: "wrap",
                  maxWidth: 360,
                  height: 100,
                }}
              >
                <CreateRaffleFormCol
                  Direction="column"
                  style={{ width: "100%" }}
                >
                  <LabelRaffle>Item Title</LabelRaffle>
                  <RaffleInput
                    Width="100%"
                    Height="39px"
                    FontFamily="Rajdhani"
                    name="itemName"
                    placeholder="item name"
                    value={title}
                    onChange={onTitleChanged}
                  />
                </CreateRaffleFormCol>
              </CreateRaffleFormCol>

              <CreateRaffleFormCol
                style={{
                  paddingRight: "25%",
                  paddingLeft: 15,
                  marginTop: 20,
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                <CreateRaffleFormCol Direction="column">
                  <LabelRaffle>Gem number</LabelRaffle>
                  <RaffleInput
                    Width="100%"
                    Height="39px"
                    FontFamily="Rajdhani"
                    name="Gems"
                    placeholder="Gems "
                    value={gemsNumber}
                    onChange={onGemsNumberChanged}
                  />
                </CreateRaffleFormCol>
              </CreateRaffleFormCol>
            </CreateRaffleFormCol>
            <CreateRaffleFormCol style={{ flexDirection: "row" }}>
              <LabelRaffle style={{ fontSize: 18, marginRight: 20 }}>
                Number of Winners
              </LabelRaffle>
              <RaffleInput
                Width="40%"
                Height="39px"
                FontFamily="Rajdhani"
                name="numberOfWinner"
                placeholder="-"
                value={winnerNumber}
                onChange={onWinnerNumberChange}
              />
            </CreateRaffleFormCol>
            <CreateRaffleFormCol style={{ flexDirection: "column" }}>
              <DateWrapper>
                <div style={{ paddingRight: 10 }}>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 15,
                      marginBottom: 0,
                    }}
                  >
                    Start Date
                  </p>
                  <div>
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      style={{
                        width: 137,
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={startDate}
                      onChange={onStartDateChange}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <input
                      type="time"
                      name="startTime"
                      id="startTime"
                      style={{
                        width: 137,
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={startTime}
                      onChange={onStartTimeChange}
                    />
                  </div>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 15,
                      marginBottom: 0,
                    }}
                  >
                    Close Date
                  </p>
                  <div>
                    <input
                      type="date"
                      name="closeDate"
                      id="closeDate"
                      style={{
                        width: 137,
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={closeDate}
                      onChange={onCloseDateChange}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <input
                      type="time"
                      name="closeTime"
                      id="closeTime"
                      style={{
                        width: 137,
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={closeTime}
                      onChange={onCloseTimeChange}
                    />
                  </div>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 15,
                      marginBottom: 0,
                    }}
                  >
                    Drawing Date
                  </p>
                  <div>
                    <input
                      type="date"
                      name="drawDate"
                      id="drawDate"
                      style={{
                        width: 137,
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={drawingDate}
                      onChange={onDrawingDateChange}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <input
                      type="time"
                      name="darwTime"
                      id="drawTime"
                      style={{
                        width: 137,
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={drawingTime}
                      onChange={onDrawingTimeChange}
                    />
                  </div>
                </div>
              </DateWrapper>
              <CreateRaffleFormCol>
                <CreateRaffleFormCol Direction="column">
                  <LabelRaffle>Category</LabelRaffle>

                  <SelectComponent
                    style={{ width: "100%", height: 35 }}
                    options={categories}
                    name="category"
                    selected={true}
                    onChange={(e) => {
                      onChangeCategory(e.target.value);
                    }}
                  />
                </CreateRaffleFormCol>

                <CreateRaffleFormCol Direction="column">
                  <LabelRaffle>Sub Category</LabelRaffle>
                  <SelectComponent
                    style={{ width: "85%", height: 35 }}
                    options={""}
                    name="subCategory"
                    selected={true}
                    onChange={(e) => {}}
                  />
                </CreateRaffleFormCol>
              </CreateRaffleFormCol>
            </CreateRaffleFormCol>
            <CreateRaffleFormCol style={{ flexDirection: "column" }}>
              <LabelRaffle>Description</LabelRaffle>
              <textarea
                style={{ width: "95%", minHeight: 80, borderColor: "#e5e9ec" }}
                value={description}
                onChange={onDescriptionChanged}
              />
            </CreateRaffleFormCol>
          </CreateRaffleFormRow>
        </div>
        <CreateRaffleFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 30,
            marginBottom: 10,
            justifyContent: "center",
          }}
        >
          <CreateRaffleFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <RaffleButon
              onClick={onSaveRaffleClick}
              style={{ padding: "12px 50px" }}
            >
              save
            </RaffleButon>
          </CreateRaffleFormCol>
          <CreateRaffleFormCol style={{ paddingLeft: 30 }}>
            <RaffleButon
              style={{ padding: "12px 50px" }}
              onClick={onCancelClick}
            >
              Cancel
            </RaffleButon>
          </CreateRaffleFormCol>
        </CreateRaffleFormRow>
      </CreateRaffleForm>
    </CreateRaffleContainer>
  );
};

export default RaffleAddItemPopup;
