import React, { useState } from "react";
import {
  ContentHeader,
  ContentHeaderTitle,
  OrganizationsContainer,
  OrganizationsHeaderControle,
  SearchOrganizations,
  OrganizationsButton,
  OrganizationsContentContainer,
  SearchOrganizationsContainer,
} from "../components/styled.components";
import WarningPopup from "../components/warningPopup/WarningPopup";

import { MdAddCircle } from "react-icons/md";
import { IoBriefcaseSharp } from "react-icons/io5";
import { BiSearchAlt } from "react-icons/bi";
import OrganizationsTable from "../components/organizations/OrganizationsTable";
import AddOrganizationComponent from "../components/organizations/AddOrganizations";

const Organization = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormWarning, setShowFormWarning] = useState(false);

  const closeForm = () => {
    setShowForm(false);
  };
  const closeWarningForm = () => {
    setShowFormWarning(false);
  };

  const closeWarning = () => {
    setShowFormWarning(false);
  };

  return (
    <>
      <ContentHeader style={{padding:0,height:0}}>
        <ContentHeaderTitle>Organizations </ContentHeaderTitle>
      </ContentHeader>
      <OrganizationsContainer className="gradegy-organization">
        <OrganizationsHeaderControle>
          <div style={{ display: "flex", alignItems: "center" , marginTop: 0, paddingLeft:30}}>
            <SearchOrganizationsContainer>
              <BiSearchAlt
                spaceLeft="18px"
                style={{ position: "absolute", top: 15, left: 41 }}
                color={"#707070"}
              />
              <SearchOrganizations
                type="text"
                placeholder="Search Organizations"
              />
            </SearchOrganizationsContainer>

            <OrganizationsButton
              style={{
                marginTop: 10,
                paddingRight: 4,
                paddingBottom: 6,
                fontSize: 16,
                marginLeft: "9%",
              }}
              spaceLeft="50px"
              onClick={() => {
                setShowForm(true);
              }}
            >
              {" "}
              <span style={{ paddingRight: 18 }}>Add Organizations</span>{" "}
              <MdAddCircle size={32} />
            </OrganizationsButton>
            {showForm ? <AddOrganizationComponent close={closeForm} /> : null}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "23%",
            }}
          >
            <OrganizationsButton
              style={{ borderRadius: 50, marginRight: 10, marginLeft: 10 , marginTop: 10}}
              onClick={() => {
                setShowFormWarning(true);
              }}
            >
              <span style={{ paddingRight: 20, fontSize: 18 }}>Admin</span>
              <IoBriefcaseSharp style={{ marginRight: 5 }} size={30} />
            </OrganizationsButton>
            {showFormWarning ? (
              <WarningPopup
                close={closeWarningForm}
                text={"Clear All Changes"}
                reject={closeWarning}
                accept={() => {}}
              />
            ) : null}
          </div>
        </OrganizationsHeaderControle>

        <OrganizationsContentContainer>
          <OrganizationsTable />
        </OrganizationsContentContainer>
      </OrganizationsContainer>
    </>
  );
};

export default Organization;
