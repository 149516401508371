import React, { useEffect, useState } from 'react'
import './student.style.css';
import shiled from '../../assets/image/profile-level.png';
const StudentXp = ({xp}) => {
    const [level,setLevel] = useState(0);
    const [percent,setPercent] = useState(0);
    useEffect(() => {
        const result = xp / 3500;
        const levelNumber = Math.floor(result);
        setLevel(levelNumber);
        const percentProgress = (result - levelNumber).toFixed(2) * 100;
        setPercent(percentProgress);

    },[xp]);
    return (
        <div className="student-xp">
            <div className="shiled">
                <img src={shiled} alt="shild" />
                <h3>
                    <p style={{fontSize:17}}>Level</p>
                    <p style={{fontSize:40, lineHeight:'37px'}}>{level}</p>
                </h3>
            </div>
            <div className="xp-content">
                <h1 className="xp-content-title">{xp} XP</h1>
                <div className="xp-progress-container">
                        <div className="progress-xp" style={{width:`${percent}%`}}></div>
                </div>
            </div>
        </div>
    )
}

export default StudentXp
