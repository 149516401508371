export const removeItemList = (userId, projectId) => {
  const dataList = window.localStorage.getItem(`${userId}-RECENTPROJECTS`);
  if (!dataList) {
    return;
  }
  const userData = JSON.parse(dataList);
  if (userData) {
    delete userData[projectId];
    window.localStorage.setItem(
      `${userId}-RECENTPROJECTS`,
      JSON.stringify(userData)
    );
  }
};
// set project to local storge
export const setItemList = (userId, project) => {
  const item = project;
  const dataList = window.localStorage.getItem(`${userId}-RECENTPROJECTS`);
  let data;
  if (!dataList) {
    data = { [project._id]: item };
    window.localStorage.setItem(
      `${userId}-RECENTPROJECTS`,
      JSON.stringify(data)
    );
  } else {
    const x = JSON.parse(dataList);
    data = { ...x, [project._id]: item };
    window.localStorage.setItem(
      `${userId}-RECENTPROJECTS`,
      JSON.stringify(data)
    );
  }
};
const removeExpiredQuickAccessAssets = (userId) => {
  const projectList = window.localStorage.getItem(`${userId}-RECENTPROJECTS`);
  if (!projectList) {
    return [];
  }
  const itemList = JSON.parse(projectList);
  const content = {};
  if (itemList) {
    Object.values(itemList).forEach((item, index) => {
      if (Object.values(itemList).length > 3) {
        removeItemList(userId, Object.keys(itemList)[0]);
      } else {
        content[item._id] = { ...item };
      }
      return content;
    });
  }
  return content;
};
export const getListItem = (userId) => {
  const projectList = removeExpiredQuickAccessAssets(userId);
  return projectList;
};
