import {
  SHOPITEM_DETAIL_TOAST_MESSAGE,
  SET_SHOP_LIST,
  SET_SHOPITEM_DETAIL_PAGE_STATUS,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const deleteShopItemAction = (id, history) => async (dispatch) => {
  const selectedOrganizationId = store.getState().app.selectedOrganizationId;

  try {
    const token = store.getState().auth.token;
    const shopItemId = store.getState().shopItemDetail.shopItemDetail?._id;
    if (shopItemId) {
      await gradify.delete(`shopitems/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
        payload: "SHOP_MESSAGE_POPUP",
      });
      dispatch({
        type: SHOPITEM_DETAIL_TOAST_MESSAGE,
        payload: {
          message: "DELETE ITEM UPDATE SUCCESSFULY",
          hasError: false,
        },
      });

      const { data } = await gradify.get(
        `shopitems?limit=1000${
          selectedOrganizationId
            ? `&organization=${selectedOrganizationId}`
            : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: SET_SHOP_LIST,
        payload: data.data,
      });
      // dispatch({
      //   type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
      //   payload: "DEFAULT",
      // });
      setTimeout(() => {
        // dispatch({
        //   type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
        //   payload: "DEFAULT",
        // });
        dispatch({
          type: SHOPITEM_DETAIL_TOAST_MESSAGE,
          payload: { message: "", hasError: false },
        });
        history.push("/admin/shop");
      }, 2000);
    }
  } catch (error) {
    console.log(error);
    // dispatch({
    //   type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
    //   payload: "DEFAULT",
    // });
    // what's in error?
  }
};
