import React from "react";
import Header from "./header";
import LeftColumn from "./leftColumn";
import CenterColumn from "./CenterColumn";
import StudentListContainer from "containers/Raffle/RaffleSystem/StudentList";
import TrashIcon from "assets/image/trashIcon.png";
import {
  Wrapper,
  ColumnWrapper,
  ButtonWrapper,
  DeleteBtn,
  UpdateBtn,
} from "./index.style";
import { allAppCategoriesSelector } from "store/selectors";
import { useSelector } from "react-redux";

const RaffleSystem = ({
  image,
  title,
  onChangeItemImg,
  onTitleInputChange,
  gemsNumber,
  leftTime,
  description,
  onDescriptionChange,
  onDeleteItemClick,
  onUpdateItemClick,
  pageStatus,
  onGemsNumberChange,
  winnerNumber,
  onWinnerNumberChange,
  isStore,
  onStoreChange,
  isPrizeWheel,
  onPrizeWheelChange,
  startDate,
  onStartDateChange,
  startTime,
  onStartTimeChange,
  closeDate,
  onCloseDateChange,
  closeTime,
  onCloseTimeChange,
  drawDate,
  onDrawDateChange,
  drawTime,
  onDrawTimeChange,
  winnersName,
  soldNum,
  onEditInventoryHandler,
  raffleId,
  onChangeSelectCategory,
  selectCategory,
  purchaseState,
  purchaseLimit,
  onChangePurchaseState,
  onChagnePurchaseLimit,
}) => {
  const categories = useSelector(allAppCategoriesSelector);
  return (
    <Wrapper>
      <Header
        headerTitle="Raffle Tickets"
        startDate={startDate}
        onStartDateChange={onStartDateChange}
        startTime={startTime}
        onStartTimeChange={onStartTimeChange}
        closeDate={closeDate}
        onCloseDateChange={onCloseDateChange}
        closeTime={closeTime}
        onCloseTimeChange={onCloseTimeChange}
        drawDate={drawDate}
        onDrawDateChange={onDrawDateChange}
        drawTime={drawTime}
        onDrawTimeChange={onDrawTimeChange}
        winnersName={winnersName}
        soldNum={soldNum}
        leftTime={leftTime}
        categories={categories}
        onChangeSelectCategory={onChangeSelectCategory}
        selectCategory={selectCategory}
      />
      <ColumnWrapper>
        <LeftColumn
          image={image}
          title={title}
          onChangeItemImg={onChangeItemImg}
          onTitleInputChange={onTitleInputChange}
          gemsNumber={gemsNumber}
          leftTime={leftTime}
          description={description}
          onDescriptionChange={onDescriptionChange}
        />
        <CenterColumn
          gemsNumber={gemsNumber}
          onGemsNumberChange={onGemsNumberChange}
          winnerNumber={winnerNumber}
          onWinnerNumberChange={onWinnerNumberChange}
          isStore={isStore}
          onStoreChange={onStoreChange}
          isPrizeWheel={isPrizeWheel}
          onPrizeWheelChange={onPrizeWheelChange}
          onEditInventoryHandler={onEditInventoryHandler}
          purchaseState={purchaseState}
          purchaseLimit={purchaseLimit}
          onChangePurchaseState={onChangePurchaseState}
          onChagnePurchaseLimit={onChagnePurchaseLimit}
        />
        <StudentListContainer raffleId={raffleId} />
      </ColumnWrapper>
      <ButtonWrapper>
        <DeleteBtn onClick={onDeleteItemClick}>
          <img src={TrashIcon} alt="trash"></img>
        </DeleteBtn>
        <UpdateBtn onClick={onUpdateItemClick}>
          {pageStatus === "UPDATE_SAVING" ? "Updating" : "Update"}
        </UpdateBtn>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default RaffleSystem;
