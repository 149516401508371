import { gradify } from "axios.config";
import {
  TIME_STUDENT_SCORES_REQUEST,
  TIME_STUDENT_SCORES_SUCCESS,
  TIME_STUDENT_SCORES_FAILD,
} from "../../constant/timescore";

export const timeRangeStudentScoreAction = (timeRange) => async (dispatch) => {
  dispatch({
    type: TIME_STUDENT_SCORES_REQUEST,
  });

  try {
    const { data } = await gradify.put(`timescore/edit`, timeRange);
    dispatch({
      type: TIME_STUDENT_SCORES_SUCCESS,
      payload: data.data[0],
    });
  } catch (error) {
    dispatch({
      type: TIME_STUDENT_SCORES_FAILD,
      payload: error.message,
    });
  }
};

export const getTimeRangeStudentScoreAction = () => async (dispatch) => {
  dispatch({
    type: TIME_STUDENT_SCORES_REQUEST,
  });

  try {
    const { data } = await gradify.get(`timescore`);

    dispatch({
      type: TIME_STUDENT_SCORES_SUCCESS,
      payload: data.data[0],
    });
  } catch (error) {
    dispatch({
      type: TIME_STUDENT_SCORES_FAILD,
      payload: error.message,
    });
  }
};
