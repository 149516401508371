import React from "react";
import ReactApexChart from "react-apexcharts";
import { RadarChartContainer } from "../styled.components/student.details.style";

const RadarChart = ({
  color,
  futureFocused,
  socialInfluence,
  meaning,
  ownership,
  tribe,
  accomplishment,
  risk,
  self_efficacy,
}) => {
  const config = {
    options: {
      yaxis: {
        show: false,
        max: 10,
      },

      xaxis: {
        categories: [
          "Future Focused",
          "Social Influence",
          "Meaning",
          "Ownership",
          "Tribe",
          "Accomplishments",
          "Risk",
          "Self-Efficacy",
        ],
        labels: {
          show: true,
          style: {
            colors: [
              "#495057",
              "#495057",
              "#495057",
              "#495057",
              "#495057",
              "#495057",
              "#495057",
              "#495057",
            ],
            fontSize: "16px",
            fontFamily: "sans-serif",
          },
        },
      },

      colors: [color, color],
      markers: {
        size: 0,
      },

      fill: {
        opacity: 0.3,
        colors: color,
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          width: 300,
          height: 300,
        },
      },
    ],
    series: [
      {
        data: [
          futureFocused,
          socialInfluence,
          meaning,
          ownership,
          tribe,
          accomplishment,
          risk,
          self_efficacy,
        ],
      },
    ],
  };

  return (
    <RadarChartContainer Color={color}>
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="radar"
        height="550"
        width={600}
      />
    </RadarChartContainer>
  );
};

export default RadarChart;
