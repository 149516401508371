import styled from 'styled-components';

import {
  EditButton,
  EditButtonText,
  EditButtonIcon,
} from "assets/style/General.style";
export const EditButtonAccess=styled(EditButton)`
display:${({isAccessEditting}) => isAccessEditting && 'none'}
`
export const EditButtonTextAccess=styled(EditButtonText)``
export const EditButtonIconAccess=styled(EditButtonIcon)``