import { SEND_FORGOT_PASSWORD_CODE } from '../../constant/forgotpassword';

import {produce} from 'immer';

const INITIAL_STATE = {
    code:null,
    email:null
};


export const forgotPassowrdReducer = (state=INITIAL_STATE,action) => produce(state,(draft) => {
    switch(action.type){
        case SEND_FORGOT_PASSWORD_CODE:
            draft.code = action.payload.code;
            draft.email = action.payload.email
    }
})