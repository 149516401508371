import React from "react";
import { CircleIcon } from "./index.style";
import { IoMdAdd } from "react-icons/io";
import { FaLock } from "react-icons/fa";
const CircleButton = ({ isDisabled, style, onClick }) => {
  return (
    <CircleIcon style={style} onClick={onClick}>
      {isDisabled ? (
        <FaLock color="#fff" size={14} />
      ) : (
        <IoMdAdd color="#fff" size={14} />
      )}
    </CircleIcon>
  );
};

export default CircleButton;
