import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import "./studentTable.style.css";
import BottomSelected from "assets/image/bottomSelected.png";
import TopSelected from "assets/image/topSelected.png";
import Unselected from "assets/image/unselected.png";
import { withStyles } from "@material-ui/core/styles";
import StudentItem from "./StudentItem";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import { gradify } from "axios.config";
const StudentList = ({
  users,
  ChagneSelectedUser,
  changeAllSelected,
  organizationId,
  projectId,
  category,
  subCategory,
  onSortStudents,
  sortType,
}) => {
  const [selectedItem, setSelectedItem] = useState(false);
  const [toggleScore, setToogleScore] = useState(false);
  const changeSelectedItemHandler = () => {
    setSelectedItem(!selectedItem);
  };
  useEffect(() => {
    if (selectedItem) {
      const selectedArray = users.map((item) => item._id);

      changeAllSelected(selectedArray);
    } else {
      changeAllSelected([]);
    }
  }, [selectedItem]);

  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  return (
    <Table className="student-table-container" style={{ width: "100%" }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }}>
            <CustomColorCheckbox
              color="default"
              size="small"
              checked={selectedItem}
              onChange={changeSelectedItemHandler}
            />
          </TableCell>
          <TableCell style={{ width: 14 }}></TableCell>
          <TableCell
            style={{ cursor: "pointer", textAlign: "left " }}
            onClick={() => {
              onSortStudents("fullName");
            }}
          >
            <div style={{ width: "100%", textAlign: "left " }}>
              Name
              {sortType?.type === "fullName" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </div>
          </TableCell>

          <TableCell>Status</TableCell>

          <TableCell
            onClick={() => {
              onSortStudents("scores.studentScore");
            }}
          >
            Student Score
            {sortType?.type === "scores" ? (
              sortType?.isReversed ? (
                <img src={BottomSelected} alt="" className="sortIcon" />
              ) : (
                <img src={TopSelected} alt="" className="sortIcon" />
              )
            ) : (
              <img src={Unselected} alt="" className="sortIcon" />
            )}
            {/* <CustomSwitch
              isToggle={toggleScore}
              onChangeToggle={async () => {
                if (!toggleScore === true) {
                  await gradify.get(
                    `scores/calcAll?project=${projectId}&progressScore=${50}&gritScore=${null}&selfEfficacySRscore=${null}&futureDevSRscore=${null}&connectSRScore=${null}&emoSRScore=${null}&phq_9Score=${null}&socialSRScore=${null}&situationalSRScore=${null}&situationalDataScore=${null}&academicSRScore=${null}&academicDataScore=${null}&financialSRScore=${null}&financialDataScore=${null}&gritScore=${null}`
                  );
                  setToogleScore(false);
                } else {
                  setToogleScore(true);
                }
              }}
            /> */}
          </TableCell>
          <TableCell
            onClick={() => {
              onSortStudents("totalNotComplete");
            }}
          >
            Not Completed Task
            {sortType?.type === "totalNotComplete" ? (
              sortType?.isReversed ? (
                <img src={BottomSelected} alt="" className="sortIcon" />
              ) : (
                <img src={TopSelected} alt="" className="sortIcon" />
              )
            ) : (
              <img src={Unselected} alt="" className="sortIcon" />
            )}
          </TableCell>
          <TableCell
            onClick={() => {
              onSortStudents("totalPassDue");
            }}
          >
            Past Due
            {sortType?.type === "totalPassDue" ? (
              sortType?.isReversed ? (
                <img src={BottomSelected} alt="" className="sortIcon" />
              ) : (
                <img src={TopSelected} alt="" className="sortIcon" />
              )
            ) : (
              <img src={Unselected} alt="" className="sortIcon" />
            )}
          </TableCell>
          <TableCell
            onClick={() => {
              onSortStudents("projectXpValue");
            }}
          >
            Level
            {sortType?.type === "projectXpValue" ? (
              sortType?.isReversed ? (
                <img src={BottomSelected} alt="" className="sortIcon" />
              ) : (
                <img src={TopSelected} alt="" className="sortIcon" />
              )
            ) : (
              <img src={Unselected} alt="" className="sortIcon" />
            )}
          </TableCell>
          <TableCell
            style={{ cursor: "pointer" }}
            onClick={() => {
              onSortStudents("projectXpValue");
            }}
          >
            XP
            {sortType?.type === "projectXpValue" ? (
              sortType?.isReversed ? (
                <img src={BottomSelected} alt="" className="sortIcon" />
              ) : (
                <img src={TopSelected} alt="" className="sortIcon" />
              )
            ) : (
              <img src={Unselected} alt="" className="sortIcon" />
            )}
          </TableCell>
          <TableCell>Award Points</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users?.map((user) => (
          <StudentItem
            key={user._id}
            ChagneSelectedUser={ChagneSelectedUser}
            selectedAll={selectedItem}
            organizationId={organizationId}
            projectId={projectId}
            category={category}
            subCategory={subCategory}
            student={user}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default StudentList;
