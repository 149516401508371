import styled from "styled-components";
import { Link } from "react-router-dom";




export const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  margin-left: 0;
  margin-right: 0;
`;

export const LayoutSidebar = styled.aside`
  background-color: ${({ theme }) => theme.colors.dark};
  height: 100vh;
  padding: 16px;
  position: fixed;
  display: flex;
  width: 253px;
  z-index: 9999;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1300px) {
    width: 190px;
    padding:12px;
  }
`;

export const LayoutContent = styled.div`
  min-height: 100vh;
  //    background-color:#F8F8FB;
  margin-left: 253px;
  width:  calc(100vw - 253px);
  padding: 0;
  @media (max-width: 1300px) {
    margin-left: 190px;
    width:  calc(100vw - 190px);
  }
`;

export const LayoutSidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LayoutSidebarUpperText = styled.p`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: ${({ theme }) => theme.fontSizes.plarge};
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 0;
  text-align: center;
  @media (max-width: 1300px) {
    font-size:13px;
  }
`;

export const SpanSidebar = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.pexlarge};
  font-weight: bold;
  @media (max-width: 1300px) {
    font-size:15px;
  }
`;

export const Logo = styled.img`
  width: 93%;
`;

export const LayoutHeader = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  height: 68px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

export const LayoutHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  padding: 0 ${({ spacing }) => spacing + "px"};
  cursor: pointer;
`;

export const Flag = styled.img`
  width: 24.83px;
  height: 18px;
`;

export const ProfileImage = styled.img`
  width: 37px;
  height: 37px;
`;

export const ProfileInfo = styled.span`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: ${({ theme }) => theme.fontSizes.plsmall};
  color: #707070;
  font-size: 13px;
  font-weight: 400;
`;

export const LayoutSidebarMenu = styled.ul`
  width: 100%;
  list-style: none;
  padding-left: 8px;
  height: 100%;
  padding-top: 90px;
  @media (max-width: 1300px) {
    padding-top: 40px;
  }
`;

export const LayoutSidebarMenuOption = styled.li`
  width: 100%;
  padding-bottom: 14px;
`;

export const LayoutSidebarMenuOptionItem = styled(Link)`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  transition-duration: 0.3s;
  &:hover {
    color: #b7b4b4;
  }
`;

export const SettingContainer = styled.ul`
  position: absolute;
  top: 68px;
  right: 4px;
  width: 200px;
  background: ${({ theme }) => theme.colors.white};
  list-style: none;
  height: 60px;
  padding-left: 0;
  z-index: 1;
  box-shadow: 0 7px 9px rgba(0, 0, 0, 0.1);
`;

export const SettingItem = styled.li`
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  &:hover {
    background: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const LayoutSidebarDownerText = styled.p`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 0;
  text-align: center;
`;

export const LayoutSidebarDownerTextSpan = styled.span`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 15px;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
`;
