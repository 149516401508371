import {
  COUNT_RANGE_MOMENTUM_FAIL,
  COUNT_RANGE_MOMENTUM_SUCCESS,
  COUNT_RANGE_MOMENTUM_REQUIRE,
} from "../constant/pmChart";

import moment from "moment";
import { gradify } from "../axios.config";
import { M_P, M_S } from "constant/momentum";

export const momentumAction =
  (day, user, organization, project, category, subCategory) =>
  async (dispatch, getState) => {
    try {
      const currentDate = moment();
      const current = moment().format("YYYY-MM-DD");
      const oldDate = currentDate.subtract(day, "days").format("YYYY-MM-DD");

      dispatch({
        type: COUNT_RANGE_MOMENTUM_REQUIRE,
      });
      if (!project) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&user=${user}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&user=${user}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_MOMENTUM_SUCCESS,
          payload: {
            completeExperience: studentComplete.count,
            totalExperience: data.count + studentComplete.count,
          },
        });
      } else if (!category) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&user=${user}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_MOMENTUM_SUCCESS,
          payload: {
            completeExperience: studentComplete.count,
            totalExperience: data.count + studentComplete.count,
          },
        });
      } else if (!subCategory) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&user=${user}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&user=${user}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_MOMENTUM_SUCCESS,
          payload: {
            completeExperience: studentComplete.count,
            totalExperience: data.count + studentComplete.count,
          },
        });
      } else {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&subCategory=${subCategory?._id}&user=${user}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        const { data: studentComplete } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&subCategory=${subCategory?._id}&user=${user}&startDate=${oldDate}&startDate=${current}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: COUNT_RANGE_MOMENTUM_SUCCESS,
          payload: {
            completeExperience: studentComplete.count,
            totalExperience: data.count + studentComplete.count,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: COUNT_RANGE_MOMENTUM_FAIL,
        payload: error.message,
      });
    }
  };

export const newMomentumAction =
  (user, project, category, days, categories) => async (dispatch, getState) => {
    dispatch({
      type: M_P,
    });

    let query;
    if (category) {
      if (categories.length < 1) {
        query = `experiencecards/momentumcalc?project=${project}&category=${category}&user=${user}&days=${days}`;
      }
      if (categories.length > 0) {
        const categoriesIds = categories.map((i) => i._id);
        query = `experiencecards/momentumcalc?project=${project}&category=${categoriesIds}&user=${user}&days=${days}`;
      }
    } else {
      query = `experiencecards/momentumcalc?project=${project}&user=${user}&days=${days}`;
    }
    const momentum = await gradify.get(query);
    console.log("momentum", momentum.data.data);

    dispatch({
      type: M_S,
      payload: momentum.data.data,
    });
  };
