import { SET_RAFFLE_TICKET_DETAIL_IMAGE } from "constant";
import store from "Store";
import axios from "axios";
export const editRaffleTicketImage = async (dispatch, photoFile) => {
  try {
    const formData = new FormData();
    formData.append("file", photoFile);
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL_IMAGE,
      payload: { url: "", isLoading: true },
    });

    const { data } = await axios.post(
      `https://apigrad.azurewebsites.net/api/v2/photos`,
      formData,
      {
        headers: { Authorization: `Bearer ${store.getState().auth.token}` },
      }
    );
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL_IMAGE,
      payload: {
        url: `https://apigrad.azurewebsites.net${data.url}`,
        isLoading: false,
      },
    });
  } catch (error) {
    const oldImage = store.getState().raffleTicketDetail.raffleTicketDetail;
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL_IMAGE,
      payload: { url: oldImage, isLoading: false },
    });
  }
};
