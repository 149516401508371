import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { login } from "../../actions/authAction";
import logo from '../../assets/image/gradegy-big-icon.png';
import headerLogin from '../../assets/image/headerlogin.png';
import "./login.css";
import Snackbar from '@material-ui/core/Snackbar';
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import VerificationCode from "./VerificationCode";
import ChangePassword from "./ChangePassword";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const AuhForm = ({ history }) => {
  const classes = useStyles(); 
  const dispatch = useDispatch();
  const {loading,error,token} = useSelector(state =>  state.auth);
  const [admin, setAdmin] = useState({
    email: "",
    password: "",
  });
  const [screen,setScreen] = useState("login");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    showReNewPassword: false
  });
  
  const [open,setOpen] = useState(false);


  const ScreenDisplay = () => {
    if (screen ===  "login"){
          return  (
                  <Login 
                  adminLoginHandler={adminLoginHandler}
                  handleMouseDownPassword={handleMouseDownPassword} 
                  handleClickShowPassword={handleClickShowPassword}
                  onchangeHanlder={onchangeHanlder}
                  username={admin.email} 
                  password={admin.password}
                  setScreen={setScreen}
                  loading={loading} showPassword={values.showPassword}/>
                  
        )
    }else if(screen === "forgot-password"){
          return <ForgotPassword
                  adminLoginHandler={adminLoginHandler}
                  onchangeHanlder={onchangeHanlder}
                  username={admin.email} 
                  setScreen={setScreen}
                  loading={loading} showPassword={values.showPassword}/>

    }else if(screen === "verification-code"){
          return <VerificationCode
                
                  loading={loading} showPassword={values.showPassword} setScreen={setScreen}/>

    } else if(screen === "change-password"){
          return <ChangePassword
                  adminLoginHandler={adminLoginHandler}
                  handleMouseDownPassword={handleMouseDownPassword} 
                  handleClickShowPassword={handleClickShowPassword}
                  onchangeHanlder={onchangeHanlder}
                  password={admin.password}
                  handleClickShowReNewPassword={handleClickShowReNewPassword}
                  showReNewPassword={values.showReNewPassword}
                  loading={loading} 
                  showPassword={values.showPassword}
                  setScreen={setScreen}
                 
                  />
                
    }
  }

  useEffect(() => {
    if(error){
      setOpen(true)
    }
  },[error])
  useEffect(() => {
      if(token){
        history.push('/admin')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  const onchangeHanlder = (e) => {
    console.log(e.target.value)
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };
  const adminLoginHandler = () => {
      dispatch(login(admin));
  }
  const handleClose = () => {
    setOpen(false);
  }
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowReNewPassword = () => {
    setValues({ ...values, showReNewPassword: !values.showReNewPassword });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="login_container">
      <div className="login_wrapper">
        <div className="login_header">
          <img className="logo" src={logo} alt="logo" />
        </div>
        {ScreenDisplay()}
      </div>
      {!loading && (
           <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
           <Alert onClose={handleClose} severity="error">
             {error}
           </Alert>
         </Snackbar>
      )}
    </div>
  );
};

export default AuhForm;
