import axios from 'axios';
import { UNIVERSITY_LIST_FAIL,UNIVERSITY_LIST_REQUSET,UNIVERSITY_LIST_SUCCESS } from '../constant/university'

export const universityListAction = () => async (dispatch) => {
    try {
        dispatch({
            type:UNIVERSITY_LIST_REQUSET
        });

        
        dispatch({
            type:UNIVERSITY_LIST_SUCCESS,
            payload:[]
        })

    } catch (error) {
        dispatch({
      type: UNIVERSITY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    }
}