import React from 'react';
import {AiOutlineCaretDown} from "react-icons/ai";
import "./index.css";

const ToggleBtn = ({onClick , condition})=> {
  const classes = ["toggleOpen"];
  if(!condition){
    classes.push("toggleClose");
  }
  return (
    <button className={classes.join(" ")} onClick={onClick} style={style}>
        <AiOutlineCaretDown />
    </button>
  )
}

export default ToggleBtn

const style ={
   position: "absolute",
   right: 0,
   top: 0,
   width: "40px",
   border: "none",
   outline: "none",
   cursor: "pointer",
   background: "white",
   transition: ".4s ease"

}