import { getInventoriesAction } from 'actions/inventory/inventory.action';
import InventoryList from 'components/shop/shopSystem/inventory/InventoryList';
import React, { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
const InventoryListContainer = ({shopItem,raffelTicket}) => {
    const dispatch = useDispatch();
    const {inventories,status} = useSelector(state => state.inventory);
    useEffect(() => {
      if(status === "loading"){
          console.log(raffelTicket)
            dispatch(getInventoriesAction(shopItem,raffelTicket))
        }
    },[])
  return (
      <>
        { status === 'succeed' && <InventoryList shopItem={shopItem} raffelTicket={raffelTicket} InventoryList={inventories} />}
      </>
  )
}

export default InventoryListContainer