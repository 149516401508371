import styled , { keyframes } from 'styled-components';


const breatheAnimation = keyframes`
 0% { opacity: 0 ; height: 0; }
 30% { opacity: 0 ; height: 0; }
 40% { opacity: 0 ; height: auto; }
 100% { opacity: 1 ; height: auto; }
`

const closebox = keyframes`
 0% { opacity: 1 ; height: auto; }

 100% { opacity: 0 ; height: 0; }
`


export const ExperienceDetailsContainer = styled.div`
    padding:20px;
    width:100%;
    
`;

export const ExperienceDetailsHeader = styled.div`
    width:100%;
    background:${({theme}) => theme.colors.black};
    color:${({theme}) => theme.colors.white};
    padding:20px 12px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px ;
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
`;

export const ExperienceTitleContainer = styled.div`
    text-align:center

`;
export const ExperienceTitle = styled.h1`
    font-family:${({theme}) => theme.fonts.Poppins};
    font-size:72px;
    line-height:0.5;
    padding-top:8px
    @media (max-width: 1650px) {
      font-size: 50px;
      line-height: 55px;
    }
`;
export const ExperienceSubTitle = styled.p`
    font-family:${({theme}) => theme.fonts.Poppins};
    font-size:24px;
    margin-bottom:0;
    padding-right: 40px;
`;

export const ExperienceDatilsInfo = styled.div`
    padding:2px;
    background:${({theme}) => theme.colors.white};
    width:100%;

`;

export const ExperienceDetailsCol = styled.div`
    display:flex;
    padding:10px 4px;
    align-items:center;
    flex-direction:${({Direction}) => Direction};
    justify-content:flex-end;
    flex-wrap:wrap;
    transition:width .2s ease-in;

    .exp-setting.motivational > div{
        animation-name: ${breatheAnimation};
        animation-duration: .6s;
        animation-fill-mode: forwards;
    }
    &.boxActive{
        width:5% !important;

        .exp-setting.motivational > div{
            animation-name: ${closebox};
            animation-duration: .01s;
            animation-fill-mode: forwards;
        }
    }
    @media (max-width: 1300px) {
      align-items: flex-start;
    }

`;

export const ExperienceRow = styled.div`
    background:${({theme}) => theme.colors.main};
    display:flex;
    justify-content:center;
    text-align:center;
    flex-direction: row;

`;
export const ExperienceCardDetails = styled.div`
background:${({cardColor}) => `linear-gradient(180deg, ${cardColor}, white)`};
    width:100%;
    max-width:445px;
    margin-top: -172px;
    position:relative;
    border-radius:15px;
    border:10px solid white;
    display:flex;
    flex-direction:column;
    @media (max-width: 1500px) {
        margin-top: 0;
      }

`;
export const ExperienceCardDetailsInfo = styled.div`

    width:100%;
    flex:3;

`;

export const ExperienceCardDetailsAction = styled.div`
    flex:1;
    width:100%;
    background:white;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px ;

`;

export const LabelExperience = styled.label`
  font-family:${({theme}) => theme.fonts.Poppins};
  font-size:13px;
  color:#272727;
  width:100%;
`;

export const StarButton = styled.button`
  width: 20px;
  height: 20px;
  background: none;
  transform: rotate(0);
  margin-right: 5px;
  margin-top: -2px;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: block;
  position: absolute;
`

export const ExperienceButton = styled.button`
background: ${({ theme }) => theme.colors.primary};
color: ${({ theme }) => theme.colors.white};
border: none;
padding: 10px 8px 10px 8px;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
margin-left: ${({ spaceLeft }) => spaceLeft};
font-family: ${({ theme }) => theme.fonts.Poppins};
font-size: 14px;
display: flex;
align-items: center;
border-radius: 5px;
font-weight: 300;
@media (max-width: 1770px) {
  font-size: 13px;
  margin-left: 20px;
}
span {
  white-space: nowrap;
}
`

export const SearchExperienceStudentContainer = styled.div`
  width: 34%;
  height: 36px;
  position: relative;
  margin-left: 10px;
`;
export const SearchExperienceStudent = styled.input`
  width: 90%;
  height: 36px;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.lightdark};
  padding-left: 35px;
  text-indent: 10px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 13px;

  &:focus {
    outline: none;
  }
`;