import { SET_APP_PAGE_STATUS, SET_APP_PROJECT_OPTIONS } from "constant";
import { gradify } from "axios.config";
import store from "Store";
import { formatAllProjects } from "helpers";
export const getAppProjectsByIdAction =
  (organizationId) => async (dispatch) => {
    try {
      const token = store.getState().auth.token;
      const organizations = store.getState().app.organizations;
      if (
        organizationId !== undefined &&
        organizationId !== "Select Organization"
      ) {
        const query = `userread/getProjectsByOrganization?organizationId=${organizationId}`;
        const { data } = await gradify.get(query, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (organizations && organizations.length !== 0) {
          dispatch({
            type: SET_APP_PROJECT_OPTIONS,
            payload: formatAllProjects(data),
          });
        }
      } else {
        dispatch({
          type: SET_APP_PROJECT_OPTIONS,
          payload: [],
        });
      }
      dispatch({
        type: SET_APP_PAGE_STATUS,
        payload: "DEAFULT",
      });
    } catch (error) {
      dispatch({
        type: SET_APP_PROJECT_OPTIONS,
        payload: [],
      });
      dispatch({
        type: SET_APP_PAGE_STATUS,
        payload: "DEAFULT",
      });
    }
  };
