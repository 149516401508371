import { M_E, M_P, M_S } from "constant/momentum";
import produce from "immer";

const INITIAL_STATE = {
  isPending: false,
  isSuccess: false,
  isError: false,
  momentumCalc: null,
};

export const newMomentumReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case M_P:
        draft.isPending = true;
        break;
      case M_S:
        draft.isPending = false;
        draft.isSuccess = true;
        draft.momentumCalc = action.payload;
        break;
      case M_E:
        draft.isError = action.payload;
        break;
    }
  });
