import React from "react";
import { AdvanceSelectStyle } from "../styled.components";
import "./general.style.css";
const AdvanceSelect = ({
  options,
  onChange,
  loadOptions,
  name,
  isLoading,
  isClearable,
  isSearchable,
  value,
  defaultValue,
}) => {
  const changeHandler = (e) => {
    onChange(e, e.target.value);
  };
  return (
    <AdvanceSelectStyle
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      className="gradegy-advane-check-box"
      options={options}
      onChange={onChange}
      value={value}
      loadOptions={loadOptions}
      placeholder="Start typing "
      defaultValue={"Searching"}
      noOptionsMessage={({ value }) =>
        !value ? "not Change" : "No results found"
      }
    />
  );
};

export default AdvanceSelect;
