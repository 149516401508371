import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gradify } from "axios.config";
import { formatListArr } from "helpers";
import {
  adminDetailSchoolDistrictOptionsSelector,
  adminDetailHighSchoolList,
  adminDetailSelectedHighSchoolId,
  selectedAdminDetailSelector,
} from "store/selectors";
import {
  addNewHighSchoolAction,
  setSelectedHighSchoolId,
  removeSelectedHighSchool,
  addSchoolDistrictAction,
} from "actions";
import SchoolDistrict from "components/admin/adminDetail/Access/EditSections/SchoolDistrict";

const SchoolDistrictContainer = () => {
  // selectors for admin
  const token = useSelector((state) => state.auth.token);
  const adminDetail = useSelector(selectedAdminDetailSelector);
  const highSchoolList = useSelector(adminDetailHighSchoolList);
  const selectedHighSchoolId = useSelector(adminDetailSelectedHighSchoolId);
  // states
  const [selectedHighSchoolOption, setSelectedHighSchoolOption] = useState();
  const [selectedDistrictSchool, setSelectedDistrictSchool] = useState();
  const [selectedOptionValue, setSelectedOptionValue] = useState();
  const dispatch = useDispatch();
  const schoolDistrictOptions = useSelector(
    adminDetailSchoolDistrictOptionsSelector
  );
  const schoolDistrictChangeHandler = useCallback(
    (event) => {
      const selectedSchDist = schoolDistrictOptions.find(
        (item) => item._id === event.target.value
      );
      setSelectedDistrictSchool(selectedSchDist);
      setSelectedHighSchoolOption();
      setSelectedOptionValue("");
    },
    [schoolDistrictOptions]
  );
  // highschool based on selected projectId
  const loadHighSchoolOptionsHandler = async (inputText, callback) => {
    const agencyName = schoolDistrictOptions.find(
      (item) => item._id === selectedDistrictSchool?._id
    )?.name;
    const res = await gradify.get(
      `highschools/search?term=${inputText}&agancy=${agencyName.toUpperCase()}`
    );
    callback(
      res.data.map((item) => {
        return {
          label: item.school_name,
          value: item._id,
        };
      })
    );
  };

  const highSchoolChangeHandler = useCallback(
    async (event) => {
      setSelectedOptionValue(event.label);
      const { data } = await gradify.get(
        `categories?project=${selectedDistrictSchool?._id}&name=${event.label}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSelectedHighSchoolOption({
        _id: event.value,
        title: data.data[0].name,
        schoolName: data.data[0].name,
        projectId: selectedDistrictSchool?._id,
        schoolDistrictName:
          selectedDistrictSchool?.name || selectedDistrictSchool?.title,
        categoryId: data.data[0]._id,
      });
    },
    [
      selectedDistrictSchool?._id,
      selectedDistrictSchool?.name,
      selectedDistrictSchool?.title,
      token,
    ]
  );
  const addSchoolDistClickHandler = useCallback(() => {
    if (selectedHighSchoolOption?._id) {
      addNewHighSchoolAction(dispatch, {
        ...selectedHighSchoolOption,
        access: {
          experienceCards: false,
          store: false,
          surveys: false,
          users: false,
        },
      });
    }
    if (selectedDistrictSchool?._id && !selectedHighSchoolOption?._id) {
      addSchoolDistrictAction(dispatch, {
        ...selectedDistrictSchool,
        projectId: selectedDistrictSchool?._id,
        schoolDistrictName:
          selectedDistrictSchool?.name || selectedDistrictSchool?.title,
        access: {
          experienceCards: false,
          store: false,
          surveys: false,
          users: false,
        },
      });
    }
    // add highschool selected and other added highschool in box
  }, [dispatch, selectedDistrictSchool, selectedHighSchoolOption]);
  const onHighSchoolClickedHandler = useCallback(
    (selectedHighSchoolId) => {
      setSelectedHighSchoolId(dispatch, selectedHighSchoolId);
      // set selected highSchool and get access then show access
    },
    [dispatch]
  );
  const deleteHighSchoolItemHandler = useCallback(
    (selectedHighSchoolId) => {
      removeSelectedHighSchool(dispatch, selectedHighSchoolId);
    },
    [dispatch]
  );
  useEffect(() => {
    setSelectedDistrictSchool(adminDetail?.organizationSchoolDistrict);
  }, [adminDetail?.organizationSchoolDistrict]);
  return (
    <SchoolDistrict
      schoolDistrictOptions={schoolDistrictOptions}
      highSchoolNameArr={formatListArr(highSchoolList)}
      onSchoolDistrictChange={schoolDistrictChangeHandler}
      onHighSchoolChange={highSchoolChangeHandler}
      loadHighSchoolOptions={loadHighSchoolOptionsHandler}
      onAddSchoolDistClick={addSchoolDistClickHandler}
      onHighSchoolClick={onHighSchoolClickedHandler}
      selectedHighSchool={highSchoolList[selectedHighSchoolId]}
      selectedOptionValue={selectedOptionValue}
      schoolDistrictAccess
      onDeleteHighSchoolItem={deleteHighSchoolItemHandler}
    />
  );
};

export default SchoolDistrictContainer;
