import { CHANGE_UNIVERSITY_ACCESS_PERMISSION } from "constant";
export const changeUniversityAccessPermissionAction = (
  dispatch,
  accessName,
  accessValue
) => {
  dispatch({
    type: CHANGE_UNIVERSITY_ACCESS_PERMISSION,
    payload: { accessName, accessValue },
  });
};
