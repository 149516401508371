import React, { useState, useCallback } from "react";
import StudentList from "components/raffle/RaffleSystem/StudentList";
import { useDispatch, useSelector } from "react-redux";
import { togglePickupRaffleAction } from "actions";
import { raffleTicketStudentsSelector } from "store/selectors";
const StudentListContainer = ({raffleId}) => {
  const dispatch = useDispatch();
  //states
  const [searchStudentValue, setSearchStudentValue] = useState("");
  const [selectedFilterId, setSelectedFilterId] = useState("All");
  //selectors
  const studentListSelectorCallback = useCallback(
    raffleTicketStudentsSelector(searchStudentValue, selectedFilterId),
    [searchStudentValue, selectedFilterId]
  );
  const studentList = useSelector(studentListSelectorCallback);
  //actions
  const handlePickUpStudentChange = useCallback(
    (id, studentId, pickup) => {
      dispatch(togglePickupRaffleAction(id, studentId, pickup));
    },
    [dispatch]
  );
  return (
    <StudentList
      onChangeToggle={handlePickUpStudentChange}
      studentList={studentList}
      raffleId={raffleId}
    />
  );
};

export default StudentListContainer;
