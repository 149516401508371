import React from "react";
import {
  AdminInfo,
  AdminDetailPic,
  AdminDetailSubTitle,
} from "components/admin/admin.style";
import { useSelector } from "react-redux";
import { selectedAdminDetailSelector } from "store/selectors";

import {
  Box,
  Column,
  Row,
  InnerCol,
  Header,
  SubTitle,
  ConstantLabel,
  ConstantValue,
  ConstantContent,
  EditButton,
  EditButtonText,
  EditButtonIcon,
  Input,
} from "assets/style/General.style";
import { MdEdit } from "react-icons/md";
import PolygonCharts from "components/charts/PolygonCharts";
const Info = ({
  adminImg,
  adminName,
  organizationName,
  organizationSubName,
  organizatioHighSchoolName,
  adminId,
  adminRole,
  isAccessEditting,
  onEditInfoClick,
  organizationType,
}) => {
  const adminDetail = useSelector(selectedAdminDetailSelector);

  return (
    <Box elevation={3} style={{ flexDirection: "column" }}>
      <Row>
        <AdminInfo>
          <AdminDetailPic>
            <PolygonCharts
              XP={3499}
              studentPic={adminImg || ""}
              color="#000"
              style={{ transform: "scale(0.35)", top: -98, left: -17 }}
            />
          </AdminDetailPic>
          <div style={{ paddingLeft: 40, width: "100%" }}>
            <Header>{adminName}</Header>
            <Column>
              <AdminDetailSubTitle>{adminRole}</AdminDetailSubTitle>
              <AdminDetailSubTitle style={{ paddingLeft: "10%" }}>
                {adminDetail.adminJobTitle}
              </AdminDetailSubTitle>
            </Column>
          </div>
        </AdminInfo>
      </Row>
      <Row style={{ height: "36px" }}>
        {!isAccessEditting && (
          <EditButton style={{ marginLeft: "33px" }} onClick={onEditInfoClick}>
            <EditButtonText>Edit</EditButtonText>
            <EditButtonIcon>
              <MdEdit color="#34C38F" size={12} />
            </EditButtonIcon>
          </EditButton>
        )}
      </Row>
      <Row alignment="space-between">
        <Column width="50%" minWidth="550px" Direction="column">
          <InnerCol style={{ paddingLeft: 15, marginTop: 15 }}>
            <SubTitle>Info</SubTitle>
          </InnerCol>
          <InnerCol>
            <ConstantContent>
              <ConstantLabel>Admin ID</ConstantLabel>
              <ConstantValue>{adminId}</ConstantValue>
            </ConstantContent>
            <ConstantContent>
              <ConstantLabel style={{ marginBottom: "5px" }}>
                Phone
              </ConstantLabel>
              <ConstantValue>{adminDetail.adminPhone}</ConstantValue>
            </ConstantContent>
            <ConstantContent>
              <ConstantLabel style={{ marginBottom: "5px" }}>
                Email
              </ConstantLabel>

              <ConstantValue>{adminDetail.adminEmail}</ConstantValue>
            </ConstantContent>
            <ConstantContent>
              <ConstantLabel style={{ marginBottom: "5px" }}>
                Job Title
              </ConstantLabel>
              <ConstantValue>{adminDetail.adminJobTitle}</ConstantValue>
            </ConstantContent>
          </InnerCol>
        </Column>
        <Column width="50%" Direction="column">
          <InnerCol style={{ paddingLeft: 35, marginTop: 15 }}>
            <SubTitle>Organization</SubTitle>
          </InnerCol>
          <InnerCol style={{ paddingLeft: 35 }}>
            <ConstantContent>
              <ConstantLabel>Organization</ConstantLabel>
              <ConstantValue>{organizationName}</ConstantValue>
            </ConstantContent>
            <ConstantContent>
              <ConstantLabel>
                {organizationType === "Post Secondary" && "University"}
                {organizationType === "Secondary" && "School District"}
                {organizationType === "CBO" && ""}
              </ConstantLabel>
              <ConstantValue>
                {organizationType !== "CBO" && organizationSubName}
              </ConstantValue>
            </ConstantContent>
            <ConstantContent>
              <ConstantLabel>
                {organizationType === "Secondary" && "School"}
              </ConstantLabel>
              <ConstantValue>
                {organizationType === "Secondary" &&
                  (organizatioHighSchoolName || "....")}
              </ConstantValue>
            </ConstantContent>
          </InnerCol>
        </Column>
      </Row>
    </Box>
  );
};

export default Info;
