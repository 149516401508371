import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShopAddItemPopup from "components/shop/ShopAddItemPopup";
import {
  setShopPageStatus,
  uploadShopItemImage,
  changeShopImageUrl,
} from "actions";

import {
  shopItemImageSelector,
  selectedAppProjectIdSelector,
  selectedAppOrganizationIdSelector,
} from "store/selectors";
const ShopAddItemPopupContainer = ({ onSaveShopItemClicked, categories }) => {
  const dispatch = useDispatch();
  //states
  const [title, setTitle] = useState("");
  const [initialStock, setInitialStock] = useState();
  const [unLockLevel, setUnLockLevel] = useState();
  const [dollarCost, setDollarCost] = useState();
  const [gemsNumber, setGemsNumber] = useState();
  const [description, setDescription] = useState();
  const [isPrizeWheel, setIsPrizeWheel] = useState(false);
  const [isStore, setIsStore] = useState(false);
  const [category, setCategory] = useState("Select category");
  // selectors
  const shopImage = useSelector(shopItemImageSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);

  const onChangeCategory = (value) => {
    console.log(value);
    setCategory(value);
  };
  //actions
  const handleCancelShopItemClick = useCallback(() => {
    setIsPrizeWheel(false);
    setIsStore(false);
    setDescription("");
    setTitle("");
    setGemsNumber(null);
    setDollarCost(null);
    setUnLockLevel(null);
    setInitialStock(null);
    changeShopImageUrl(dispatch, null);
    setShopPageStatus(dispatch, "DEFAULT");
  }, [dispatch]);
  const prizeWheelChangedHandler = useCallback(() => {
    setIsPrizeWheel(!isPrizeWheel);
  }, [isPrizeWheel]);
  const storeChangedHandler = useCallback(() => {
    setIsStore(!isStore);
  }, [isStore]);
  const changeItemImgHandler = useCallback(
    (e) => {
      uploadShopItemImage(dispatch, e.target.files[0]);
    },
    [dispatch]
  );
  const saveShopItemClickedHandler = useCallback(() => {
    if (!gemsNumber) {
      alert("Please Check Gem Field");
    } else {
      let shopInfo;
      if (category === "Select category") {
        shopInfo = {
          title,
          image:
            shopImage?.url ||
            "https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg",
          inital_stock: initialStock,
          unlock_level: unLockLevel,
          description,
          cost_per_item: dollarCost,
          gem: gemsNumber,
          store: isStore || false,
          prize_wheel: isPrizeWheel || false,
          organization: selectedOrganizationId,
          project: selectedProjectId,
        };
      } else {
        shopInfo = {
          title,
          image:
            shopImage?.url ||
            "https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg",
          inital_stock: initialStock,
          unlock_level: unLockLevel,
          description,
          cost_per_item: dollarCost,
          gem: gemsNumber,
          store: isStore || false,
          prize_wheel: isPrizeWheel || false,
          organization: selectedOrganizationId,
          project: selectedProjectId,
          category: category,
        };
      }

      onSaveShopItemClicked(shopInfo);
      setIsPrizeWheel(false);
      setIsStore(false);
      setDescription("");
      setTitle("");
      setGemsNumber(null);
      setDollarCost(null);
      setUnLockLevel(null);
      setInitialStock(null);
      changeShopImageUrl(dispatch, null);
    }
  }, [
    description,
    dispatch,
    dollarCost,
    gemsNumber,
    initialStock,
    isPrizeWheel,
    isStore,
    onSaveShopItemClicked,
    selectedOrganizationId,
    selectedProjectId,
    shopImage?.url,
    title,
    unLockLevel,
    category,
  ]);
  return (
    <ShopAddItemPopup
      shopImage={shopImage}
      onChangeItemImg={changeItemImgHandler}
      title={title}
      onTitleChanged={(e) => {
        setTitle(e.target.value);
      }}
      initialStock={initialStock}
      onInitialStockChanged={(e) => {
        setInitialStock(e.target.value);
      }}
      unLockLevel={unLockLevel}
      onUnLockLevelChanged={(e) => {
        setUnLockLevel(e.target.value);
      }}
      dollarCost={dollarCost}
      onDollarCostChanged={(e) => {
        setDollarCost(e.target.value);
      }}
      gemsNumber={gemsNumber}
      onGemsNumberChanged={(e) => {
        setGemsNumber(e.target.value);
      }}
      description={description}
      onDescriptionChanged={(e) => {
        setDescription(e.target.value);
      }}
      isPrizeWheel={isPrizeWheel}
      onPrizeWheelChange={prizeWheelChangedHandler}
      isStore={isStore}
      onStoreChange={storeChangedHandler}
      onCancelSaveItemClicked={handleCancelShopItemClick}
      onSaveShopItemClicked={saveShopItemClickedHandler}
      categories={categories}
      onChangeCategory={onChangeCategory}
    />
  );
};

export default ShopAddItemPopupContainer;
