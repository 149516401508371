export const EXPERIENCE_LIST_REQUEST = "EXPERIENCE_LIST_REQUEST";
export const EXPERIENCE_LIST_SUCCESS = "EXPERIENCE_LIST_SUCCESS";
export const EXPERIENCE_LIST_FAIL = "EXPERIENCE_LIST_FAIL";
export const EXPERIENCE_SET_SORT_TYPE = "EXPERIENCE_SET_SORT_TYPE";
export const EXPERIENCE_ITEM_REQUEST = "EXPERIENCE_ITEM_REQUEST";
export const EXPERIENCE_ITEM_SUCCESS = "EXPERIENCE_ITEM_SUCCESS";
export const EXPERIENCE_ITEM_FAIL = "EXPERIENCE_ITEM_FAIL";

export const EXPERIENCE_UPDATE_REQUEST = "EXPERIENCE_UPDATE_REQUEST";
export const EXPERIENCE_UPDATE_SUCCESS = "EXPERIENCE_UPDATE_SUCCESS";
export const EXPERIENCE_UPDATE_FAIL = "EXPERIENCE_UPDATE_FAIL";

export const EXPERIENCE_USER_COMPLETE_LIST_FAIL =
  "EXPERIENCE_USER_COMPLETE_LIST_FAIL";
export const EXPERIENCE_USER_COMPLETE_LIST_SUCCESS =
  "EXPERIENCE_USER_COMPLETE_LIST_SUCCESS";
export const EXPERIENCE_USER_COMPLETE_LIST_REQUEST =
  "EXPERIENCE_USER_COMPLETE_LIST_REQUEST";
export const EXPERIENCE_USER_PASSDUE_LIST_FAIL =
  " EXPERIENCE_USER_PASSDUE_LIST_FAIL";
export const EXPERIENCE_USER_PASSDUE_LIST_SUCCESS =
  "EXPERIENCE_USER_PASSDUE_LIST_SUCCESS";
export const EXPERIENCE_USER_PASSDUE_LIST_REQUEST =
  "EXPERIENCE_USER_PASSDUE_LIST_REQUEST";
export const EXPERIENCE_USER_LIST_FAIL = "EXPERIENCE_USER_LIST_FAIL";
export const EXPERIENCE_USER_LIST_SUCCESS = "EXPERIENCE_USER_LIST_SUCCESS";
export const EXPERIENCE_USER_LIST_REQUEST = "EXPERIENCE_USER_LIST_REQUEST";
export const EXPERIENCE_USER_CHANGE_LIST = "EXPERIENCE_USER_CHANGE_LIST";
export const EXPERIENCE_SET_SEARCH_INPUT = "EXPERIENCE_SET_SEARCH_INPUT";
export const EXPERIENCE_SET_TYPE_FILTER = "EXPERIENCE_SET_TYPE_FILTER";

export const NEW_EXPERIENCE_ITEM_PENDING = "NEW_EXPERIENCE_ITEM_PENDING";
export const NEW_EXPERIENCE_ITEM_SUCCESS = "NEW_EXPERIENCE_ITEM_SUCCESS";
export const NEW_EXPERIENCE_ITEM_FAIL = "NEW_EXPERIENCE_ITEM_FAIL";
