import {
    EXPERIENCEIMAGE_LIST_REQUEST,
    EXPERIENCEIMAGE_LIST_SUCCESS,
    EXPERIENCEIMAGE_LIST_FAIL
} from '../constant/experienceImage';


export const exprienceImageListReducer = (state = { experienceImages:{},loading:true},action) =>{
    switch(action.type){
        case EXPERIENCEIMAGE_LIST_REQUEST:
            return {...state,loading:true,experienceImages:{}}
        case EXPERIENCEIMAGE_LIST_SUCCESS:
            return {...state,loading:false,experienceImages:action.payload}
        case EXPERIENCEIMAGE_LIST_FAIL:
            return {...state,loading:false,experienceImages:{},error:action.payload}        
        default:
            return state
    }
}