import React from "react";
import { VerticalContainer, Vertical } from "./index.style";


 
const VerticalText = ({style, text , width}) => {

  return (      
<VerticalContainer Width={width}>
    <Vertical style={style}>{text}</Vertical>
</VerticalContainer>

  );
};


export default VerticalText;
