import React, { useCallback, useState } from "react";
import Header from "containers/Home/Header";
import Recent from "containers/Home/Recent";
import All from "containers/Home/All";
import { useSelector, useDispatch } from "react-redux";
import {
  allAppProjectsSelector,
  selectedAppOrganizationIdSelector,
} from "store/selectors";
import { setAppSelectedProjectId, getALLUserList } from "actions";
import { setItemList, formatArrToObj } from "helpers";
const HomeContainer = ({ history }) => {
  const dispatch = useDispatch();
  const [searchProjectInput, setSearchProjectInput] = useState("");
  const projectsSelectorCallback = useCallback(
    allAppProjectsSelector(searchProjectInput),
    [searchProjectInput]
  );
  //selectors
  const user = useSelector((state) => state.loggedInUser?.user);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const projects = useSelector(projectsSelectorCallback);
  const projectClickHandler = useCallback(
    (projectId) => {
      setAppSelectedProjectId(dispatch, projectId);
      setItemList(user._id, {
        ...formatArrToObj(projects)[projectId],
        selectedOrganizationId,
      });
      dispatch(getALLUserList(`filters=[{"field":"role","value":"user"}]&`));

      const project = projects.find((item) => item._id === projectId);
      if (project) {
        history.push(`/admin/students?studentCount=${project?.studentCount}`);
      }
    },
    [dispatch, history, projects, selectedOrganizationId, user._id]
  );

  const searchProjectChangeHandler = useCallback((e) => {
    setSearchProjectInput(e.target.value);
  }, []);
  return (
    <>
      <Header
        searchProjectInput={searchProjectInput}
        onSearchProjectChange={searchProjectChangeHandler}
      />
      <Recent history={history} />
      <All onProjectClick={projectClickHandler} projects={projects} />
    </>
  );
};

export default HomeContainer;
