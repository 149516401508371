import React, { useState, useCallback, useEffect } from "react";
import AddAdminComponent from "components/admin/AddAdmin";
import { useSelector, useDispatch } from "react-redux";
import { gradify } from "axios.config";
import { SUPER_ADMIN_LEVEL, ULTRA_ADMIN_LEVEL } from "constants/index";
import { backRoleMapping, roleMapping } from "helpers";
import {
  adminEditPhotoSelector,
  allAppOrganizationsSelector,
  selectedAdminDetailSelector,
} from "store/selectors";
import {
  getAppAllOrganizations,
  uploadEditPhoto,
  changeEditPhotoUrl,
  cancelAdminEditAction,
} from "actions";
const EditAdminContainer = ({
  editInfoAdminHandler,
  onCancelClick,
  pageStatus,
}) => {
  const dispatch = useDispatch();

  //selectors
  const adminDetail = useSelector(selectedAdminDetailSelector);
  const organizations = useSelector(allAppOrganizationsSelector);
  const token = useSelector((state) => state.auth.token);
  const loggedInUser = useSelector((state) => state.loggedInUser.user);
  const adminPhoto = useSelector(adminEditPhotoSelector);
  const [firstName, setFirstName] = useState(adminDetail?.firstName);
  const [lastName, setLastName] = useState(adminDetail?.lastName);
  const [phoneNumber, setPhonNumber] = useState(adminDetail?.adminPhone);
  const [email, setEmail] = useState(adminDetail?.adminEmail);
  const [jobTitle, setJobTitle] = useState(adminDetail?.adminJobTitle);
  const [adminLevel, setAdminLevel] = useState(adminDetail?.adminRole);
  const [schoolLink, setSchoolLink] = useState(true);
  const [category, setCategory] = useState("...");
  const [highSchool, setHighSchool] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [university, setUniversity] = useState();
  useEffect(() => {
    dispatch(getAppAllOrganizations());
  }, [dispatch]);
  useEffect(() => {
    if (organizations)
      setSelectedOrganization(
        organizations.find((org) => org._id === adminDetail?.organizationId)
      );
    setSelectedProject(adminDetail?.project);
  }, [adminDetail?.organizationId, adminDetail?.project, organizations]);
  const handleChangeImgPic = useCallback(
    (e) => {
      uploadEditPhoto(dispatch, e.target.files[0]);
      // add photo api call here and save url then return it
    },
    [dispatch]
  );
  const handleAdminLevelChange = useCallback((e) => {
    setAdminLevel(roleMapping[e.target.value]);
  }, []);
  const handleProjectChange = useCallback(
    (e) => {
      if (e.target.value !== "select project") {
        const selectedPro = selectedOrganization?.projects.find(
          (Pro) => Pro._id === e.target.value
        );
        setSelectedProject(selectedPro);
      } else {
        setSelectedProject();
      }
    },
    [selectedOrganization?.projects]
  );
  const handlePhoneValueChange = useCallback((e) => {
    setPhonNumber(e.target.value);
  }, []);
  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);
  const handleJobTitleChange = useCallback((e) => {
    setJobTitle(e.target.value);
  }, []);
  const handleLoadUniversityOptions = async (inputText, callback) => {
    const res = await gradify.get(`universities/search?term=${inputText}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    callback(
      res.data.map((item) => {
        return {
          label: item.instnm,
          value: item._id,
        };
      })
    );
  };
  const handleLoadHighSchoolOptions = async (inputText, callback) => {
    const res = await gradify.get(
      `categories/search?term=${inputText}&project=${selectedProject?._id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    callback(
      res.data.map((item) => {
        return {
          label: item.name,
          value: item.highSchool,
        };
      })
    );
  };
  const handleUniversityChange = useCallback(
    async (selectedUniversity) => {
      const { data: projectResult } = await gradify.get(
        `projects?name=${selectedUniversity.label}
      `,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSelectedProject(projectResult?.data[0]);
      setUniversity(selectedUniversity?.value);
      if (projectResult.count > 0) {
        setSelectedProject(projectResult?.data[0]);
        setUniversity(selectedUniversity?.value);
      } else {
        setUniversity(selectedUniversity?.value);
      }
    },
    [token]
  );
  const HandleSchoolChange = useCallback(
    async (selectedSchool) => {
      const { data: categoryResult } = await gradify.get(
        `categories?project=${selectedProject?._id}&name=${selectedSchool.label}
      `,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCategory(categoryResult?.data[0]);
      setHighSchool(selectedSchool?.value);
      if (categoryResult.count > 0) {
        setCategory(categoryResult?.data[0]);
        setHighSchool(selectedSchool?.value);
      } else {
        setHighSchool(selectedSchool?.value);
      }
    },
    [selectedProject?._id, token]
  );
  const handleSaveAddeAdminClick = useCallback(() => {
    const adminInfo = {
      firstName,
      lastName,
      role: backRoleMapping[adminLevel],
      profilePicture: adminPhoto?.url,
      phone: phoneNumber,
      email,
      jobTitle,
      highSchool: highSchool || null,
      university: { universityID: university },
      system: [
        {
          organization: selectedOrganization._id,
          project: selectedProject?._id || null,
        },
      ],
    };
    editInfoAdminHandler(adminInfo);
    setPhonNumber("");
    setEmail("");
    setJobTitle("");
    setFirstName("");
    setLastName("");
    setSelectedOrganization("");
    changeEditPhotoUrl(dispatch, null);
  }, [
    adminLevel,
    adminPhoto?.url,
    dispatch,
    editInfoAdminHandler,
    email,
    firstName,
    highSchool,
    jobTitle,
    lastName,
    phoneNumber,
    selectedOrganization?._id,
    selectedProject?._id,
    university,
  ]);
  const handleCancelClick = useCallback(() => {
    setPhonNumber("");
    setEmail("");
    setJobTitle("");
    setFirstName("");
    setLastName("");
    setSelectedOrganization("");
    changeEditPhotoUrl(dispatch, null);
    dispatch(cancelAdminEditAction());
    onCancelClick();
  }, [onCancelClick, dispatch]);
  return (
    <AddAdminComponent
      isEditting
      firstName={firstName}
      onFirstNameChange={(e) => {
        setFirstName(e.target.value);
      }}
      lastName={lastName}
      onLastNameChange={(e) => {
        setLastName(e.target.value);
      }}
      adminPhoto={adminPhoto}
      onChangeImgPic={handleChangeImgPic}
      onAdminLevelChange={handleAdminLevelChange}
      AdminLevelOptions={
        loggedInUser.role === "ultraAdmin"
          ? ULTRA_ADMIN_LEVEL
          : SUPER_ADMIN_LEVEL
      }
      selectedLevel={backRoleMapping[adminLevel]}
      organizationName={adminDetail?.organizationName}
      selectedOrganization={selectedOrganization}
      selectedProject={selectedProject}
      onProjectChange={handleProjectChange}
      phoneNumber={phoneNumber}
      onPhoneValueChange={handlePhoneValueChange}
      email={email}
      onEmailChange={handleEmailChange}
      jobTitle={jobTitle}
      onJobTitleChange={handleJobTitleChange}
      category={category?.name || "..."}
      loadHighSchoolOptions={handleLoadHighSchoolOptions}
      loadUniversityOptions={handleLoadUniversityOptions}
      onSchoolChange={HandleSchoolChange}
      onUniversityChange={handleUniversityChange}
      schoolLink={schoolLink}
      setSchoolLink={(value) => setSchoolLink(value)}
      onSaveAddeAdminClick={handleSaveAddeAdminClick}
      onCancelAdminClick={handleCancelClick}
      isAddAdminLoading={pageStatus === "INFO_SAVING"}
    />
  );
};
export default EditAdminContainer;
