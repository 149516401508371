import { gradify } from "axios.config";
import {
  PROGRESS_SETTING_FAILD,
  PROGRESS_SETTING_REQUIRE,
  PROGRESS_SETTING_SUCCESS,
} from "../../constant/progressSetting";

export const progressSettingAction = (progressSetting) => async (dispatch) => {
  dispatch({
    type: PROGRESS_SETTING_REQUIRE,
  });

  try {
    const { data } = await gradify.put(`progressSeting`, progressSetting);
    dispatch({
      type: PROGRESS_SETTING_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: PROGRESS_SETTING_FAILD,
      paylaod: error.message,
    });
  }
};

export const getProgerssSettingAction = (project) => async (dispatch) => {
  dispatch({
    type: PROGRESS_SETTING_REQUIRE,
  });

  try {
    const { data } = await gradify.get(`progressSeting?project=${project}`);
    console.log("data progress setting", data.data);
    dispatch({
      type: PROGRESS_SETTING_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: PROGRESS_SETTING_FAILD,
      payload: error.message,
    });
  }
};
