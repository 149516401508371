import { updateDigitalCodeAciton, updateGroupInventory } from "actions/inventory/inventory.action";
import {
  RowTd,
  RowTr,
  RowInput
} from "components/raffle/RaffleSystem/StudentList/index.style";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const RowTable = ({
  stock_id,
  serialNumber,
  digitalCode,
  date_Purchased,
  sold,
  pickup,
  shopItem,
  raffelTicket
}) => {
    const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [digitalEdit,setDigitalEdit] = useState(false);
  const [serialNumberState, setSerialNumberState] = useState(serialNumber);
  const [digitalCodeState,setDigitalCodeState] = useState(digitalCode);

  const changeUpdateHandler = () => {
    setShowEdit(true);
  };
  const changeUpdateDigital = () => {
      setDigitalEdit(true)
  }
  const onChangeHandler = (event) => {
    const { value } = event.target;
    setSerialNumberState(value);
  };
  const serialOnKeyPressEnterHandler =  (event) => {
    if (event.keyCode === 13) {
        dispatch(updateGroupInventory(serialNumberState,shopItem,raffelTicket))
    }   
  };
  const onChangeDigitalHandler = (event) => {
      const {value} = event.target;
      setDigitalCodeState(value);
  }
  const digitalOnKeyPressEnterHandler =  (event) => {
    if (event.keyCode === 13) {
       const inventory = {
         digitalCode:digitalCodeState
       }
       dispatch(updateDigitalCodeAciton(inventory,stock_id,shopItem,raffelTicket));
    }   
  };
  return (
    <RowTr key={stock_id}>
      <RowTd>{stock_id.substring(0, 8)}</RowTd>
      <RowTd onClick={changeUpdateHandler}>
        {showEdit ? (
          <RowInput
            type="text"
            name="serialNumber"
            value={serialNumberState}
            onChange={onChangeHandler}
            onKeyUp={serialOnKeyPressEnterHandler}
          />
        ) : serialNumberState ? (
          serialNumberState
        ) : (
          "------"
        )}
      </RowTd>
      <RowTd onClick={changeUpdateDigital} >{digitalEdit ? (
          <RowInput
            type="text"
            name="digitalCode"
            value={digitalCodeState}
            onChange={onChangeDigitalHandler}
            onKeyUp={digitalOnKeyPressEnterHandler}
          />
        ) : digitalCodeState ? (
            digitalCodeState
        ) : (
          "------"
        )}</RowTd>
      <RowTd>{date_Purchased ? date_Purchased : `-------`}</RowTd>

      <RowTd>{sold ? sold === true && "Yes" : "No"}</RowTd>
      <RowTd>{pickup ? pickup === true && "Yes" : "No"}</RowTd>
    </RowTr>
  );
};

export default RowTable;
