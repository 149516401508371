import React, { useState, useEffect, useCallback, useRef } from "react";
import ShopSystemHeader from "../header/index";
import LeftColumn from "../leftColumn/index";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import DeleteConfirm from "components/display.component/DeleteConfirm";
import {
  getShopItemDetailAction,
  addStockShopItemAction,
  updateShopItemAction,
  setShopDetailPageStatus,
  deleteShopItemAction,
  editShopItemImage,
  getRaffleTicketDetailAction,
  editRaffleTicketImage,
  updateRaffleTicketAction,
  deleteRaffleTicketAction,
} from "actions";

import {
  shopItemDetailPageStatusSelector,
  shopItemDetailSelector,
  shopItemDetailToastMessageSelector,
  shopDetailItemImageSelector,
  raffleTicketDetailPageStatusSelector,
  raffleTicketDetailSelector,
  raffleTicketDetailToastMessageSelector,
  raffleTicketDetailImageSelector,
  selectedAppOrganizationIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import {
  Wrapper,
  ColumnWrapper,
  SaveBtn,
  BackBtn,
  ButtonWrapper,
} from "./index.style";
import InventoryListContainer from "containers/inentory/inventory-list/inventory-list.container";
import ManuallyInventoryContainer from "containers/inentory/menually-inventory/menually-inventory.container";
import { SET_SHOPITEM_DETAIL_PAGE_STATUS } from "constant";
const ShopSystemInventory = ({
  onBackItemClick,
  itemId,
  onEditInventoryHandler,
  history,
  type,
}) => {
  const dispatch = useDispatch();
  const titleInputRef = useRef();

  // selectors
  const pageStatus = useSelector(shopItemDetailPageStatusSelector);

  const shopItemDetail = useSelector(shopItemDetailSelector);
  const toastMessage = useSelector(shopItemDetailToastMessageSelector);
  const shopItemImage = useSelector(shopDetailItemImageSelector);
  //states
  const [title, setTitle] = useState(shopItemDetail?.title);
  const [description, setDescription] = useState(shopItemDetail?.description);
  const [costDollar, setCostDollar] = useState(shopItemDetail?.costDollar);
  const [gemsNumber, setGemsNumber] = useState(shopItemDetail?.gemsNumber);
  const [unlockLevel, setUnLockLevel] = useState(shopItemDetail?.unlockLevel);
  const [isStore, setIsStore] = useState(shopItemDetail?.isStore);
  const [isPrizeWheel, setIsPrizeWheel] = useState(
    shopItemDetail?.isPrizeWheel
  );
  const [addedInventoryNum, setAddedInventoryNum] = useState(0);
  useEffect(() => {
    dispatch(getShopItemDetailAction(itemId));
    onEditInventoryHandler();
  }, [dispatch, itemId]);
  useEffect(() => {
    setIsPrizeWheel(shopItemDetail?.isPrizeWheel);
    setIsStore(shopItemDetail?.isStore);
    setUnLockLevel(shopItemDetail?.unlockLevel);
    setGemsNumber(shopItemDetail?.gemsNumber);
    setCostDollar(shopItemDetail?.costDollar);
    setDescription(shopItemDetail?.description);
    setTitle(shopItemDetail?.title);
  }, [
    itemId,
    shopItemDetail?.costDollar,
    shopItemDetail?.description,
    shopItemDetail?.gemsNumber,
    shopItemDetail?.isPrizeWheel,
    shopItemDetail?.isStore,
    shopItemDetail?.title,
    shopItemDetail?.unlockLevel,
  ]);

  //actions
  const titleInputChangeHandler = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const descriptionChangeHandler = useCallback((e) => {
    setDescription(e.target.value);
  }, []);
  const costDollarChangeHandler = useCallback((e) => {
    setCostDollar(e.target.value);
  }, []);
  const gemsNumberChangeHandler = useCallback((e) => {
    setGemsNumber(e.target.value);
  }, []);
  const unlockLevelChangeHandler = useCallback((e) => {
    setUnLockLevel(e.target.value);
  }, []);
  const storeChangeHandler = useCallback(() => {
    setIsStore(!isStore);
  }, [isStore]);
  const prizeWheelChangeHandler = useCallback(() => {
    setIsPrizeWheel(!isPrizeWheel);
  }, [isPrizeWheel]);
  const addedInventoryNumChangeHandler = useCallback((e) => {
    setAddedInventoryNum(e.target.value);
  }, []);
  const addStockClickedHandler = useCallback(() => {
    if (addedInventoryNum > 0) {
      const input = {
        current_stock:
          parseInt(shopItemDetail.currentStock) + parseInt(addedInventoryNum),
      };
      dispatch(addStockShopItemAction(input));
      setAddedInventoryNum(0);
    }
  }, [addedInventoryNum, dispatch, shopItemDetail?.currentStock]);

  const changeItemImgHandler = useCallback(
    (e) => {
      editShopItemImage(dispatch, e.target.files[0]);
    },
    [dispatch]
  );

  const addClickedHandler = useCallback(() => {
    if (addedInventoryNum > 0) {
      const input = {
        current_stock:
          parseInt(shopItemDetail.currentStock) + parseInt(addedInventoryNum),
      };
      dispatch(addStockShopItemAction(input));
      setAddedInventoryNum(0);
    }
  }, [addedInventoryNum, dispatch, shopItemDetail?.currentStock]);
  const updateClickedHandler = useCallback(() => {
    const itemInfo = {
      title,
      unlock_level: unlockLevel,
      description,
      cost_per_item: costDollar,
      store: isStore,
      prize_wheel: isPrizeWheel,
      gem: gemsNumber,
      image: shopItemImage?.url,
    };
    dispatch(updateShopItemAction(itemInfo));
  }, [
    costDollar,
    description,
    dispatch,
    gemsNumber,
    isPrizeWheel,
    isStore,
    shopItemImage?.url,
    title,
    unlockLevel,
  ]);
  const deleteClickedHandler = useCallback(() => {
    dispatch(deleteShopItemAction(itemId, history));
  }, [dispatch, history, itemId]);

  //============================RAffleTickeet ==========================\
  const pageStatusRaffle = useSelector(raffleTicketDetailPageStatusSelector);
  const raffleTicketDetail = useSelector(raffleTicketDetailSelector);
  const toastMessageRaffle = useSelector(
    raffleTicketDetailToastMessageSelector
  );
  const raffleTicketImage = useSelector(raffleTicketDetailImageSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  //states
  //left
  const [titleRaffle, setTitleRaffle] = useState(raffleTicketDetail?.title);
  const [descriptionRaffle, setDescriptionRaffle] = useState(
    raffleTicketDetail?.description
  );

  //center
  const [gemsNumberRaffle, setGemsNumberRaffle] = useState(
    raffleTicketDetail?.gemsNumber
  );
  const [isStoreRaffle, setIsStoreRaffle] = useState(
    raffleTicketDetail?.isStore
  );
  const [isDigitalCode, setIsDigitalCode] = useState(false);
  const [isPrizeWheelRaffle, setIsPrizeWheelRAffle] = useState(
    raffleTicketDetail?.isPrizeWheel
  );
  //header
  const [winnerNumber, setWinnerNumber] = useState(
    raffleTicketDetail?.winnerNumber
  );
  const [startDate, setStartDate] = useState(raffleTicketDetail?.startDate);
  const [startTime, setStartTime] = useState(raffleTicketDetail?.startTime);
  const [closeDate, setCloseDate] = useState(raffleTicketDetail?.closeDate);
  const [closeTime, setCloseTime] = useState(raffleTicketDetail?.closeTime);
  const [drawDate, setDrawDate] = useState(raffleTicketDetail?.drawDate);
  const [drawTime, setDrawTime] = useState(raffleTicketDetail?.drawTime);
  useEffect(() => {
    dispatch(getRaffleTicketDetailAction(itemId));
  }, [dispatch, itemId]);
  useEffect(() => {
    setStartDate(raffleTicketDetail?.startDate);
    setStartTime(raffleTicketDetail?.startTime);
    setCloseDate(raffleTicketDetail?.closeDate);
    setCloseTime(raffleTicketDetail?.closeTime);
    setDrawDate(raffleTicketDetail?.drawDate);
    setDrawTime(raffleTicketDetail?.drawTime);
    setWinnerNumber(raffleTicketDetail?.winnerNumber);
    setIsPrizeWheel(raffleTicketDetail?.isPrizeWheel);
    setIsStore(raffleTicketDetail?.isStore);
    setGemsNumber(raffleTicketDetail?.gemsNumber);
    setDescription(raffleTicketDetail?.description);
    setTitle(raffleTicketDetail?.title);
  }, [
    raffleTicketDetail?.closeDate,
    raffleTicketDetail?.closeTime,
    raffleTicketDetail?.description,
    raffleTicketDetail?.drawDate,
    raffleTicketDetail?.drawTime,
    raffleTicketDetail?.gemsNumber,
    raffleTicketDetail?.isPrizeWheel,
    raffleTicketDetail?.isStore,
    raffleTicketDetail?.startDate,
    raffleTicketDetail?.startTime,
    raffleTicketDetail?.title,
    raffleTicketDetail?.winnerNumber,
  ]);
  useEffect(() => {
    return () => {
     dispatch({
      type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
      payload: "DEFAULT",
    });
  }
  },[])
  //actions
  const titleInputChangeHandlerRAffle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const descriptionChangeHandlerRAffle = useCallback((e) => {
    setDescription(e.target.value);
  }, []);
  const winnerNumberChangeHandler = useCallback((e) => {
    setWinnerNumber(e.target.value);
  }, []);
  const gemsNumberChangeHandlerRAffle = useCallback((e) => {
    setGemsNumber(e.target.value);
  }, []);
  const storeChangeHandlerRAffle = useCallback(() => {
    setIsStore(!isStore);
  }, [isStore]);
  const prizeWheelChangeHandlerRAffle = useCallback(() => {
    setIsPrizeWheel(!isPrizeWheel);
  }, [isPrizeWheel]);

  const changeItemImgHandlerRAffle = useCallback(
    (e) => {
      editRaffleTicketImage(dispatch, e.target.files[0]);
    },
    [dispatch]
  );
  const updateClickedHandlerRaffle = useCallback(() => {
    const itemInfo = {
      raffle_title: title,
      description: description,
      in_store: isStore,
      in_prize_wheel: isPrizeWheel,
      gem_raffle: gemsNumber,
      raffle_image: raffleTicketImage?.url,
      number_winner: winnerNumber,
      start_date: new Date(
        Moment(`${startDate} ${startTime}`).format()
      ).toISOString(),
      end_date: new Date(
        Moment(`${closeDate} ${closeTime}`).format()
      ).toISOString(),
      draw_date: new Date(
        Moment(`${drawDate} ${drawTime}`).format()
      ).toISOString(),
    };
    dispatch(updateRaffleTicketAction(itemInfo));
  }, [
    closeDate,
    closeTime,
    description,
    dispatch,
    drawDate,
    drawTime,
    gemsNumber,
    isPrizeWheel,
    isStore,
    raffleTicketImage?.url,
    startDate,
    startTime,
    title,
    winnerNumber,
  ]);
  const deleteClickedHandlerRaffle = useCallback(() => {
    dispatch(deleteRaffleTicketAction(itemId, history));
  }, [dispatch, history, itemId]);

  return (
    <Wrapper>
      <ShopSystemHeader
        headerTitle="Inventory"
        inStockNum={shopItemDetail?.currentStock}
        pickedUpNum={shopItemDetail?.pickedUpNum}
        notPickedUpNum={
          shopItemDetail?.soldNum - shopItemDetail?.pickedUpNum || 0
        }
        soldNum={shopItemDetail?.soldNum}
      />
      <ColumnWrapper>
        {type === "shopItem" ? (
          <LeftColumn
            image={shopItemImage}
            title={title}
            onTitleInputChange={titleInputChangeHandler}
            description={description}
            onDescriptionChange={descriptionChangeHandler}
            titleInputRef={titleInputRef}
            gemsNumber={gemsNumberRaffle}
            onChangeItemImg={changeItemImgHandlerRAffle}
            unlockLevel={unlockLevel}
            leftItemNumber={shopItemDetail?.currentStock}
          />
        ) : (
          <LeftColumn
            image={raffleTicketImage}
            title={titleRaffle}
            onTitleInputChange={titleInputChangeHandlerRAffle}
            description={descriptionRaffle}
            onDescriptionChange={descriptionChangeHandlerRAffle}
            titleInputRef={titleInputRef}
            gemsNumber={gemsNumber}
            onChangeItemImg={changeItemImgHandler}
            unlockLevel={unlockLevel}
            leftItemNumber={raffleTicketDetail?.currentStock}
          />
        )}

        {type === "shopItem" ? (
          <ManuallyInventoryContainer shopItem={itemId} />
        ) : (
          <ManuallyInventoryContainer raffelTicket={itemId} />
        )}

        {type === "shopItem" ? (
          <InventoryListContainer shopItem={itemId} />
        ) : (
          <InventoryListContainer raffelTicket={itemId} />
        )}
      </ColumnWrapper>
      <ButtonWrapper>
        <BackBtn onClick={onBackItemClick}>Back</BackBtn>
        <SaveBtn>Save</SaveBtn>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ShopSystemInventory;
