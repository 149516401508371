import { gradify } from "axios.config";
import {
  EXPERIENCE_STUDENT_PENDING,
  EXPERIENCE_STUDENT_SUCCESS,
  EXPERIENCE_STUDENT_FAIL,
  EXPERIENCE_STUDENT_CHANGE,
} from "../../constant/experienceStudentList";

export const experienceStudentListAction =
  (projectId, categoryId, experienceId, page) => async (dispatch, getState) => {
    dispatch({
      type: EXPERIENCE_STUDENT_PENDING,
    });

    try {
      const { data } = await gradify.get(
        `users/getuserexperience?projectId=${projectId}&categoryId=${categoryId}&experienceId=${experienceId}&page=${page}`
      );

      dispatch({
        type: EXPERIENCE_STUDENT_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const expereincePhotoChangeCompleteAction =
  (experience, user, complete) => (dispatch) => {
    console.log("e", experience);
    console.log("u", user);
    console.log("c", complete);
    dispatch({
      type: EXPERIENCE_STUDENT_CHANGE,
      payload: {
        user,
        experience,
        complete,
        updatedAt: new Date().toLocaleDateString(),
      },
    });
  };
