import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentHeaderUniversity,
} from "components/styled.components";
import { Row } from "assets/style/General.style";
import Info from "components/admin/adminDetail/Info";
import EditAdminPopupContainer from "containers/Admin/AdminDetail/EditAdminPopup";

import Access from "components/admin/adminDetail/Access/Access";
import { Spinner } from "components/admin/admin.style";
import {
  selectedAdminDetailSelector,
  adminDetailPageStatusSelector,
} from "store/selectors";
import {
  getAdminDetailAction,
  setAdminDetailPageStatus,
  cancelAdminEditAction,
  editInfoAdminDetailAction,
} from "actions";
const AdminDetailContainer = ({ onSaveAdminHandler, adminId }) => {
  // selectors for admin
  const adminDetail = useSelector(selectedAdminDetailSelector);
  const pageStatus = useSelector(adminDetailPageStatusSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminDetailAction(adminId));
  }, [adminId, dispatch]);
  const [isAccessEditting, setIsAccessEditting] = useState(false);
  const handleEditAccess = useCallback(() => {
    setIsAccessEditting(true);
  }, []);
  const onSaveEditAdminClick = useCallback(() => {
    // send highSchoolsList selector for edit with format
    onSaveAdminHandler();
    setIsAccessEditting(false);
  }, [onSaveAdminHandler]);
  const cancelEditAccessClickHandler = useCallback(() => {
    dispatch(cancelAdminEditAction());
    setIsAccessEditting(false);
  }, [dispatch]);
  const handleCloseEditAdmin = useCallback(() => {
    setAdminDetailPageStatus(dispatch, "DEFAULT");
  }, [dispatch]);
  const editInfoAdminHandler = useCallback(
    (adminInfo) => {
      dispatch(editInfoAdminDetailAction(adminInfo));
    },
    [dispatch]
  );
  return (
    <>
      {(pageStatus === "DEFAULT" || pageStatus === "SAVING_EDIT") && (
        <>
          <ContentHeader>
            <ContentHeaderTitle>
              ADMIN ACCESS
              <ContentHeaderUniversity>
                {adminDetail?.organizationName}
              </ContentHeaderUniversity>
            </ContentHeaderTitle>
          </ContentHeader>
          <Row Direction="column" style={{ width: "96%", margin: "2%" }}>
            <Info
              organizationType={adminDetail?.organizationType}
              organizationName={adminDetail?.organizationName}
              organizationSubName={
                adminDetail?.organizationType !== "Post Secondary"
                  ? adminDetail?.organizationSchoolDistrict?.name
                  : adminDetail?.organizationUniversity?.name
              }
              organizatioHighSchoolName={
                adminDetail?.organizationHighSchoolName
              }
              adminRole={adminDetail.adminRole}
              adminName={adminDetail.adminName}
              adminImg={adminDetail?.adminImg}
              adminJobTitle={adminDetail.adminJobTitle}
              adminId={adminDetail.adminId}
              adminEmail={adminDetail?.adminEmail}
              adminPhone={adminDetail?.adminPhone}
              isAccessEditting={isAccessEditting}
              onEditInfoClick={() => {
                setAdminDetailPageStatus(dispatch, "EDIT_ADMIN_POPUP");
              }}
            />
            <Access
              organizationType={adminDetail.organizationType}
              isAccessEditting={isAccessEditting}
              onEditAccessClick={handleEditAccess}
              onCancelEditAccessClick={cancelEditAccessClickHandler}
              onSaveEditAdminClick={onSaveEditAdminClick}
            />
          </Row>
        </>
      )}
      {pageStatus === "LOADING" && <Spinner />}
      {(pageStatus === "EDIT_ADMIN_POPUP" || pageStatus === "INFO_SAVING") && (
        <EditAdminPopupContainer
          pageStatus={pageStatus}
          editInfoAdminHandler={editInfoAdminHandler}
          onCancelClick={handleCloseEditAdmin}
        />
      )}
    </>
  );
};

export default AdminDetailContainer;
