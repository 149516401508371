
import styled from "styled-components";




export const ProjectEditRowBox = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}`