import React, { useState } from "react";
import CircleChart from "components/charts/CircleChart";
import { useEffect } from "react";

const MomentumChart = ({
  percent,
  lastPercent,
  textWeight,
  chartPercentCal,
  title,
  description,
  numbericCircle,
  lastChartPercentCal,
  scale,
  left,
  top
}) => {
  return (
    <CircleChart
      top={top ? top : 47}
      left={left ? left : -83}
      scale={scale ? scale : 1}
      title={title}
      chartPercentCal={chartPercentCal}
      percent={percent}
      description={description}
      numbericCircle={percent - lastPercent}
      lastChartPercentCal={lastChartPercentCal}
    />
    //     <CircleChart
    //     percent={percent}
    //     textWeight={textWeight}
    //     chartPercentCal={chartPercentCal}
    //     title={title}
    //     description={description}
    //     numbericCircle={"+50"}
    //     lastChartPercentCal={chartPercentCal - 20}
    //   />
  );
};

export default MomentumChart;
