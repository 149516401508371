import {
  SET_RAFFLE_TICKET_LIST,
  ADD_RAFFLE_ITEM_IN_RAFFLE_TICKET_LIST,
  SET_RAFFLE_TICKET_PAGE_STATUS,
  SET_RAFFLE_TICKET_ITEM_PHOTO,
  RAFFLE_TICKET_TOAST_MESSAGE,
} from "constant";
import produce from "immer";


const INITIAL_STATE = {
  raffleImage: { isLoading: false, url: 'https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg' },
  raffleItemList: [],
  pageStatus: "Default",
  toastMessage: {
    message: "",
    hasError: false,
  },
  total:0,
  pagination:null,
  searchInput:null,
};

const raffleReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_RAFFLE_TICKET_ITEM_PHOTO:
        draft.raffleImage = {
          ...draft.raffleImage,
          isLoading: action.payload.isLoading,
          url: action.payload.url,
        };
        break;
      case SET_RAFFLE_TICKET_LIST:
        draft.raffleItemList = action.payload.data;
        draft.pagination = action.payload.pagination;
        draft.total = action.payload.total;
        break;
      case ADD_RAFFLE_ITEM_IN_RAFFLE_TICKET_LIST:
        draft.raffleItemList = [...draft.raffleItemList, action.payload];
        break;
      case RAFFLE_TICKET_TOAST_MESSAGE:
        draft.toastMessage = action.payload;
        break;
      case SET_RAFFLE_TICKET_PAGE_STATUS:
        draft.pageStatus = action.payload;
        break;
      // no default
    }
  });
export default raffleReducer;
