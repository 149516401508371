import React from 'react'
import './deleteConfirm.style.css'
const DeleteConfirm = ({onDelete,close}) => {
    const deleteHandler = () => {
        onDelete();

    }
    return (
        <div className="delete_confirm">
            <div className="delete_confirm_container">
                <h1 className="delete_title">DELETE</h1>
                <div className="delete-button">
                    <button className="confirm_yes" onClick={deleteHandler}>Yes</button>
                    <button className="confirm_no" onClick={() => close()}>No</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirm
