import React from "react";
import "./login.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField, FormControl , InputLabel, OutlinedInput ,InputAdornment , IconButton} from "@mui/material";

const Login = ({ 
    handleClickShowPassword ,
    onchangeHanlder,
    adminLoginHandler,
    username,
    password,
    showPassword,
    loading,
    setScreen

}) => {
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
        <div className="login-content">
          <h3 className="login-content-title">Account Login</h3>
          <TextField 
                sx={{
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                }}
          style={{width:"100%"}} id="outlined-basic" label="Username" variant="outlined"  name="email" value={username} onChange={onchangeHanlder} />
  


          <FormControl style={{width:"100%", marginTop:10}}  variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
          variant="outlined"
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            name="password"
            onChange={onchangeHanlder}
            style={{borderRadius:12}}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <button className="forgot-password-button" onClick={() => setScreen('forgot-password')}>Forgot Password?</button>

          <div className="loginbutton">
            <button onClick={adminLoginHandler} disabled={loading}>{loading? 'Loading...' : 'Login'}</button>
          </div>
        </div>
  );
};

export default Login;
