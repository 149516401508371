import {
  ADD_ORGANIZATION_FAIL,
  ADD_ORGANIZATION_REQUEST,
  ADD_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_DETAILS_FAIL,
  GET_ORGANIZATION_DETAILS_REQUEST,
  GET_ORGANIZATION_DETAILS_SUCCESS,
  UPDATE_ORGANIZATION_DETAILS_FAIL,
  UPDATE_ORGANIZATION_DETAILS_REQUEST,
  UPDATE_ORGANIZATION_DETAILS_SUCCESS,
  RESET_ORGANIZATION,
  RESET_UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_DETAILS_SINGLE_FAIL,
  UPDATE_ORGANIZATION_DETAILS_SINGLE_REQUEST,
  UPDATE_ORGANIZATION_DETAILS_SINGLE_SUCCESS,
  ORGANIZATION_SEARCH,
} from "../constant/organization";

export const organizationReducer = (
  state = { organizations: [], organizationAdd: null },
  action
) => {
  switch (action.type) {
    case GET_ORGANIZATION_REQUEST:
      return { loading: true, organizations: [] };
    case GET_ORGANIZATION_SUCCESS:
      return { loading: false, organizations: action.payload };
    case GET_ORGANIZATION_FAIL:
      return { loading: false, organizations: [], error: action.payload };
    case ADD_ORGANIZATION_REQUEST:
      return { ...state, loading: true, error: null, organizationAdd: null };
    case ADD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: [...state.organizations, action.payload],
        organizationAdd: action.payload,
      };
    case ADD_ORGANIZATION_FAIL:
      return { ...state, error: action.payload, organizationAdd: null };
    case UPDATE_ORGANIZATION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: state.organizations.map((item) =>
          item._id === action.payload._id ? (item = action.payload) : item
        ),
        updateOrganization: action.payload,
      };
    case UPDATE_ORGANIZATION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateOrganization: null,
      };
    case RESET_ORGANIZATION:
      return { ...state, organizationAdd: null };
    case RESET_UPDATE_ORGANIZATION:
      return { ...state, updateOrganization: null };
    case ORGANIZATION_SEARCH:
      const organizationClone = state.organizations;
      if (action.payload === "") {
        return { ...state, organizations: organizationClone };
      } else {
        const searchOrganization = state.organizations.filter((item) =>
          item.title.includes(action.payload)
        );
        return { ...state, organization: searchOrganization };
      }
      return { ...state };
    default:
      return state;
  }
};

export const getDetailsOrganizationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_DETAILS_REQUEST:
      return { loading: true };
    case GET_ORGANIZATION_DETAILS_SUCCESS:
      return { loadign: false, organization: action.payload };
    case GET_ORGANIZATION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_ORGANIZATION_DETAILS_SINGLE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_ORGANIZATION_DETAILS_SINGLE_SUCCESS:
      return { ...state, loading: false, organization: action.payload };
    case UPDATE_ORGANIZATION_DETAILS_SINGLE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
