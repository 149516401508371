import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_GET_USER_FAIL,
  AUTH_GET_USER_REQUEST,
  AUTH_GET_USER_SUCCESS,
  LOGOUT_USER,
  ADD_XP_USER,
} from "../constant/auth";
import { RESET_APP_PAGE } from "constant";
import { gradify } from "../axios.config";

export const login = (loginInfo) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_REQUEST,
    });
    const { data } = await gradify.post("auth/login", loginInfo);
    localStorage.setItem("gradifyToken", data.token);
    dispatch({
      type: AUTH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const loggOutUser = () => async (dispatch) => {
  dispatch({
    type: RESET_APP_PAGE,
  });
  dispatch({
    type: LOGOUT_USER,
  });

  localStorage.removeItem("gradifyToken");
};

export const loggedInUserAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUTH_GET_USER_REQUEST,
    });
    const {
      data: { data },
    } = await gradify.get("auth/getme", {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });

    dispatch({
      type: AUTH_GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_GET_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
