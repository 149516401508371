import React, { useMemo } from "react";
import StudentRow from "./StudentRow";
import {
  Wrapper,
  Header,
  Title,
  ListWrapper,
  HeadTr,
  HeadTh,
} from "./index.style";

const StudentList = ({ onChangeToggle, studentList, raffleId }) => {
  const renderStudent = useMemo(
    () =>
      studentList &&
      Object.values(studentList).map((student) => {
        const {
          _id,
          name,
          purchasedDate,
          ticketsNumber,
          isWinner,
          isPickedup,
          pickupDate,
          studentId,
        } = student;

        return (
          <StudentRow
            _id={_id}
            studentId={studentId}
            name={name}
            purchasedDate={purchasedDate}
            ticketsNumber={ticketsNumber}
            isWinner={isWinner}
            isPickedup={isPickedup}
            pickupDate={pickupDate}
            onChangeToggle={onChangeToggle}
            raffleId={raffleId}
          />
        );
      }),
    [studentList, onChangeToggle]
  );
  return (
    <Wrapper>
      <Header>
        <Title>Students</Title>
      </Header>
      <ListWrapper>
        <HeadTr>
          <HeadTh>Student</HeadTh>
          <HeadTh>First Purchase</HeadTh>
          <HeadTh># Tickets</HeadTh>
          <HeadTh>Winner</HeadTh>
          <HeadTh>Picked up?</HeadTh>
          <HeadTh>Date Picked up</HeadTh>
        </HeadTr>

        {renderStudent}
      </ListWrapper>
    </Wrapper>
  );
};

export default StudentList;
