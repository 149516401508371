import {
  SET_RAFFLE_TICKET_DETAIL,
  SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
  SET_RAFFLE_TICKET_STUDENT_LIST,
  RAFFLE_TICKET_DETAIL_TOAST_MESSAGE,
  SET_PICKUP_STUDENT_RAFFLE_TICKET,
  SET_RAFFLE_TICKET_DETAIL_IMAGE,
} from "constant";
import produce from "immer";
const INITIAL_STATE = {
  raffleTicketDetail: {},
  raffleTicketImage: { url: "", isLoading: false },
  pageStatus: "DEFAULT",
  raffleTicketStudents: {},
  toastMessage: {
    message: "",
    hasError: false,
  },
};

const raffleTicketDetailReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_RAFFLE_TICKET_DETAIL:
        draft.raffleTicketDetail = action.payload;
        break;
      case SET_RAFFLE_TICKET_DETAIL_IMAGE:
        draft.raffleTicketImage = action.payload;
        break;
      case SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS:
        draft.pageStatus = action.payload;
        break;
      case SET_RAFFLE_TICKET_STUDENT_LIST:
        draft.raffleTicketStudents = action.payload;
        break;
      case SET_PICKUP_STUDENT_RAFFLE_TICKET:
        draft.raffleTicketStudents[action.payload.id] = {
          ...draft.raffleTicketStudents[action.payload.id],
          isPickedup: action.payload.isPickedup,
          pickupDate: `${new Date(
            action.payload.pickupDate
          ).getDate()}-${new Date(
            action.payload.pickupDate
          ).getMonth()}-${new Date(action.payload.pickupDate).getFullYear()}`,
        };
        break;
      case RAFFLE_TICKET_DETAIL_TOAST_MESSAGE:
        draft.toastMessage = action.payload;
        break;
      // no default
    }
  });
export default raffleTicketDetailReducer;
