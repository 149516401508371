import {
  GET_XP_BOUNES,
  GET_XP_USER_ORGANIZATION_PROJECT,
  GET_XP_USER_ORGANIZATION_PROJECT_LIST,
} from "../constant/xp";

export const xpsBounesReducer = (state = { xps: [] }, action) => {
  switch (action.type) {
    case GET_XP_BOUNES:
      return { xps: action.payload };
    case GET_XP_USER_ORGANIZATION_PROJECT:
      return { ...state, totalAmount: action.payload };
    default:
      return state;
  }
};

export const xpsListProject = (state = { xpList: [] }, action) => {
  switch (action.type) {
    case GET_XP_USER_ORGANIZATION_PROJECT_LIST:
      return { xpList: action.payload };
    default:
      return state;
  }
};
