import React from 'react';
import './gems.style.css';
// import collagecoins from '../../assets/image/collagecoins.png';
import GemIcon from "../../assets/image/gemIcon.png";
const Gems = ({coinsNumber}) => {
    return (
        <div className="coins-student">
            <img src={GemIcon} alt="" style={{width:35}}/>
            <p className="coins-number">{coinsNumber}</p>
            <p className="coins">Gem</p>
        </div>
    )
}

export default Gems
