import React from "react";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import {
  EditButton,
  EditButtonText,
  EditButtonIcon,
} from "assets/style/General.style";
import "components/shop/shop.style.css";
import { withRouter } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { withStyles } from "@material-ui/core/styles";

const ShopItem = ({
  _id,
  prize,
  description,
  imageUrl,
  currentStock,
  shop,
  prizeWheel,
  gemNumber,
  levelNumber,
  haveAccess,
  onEditButtonClick,
}) => {
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <>
      <TableRow style={{ borderBottom: "2px solid #EFF2F7" }}>
        <TableCell style={{ borderBottom: 0 }}>
          <img
            src={imageUrl}
            alt="asternuts"
            width="50"
            height="50"
            style={{ borderRadius: 50 }}
          />
        </TableCell>
        <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
          {prize}
        </TableCell>
        <TableCell
          style={{
            borderBottom: 0,
            whiteSpace: "normal",
            width: "150px",
          }}
        >
          {description}
        </TableCell>
        <TableCell style={{ borderBottom: 0 }}>{currentStock}</TableCell>
        <TableCell style={{ borderBottom: 0 }}>{shop ? "Yes" : "No"}</TableCell>
        <TableCell style={{ borderBottom: 0 }}>
          {prizeWheel ? "Yes" : "No"}
        </TableCell>
        <TableCell style={{ borderBottom: 0 }}>{gemNumber}</TableCell>
        <TableCell style={{ borderBottom: 0 }}>{levelNumber}</TableCell>
        <TableCell style={{ borderBottom: 0 }} className="addXp">
          {haveAccess && (
            <EditButton
              style={{ marginLeft: "10px", width: "120px" }}
              onClick={onEditButtonClick}
            >
              <EditButtonText>Edit/Review</EditButtonText>
              <EditButtonIcon>
                <MdEdit color="#34C38F" size={12} />
              </EditButtonIcon>
            </EditButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default withRouter(ShopItem);
