import React, { useState } from "react";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import {
  Wrapper,
  InfoSection,
  AvailableSection,
  InventorySection,
  InfoTitle,
  InfoItem,
  InfoText,
  InfoTextArea,
  AvailableTitle,
  AvailableItem,
  AvailableText,
  InventoryTitle,
  InventoryItem,
  InventoryText,
  InventoryTextArea,
  InventoryAdd,
  InventoryWrapper,
  EditInventoryBtn,
} from "./index.style";

const CenterColumn = ({
  costDollar,
  onCostDollarChange,
  gemsNumber,
  onGemsNumberChange,
  unlockLevel,
  onUnlockLevelChange,
  isStore,
  onStoreChange,
  isPrizeWheel,
  onPrizeWheelChange,
  initialStock,
  currentStock,
  addedInventoryNum,
  onAddedInventoryNumChange,
  onAddStockClick,
  onEditInventory,
  purchaseLimitState,
  purchaseLimitCount,
  onPurchaseLimitChange,
  purchaseLimitCountChage,
}) => {
  const [purchaseLimitToggel, setPurchaseLimitToggel] = useState(true);

  return (
    <Wrapper>
      <InfoSection>
        <InfoTitle>Info</InfoTitle>
        <InfoItem>
          <InfoText>Cost per Item ($)</InfoText>
          <InfoTextArea value={costDollar} onChange={onCostDollarChange} />
        </InfoItem>
        <InfoItem>
          <InfoText>Suggested Gems</InfoText>
          <InfoTextArea
            placeholder="gems"
            style={{ border: "none", outline: "none", fontSize: "10px" }}
          />
        </InfoItem>
        <InfoItem>
          <InfoText>Price in Gems</InfoText>
          <InfoTextArea value={gemsNumber} onChange={onGemsNumberChange} />
        </InfoItem>
        <InfoItem>
          <InfoText>Unlock Level</InfoText>
          <InfoTextArea value={unlockLevel} onChange={onUnlockLevelChange} />
        </InfoItem>
        <InfoItem>
          <InfoText
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Purchase limit
            {console.log(purchaseLimitState)}
            <CustomSwitch
              style={{ marginLeft: 10 }}
              isToggle={purchaseLimitState}
              onChangeToggle={onPurchaseLimitChange}
            />
          </InfoText>
          {purchaseLimitState && (
            <InfoTextArea
              value={purchaseLimitCount}
              onChange={purchaseLimitCountChage}
            />
          )}
        </InfoItem>
      </InfoSection>
      <AvailableSection>
        <AvailableTitle>Available</AvailableTitle>
        <AvailableItem>
          <AvailableText>Shop</AvailableText>
          <CustomSwitch isToggle={isStore} onChangeToggle={onStoreChange} />
        </AvailableItem>
        <AvailableItem>
          <AvailableText>Prize Wheel</AvailableText>
          <CustomSwitch
            isToggle={isPrizeWheel}
            onChangeToggle={onPrizeWheelChange}
          />
        </AvailableItem>
      </AvailableSection>
      <InventorySection>
        <InventoryTitle>Inventory</InventoryTitle>
        <InventoryItem>
          <InventoryText style={{ opacity: "50%" }}>
            Initial Stock
          </InventoryText>
          <InventoryText style={{ opacity: "50%" }}>
            {initialStock}
          </InventoryText>
        </InventoryItem>
        <InventoryItem>
          <InventoryText>Current Stock</InventoryText>
          <InventoryText>{currentStock}</InventoryText>
        </InventoryItem>
        <InventoryItem>
          <InventoryText>Add Stock</InventoryText>
          <InventoryWrapper>
            <InventoryAdd onClick={onAddStockClick}>+</InventoryAdd>
            <InventoryTextArea
              value={addedInventoryNum}
              onChange={onAddedInventoryNumChange}
            />
          </InventoryWrapper>
        </InventoryItem>
        <EditInventoryBtn onClick={onEditInventory}>
          Edit Full Inventory
        </EditInventoryBtn>
      </InventorySection>
    </Wrapper>
  );
};

export default CenterColumn;
