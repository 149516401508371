import React, { useEffect, useMemo, useState } from "react";
import { switchRoute } from "../helpers";
import { useDispatch, useSelector } from "react-redux";

import {
  LayoutContainer,
  LayoutContent,
  LayoutSidebar,
  Logo,
  LayoutSidebarFooter,
  LayoutSidebarUpperText,
  SpanSidebar,
  LayoutHeader,
  LayoutHeaderContainer,
  Flag,
  ProfileImage,
  ProfileInfo,
  LayoutSidebarMenu,
  LayoutSidebarMenuOption,
  LayoutSidebarMenuOptionItem,
  SettingContainer,
  SettingItem,
  LayoutSidebarDownerText,
  LayoutSidebarDownerTextSpan,
} from "../components/styled.components";
import logo from "../assets/image/gradegyLogo.png";
import flag from "../assets/image/flag.png";
import { BiBell } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";
import profilePic from "../assets/image/asternuts.png";
import { getRoutes } from "../routes";
import { FaSignOutAlt } from "react-icons/fa";
import "./admin.css";
import { loggedInUserAction, loggOutUser } from "../actions/authAction";
import { useLocation } from "react-router-dom";
import { universityListAction } from "../actions/universityActions";
import { selectedAppProjectIdSelector } from "store/selectors";

const Admin = ({ history }) => {
  const location = useLocation();
  const route = location.pathname.split("/")[2];
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const dispatch = useDispatch();
  const { user, loading, isAuthenticated, error } = useSelector(
    (state) => state.loggedInUser
  );
  const { token } = useSelector((state) => state.auth);
  const [setting, setSetting] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(loggedInUserAction());

      dispatch(universityListAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const renderRoutes = useMemo(
    () =>
      getRoutes(user).map((route, index) => {
        if (route.show && route.partOf === "Admin") {
          return (
            <LayoutSidebarMenuOption key={index}>
              <LayoutSidebarMenuOptionItem
                to={`${
                  route.params
                    ? route.layout +
                      route.path.replace(
                        ":id",
                        user?.system[0]?.organization?._id
                      )
                    : route.layout + route.path
                }`}
              >
                <route.icon color="#fff" size={20} />
                <span className="sidebar_item_name">{route.name}</span>
              </LayoutSidebarMenuOptionItem>
            </LayoutSidebarMenuOption>
          );
        }

        return null;
      }),
    [user]
  );

  return (
    <div>
      {!token && history.push("/login")}
      {loading ? (
        <h1>Loading...</h1>
      ) : error ? (
        history.push("/login")
      ) : user.role === "user" ? (
        <h1>Access Denied</h1>
      ) : (
        <LayoutContainer>
          <LayoutSidebar>
            <Logo src={logo} />
            <LayoutSidebarMenu>
              <div className="part-of-home">
                {getRoutes(user).map((route, index) => {
                  if (route.show && route.partOf === "Home") {
                    return (
                      <LayoutSidebarMenuOption key={index}>
                        <LayoutSidebarMenuOptionItem
                          to={`${route.layout}${route.path}`}
                        >
                          <route.icon color="#fff" size={20} />
                          <span className="sidebar_item_name">
                            {route.name}
                          </span>
                        </LayoutSidebarMenuOptionItem>
                      </LayoutSidebarMenuOption>
                    );
                  }
                  return null;
                })}
              </div>
              {selectedProjectId && (
                <div className="part-of-student">
                  <h4>Student</h4>
                  {getRoutes(user).map((route, index) => {
                    if (route.show && route.partOf === "Student") {
                      return (
                        <LayoutSidebarMenuOption key={index}>
                          <LayoutSidebarMenuOptionItem
                            to={`${route.layout}${route.path}`}
                          >
                            <route.icon color="#fff" size={20} />
                            <span className="sidebar_item_name">
                              {route.name}
                            </span>
                          </LayoutSidebarMenuOptionItem>
                        </LayoutSidebarMenuOption>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {user.role !== "admin" && (
                <div className="part-of-admin">
                  <h4>Admin</h4>
                  {renderRoutes}
                </div>
              )}
            </LayoutSidebarMenu>
            <LayoutSidebarFooter>
              <LayoutSidebarUpperText>
                Powered by <SpanSidebar>GRADegy</SpanSidebar>
              </LayoutSidebarUpperText>
              <LayoutSidebarDownerText>
                Organized by{" "}
                <LayoutSidebarDownerTextSpan>UFCS</LayoutSidebarDownerTextSpan>
              </LayoutSidebarDownerText>
            </LayoutSidebarFooter>
          </LayoutSidebar>
          <LayoutContent>
            <>
              <LayoutHeader>
                <LayoutHeaderContainer spacing={110}>
                  <Flag src={flag} />
                </LayoutHeaderContainer>
                <LayoutHeaderContainer spacing={2}>
                  <BiBell color={"#707070"} size={24} />
                </LayoutHeaderContainer>
                <LayoutHeaderContainer spacing={20}>
                  <ProfileImage src={profilePic} />
                  <ProfileInfo>
                    {user.firstName} {user.lastName}
                  </ProfileInfo>
                </LayoutHeaderContainer>
                <LayoutHeaderContainer
                  spacing={10}
                  onClick={() => setSetting(!setting)}
                >
                  <AiOutlineSetting
                    color={"#555B6D"}
                    className="setting_icon"
                    size={24}
                  />
                  {setting ? (
                    <SettingContainer>
                      <SettingItem
                        onClick={() => {
                          dispatch(loggOutUser());
                          history.push("/login");
                        }}
                      >
                        {" "}
                        <FaSignOutAlt
                          size={24}
                          style={{ marginRight: 10 }}
                        />{" "}
                        Sing Out
                      </SettingItem>
                    </SettingContainer>
                  ) : null}
                </LayoutHeaderContainer>
              </LayoutHeader>
              {switchRoute(user)}
            </>
          </LayoutContent>
        </LayoutContainer>
      )}
    </div>
  );
};

export default Admin;
