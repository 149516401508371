import React, { useContext, useEffect } from "react";
import ExpreienceStudentContext from "../../context/expreienceStudent/experienceStudentContext";
import CircleChart from "../charts/CircleChart";
import "./reviewanalyis.css";
import {
  calculatePercent,
  calulateCircleChartPercent,
} from "../../helpers/calculatePercent";
import { useDispatch, useSelector } from "react-redux";
import { listUserExperienceAction } from "../../actions/listUserExperienceAction";
import Spinner from "../spinner/spinner";
import {
  calcChartPercentageCompleted,
  clacExpereinceAnalizeChartSelector,
} from "reducers/experienceStudentList/experienceStudentList.selector";

const ExperienceReviewAnayis = ({ experience }) => {
  const dispatch = useDispatch();
  const { total, completedTask, notCompletedTask, views } = useSelector(
    clacExpereinceAnalizeChartSelector
  );
  // const { total, completedCount, notCompleted, viewCount } = useSelector(
  //   calcChartPercentageCompleted
  // );
  // const {
  //   listUsers,
  //   completeCount,
  //   viewCount,
  //   notCompleteCount,
  //   loading,
  //   error,
  // } = useSelector((state) => state.listOfUserExperience);

  // const organization = experience.organization ? experience.organization : null;
  // const project = experience.project ? experience.project._id : null;
  // const category = experience.category ? experience.category._id : null;
  // const subcategory = experience.subCategory
  //   ? experience.subCategory._id
  //   : null;

  useEffect(() => {
    // dispatch(
    //   listUserExperienceAction(
    //     organization,
    //     project,
    //     category,
    //     subcategory,
    //     experience._id
    //   )
    // );
  }, []);

  return (
    <>
      <div className="review-analyis">
        <div className="analyis-header">
          <div className="completion-rate">
            <p>Completion</p>
            {/* <p>Rate</p> */}
          </div>
          <div className="cirlce-chart">
            <CircleChart
              scale={0.5}
              left={-80}
              top={-50}
              message=""
              percent={calculatePercent(completedTask, total)}
              chartPercentCal={calulateCircleChartPercent(
                495,
                calculatePercent(completedTask, total)
              )}
            />
          </div>
        </div>

        <div className="counter-student-container">
          <div className="total-student">
            <p>Total Students</p>
            <p>{total}</p>
          </div>
          <div className="compelete-ex">
            <p className="complete-ex-text">Compelete</p>
            <p className="complete-ex-value">{completedTask}</p>
            <div className="complete-ex-liner-container">
              <div
                className="compelte-ex-liner-chart"
                style={{
                  width:
                    calculatePercent(completedTask, total).toString() + "%",
                }}
              ></div>
            </div>
          </div>
          <div className="not-compeleted-ex">
            <p className="complete-ex-text">Not Compelete</p>
            <p className="complete-ex-value">{notCompletedTask}</p>
            <div className="complete-ex-liner-container">
              <div
                className="not-compelte-ex-liner-chart"
                style={{
                  width:
                    calculatePercent(notCompletedTask, total).toString() + "%",
                }}
              ></div>
            </div>
          </div>
          <div className="view-ex">
            <p className="complete-ex-text">Viewed</p>
            <p className="complete-ex-value">{views}</p>
            <div className="complete-ex-liner-container">
              <div
                className="view-ex-liner-chart"
                style={{
                  width: calculatePercent(views, total).toString() + "%",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperienceReviewAnayis;
