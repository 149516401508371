// SortByName
const sortNameHandler = (a, b) => {
  const x = a.firstName.toLowerCase();
  const y = b.firstName.toLowerCase();
  const res = x < y ? -1 : x > y ? 1 : 0;
  return res;
};
// export const sortStudentsByName = (studentData, isReversed, filterSearch) => {
//   let result = [];
//   if (studentData?.data) {
//     studentData.data.sort(sortNameHandler);
//     if (isReversed) studentData.data.reverse();
//     result = studentData.data;
//     if (filterSearch) {
//       result = studentData.data.filter(
//         (item) =>
//           item.firstName.toLowerCase().match(filterSearch.toLowerCase()) ||
//           item.lastName.toLowerCase().match(filterSearch.toLowerCase())
//       );
//       return { ...studentData, data: result };
//     }
//     return { ...studentData, data: result };
//   }
//   return { ...studentData, data: result };
// };
const studentSearchInput = (studentData, input) => {
  let result = [];
  result = studentData.data;
  if (input) {
    result = studentData.data.filter(
      (item) =>
        item.firstName.toLowerCase().match(input.toLowerCase()) ||
        item.lastName.toLowerCase().match(input.toLowerCase())
    );
    return { ...studentData, data: result };
  }
  return { ...studentData, data: result };
};
const sortStudentsByName = (studentData, isReversed) => {
  let result = [];
  result = studentData.data;
  if (studentData?.data) {
    studentData.data.sort(sortNameHandler);
    if (isReversed) studentData.data.reverse();
    return { ...studentData, data: result };
  }
  return { ...studentData, data: result };
};
export const sortStudent = (studentData, { type, isReversed }, input) => {
  let studentList = studentData;
  switch (type) {
    case "Name":
      studentList = sortStudentsByName(studentData, isReversed, input);
      break;
    default:
      studentList = studentData;
      break;
  }
  return studentSearchInput(studentList, input);
};
const filterStudentListById = (studentList, filterItem, selectedId) => {
  const filtered = [];
  studentList &&
    studentList?.data?.map((studentItem) => {
      if (studentItem.system.find((sys) => sys[filterItem]?._id === selectedId))
        filtered.push(studentItem);
      return { ...studentList, data: filtered };
    });
  return { ...studentList, data: filtered };
};
export const filterStudentList = (
  studentList,
  organizationId,
  projectId,
  categoryId,
  subCategoryId
) => {
  const x = organizationId
    ? filterStudentListById(studentList, "organization", organizationId)
    : studentList;
  const y =
    organizationId && projectId
      ? filterStudentListById(x, "project", projectId)
      : x;
  const z =
    organizationId && projectId && categoryId
      ? filterStudentListById(y, "category", categoryId)
      : y;
  const w =
    organizationId && projectId && categoryId && subCategoryId
      ? filterStudentListById(z, "subCategory", subCategoryId)
      : z;
  return w;
};
export const studentFilterTransformer = (filter) => {
  let params = `filters=[{"field":"role","value":"user"}]&`;
  if (!filter.active) {
    params = `filters=[{"field":"role","value":"user"}]&`;
  }
  if (filter?.active) {
    params = `filters=[{"field":"role","value":"user"},{"field":"active","value":"${filter?.active}"}]&`;
  }
  if (filter?.category) {
    params = `filters=[{"field":"role","value":"user"},{"field":"category","value":"${filter?.category}"}]&`;
  }
  if (filter?.category && filter.subCategory)
    params = `filters=[{"field":"role","value":"user"},{"field":"category","value":"${filter?.category}"},{"field":"subCategory","value":"${filter?.subCategory}"}]&`;
  if (filter?.searchWord)
    params = params.concat(`search=${filter?.searchWord}&`);
  if (filter?.sort)
    params = params.concat(
      `sort={"field":"${filter?.sort?.field}","order":"${
        filter?.sort.order ? "desc" : "asc"
      }"}&`
    );
  if (filter?.page) params = params.concat(`page=${filter?.page}&`);
  return params;
};
