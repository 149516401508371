import { SET_RAFFLE_TICKET_ITEM_PHOTO } from "constant";
import store from "Store";
import axios from "axios";


export const changeRaffleImageUrl = (dispatch, photoUrl) => {
  dispatch({
    type: SET_RAFFLE_TICKET_ITEM_PHOTO,
    payload: { url: photoUrl, isLoading: false },
  });
};
export const uploadRaffleItemImage = async (dispatch, photoFile) => {
  try {
    const formData = new FormData();
    formData.append("file", photoFile);
    console.log(formData);
    dispatch({
      type: SET_RAFFLE_TICKET_ITEM_PHOTO,
      payload: { url: "", isLoading: true },
    });

    const { data } = await axios.post(
      `https://apigrad.azurewebsites.net/api/v2/photos`,
      formData,
      {
        headers: { Authorization: `Bearer ${store.getState().auth.token}` },
      }
    );
    dispatch({
      type: SET_RAFFLE_TICKET_ITEM_PHOTO,
      payload: {
        url: `https://apigrad.azurewebsites.net${data.url}`,
        isLoading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: SET_RAFFLE_TICKET_ITEM_PHOTO,
      payload: { url: 'https://apigrad.azurewebsites.net/static/images/oragapic/default-back-raffle.jpg', isLoading: false },
    });
  }
};
