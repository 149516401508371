import {
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_UPDATE_FAIL,
  USER_DETAILS_UPDATE_REQUEST,
  USER_DETAILS_UPDATE_SUCCESS,
  UPDATE_XP_USER
} from "../constant/userDetails";

export const userDetailsReducer = (
  state = { user: { schoolDistrict: {} } },
  action
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true, user: { schoolDistrict: {} } };
    case USER_DETAILS_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        user: { schoolDistrict: {} },
        error: action.payload,
      };
    case USER_DETAILS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        user: { schoolDistrict: {} },
        error: {},
      };
    case USER_DETAILS_UPDATE_SUCCESS:
      return { ...state, loading: false, user: action.payload, error: {} };
    case USER_DETAILS_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        user: { schoolDistrict: {} },
        error: action.payload,
      };
    case UPDATE_XP_USER:
      return{
        ...state,
        user:{...state.user,xp:state.user.xp + action.payload.amount}
      }  
    default:
      return state;
  }
};
