import {
  DELETE_ADMIN_DETAIL_HIGHSCHOOL,
  SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID,
} from "constant";

export const removeSelectedHighSchool = (dispatch, id) => {
  dispatch({
    type: DELETE_ADMIN_DETAIL_HIGHSCHOOL,
    payload: id,
  });
  dispatch({
    type: SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID,
    payload: "",
  });
};
