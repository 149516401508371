import { COUNT_RANGE_EXP_FAIL,COUNT_RANGE_EXP_SUCCESS,COUNT_RANGE_EXP_REQUIRE } from "../constant/pmChart";


export const pmChartInfoReducer = (state={experienceRangeCount:0,completeRangeCount:0},action) =>{
    switch(action.type){
        case COUNT_RANGE_EXP_REQUIRE:
         return{ loading:true}
        case COUNT_RANGE_EXP_SUCCESS:
            return {loading:false,experienceRangeCount:action.payload.experienceRangeCount,completeRangeCount:action.payload.completeRangeCount}
        case COUNT_RANGE_EXP_FAIL:
            return {loading:false,experienceRangeCount:0,completeRangeCount:0,error:action.payload}
        default:
        return state        
    }
}