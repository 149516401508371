import { gradify } from "axios.config";
import store from "Store";
import {
  SET_SHOPITEM_DETAIL_PAGE_STATUS,
  SET_SHOP_ITEM_DETAIL,
  SET_SHOPITEM_STUDENT_LIST,
  SET_SHOP_ITEM_DETAIL_IMAGE,
} from "constant";
import { formatItemStudents } from "helpers";
export const getShopItemDetailAction = (itemId) => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    dispatch({
      type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
      payload: "LOADING",
    });
    const { data } = await gradify.get(`shopitems/${itemId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: SET_SHOP_ITEM_DETAIL,
      payload: data.data,
    });
    dispatch({
      type: SET_SHOP_ITEM_DETAIL_IMAGE,
      payload: { url: data.data?.image, isLoading: false },
    });
    dispatch({
      type: SET_SHOPITEM_STUDENT_LIST,
      payload: formatItemStudents(data.data.students),
    });
    // dispatch({
    //   type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
    //   payload: "DEFAULT",
    // });
  } catch (error) {
    console.log(error);
  }
};
