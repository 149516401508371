import React from "react";
import SelectComponent from "components/control.component/select.component.jsx";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  LabelStudent,
  StudentTagContainer,
  AddTagRow,
  AddTagPopup,
  AddTagColumn,
  AddTagTitle,
  AddTagCloseIcon,
} from "components/styled.components/create.student";
import { FaPlus } from "react-icons/fa";
import { SaveButton, StudentButton } from "components/styled.components";
import { AiOutlineCloseCircle } from "react-icons/ai";

const TagComponent = ({ tagPanel, setTagPanel }) => {
  return (
    <>
      <CreateStudentFormRow
        style={{
          marginTop: 20,
          padding: 10,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <CreateStudentFormCol Direction="column">
          <StudentButton
            style={{
              borderRadius: 50,
              marginTop: 5,
              marginRight: 10,
              marginLeft: 0,
            }}
            onClick={() => {
              setTagPanel(!tagPanel);
            }}
          >
            <FaPlus style={{ marginRight: 12 }} size={27} />{" "}
            <span>Add Tag</span>{" "}
          </StudentButton>
          {tagPanel ? (
            <>
              <AddTagRow>
                <AddTagPopup>
                  <AddTagColumn alignment={"center"}>
                    <AddTagTitle style={{ marginTop: 18 }}>Add Tag</AddTagTitle>
                    <AddTagCloseIcon
                      onClick={() => {
                        setTagPanel(!tagPanel);
                      }}
                    >
                      <AiOutlineCloseCircle
                        spaceLeft="18px"
                        color={"#707070"}
                      />
                    </AddTagCloseIcon>
                  </AddTagColumn>
                  <AddTagColumn Direction="column" alignment={"center"}>
                    <LabelStudent htmlFor="specialProject">
                      Special Project
                    </LabelStudent>
                    <SelectComponent
                      style={{ marginLeft: 10, marginRight: 10 }}
                      options={""}
                      name="specialProject"
                      value={""}
                      onChange={() => {}}
                    />
                  </AddTagColumn>
                  <AddTagColumn
                    alignment={"center"}
                    style={{
                      justifyContent: "space-around",
                      marginTop: "13%",
                    }}
                  >
                    <SaveButton>SAVE</SaveButton>
                    <SaveButton>Cancel</SaveButton>
                  </AddTagColumn>
                </AddTagPopup>
              </AddTagRow>
            </>
          ) : null}
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="row" style={{ width: "70%" }}>
          <StudentTagContainer></StudentTagContainer>
        </CreateStudentFormCol>
      </CreateStudentFormRow>
    </>
  );
};

export default TagComponent;
