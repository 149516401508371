import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "components/control.component/select.component";

import {
  CreateAdminFormCol,
  CreateAdminContainer,
  CreateAdminForm,
  CreateAdminFormRow,
  AdminInput,
  LabelAdmin,
  AdminTitle,
  AdminImage,
  AdminEditButtton,
  AdminEditButttonText,
  AdminEditButttonIcon,
  AddAdminButton,
  AdminHexImage,
  AdminLink,
} from "components/styled.components/admin.style";

import { MdEdit } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import { FaUnlink } from "react-icons/fa";
import AdvanceSelect from "components/control.component/advenceSelect";
import organizationImage from "assets/image/defualt-icon.png";
import hexAsset from "assets/image/HexAsset.png";
import { Spinner } from "./index.style";
const AddAdminComponent = ({
  firstName,
  onFirstNameChange,
  lastName,
  onLastNameChange,
  onChangeImgPic,
  onAdminLevelChange,
  onOrganizationChange,
  organizations,
  onPhoneValueChange,
  adminPhoto,
  phoneNumber,
  email,
  onEmailChange,
  jobTitle,
  onJobTitleChange,
  category,
  onSchoolChange,
  loadHighSchoolOptions,
  onUniversityChange,
  loadUniversityOptions,
  selectedOrganization,
  selectedProject,
  onProjectChange,
  schoolLink,
  setSchoolLink,
  onCancelAdminClick,
  onSaveAddeAdminClick,
  isAddAdminLoading,
  selectedLevel,
  AdminLevelOptions,
  isEditting,
  organizationName,
}) => {
  const adminImgInputRef = useRef();
  const handleOnEditImgClicked = useCallback(() => {
    if (adminImgInputRef) adminImgInputRef.current.click();
  }, []);
  return (
    <CreateAdminContainer className="form-Admin-add" onClick={() => {}}>
      <CreateAdminForm>
        <CreateAdminFormRow style={{ paddingLeft: 55 }}>
          <CreateAdminFormCol
            alignment="flex-start"
            style={{ paddingLeft: 0, position: "relative" }}
          >
            {adminPhoto.isLoading ? (
              <Spinner />
            ) : (
              <AdminImage
                src={adminPhoto.url || organizationImage}
                alt="gradegy"
              />
            )}
            <AdminHexImage src={hexAsset} alt="gradegy" />
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              ref={adminImgInputRef}
              onChange={onChangeImgPic}
            />
            <AdminEditButtton onClick={handleOnEditImgClicked}>
              <AdminEditButttonText>Edit</AdminEditButttonText>
              <AdminEditButttonIcon>
                <MdEdit color="#34C38F" size={17} />
              </AdminEditButttonIcon>
            </AdminEditButtton>
          </CreateAdminFormCol>
          <CreateAdminFormCol
            Direction="column"
            alignment="sttart"
            style={{ alignItems: "center" }}
          >
            <div
              style={{
                paddingLeft: 12,
                paddingTop: 22,
              }}
            >
              <AdminInput
                Width="329px"
                Height="53px"
                FontFamily="Rajdhani"
                Size="41px"
                Weight="700"
                name="firsrName"
                placeholder="First Name"
                value={firstName}
                onChange={onFirstNameChange}
              />
              <AdminInput
                Width="329px"
                Height="53px"
                FontFamily="Rajdhani"
                Size="41px"
                Weight="700"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={onLastNameChange}
              />
            </div>
            <CreateAdminFormCol
              Direction="column"
              style={{ paddingTop: 20, paddingright: "24%" }}
            >
              <LabelAdmin>Admin Level</LabelAdmin>
              <SelectComponent
                options={AdminLevelOptions}
                name="admin level"
                onChange={onAdminLevelChange}
                selected={true}
                selectedValue={selectedLevel}
                style={{
                  paddingTop: 1,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 1,
                  minWidth: 129,
                  fontSize: 10,
                  minHeight: 33,
                  backgroundPosition: "bottom 12px right 6px",
                }}
              />
            </CreateAdminFormCol>
          </CreateAdminFormCol>
        </CreateAdminFormRow>
        <CreateAdminFormRow
          style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 35 }}
        >
          <AdminTitle>Organization Info</AdminTitle>
        </CreateAdminFormRow>
        <CreateAdminFormRow
          style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 4 }}
        >
          <CreateAdminFormCol
            style={{
              padding: 0,
              borderRightWidth: 2,
              borderRightColor: "#c8c8c8",
              borderRightStyle: "solid",
            }}
            Direction="column"
          >
            <LabelAdmin>Organization</LabelAdmin>
            {isEditting ? (
              <LabelAdmin>{organizationName}</LabelAdmin>
            ) : (
              <SelectComponent
                options={organizations}
                name="organization"
                selectedValue={selectedOrganization}
                onChange={onOrganizationChange}
                isDisabled={false}
                style={{
                  paddingTop: 1,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 1,
                  minWidth: 129,
                  fontSize: 10,
                  minHeight: 33,
                  width: 158,
                  backgroundPosition: "bottom 12px right 6px",
                }}
              />
            )}
          </CreateAdminFormCol>
          {selectedOrganization?.organization_type !== "CBO" && (
            <>
              <CreateAdminFormCol
                style={{
                  width: "168px",
                  paddingRight: 10,
                  borderRightWidth: 2,
                  borderRightColor: "#c8c8c8",
                  borderRightStyle: "solid",
                }}
                Direction="column"
              >
                {selectedOrganization?.organization_type === "Secondary" && (
                  <>
                    <LabelAdmin>School District</LabelAdmin>
                    <SelectComponent
                      options={selectedOrganization?.projects}
                      name="project"
                      selected="true"
                      selectedValue={selectedProject?._id}
                      onChange={onProjectChange}
                      style={{
                        paddingTop: 1,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 1,
                        minWidth: 129,
                        fontSize: 10,
                        minHeight: 33,
                        width: 158,
                        backgroundPosition: "bottom 12px right 6px",
                      }}
                    />
                  </>
                )}
              </CreateAdminFormCol>

              <CreateAdminFormCol
                Direction="column"
                style={{ position: "relative" }}
              >
                {selectedOrganization?.organization_type === "Secondary" && (
                  <>
                    <LabelAdmin>School</LabelAdmin>
                    <AdvanceSelect
                      onChange={onSchoolChange}
                      loadOptions={loadHighSchoolOptions}
                    />
                  </>
                )}
                {selectedOrganization?.organization_type ===
                  "Post Secondary" && (
                  <>
                    <LabelAdmin>University</LabelAdmin>
                    <AdvanceSelect
                      onChange={onUniversityChange}
                      loadOptions={loadUniversityOptions}
                    />
                  </>
                )}
                {!isEditting &&
                  (schoolLink ? (
                    <AdminLink
                      onClick={() => {
                        setSchoolLink(false);
                      }}
                    >
                      <FaLink color="#000" size={16} />
                    </AdminLink>
                  ) : (
                    <AdminLink
                      onClick={() => {
                        setSchoolLink(true);
                      }}
                    >
                      <FaUnlink color="#000" size={16} />
                    </AdminLink>
                  ))}
              </CreateAdminFormCol>
              {!isEditting &&
                selectedOrganization?.organization_type === "Secondary" && (
                  <CreateAdminFormCol Direction="column">
                    <LabelAdmin>Category</LabelAdmin>
                    <LabelAdmin style={{ marginLeft: "10px" }}>
                      {schoolLink ? category : "..."}
                    </LabelAdmin>
                  </CreateAdminFormCol>
                )}
              {!isEditting &&
                selectedOrganization?.organization_type ===
                  "Post Secondary" && (
                  <CreateAdminFormCol Direction="column">
                    <LabelAdmin>Project</LabelAdmin>
                    <LabelAdmin style={{ marginLeft: "10px" }}>
                      {schoolLink ? selectedProject?.name : "..."}
                    </LabelAdmin>
                  </CreateAdminFormCol>
                )}
            </>
          )}
        </CreateAdminFormRow>

        <CreateAdminFormRow
          style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 16 }}
        >
          <AdminTitle>About</AdminTitle>
        </CreateAdminFormRow>
        <CreateAdminFormRow
          style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 4 }}
        >
          <CreateAdminFormCol
            style={{
              padding: 0,
              borderRightWidth: 2,
              borderRightColor: "#c8c8c8",
              borderRightStyle: "solid",
            }}
            Direction="column"
          >
            <LabelAdmin>Phone</LabelAdmin>
            <AdminInput
              Width="158px"
              Height="33px"
              FontFamily="Rajdhani"
              Size="20px"
              SpacingTB="1px"
              SpacingLR="10px"
              value={phoneNumber}
              onChange={onPhoneValueChange}
              style={{
                marginRight: "10px",
                marginLeft: "0px",
              }}
            />
          </CreateAdminFormCol>
          <CreateAdminFormCol
            style={{
              paddingRight: 0,
              borderRightWidth: 2,
              borderRightColor: "#c8c8c8",
              borderRightStyle: "solid",
            }}
            Direction="column"
          >
            <LabelAdmin>Email</LabelAdmin>
            <AdminInput
              Width="158px"
              Height="33px"
              FontFamily="Rajdhani"
              Size="20px"
              SpacingTB="1px"
              SpacingLR="10px"
              value={email}
              onChange={onEmailChange}
              style={{
                marginRight: "10px",
                marginLeft: "0px",
              }}
            />
          </CreateAdminFormCol>
          <CreateAdminFormCol Direction="column">
            <LabelAdmin>Job Title</LabelAdmin>
            <AdminInput
              Width="158px"
              Height="33px"
              FontFamily="Rajdhani"
              Size="20px"
              SpacingTB="1px"
              SpacingLR="10px"
              value={jobTitle}
              onChange={onJobTitleChange}
              style={{
                marginRight: "10px",
                marginLeft: "0px",
              }}
            />
          </CreateAdminFormCol>
        </CreateAdminFormRow>

        <CreateAdminFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 30,
            marginBottom: 30,
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CreateAdminFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <AddAdminButton onClick={onSaveAddeAdminClick} disabled={""}>
              {isAddAdminLoading ? "Sending..." : "SAVE"}
            </AddAdminButton>
          </CreateAdminFormCol>
          <CreateAdminFormCol style={{ paddingLeft: 30 }}>
            <AddAdminButton onClick={onCancelAdminClick}>Cancel</AddAdminButton>
          </CreateAdminFormCol>
        </CreateAdminFormRow>
      </CreateAdminForm>
      {false && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {"error"}
        </div>
      )}
    </CreateAdminContainer>
  );
};

export default AddAdminComponent;
