import { gradify } from "../axios.config";
import {
  EXPERIENCEIMAGE_LIST_FAIL,
  EXPERIENCEIMAGE_LIST_REQUEST,
  EXPERIENCEIMAGE_LIST_SUCCESS,
} from "../constant/experienceImage";

export const getAllExperienceImageList =
  (name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCEIMAGE_LIST_REQUEST,
      });
      const { data, total } = await gradify.get(
        `experienceimages?exType=${name}&limit=30`,
        {
          headers: { Authorization: "Bearer " + getState().auth.token },
        }
      );
      console.log("expimange", data.total);
      dispatch({
        type: EXPERIENCEIMAGE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCEIMAGE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
