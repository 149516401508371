import React from 'react';
import "./tab.css";

const TabName = (props)=> {
    const style = {
        left:props.condition? 50: props.left,
        top:props.condition? -200:100,
        transform: "rotate(90deg)",
        position:"absolute",
        zIndex: 80,
        fontSize: 15,
        whiteSpace: "nowrap",
        display: props.condition? "none" : "block",
        fontWeight: "bold",
        transition: "none"
      }
  return (
    <div className='tab' style={style}>{props.name}</div>
  )
}

export default TabName