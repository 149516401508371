import {
  PROJECT_LIST_FAIL,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_REQUEST,
  PROJECT_ORGANIZATION_LIST_FAIL,
  PROJECT_ORGANIZATION_LIST_REQUEST,
  PROJECT_ORGANIZATION_LIST_SUCCESS,
  PROJECT_ORGANIZATION_ADD_FAIL,
  PROJECT_ORGANIZATION_ADD_REQUEST,
  PROJECT_ORGANIZATION_ADD_SUCCESS,
  GET_PROJECT_DETAILS_FAIL,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_REQUEST,
  UPDATE_PROJECT_DETAILS_FAIL,
  UPDATE_PROJECT_DETAILS_REQUEST,
  UPDATE_PROJECT_DETAILS_SUCCESS,
  RESET_PROJECT,
  RESET_UPDATE_PROJECT,
  UPDATE_PROJECT_SINGLE_REQUEST,
  UPDATE_PROJECT_SINGLE_FAIL,
  UPDATE_PROJECT_SINGLE_SUCCESS,
  PROJECT_SEARCH,
} from "../constant/project";

export const projectListReducer = (state = { projects: [] }, action) => {
  switch (action.type) {
    case PROJECT_LIST_REQUEST:
      return { ...state, loading: true, projects: [] };
    case PROJECT_LIST_SUCCESS:
      return { ...state, loading: false, projects: action.payload.data };
    case PROJECT_LIST_FAIL:
      return { ...state, loading: false, projects: [], error: action.payload };

    default:
      return state;
  }
};

export const organizationProjectListReducer = (
  state = { projects: [] },
  action
) => {
  switch (action.type) {
    case PROJECT_ORGANIZATION_LIST_REQUEST:
      return { loading: true, projects: [] };
    case PROJECT_ORGANIZATION_LIST_SUCCESS:
      return { loading: false, projects: action.payload };
    case PROJECT_ORGANIZATION_LIST_FAIL:
      return { loading: false, projects: [], error: action.payload };
    case PROJECT_ORGANIZATION_ADD_REQUEST:
      return { ...state, loading: true };
    case PROJECT_ORGANIZATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [...state.projects, action.payload],
        addProject: action.payload,
      };
    case PROJECT_ORGANIZATION_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        addProject: null,
      };
    case UPDATE_PROJECT_DETAILS_REQUEST:
      return { ...state, loading: true, updateProject: false };
    case UPDATE_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: state.projects.map((item) =>
          item._id === action.payload._id ? (item = action.payload) : item
        ),
        updateProject: true,
      };
    case UPDATE_PROJECT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateProject: false,
      };
    case RESET_PROJECT:
      return { ...state, addProject: null };
    case RESET_UPDATE_PROJECT:
      return { ...state, updateProject: null };

    default:
      return state;
  }
};

export const projectDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROJECT_DETAILS_REQUEST:
      return { loading: true };
    case GET_PROJECT_DETAILS_SUCCESS:
      return { loading: false, project: action.payload };
    case GET_PROJECT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_PROJECT_SINGLE_SUCCESS:
      return { ...state, project: action.payload };

    default:
      return state;
  }
};
