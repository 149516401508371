import styled from 'styled-components';

export const CircleIcon = styled.div`
    background-color:#34C38F;
    width:28px;
    height:28px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
`;