import { createSelector } from "reselect";
import { formatAdminList, filterAdminList } from "helpers";
export const adminAddPhotoSelector = createSelector(
  (state) => state.admin.photo,
  (adminPhoto) => adminPhoto
);
export const adminMessageSelector = createSelector(
  (state) => state.admin.message,
  (message) => message
);
export const adminListSelector = createSelector(
  (state) => state.admin.adminList,
  (adminList) => adminList
);
export const formatAdminListSelector = createSelector(
  (state) => ({
    adminList: state.admin.adminList,
    organizationId: state.app?.selectedOrganizationId,
    projectId: state.app.filteredSystemId?.projectId,
    categoryId: state.app.filteredSystemId?.categoryId,
    subCategoryId: state.app.filteredSystemId?.subCategoryId,
  }),
  ({ adminList, organizationId, projectId, categoryId, subCategoryId }) =>
    formatAdminList(
      filterAdminList(
        adminList,
        organizationId,
        projectId,
        categoryId,
        subCategoryId
      )
    )
);
export const filterAdminListSelector = createSelector(
  (state) => ({
    adminList: state.admin.adminList,
    organizationId: state.app?.selectedOrganizationId,
    projectId: state.app.filteredSystemId?.projectId,
    categoryId: state.app.filteredSystemId?.categoryId,
    subCategoryId: state.app.filteredSystemId?.subCategoryId,
  }),
  ({ adminList, organizationId, projectId, categoryId, subCategoryId }) =>
    filterAdminList(
      adminList,
      organizationId,
      projectId,
      categoryId,
      subCategoryId
    )
);
export const addAdminLoadingSelector = createSelector(
  (state) => state.admin.isAddAdminLoading,
  (isAddAdminLoading) => isAddAdminLoading
);
export const adminListLoadingSelector = createSelector(
  (state) => state.admin.isAdminListLoading,
  (isLoading) => isLoading
);
export const adminPageStatusSelector = createSelector(
  (state) => state.admin.pageStatus,
  (pageStatus) => pageStatus
);
