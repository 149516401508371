import React, { useState } from "react";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import {
  Wrapper,
  InfoSection,
  InfoTitle,
  InfoItem,
  InfoText,
  InfoTextArea,
  AddItemBtn,
  DigitalCodeSection,
  DigitalCodeTitle,
  DigitalCodeItem,
  StockIdText,
  AddItemWrapper,
  DigitalCodeAddBtn,
} from "./index.style";

const CenterColumn = ({ manuallyField, setManuallyField,onAddInventory }) => {
  const { serialNumber, digitalCode } = manuallyField;
  const [showDigitalCode,setShowDigitalCode] = useState(false)
  const onChangeHandler = (event) => {
    const {name,value} = event.target;
    setManuallyField({...manuallyField,[name]:value})
  }
  return (
    <Wrapper>
      <InfoSection>
        <InfoTitle>Manually Add</InfoTitle>
        <InfoItem>
          <InfoText>Stock_ID</InfoText>
          <StockIdText
            style={{ border: "none", outline: "none", fontSize: "10px" }}
          >
            Auto Genarate Stock ID
          </StockIdText>
        </InfoItem>
        <InfoItem>
          <InfoText>Serial Number</InfoText>
          <InfoTextArea name="serialNumber" value={serialNumber} onChange={onChangeHandler} />
        </InfoItem>
      </InfoSection>
      <DigitalCodeSection>
        <DigitalCodeTitle>Digital Codes</DigitalCodeTitle>
        <DigitalCodeItem>
          <CustomSwitch
            isToggle={showDigitalCode}
            onChangeToggle={() => setShowDigitalCode(!showDigitalCode)}
          />
          {showDigitalCode && ( <><InfoText>Digital Code</InfoText>
          <InfoTextArea value={digitalCode} name="digitalCode" onChange={onChangeHandler} /></>)}
         
          {/* <DigitalCodeAddBtn>Add</DigitalCodeAddBtn> */}
        </DigitalCodeItem>
      </DigitalCodeSection>
      <AddItemWrapper>
        <AddItemBtn onClick={onAddInventory}>Add Item</AddItemBtn>
      </AddItemWrapper>
    </Wrapper>
  );
};

export default CenterColumn;
