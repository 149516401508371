import React from "react";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import {
  EditButton,
  EditButtonText,
  EditButtonIcon,
} from "assets/style/General.style";
import "components/raffle/raffle.style.css";
import { MdEdit } from "react-icons/md";
import { withStyles } from "@material-ui/core/styles";
import raffleImage from "assets/image/defualt-icon.png";

const RaffleItem = ({
  prize,
  description,
  imageUrl,
  startDate,
  closeDate,
  drawDate,
  haveAccess,
  onEditButtonClick,
}) => {
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <>
      <TableRow style={{ borderBottom: "2px solid #EFF2F7" }}>
        <TableCell style={{ borderBottom: 0 }}>
          <img
            src={imageUrl || raffleImage}
            alt="asternuts"
            width="50"
            height="50"
            style={{ borderRadius: 50 }}
          />
        </TableCell>
        <TableCell>{prize}</TableCell>
        <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
          {description}
        </TableCell>
        <TableCell
          style={{
            borderBottom: 0,
            whiteSpace: "normal",
            width: "150px",
          }}
        >
          {new Date(startDate).toISOString().split("T")[0]}
        </TableCell>
        <TableCell style={{ borderBottom: 0 }}>
          {new Date(closeDate).toISOString().split("T")[0]}
        </TableCell>
        <TableCell style={{ borderBottom: 0 }}>
          {new Date(drawDate).toISOString().split("T")[0]}
        </TableCell>

        <TableCell style={{ borderBottom: 0 }} className="addXp">
          {haveAccess && (
            <EditButton
              style={{ marginLeft: "33px", width: "120px" }}
              onClick={onEditButtonClick}
            >
              <EditButtonText>Edit/Review</EditButtonText>
              <EditButtonIcon>
                <MdEdit color="#34C38F" size={12} />
              </EditButtonIcon>
            </EditButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default RaffleItem;
