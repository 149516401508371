import styled from "styled-components";

export const ConfirmContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;
`;

export const ConfirmDialog = styled.div`
  width: 600px;
  height: 300px;
  background: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ConfirmMessage = styled.h3`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 20px;
  color: black;
  padding-top: 20px;
  text-align: center;
`;
