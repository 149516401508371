import produce from "immer";
import {
  PROGRESS_SETTING_REQUIRE,
  PROGRESS_SETTING_SUCCESS,
  PROGRESS_SETTING_FAILD,
} from "../../constant/progressSetting";

const INITIAL_STATE = {
  loading: false,
  progresSetting: null,
  error: null,
};

export const progressSettingReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PROGRESS_SETTING_REQUIRE:
        draft.loading = true;
        break;
      case PROGRESS_SETTING_SUCCESS:
        draft.loading = false;
        draft.progresSetting = action.payload;
        break;
      case PROGRESS_SETTING_FAILD:
        draft.loading = false;
        draft.error = action.payload;
    }
  });
