import React, { useState } from "react";
import "./login.css";
import { TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { forgotPasswordAction } from "actions/forgotPassword/forgotPasswordAction";
const VerificationCode = ({ 

  
    loading,
    setScreen

}) => {

 const dispatch = useDispatch();
const code = useSelector(state => state.forgotpassword.code);
const email = useSelector(state => state.forgotpassword.email);
const [codeState,setCodeState] = useState('');

const adminLoginHandler = () => {

  if(code == codeState){
    
    setScreen('change-password')
  }else{
    //do someting
  }
}
  return (
        <div className="login-content">
          <h3 className="login-content-title">Enter Verification Code</h3>
          <TextField 
                    sx={{
                      '& fieldset': {
                        borderRadius: '12px',
                      },
                      '& input': {
                        padding: '11.5px 14px',
                      },
                      '& label': {
                        lineHeight: '14px',
                      },
                    }}
          style={{width:"100%"}} id="outlined-basic" label="Code" variant="outlined"  name="code" value={codeState} onChange={(event)=>{ setCodeState(event.target.value)}} />

          <div className="loginbutton">
            <button onClick={adminLoginHandler} disabled={loading}>{loading? 'Loading...' : 'Submit'}</button>
          </div>

          <p className="forgot-password">You’ll receive an email in your inbox with verification code. If you didn’t get a code, </p>
          <button className="verification-resend-code" onClick={()=>{
              dispatch(forgotPasswordAction(email));
              alert('email sent');
          }}>Send new verificatin code</button>
        </div>
  );
};

export default VerificationCode;