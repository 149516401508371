import React from "react";
import { IconButton } from "@material-ui/core";
import {HorizontallContaner} from "./horizontalScroll.style";



//icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CollectionsOutlined } from "@material-ui/icons";

const HorizontalScroll = ({children}) => {

  const [sliderWidth, setSliderWidth] = React.useState(300);



  const onHScroll = () => {
    const el = document.getElementById(`hscroll`).scrollLeft;
  };

  return (
    <div className="homepageMargin">
      <HorizontallContaner id={`hscroll`} onScroll={() => onHScroll()}>
      {children}
      </HorizontallContaner>
    </div>
  );
};

export default HorizontalScroll;
