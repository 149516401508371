import React from "react";
import {
  CreateStudentFormCol,
  CreateStudentContainer,
  CreateStudentForm,
  CreateStudentFormRow,
} from "components/styled.components/create.student";
import StudentInfoComponent from "./StudentInfo";
import AddProjectComponent from "./AddProject";
import SchoolDistrictComponent from "./SchoolDistrict";
import UniversityComponent from "./University";
import PersonalInfoComponent from "./PersonalInfo";
import TagComponent from "./Tag";
import { SaveButton } from "components/styled.components";
import WarningPopup from "components/warningPopup/WarningPopup";

const AddStudentComponent = ({
  changeText,
  userInfo,
  teams,
  setUserInfo,
  schoolDistrict,
  setSchoolDistrict,
  loadOptionHandler,
  highschoolState,
  setHighSchoolState,
  loadHighschoolHanlder,
  saveStudent,
  loading,
  closeForm,
  showFormWarning,
  closeWarningForm,
  closeWarning,
  error,
  tagPanel,
  setTagPanel,
  textMe,
  setTextMe,
  emailMe,
  setEmailMe,
  callMe,
  setCallMe,
  universityOption,
  setUniversityName,
  universityName,
}) => {
  return (
    <CreateStudentContainer className="form-student-add">
      <CreateStudentForm
        style={{
          height: "fit-content",
          margin: "0 auto",
        }}
      >
        <StudentInfoComponent
          changeText={changeText}
          userInfo={userInfo}
          teams={teams}
        />
        <AddProjectComponent userInfo={userInfo} setUserInfo={setUserInfo} />
        <SchoolDistrictComponent
          changeText={changeText}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          schoolDistrict={schoolDistrict}
          setSchoolDistrict={setSchoolDistrict}
          loadOptionHandler={loadOptionHandler}
          highschoolState={highschoolState}
          setHighSchoolState={setHighSchoolState}
          loadHighschoolHanlder={loadHighschoolHanlder}
        />
        <UniversityComponent
          universityOption={universityOption}
          setUniversityName={setUniversityName}
          universityName={universityName}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
        <PersonalInfoComponent
          changeText={changeText}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          textMe={textMe}
          setTextMe={setTextMe}
          emailMe={emailMe}
          setEmailMe={setEmailMe}
          callMe={callMe}
          setCallMe={setCallMe}
        />
        <TagComponent tagPanel={tagPanel} setTagPanel={setTagPanel} />
        <CreateStudentFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 50,
            padding: 0,
            justifyContent: "center",
          }}
        >
          <CreateStudentFormCol
            style={{ justifyContent: "flex-end", paddingRight: 30 }}
          >
            <SaveButton onClick={saveStudent} disabled={loading}>
              {loading ? "Sending..." : "SAVE"}
            </SaveButton>
          </CreateStudentFormCol>
          <CreateStudentFormCol style={{ paddingLeft: 30 }}>
            <SaveButton onClick={closeForm}>Cancel</SaveButton>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
      </CreateStudentForm>
      {showFormWarning ? (
        <WarningPopup
          close={closeWarningForm}
          text={"Clear All Changes"}
          reject={closeWarning}
          accept={() => {}}
        />
      ) : null}
      {error && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {error}
        </div>
      )}
    </CreateStudentContainer>
  );
};

export default AddStudentComponent;
