import React, { useState, useCallback } from "react";
import {
  TitleWrapper,
  Wrapper,
  Title,
  Desc,
  Store,
  WhiteHeader,
  BlackHeader,
  SectionWrapper,
  TitleText,
  DetailText,
  PickedUpText,
  PickedUpValue,
  SectionCategoryWrapper,
} from "./index.style";
import ProgressBar from "./progressBar/index";
import StoreIcon from "assets/image/storeIcon.png";
import { useSelector } from "react-redux";
import { shopItemStudentsSelector } from "store/selectors";
import SelectComponent from "components/control.component/select.component";
const ShopSystemHeader = ({
  inStockNum,
  pickedUpNum,
  notPickedUpNum,
  soldNum,
  headerTitle,
  categoryOptions,
  selectedCategoryId,
  onCategoryChanged,
  subCategoryOptions,
  selectedSubCategoryId,
  onSubCategoryChanged,
  selectCategory,
}) => {
  const [searchStudentValue, setSearchStudentValue] = useState("");
  const [selectedFilterId, setSelectedFilterId] = useState("All");
  const studentListSelectorCallback = useCallback(
    shopItemStudentsSelector(searchStudentValue, selectedFilterId),
    [searchStudentValue, selectedFilterId]
  );
  const studentsList = useSelector(studentListSelectorCallback);

  return (
    <Wrapper>
      <BlackHeader>
        <TitleWrapper>
          <Title>{headerTitle}</Title>
          <Desc>Reward system</Desc>
        </TitleWrapper>
        <Store src={StoreIcon} />
      </BlackHeader>
      <WhiteHeader>
        <SectionCategoryWrapper Direction="column">
          <Desc style={{ color: "#000", fontSize: 13 }}>Category</Desc>
          <SelectComponent
            options={categoryOptions}
            noShowSlectText
            name="All"
            selectedValue={selectCategory}
            selected={true}
            onChange={(e) => {
              onCategoryChanged(e.target.value);
            }}
            style={{ minWidth: 180 }}
          />
        </SectionCategoryWrapper>
        <SectionCategoryWrapper Direction="column">
          <Desc style={{ color: "#000", fontSize: 13 }}>Sub Category</Desc>

          <SelectComponent
            options={subCategoryOptions}
            noShowSlectText
            name="All"
            selected={true}
            selectedValue={selectedSubCategoryId}
            onChange={onSubCategoryChanged}
            style={{ minWidth: 180 }}
          />
        </SectionCategoryWrapper>
        <SectionWrapper>
          <TitleText>In Stock</TitleText>
          <DetailText>{inStockNum}</DetailText>
        </SectionWrapper>
        <SectionWrapper>
          <ProgressBar
            strokeWidth={14}
            percentage={
              Math.floor(
                (notPickedUpNum / Object.keys(studentsList).length) * 100
              ) || 0
            }
          />
        </SectionWrapper>
        <SectionWrapper style={{ marginTop: "50px", marginLeft: "-103px" }}>
          <PickedUpText>Not Picked up</PickedUpText>
          <PickedUpValue>{notPickedUpNum}</PickedUpValue>
        </SectionWrapper>
        <SectionWrapper>
          <TitleText>Picked up</TitleText>
          <DetailText>{pickedUpNum}</DetailText>
        </SectionWrapper>
        <SectionWrapper>
          <TitleText>Sold</TitleText>
          <DetailText>{soldNum}</DetailText>
        </SectionWrapper>
      </WhiteHeader>
    </Wrapper>
  );
};

export default ShopSystemHeader;
