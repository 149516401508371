export const ADD_ORGANIZATION_REQUEST = "ADD_ORGANIZATION_REQUEST";
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS";
export const ADD_ORGANIZATION_FAIL = "ADD_ORGANIZATION_FAIL";

export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAIL = "GET_ORGANIZATION_FAIL";

export const GET_ORGANIZATION_DETAILS_REQUEST =
  "GET_ORGANIZATION_DETAILS_REQUEST";
export const GET_ORGANIZATION_DETAILS_SUCCESS =
  "GET_ORGANIZATION_DETAILS_SUCCESS";
export const GET_ORGANIZATION_DETAILS_FAIL = "GET_ORGANIZATION_DETAILS_FAIL";

export const UPDATE_ORGANIZATION_DETAILS_REQUEST =
  "UPDATE_ORGANIZATION_DETAILS_REQUEST";
export const UPDATE_ORGANIZATION_DETAILS_SUCCESS =
  "UPDATE_ORGANIZATION_DETAILS_SUCCESS";
export const UPDATE_ORGANIZATION_DETAILS_FAIL =
  "UPDATE_ORGANIZATION_DETAILS_FAIL";

export const UPDATE_ORGANIZATION_DETAILS_SINGLE_REQUEST =
  "UPDATE_ORGANIZATION_DETAILS_SINGLE_REQUEST";
export const UPDATE_ORGANIZATION_DETAILS_SINGLE_SUCCESS =
  "UPDATE_ORGANIZATION_DETAILS_SINGLE_SUCCESS";
export const UPDATE_ORGANIZATION_DETAILS_SINGLE_FAIL =
  "UPDATE_ORGANIZATION_DETAILS_SINGLE_FAIL";

export const RESET_ORGANIZATION = "RESET_ORGANIZATION";
export const RESET_UPDATE_ORGANIZATION = "RESET_UPDATE_ORGANIZATION";

export const ORGANIZATION_SEARCH = "ORGANIZATION_SEARCH";
