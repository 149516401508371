import {
  COMPELTION_LIST_FAIL,
  COMPELTION_LIST_REQUEST,
  COMPELTION_LIST_SUCCESS,
 
} from "../constant/completion";

export const completionListReducer = (
  state = { completions: {} },
  action
) => {
  switch (action.type) {
    case COMPELTION_LIST_REQUEST:
      return { ...state, loading: true, completions: {} };
    case COMPELTION_LIST_SUCCESS:
      return { ...state, loading: false, completions: action.payload };
    case COMPELTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        completions: {},
        error: action.payload,
      };
    
    default:
      return state;
  }
};
