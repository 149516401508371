import { createSelector } from "reselect";
import { formatAdminDetail } from "helpers";

export const adminEditPhotoSelector = createSelector(
  (state) => state.adminDetail.adminDetailPhoto,
  (adminDetailPhoto) => adminDetailPhoto
);
export const selectedAdminDetailSelector = createSelector(
  (state) => state.adminDetail.adminDetail,
  (adminDetail) => (adminDetail ? formatAdminDetail(adminDetail) : {})
);
export const adminDetailPageStatusSelector = createSelector(
  (state) => state.adminDetail.adminDetailStatus,
  (adminDetailStatus) => adminDetailStatus
);
export const adminDetailSchoolDistrictOptionsSelector = createSelector(
  (state) => state.adminDetail.schoolDistrictOptions,
  (schoolDistrictOptions) => schoolDistrictOptions || []
);

export const adminDetailHighSchoolList = createSelector(
  (state) => state.adminDetail.highSchoolsList,
  (highSchoolsList) => highSchoolsList
);
export const adminDetailSelectedHighSchoolId = createSelector(
  (state) => state.adminDetail.selectedHighSchoolId,
  (selectedHighSchoolId) => selectedHighSchoolId
);
export const adminDetailSpecialProjectOptionsSelector = createSelector(
  (state) => state.adminDetail.specialProjectsOptions,
  (specialProjectsOptions) => specialProjectsOptions
);
export const adminDetailSpecialProjectListSelector = createSelector(
  (state) => state.adminDetail.specialProjectList,
  (specialProjectList) => specialProjectList
);
export const adminDetailSelectedSpecialProjectId = createSelector(
  (state) => state.adminDetail.selectedSpecialProjectId,
  (selectedSpecialProjectId) => selectedSpecialProjectId
);
export const adminDetailUniversityOptionsSelector = createSelector(
  (state) => state.adminDetail.universityOptions,
  (universityOptions) => universityOptions
);
export const adminDetailUniversityListSelector = createSelector(
  (state) => state.adminDetail.universityList,
  (universityList) => universityList
);
export const adminDetailSelectedUniversityId = createSelector(
  (state) => state.adminDetail.selectedUniversityId,
  (selectedUniversityId) => selectedUniversityId
);
