import React, { useEffect, useState, useRef, memo } from "react";
import "./circle.style.css";
import kingsIcon from "../../assets/image/king.png";
const CircleChart = ({
  scale,
  left,
  top,
  message,
  className,
  percent,
  lastPercent,
  lastChartPercentCal,
  chartPercentCal,
  textPosition,
  textWeight,
  level,
  kingIcon,
  description,
  title,
  numbericCircle,
  numbericCircleColor,
}) => {
  const [values, setValues] = useState(null);
  const [lastValue, setLastValue] = useState(487);
  const editorRef = useRef();

  useEffect(() => {
    if (lastChartPercentCal) {
      setValues(lastChartPercentCal);
      setTimeout(() => {
        setValues(chartPercentCal);
        setLastValue(lastChartPercentCal);
        if (editorRef.current)
          editorRef.current.className = "show-numbric-popup";
      }, 2000);
    }
  }, [lastChartPercentCal]);

  return (
    <div
      className={`circle-chart ${className}    ${
        textPosition === "top" ? "text-position-top" : ""
      }`}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
        left: -65,
        transform: `scale(${scale})`,
        left: `${left}px`,
        top: `${top}px`,
      }}
    >
      {textPosition === "top" ? (
        message ? (
          <h4 style={{ fontWeight: textWeight }}> {message}</h4>
        ) : (
          <div>
            <h4>{title}</h4>
            <h3>{description}</h3>
          </div>
        )
      ) : null}
      <svg
        width="300"
        height="300"
        style={{
          transform: "rotate(-90deg)",
          position: "relative",
          top: "-50px",
        }}
      >
        <circle r="80" cx="125" cy="125" className="track"></circle>
        {lastChartPercentCal && (
          <>
            <circle
              r="80"
              cx="125"
              cy="125"
              className="progress"
              style={{
                strokeDashoffset:
                  lastValue || lastValue === 1 ? lastValue : 495,
                stroke:
                  lastValue >= 75
                    ? "red"
                    : lastPercent < 74 && lastPercent > 55
                    ? "red"
                    : "red",
              }}
            ></circle>
            <circle
              r="80"
              cx="125"
              cy="125"
              className="progress"
              style={{
                strokeDashoffset: values || values === 0 ? values : 495,
                stroke:
                  percent >= 75
                    ? "green"
                    : percent < 74 && percent > 55
                    ? "green"
                    : "green",
                transitionDuration: "1000ms",
              }}
            ></circle>
          </>
        )}
        {chartPercentCal && (
          <>
            {console.log("result final----> ", chartPercentCal)}
            <circle
              r="80"
              cx="125"
              cy="125"
              className="progress"
              style={{
                strokeDashoffset:
                  chartPercentCal || chartPercentCal === 0
                    ? chartPercentCal
                    : 495,
                stroke:
                  percent >= 75
                    ? "green"
                    : percent < 74 && percent > 55
                    ? "green"
                    : "green",
              }}
            ></circle>
          </>
        )}
      </svg>
      {level ? (
        <div class="profile-level">
          <h3>
            Lv <span>{level}</span>
          </h3>
          <h5>{percent ? percent : 0}%</h5>
        </div>
      ) : (
        <h2>{percent ? percent : 0}</h2>
      )}
      {kingIcon && (
        <img className="king-icon" src={kingsIcon} alt="king icon" />
      )}
      {numbericCircle !== undefined && (
        <div className="numberic-circle">
          <span
            ref={editorRef}
            style={{ backgroundColor: numbericCircleColor }}
          >
            {numbericCircle}
          </span>
        </div>
      )}
      {textPosition !== "top" ? (
        message ? (
          <h4 className="circle-title" style={{ fontWeight: textWeight }}>
            {message}
          </h4>
        ) : (
          <div className="circle-title-container">
            <h3>{title}</h3>
            <h4>{description}</h4>
          </div>
        )
      ) : null}
    </div>
  );
};

export default memo(CircleChart);
