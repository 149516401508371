import React, { useEffect, useCallback } from "react";
import ExperienceHeaderContainer from "containers/Experience/ExperienceHeader";
import { StudentContainer } from "components/styled.components";
import ExperienceList from "components/experience/ExperienceList";
import { useState } from "react";
import { experienceListSelector } from "store/selectors";
import {
  getAllExperienceList,
  setExperienceSearchInput,
  clearExperienceFilter,
} from "actions/experienceAction";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BsCaretLeftFill } from "react-icons/bs";
import {
  experienceSortTypeSelector,
  experienceSearchInputSelector,
  experienceTypeFilterSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import "../assets/css/pagination.css";

const Experience = ({ history }) => {
  const dispatch = useDispatch();
  //state
  const [searchExperienceInput, setSearchExperienceInput] = useState("");
  const [pageCount, setPageCount] = useState(1);
  // selectors
  const sortType = useSelector(experienceSortTypeSelector);
  const experiencesList = useSelector(experienceListSelector);
  const searchInput = useSelector(experienceSearchInputSelector);
  const filterType = useSelector(experienceTypeFilterSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  useEffect(() => {
    dispatch(
      getAllExperienceList(
        pageCount,
        25,
        sortType,
        searchInput,
        filterType,
        selectedCategoryId,
        selectedSubCategoryId 
      )
    );
  }, [
    dispatch,
    filterType,
    pageCount,
    searchInput,
    selectedCategoryId,
    selectedSubCategoryId,
    sortType,
  ]);
  //when leave this page, filters reset
  useEffect(() => {
    return () => {
      clearExperienceFilter(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchExperienceListHandler = useCallback(
    (e) => {
      setSearchExperienceInput(e.target.value);
      setPageCount(1);
      setExperienceSearchInput(dispatch, e.target.value);
    },
    [dispatch]
  );

  const handlePageClick = (event) => {
    setPageCount(event.selected + 1);
  };
  return (
    <>
      <ExperienceHeaderContainer
        onCreateExpCardBtnClick={() => {
          history.push("/admin/experiences/details");
        }}
        searchExperienceInput={searchExperienceInput}
        onSearchExperienceChanged={searchExperienceListHandler}
      />
      <StudentContainer>
        <ExperienceList experienceListState={experiencesList.data} />
      </StudentContainer>
      {experiencesList?.total > 25 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={<BsFillCaretRightFill />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil((experiencesList?.total || 0) / 25)}
          forcePage={pageCount - 1}
          previousLabel={<BsCaretLeftFill />}
          renderOnZeroPageCount={false}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}
    </>
  );
};

export default Experience;
