import { createSelector } from "reselect";
import { sortProjectByName, formatArrToObj } from "helpers";
export const selectedAppOrganizationIdSelector = createSelector(
  (state) => state.app.selectedOrganizationId,
  (selectedOrganizationId) => selectedOrganizationId
);
export const selectedOrganizationInfoSelector = createSelector(
  (state) => ({
    organizationList: state.app?.organizations,
    selectedOrganizationId: state.app.selectedOrganizationId,
  }),
  ({ organizationList, selectedOrganizationId }) =>
    formatArrToObj(organizationList)[selectedOrganizationId]
);
export const selectedAppProjectIdSelector = createSelector(
  (state) => state.app.selectedProjectId,
  (selectedProjectId) => selectedProjectId
);

export const allAppOrganizationsSelector = createSelector(
  (state) => state.app.organizations,
  (organizations) => organizations
);
export const allProjectsOrganizationSelector = createSelector(
  (state) => ({
    projectList: state.app?.projects,
  }),
  ({ projectList }) => formatArrToObj(projectList)
);
export const selectedProjectInfoSelector = createSelector(
  (state) => ({
    projectList: state.app?.projects,
    selectedProjectId: state.app?.selectedProjectId,
  }),
  ({ projectList, selectedProjectId }) =>
    formatArrToObj(projectList)[selectedProjectId]
);

export const allAppProjectsSelector = (searchProjectInput) =>
  createSelector(
    (state) => ({
      projectList: state.app?.projects,
    }),
    ({ projectList }) => {
      return sortProjectByName(projectList, searchProjectInput);
    }
  );
// filters
export const selectedAppProjectIdFilterSelector = createSelector(
  (state) => state.app.filteredSystemId,
  (filteredSystemId) => filteredSystemId?.projectId
);
export const selectedAppCategoryIdSelector = createSelector(
  (state) => state.app.filteredSystemId,
  (filteredSystemId) => filteredSystemId?.categoryId
);
export const selectedAppSubCategoryIdSelector = createSelector(
  (state) => state.app.filteredSystemId,
  (filteredSystemId) => filteredSystemId?.subCategoryId
);
export const allAppProjectsFilterSelector = createSelector(
  (state) => state.app.filterOptions,
  (filterOptions) => filterOptions?.projects
);
export const allAppCategoriesSelector = createSelector(
  (state) => state.app.filterOptions,
  (filterOptions) => filterOptions?.categories
);
export const allAppSubCategoriesSelector = createSelector(
  (state) => state.app.filterOptions,
  (filterOptions) => filterOptions?.subCategories
);
export const appPageStatusSelector = createSelector(
  (state) => state.app.pageStatus,
  (pageStatus) => pageStatus
);
