export const systemLoader = (system) => {
  const systemList = [];

  system.forEach((item) => {
    const orgProResult = systemList.find(
      (i) =>
        i.organization._id === item.organization._id &&
        i.project._id === item.project._id
    );

    if (orgProResult && item?.category?.displayProject) {
      orgProResult.categories.push(item.category);
      systemList.map((t) =>
        t.organization._id === orgProResult.organization._id &&
        t.project._id === orgProResult.project._id
          ? (t = orgProResult)
          : t
      );
    } else {
      if (item?.category?.displayProject) {
        systemList.push({
          organization: item.organization,
          project: item.project,
          category: item.category,
          subCategory: item.subCategory,
          categories: [item.category],
        });
      } else {
        systemList.push({
          organization: item.organization,
          project: item.project,
          category: item.category ? item.category : null,
          subCategory: item?.subCategory ? item.subCategory : null,
          categories: [],
        });
      }
    }
  });
  return systemList;
};

export const normalizeSelectedStudentDetails = (systemLoader) => {
  return systemLoader.map((s, idx) => {
    console.log("sssss", s);
    return {
      value: JSON.stringify(s),
      name: `${s.organization?.title} - ${s.project?.name} - ${
        s.categories.length > 0
          ? s.categories.map((c) => c?.name).join(" - ")
          : s.category?.name
          ? s.category?.name
          : ""
      }`,

      _id: JSON.stringify(s),
    };
  });
};
