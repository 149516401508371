export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_LEADERBOARD_REQUEST = "USER_LEADERBOARD_REQUEST";
export const USER_LEADERBOARD_SUCCESS = "USER_LEADERBOARD_SUCCESS";
export const USER_LEADERBOARD_FAIL = "USER_LEADERBOARD_FAIL";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";

export const USER_CREATE_NEW = "USER_CREATE_NEW";

export const USER_EXPERIENCE_LIST_REQUEST = "USER_EXPERIENCE_LIST_REQUEST";
export const USER_EXPERIENCE_LIST_SUCCESS = "USER_EXPERIENCE_LIST_SUCCESS";
export const USER_EXPERIENCE_LIST_FAIL = "USER_EXPERIENCE_LIST_FAIL";
export const USER_SET_SORT_TYPE = "USER_SET_SORT_TYPE";

export const ADD_USER_XP = "ADD_USER_XP";

export const UPDATE_ORGANIZATION_SYSTEM_REQUEST =
  "UPDATE_ORGANIZATION_SYSTEM_REQUEST";
export const UPDATE_ORGANIZATION_SYSTEM_SUCCESS =
  "UPDATE_ORGANIZATION_SYSTEM_SUCCESS";
export const UPDATE_ORGANIZATION_SYSTEM_FAILED =
  "UPDATE_ORGANIZATION_SYSTEM_FAILED";

export const UPDATE_USER_LIST = "UDPATE_USER_LIST";
