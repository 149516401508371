import {
  EXPERIENCETYPE_LIST_REQUEST,
  EXPERIENCETYPE_LIST_FAIL,
  EXPERIENCETYPE_LIST_SUCCESS,
 
} from "../constant/experienceType";

export const experienceTypeListReducer = (state = { experienceType: {},experienceTypeDetails:{} }, action) => {
  switch (action.type) {
    case EXPERIENCETYPE_LIST_REQUEST:
      return { ...state, loading: true, experienceType: {},experienceTypeDetails:{} };
    case EXPERIENCETYPE_LIST_SUCCESS:
      return { ...state, loading: false, experienceType: action.payload };
    case EXPERIENCETYPE_LIST_FAIL:

      return {
        ...state,
        loading: false,
        experienceType: {},
        error: action.payload,
      };

    
    default:
      return state;
  }
};
