import { createSelector } from "reselect";
import {
  formatRaffleList,
  filterRaffleList,
  sortRaffleListByTitle,
} from "helpers";

export const raffleItemListSelector = createSelector(
  (state) => state.raffle.raffleItemList,
  (raffleItemList) => raffleItemList
);
export const rafflePageStatusSelector = createSelector(
  (state) => state.raffle.pageStatus,
  (pageStatus) => pageStatus
);
export const raffleItemImageSelector = createSelector(
  (state) => state.raffle.raffleImage,
  (raffleImage) => raffleImage
);

export const raffleToastMessageSelector = createSelector(
  (state) => state.raffle.toastMessage,
  (toastMessage) => toastMessage
);

export const raffleListSelector = createSelector(
  (state) => state.raffle,
  (raffle) => raffle
);

export const selectedRaffleTotalSelector = createSelector(
  (state) => state.raffle.total,
  (total) => total
);

// export const raffleListSelector = (isReversed, searchRaffleInput) =>
//   createSelector(
//     (state) => ({
//       raffleList: state.raffle.raffleItemList,
//       organizationId: state.app?.selectedOrganizationId,
//       projectId: state.app.filteredSystemId?.projectId,
//       categoryId: state.app.filteredSystemId?.categoryId,
//       subCategoryId: state.app.filteredSystemId?.subCategoryId,
//     }),
//     ({ raffleList, organizationId, projectId, categoryId, subCategoryId }) => {
//       return sortRaffleListByTitle(
//         formatRaffleList(
//           filterRaffleList(
//             raffleList,
//             organizationId,
//             projectId,
//             categoryId,
//             subCategoryId
//           )
//         ),
//         isReversed,
//         searchRaffleInput
//       );
//     }
//   );
