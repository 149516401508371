import React, { useRef, useCallback } from "react";
import {
  Wrapper,
  Header,
  HeaderImg,
  Footer,
  BuyButton,
  CancelButton,
  ButtonText,
  DescTextbox,
  MiddleWrapper,
  TopInput,
  TopWrapper,
  BottomWrapper,
  LeftItem,
  GemWrapper,
  GemCount,
  Gem,
  RaffleEditButton,
  RaffleEditButtonText,
  RaffleEditButtonIcon,
  Spinner,
} from "./index.style";
import CountDownTimer from "components/CountDownTimer/ConuntDownTimer";
import GradegyTshirt from "assets/image/gradegyTshirt.png";
import GemIcon from "assets/image/gemIcon.png";
import { MdEdit } from "react-icons/md";

const LeftColumn = ({
  image,
  title,
  onChangeItemImg,
  onTitleInputChange,
  gemsNumber,
  leftTime,
  description,
  onDescriptionChange,
}) => {
  const raffleItemImgInputRef = useRef();
  const handleOnEditImgClicked = useCallback(() => {
    if (raffleItemImgInputRef) raffleItemImgInputRef.current.click();
  }, []);
  return (
    <Wrapper>
      <Header style={{ position: "relative" }}>
        {image?.isLoading ? (
          <Spinner />
        ) : (
          <HeaderImg src={image?.url || GradegyTshirt} />
        )}
        <input
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          ref={raffleItemImgInputRef}
          onChange={onChangeItemImg}
        />
        <RaffleEditButton onClick={handleOnEditImgClicked}>
          <RaffleEditButtonText>Edit</RaffleEditButtonText>
          <RaffleEditButtonIcon>
            <MdEdit color="#34C38F" size={12} />
          </RaffleEditButtonIcon>
        </RaffleEditButton>
      </Header>
      <MiddleWrapper style={{ zIndex: 2 }}>
        <TopWrapper>
          <TopInput onChange={onTitleInputChange} value={title} />
        </TopWrapper>
        <BottomWrapper>
          <LeftItem>
            <CountDownTimer time={leftTime} />
          </LeftItem>
          <GemWrapper>
            <GemCount>{gemsNumber}</GemCount>
            <Gem src={GemIcon} />
          </GemWrapper>
        </BottomWrapper>
      </MiddleWrapper>
      <DescTextbox value={description} onChange={onDescriptionChange} />
      <Footer>
        <BuyButton>
          <ButtonText>Buy</ButtonText>
        </BuyButton>
        <CancelButton>
          <ButtonText>Cancel</ButtonText>
        </CancelButton>
      </Footer>
    </Wrapper>
  );
};

export default LeftColumn;
