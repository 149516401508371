
import {
  LIST_OF_USER_EXPEREINCE_FAIL,
  LIST_OF_USER_EXPEREINCE_REQUEST,
  LIST_OF_USER_EXPEREINCE_SUCCESS,

} from "../constant/listOfExperienceUser";


export const listOfUserExperienceReducer = (state ={ listUsers:{data:[]},completeCount:0,viewCount:0,notCompleteCount:0},action) => {
    switch(action.type){
        case LIST_OF_USER_EXPEREINCE_REQUEST:
            return {...state,loading:true}
        case LIST_OF_USER_EXPEREINCE_SUCCESS:
            return{
                ...state,
                loading:false,
                listUsers:action.payload,
                completeCount:action.payload.data.filter(item => {
                   
                    return item.completeState.length > 0 && item.completeState[0].complete == true
                }).length,
                viewCount: action.payload.data.filter(item => {
                    
                  return item.completeState.length > 0
                }).length,
                notCompleteCount: action.payload.count - action.payload.data.filter(item => {
                    
                    return item.completeState.length > 0 && item.completeState[0].complete == true
                }).length
            }
            
        case LIST_OF_USER_EXPEREINCE_FAIL:
            return {
                ...state,
                listUsers:{},
                completeCount:0,
                notCompleteCount:0,
                viewCount:0,
                error:action.payload
            }        
        default:
            return state
    }
}

