import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  allAppOrganizationsSelector,
  selectedAppOrganizationIdSelector,
} from "store/selectors";
import {
  setAppSelectedOrganizationId,
  getAppProjectsByIdAction,
  setAppPageStatus,
} from "actions";
import Header from "components/home/Header";

const HeaderContainer = ({ searchProjectInput, onSearchProjectChange }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.loggedInUser?.user);
  const organizations = useSelector(allAppOrganizationsSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const organizationChangeHandler = useCallback(
    (organizationId) => {
      setAppSelectedOrganizationId(dispatch, organizationId);
      setAppPageStatus(dispatch, "homeLoading");
      dispatch(getAppProjectsByIdAction(organizationId));
    },
    [dispatch]
  );
  return (
    <Header
      userRole={user.role}
      selectedOrganization={selectedOrganizationId ?? ""}
      onOrganizationChange={organizationChangeHandler}
      organizations={organizations}
      searchProjectInput={searchProjectInput}
      onSearchProjectChange={onSearchProjectChange}
    />
  );
};

export default HeaderContainer;
