import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Confirm from "components/display.component/confirm.components";
import AnimationCheck from "components/animation.icon/animation.icon.components";
import DeleteConfirm from "components/display.component/DeleteConfirm";
import Moment from "moment";
import {
  raffleTicketDetailPageStatusSelector,
  raffleTicketDetailSelector,
  raffleTicketDetailToastMessageSelector,
  raffleTicketDetailImageSelector,
  selectedAppOrganizationIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import {
  getRaffleTicketDetailAction,
  editRaffleTicketImage,
  deleteRaffleTicketAction,
  setRaffleDetailPageStatus,
  updateRaffleTicketAction,
} from "actions";
import moment from "moment";
import RaffleSystem from "components/raffle/RaffleSystem";
import { setDefaultCloseDate } from "helpers";
const RaffleSystemContainer = ({
  raffleId,
  history,
  onEditInventoryHandler,
  type,
}) => {
  const dispatch = useDispatch();
  // selectors
  const pageStatus = useSelector(raffleTicketDetailPageStatusSelector);
  const raffleTicketDetail = useSelector(raffleTicketDetailSelector);
  const toastMessage = useSelector(raffleTicketDetailToastMessageSelector);
  const raffleTicketImage = useSelector(raffleTicketDetailImageSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  //states
  //left
  const [title, setTitle] = useState(raffleTicketDetail?.title);
  const [description, setDescription] = useState(
    raffleTicketDetail?.description
  );

  const [category, setCategory] = useState("All");
  const [categorySelect, setCategorySelect] = useState(
    raffleTicketDetail?.category
  );

  //center
  const [gemsNumber, setGemsNumber] = useState(raffleTicketDetail?.gemsNumber);
  const [isStore, setIsStore] = useState(raffleTicketDetail?.isStore);
  const [isDigitalCode, setIsDigitalCode] = useState(false);
  const [isPrizeWheel, setIsPrizeWheel] = useState(
    raffleTicketDetail?.isPrizeWheel
  );
  //header
  const [winnerNumber, setWinnerNumber] = useState(
    raffleTicketDetail?.winnerNumber
  );
  console.log("startDate", raffleTicketDetail?.startDate);
  const [startDate, setStartDate] = useState(raffleTicketDetail?.startDate);

  const [startTime, setStartTime] = useState(raffleTicketDetail?.startTime);
  const [closeDate, setCloseDate] = useState(raffleTicketDetail?.closeDate);
  const [closeTime, setCloseTime] = useState(raffleTicketDetail?.closeTime);
  const [drawDate, setDrawDate] = useState(raffleTicketDetail?.drawDate);
  const [drawTime, setDrawTime] = useState(raffleTicketDetail?.drawTime);
  const [purchaseState, setPurchaseState] = useState(
    raffleTicketDetail?.purchase_limit_state
  );
  const [purchaseLimit, setPurchaseLimt] = useState(
    raffleTicketDetail?.purchase_limit
  );

  useEffect(() => {
    dispatch(getRaffleTicketDetailAction(raffleId));
  }, [dispatch, raffleId]);
  useEffect(() => {
    console.log("raffel close date", raffleTicketDetail.closeDate);
    setStartDate(raffleTicketDetail?.startDate);
    setStartTime(raffleTicketDetail?.startTime);
    setCloseDate(raffleTicketDetail?.closeDate);
    setCloseTime(raffleTicketDetail?.closeTime);
    setDrawDate(raffleTicketDetail?.drawDate);
    setDrawTime(raffleTicketDetail?.drawTime);
    setWinnerNumber(raffleTicketDetail?.winnerNumber);
    setIsPrizeWheel(raffleTicketDetail?.isPrizeWheel);
    setIsStore(raffleTicketDetail?.isStore);
    setGemsNumber(raffleTicketDetail?.gemsNumber);
    setDescription(raffleTicketDetail?.description);
    setTitle(raffleTicketDetail?.title);
    setCategorySelect(raffleTicketDetail?.category);
    setPurchaseState(raffleTicketDetail?.purchase_limit_state);
    setPurchaseLimt(raffleTicketDetail?.purchase_limit);
  }, [
    raffleTicketDetail?.purchase_limit_state,
    raffleTicketDetail?.purchase_limit,
    raffleTicketDetail?.closeDate,
    raffleTicketDetail?.closeTime,
    raffleTicketDetail?.description,
    raffleTicketDetail?.drawDate,
    raffleTicketDetail?.drawTime,
    raffleTicketDetail?.gemsNumber,
    raffleTicketDetail?.isPrizeWheel,
    raffleTicketDetail?.isStore,
    raffleTicketDetail?.startDate,
    raffleTicketDetail?.startTime,
    raffleTicketDetail?.title,
    raffleTicketDetail?.winnerNumber,
    raffleTicketDetail?.category,
    raffleTicketDetail?.purchase_limit_state,
    raffleTicketDetail?.purchase_limit,
  ]);

  useEffect(() => {
    // setCloseDate(setDefaultCloseDate(startDate))
    // setDrawDate(setDefaultCloseDate(startDate))
  }, [startDate]);
  //actions
  const titleInputChangeHandler = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const descriptionChangeHandler = useCallback((e) => {
    setDescription(e.target.value);
  }, []);
  const winnerNumberChangeHandler = useCallback((e) => {
    setWinnerNumber(e.target.value);
  }, []);
  const gemsNumberChangeHandler = useCallback((e) => {
    setGemsNumber(e.target.value);
  }, []);
  const storeChangeHandler = useCallback(() => {
    setIsStore(!isStore);
  }, [isStore]);
  const prizeWheelChangeHandler = useCallback(() => {
    setIsPrizeWheel(!isPrizeWheel);
  }, [isPrizeWheel]);

  const changeItemImgHandler = useCallback(
    (e) => {
      editRaffleTicketImage(dispatch, e.target.files[0]);
    },
    [dispatch]
  );
  const onChangePurchaseState = (value) => {
    setPurchaseState(value);
  };

  const onChagnePurchaseLimit = (value) => {
    setPurchaseLimt(value);
  };
  const updateClickedHandler = useCallback(() => {
    let itemInfo;
    if (category === "All") {
      itemInfo = {
        raffle_title: title,
        description: description,
        in_store: isStore,
        in_prize_wheel: isPrizeWheel,
        gem_raffle: gemsNumber,
        raffle_image: raffleTicketImage?.url,
        number_winner: winnerNumber,
        start_date: new Date(
          Moment(`${startDate} ${startTime}`).format()
        ).toISOString(),
        end_date: new Date(
          Moment(`${closeDate} ${closeTime}`).format()
        ).toISOString(),
        draw_date: new Date(
          Moment(`${drawDate} ${drawTime}`).format()
        ).toISOString(),
        purchase_limit: purchaseLimit,
        purchase_limit_state: purchaseState,
      };
    } else {
      itemInfo = {
        raffle_title: title,
        description: description,
        in_store: isStore,
        in_prize_wheel: isPrizeWheel,
        gem_raffle: gemsNumber,
        raffle_image: raffleTicketImage?.url,
        number_winner: winnerNumber,
        start_date: new Date(
          Moment(`${startDate} ${startTime}`).format()
        ).toISOString(),
        end_date: new Date(
          Moment(`${closeDate} ${closeTime}`).format()
        ).toISOString(),
        draw_date: new Date(
          Moment(`${drawDate} ${drawTime}`).format()
        ).toISOString(),
        category: category,
        purchase_limit: purchaseLimit,
        purchase_limit_state: purchaseState,
      };
    }

    dispatch(
      updateRaffleTicketAction(itemInfo, raffleTicketDetail?.winnerNumber)
    );
  }, [
    closeDate,
    closeTime,
    description,
    dispatch,
    drawDate,
    drawTime,
    gemsNumber,
    isPrizeWheel,
    isStore,
    raffleTicketImage?.url,
    startDate,
    startTime,
    title,
    winnerNumber,
    category,
    purchaseState,
    purchaseLimit,
  ]);
  const deleteClickedHandler = useCallback(() => {
    dispatch(deleteRaffleTicketAction(raffleId, history));
  }, [dispatch, history, raffleId]);

  const onChangeSelectCategory = (value) => {
    setCategory(value);
  };
  return (
    <>
      <RaffleSystem
        image={raffleTicketImage}
        title={title}
        onChangeItemImg={changeItemImgHandler}
        onTitleInputChange={titleInputChangeHandler}
        gemsNumber={gemsNumber}
        leftTime={new Date(
          Moment(`${closeDate} ${closeTime}`).format()
        ).getTime()}
        description={description}
        onDescriptionChange={descriptionChangeHandler}
        onGemsNumberChange={gemsNumberChangeHandler}
        winnerNumber={winnerNumber}
        onWinnerNumberChange={winnerNumberChangeHandler}
        isStore={isStore}
        onStoreChange={storeChangeHandler}
        isPrizeWheel={isPrizeWheel}
        onPrizeWheelChange={prizeWheelChangeHandler}
        isDigitalCode={isDigitalCode}
        onDigitalCodeChange={() => {
          setIsDigitalCode(false);
        }}
        startDate={startDate}
        onStartDateChange={(e) => {
          setStartDate(e.target.value);
        }}
        startTime={startTime}
        onStartTimeChange={(e) => {
          setStartTime(e.target.value);
        }}
        closeDate={closeDate}
        onCloseDateChange={(e) => {
          setCloseDate(e.target.value);
        }}
        closeTime={closeTime}
        onCloseTimeChange={(e) => {
          setCloseTime(e.target.value);
        }}
        drawDate={drawDate}
        onDrawDateChange={(e) => {
          setDrawDate(e.target.value);
        }}
        drawTime={drawTime}
        onDrawTimeChange={(e) => {
          setDrawTime(e.target.value);
        }}
        winnersName={raffleTicketDetail?.winnerNumber}
        soldNum={raffleTicketDetail?.soldNum}
        onDeleteItemClick={() => {
          setRaffleDetailPageStatus(dispatch, "DELETE_RAFFLE_POPUP");
        }}
        onUpdateItemClick={updateClickedHandler}
        pageStatus={pageStatus}
        onEditInventoryHandler={onEditInventoryHandler}
        raffleId={raffleId}
        onChangeSelectCategory={onChangeSelectCategory}
        selectCategory={categorySelect}
        purchaseState={purchaseState}
        purchaseLimit={purchaseLimit}
        onChangePurchaseState={onChangePurchaseState}
        onChagnePurchaseLimit={onChagnePurchaseLimit}
      />
      {pageStatus === "RAFFLE_MESSAGE_POPUP" && !toastMessage?.hasError && (
        <Confirm message={toastMessage?.message}>
          <AnimationCheck />
        </Confirm>
      )}
      {pageStatus === "DELETE_RAFFLE_POPUP" && (
        <DeleteConfirm
          onDelete={deleteClickedHandler}
          close={() => {
            setRaffleDetailPageStatus(dispatch, "DEFAULT");
          }}
        />
      )}
    </>
  );
};

export default RaffleSystemContainer;
