import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_GET_USER_REQUEST,
  AUTH_GET_USER_FAIL,
  AUTH_GET_USER_SUCCESS,
  LOGOUT_USER,
} from "../constant/auth";
import { CHANGE_PASSWORD } from "constant/forgotpassword";
import { UPDATE_ORGANIZATION_SYSTEM_SUCCESS } from "constant/user";

export const authReducer = (state = { token: null }, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return { loading: true };
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
      };
    case AUTH_FAIL:
      return {
        ...state,
        loading: false,
        token: null,
        error: action.payload,
      };
    case LOGOUT_USER:
      return { ...state, loading: false, token: null, error: null };
    default:
      return state;
  }
};

export const loggedInUserReducer = (
  state = { user: {}, loading: true },
  action
) => {
  switch (action.type) {
    case AUTH_GET_USER_REQUEST:
      return { loading: true, user: {}, isAuthenticated: false };
    case AUTH_GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        user: {},
        isAuthenticated: false,
        error: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loading: false,
        user: {},
        isAuthenticated: false,
        error: null,
      };
    case UPDATE_ORGANIZATION_SYSTEM_SUCCESS:
      console.log(action.payload, "action.payload");
      return { ...state, user: action.payload, error: null };
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
        error: null,
      };

    default:
      return state;
  }
};
