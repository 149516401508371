import {
  SET_APP_FILTERED_SYSTEM_ID,
  SET_APP_FILTER_OPTIONS,
  SET_APP_ORGANIZATION_OPTIONS,
  SET_APP_SELECTED_ORGANIZATION_ID,
  SET_APP_PROJECT_OPTIONS,
  SET_APP_SELECTED_PROJECT_ID,
  SET_APP_PAGE_STATUS,
  RESET_APP_PAGE,
} from "constant";
import produce, { createDraft } from "immer";

const INITIAL_STATE = {
  organizations: [],
  selectedOrganizationId: undefined,
  projects: [],
  selectedProjectId: undefined,
  filteredSystemId: {
    projectId: undefined,
    categoryId: undefined,
    subCategoryId: undefined,
  },
  filterOptions: {
    projects: undefined,
    categories: undefined,
    subCategories: undefined,
  },
  pageStatus: "DEFAULT",
};

const appReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_APP_FILTERED_SYSTEM_ID:
        draft.filteredSystemId = action.payload;
        break;
      case SET_APP_FILTER_OPTIONS:
        draft.filterOptions = action.payload;
        break;
      case SET_APP_ORGANIZATION_OPTIONS:
        draft.organizations = action.payload;
        break;
      case SET_APP_SELECTED_ORGANIZATION_ID:
        draft.selectedOrganizationId = action.payload;
        break;
      case SET_APP_PROJECT_OPTIONS:
        draft.projects = action.payload;
        break;
      case SET_APP_SELECTED_PROJECT_ID:
        draft.selectedProjectId = action.payload;
        break;
      case SET_APP_PAGE_STATUS:
        draft.pageStatus = action.payload;
        break;
      case RESET_APP_PAGE:
        return createDraft(INITIAL_STATE);
      // no default
    }
  });
export default appReducer;
