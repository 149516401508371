import React from "react";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  StudentInput,
  LabelStudent,
} from "components/styled.components/create.student";
import AdvanceSelect from "components/control.component/advenceSelect";

const SchoolDistrictComponent = ({
  changeText,
  userInfo,
  setUserInfo,
  schoolDistrict,
  setSchoolDistrict,
  loadOptionHandler,
  highschoolState,
  setHighSchoolState,
  loadHighschoolHanlder,
}) => {
  return (
    <>
      <CreateStudentFormRow
        style={{
          marginTop: 20,
          padding: 10,
          justifyContent: "start",
        }}
      >
        <CreateStudentFormCol Direction="column">
          <LabelStudent>School District</LabelStudent>
          <AdvanceSelect
            value={schoolDistrict}
            onChange={(selectedSchoolDistriect) => {
              setSchoolDistrict(selectedSchoolDistriect);
              setUserInfo({
                ...userInfo,
                schoolDistrict: {
                  ...userInfo.schoolDistrict,
                  schoolDistrictID: selectedSchoolDistriect.value,
                },
              });
            }}
            loadOptions={loadOptionHandler}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>HighSchools </LabelStudent>
          <AdvanceSelect
            value={highschoolState}
            onChange={(selectedHighSchool) => {
              setHighSchoolState(selectedHighSchool);
              setUserInfo({
                ...userInfo,
                highSchool: selectedHighSchool.value,
              });
            }}
            loadOptions={loadHighschoolHanlder}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="schoolDistrictid">
            School District ID
          </LabelStudent>
          <StudentInput
            type="text"
            id="schoolDistrictId"
            name="schoolDistrict.schoolDistrictId"
            value={userInfo.schoolDistrict?.schoolDistrictId}
            onChange={changeText}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="schoolDistrictEmail">
            School District email
          </LabelStudent>
          <StudentInput
            type="text"
            id="schoolDistrictEmail"
            name="schoolDistrict.schoolDistrictEmail"
            value={userInfo.schoolDistrict?.schoolDistrictEmail}
            onChange={changeText}
          />
        </CreateStudentFormCol>
      </CreateStudentFormRow>
    </>
  );
};

export default SchoolDistrictComponent;
