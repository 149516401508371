export const calculatePercent = (value,total) => {
    return Math.floor((value/total) * 100);
}

export const calulateCircleChartPercent = (total,percent) => {
    var result
    if(percent == 100 ){
         result = 1; 
    }else{
        const calPercent = (total * percent) / 100;
         result = total - calPercent;
    }

    return result;
} 