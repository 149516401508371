import React from "react";
import SelectComponent from "components/control.component/select.component.jsx";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  StudentInput,
  LabelStudent,
} from "components/styled.components/create.student";
import { EDUCATIONAL_PHASES } from "constants/index";

const StudentInfoComponent = ({ changeText, userInfo, teams }) => {
  return (
    <>
      <CreateStudentFormRow style={{ padding: 10, justifyContent: "center" }}>
        <CreateStudentFormCol alignment="flex-end">
          <StudentInput
            Width="329px"
            Height="57px"
            FontFamily="Rajdhani"
            Size="45px"
            Weight="700"
            name="firstName"
            placeholder="FirstName"
            value={userInfo.firstName}
            onChange={changeText}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol
          alignment="flex-start"
          style={{ paddingLeft: 15 }}
        >
          <StudentInput
            Width="329px"
            Height="57px"
            FontFamily="Rajdhani"
            Size="45px"
            Weight="700"
            name="lastName"
            placeholder="LastName"
            value={userInfo.lastName}
            onChange={changeText}
          />
        </CreateStudentFormCol>
      </CreateStudentFormRow>
      <CreateStudentFormRow style={{ marginTop: 30, padding: 10 }}>
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="email">email</LabelStudent>
          <StudentInput
            type="text"
            id="email"
            name="email"
            value={userInfo.email}
            onChange={changeText}
          />
        </CreateStudentFormCol>

        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="phone">phone</LabelStudent>
          <StudentInput
            type="text"
            name="phone"
            id="phone"
            value={userInfo.phone}
            onChange={changeText}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Team</LabelStudent>
          <SelectComponent options={teams} name="team" onChange={changeText} />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Educational Phases</LabelStudent>
          <SelectComponent
            options={EDUCATIONAL_PHASES}
            name="educationalPhase"
            onChange={changeText}
          />
        </CreateStudentFormCol>
      </CreateStudentFormRow>
    </>
  );
};

export default StudentInfoComponent;
