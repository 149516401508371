// SortByName
const sortNameHandler = (a, b) => {
  var x = a.name.toLowerCase();
  var y = b.name.toLowerCase();
  return x < y ? -1 : x > y ? 1 : 0;
};
export const sortProjectByName = (raffleData, filterSearch) => {
  let result = [...raffleData];
  if (raffleData) {
    raffleData.slice().sort(sortNameHandler);
    if (filterSearch) {
      result = raffleData.filter((item) =>
        item.name.toLowerCase().match(filterSearch.toLowerCase())
      );
      return result;
    }
    return result;
  }
  return result;
};
export const formatAllProjects = (data) =>
  data.data.map((item) => {
    return { ...item.project, studentCount: item.count };
  });
export const formatArrToObj = (data) => {
  const content = {};
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    content[item._id] = { ...item };
  }
  return content;
};
export * from "./recentProjects";
