import React from 'react'
import StarSvg from "../../logos/star/StarSvg";

const StarBtn = ({onClick , stroke , fill}) => {
  return (
    <>
       <button onClick={onClick} style={style}> 
          <StarSvg stroke={stroke}
                   fill={fill} 
                   width={20} 
                   height={26}
                   />
       </button>
	       
    </>
  )
}

export default StarBtn

const style = {
   width: 20,
   height: 20,
   background: "none",
   transform: "rotate(0)",
   marginRight: 5,
   marginTop: -2,
   padding:0,
   cursor: "pointer",
   outline: "none",
   display:"inline-block",
   position:"relative"
}