import React, { useCallback } from "react";
import AdminDetailContainer from "containers/Admin/AdminDetail";
import { useDispatch } from "react-redux";

import { editAdminDetailAction } from "actions";
const AdminDetail = ({ match }) => {
  const dispatch = useDispatch();
  const onSaveAdminHandler = useCallback(async () => {
    dispatch(editAdminDetailAction());
  }, [dispatch]);
  return (
    <AdminDetailContainer
      onSaveAdminHandler={onSaveAdminHandler}
      adminId={match.params.id}
    />
  );
};

export default AdminDetail;
