import React from "react";
import { HomeTitle, HomeRow, HomeColumn } from "components/home/home.style";
import HomeItem from "../HomeItem";

const HomeRecent = ({ onProjectClick, projects }) => {
  return (
    <>
      <HomeRow Direction="column">
        <HomeColumn>
          {projects.length > 0 && <HomeTitle>RECENT</HomeTitle>}
        </HomeColumn>
        <HomeColumn>
          {projects?.map((project) => {
            const { name, project_type: type, image } = project;
            return (
              <HomeItem
                key={project._id}
                onProjectClick={() => onProjectClick(project._id)}
                name={name}
                type={type}
                image={image}
                studentLength={project?.studentCount}
              />
            );
          })}
        </HomeColumn>
      </HomeRow>
    </>
  );
};

export default HomeRecent;
