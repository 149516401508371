import React from "react";
import {
  Box,
  Column,
  Row,
  InnerCol,
  SubTitle,
  Label,
  SwitchLabel,
} from "assets/style/General.style";
import { useDispatch } from "react-redux";
import VerticalText from "components/general/verticalText";
import CustomSwitch from "components/control.component/switch/CustomSwitch";
import AdvanceSelect from "components/control.component/advenceSelect";
import SelectComponent from "components/control.component/select.component";
import CircleButton from "components/general/circleButton";
import List from "components/general/list";
import { changeHighSchoolAccessPermissionAction } from "actions";
const SchoolDistrict = ({
  schoolDistrictOptions,
  loadHighSchoolOptions,
  schoolDistrictAccess,
  onSchoolDistrictChange,
  onHighSchoolChange,
  onAddSchoolDistClick,
  onHighSchoolClick,
  highSchoolNameArr,
  selectedHighSchool,
  selectedOptionValue,
  onDeleteHighSchoolItem,
}) => {
  const dispatch = useDispatch();
  return (
    <Box elevation={3} className="st-info" style={{ flexDirection: "row" }}>
      <Row Direction="column" Width="40%" tabletWidth={'100%'}>
        <Column>
          <SubTitle>School district</SubTitle>
        </Column>
        <Column style={{ flexWrap:"wrap"}}>
          <InnerCol Direction="column">
            <Label>School District</Label>
            <SelectComponent
              isDisabled={!schoolDistrictAccess}
              options={schoolDistrictOptions}
              name="schoolDistric"
              selectedValue={schoolDistrictOptions && schoolDistrictOptions[0]}
              onChange={onSchoolDistrictChange}
            />
          </InnerCol>
          <InnerCol Direction="column">
            <Label>High School</Label>
            <AdvanceSelect
              // value={selectedOptionValue}
              onChange={onHighSchoolChange}
              loadOptions={loadHighSchoolOptions}
            />
          </InnerCol>
          <InnerCol style={{ padding: "20px 10px 0 0" }} align="center">
            <CircleButton onClick={onAddSchoolDistClick} />
          </InnerCol>
        </Column>
      </Row>

      <Row  Width="30%" tabletWidth={'50%'}>
        <Column>
          <List
            listArray={Object.values(highSchoolNameArr)}
            selectedId={selectedHighSchool?._id}
            ItemClick={onHighSchoolClick}
            CloseItemClick={onDeleteHighSchoolItem}
          />
        </Column>
      </Row>

      <Row Width="20%" tabletWidth={'40%'} minWidth={'240px'}>
        {selectedHighSchool && (
          <>
            <Column Width="fit-content" style={{ justifyContent: "end" }}>
              <VerticalText
                text={selectedHighSchool?.title || selectedHighSchool?.name}
                width="20px"
              />
            </Column>
            <Column Direction="column" style={{ minWidth: "210px" }}>
              <InnerCol alignment="space-between">
                <SwitchLabel>Users</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedHighSchool.access?.users || false}
                  onChangeToggle={() => {
                    changeHighSchoolAccessPermissionAction(
                      dispatch,
                      "users",
                      !selectedHighSchool.access?.users
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>Experience cards</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedHighSchool.access?.experienceCards || false}
                  onChangeToggle={() => {
                    changeHighSchoolAccessPermissionAction(
                      dispatch,
                      "experienceCards",
                      !selectedHighSchool.access?.experienceCards
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>surveys</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedHighSchool.access?.surveys || false}
                  onChangeToggle={() => {
                    changeHighSchoolAccessPermissionAction(
                      dispatch,
                      "surveys",
                      !selectedHighSchool.access?.surveys
                    );
                  }}
                />
              </InnerCol>
              <InnerCol alignment="space-between">
                <SwitchLabel>store</SwitchLabel>
                <CustomSwitch
                  isToggle={selectedHighSchool.access?.store || false}
                  onChangeToggle={() => {
                    changeHighSchoolAccessPermissionAction(
                      dispatch,
                      "store",
                      !selectedHighSchool.access?.store
                    );
                  }}
                />
              </InnerCol>
            </Column>{" "}
          </>
        )}
      </Row>
    </Box>
  );
};

export default SchoolDistrict;
