import { gradify } from "axios.config";
import {
  formatHighSchoolList,
  formatSpecialProjectList,
  formatUniversityList,
} from "helpers";
import store from "Store";
import {
  SET_SELECTED_ADMIN_DETAIL,
  SET_ADMIN_DETAIL_PAGE_STATUS,
  SET_ADMIN_DETAIL_SCHOOL_DISTRICT_OPTIONS,
  SET_ADMIN_DETAIL_HIGHSCHOOL_LIST,
  SET_ADMIN_DETAIL_SPECIAL_PROJECTS_OPTIONS,
  SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST,
  SET_ADMIN_DETAIL_UNIVERSITY_OPTIONS,
  SET_ADMIN_DETAIL_UNIVERSITY_LIST,
  SET_EDIT_PHOTO_URL_SUCCESS,
} from "constant";
export const getAdminDetailAction = (adminId) => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    dispatch({
      type: SET_ADMIN_DETAIL_PAGE_STATUS,
      payload: "LOADING",
    });
    const { data } = await gradify.get(`users/${adminId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: SET_SELECTED_ADMIN_DETAIL,
      payload: data.data,
    });
    if (data.data?.profilePicture)
      dispatch({
        type: SET_EDIT_PHOTO_URL_SUCCESS,
        payload: {
          url: data.data?.profilePicture,
        },
      });
    dispatch({
      type: SET_ADMIN_DETAIL_PAGE_STATUS,
      payload: "DEFAULT",
    });
    const organizationType =
      data.data.system[0] &&
      data.data.system[0]?.organization?.organization_type;
    const organizationId =
      data.data.system[0] && data.data.system[0]?.organization?._id;
    if (organizationId) {
      const { data: projectsOrganization } = await gradify.get(
        `projects?organization[in]=${organizationId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (organizationType === "Secondary") {
        // set high schools
        const schoolDistrictOptions = projectsOrganization.data.filter(
          (project) =>
            project.condition === "Default" ||
            project.project_type === "Secondary"
        );
        dispatch({
          type: SET_ADMIN_DETAIL_SCHOOL_DISTRICT_OPTIONS,
          payload: schoolDistrictOptions,
        });

        dispatch({
          type: SET_ADMIN_DETAIL_HIGHSCHOOL_LIST,
          payload: formatHighSchoolList(
            data.data.schoolDistrictPermissionEdit,
            data.data.system[0]
          ),
        });
      }

      // set special projects
      const specialProjectsOptions = projectsOrganization.data.filter(
        (project) => project.project_type === "Special Project"
      );
      dispatch({
        type: SET_ADMIN_DETAIL_SPECIAL_PROJECTS_OPTIONS,
        payload: specialProjectsOptions,
      });
      dispatch({
        type: SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST,
        payload: formatSpecialProjectList(
          data.data.spicalProjectPermissionEdit,
          data.data.system[0]
        ),
      });
      if (organizationType === "Post Secondary") {
        // set university options here
        const universityOptions = projectsOrganization.data.filter(
          (project) =>
            project.condition === "Default" ||
            project.project_type === "Post Secondary"
        );
        dispatch({
          type: SET_ADMIN_DETAIL_UNIVERSITY_OPTIONS,
          payload: universityOptions,
        });
        // set universityList here
        dispatch({
          type: SET_ADMIN_DETAIL_UNIVERSITY_LIST,
          payload: formatUniversityList(
            data.data.universityPermissionEdit,
            data.data.system[0]
          ),
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
};
