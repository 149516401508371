import {
    SCHOOLDISTRICT_LIST_REQUEST,
    SCHOOLDISTRICT_LIST_SUCCESS,
    SCHOOLDISTRICT_LIST_FAIL
} from '../constant/schoolDistrict';


export const schoolDistrictListReducer = (state = { schoolDistricts:[]},action) => {
    switch(action.type){
        case SCHOOLDISTRICT_LIST_REQUEST:
            return {...state,loading:true,schoolDistricts:[]}
        case SCHOOLDISTRICT_LIST_SUCCESS:
            return {...state,loading:false,schoolDistricts:action.payload}
        case SCHOOLDISTRICT_LIST_FAIL:
            return {...state,loading:false,schoolDistricts:[],error:action.payload}        
        default:
            return state
    }
}