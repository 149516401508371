import { gradify } from "../axios.config";
import {
  ADD_ORGANIZATION_REQUEST,
  ADD_ORGANIZATION_FAIL,
  ADD_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_DETAILS_SUCCESS,
  GET_ORGANIZATION_DETAILS_REQUEST,
  GET_ORGANIZATION_DETAILS_FAIL,
  UPDATE_ORGANIZATION_DETAILS_FAIL,
  UPDATE_ORGANIZATION_DETAILS_REQUEST,
  UPDATE_ORGANIZATION_DETAILS_SUCCESS,
  RESET_ORGANIZATION,
  RESET_UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_DETAILS_SINGLE_FAIL,
  UPDATE_ORGANIZATION_DETAILS_SINGLE_REQUEST,
  UPDATE_ORGANIZATION_DETAILS_SINGLE_SUCCESS,
  ORGANIZATION_SEARCH,
} from "../constant/organization";

export const addOrganizationAction =
  (organization) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_ORGANIZATION_REQUEST,
      });

      const { data } = await gradify.post("organization", organization, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: ADD_ORGANIZATION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: ADD_ORGANIZATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getOrganizationAction = (user) => async (dispatch, getState) => {
  let query;
  switch (user.role) {
    case "ultraAdmin":
      query = "organization";
      break;
    case "superAdmin":
      query = `organization?title=${user.system[0].organization.title}`;
      break;
    case "admin":
      query = `organization?title=${user.system[0].organization.title}`;
      break;
    //no default
  }
  try {
    dispatch({
      type: GET_ORGANIZATION_REQUEST,
    });

    const { data } = await gradify.get(query, {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });
    dispatch({
      type: GET_ORGANIZATION_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_ORGANIZATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDetailsOrganizationAction =
  (organizationId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ORGANIZATION_DETAILS_REQUEST,
      });

      const { data } = await gradify.get(`organization/${organizationId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: GET_ORGANIZATION_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORGANIZATION_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateOrganizationAction =
  (organizationId, organizationInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ORGANIZATION_DETAILS_REQUEST,
      });

      const { data } = await gradify.put(
        `organization/${organizationId}`,
        organizationInfo,
        {
          headers: { Authorization: "Bearer " + getState().auth.token },
        }
      );

      dispatch({
        type: UPDATE_ORGANIZATION_DETAILS_SUCCESS,
        payload: data.data,
      });
      dispatch({
        type: UPDATE_ORGANIZATION_DETAILS_SINGLE_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ORGANIZATION_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetOrganizationAdd = () => (dispatch) => {
  dispatch({
    type: RESET_ORGANIZATION,
  });
};

export const resetUpdateOrganization = () => (dispatch) => {
  dispatch({
    type: RESET_UPDATE_ORGANIZATION,
  });
};

export const searchOrganization = (value) => (dispatch) => {
  dispatch({
    type: ORGANIZATION_SEARCH,
    payload: value,
  });
};
