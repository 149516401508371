import {
  SET_SHOPITEM_DETAIL_PAGE_STATUS,
  SET_SHOP_ITEM_DETAIL,
  SHOPITEM_DETAIL_TOAST_MESSAGE,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const addStockShopItemAction = (input, addedNum) => async (dispatch) => {
  const shopItemStock = [];

  const shopItemDetail = store.getState().shopItemDetail.shopItemDetail;
  try {
    const token = store.getState().auth.token;
    const shopItemId = store.getState().shopItemDetail.shopItemDetail?._id;
    for (let i = 0; i < addedNum; i++) {
      shopItemStock.push({ shopItem: shopItemId });
    }
    if (shopItemId) {
      dispatch({
        type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
        payload: "ADD_STOCK_SAVING",
      });
      await gradify.post(
        `inventories/addgroup`,
        { inventories: shopItemStock },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      await gradify.put(`shopitems/${shopItemId}`, input, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
        payload: "SHOP_MESSAGE_POPUP",
      });
      dispatch({
        type: SHOPITEM_DETAIL_TOAST_MESSAGE,
        payload: {
          message: "STOCK ADDED SUCCESSFULY",
          hasError: false,
        },
      });

      const { data: shopItemData } = await gradify.get(
        `shopitems/${shopItemId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: SET_SHOP_ITEM_DETAIL,
        payload: shopItemData.data,
      });
      setTimeout(() => {
        dispatch({
          type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
          payload: "DEFAULT",
        });
        dispatch({
          type: SHOPITEM_DETAIL_TOAST_MESSAGE,
          payload: { message: "", hasError: false },
        });
      }, 2000);
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_SHOPITEM_DETAIL_PAGE_STATUS,
      payload: "DEFAULT",
    });
    dispatch({
      type: SET_SHOP_ITEM_DETAIL,
      payload: shopItemDetail,
    });
    // what's in error?
  }
};
