import {
  SET_ADMINLIST_LOADING,
  SET_ADMIN_LIST,
  ADMIN_TOAST_MESSAGE,
  SET_ADMIN_PAGE_STATUS,
  SET_SELECTED_ADMIN_DETAIL,
  SET_ADMIN_DETAIL_PAGE_STATUS,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const getALLAdminListAction = () => async (dispatch) => {
  try {
    const user = store.getState().loggedInUser?.user;
    const token = store.getState().auth.token;
    dispatch({
      type: SET_ADMINLIST_LOADING,
      payload: true,
    });
    const { data } = await gradify.get(
      `users?${
        user.role === "ultraAdmin"
          ? "role=ultraAdmin&role=superAdmin&role=admin"
          : ""
      }${
        user.role === "superAdmin"
          ? `role=superAdmin&role=admin&system.organization=${
              user?.system[0]?.organization?._id
            }${
              user?.system[0]?.project?._id
                ? `&system.project=${user?.system[0]?.project?._id}`
                : ""
            }${
              user?.system[0]?.category?._id
                ? `&system.category=${user?.system[0]?.category?._id}`
                : ""
            }`
          : ""
      }${
        user.role === "admin"
          ? `role=admin&system.organization=${
              user?.system[0]?.organization?._id
            }${
              user?.system[0]?.project?._id
                ? `&system.project=${user?.system[0]?.project?._id}`
                : ""
            }${
              user?.system[0]?.category?._id
                ? `&system.category=${user?.system[0]?.category?._id}`
                : ""
            }`
          : ""
      }&limit=1000`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch({
      type: SET_ADMIN_LIST,
      payload: data.data,
    });
    dispatch({
      type: SET_ADMINLIST_LOADING,
      payload: false,
    });
    dispatch({
      type: ADMIN_TOAST_MESSAGE,
      payload: { message: "" },
    });
    dispatch({
      type: SET_ADMIN_PAGE_STATUS,
      payload: "DEFAULT",
    });
    dispatch({
      type: SET_SELECTED_ADMIN_DETAIL,
      payload: {},
    });
    dispatch({
      type: SET_ADMIN_DETAIL_PAGE_STATUS,
      payload: "LOADING",
    });
  } catch (error) {
    console.log(error);
    // what's in error?
  }
};
