import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminMessageSelector,
  formatAdminListSelector,
  addAdminLoadingSelector,
  adminPageStatusSelector,
  allAppOrganizationsSelector,
  allAppProjectsFilterSelector,
  allAppCategoriesSelector,
  allAppSubCategoriesSelector,
  selectedAppOrganizationIdSelector,
  selectedAppProjectIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import {
  addAdminAction,
  setAdminPageStatus,
  setAppSelectedSubCategoryId,
  getAppSubCategoriesAction,
  clearAppSelectedFilterIds,
  setAppSelectedOrganizationId,
} from "actions";
import Confirm from "components/display.component/confirm.components";
import AnimationCheck from "components/animation.icon/animation.icon.components";

import {
  AdminHeader,
  AdminHeaderTitle,
  AdminContainer,
  AdminHeaderControle,
  SearchAdmin,
  AdminButton,
  AdminContentContainer,
  UploadAdminButton,
  SearchAdminContainer,
  CreateAdminFormCol,
  CreateAdminFormRow,
  LabelAdmin,
} from "components/styled.components";
import AddAdminContainer from "containers/Admin/AddAdmin";
import { MdAddCircle } from "react-icons/md";
import { FaFileUpload } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import SelectComponent from "components/control.component/select.component";
import AdminTable from "components/admin/AdminList";
import {
  getALLAdminListAction,
  getAppProjectsFilterAction,
  getAppCategoriesAction,
} from "actions";
const AdminList = () => {
  const dispatch = useDispatch();
  // selectors
  const adminMessage = useSelector(adminMessageSelector);
  const adminList = useSelector(formatAdminListSelector);
  const isAddAdminLoading = useSelector(addAdminLoadingSelector);
  const adminPageStatus = useSelector(adminPageStatusSelector);
  const organizations = useSelector(allAppOrganizationsSelector);
  const projects = useSelector(allAppProjectsFilterSelector);
  const categories = useSelector(allAppCategoriesSelector);
  const subCategories = useSelector(allAppSubCategoriesSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  const closeAddAdmin = () => {
    setAdminPageStatus(dispatch, "DEFAULT");
  };
  const addAdminHandler = useCallback(
    async (adminInfo) => {
      dispatch(addAdminAction(adminInfo));
    },
    [dispatch]
  );
  useEffect(() => {
    setAppSelectedOrganizationId(dispatch, undefined);
    dispatch(getALLAdminListAction());
    return () => {
      clearAppSelectedFilterIds(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const organizationChangedHandler = useCallback(
    (e) => {
      const selectedValue = e.target.value;
      dispatch(getAppProjectsFilterAction(selectedValue));
    },
    [dispatch]
  );
  return (
    <>
      <AdminHeader>
        <AdminHeaderTitle>ADMIN </AdminHeaderTitle>
      </AdminHeader>
      <AdminContainer style={{paddingLeft:30}}>
        <AdminHeaderControle style={{flexWrap:"wrap"}}>
          <CreateAdminFormRow style={{ paddingLeft: 0, marginTop: 10, width:"100%" }}>

            <CreateAdminFormCol
              Direction="column"
              style={{ marginLeft:0 , paddingLeft:0}}
            >
              <LabelAdmin>Organization</LabelAdmin>
              <SelectComponent
                options={organizations}
                name="organization"
                selectedValue={selectedOrganizationId}
                selected={true}
                onChange={organizationChangedHandler}
                style={{ minWidth: 180, marginRight: 0, paddingRight: 3 }}
              />
            </CreateAdminFormCol>
            <CreateAdminFormCol Direction="column">
              <LabelAdmin>Project</LabelAdmin>
              <SelectComponent
                options={projects}
                selectedValue={selectedProjectId}
                name="Project"
                selected={true}
                onChange={(e) => {
                  dispatch(getAppCategoriesAction(e.target.value));
                }}
                style={{ minWidth: 180, marginRight: 0, paddingRight: 3 }}
              />
            </CreateAdminFormCol>
            <CreateAdminFormCol Direction="column">
              <LabelAdmin>Category</LabelAdmin>
              <SelectComponent
                options={categories}
                name="category"
                selectedValue={selectedCategoryId}
                selected="true"
                onChange={(e) => {
                  dispatch(getAppSubCategoriesAction(e.target.value));
                }}
                style={{ minWidth: 180, marginRight: 0, paddingRight: 3 }}
              />
            </CreateAdminFormCol>
            <CreateAdminFormCol Direction="column">
              <LabelAdmin>Sub Category</LabelAdmin>
              <SelectComponent
                options={subCategories}
                name="SubCategory"
                selectedValue={selectedSubCategoryId}
                onChange={(e) => {
                  setAppSelectedSubCategoryId(dispatch, e.target.value);
                }}
                style={{ minWidth: 180, marginRight: 0, paddingRight: 3 }}
              />
            </CreateAdminFormCol>
          </CreateAdminFormRow>
          <CreateAdminFormRow style={{ paddingRight: 20, width:"46%" ,justifyContent:"space-between" }}>
           <SearchAdminContainer>
              <BiSearchAlt
                spaceleft="18px"
                style={{ position: "absolute", top: 11, left: 18 }}
                color={"#707070"}
              />
              <SearchAdmin type="text" placeholder="Search Admin" />
             </SearchAdminContainer>
            <AdminButton
              style={{
                paddingRight: 4,
                paddingTop: 6,
                paddingBottom: 6,
                fontSize: 16,
              }}
              onClick={() => {
                setAdminPageStatus(dispatch, "ADD_ADMIN_POPUP");
              }}
            >
              {" "}
              <span style={{ paddingRight: 18 }}>Add Admin</span>{" "}
              <MdAddCircle size={32} />
            </AdminButton>
            {adminPageStatus === "ADD_ADMIN_POPUP" && (
              <AddAdminContainer
                isAddAdminLoading={isAddAdminLoading}
                addAdminHandler={addAdminHandler}
                onCancelClick={closeAddAdmin}
              />
            )}
            {adminPageStatus === "ADMIN_MESSAGE_POPUP" && (
              <Confirm message={adminMessage}>
                <AnimationCheck />
              </Confirm>
            )}
            <UploadAdminButton spaceLeft="7px">
              {" "}
              <span style={{ paddingRight: 20 }}>Upload Admin</span>{" "}
              <FaFileUpload size={32} />
            </UploadAdminButton>
          </CreateAdminFormRow>
        </AdminHeaderControle>

        <AdminContentContainer>
          <AdminTable adminList={adminList} />
        </AdminContentContainer>
      </AdminContainer>
    </>
  );
};

export default AdminList;
