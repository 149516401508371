import React from "react";
import SelectComponent from "components/control.component/select.component.jsx";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  StudentInput,
  LabelStudent,
} from "components/styled.components/create.student";
import AdvanceSelect from "components/control.component/advenceSelect";

const UniversityComponent = ({
  universityOption,
  setUniversityName,
  universityName,
  userInfo,
  setUserInfo,
}) => {
  return (
    <>
      <CreateStudentFormRow
        style={{
          marginTop: 30,
          paddingTop: 10,
        }}
      >
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="university">University</LabelStudent>
          <AdvanceSelect
            loadOptions={universityOption}
            value={universityName}
            onChange={(selectedUniversity) => {
              setUniversityName(selectedUniversity);
              setUserInfo({
                ...userInfo,
                university: {
                  ...userInfo.university,
                  universityID: selectedUniversity.value,
                },
              });
            }}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="universityId">University ID</LabelStudent>
          <StudentInput
            type="number"
            id="universityId"
            name="universityId"
            value={""}
            onChange={() => {}}
          />
        </CreateStudentFormCol>
        {/* <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="universityCode">University Code</LabelStudent>
          <StudentInput
            type="number"
            id="universityCode"
            name="universityCode"
            value={""}
            onChange={() => {}}
          />
        </CreateStudentFormCol> */}
        <CreateStudentFormCol Direction="column">
          <LabelStudent htmlFor="universityEmail">
            University email
          </LabelStudent>
          <StudentInput
            type="number"
            id="universityEmail"
            name="universityEmail"
            value={""}
            onChange={() => {}}
          />
        </CreateStudentFormCol>
      </CreateStudentFormRow>
    </>
  );
};

export default UniversityComponent;
