import { USER_SYSTEM } from "../constant/system";

export const userSystemReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SYSTEM:
      return {
        organization: action.payload.organization,
        project: action.payload.project,
        category: action.payload.category,
        subCategory: action.payload.subCategory,
      };
    default:
      return state;
  }
};
