export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const CATEGORY_PROJECT_LIST_REQUEST = "CATEGORY_PROJECT_LIST_REQUEST";
export const CATEGORY_PROJECT_LIST_SUCCESS = "CATEGORY_PROJECT_LIST_SUCCESS";
export const CATEGORY_PROJECT_LIST_FAIL = "CATEGORY_PROJECT_LIST_FAIL";

export const CATEGORY_PROJECT_ADD_REQUEST = "CATEGORY_PROJECT_ADD_REQUEST";
export const CATEGORY_PROJECT_ADD_SUCCESS = "CATEGORY_PROJECT_ADD_SUCCESS";
export const CATEGORY_PROJECT_ADD_FAIL = "CATEGORY_PROJECT_ADD_FAIL";

export const CATEGORY_PROJECT_DELETE_REQUEST =
  "CATEGORY_PROJECT_DELETE_REQUEST";
export const CATEGORY_PROJECT_DELETE_SUCCESS =
  "CATEGORY_PROJECT_DELETE_SUCCESS";
export const CATEGORY_PROJECT_DELETE_FAIL = "CATEGORY_PROJECT_DELETE_FAIL";

export const CATEGORY_PROJECT_UPDATE_PENDING =
  "CATEGOERY_PROJECT_UPDATE_PENDING";
export const CATEGORY_PROJECT_UPDATE_SUCCESS =
  "CATEGORY_PROJECT_UPDATE_SUCCESS";
export const CATEGORY_PROJECT_UPDATE_FAIL = "CATEGORY_PROJECT_UPDATE_FAIL";
