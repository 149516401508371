export const roleMapping = {
  superAdmin: "Super Admin",
  ultraAdmin: "Ultra Admin",
  admin: "Admin",
};
export const backRoleMapping = {
  "Super Admin": "superAdmin",
  "Ultra Admin": "ultraAdmin",
  Admin: "admin",
};
export const hasAdminAccess = (loggedInRole, adminRole) => {
  if (loggedInRole === "ultraAdmin" && roleMapping[loggedInRole] === adminRole)
    return false;
  if (loggedInRole === "ultraAdmin" && roleMapping[loggedInRole] !== adminRole)
    return true;
  if (loggedInRole === "superAdmin" && adminRole === "Admin") return true;
  return false;
};
export const formatAdminList = (data) =>
  data.map((adminItem) => {
    return {
      _id: adminItem._id,
      firstName: adminItem.firstName,
      lastName: adminItem.lastName,
      adminPhoto: adminItem.profilePicture,
      adminRole: roleMapping[adminItem.role],
      organizationName:
        adminItem.system.length > 0
          ? adminItem.system[0]?.organization?.short_form_name
          : "All",
      projectName:
        adminItem.system.length > 0
          ? adminItem.system[0]?.project?.name
          : "All",
      categoryName:
        adminItem.system.length > 0
          ? adminItem.system[0]?.category?.name
          : "All",
      subCategoryName:
        adminItem.system.length > 0
          ? adminItem.system[0]?.subCategory?.name
          : "All",
    };
  });
const filterAdminSystemById = (adminList, filterItem, selectedId) => {
  const filtered = [];
  adminList.map((admin) => {
    if (admin.system.find((sys) => sys[filterItem]?._id === selectedId)) {
      filtered.push(admin);
      return filtered;
    }
    return filtered;
  });
  return filtered;
};
export const filterAdminList = (
  adminList,
  organizationId,
  projectId,
  categoryId,
  subCategoryId
) => {
  const x = organizationId
    ? filterAdminSystemById(adminList, "organization", organizationId)
    : adminList;
  const y =
    organizationId && projectId
      ? filterAdminSystemById(x, "project", projectId)
      : x;
  const z =
    organizationId && projectId && categoryId
      ? filterAdminSystemById(y, "category", categoryId)
      : y;
  const w =
    organizationId && projectId && categoryId && subCategoryId
      ? filterAdminSystemById(z, "subcategory", subCategoryId)
      : z;
  return w;
};
export const formatAdminDetail = (data) => ({
  adminId: data._id,
  adminName: `${data.firstName || ""} ${data.lastName || ""}`,
  firstName: data.firstName,
  lastName: data.lastName,
  organizationId: data.system && data.system[0]?.organization?.id,
  project: {
    name: data.system && data.system[0]?.project?.name,
    _id: data.system && data.system[0]?.project?.id,
  },
  organizationName: data.system && data.system[0]?.organization?.title,
  organizationSchoolDistrict: {
    _id: data.system && data.system[0]?.project?._id,
    name: data.system && data.system[0]?.project?.name,
  },
  organizationUniversity: {
    name: data.university && data?.university?.universityID?.instnm,
    _id: data.university && data?.university?.universityID?._id,
  },
  //what happened to highschool he searched and add?
  organizationHighSchoolName: data.highSchool?.school_name,
  adminRole: roleMapping[data.role] || "",
  adminImg: data.profilePicture || "",
  adminJobTitle: data.jobTitle || "",
  adminEmail: data.email || "",
  adminPhone: data.phone || "",
  organizationType:
    data.system && data.system[0]?.organization?.organization_type,
});
export const formatHighSchoolList = (data, system) => {
  const highSchoolList = {};
  data &&
    data.map((item) => {
      return (highSchoolList[item?._id] = {
        _id: item?._id,
        title:
          item?.category?.title ||
          item?.category?.name ||
          item?.project?.name ||
          item?.project?.title,
        schoolName: item?.category?.title || item?.category?.name,
        projectId: item?.project?._id,
        schoolDistrictName: item?.project?.name || item?.project?.title,
        organizationId: system.organization._id,
        categoryId: item?.category?._id,
        access: {
          store: item.store || false,
          surveys: item.surveys || false,
          experienceCards: item.experienceCards || false,
          users: item.users || false,
        },
      });
    });
  return highSchoolList;
};
export const formatSpecialProjectList = (data, system) => {
  const specialProjectList = {};
  data &&
    data.map((item) => {
      return (specialProjectList[item?._id] = {
        _id: item?._id,
        title:
          item?.subCategory?.name ||
          item?.category?.name ||
          item?.project?.name,
        projectId: item?.project?._id,
        projectName: item?.project?.name,
        organizationId: system.organization._id,
        categoryId: item?.category?._id,
        categoryName: item?.category?.name,
        subCategoryId: item?.subCategory?._id,
        subCategoryName: item?.subCategory?.name,
        access: {
          store: item.store || false,
          surveys: item.surveys || false,
          experienceCards: item.experienceCards || false,
          users: item.users || false,
        },
      });
    });
  return specialProjectList;
};
export const formatUniversityList = (data, system) => {
  const universityList = {};
  data &&
    data.map((item) => {
      return (universityList[item?._id] = {
        _id: item?._id,
        title: item?.project?.name,
        projectId: item?.project?._id,
        organizationId: system.organization._id,
        categoryId: null,
        subCategoryId: null,
        access: {
          stores: item.stores || false,
          surveys: item.surveys || false,
          experienceCards: item.experienceCards || false,
          users: item.users || false,
        },
      });
    });
  return universityList;
};
export const formatInputEditAdminDetail = (
  highSchoolList,
  specialProjectList,
  universityList
) => {
  const schoolDistrictPermissionEdit = Object.values(highSchoolList).map(
    (highSchoolItem) => {
      return {
        organization: highSchoolItem.organizationId,
        project: highSchoolItem.projectId,
        category: highSchoolItem.categoryId,
        experienceCards: highSchoolItem.access?.experienceCards || false,
        store: highSchoolItem.access?.store || false,
        surveys: highSchoolItem.access?.surveys || false,
        users: highSchoolItem.access?.users || false,
      };
    }
  );
  const spicalProjectPermissionEdit = Object.values(specialProjectList).map(
    (specialProjectItem) => {
      return {
        organization: specialProjectItem.organizationId,
        project: specialProjectItem?.projectId || null,
        category: specialProjectItem?.categoryId || null,
        subCategory: specialProjectItem?.subCategoryId || null,
        experienceCards: specialProjectItem.access?.experienceCards || false,
        store: specialProjectItem.access?.store || false,
        surveys: specialProjectItem.access?.surveys || false,
        users: specialProjectItem.access?.users || false,
      };
    }
  );
  const universityPermissionEdit = Object.values(universityList).map(
    (universityItem) => {
      return {
        organization: universityItem.organizationId,
        project: universityItem?.projectId || null,
        category: null,
        subCategory: null,
        experienceCards: universityItem.access?.experienceCards || false,
        store: universityItem.access?.store || false,
        surveys: universityItem.access?.surveys || false,
        users: universityItem.access?.users || false,
      };
    }
  );
  return {
    schoolDistrictPermissionEdit,
    spicalProjectPermissionEdit,
    universityPermissionEdit,
  };
};
export const formatListArr = (data) => {
  const formatList = {};
  data &&
    Object.values(data).map((item) => {
      return (formatList[item?._id] = {
        ...item,
        title: item.categoryId ? item.title : item.schoolDistrictName,
      });
    });
  return formatList;
};
