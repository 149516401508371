import { CHANGE_SPECIAL_PROJECT_ACCESS_PERMISSION } from "constant";
export const changeSpecialProjectAccessPermissionAction = (
  dispatch,
  accessName,
  accessValue
) => {
  dispatch({
    type: CHANGE_SPECIAL_PROJECT_ACCESS_PERMISSION,
    payload: { accessName, accessValue },
  });
};
