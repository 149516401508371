import { gradify } from "axios.config";
import store from "Store";
import {
  SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
  SET_RAFFLE_TICKET_DETAIL,
  SET_RAFFLE_TICKET_STUDENT_LIST,
  SET_RAFFLE_TICKET_DETAIL_IMAGE,
} from "constant";
import { formatRaffleStudents } from "helpers";
export const getRaffleTicketDetailAction = (itemId) => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
      payload: "LOADING",
    });
    const { data } = await gradify.get(`raffletickets/${itemId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL,
      payload: data.data,
    });
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL_IMAGE,
      payload: { url: data.data?.raffle_image, isLoading: false },
    });
    dispatch({
      type: SET_RAFFLE_TICKET_STUDENT_LIST,
      payload: formatRaffleStudents(data.data.students),
    });
    dispatch({
      type: SET_RAFFLE_TICKET_DETAIL_PAGE_STATUS,
      payload: "DEFAULT",
    });
  } catch (error) {
    console.log(error);
  }
};
