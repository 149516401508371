import { createSelector } from "reselect";
import { formatShopList, filterShopList, sortShopListByTitle } from "helpers";

export const shopItemListSelector = createSelector(
  (state) => state.shop.shopItemList,
  (shopItemList) => shopItemList
);
export const shopPageStatusSelector = createSelector(
  (state) => state.shop.pageStatus,
  (pageStatus) => pageStatus
);
export const shopItemImageSelector = createSelector(
  (state) => state.shop.shopImage,
  (shopImage) => shopImage
);
export const shopToastMessageSelector = createSelector(
  (state) => state.shop.toastMessage,
  (toastMessage) => toastMessage
);

export const shopListSelector = createSelector(
  (state) => state?.shop,
  (shop) => shop
);

export const shopTotal = createSelector(
  (state) => state?.shop.total,
  (total) => total
);
export const shopPagination = createSelector(
  (state) => state?.shop.pagination,
  (pagination) => pagination
);
// export const shopListSelector = (isReversed, searchShopInput) =>
//   createSelector(
//     (state) => ({
//       shopList: state.shop.shopItemList,
//       organizationId: state.app?.selectedOrganizationId,
//       projectId: state.app.filteredSystemId?.projectId,
//       categoryId: state.app.filteredSystemId?.categoryId,
//       subCategoryId: state.app.filteredSystemId?.subCategoryId,
//     }),
//     ({ shopList, organizationId, projectId, categoryId, subCategoryId }) => {
//       return sortShopListByTitle(
//         formatShopList(
//           filterShopList(
//             shopList,
//             organizationId,
//             projectId,
//             categoryId,
//             subCategoryId
//           )
//         ),
//         isReversed,
//         searchShopInput
//       );
//     }
//   );
