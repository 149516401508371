import { getScoresAction } from "actions/scores";
import React, { useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { selectProgressData, selectProgressPercent } from "store/selectors";

const StudentDetailFilter = ({ user, selectOrganization }) => {
  const { percent } = useSelector((state) => selectProgressData(state));
  const dispatch = useDispatch();
  const expActiveRef = useRef(null);
  const publishHandler = useCallback(() => {
    expActiveRef.current.style.transform = "translateX(0)";

    dispatch(
      getScoresAction(
        user?._id,
        selectOrganization.project ? selectOrganization.project : null,
        percent,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        selectOrganization.category ? selectOrganization.category : null,
        selectOrganization.categories ? selectOrganization.categories : []
      )
    );
  }, [selectOrganization, user, percent]);

  const savedHandler = useCallback(() => {
    expActiveRef.current.style.transform = "translateX(100%)";

    dispatch(
      getScoresAction(
        user?._id,
        selectOrganization.project ? selectOrganization.project : null,
        percent,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    );
  }, [selectOrganization, user, percent]);
  return (
    <div className="student-filter-container">
      <div className="account " onClick={publishHandler}></div>
      <div className="all" onClick={savedHandler}></div>
      <div className="stuActive" ref={expActiveRef}></div>
    </div>
  );
};

export default StudentDetailFilter;
