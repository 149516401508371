import { TableRow, TableCell } from "@material-ui/core";
import React from "react";
import { StudentButton } from "components/styled.components";
import { MdModeEdit } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import Moment from "moment";
const ExperienceCardList = ({ experineceCard, history }) => {
  return (
    <>
      {experineceCard && (
        <TableRow>
          <TableCell>
            <img
              className="exppic"
              src={experineceCard.experienceCardPic}
              alt=""
            />{" "}
          </TableCell>
          <TableCell>{experineceCard.title}</TableCell>
          <TableCell>{experineceCard.description}</TableCell>
          <TableCell>
            <div>
              {new Date(experineceCard.startDate).toISOString().split("T")[0]}{" "}
              {experineceCard.startTime}
            </div>
            <div>
              {new Date(experineceCard.endDate).toISOString().split("T")[0]}{" "}
              {experineceCard.endTime}
            </div>
          </TableCell>
          <TableCell> - </TableCell>
          <TableCell>{experineceCard.xpCompletion}</TableCell>
          <TableCell>{experineceCard.experienceType.name}</TableCell>
          <TableCell>{experineceCard.completionType.name}</TableCell>
          <TableCell style={{ overflow: "visible" }}>
            <div style={{ display: "flex" }}>
              <StudentButton
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5,
                  marginLeft: 2,
                }}
                onClick={() =>
                  history.push(`/admin/experiences/edit/${experineceCard._id}`)
                }
              >
                <span className="experience-item-button" style={{ paddingRight: 5 }}>Edit</span>
                <MdModeEdit />
              </StudentButton>
              <StudentButton
                style={{ display: "flex", alignItems: "center", marginLeft: 3 }}
                onClick={() =>
                  history.push(
                    `/admin/experiences/review/${experineceCard._id}`
                  )
                }
              >
                <span className="experience-item-button" style={{ paddingRight: 5 }}>Review</span>
                <FaRegChartBar />
              </StudentButton>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default withRouter(ExperienceCardList);
