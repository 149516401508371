import {
  SEND_FORGOT_PASSWORD_CODE,
  CHANGE_PASSWORD,
} from "../../constant/forgotpassword";
import { gradify } from "axios.config";

export const forgotPasswordAction = (email) => async (dispatch) => {
  const { data } = await gradify.post(
    "forgotpassword",
    {
      email,
    },
    {
      headers: { "Content-type": "application/json" },
    }
  );

  dispatch({
    type: SEND_FORGOT_PASSWORD_CODE,
    payload: { code: data.code, email },
  });
};

export const changePasswordAction =
  (password, email) => async (dispatch, getState) => {
    const { data: emailInfo } = await gradify.get(`users?email=${email}`, {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });

    if (!emailInfo) return "Email does not exist";

    const { data: updatedUser } = await gradify.put(
      `users/changePassword/${emailInfo.data[0]._id}`,
      { password },
      {
        headers: { Authorization: "Bearer " + getState().auth.token },
      }
    );

    // dispatch({
    //     type:CHANGE_PASSWORD,
    //     payload:updatedUser.data
    // })
  };
