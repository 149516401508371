import React from "react";
import ReactApexChart from "react-apexcharts";


const AreaChart = ({logins,loginCount}) => {
  
   const data = logins.map((item,index) => {
     return {x: new Date(item.createdAt).getTime(),y:index}
   })
 
    const series = [
        {
          name: "Logins",
          data: data
        },
      ];
      const options = {
        chart: {
          id: "simple-bar",
          toolbar: {
              show:false
          }
        },
        colors: ['#00D1B1', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
        stroke: {
            curve: 'smooth',
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [1]
          },
        xaxis: {
            type: 'datetime',
            hideOverlappingLabels:true,

            labels: {
                show:true
              },
              showDuplicates:false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm',
            },
        },
      };

    return (
        <div id="chart">
            <h4>usage</h4>
            <ReactApexChart options={options} series={series} type="area" height={160}  />
        </div>
    )

}

export default AreaChart;
