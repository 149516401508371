import { GET_ALL_INVENTORY,ADD_AN_INVENTORY,LOADING_INVENTORY,PENDING_INVENTORY,SUCCEED_INVENTORY,UPDATE_SERIAL_NUMBER,UPDATE_INVENTORY } from "constant/inventory";
import produce from "immer";


const INITIAL_STATE = {
    inventories:{},
    status:'loading' //loading,pending,succeed
}


export const inventoryReudcer = (state=INITIAL_STATE,action) => 
    produce(state,(draft) => {
        switch(action.type){
            case LOADING_INVENTORY:
                draft.status = 'loading';
                break;
            case PENDING_INVENTORY:
                draft.status = 'pending';
                break;
            case SUCCEED_INVENTORY:
                draft.status = 'succeed';
                break;        
            case GET_ALL_INVENTORY:
                draft.inventories = action.payload;
                break;
            case ADD_AN_INVENTORY:
               draft.inventories[Object.values(action.payload)[0].stock_id] = Object.values(action.payload)[0];
               break    
            case UPDATE_SERIAL_NUMBER:
                const newUpdateObject  = {}
                for (let [key, value] of Object.entries(draft.inventories)) {
                    // ${key} = ${{...value,serialNumber:action.payload}}
                    newUpdateObject[key] = {...value,serialNumber:action.payload}
                  }

                  draft.inventories = newUpdateObject;
                  console.log(draft.inventories);
                  break;
             case UPDATE_INVENTORY:
                 console.log(Object.values(action.payload)[0].stock_id)
                draft.inventories[Object.values(action.payload)[0].stock_id] = Object.values(action.payload)[0];
                 break;   



             

             
        }    
    })



