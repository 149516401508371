import {
  SET_PHOTO_URL_LOADING,
  SET_PHOTO_URL_SUCCESS,
  ADMIN_TOAST_MESSAGE,
  PHOTO_URL_FAIL,
  SET_ADMIN_LIST,
  SET_ADD_ADMIN_LOADING,
  ADD_ADMIN_IN_ADMINLIST,
  SET_ADMINLIST_LOADING,
  SET_ADMIN_PAGE_STATUS,
} from "constant";
import produce from "immer";
import organizationImage from "assets/image/defualt-icon.png";

const INITIAL_STATE = {
  photo: { isLoading: false, url: organizationImage },
  message: { message: "", isShown: false },
  isAddAdminLoading: false,
  adminList: [],
  isAdminListLoading: false,
  pageStatus: "Default",
};

const adminReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_PHOTO_URL_LOADING:
        draft.photo.isLoading = action.payload.isLoading;
        break;
      case SET_PHOTO_URL_SUCCESS:
        draft.photo = { isLoading: false, url: action.payload.url };
        break;
      case PHOTO_URL_FAIL:
        draft.photo = { isLoading: false, url: null };
        break;
      case ADMIN_TOAST_MESSAGE:
        draft.message = action.payload.message;
        break;
      case SET_ADMIN_LIST:
        draft.adminList = action.payload;
        break;
      case ADD_ADMIN_IN_ADMINLIST:
        draft.adminList = [...draft.adminList, action.payload];
        break;
      case SET_ADD_ADMIN_LOADING:
        draft.isAddAdminLoading = action.payload.isLoading;
        break;
      case SET_ADMINLIST_LOADING:
        draft.isAdminListLoading = action.payload;
        break;
      case SET_ADMIN_PAGE_STATUS:
        draft.pageStatus = action.payload;
        break;
      // no default
    }
  });
export default adminReducer;
