import React from "react";
import { SelectGradifyStyle } from "../styled.components";
const SelectComponent = ({
  options,
  onChange,
  name,
  style,
  selected,
  loading,
  value,
  selectedValue,
  isDisabled,
  noShowSlectText,
}) => {
  const changeHandler = (e) => {
    onChange(e, e.target.value);
  };
  return (
    <SelectGradifyStyle
      onChange={changeHandler}
      name={name}
      style={style}
      value={value}
      isDisabled={isDisabled}
      disabled={isDisabled}
    >
      {selected && (
        <option value={null} selected>
          {noShowSlectText && noShowSlectText ? "" : "Select"} {name}
        </option>
      )}
      {options &&
        options.map((opt, index) => {
          return (
            <option
              key={index}
              value={opt._id}
              selected={opt._id === selectedValue}
            >
              {opt.name || opt.title}
            </option>
          );
        })}
    </SelectGradifyStyle>
  );
};

export default SelectComponent;
