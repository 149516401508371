import {
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_UPDATE_FAIL,
  USER_DETAILS_UPDATE_REQUEST,
  USER_DETAILS_UPDATE_SUCCESS,
  UPDATE_XP_USER,
} from "../constant/userDetails";
import { gradify } from "../axios.config";
import {
  UPDATE_ORGANIZATION_SYSTEM_FAILED,
  UPDATE_ORGANIZATION_SYSTEM_REQUEST,
  UPDATE_ORGANIZATION_SYSTEM_SUCCESS,
  UPDATE_USER_LIST,
} from "constant/user";
export const userDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });
    const { data } = await gradify.get(`users/${id}`, {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserDetailsInfoAction =
  (userDetails) => async (dispatch, getState) => {
    try {
      const user = { ...userDetails };
      delete user.profilePicture;
      delete user.backgroundBanner;

      dispatch({
        type: USER_DETAILS_UPDATE_REQUEST,
      });

      const { data } = await gradify.put(`users/${user._id}`, user, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      console.log("system", data.data.system);
      const { data: updateUserRead } = await gradify.put(
        `userread/updateUserRead?id=${user._id}&fullName=${
          user.firstName + " " + user.lastName
        }`,
        {
          system: data.data.system,
        }
      );
      dispatch({
        type: USER_DETAILS_UPDATE_SUCCESS,
        payload: data.data,
      });
      console.log("update", updateUserRead.data);
      dispatch({
        type: UPDATE_USER_LIST,
        payload: {
          id: updateUserRead.data._id,
          fullName: updateUserRead.data.fullName,
        },
      });
    } catch (error) {
      console.log(error.message);
      dispatch({
        type: USER_DETAILS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateXpDetails = (amount) => (dispatch) => {
  dispatch({
    type: UPDATE_XP_USER,
    payload: {
      amount,
    },
  });
};

export const updateOrganizationSystem =
  (userId, system, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ORGANIZATION_SYSTEM_REQUEST,
      });

      const { data } = await gradify.put(
        `users/${userId}`,
        { system },
        {
          headers: { Authorization: "Bearer " + getState().auth.token },
        }
      );

      dispatch({
        type: UPDATE_ORGANIZATION_SYSTEM_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ORGANIZATION_SYSTEM_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
