import React, { useState, useEffect } from "react";
import SelectComponent from "../control.component/select.component";
import {
  CreateStudentFormCol,
  CreateStudentContainer,
  CreateStudentForm,
  CreateStudentFormRow,
  StudentInput,
  LabelStudent,
} from "../styled.components/create.student";
import { SaveButton } from "../styled.components";
import WarningPopup from "../warningPopup/WarningPopup";
import {
  PERSONALITY_TYPE,
  WORKING,
  GENDER,
  FIRST_GEN,
} from "../../constant/userDetails";
import { updateUserDetailsInfoAction } from "../../actions/userDetailsAction";
import { useDispatch, useSelector } from "react-redux";

const EditStudentAbout = ({ close, student }) => {
  const [studentDetails, setStudentDetails] = useState({});
  const [showFormWarning, setShowFormWarning] = useState(false);
  const dispatch = useDispatch();
  const { userDetails, loading, error } = useSelector(
    (state) => state.userDetails
  );
  useEffect(() => {
    if (student) {
    
      setStudentDetails(student);
    }
  }, [student]);

  const closeWarningForm = () => {
    setShowFormWarning(false);
    close();
  };
  const closeWarning = () => {
    setShowFormWarning(false);
  };

  const closeForm = (e) => {
    setShowFormWarning(true);

  };

  const changeFirstGenHandler = (e) => {
    setStudentDetails({
      ...studentDetails,
      firstGen: e.target.value === "YES" ? true : false,
    });
  };
  const changeStudentDetailsHandler = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };

  const updateInfoStudentHandler = () => {
    dispatch(updateUserDetailsInfoAction(studentDetails));
    close();
  };

  return (
    <CreateStudentContainer className="form-student-add">
      <CreateStudentForm style={{  maxWidth: 1100 }}>
        <CreateStudentFormRow>
          <CreateStudentFormCol
            alignment="flex-end"
            style={{ paddingRight: 15 }}
          >
            <div
              style={{
                fontFamily: "Rajdhani",
                fontSize: 45,
                fontWeight: 600,
                paddingLeft: "8vw",
              }}
            >
              {studentDetails.firstName}
            </div>
          </CreateStudentFormCol>
          <CreateStudentFormCol
            alignment="flex-start"
            style={{ paddingLeft: 15 }}
          >
            <div
              style={{
                fontFamily: "Rajdhani",
                fontSize: 45,
                fontWeight: 600,
                paddingLeft: "8vw",
              }}
            >
              {studentDetails.lastName}
            </div>
          </CreateStudentFormCol>
          <CreateStudentFormCol
            Direction="column"
            style={{ marginLeft: "5vw" }}
          >
            <LabelStudent>User_ID</LabelStudent>
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 13,
                color: "#74788D",
              }}
            >
              {studentDetails._id}
            </p>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{ paddingRight: 50, paddingLeft: 50, marginTop: 30 }}
        >
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="firstGen">First Gen?</LabelStudent>
            <SelectComponent
              options={FIRST_GEN}
              name="firstGen"
              selectedValue={studentDetails.firstGen ? "YES" : "NO"}
              onChange={changeFirstGenHandler}
            />
          </CreateStudentFormCol>

          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="personality">Personality type</LabelStudent>
            <SelectComponent
              options={PERSONALITY_TYPE}
              name="personality_type"
              selectedValue={studentDetails.personality_type ? studentDetails.personality_type : "" }
              onChange={changeStudentDetailsHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="survey">GRIT survey</LabelStudent>
            <StudentInput
              type="text"
              name="grit_score"
              id="grit_score"
              value={studentDetails.grit_score}
              onChange={changeStudentDetailsHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="burns">Burns index</LabelStudent>
            <StudentInput
              type="text"
              name="burns_score"
              id="burns_score"
              value={studentDetails.burns_score}
              onChange={changeStudentDetailsHandler}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{ paddingRight: 50, paddingLeft: 50, marginTop: 60 }}
        >
          <CreateStudentFormCol Direction="column">
            <LabelStudent>Working ?</LabelStudent>
            <SelectComponent
              options={WORKING}
              name="working"
              id="working"
              selectedValue={studentDetails.working ? "YES" : "NO"}
              onChange={changeStudentDetailsHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent htmlFor="workingWhere">Working Where</LabelStudent>
            <StudentInput
              type="text"
              name="workingWhere"
              id="workingWhere"
              value={studentDetails.workingWhere}
              onChange={changeStudentDetailsHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent>Age</LabelStudent>
            <StudentInput
              type="text"
              name="age"
              id="age"
              value={studentDetails.age}
              onChange={changeStudentDetailsHandler}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent>Gender</LabelStudent>
            <SelectComponent
              options={GENDER}
              name="gender"
              selectedValue={studentDetails.gender ? studentDetails.gender : "Male"}
              onChange={changeStudentDetailsHandler}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 60,
            marginBottom: 60,
            justifyContent: "center",
          }}
        >
          <CreateStudentFormCol
            style={{ justifyContent: "flex-end", paddingRight: 30 }}
          >
            <SaveButton onClick={updateInfoStudentHandler}>{loading ? 'Sending...' :'Save'}</SaveButton>
          </CreateStudentFormCol>
          <CreateStudentFormCol style={{ paddingLeft: 30 }}>
            <SaveButton onClick={closeForm}>Cancel</SaveButton>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
      </CreateStudentForm>
      {showFormWarning ? (
              <WarningPopup
                close={closeWarningForm}
                text={'Clear All Changes'}
                reject={closeWarning}
                accept={()=>{}}
              />
            ) : null}
      {/* {error && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {error}
        </div>
      )} */}
    </CreateStudentContainer>
  );
};

export default EditStudentAbout;
