import {
    LEAD_CONTACT_ORGANIZATION_FAIL,
    LEAD_CONTACT_ORGANIZATION_REQUEST,
    LEAD_CONTACT_ORGANIZATION_SUCCESS
} from '../constant/leadContact';
import {gradify} from '../axios.config'


export const leadContactOrganization = () => async (dispatch,getState) => {
    try {
        dispatch({
            type:LEAD_CONTACT_ORGANIZATION_REQUEST
        });
        const {data} = await gradify.get(`users?role=superAdmin`,{
            headers: { Authorization: "Bearer " + getState().auth.token }
        });
        
        dispatch({
            type:LEAD_CONTACT_ORGANIZATION_SUCCESS,
            payload:data.data
        })
    } catch (error) {
        dispatch({
            type: LEAD_CONTACT_ORGANIZATION_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          });
    }
}