export const PROJECT_LIST_REQUEST = "PROJECT_LIST_REQUEST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_FAIL = "PROJECT_LIST_FAIL";

export const PROJECT_ORGANIZATION_LIST_REQUEST =
  "PROJECT_ORGANIZATION_LIST_REQUEST";
export const PROJECT_ORGANIZATION_LIST_SUCCESS =
  "PROJECT_ORGANIZATION_LIST_SUCCESS";
export const PROJECT_ORGANIZATION_LIST_FAIL = "PROJECT_ORGANIZATION_LIST_FAIL";

export const PROJECT_ORGANIZATION_ADD_REQUEST =
  "PROJECT_ORGANIZATION_ADD_REQUEST";
export const PROJECT_ORGANIZATION_ADD_SUCCESS =
  "PROJECT_ORGANIZATION_ADD_SUCCESS";
export const PROJECT_ORGANIZATION_ADD_FAIL = "PROJECT_ORGANIZATION_ADD_FAIL";

export const GET_PROJECT_DETAILS_REQUEST = "GET_PROJECT_DETAILS_REQUEST";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_FAIL = "GET_PROJECT_DETAILS_FAIL";

export const UPDATE_PROJECT_DETAILS_REQUEST = "UPDATE_PROJECT_DETAILS_REQUEST";
export const UPDATE_PROJECT_DETAILS_SUCCESS = "UPDATE_PROJECT_DETAILS_SUCCESS";
export const UPDATE_PROJECT_DETAILS_FAIL = "UPDATE_PROJECT_DETAILS_FAIL";

export const UPDATE_PROJECT_SINGLE_REQUEST = "UPDATE_PROJECT_SINGLE_REQUEST";
export const UPDATE_PROJECT_SINGLE_SUCCESS = "UPDATE_PROJECT_SINGLE_SUCCESS";
export const UPDATE_PROJECT_SINGLE_FAIL = "UPDATE_PROJECT_SINGLE_FAIL";

export const RESET_PROJECT = "RESET_PROJECT";
export const RESET_UPDATE_PROJECT = "RESET_UPDATE_PROJECT";

export const PROJECT_STUDENT_COUNT_REQUEST = "PROJECT_STUDENT_COUNT_REQUEST";
export const PROJECT_STUDENT_COUNT_SUCCESS = "PROJECT_STUDENT_COUNT_SUCCESS";
export const PROJECT_STUDENT_COUNT_FAILED = "PROJECT_STUDENT_COUNT_FAILED";

export const PROJECT_SEARCH = "PROJECT_SEARCH";
