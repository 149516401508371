import React , {useState}from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { MdEdit } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import "./awardedTable.style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addXpToUserAction
} from "../../../actions/userListAction";



const AwardedItem = ({item}) => {
  const [xpPanel,setXpPanel] = useState(false);
  const [selectedUsers,setSelectedUsers] = useState([]);
  const [xpInfo,setXpInfo] = useState({
    description:'',
    amount:'',
    addByAdmin:true,
    
})
const today = new Date();

const dispatch = useDispatch();

const changeHandler = (e) =>{
  setXpInfo({...xpInfo,[e.target.name]:e.target.value});
} 
const changeXpHandler = (e) => {
  setXpInfo({...xpInfo,amount:parseInt(e.target.value)})
}

  return (
      <TableRow>
              <TableCell style={{ borderBottom:0  }} >
          <div className="awarded-edit-button"   onClick={() => setXpPanel(true)}>
            <p className="awarded-edit-button-text">Edit</p>
            <div className="awarded-edit-button-icon"><MdEdit color="#34C38F" size={12} /></div>
          </div>
      </TableCell>
      <TableCell style={{ borderBottom:0  }}>
            Bonus point
      </TableCell>
      <TableCell style={{ borderBottom:0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 175,
    overflow: "hidden",
    display: "block",
    paddingTop:11}}>
      {item.description}
      </TableCell>
      <TableCell style={{ borderBottom:0  }}>
        {new Date(item.createdAt).toLocaleDateString()}
        </TableCell>
      <TableCell style={{  borderBottom:0  }}>{item.amount}</TableCell>
      <TableCell style={{ color: "#495057",borderBottom:0  }}>
        <IconButton onClick={() => {}}>
          <FaTrashAlt color="#000" size={21} />
        </IconButton>
      </TableCell>
      {xpPanel ? (
              <>
                <div
                  className="xpContainer"
                  onClick={() => setXpPanel(false)}
                  
                ></div>
                <div className="xpPanle" style={{top:100}}>
                    <div className="xpPanel_header">Add XP</div>
                    <div className="xp_description">
                        <input type="text" name="description" placeholder="Description" className="xpDescription" value={xpInfo.description} onChange={changeHandler} />
                    </div>
                    <div className="xp_description">
                        <input type="text" name="amount" placeholder="Xp Amount" className="xpDescription" value={xpInfo.amount}  onChange={changeXpHandler} />
                        <button className="addxpbutton" onClick={() => {
                          selectedUsers.forEach(item => {
                            const resultUserXp = {...xpInfo,user:item};
                            dispatch(addXpToUserAction(resultUserXp));
                            setXpPanel(false);
                          })
                        }} >+</button>
                    </div>
                    <div className="xpDate">
                      
                        <p className="date_text">Date</p>
                        <p className="date_content">{today.getMonth() + 1}/{today.getDate()}/{today.getFullYear()}</p>
                    </div>
                </div>
              </>
            ) : null}
      </TableRow>
    );
};
export default AwardedItem;
