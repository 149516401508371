import styled from "styled-components";


export const SettingMainContainer = styled.div`
   width: 95%;
   position: relative;
   display:block;

`;

export const SettingHeaderControle = styled.div`
    width: 100%;
    height:40px;
    margin-left: 45px;
    padding: 0;
    display: flex;
    position:relative;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 1770px) {
     flex-wrap: wrap;
    }
`;

export const TabButton = styled.button`
   height:35px;
   background: white;
   ouline: none;
   border: none;
   font-weight: ${props => props.condition? "bold" : "none"};
   cursor: pointer;
   margin: 2px;
   padding:5px;
   font-size: 13px;
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   border: 1px solid rgb(239,239,239);
   border-bottom: ${props => props.condition ? "20px solid white" : "1plx solid rgb(239,239,239)"};

`;

export const SettingBox = styled.div`
   width: 95%;
   margin-top: -3px;
   margin-left: 45px;
   display: block;
   border: 1px solid rgb(239,239,239);
   border-radius: 5px;
  `;

export const SettingTitleRow = styled.div`
   display:block;
   flex-wrap:wrap;
   margin-top: 10px;
   margin-left: 20px;
   position:relative;
   `;


export const SettingText = styled.p`
   font-size: 16px;
  
 `;
 export const DriverText = styled.div`
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 12px;
  color: #495057;
`;
 
//DRIVER

export const DriverTitleBox = styled.div`
    width:100%;
    display: flex;
    flex-flow: wrap;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    justify-content: center;
    margin-top: 20px;
 `;


export const DriverTitle = styled.h1`
   font-size: 34px;
   font-weight: bold;
 `;

export const DriverRow = styled.div`
   display: flex;
   flex-flow: wrap;
   flex-wrap: wrap;
   justify-content: center;
   padding: 10px;
   position: relative;

`;

export const DriverColumn = styled.div`
   display: flex;
   flex-flow: column;
   justify-content: center;
   padding: 10px;
   position: relative;
   justify-content: ${({ alignment }) => alignment || "center"};
  text-align:left;
  flex-direction:${({Direction}) => Direction};
 `;

export const DriverLabel = styled.label`
   font-family:${({theme}) => theme.fonts.Poppins};
   font-size:13px;
   color:#272727;
   width:100%;
`;

export const DriverMainBox = styled.div`
   display: flex;
   flex-flow: row;
   width: 100%;
   padding: 10px;
   position: relative;
   margin-top: 20px;
 `;

export const DriverMainLeftBox = styled.div`
   display: flex;
   flex-flow:column;
   width: 50%;
   padding: 10px;
   position: relative;
   justify-content: space-between;
`;


export const DriverMainTitle = styled.h1`
   font-size: 26px;
   font-weight: bold;
 `;

 export const DriverSaveButton = styled.button`
   color: #FFFFFF;
    background-color: #0078FF;
    border: none;
    border-radius: 50px;
    padding: 10px 46px;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 600;
    align-items: center;
    display: flex;

    margin:10px auto;
`;
export const DriverTextArea = styled.input`
    font-weight: 400;
    font-family: "Poppins";
    line-height: inherit;
    height: 30px;
    margin-right: 12px;
    text-align: center;
    width: 180px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    text-align: left;
    font-size: 13px;
    padding: 0 10px;
  & ::placeholder {
    text-align: center;
  }
`;



