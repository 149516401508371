import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "components/control.component/select.component.jsx";
import {
  CreateStudentFormCol,
  LabelStudent,
  StudentProjectContainer,
  StudentProject,
  StudentCloseProject,
  AddProjectRow,
  AddProjectPopup,
  AddProjectColumn,
  AddProjectTitle,
  AddProjectAddButton,
} from "components/styled.components/create.student";
import { FaPlus } from "react-icons/fa";
import { SaveButton, StudentButton } from "components/styled.components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CreateOrganizationFormRow } from "components/organizations/AddOrganization.style";
import { uuid } from "uuidv4";
import {
  getAppSubCategoriesAction,
  setAppSelectedCategoryId,
  getAppCategoriesAction,
  clearAppSelectedFilterIds,
  setAppSelectedSubCategoryId,
} from "actions";
import {
  selectedAppProjectIdSelector,
  selectedProjectInfoSelector,
  selectedOrganizationInfoSelector,
  allAppCategoriesSelector,
  allAppSubCategoriesSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
  selectedAppOrganizationIdSelector,
} from "store/selectors";
import { formatArrToObj } from "helpers";

const AddProjectComponent = ({ userInfo, setUserInfo }) => {
  const dispatch = useDispatch();
  //states
  const [projectPanel, setProjectPanel] = useState(false);
  //selectors
  const selectedOrganizationId = useSelector(selectedAppOrganizationIdSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);
  const projectInfo = useSelector(selectedProjectInfoSelector);
  const organizationInfo = useSelector(selectedOrganizationInfoSelector);
  const categories = useSelector(allAppCategoriesSelector);
  const subCategories = useSelector(allAppSubCategoriesSelector);
  useEffect(() => {
    dispatch(getAppCategoriesAction(selectedProjectId));
    return () => {
      clearAppSelectedFilterIds(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <CreateOrganizationFormRow
        style={{ alignItems: "center", marginTop: 20 }}
      >
        <CreateStudentFormCol Direction="column">
          <StudentButton
            style={{
              borderRadius: 50,
              marginTop: 10,
              marginRight: 10,
              marginLeft: 20,
            }}
            onClick={() => {
              setProjectPanel(true);
            }}
          >
            <FaPlus style={{ marginRight: 12 }} size={27} />
            <span>Add Project</span>
          </StudentButton>
          {projectPanel ? (
            <>
              <AddProjectRow>
                <AddProjectPopup>
                  <AddProjectColumn alignment={"center"}>
                    <AddProjectTitle style={{ marginTop: 8, marginBottom: 20 }}>
                      Projects
                    </AddProjectTitle>
                  </AddProjectColumn>
                  <AddProjectColumn
                    style={{ marginLeft: "5%" }}
                    Direction="column"
                  >
                    <LabelStudent htmlFor="project">
                      Organization: {organizationInfo?.title}
                    </LabelStudent>
                  </AddProjectColumn>
                  <AddProjectColumn
                    style={{ marginLeft: "5%", paddingTop: 18, width: 180 }}
                  >
                    <AddProjectColumn
                      style={{ paddingLeft: 0 }}
                      Direction="column"
                      alignment={"center"}
                    >
                      <LabelStudent htmlFor="project">
                        Project: {projectInfo?.name}
                      </LabelStudent>
                    </AddProjectColumn>
                    <AddProjectColumn Direction="column" alignment={"center"}>
                      <LabelStudent htmlFor="category">Category</LabelStudent>
                      <SelectComponent
                        style={{ marginRight: 10, width: 180 }}
                        options={categories}
                        selected={true}
                        name="category"
                        selectedValue={selectedCategoryId}
                        onChange={(e) => {
                          dispatch(getAppSubCategoriesAction(e.target.value));
                          setAppSelectedCategoryId(dispatch, e.target.value);
                        }}
                      />
                    </AddProjectColumn>
                    <AddProjectColumn Direction="column" alignment={"center"}>
                      <LabelStudent htmlFor="subCategory">
                        Sub Category
                      </LabelStudent>
                      <SelectComponent
                        style={{ marginRight: 10, width: 180 }}
                        options={subCategories}
                        name="SubCategory"
                        selected={true}
                        selectedValue={selectedSubCategoryId}
                        onChange={(e) => {
                          setAppSelectedSubCategoryId(dispatch, e.target.value);
                        }}
                      />
                    </AddProjectColumn>
                    <AddProjectColumn>
                      <AddProjectAddButton
                        style={{ borderRadius: 50, marginLeft: 10 }}
                        onClick={() => {
                          //add the object project to state
                          const system = {
                            id: uuid(),
                            organization: selectedOrganizationId,
                            organizationName: organizationInfo?.title,
                            project: selectedProjectId,
                            projectName: projectInfo?.name,
                            category: selectedCategoryId,
                            categoryName: categories
                              ? formatArrToObj(categories)[selectedCategoryId]
                                  ?.name ?? ""
                              : "",
                            subCategory: selectedSubCategoryId,
                            subCategoryName: subCategories
                              ? formatArrToObj(subCategories)[
                                  selectedSubCategoryId
                                ]?.name ?? ""
                              : "",
                          };

                          const existSystem = userInfo.system.find(
                            (x) =>
                              x.organization === system.organization &&
                              x.project === system.project &&
                              x.category === system.category &&
                              x.subCategory === system.subCategory
                          );

                          if (!existSystem) {
                            setUserInfo({
                              ...userInfo,
                              system: [...userInfo.system, system],
                            });
                          }
                        }}
                      >
                        <FaPlus size={10} />
                      </AddProjectAddButton>
                    </AddProjectColumn>
                  </AddProjectColumn>
                  <AddProjectColumn
                    Direction="column"
                    style={{ marginLeft: "5%", marginRight: "3%" }}
                  >
                    <LabelStudent
                      htmlFor="subCategory"
                      style={{
                        marginTop: 20,
                        marginLeft: 5,
                        fontSize: 18,
                        color: "#003267",
                      }}
                    >
                      Projects added
                    </LabelStudent>
                    <StudentProjectContainer>
                      {userInfo.system.map((item, index) => {
                        return (
                          <StudentProject
                            key={index + 1}
                            className="item_student_project"
                          >
                            <span>
                              Organization: {" " + item.organizationName}
                            </span>
                            <span>Project: {" " + item.projectName} </span>
                            <span>
                              Category: {" " + item.categoryName ?? ""}
                            </span>
                            <span>
                              Subcategory: {" " + item?.subCategoryName || ""}
                            </span>
                            <StudentCloseProject
                              onClick={() => {
                                const result = userInfo.system.filter(
                                  (x) => x.id !== item.id
                                );
                                setUserInfo({ ...userInfo, system: result });
                              }}
                            >
                              <AiOutlineCloseCircle
                                spaceLeft="18px"
                                color={"#707070"}
                              />
                            </StudentCloseProject>
                          </StudentProject>
                        );
                      })}
                    </StudentProjectContainer>
                  </AddProjectColumn>

                  <AddProjectColumn
                    alignment={"center"}
                    style={{
                      justifyContent: "space-around",
                      marginTop: "2%",
                      marginBottom: "2%",
                      marginLeft: "25%",
                      marginRight: "25%",
                    }}
                  >
                    <SaveButton
                      onClick={() => {
                        setProjectPanel(false);
                      }}
                    >
                      SAVE
                    </SaveButton>
                  </AddProjectColumn>
                </AddProjectPopup>
              </AddProjectRow>
            </>
          ) : null}
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column" style={{ width: "70%" }}>
          <StudentProjectContainer>
            {userInfo.system.map((item, index) => (
              <StudentProject key={index + 1} className="item_student_project">
                <span>
                  Organization:
                  {" " + item.organizationName === null
                    ? ""
                    : item.organizationName}
                </span>
                <span>
                  Project: {" " + item.projectName ? item.projectName : ""}
                </span>
                <span>
                  Category: {" " + item.categoryName ? item.categoryName : ""}
                </span>
                <span>
                  Subcategory:
                  {" " + item.subCategoryName ? item.subCategoryName : ""}
                </span>
                <StudentCloseProject
                  onClick={() => {
                    const result = userInfo.system.filter(
                      (x) => x.id !== item.id
                    );
                    setUserInfo({ ...userInfo, system: result });
                  }}
                >
                  <AiOutlineCloseCircle spaceLeft="18px" color={"#707070"} />
                </StudentCloseProject>
              </StudentProject>
            ))}
          </StudentProjectContainer>
        </CreateStudentFormCol>
      </CreateOrganizationFormRow>
    </>
  );
};

export default AddProjectComponent;
