import { createSelector } from "reselect";
export const experienceListSelector = createSelector(
  (state) => state?.experienceList?.experienceList,
  (experienceList) => experienceList
);
export const experienceSortTypeSelector = createSelector(
  (state) => state?.experienceList?.filter?.sort,
  (sort) => sort
);
export const experienceSearchInputSelector = createSelector(
  (state) => state?.experienceList?.filter?.searchInput,
  (searchInput) => searchInput || ""
);
export const experienceTypeFilterSelector = createSelector(
  (state) => state?.experienceList?.filter?.type,
  (type) => type || "publish"
);
// progress chart calculations selector

export const selectExperienceListRange = (state, startDate) => {
  const count = state.experienceLists.experienceList.data.filter((item) => {
    return new Date(item.startDate) > startDate;
  });

  return count.length;
};

export const selectExperienceCompeleteRange = (state, startDate) => {
  const count = state.experienceCompleteList.experienceCompleteList.data.filter(
    (item) => {
      return new Date(item.startDate) > startDate;
    }
  );

  return count.length;
};

export const selectExpreiencePassDueRange = (state, startDate) => {
  const count = state.experiencePassDueList.experiencePassDueList.data.filter(
    (item) => {
      return new Date(item.startDate) > startDate;
    }
  );

  return count.length;
};

export const selectProgressPercent = createSelector(
  [
    selectExperienceListRange,
    selectExperienceCompeleteRange,
    selectExpreiencePassDueRange,
  ],
  (expList, expComplete, expPassdue) => {
    const total = expList + expComplete + expPassdue;
    const percent = Math.round((expComplete / total) * 100);
    return percent;
  }
);

export const selectProgressData = createSelector(
  (state) => state.Progress.progressCalc,
  (progressCalc) => {
    return {
      percent: Math.round(progressCalc?.current),
    };
  }
);

export const selectMomentumData = createSelector(
  (state) => state.momentumState.momentumCalc,
  (momentumCalc) => {
    console.log("selectmom", momentumCalc);
    return {
      percent: Math.round(momentumCalc?.current),
      lastPercent: Math.round(momentumCalc?.last),
    };
  }
);
