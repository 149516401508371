import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ShopContainer,
  ShopContentContainer,
} from "components/styled.components/shop.style";
import ShopList from "components/shop/ShopList";
import { getALLShopItemListAction, clearAppSelectedFilterIds } from "actions";

const ShopListContainer = ({
  onSortShopList,
  sortType,
  haveAccess,
  onEditButtonClick,
  shopList,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getALLShopItemListAction("title"));
    return () => {
      clearAppSelectedFilterIds(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ShopContainer>
      <ShopContentContainer
        style={{ width: "99%", height: "50%", paddingLeft: 0, marginLeft: 0 }}
      >
        {console.log(shopList , 'ffff')}
        <ShopList
          onEditButtonClick={onEditButtonClick}
          haveAccess={haveAccess}
          shopList={shopList}
          onSortShopList={onSortShopList}
          sortType={sortType}
        />
      </ShopContentContainer>
    </ShopContainer>
  );
};

export default ShopListContainer;
