import React, { useCallback, useState } from "react";
import RaffleIcon from "assets/image/raffleIcon.png";
import moment from "moment";
import HorizontalScroll from "components/HorizontalScroll";
import CountDownTimer from "components/CountDownTimer/ConuntDownTimer";
import {
  TitleWrapper,
  Wrapper,
  Title,
  Desc,
  Raffle,
  WhiteHeader,
  BlackHeader,
  DateWrapper,
  DescWrapper,
  CountDown,
  RemainingTime,
  Winners,
  WinnersName,
  Sold,
  SectionCategoryWrapper,
} from "./index.style";
import { raffleTicketStudentsSelector } from "store/selectors";
import { useSelector } from "react-redux";
import SelectComponent from "components/control.component/select.component";
const Header = ({
  headerTitle,
  startDate,
  onStartDateChange,
  startTime,
  onStartTimeChange,
  closeDate,
  onCloseDateChange,
  closeTime,
  onCloseTimeChange,
  drawDate,
  onDrawDateChange,
  drawTime,
  onDrawTimeChange,
  winnersName,
  soldNum,
  leftTime,
  categoryOptions,
  selectedCategoryId,
  onCategoryChanged,
  subCategoryOptions,
  selectedSubCategoryId,
  onSubCategoryChanged,
  categories,
  onChangeSelectCategory,
  selectCategory,
}) => {
  const [searchStudentValue, setSearchStudentValue] = useState("");
  const [selectedFilterId, setSelectedFilterId] = useState("All");
  const studentListSelectorCallback = useCallback(
    raffleTicketStudentsSelector(searchStudentValue, selectedFilterId),
    [searchStudentValue, selectedFilterId]
  );
  const studentList = useSelector(studentListSelectorCallback);
  return (
    <Wrapper>
      <BlackHeader>
        <TitleWrapper>
          <Title>{headerTitle}</Title>
          <Desc>Reward system</Desc>
        </TitleWrapper>
        <Raffle src={RaffleIcon} />
      </BlackHeader>
      <WhiteHeader>
        <div
          style={{
            display: "flex",
            height: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {console.log("select category", selectCategory)}
          <SectionCategoryWrapper Direction="column">
            <Desc style={{ color: "#000", fontSize: 13 }}>Category</Desc>
            <SelectComponent
              options={categories}
              noShowSlectText
              name="All"
              selectedValue={selectCategory}
              selected={true}
              onChange={(e) => {
                console.log(e.target.value);
                onChangeSelectCategory(e.target.value);
              }}
              style={{ minWidth: 160 }}
            />
          </SectionCategoryWrapper>
          <SectionCategoryWrapper Direction="column">
            <Desc style={{ color: "#000", fontSize: 13 }}>Sub Category</Desc>

            <SelectComponent
              options={subCategoryOptions}
              noShowSlectText
              name="All"
              selected={true}
              selectedValue={selectedSubCategoryId}
              onChange={onSubCategoryChanged}
              style={{ minWidth: 160 }}
            />
          </SectionCategoryWrapper>
        </div>

        <DateWrapper>
          <div style={{ paddingRight: 10 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 15, marginBottom: 0 }}>
              Start Date
            </p>
            <div>
              <input
                type="date"
                name="startDate"
                id="startDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={startDate}
                onChange={(e) => onStartDateChange(e)}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="startTime"
                id="startTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={startTime}
                onChange={(e) => {
                  onStartTimeChange(e);
                }}
              />
            </div>
          </div>
          <div style={{ paddingRight: 10 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 15, marginBottom: 0 }}>
              Close Date
            </p>
            <div>
              <input
                type="date"
                name="closeDate"
                id="closeDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={closeDate}
                onChange={(e) => onCloseDateChange(e)}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="closeTime"
                id="closeTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={closeTime}
                onChange={(e) => onCloseTimeChange(e)}
              />
            </div>
          </div>
          <div style={{ paddingRight: 10 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 15, marginBottom: 0 }}>
              Drawing Date
            </p>
            <div>
              <input
                type="date"
                name="drawDate"
                id="drawDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={drawDate}
                onChange={(e) => onDrawDateChange(e)}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="darwTime"
                id="drawTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={drawTime}
                onChange={(e) => onDrawTimeChange(e)}
              />
            </div>
          </div>
        </DateWrapper>
        <DescWrapper className="counter-responsive">
          <CountDown>CountDown</CountDown>
          <CountDownTimer time={leftTime} />

          <Winners>WINNER(s)</Winners>
          <HorizontalScroll>
            {/* map winnersName with this WinnersName */}
            {console.log("array studentList", Object.values(studentList))}
            {studentList &&
              Object.values(studentList).map((item) =>
                item.isWinner ? (
                  <WinnersName>{item.name + ", "}</WinnersName>
                ) : null
              )}
          </HorizontalScroll>
        </DescWrapper>
        <DescWrapper>
          <Sold>Sold</Sold>
          <Sold style={{ color: "#B7B7BC" }}>{soldNum}</Sold>
        </DescWrapper>
      </WhiteHeader>
    </Wrapper>
  );
};

export default Header;
