import {
  SET_EDIT_PHOTO_URL_LOADING,
  SET_EDIT_PHOTO_URL_SUCCESS,
  PHOTO_EDIT_URL_FAIL,
} from "constant";
import store from "Store";
import axios from "axios";

export const changeEditPhotoUrl = (dispatch, photoUrl) => {
  dispatch({
    type: SET_EDIT_PHOTO_URL_SUCCESS,
    payload: { url: photoUrl },
  });
};
export const uploadEditPhoto = async (dispatch, photoFile) => {
  try {
    dispatch({
      type: SET_EDIT_PHOTO_URL_LOADING,
      payload: { isLoading: true },
    });
    const formData = new FormData();
    formData.append("file", photoFile);
    const { data } = await axios.post(
      `https://apigrad.azurewebsites.net/api/v2/photos`,
      formData,
      {
        headers: { Authorization: `Bearer ${store.getState().auth.token}` },
      }
    );
    dispatch({
      type: SET_EDIT_PHOTO_URL_SUCCESS,
      payload: { url: `https://apigrad.azurewebsites.net${data.url}` },
    });
  } catch (error) {
    dispatch({
      type: PHOTO_EDIT_URL_FAIL,
      payload: { isLoading: false },
    });
  }
};
