import styled from 'styled-components';

export const VerticalContainer = styled.div`
    border:1px solid #74788D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:10px;
    height: fit-content;
    padding: 2px;
    height:100%;
    width:${({Width}) => Width ? Width : "100%"};
   
`;

export const Vertical = styled.h6`
    font-family: 'Poppins';
    font-size: 8px;
    color:#74788D;
    white-space: nowrap;
    margin: 0;
    transform: rotate(-90deg);
    align-items: center;
    overflow: hidden;
    width: 80px;
    display: inline;
    text-overflow: ellipsis;
    text-align: center;
`;