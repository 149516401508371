import React from "react";
import { MdAddCircle } from "react-icons/md";
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentHeaderUniversity,
  SearchStudent,
  StudentHeaderControle,
  StudentButton,
  CreateStudentFormCol,
  LabelStudent,
  CreateStudentFormRow,
  CreateStudentAbsoluteRow,
} from "components/styled.components";
import FilterExperience from "components/experience/ExperienceHeader/ExperienceTagFilter";
import SelectComponent from "components/control.component/select.component";
const ExperienceHeader = ({
  categoryOptions,
  selectedCategoryId,
  onCategoryChanged,
  subCategoryOptions,
  selectedSubCategoryId,
  onSubCategoryChanged,
  searchExperienceInput,
  onSearchExperienceChanged,
  onCreateExpCardBtnClick,
}) => {
  return (
    <>
      <ContentHeader>
        <ContentHeaderTitle>
          EXPERIENCE ITEM LIST
          <ContentHeaderUniversity>
            {/* University of Houston-Downtown{" "} */}
          </ContentHeaderUniversity>
        </ContentHeaderTitle>
      </ContentHeader>
      <CreateStudentFormRow>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Category</LabelStudent>
          <SelectComponent
            options={categoryOptions}
            noShowSlectText
            name="All"
            selectedValue={"All"}
            selected={true}
            onChange={onCategoryChanged}
            style={{ minWidth: 180 }}
          />
        </CreateStudentFormCol>
        <CreateStudentFormCol Direction="column">
          <LabelStudent>Sub Category</LabelStudent>
          <SelectComponent
            options={subCategoryOptions}
            noShowSlectText
            name="All"
            selected={true}
            selectedValue={selectedSubCategoryId}
            onChange={onSubCategoryChanged}
            style={{ minWidth: 180 }}
          />
        </CreateStudentFormCol>
        {/* <CreateStudentFormCol
          style={{ padding: 11, fontSize: 20, fontWeight: 700 }}
        > */}
        {/* <div>Or</div> */}
        {/* </CreateStudentFormCol> */}
        {/* <CreateStudentFormCol
          style={{
            borderRightWidth: 2,
            borderColor: "#a09696",
            borderRightStyle: "solid",
            paddingLeft: 10,
          }}
          Direction="column"
        >
          <LabelStudent>Tag</LabelStudent>
          <SelectComponent
            options={""}
            name="tag"
            onChange={() => {}}
            style={{ minWidth: 180 }}
          />
        </CreateStudentFormCol> */}
        <CreateStudentAbsoluteRow>
          <CreateStudentFormCol Direction="column">
            <LabelStudent>Educational Phases</LabelStudent>
            <SelectComponent
              options={""}
              name="education"
              onChange={() => {}}
              style={{ minWidth: 180 }}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column">
            <LabelStudent>Semester</LabelStudent>
            <SelectComponent
              options={""}
              name="semester"
              onChange={() => {}}
              style={{ minWidth: 180 }}
            />
          </CreateStudentFormCol>
        </CreateStudentAbsoluteRow>
      </CreateStudentFormRow>

      <StudentHeaderControle style={{ maxWidth: 1380 }}>
        <SearchStudent
          type="text"
          placeholder="Search Experience"
          value={searchExperienceInput}
          onChange={(e) => onSearchExperienceChanged(e)}
        />

        <StudentButton
          spaceLeft="35px"
          onClick={onCreateExpCardBtnClick}
          style={{ borderRadius: 50, fontSize: 16 }}
        >
          <MdAddCircle size={30} />
          <span style={{ paddingLeft: 8, fontSize: 19 }}>Create Card</span>
        </StudentButton>

        {/*  the filter by save and publish goes here */}
        <FilterExperience />
      </StudentHeaderControle>
    </>
  );
};

export default ExperienceHeader;
