import React, { useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import "../raffle.style.css";
import { withStyles } from "@material-ui/core/styles";
import Unselected from "assets/image/unselected.png";
import BottomSelected from "assets/image/bottomSelected.png";
import TopSelected from "assets/image/topSelected.png";
import RaffleItem from "./RaffleItem";
const RaffleList = ({
  onSortRaffleList,
  sortType,
  raffleList,
  onEditButtonClick,
  haveAccess,
}) => {
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  const renderRaffleItems = useMemo(
    () =>
      raffleList &&
      raffleList.map((raffleItem) => {
        const {
          _id,
          raffle_title,
          description,
          imageUrl,
          start_date,
          end_date,
          draw_date,
        } = raffleItem;
        return (
          <RaffleItem
            key={_id}
            prize={raffle_title}
            description={description}
            imageUrl={imageUrl}
            startDate={start_date}
            closeDate={end_date}
            drawDate={draw_date}
            haveAccess={haveAccess}
            onEditButtonClick={() => onEditButtonClick(_id)}
          />
        );
      }),
    [haveAccess, onEditButtonClick, raffleList]
  );

  return (
    <>
      <Table
        className="raffle-table-container raffle-table-container"
        style={{ width: "100%" }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 14 }}></TableCell>
            <TableCell
              onClick={() => {
                onSortRaffleList("titleSort");
              }}
            >
              Prize
              {sortType?.type === "titleSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortRaffleList("desSort");
              }}
            >
              Description
              {sortType?.type === "desSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortRaffleList("start_date");
              }}
            >
              Start Date
              {sortType?.type === "start_date" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortRaffleList("end_date");
              }}
            >
              Close Date
              {sortType?.type === "end_date" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortRaffleList("draw_date");
              }}
            >
              Drawing Date
              {sortType?.type === "draw_date" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderRaffleItems}</TableBody>
      </Table>
    </>
  );
};

export default RaffleList;
