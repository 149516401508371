import React from 'react';
import './crystals.style.css';
import collagecrystal from '../../assets/image/crystal.png';
const Crystals = ({crystalNumber}) => {
    return (
        <div className="crystal-student">
            <img src={collagecrystal} alt="" />
            <p className="crystal-number">{crystalNumber}</p>
            <p className="crystal">Focus Crystal</p>
        </div>
    )
}

export default Crystals
