import produce from "immer";
import {
  TIME_STUDENT_SCORES_REQUEST,
  TIME_STUDENT_SCORES_SUCCESS,
  TIME_STUDENT_SCORES_FAILD,
} from "../../constant/timescore";

const INITIAL_STATE = {
  loading: false,
  errors: null,
  timingRange: null,
};

export const timingRangeStudentScoreReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TIME_STUDENT_SCORES_REQUEST:
        draft.loading = true;
        break;
      case TIME_STUDENT_SCORES_SUCCESS:
        draft.loading = false;
        draft.timingRange = action.payload;
        break;

      case TIME_STUDENT_SCORES_FAILD:
        draft.loading = false;
        draft.errors = action.payload;
        draft.timingRange = null;
    }
  });
