import {
  SET_SHOP_PAGE_STATUS,
  ADD_SHOP_ITEM_IN_SHOPLIST,
  SET_SHOP_LIST,
  SHOP_TOAST_MESSAGE,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";
import { getALLShopItemListAction } from "../getAllShopItems";

export const addShopItemAction = (shopItemInfo) => async (dispatch) => {
  try {
    const token = store.getState().auth.token;
    const selectedOrganizationId = store.getState().app.selectedOrganizationId;
    const selectedProjectId = store.getState().app.selectedProjectId;

    const { organization, project } = shopItemInfo;
    if (organization && project) {
      dispatch({
        type: SET_SHOP_PAGE_STATUS,
        payload: "ADD_SHOPITEM_LOADING",
      });
      // api add shop item
      const { data: addedShopItem } = await gradify.post(
        "shopitems",
        shopItemInfo,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: ADD_SHOP_ITEM_IN_SHOPLIST,
        payload: addedShopItem,
      });

      dispatch({
        type: SET_SHOP_PAGE_STATUS,
        payload: "SHOP_MESSAGE_POPUP",
      });
      dispatch({
        type: SHOP_TOAST_MESSAGE,
        payload: {
          message: "SHOP ITEM ADDED SUCCESSFULY",
          hasError: false,
        },
      });
      setTimeout(() => {
        dispatch({
          type: SET_SHOP_PAGE_STATUS,
          payload: "DEFAULT",
        });
        dispatch({
          type: SHOP_TOAST_MESSAGE,
          payload: { message: "", hasError: false },
        });
      }, 2000);

      //api All shop
      const { data } = await gradify.get(
        `shopitems?limit=25${
          organization ? `&organization=${organization}` : ""
        }${selectedProjectId ? `&project=${project}` : ""}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: SET_SHOP_LIST,
        payload: data.data,
      });
      dispatch(getALLShopItemListAction({}, 1));
    } else {
      dispatch({
        type: SET_SHOP_PAGE_STATUS,
        payload: "SHOP_MESSAGE_POPUP",
      });
      dispatch({
        type: SHOP_TOAST_MESSAGE,
        payload: {
          message: "SELECT ORGANIZATION AND PROJECT FIRST",
          hasError: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: SET_SHOP_PAGE_STATUS,
          payload: "DEFAULT",
        });
        dispatch({
          type: SHOP_TOAST_MESSAGE,
          payload: { message: "", hasError: false },
        });
      }, 2000);
    }
  } catch (error) {
    dispatch({
      type: SHOP_TOAST_MESSAGE,
      payload: {
        message: "SHOP ITEM ADDED FAILED",
        hasError: true,
      },
    });
    console.log(error);
    const oldShopList = store.getState().shop.shopItemList;
    dispatch({
      type: SET_SHOP_LIST,
      payload: oldShopList,
    });
    setTimeout(() => {
      dispatch({
        type: SET_SHOP_PAGE_STATUS,
        payload: "DEFAULT",
      });
      dispatch({
        type: SHOP_TOAST_MESSAGE,
        payload: { message: "", hasError: false },
      });
    }, 2000);
  }
};
