import {COMPLETION_DETAILS_REQUEST,
    COMPLETION_DETAILS_SUCCESS,
    COMPLETION_DETAILS_FAIL
    } from "../constant/completion";
    
    export const completionDetailsReducer = (state = { completionDetails:{}},action) => {
        switch(action.type){
            case COMPLETION_DETAILS_REQUEST:
                return {...state,loading:true,completionDetails:{}}
            case COMPLETION_DETAILS_SUCCESS:
                return {...state,loading:false,completionDetails:action.payload}
            case COMPLETION_DETAILS_FAIL:
                return {...state,loading:false,completionDetails:{},error:action.payload}        
            default:
                return state;
        }
    }