import React from "react";
import { HomeTitle, HomeRow, HomeColumn } from "components/home/home.style";
import HomeItem from "components/home/HomeList/HomeItem";
import { useSelector } from "react-redux";
import { appPageStatusSelector } from "store/selectors";
import Spinner from "components/spinner/spinner";

const HomeAll = ({ onProjectClick, projects }) => {
  const pageStatus = useSelector(appPageStatusSelector);

  return (
    <>
      <HomeRow Direction="column" style={{ marginTop: 10 }}>
        <HomeColumn>
          {projects.length > 0 && <HomeTitle>ALL</HomeTitle>}
        </HomeColumn>
        <HomeColumn>
          {pageStatus === "homeLoading" ? (
            <Spinner style={{ width: 50, height: 50, top: 20 }} />
          ) : (
            projects?.map((project) => {
              const { name, project_type: type, image } = project;
              return (
                <HomeItem
                  key={project._id}
                  onProjectClick={() => onProjectClick(project._id)}
                  name={name}
                  type={type}
                  image={image}
                  studentLength={project?.studentCount}
                />
              );
            })
          )}
        </HomeColumn>
      </HomeRow>
    </>
  );
};

export default HomeAll;
