import {
  SET_RAFFLE_TICKET_LIST,
  SET_RAFFLE_TICKET_PAGE_STATUS,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const getALLRaffleItemListAction =
  (sortType, page, searchInput, category) => async (dispatch) => {
    try {
      const selectedOrganizationId =
        store.getState().app.selectedOrganizationId;
      const selectedProjectId = store.getState().app.selectedProjectId;

      const token = store.getState().auth.token;
      dispatch({
        type: SET_RAFFLE_TICKET_PAGE_STATUS,
        payload: "RAFFLELIST_LOADING",
      });
      const sort = sortType?.isReversed ? `-${sortType?.type}` : sortType?.type;
      let query;
      if (searchInput) {
        query = `raffletickets/search?term=${searchInput}${
          selectedProjectId ? `&project=${selectedProjectId}` : ""
        }`;
      } else {
        if (category && category !== "All") {
          query = `raffletickets?${
            selectedOrganizationId
              ? `&organization=${selectedOrganizationId}`
              : ""
          }${
            selectedProjectId ? `&project=${selectedProjectId}` : ""
          }&category=${category}&sort=${sort}&page=${page}`;
        } else if (category == "All" || category == undefined) {
          query = `raffletickets?${
            selectedOrganizationId
              ? `&organization=${selectedOrganizationId}`
              : ""
          }${
            selectedProjectId ? `&project=${selectedProjectId}` : ""
          }&sort=${sort}&page=${page}`;
        }
      }

      const { data } = await gradify.get(query, {
        headers: { Authorization: `Bearer ${token}` },
      });

      dispatch({
        type: SET_RAFFLE_TICKET_LIST,
        payload: data,
      });
      dispatch({
        type: SET_RAFFLE_TICKET_PAGE_STATUS,
        payload: "DEFAULT",
      });
    } catch (error) {
      console.log(error, "error");
      // what's in error?
    }
  };
