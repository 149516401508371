import { createSelector } from "reselect";
import {
  formatShopItemDetail,
  searchStudentsByName,
  filterShopStudentsById,
} from "helpers";

export const shopItemDetailSelector =  (state) => state.shopItemDetail.shopItemDetail ? 
 formatShopItemDetail( state.shopItemDetail.shopItemDetail) : {}
 

export const shopDetailItemImageSelector = createSelector(
  (state) => state.shopItemDetail?.shopItemImage,
  (shopItemImage) => shopItemImage
);
export const shopItemDetailPageStatusSelector = createSelector(
  (state) => state.shopItemDetail.pageStatus,
  (pageStatus) => pageStatus
);
export const shopItemStudentsSelector = (
  searchStudentInput,
  selectedFilterId
) =>
  createSelector(
    (state) => state.shopItemDetail.shopItemStudents,
    (shopItemStudents) => {
      return filterShopStudentsById(
        searchStudentsByName(shopItemStudents, searchStudentInput),
        selectedFilterId
      );
    }
  );
export const shopItemDetailToastMessageSelector = createSelector(
  (state) => state.shopItemDetail.toastMessage,
  (toastMessage) => toastMessage
);
