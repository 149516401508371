import {
  SET_SHOP_ITEM_DETAIL,
  SET_SHOPITEM_DETAIL_PAGE_STATUS,
  SET_SHOPITEM_STUDENT_LIST,
  SHOPITEM_DETAIL_TOAST_MESSAGE,
  SET_PICKUP_STUDENT_SHOPITEM,
  SET_SHOP_ITEM_DETAIL_IMAGE,
} from "constant";
import produce from "immer";
const INITIAL_STATE = {
  shopItemDetail: {},
  shopItemImage: { url: "", isLoading: false },
  pageStatus: "DEFAULT",
  shopItemStudents: {},
  toastMessage: {
    message: "",
    hasError: false,
  },
};

const shopItemDetailReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_SHOP_ITEM_DETAIL:
        draft.shopItemDetail = action.payload;
        break;
      case SET_SHOP_ITEM_DETAIL_IMAGE:
        draft.shopItemImage = action.payload;
        break;
      case SET_SHOPITEM_DETAIL_PAGE_STATUS:
        draft.pageStatus = action.payload;
        break;
      case SET_SHOPITEM_STUDENT_LIST:
        draft.shopItemStudents = action.payload;
        break;
      case SET_PICKUP_STUDENT_SHOPITEM:
        draft.shopItemDetail.students = draft.shopItemDetail.students.map(student => student._id === action.payload.id ? student= {...student,pickup:action.payload.isPickedup}: student)
        draft.shopItemStudents[action.payload.id] = {
          ...draft.shopItemStudents[action.payload.id],
          isPickedup: action.payload.isPickedup,
          pickupDate: `${new Date(
            action.payload.pickupDate
          ).getDate()}-${new Date(
            action.payload.pickupDate
          ).getMonth()}-${new Date(action.payload.pickupDate).getFullYear()}`,
        };
        break;
      case SHOPITEM_DETAIL_TOAST_MESSAGE:
        draft.toastMessage = action.payload;
        break;
      // no default
    }
  });
export default shopItemDetailReducer;
