import {
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAIL,
  PROJECT_ORGANIZATION_ADD_FAIL,
  PROJECT_ORGANIZATION_ADD_REQUEST,
  PROJECT_ORGANIZATION_ADD_SUCCESS,
  PROJECT_ORGANIZATION_LIST_FAIL,
  PROJECT_ORGANIZATION_LIST_REQUEST,
  PROJECT_ORGANIZATION_LIST_SUCCESS,
  GET_PROJECT_DETAILS_FAIL,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_REQUEST,
  UPDATE_PROJECT_DETAILS_FAIL,
  UPDATE_PROJECT_DETAILS_REQUEST,
  UPDATE_PROJECT_DETAILS_SUCCESS,
  RESET_PROJECT,
  RESET_UPDATE_PROJECT,
  UPDATE_PROJECT_SINGLE_SUCCESS,
  PROJECT_STUDENT_COUNT_REQUEST,
  PROJECT_STUDENT_COUNT_SUCCESS,
} from "../constant/project";
import { gradify } from "../axios.config";
export const getProjectList =
  (role, userId, projectId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROJECT_LIST_REQUEST,
      });
      let query;
      switch (role) {
        case "ultraAdmin":
          query = "projects";
          break;
        case "superAdmin":
          query = `projects`;

          break;
        case "admin":
          query = `project/${projectId}`;
          break;
      }

      const { data } = await gradify.get(query, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: PROJECT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {}
  };

export const addOrganizationProject =
  (projectInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROJECT_ORGANIZATION_ADD_REQUEST,
      });

      const { data } = await gradify.post(`projects`, projectInfo, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: PROJECT_ORGANIZATION_ADD_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_ORGANIZATION_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setTimeout(() => {
        dispatch({
          type: PROJECT_ORGANIZATION_ADD_FAIL,
          payload: null,
        });
      }, 3000);
    }
  };

export const getOrganizationProject =
  (organizationID, user) => async (dispatch, getState) => {
    let query;
    switch (user.role) {
      case "ultraAdmin":
        query = `projects?organization[in]=${organizationID}`;
        break;
      case "superAdmin":
        query = `projects?organization[in]=${organizationID}`;
        const superAdminAccessProject = [
          ...user.schoolDistrictPermissionEdit,
          ...user.spicalProjectPermissionEdit,
        ];
        superAdminAccessProject.forEach((item) => {
          query += `&name=${item.project?.name}`;
        });

        break;
      case "admin":
        query = `projects?organization[in]=${organizationID}`;
        const superAdminAccessProjectAdmin = [
          ...user.schoolDistrictPermissionEdit,
          ...user.spicalProjectPermissionEdit,
        ];
        superAdminAccessProjectAdmin.forEach((item) => {
          query += `&name=${item.project?.name}`;
        });

        break;
      // no default
    }
    try {
      dispatch({
        type: PROJECT_ORGANIZATION_LIST_REQUEST,
      });
      const { data } = await gradify.get(query, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: PROJECT_ORGANIZATION_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_ORGANIZATION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getProjectDetailsAction =
  (projectId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PROJECT_DETAILS_REQUEST,
      });

      const { data } = await gradify.get(`projects/${projectId}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      //todo: this should be removed when back is ok
      dispatch({
        type: GET_PROJECT_DETAILS_SUCCESS,
        payload: data.data._doc,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateProjectAction =
  (projectId, projectInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PROJECT_DETAILS_REQUEST,
      });

      const { data } = await gradify.put(`projects/${projectId}`, projectInfo, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: UPDATE_PROJECT_DETAILS_SUCCESS,
        payload: data.data,
      });

      dispatch({
        type: UPDATE_PROJECT_SINGLE_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PROJECT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetProject = () => (dispatch) => {
  dispatch({
    type: RESET_PROJECT,
  });
};

export const resetUpdateProject = () => (dispatch) => {
  dispatch({
    type: RESET_UPDATE_PROJECT,
  });
};
