import React, { useContext, useEffect } from "react";
import {
  getAllExperienceUserActions,
  updateCompletionExperienceUserAction,
} from "../../actions/experienceUserActions";
import PhotoItem from "./PhotoItem";
import "./photosubmission.css";
import Spinner from "components/spinner/spinner";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { photoExperineceAction } from "actions/photo-experience";
import { photoListCacheSelect } from "reducers/photo-experience/photo-experience.selector";
import Slider from "components/sliders/experienece/slider";
import { IoGameController } from "react-icons/io5";
import { setItemList } from "helpers";
import { experienceStudentListAction } from "actions/experienceStudentList/experienceStudentList.action";
import {
  SearchExperienceStudentContainer,
  SearchExperienceStudent,
} from "components/styled.components/experience.details.style";
import { BiSearchAlt } from "react-icons/bi";
import { ContactSupportOutlined } from "@material-ui/icons";

const PhotoSubmission = ({ experience, users }) => {
  const [checkState, setCheckState] = useState(null);
  const [showSlider, setShowSlider] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [photoArray, setPhotoArray] = useState([]);

  const [gallery, setGallery] = useState([]);
  const dispatch = useDispatch();
  // const pohotlists = useSelector(photolistSelector);
  const { isPending, isSuccess, errors, photoList, pagination } =
    useSelector(photoListCacheSelect);
  console.log("run photExperience");
  useEffect(() => {
    if (experience) {
      dispatch(photoExperineceAction(experience._id, 1));
    }
  }, [experience, dispatch]);

  useEffect(() => {
    if (experience) {
      dispatch(
        experienceStudentListAction(
          experience.project,
          experience.category ? experience.category : null,
          experience._id,
          1
        )
      );
    }
  }, [experience]);

  if (isPending) {
    return <Spinner style={{ width: 50, height: 50, top: 20 }} />;
  } else if (!!errors) {
    console.log(errors);
  } else {
    const imageClickHandler = (itemId, itemIndex) => {
      setPhotoArray([]);
      {
        users &&
          users?.data.map((item, index) =>
            item.photos ? setPhotoArray((current) => [...current, item]) : null
          );
      }
      console.log(photoArray, "photoarray");
      setShowSlider(true);
      setImageIndex(itemIndex);
      const newSlide = photoArray?.filter((item, index) => {
        if (itemId == item?._id) {
          return item;
        }
      });
      setGallery(newSlide);
    };
    const nextBtnHandler = () => {
      let newIndexCount = imageIndex;
      newIndexCount = newIndexCount + 1;
      const newSlide = photoArray?.filter((item, index) => {
        if (newIndexCount == index) {
          return item;
        }
      });
      setGallery(newSlide);
      setImageIndex(newIndexCount);
    };

    const leftBtnHandler = () => {
      let newIndexCount = imageIndex;
      console.log(newIndexCount);
      if (newIndexCount == 0) {
        setImageIndex(0);
      } else {
        newIndexCount = newIndexCount - 1;
        setImageIndex(newIndexCount);
      }
      const newSlide = photoArray?.filter((item, index) => {
        console.log(newIndexCount, index);
        if (newIndexCount == index) {
          return item;
        }
      });
      setGallery(newSlide);
    };

    const backdropHandler = () => {
      setShowSlider(false);
    };



    return (
      <>
         
        { (showSlider && gallery.length > 0) && 
            gallery.map((item , index)=>{
                    return ( <Slider
                    item={item}
                    index={index}
                    backdropHandler={() => backdropHandler()}
                    nextBtn={() => nextBtnHandler()}
                    preBtn={() => leftBtnHandler()}
                    key={item._id}
                    experience={experience}
                    />
                    )
         
                    })
            }
    
      
        <div className="photo-submission-container">
          {/* <h1 className="photo-submission-title">Submission</h1> */}
          <div className="photo_container">
            {users &&
              users?.data.map((item, index) =>
                item.photos ? (
                  <PhotoItem
                    key={item._id}
                    item={item}
                    experience={experience}
                    index={index}
                    imageClicked={() => imageClickHandler(item?._id, 0)}
                  />
                ) : null
              )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
        </div>
      </>
    );
  }
};

export default PhotoSubmission;
