import CircleChart from "components/charts/CircleChart";
import React, { memo, useEffect, useState } from "react";

const ProgressChart = ({
  percent,
  lastPercent,
  textWeight,
  chartPercentCal,
  title,
  description,
  lastchartPercentCal,
  numbericCircle,
}) => {
  console.log("per", percent);
  return (
    <>
      {console.log("progress calc chart", chartPercentCal)}
      <CircleChart
        top={47}
        left={-20}
        scale={1}
        title={title}
        description="Score"
        chartPercentCal={chartPercentCal}
        percent={percent}
        lastChartPercentCal={lastchartPercentCal}
      />
    </>
  );
};

export default memo(ProgressChart);
