import {
  SET_APP_FILTER_OPTIONS,
  SET_APP_FILTERED_SYSTEM_ID,
  SET_APP_SELECTED_ORGANIZATION_ID,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";
// export const getAppOrganizations = () => async (dispatch) => {
//   try {
//     const token = store.getState().auth.token;
//     const user = store.getState().loggedInUser?.user;
//     const query = `organization`;
//     // ${
//     //   user?.system[0]?.organization?._id
//     //     ? `?_id=${user?.system[0]?.organization?._id}`
//     //     : ""
//     // }
//     const { data } = await gradify.get(query, {
//       headers: { Authorization: `Bearer ${token}` },
//     });

//     dispatch({
//       type: SET_APP_FILTER_OPTIONS,
//       payload: {
//         organizations: data.data,
//         projects: undefined,
//         categories: undefined,
//         subCategories: undefined,
//       },
//     });
//   } catch (error) {
//     dispatch({
//       type: SET_APP_FILTER_OPTIONS,
//       payload: {
//         organizations: undefined,
//         projects: undefined,
//         categories: undefined,
//         subCategories: undefined,
//       },
//     });
//   }
// };

export const getAppProjectsFilterAction =
  (organizationId) => async (dispatch) => {
    try {
      const token = store.getState().auth.token;
      const user = store.getState().loggedInUser?.user;
      const organizations = store.getState().app.organizations;
      if (
        organizationId !== undefined &&
        organizationId !== "Select organization"
      ) {
        dispatch({
          type: SET_APP_SELECTED_ORGANIZATION_ID,
          payload: organizationId,
        });

        const query = `projects?organization[in]=${organizationId}`;
        // ${
        //   user?.system[0]?.project?._id
        //     ? `&_id=${user?.system[0]?.project?._id}`
        //     : ""
        // }
        const { data } = await gradify.get(query, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (organizations && organizations.length !== 0) {
          dispatch({
            type: SET_APP_FILTER_OPTIONS,
            payload: {
              projects: data.data,
              categories: undefined,
              subCategories: undefined,
            },
          });
        }
      } else {
        dispatch({
          type: SET_APP_SELECTED_ORGANIZATION_ID,
          payload: undefined,
        });
        dispatch({
          type: SET_APP_FILTER_OPTIONS,
          payload: {
            projects: undefined,
            categories: undefined,
            subCategories: undefined,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_APP_FILTER_OPTIONS,
        payload: {
          projects: undefined,
          categories: undefined,
          subCategories: undefined,
        },
      });
    }
  };
export const getAppCategoriesAction = (projectId) => async (dispatch) => {
  try {
    const selectedOrganizationId = store.getState().app.selectedOrganizationId;
    const token = store.getState().auth.token;
    const user = store.getState().loggedInUser?.user;
    const organizations = store.getState().app.organizations;
    const projects = store.getState().app.filterOptions?.projects;
    if (projectId !== undefined && projectId !== "Select Project") {
      dispatch({
        type: SET_APP_FILTERED_SYSTEM_ID,
        payload: {
          projectId: projectId,
          categoryId: undefined,
          subCategoryId: undefined,
        },
      });

      const { data } = await gradify.get(`categories?project=${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // ${
      //   user?.system[0]?.category?._id
      //     ? `&_id=${user?.system[0]?.category?._id}`
      //     : ""
      // }
      if (
        organizations &&
        organizations.length !== 0 &&
        selectedOrganizationId &&
        projects &&
        projects.length !== 0
      ) {
        dispatch({
          type: SET_APP_FILTER_OPTIONS,
          payload: {
            projects,
            categories: data.data,
            subCategories: undefined,
          },
        });
      } else {
        dispatch({
          type: SET_APP_FILTER_OPTIONS,
          payload: {
            projects,
            categories: data.data,
            subCategories: undefined,
          },
        });
      }
    } else {
      dispatch({
        type: SET_APP_FILTERED_SYSTEM_ID,
        payload: {
          projectId: undefined,
          categoryId: undefined,
          subCategoryId: undefined,
        },
      });
      dispatch({
        type: SET_APP_FILTER_OPTIONS,
        payload: {
          projects,
          categories: undefined,
          subCategories: undefined,
        },
      });
    }
  } catch (error) {
    const projects = store.getState().app.filterOptions?.projects;
    dispatch({
      type: SET_APP_FILTER_OPTIONS,
      payload: {
        projects,
        categories: undefined,
        subCategories: undefined,
      },
    });
  }
};
export const getAppSubCategoriesAction = (categoryId) => async (dispatch) => {
  try {
    const selectedProjectId = store.getState().app.filteredSystemId?.projectId;
    const token = store.getState().auth.token;
    const projects = store.getState().app.filterOptions?.projects;
    const categories = store.getState().app.filterOptions?.categories;
    if (categoryId !== undefined && categoryId !== "All") {
      dispatch({
        type: SET_APP_FILTERED_SYSTEM_ID,
        payload: {
          projectId: selectedProjectId,
          categoryId,
          subCategoryId: undefined,
        },
      });

      const { data } = await gradify.get(
        `subcategories/category/${categoryId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (categories && categories.length !== 0 && categoryId) {
        dispatch({
          type: SET_APP_FILTER_OPTIONS,
          payload: {
            projects,
            categories: categories,
            subCategories: data.data,
          },
        });
      }
    } else {
      dispatch({
        type: SET_APP_FILTERED_SYSTEM_ID,
        payload: {
          projectId: selectedProjectId,
          categoryId: undefined,
          subCategoryId: undefined,
        },
      });
      dispatch({
        type: SET_APP_FILTER_OPTIONS,
        payload: {
          projects,
          categories,
          subCategories: undefined,
        },
      });
    }
  } catch (error) {
    const projects = store.getState().app?.projects;
    const categories = store.getState().app.filterOptions?.categories;

    dispatch({
      type: SET_APP_FILTER_OPTIONS,
      payload: {
        projects,
        categories: categories,
        subCategories: undefined,
      },
    });
  }
};
