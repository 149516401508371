import produce from "immer";
import {
  TIME_SCORE_REQUEST,
  TIME_SCORE_SUCCESS,
  TIME_SCORE_FAILD,
} from "../../constant/timescore";

const INITIAL_STATE = {
  loading: false,
  timeScore: null,
  errors: null,
};

export const timeScoreReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TIME_SCORE_REQUEST:
        draft.loading = true;
      case TIME_SCORE_SUCCESS:
        draft.loading = false;
        draft.timeScore = action.payload;
      case TIME_SCORE_FAILD:
        draft.loading = false;
        draft.timeScore = null;
        draft.errors = action.payload;
    }
  });
