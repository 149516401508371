// export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
// export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
// export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL";

// export const GET_ADMIN_DETAILS_REQUEST = "GET_ADMIN_DETAILS_REQUEST";
// export const GET_ADMIN_DETAILS_SUCCESS = "GET_ADMIN_DETAILS_SUCCESS";
// export const GET_ADMIN_DETAILS_FAIL = "GET_ADMIN_DETAILS_FAIL";

// export const ADD_ADMIN_DETAILS_REQUEST = "ADD_ADMIN_DETAILS_REQUEST";
// export const ADD_ADMIN_DETAILS_SUCCESS = "ADD_ADMIN_DETAILS_SUCCESS";
// export const ADD_ADMIN_DETAILS_FAIL = "ADD_ADMIN_DETAILS_FAIL";
// export const CLEAN_CONFIRM = "CLEAN_CONFIRM";
// export const CLEAN_CONFIRM_ERROR = "CLEAN_CONFIRM_ERROR";

// export const UPDATE_ADMIN_DETAILS_REQUEST = "UPDATE_ADMIN_DETAILS_REQUEST";
// export const UPDATE_ADMIN_DETAILS_SUCCESS = "UPDATE_ADMIN_DETAILS_SUCCESS";
// export const UPDATE_ADMIN_DETAILS_FAIL = "UPDATE_ADMIN_DETAILS_FAIL";

//adminList
export const SET_PHOTO_URL_LOADING = "SET_PHOTO_URL_LOADING";
export const SET_PHOTO_URL_SUCCESS = "SET_PHOTO_URL_SUCCESS";
export const PHOTO_URL_FAIL = "PHOTO_URL_FAIL";
export const SET_ADD_ADMIN_LOADING = "SET_ADD_ADMIN_LOADING";
export const ADD_ADMIN_IN_ADMINLIST = "ADD_ADMIN_IN_ADMINLIST";
export const ADMIN_TOAST_MESSAGE = "ADMIN_TOAST_MESSAGE";
export const SET_ADMIN_LIST = "SET_ADMIN_LIST";
export const SET_ADMINLIST_LOADING = "SET_ADMINLIST_LOADING";
export const SET_ADMIN_PAGE_STATUS = "SET_ADMIN_PAGE_STATUS";

// adminDetail
export const SET_SELECTED_ADMIN_DETAIL = "SET_SELECTED_ADMIN_DETAIL";
export const SET_ADMIN_DETAIL_PAGE_STATUS = "SET_ADMIN_DETAIL_PAGE_STATUS";
export const SET_ADMIN_DETAIL_SCHOOL_DISTRICT_OPTIONS =
  "SET_ADMIN_DETAIL_SCHOOL_DISTRICT_OPTIONS";
export const SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID =
  "SET_ADMIN_DETAIL_SELECTED_HIGHSCHOOL_ID";
export const SET_ADMIN_DETAIL_HIGHSCHOOL_LIST =
  "SET_ADMIN_DETAIL_HIGHSCHOOL_LIST";
export const ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL =
  "ADD_ADMIN_DETAIL_NEW_HIGHSCHOOL";
export const DELETE_ADMIN_DETAIL_HIGHSCHOOL = "DELETE_ADMIN_DETAIL_HIGHSCHOOL";
export const CHANGE_HIGHSCHOOL_ACCESS_PERMISSION =
  "CHANGE_HIGHSCHOOL_ACCESS_PERMISSION";
export const SET_ADMIN_DETAIL_SPECIAL_PROJECTS_OPTIONS =
  "SET_ADMIN_DETAIL_SPECIAL_PROJECTS_OPTIONS";
export const SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID =
  "SET_ADMIN_DETAIL_SELECTED_SPECIAL_PROJECT_ID";
export const DELETE_ADMIN_DETAIL_SPECIAL_PROJECT =
  "DELETE_ADMIN_DETAIL_SPECIAL_PROJECT";
export const CHANGE_SPECIAL_PROJECT_ACCESS_PERMISSION =
  "CHANGE_SPECIAL_PROJECT_ACCESS_PERMISSION";
export const SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST =
  "SET_ADMIN_DETAIL_SPECIAL_PROJECT_LIST";
export const ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT =
  "ADD_ADMIN_DETAIL_NEW_SPECIAL_PROJECT";
export const CHANGE_UNIVERSITY_ACCESS_PERMISSION =
  "CHANGE_UNIVERSITY_ACCESS_PERMISSION";
export const SET_ADMIN_DETAIL_UNIVERSITY_OPTIONS =
  "SET_ADMIN_DETAIL_UNIVERSITY_OPTIONS";
export const SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID =
  "SET_ADMIN_DETAIL_SELECTED_UNIVERSITY_ID";
export const SET_ADMIN_DETAIL_UNIVERSITY_LIST =
  "SET_ADMIN_DETAIL_UNIVERSITY_LIST";
export const ADD_ADMIN_DETAIL_NEW_UNIVERSITY =
  "ADD_ADMIN_DETAIL_NEW_UNIVERSITY";
export const DELETE_ADMIN_DETAIL_UNIVERSITY = "DELETE_ADMIN_DETAIL_UNIVERSITY";
export const SET_EDIT_PHOTO_URL_LOADING = "SET_EDIT_PHOTO_URL_LOADING";
export const SET_EDIT_PHOTO_URL_SUCCESS = "SET_EDIT_PHOTO_URL_SUCCESS";
export const PHOTO_EDIT_URL_FAIL = "PHOTO_EDIT_URL_FAIL";
