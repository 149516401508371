import {
    HIGHSCHOOL_LIST_FAIL,
    HIGHSCHOOL_LIST_REQUEST,
    HIGHSCHOOL_LIST_SUCCESS,
  } from "../constant/highschool";
  import {gradify} from '../axios.config';
export const getHighSchoolList = (schoolDistrictId) => async (dispatch,getState) =>{
    try {
        dispatch({
            type: HIGHSCHOOL_LIST_REQUEST
        })
        
        const {data} = await gradify.get(`schooldistricts/${schoolDistrictId}/highschools?limit=100&sort=name`,{headers:{"Authorization": "Bearer "+ getState().auth.token }});
        dispatch({
            type: HIGHSCHOOL_LIST_SUCCESS,
            payload:data
        })
    } catch (error) {
        
    }
}