import React from "react";
import {
  Column,
  Row,
  InnerCol,
  SubTitle,
  ConstantLabel,
  ConstantValue,
  ConstantWrapper,
  Square,
  InnerTag,
} from "assets/style/General.style";
const SummarySections = ({
  schoolItems,
  schoolDistrictItems,
  universityNames,
  specialProjectsNames,
  categoryNames,
  subCategoryNames,
  organizationType,
}) => {
  return (
    <Row alignment="space-between">
      {organizationType === "Secondary" && (
        <Column width="28.5%" minWidth="290px" Direction="column">
          <InnerCol style={{ paddingLeft: 15, marginTop: 15 }}>
            <SubTitle>School district</SubTitle>
          </InnerCol>
          <InnerCol>
            <ConstantWrapper>
              <ConstantLabel>School District</ConstantLabel>
              {schoolDistrictItems}
            </ConstantWrapper>
            <ConstantWrapper>
              <ConstantLabel>School</ConstantLabel>
              {schoolItems}
            </ConstantWrapper>
          </InnerCol>
        </Column>
      )}
      <Column width="14.2%" minWidth="160px" Direction="column">
        <InnerCol style={{ paddingLeft: 15, marginTop: 15 }}>
          <SubTitle>University</SubTitle>
        </InnerCol>
        <InnerCol>
          <ConstantWrapper>
            <ConstantLabel>University</ConstantLabel>
            {universityNames}
          </ConstantWrapper>
        </InnerCol>
      </Column>
      <Column width="43%" minWidth="460px" Direction="column">
        <InnerCol style={{ paddingLeft: 35, marginTop: 15 }}>
          <SubTitle>Special Projects</SubTitle>
        </InnerCol>
        <InnerCol style={{ paddingLeft: 35 }}>
          <ConstantWrapper>
            <ConstantLabel>PROJECT</ConstantLabel>
            <ConstantValue>{specialProjectsNames}</ConstantValue>
          </ConstantWrapper>
          <ConstantWrapper>
            <ConstantLabel>Category</ConstantLabel>
            <ConstantValue>{categoryNames}</ConstantValue>
          </ConstantWrapper>
          <ConstantWrapper>
            <ConstantLabel>Sub category</ConstantLabel>
            <ConstantValue>{subCategoryNames}</ConstantValue>
          </ConstantWrapper>
        </InnerCol>
      </Column>
      <Column width="100%" minWidth="160px" Direction="column" style={{maxWidth:350}}>
        <InnerCol style={{ paddingLeft: 15, marginTop: -55 }}>
          <SubTitle>Tags</SubTitle>
        </InnerCol>
        <InnerCol>
          <Square
            width="100%"
            height="150px"
            borderRadius="10px"
            borderColor="#CED4DA"
          >
            <InnerTag
              width="120px"
              height="29px"
              borderRadius="5px"
              textColor="#74788d"
              fontSize="12px"
              borderColor="#CED4DA"
              margin="10px"
            >
              All
            </InnerTag>
          </Square>
        </InnerCol>
      </Column>
    </Row>
  );
};

export default SummarySections;
