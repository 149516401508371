import React, { useEffect } from "react";
import StudentHeader from "components/students/StudentList/StudentHeader";
import {
  clearAppSelectedFilterIds,
  getAppCategoriesAction,
  getAppSubCategoriesAction,
  setAppSelectedSubCategoryId,
  setAppSelectedCategoryId,
} from "actions";
import {
  allAppCategoriesSelector,
  allAppSubCategoriesSelector,
  selectedAppProjectIdSelector,
  selectedAppCategoryIdSelector,
  selectedAppSubCategoryIdSelector,
} from "store/selectors";
import { useDispatch, useSelector } from "react-redux";
const StudentHeaderContainer = ({
  onAddStudentClick,
  searchStudentInput,
  onSearchStudentChanged,
  onAwardPointsClicked,
  onCategoryChange,
  onSubCategoryChange,
  onStatusChange,
}) => {
  const dispatch = useDispatch();
  //selectors
  const categories = useSelector(allAppCategoriesSelector);
  const subCategories = useSelector(allAppSubCategoriesSelector);
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);
  const selectedCategoryId = useSelector(selectedAppCategoryIdSelector);
  const selectedSubCategoryId = useSelector(selectedAppSubCategoryIdSelector);

  const statusOptions = [
    { _id: "1", name: "All", value: "All" },
    { _id: "2", name: "Active", value: "Active" },
    { _id: "3", name: "inActive", value: "inActive" },
  ];
  useEffect(() => {
    dispatch(getAppCategoriesAction(selectedProjectId));
    return () => {
      clearAppSelectedFilterIds(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StudentHeader
      categoryOptions={categories}
      selectedCategoryId={selectedCategoryId}
      onCategoryChanged={(e) => {
        setAppSelectedCategoryId(dispatch, e.target.value);
        dispatch(getAppSubCategoriesAction(e.target.value));
        onCategoryChange(e.target.value);
      }}
      subCategoryOptions={subCategories}
      selectedSubCategoryId={selectedSubCategoryId}
      onSubCategoryChanged={(e) => {
        setAppSelectedSubCategoryId(dispatch, e.target.value);
        onSubCategoryChange(e.target.value);
      }}
      searchStudentInput={searchStudentInput}
      onSearchStudentList={onSearchStudentChanged}
      onAddStudentClick={onAddStudentClick}
      onAwardPointsClicked={onAwardPointsClicked}
      statusOptions={statusOptions}
      onStatusChanged={onStatusChange}
      selectedStatusId={() => {}}
    />
  );
};

export default StudentHeaderContainer;
