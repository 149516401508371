import { gradify } from "../axios.config";
import {
  EXPERIENCE_LIST_FAIL,
  EXPERIENCE_LIST_SUCCESS,
  EXPERIENCE_LIST_REQUEST,
  EXPERIENCE_ITEM_REQUEST,
  EXPERIENCE_ITEM_FAIL,
  EXPERIENCE_ITEM_SUCCESS,
  EXPERIENCE_UPDATE_REQUEST,
  EXPERIENCE_UPDATE_SUCCESS,
  EXPERIENCE_UPDATE_FAIL,
  EXPERIENCE_USER_COMPLETE_LIST_FAIL,
  EXPERIENCE_USER_COMPLETE_LIST_SUCCESS,
  EXPERIENCE_USER_COMPLETE_LIST_REQUEST,
  EXPERIENCE_USER_PASSDUE_LIST_FAIL,
  EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
  EXPERIENCE_USER_PASSDUE_LIST_REQUEST,
  EXPERIENCE_USER_LIST_FAIL,
  EXPERIENCE_USER_LIST_SUCCESS,
  EXPERIENCE_USER_LIST_REQUEST,
  EXPERIENCE_SET_SORT_TYPE,
  EXPERIENCE_SET_SEARCH_INPUT,
  EXPERIENCE_SET_TYPE_FILTER,
} from "../constant/experience";
import store from "Store";

export const getExperienceListAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_LIST_REQUEST,
    });
    const { data } = await gradify.get("experiencecards", {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });
    dispatch({
      type: EXPERIENCE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getExperienceDetailsItemAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_ITEM_REQUEST,
      });

      const {
        data: { data },
      } = await gradify.get(`experiencecards/${id}`, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: EXPERIENCE_ITEM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_ITEM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addNewExperience = (experience) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_LIST_REQUEST,
    });
    const experiences = await gradify.post("experiencecards", experience, {
      headers: { Authorization: "Bearer " + getState().auth.token },
    });

    dispatch(getExperienceListAction());
  } catch (error) {
    dispatch({
      type: EXPERIENCE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const setExperienceSortType = (dispatch, sort) => {
  dispatch({
    type: EXPERIENCE_SET_SORT_TYPE,
    payload: sort,
  });
};
export const setExperienceSearchInput = (dispatch, searchInput) => {
  dispatch({
    type: EXPERIENCE_SET_SEARCH_INPUT,
    payload: searchInput,
  });
};
export const setExperienceTypeFilter = (dispatch, type) => {
  dispatch({
    type: EXPERIENCE_SET_TYPE_FILTER,
    payload: type,
  });
};
export const getAllExperienceList =
  (
    page,
    limit,
    sortType,
    searchInput,
    filterType,
    selectedCategoryId,
    selectedSubCategoryId
  ) =>
  async (dispatch, getState) => {
    console.log("categoryId", selectedCategoryId);
    const selectedProjectId = store.getState().app.selectedProjectId;
    const filter = `${
      filterType === "publish"
        ? filterType === "saved"
          ? "&expSave=true"
          : "&publish=true"
        : "&expSave=true"
    }`;

    let query;
    if (searchInput) {
      const filterCat = `${
        selectedCategoryId ? `&categoryId=${selectedCategoryId}` : ""
      }`;
      const filterSubCat = `${
        selectedSubCategoryId ? `&subCategoryId=${selectedSubCategoryId}` : ""
      }`;

      const sort = `sort={"field":"${sortType.type}","order":"${
        sortType?.isReversed ? "desc" : "asc"
      }"}`;

      console.log("============= sort ===================");
      query = `experiencecards/search?term=${searchInput}&${filter}&projectId=${selectedProjectId}&page=${page}&limit=${limit}&${sort}${filterCat}${filterSubCat}`;
    } else {
      const filterCat = `${
        selectedCategoryId || selectedCategoryId !== "All"
          ? selectedCategoryId == "project level"
            ? `&categoryId=null`
            : `&categoryId=${selectedCategoryId}`
          : ""
      }`;
      const filterSubCat = `${
        selectedSubCategoryId ? `&subCategory=${selectedSubCategoryId}` : ""
      }`;
      const sort = `sort={"field":"${sortType.type}","order":"${
        sortType?.isReversed ? "desc" : "asc"
      }"}`;
      console.log("============= sort ===================", sort);
      query = `experiencecards/experiencelistnew?projectId=${selectedProjectId}&page=${page}&limit=${limit}&${sort}${filterCat}${filterSubCat}${filter}`;
      //${filterSubCat}`;
      console.log(query);
    }
    try {
      dispatch({
        type: EXPERIENCE_LIST_REQUEST,
      });
      const { data } = await gradify.get(query, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });
      dispatch({
        type: EXPERIENCE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateExperience =
  (id, updateItem) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_UPDATE_REQUEST,
      });
      const { data } = await gradify.put(`experiencecards/${id}`, updateItem, {
        headers: { Authorization: "Bearer " + getState().auth.token },
      });

      dispatch({
        type: EXPERIENCE_UPDATE_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserExperienceList =
  (organization, project, category = null, subcategory = null, user) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_USER_LIST_REQUEST,
      });
      if (!project) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&user=${user}&sort=endDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_LIST_SUCCESS,
          payload: data,
        });
      } else if (!category) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&user=${user}&sort=endDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_LIST_SUCCESS,
          payload: data,
        });
      } else if (!subcategory) {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&user=${user}&sort=endDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_LIST_SUCCESS,
          payload: data,
        });
      } else {
        const { data } = await gradify.get(
          `experiencecards/userexperience?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&subCategory=${subcategory?._id}&user=${user}&sort=endDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_LIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: EXPERIENCE_USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getUserExperiencePassDueListAction =
  (organization, project, category, subCategory, user) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_USER_PASSDUE_LIST_REQUEST,
      });
      if (!project) {
        const { data } = await gradify.get(
          `experiencecards/userexperiencepassdue?organization=${organization}&user=${user}&sort=startDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
          payload: data,
        });
      } else if (!category) {
        const { data } = await gradify.get(
          `experiencecards/userexperiencepassdue?organization=${organization}&project=${project}&user=${user}&sort=startDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
          payload: data,
        });
      } else if (!subCategory) {
        const { data } = await gradify.get(
          `experiencecards/userexperiencepassdue?organization=${organization}&project=${project}&category=${category}&user=${user}&sort=startDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
          payload: data,
        });
      } else {
        const { data } = await gradify.get(
          `experiencecards/userexperiencepassdue?organization=${organization}&project=${project}&category=${category}&subcategory=${subCategory}&user=${user}&sort=startDate`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
      dispatch({
        type: EXPERIENCE_USER_PASSDUE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
// export const getUserExperiencePassDueListAction =
//   (organization, project, category, subcategory, user) =>
//   async (dispatch, getState) => {
//     try {
//       dispatch({
//         type: EXPERIENCE_USER_PASSDUE_LIST_REQUEST,
//       });
//       if (!subcategory) {
//         const { data } = await gradify.get(
//           `experiencecards/userexperiencepassdue?organization=${organization?._id}&pj=${project?._id}&category=${category?._id}&subCategory=${subcategory?._id}&user=${user}`,
//           { headers: { Authorization: "Bearer " + getState().auth.token } }
//         );

//         dispatch({
//           type: EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
//           payload: data,
//         });
//       } else {
//         const { data } = await gradify.get(
//           `experiencecards/userexperiencepassdue?organization=${organization?._id}&pj=${project?._id}&category=${category?._id}&subCategory=${subcategory?._id}&user=${user}`,
//           { headers: { Authorization: "Bearer " + getState().auth.token } }
//         );

//         dispatch({
//           type: EXPERIENCE_USER_PASSDUE_LIST_SUCCESS,
//           payload: data,
//         });
//       }
//     } catch (error) {
//       dispatch({
//         type: EXPERIENCE_USER_PASSDUE_LIST_FAIL,
//         payload:
//           error.response && error.response.data.message
//             ? error.response.data.message
//             : error.message,
//       });
//     }
//   };

export const getUserExperienceCompleteListAction =
  (organization, project, category, subCategory, user) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_USER_COMPLETE_LIST_REQUEST,
      });
      if (!project) {
        const { data } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&user=${user}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_COMPLETE_LIST_SUCCESS,
          payload: data,
        });
      } else if (!category) {
        const { data } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&user=${user}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_COMPLETE_LIST_SUCCESS,
          payload: data,
        });
      } else if (!subCategory) {
        const { data } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&user=${user}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_COMPLETE_LIST_SUCCESS,
          payload: data,
        });
      } else {
        const { data } = await gradify.get(
          `experiencecards/userexperiencecomplete?organization=${organization?._id}&project=${project?._id}&category=${category?._id}&subCategory=${subCategory?._id}&user=${user}`,
          { headers: { Authorization: "Bearer " + getState().auth.token } }
        );

        dispatch({
          type: EXPERIENCE_USER_COMPLETE_LIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: EXPERIENCE_USER_COMPLETE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const clearExperienceFilter = (dispatch) => {
  dispatch({
    type: EXPERIENCE_SET_SEARCH_INPUT,
    payload: "",
  });
  dispatch({
    type: EXPERIENCE_SET_SORT_TYPE,
    payload: { type: "titleSort", isReversed: false },
  });
  dispatch({
    type: EXPERIENCE_SET_TYPE_FILTER,
    payload: "publish",
  });
};
