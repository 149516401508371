import React, { useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import "../shop.style.css";
import { withStyles } from "@material-ui/core/styles";
import ShopItem from "./ShopItem";
import Unselected from "assets/image/unselected.png";
import BottomSelected from "assets/image/bottomSelected.png";
import TopSelected from "assets/image/topSelected.png";
const ShopList = ({
  onSortShopList,
  sortType,
  shopList,
  onEditButtonClick,
  haveAccess,
}) => {
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  const renderShopItems = useMemo(
    () =>
      shopList &&
      shopList.map((shopItem) => {
        const {
          _id,
          title,
          description,
          image,
          current_stock,
          store,
          prize_wheel,
          gem,
          unlock_level,
        } = shopItem;
        return (
          <ShopItem
            key={_id}
            _id={_id}
            prize={title}
            description={description}
            imageUrl={image}
            currentStock={current_stock}
            shop={store}
            prizeWheel={prize_wheel}
            gemNumber={gem}
            levelNumber={unlock_level}
            haveAccess={haveAccess}
            onEditButtonClick={() => onEditButtonClick(_id)}
          />
        );
      }),
    [haveAccess, onEditButtonClick, shopList]
  );
  return (
    <>
      <Table
        className="student-table-container shop-table-container"
        style={{ width: "100%" }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 14 }}></TableCell>
            <TableCell
              onClick={() => {
                onSortShopList("titleSort");
              }}
            >
              Prize
              {sortType?.type === "titleSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortShopList("desSort");
              }}
            >
              Description
              {sortType?.type === "desSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
              </TableCell>
            <TableCell
              onClick={() => {
                onSortShopList("current_stock");
              }}
            >
              Current Stack
              {sortType?.type === "current_stock" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortShopList("store");
              }}
            >
                Shop
              {sortType?.type === "store" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortShopList("prize_wheel");
              }}
            >
                Prize Wheel
              {sortType?.type === "prize_wheel" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell
                onClick={() => {
                  onSortShopList("gem");
                }}
              >
                  Gem
                {sortType?.type === "gem" ? (
                  sortType?.isReversed ? (
                    <img src={BottomSelected} alt="" className="sortIcon" />
                  ) : (
                    <img src={TopSelected} alt="" className="sortIcon" />
                  )
                ) : (
                  <img src={Unselected} alt="" className="sortIcon" />
                )}
            </TableCell>
            <TableCell
              onClick={() => {
                onSortShopList("unlock_level");
              }}
            >
                Level
              {sortType?.type === "unlock_level" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderShopItems}</TableBody>
      </Table>
    </>
  );
};

export default ShopList;
