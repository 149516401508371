import React, { memo } from "react";
import { useSelector } from "react-redux";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import "../adminTable.style.css";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import organizationImage from "assets/image/defualt-icon.png";
import { hasAdminAccess } from "helpers";
const AdminItem = ({
  adminId,
  adminPhoto,
  adminFirstName,
  adminLastName,
  adminRole,
  organizationName,
  projectName,
  categoryName,
  subCategoryName,
}) => {
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  const loggedInUser = useSelector((state) => state.loggedInUser.user);
  return (
    <TableRow>
      <TableCell style={{ borderBottom: 0 }}>
        <CustomColorCheckbox
          color="#CED4DA"
          size="small"
          checked={""}
          onChange={() => {}}
          value={""}
        />
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <img
          src={adminPhoto || organizationImage}
          alt={adminFirstName}
          width="50"
          height="50"
          style={{ borderRadius: 50 }}
        />
      </TableCell>
      <TableCell className="admin-name-table">
        <Link
          style={{
            color: "#495057",
            fontSize: 15,
            textDecoration: "none",
            pointerEvents: `${
              !hasAdminAccess(loggedInUser?.role, adminRole) && "none"
            }`,
          }}
          to={`/admin/adminDetail/${adminId}`}
        >
          {`${adminFirstName || ""}  ${adminLastName || ""}`}
        </Link>
      </TableCell>

      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {adminRole || "Admin"}
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {organizationName || "..."}
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {projectName || "..."}
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {categoryName || "..."}
      </TableCell>
      <TableCell style={{ color: "##797E83", borderBottom: 0 }}>
        {" "}
        {subCategoryName || "..."}
      </TableCell>
    </TableRow>
  );
};
AdminItem.defaultProps = {
  adminPhoto: organizationImage,
};
export default memo(withRouter(AdminItem));
