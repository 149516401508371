import { SET_APP_SELECTED_ORGANIZATION_ID } from "constant";
export const setAppSelectedOrganizationId = (dispatch, organizationId) => {
  dispatch({
    type: SET_APP_SELECTED_ORGANIZATION_ID,
    payload:
      organizationId === "Select Organization" || !organizationId
        ? undefined
        : organizationId,
  });
};
