import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../control.component/select.component";
import Select from "react-select";
import AdvanceSelect from "../../components/control.component/advenceSelect";

import {
  CreateOrganizationFormCol,
  CreateOrganizationContainer,
  CreateOrganizationForm,
  CreateOrganizationFormRow,
  OrganizationInput,
  LabelOrganization,
  SwitchLabelOrganization,
  OrganizationImage,
  OrganizationEditButtton,
  OrganizationEditButttonText,
  OrganizationEditButttonIcon,
  OrganizationButon,
} from "./AddOrganization.style";
import WarningPopup from "../warningPopup/WarningPopup";
import { MdEdit } from "react-icons/md";
import {
  organizationType,
  ufcsMember,
  paid,
  rewardSystem,
  surveySystem,
} from "../../constants";

import organizationImage from "../../assets/image/defualt-icon.png";
import {leadContactOrganization} from '../../actions/leadContactOrganizationAction';
import { addOrganizationAction,resetOrganizationAdd } from '../../actions/organizationActions'
import axios from "axios";
import { gradify } from "../../axios.config";

const AddOrganizationComponent = ({ close }) => {

  const fileRef = useRef(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [leadContactState,setLeadContactState] = useState([]);
  const dispatch = useDispatch();
  const {token} = useSelector(state => state.auth);
  const { schoolDistricts } = useSelector((state) => state.schoolDistrictList);
  const {universities} = useSelector(state => state.universityList);
  const {leadContacts} = useSelector(state => state.leadContactOrganizationList);
  const {organizations,loading:organizationLoading,organizationAdd} = useSelector(state => state.organization)
  const [showFormWarning, setShowFormWarning] = useState(false);
  
  const [organizationInfo, setOrganizationInfo] = useState({
    title: "",
    short_form_name: "",
    organization_type: "Secondary",
    schoolDistrict: "",
    university: "",
    image: organizationImage,
    ufcs_member:  true ,
    paid: "Yes",
    lead_contact: null,
    reward_system: false,
    survey_system: false,
    schoolDistrictID:schoolDistricts[0]?._id,
    universityID:null
  });
  useEffect(() => {
    dispatch(leadContactOrganization());
  },[dispatch]);

  useEffect(() => {
    if(leadContacts.length > 0){
      const result = leadContacts.map(item => {
        return {
          _id:item?._id,
          name:item?.firstName + " " +item?.lastName 
        }
      });

     setOrganizationInfo({...organizationInfo,lead_contact:result[0]?._id})
      setLeadContactState(result);
    }
  },[leadContacts]);

  useEffect(() => {
     if(organizationAdd){
       close();
       dispatch(resetOrganizationAdd());

     }
  },[organizationAdd])

  const closeWarningForm = () => {
    setShowFormWarning(false);
    close();
  };
  const closeWarning = () => {
    setShowFormWarning(false);
  };

  const closeForm = (e) => {
    setShowFormWarning(true);

  };


  const changeHandler = (e) => {
  

    setOrganizationInfo({
      ...organizationInfo,
      [e.target.name]: e.target.value,
    });
  };

  const closeEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const loadOptionHandler = async (inputText,callback) => {
    const res = await gradify.get(`schooldistricts/search?term=${inputText}`);
   
    callback(res.data.map(item => {
      return {
        label:item.agancy_name,
        value:item._id
      }
    }))
  }

  const loadOptionUniversityHandler = async (inputText,callback) => {
    const res = await gradify.get(`universities/search?term=${inputText}`);
   
    callback(res.data.map(item => {
      return {
        label:item.instnm,
        value:item._id
      }
    }))
  }

  return (
    <CreateOrganizationContainer
      className="form-Organization-add"
    >
      <CreateOrganizationForm>
        <CreateOrganizationFormRow style={{ paddingLeft: 40 }}>
          <CreateOrganizationFormCol
            alignment="flex-start"
            style={{ paddingLeft: 0, position: "relative" }}
          >
            <OrganizationImage src={organizationInfo.image} alt="gradegy" />
            <input type='file' style={{display:"none"}} ref={fileRef} onChange={async (e) => {
              const formData = new FormData();
              formData.append('file',e.target.files[0]);
             const res = await axios.post(`https://apigrad.azurewebsites.net/api/v2/photos`,formData,{headers:{"Authorization": "Bearer "+ token }})
            setOrganizationInfo({...organizationInfo,image:`https://apigrad.azurewebsites.net${res.data.url}`})
            }} />
            <OrganizationEditButtton onClick={() => {
              fileRef.current.click();
             
            }}>
              
              <OrganizationEditButttonText>Edit</OrganizationEditButttonText>
              <OrganizationEditButttonIcon>
                <MdEdit color="#34C38F" size={12} />
              </OrganizationEditButttonIcon>
            </OrganizationEditButtton>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol
            Direction="column"
            alignment="sttart"
            style={{ alignItems: "center" }}
          >
            <OrganizationInput
              Width="485px"
              Height="39px"
              FontFamily="Rajdhani"
              Size="32px"
              Weight="700"
              name="title"
              placeholder="Name of Organization here"
              value={organizationInfo?.title}
              onChange={changeHandler}
            />
            <OrganizationInput
              Width="120px"
              Height="33px"
              FontFamily="Rajdhani"
              Size="13px"
              Weight="400"
              name="short_form_name"
              placeholder="Short Form Name"
              value={organizationInfo?.short_form_name}
              onChange={changeHandler}
              style={{ marginTop: 5 }}
            />
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>

        <CreateOrganizationFormRow
          style={{ paddingRight: 20, paddingLeft: 20, marginTop: 16 }}
        >
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Organization Type</LabelOrganization>
            <SelectComponent
              options={organizationType}
              name="organization_type"
              selectedValue="Secondary"
              onChange={changeHandler}
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 6,
                minWidth: 129,
                fontSize: 10,
                backgroundPosition: "bottom 10px right 6px",
              }}
            />
          </CreateOrganizationFormCol>
          {organizationInfo.organization_type == "Secondary" ? (
            <CreateOrganizationFormCol Direction="column">
              <LabelOrganization>School District</LabelOrganization>
              <AdvanceSelect
              
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="schoolDistrict"
                loadOptions={loadOptionHandler}
                onChange={e => setOrganizationInfo({...organizationInfo,university:"",schoolDistrict: e ? e.label : "",schoolDistrictID:e ? e.value : null,universityID:null})}
              />
            </CreateOrganizationFormCol>
          ) : null}

          {organizationInfo.organization_type == "Post Secondary" ? (
            <CreateOrganizationFormCol Direction="column">
              <LabelOrganization>University</LabelOrganization>
              <AdvanceSelect
              
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="university"
                loadOptions={loadOptionUniversityHandler}
                onChange={e => setOrganizationInfo({...organizationInfo,schoolDistrict:"",university: e ? e.label : "",universityID:e ? e.value : null , schoolDistrictID:null})}
              />
            </CreateOrganizationFormCol>
          ) : null}

          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>UFCS Member</LabelOrganization>
            <SelectComponent
              options={ufcsMember}
              name="ufcs_member"
              onChange={(e) => {setOrganizationInfo({...organizationInfo,ufcs_member:e.target.value === "Yes" ? true : false})}}
              selectedValue="Yes"
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 6,
                minWidth: 129,
                fontSize: 10,
                backgroundPosition: "bottom 10px right 6px",
              }}
            />
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Lead Contact</LabelOrganization>
            <SelectComponent
              options={leadContactState}
              name="lead_contact"
              selectedValue={leadContactState[0]?._id}
              onChange={(e) => {setOrganizationInfo({...organizationInfo,lead_contact:e.target.value})}}
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 6,
                minWidth: 129,
                fontSize: 10,
                backgroundPosition: "bottom 10px right 6px",
              }}
            />
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>

        <CreateOrganizationFormRow
          style={{ paddingRight: 20, paddingLeft: 20, marginTop: 20 }}
        >
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Paid</LabelOrganization>
            <SelectComponent
              options={paid}
              name="paid"
              onChange={(e) => setOrganizationInfo({...organizationInfo,paid:e.target.value})}
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 6,
                minWidth: 129,
                fontSize: 10,
                backgroundPosition: "bottom 10px right 6px",
              }}
            />
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Reward System</LabelOrganization>
            <SelectComponent
              options={rewardSystem}
              
              onChange={(e) => {setOrganizationInfo({...organizationInfo,reward_system: e.target.value === "Yes" ? true:false})}}
              selectedValue="No"
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 6,
                minWidth: 129,
                fontSize: 10,
                backgroundPosition: "bottom 10px right 6px",
              }}
            />
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column">
            <LabelOrganization>Survey System</LabelOrganization>
            <SelectComponent
              options={surveySystem}
              selectedValue="No"
              onChange={(e) => { setOrganizationInfo({...organizationInfo,survey_system:e.target.value === 'Yes' ? true :false})}}
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 6,
                minWidth: 129,
                fontSize: 10,
                backgroundPosition: "bottom 10px right 6px",
              }}
            />
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>

        <CreateOrganizationFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 48,
            marginBottom: 30,
            justifyContent: "center",
          }}
        >
          <CreateOrganizationFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <OrganizationButon onClick={() => {dispatch(addOrganizationAction(organizationInfo))}} disabled={organizationLoading}>
              {" "}
              {organizationLoading ? "Sending..." : "SAVE"}
            </OrganizationButon>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol style={{ paddingLeft: 30 }}>
            <OrganizationButon onClick={closeForm}>Cancel</OrganizationButon>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
      </CreateOrganizationForm>
      {showFormWarning ? (
              <WarningPopup
                close={closeWarningForm}
                text={'Clear All Changes'}
                reject={closeWarning}
                accept={()=>{}}
              />
            ) : null}
      {false && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "darkred",
            color: "white",
            padding: 20,
          }}
        >
          {"error"}
        </div>
      )}
    </CreateOrganizationContainer>
  );
};

export default AddOrganizationComponent;
