import styled from 'styled-components';

export const WarningPopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display:flex;
  justify-content:center;
  align-items:center;
  
`;

export const WarningPopupForm = styled.div`
    padding-bottom: 15px;
    margin: 0 auto;
    padding-top:15px;
    width: fit-content;
    height: fit-content;
    background:${({theme}) => theme.colors.white};
    border-radius:10px;
   box-shadow: 1px 2px 14px -1px rgba(0,0,0,0.82);
   min-width: 376px;
padding-left: 20px;
padding-right: 20px;



`;

export const WarningPopupFormRow = styled.div`
  width:100%;
  display:flex;
  padding-top:1px;
  justify-content:center;
  

`;
export const WarningPopupButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 10px 17px 10px 14px;
  box-shadow:0px 3px 6px rgba(0,0,0,0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  display: flex;
  align-items: center;
  border-radius: 23px;
  font-weight:300;
  width: 150px;
  height: 45px;
  justify-content: center;
  font-size: 19px;
  span{
    white-space: nowrap;
  }
`;

export const WarnigPoupText = styled.p `
font-family: 'Poppins';
font-size: 26px !important;
font-weight: 400;
line-height: 35px;
margin-bottom: 50px;
`