import styled, { css } from "styled-components";
import arrow from "../../assets/image/ArrowShape.png";
import lock from "assets/image/lock.png";
import AsyncSelect from "react-select/async";

export const ContentHeader = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  margin-top: 10px;
`;

export const ContentHeaderTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkgray};
  position: absolute;
  top: -55px;
  left: 55px;
  @media (max-width: 1300px) {
    flex-direction: row;
  }
  @media (max-width: 1150px) {
    display: flex;
  }
`;

export const ContentHeaderUniversity = styled.span`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.darkgray};
  padding-left: 40px;
  font-weight: 400;
`;

export const StudentContainer = styled.div`
  width: 90%;
  margin-left: 50px;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100vh - 140px);
  @media (max-width: 1300px) {
    margin-left: 20px;
    width: 95%;
  }
`;

export const StudentHeaderControle = styled.div`
  width: 95%;
  display: flex;
  height: 115px;
  margin-left: 50px;
  margin-bottom:-20px;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1300px) {
    margin-left: 20px;
  }
`;

export const SearchStudent = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.lightdark};
  padding-left: 35px;
  text-indent: 10px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 13px;

  &:focus {
    outline: none;
  }
  @media (max-width: 1300px) {
    margin-left: 10px;
  }
`;

export const SearchStudentContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
  margin-left: 0px;
`;

export const StudentButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 10px 8px 10px 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 300;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
  }
`;

export const UploadStudentButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 259px;
  padding: 8px 7px 8px 21px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 13px;
    width: 220px;
  } ;
`;

export const SelectGradifyStyle = styled.select`
  padding: 5px 15px;
  border: 1px solid #ced4da;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  border-radius: 5px;
  color: #495057;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-right: 10px;
  position: relative;
  background: ${({ isDisabled }) =>
    isDisabled
      ? `url(${lock}) no-repeat white`
      : `url(${arrow}) no-repeat white`};
  background-position: bottom
    ${({ isDisabled }) => (isDisabled ? "0px" : "13px")} right
    ${({ isDisabled }) => (isDisabled ? "-10px" : "6px")};
  font-weight: 300;
  width: 173px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    `}
  &:focus {
    border-color: #ced4da;
    box-shadow: none;
    outline: none;
  }
  option {
    font-family: ${({ theme }) => theme.fonts.Poppins};
    font-size: 13px;
    color: ${({ theme }) => theme.colors.lightdark};
  }

  @media (max-width: 1870px) {
    padding: 3px 20px;
    width: 123px;
    font-size: 12px;
  }

  @media (max-width: 1300px) {
    width: 120px;
    min-width:120px !important;
  }
`;

export const AdvanceSelectStyle = styled(AsyncSelect)`
  padding: 1px 15px 5px 1px;
  border: 1px solid #ced4da;
  height: 33px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  border-radius: 5px;
  color: #495057;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-right: 10px;
  background: url(${arrow}) no-repeat white;
  background-position: bottom 13px right 6px;
  font-weight: 300;
  width: 173px;
  text-overflow: ellipsis;
  & > div {
    border: none;
  }
  &:focus {
    border-color: #ced4da;
    box-shadow: none;
    outline: none;
  }

  @media (max-width: 1770px) {
    padding: 5px 43px;
  }
`;
export const StudentContentContainer = styled.div`
  max-width: 1340px;
  @media (max-width: 1770px) {
    
  }
`;
