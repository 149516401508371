import produce from "immer";
import {
  EXPREIENCE_REVIEW_PENDING,
  EXPREIENCE_REVIEW_SUCCESS,
  EXPREIENCE_REVIEW_FAIL,
  EXPERIENCE_REIVEW_COMPLETE_CHAGNE,
} from "../../constant/newExperienceReview";

const INITIAL_STATE = {
  loading: false,
  users: null,
  error: null,
};

export const newExperienceReviewReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EXPREIENCE_REVIEW_PENDING:
        draft.loading = true;
        break;

      case EXPREIENCE_REVIEW_SUCCESS:
        draft.loading = false;
        draft.users = action.payload;
        break;

      case EXPREIENCE_REVIEW_FAIL:
        draft.loading = false;
        draft.users = null;
        draft.error = action.payload;
        break;

      case EXPERIENCE_REIVEW_COMPLETE_CHAGNE:
        draft.users.data = draft.users.data.map((item) =>
          item._id === action.payload._id
            ? (item = { ...item, complete: action.payload.complete })
            : item
        );
    }
  });
