export const canViewShopRaffle = (user) => {
  if (user.role === "ultraAdmin" || user.role === "superAdmin") return true;
  if (user?.role === "admin") {
    return (
      user?.schoolDistrictPermissionEdit?.length ||
      user?.spicalProjectPermissionEdit?.length ||
      user?.universityPermissionEdit?.length
    );
  }
  return true;
};
export const haveAccessMenuItem = (userRole, accessRole) => {
  return userRole === accessRole;
};
export const canEditAccessCheckShopRaffle = (user) => {
  if (user.role === "ultraAdmin") return true;
  if (user?.role === "admin" || user.role === "superAdmin") {
    return (
      user?.schoolDistrictPermissionEdit[0]?.store ||
      user?.spicalProjectPermissionEdit[0]?.store ||
      user?.universityPermissionEdit[0]?.store ||
      false
    );
  }
  return true;
};
export const formatShopList = (data) =>
  data.map((shopItem) => {
    return {
      _id: shopItem._id,
      prize: shopItem?.title,
      description: shopItem?.description,
      imageUrl: shopItem?.image,
      currentStock: shopItem?.current_stock,
      shop: shopItem?.store || false,
      prizeWheel: shopItem?.prize_wheel || false,
      gemNumber: shopItem?.gem,
      levelNumber: shopItem?.unlock_level,
    };
  });
const filterShopSystemById = (shopList, filterItem, selectedId) => {
  const filtered = [];
  shopList.map((shop) => {
    if (
      shop[filterItem]?._id === selectedId ||
      shop[filterItem] === selectedId
    ) {
      filtered.push(shop);
      return filtered;
    }
    return filtered;
  });
  return filtered;
};
export const filterShopList = (
  shopList,
  organizationId,
  projectId,
  categoryId,
  subCategoryId
) => {
  const x = organizationId
    ? filterShopSystemById(shopList, "organization", organizationId)
    : shopList;
  const y =
    organizationId && projectId
      ? filterShopSystemById(x, "project", projectId)
      : x;
  const z =
    organizationId && projectId && categoryId
      ? filterShopSystemById(y, "category", categoryId)
      : y;
  const w =
    organizationId && projectId && categoryId && subCategoryId
      ? filterShopSystemById(z, "subCategory", subCategoryId)
      : z;
  return w;
};
export const sortShopListByTitle = (shopData, filterSearch) => {
  let result = shopData;
  if (shopData) {
    if (filterSearch) {
      result = shopData.filter((item) =>
        item.prize.toLowerCase().match(filterSearch.toLowerCase())
      );
      return result;
    }
    return result;
  }
  return result;
};
export const filterShopStudentsById = (studentList, selectedId) => {
  let filtered = {};
  Object.values(studentList).map((student) => {
    switch (selectedId) {
      case "All":
        filtered = studentList;
        break;
      case "Not picked up":
        if (!student.isPickedup) {
          filtered[student._id] = student;
        }
        break;
      case "Picked up":
        if (student.isPickedup) {
          filtered[student._id] = student;
        }
        break;
      default:
        filtered = studentList;
    }
    return filtered;
  });
  return filtered;
};
const calculatePickedUpNumber = (data) => {
  const pickedUpStudent = data.filter((item) => item.pickup);

  return pickedUpStudent?.length || 0;
};
export const formatItemStudents = (students) => {
  const content = {};
  students.map((student) => {
    content[student._id] = {
      _id: student._id,
      name: `${student.student.firstName} ${student.student.lastName}`,
      purchasedDate: new Date(student.createdAt).toISOString().split("T")[0],
      // purchasedDate: `${new Date(student.createdAt).getDate()}/${new Date(
      //   student.createdAt
      // ).getMonth()}/${new Date(student.createdAt).getFullYear()}`,
      isPickedup: student.pickup,
      stockId: student.stock_id,
      pickupDate: student.pickup
        ? `${new Date(student.updatedAt).getDate()}-${new Date(
            student.updatedAt
          ).getMonth()}-${new Date(student.updatedAt).getFullYear()}`
        : "",
    };
    return content;
  });
  return content;
};
export const searchStudentsByName = (studentData, searchInp) => {
  let result = {};
  if (studentData) {
    if (searchInp) {
      Object.values(studentData).map((item) => {
        if (item.name.toLowerCase().match(searchInp.toLowerCase())) {
          result[item._id] = item;
        }
        return result;
      });
      return result;
    } else {
      result = studentData;
      return result;
    }
  }
  return result;
};
export const formatShopItemDetail = (data) => {
  return {
    _id: data._id,
    title: data?.title,
    description: data?.description,
    image: data?.image,
    gemsNumber: data?.gem,
    unlockLevel: data?.unlock_level,
    leftItemNumber: 0,
    costDollar: data?.cost_per_item,
    isStore: data?.store || false,
    isPrizeWheel: data?.prize_wheel || false,
    initialStock: data?.inital_stock || 0,
    currentStock: data?.current_stock || 0,
    soldNum: data?.students?.length || 0,
    pickedUpNum: data?.students?.length
      ? calculatePickedUpNumber(data?.students)
      : 0,
    purchaseLimitState: data?.purchase_limit_state,
    purchaseLimitCount: data?.purchase_limit,
    category: data?.category ? data?.category : "All",
  };
};
