import React from "react";
import {
  ContentHeader,
  ContentHeaderTitle,
} from "components/styled.components";
import {
  CreateShopFormCol,
  CreateShopFormRow,
  LabelShop,
  ShopHeaderControle,
  SearchShop,
  ShopButton,
  PrizeShopButton,
  SearchShopContainer,
} from "components/styled.components/shop.style";
import SelectComponent from "components/control.component/select.component";
import { MdAddCircle } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";
const ShopHeader = ({
  categoryOptions,
  selectedCategoryId,
  onCategoryChanged,
  subCategoryOptions,
  selectedSubCategoryId,
  onSubCategoryChanged,
  haveAccess,
  searchShopInput,
  onSearchInputChanged,
  onAddShopItemClick,
}) => {
  return (
    <>
      <ContentHeader>
        <ContentHeaderTitle>SHOP ITEM LIST </ContentHeaderTitle>
      </ContentHeader>
      <CreateShopFormRow>
        <CreateShopFormCol>
          <CreateShopFormCol Direction="column">
            <LabelShop>Category</LabelShop>
            <SelectComponent
              options={categoryOptions}
              selectedValue={selectedCategoryId}
              noShowSlectText
              name="All"
              selected="Family"
              onChange={onCategoryChanged}
              style={{ minWidth: 180 }}
            />
          </CreateShopFormCol>
          <CreateShopFormCol Direction="column">
            <LabelShop>Sub Category</LabelShop>
            <SelectComponent
              options={subCategoryOptions}
              noShowSlectText
              name="All"
              selectedValue={selectedSubCategoryId}
              selected={true}
              onChange={onSubCategoryChanged}
              style={{ minWidth: 180 }}
            />
          </CreateShopFormCol>
          <CreateShopFormCol
            style={{
              borderLeftWidth: 2,
              borderColor: "#959393",
              borderLeftStyle: "solid",
              paddingLeft: 10,
            }}
            Direction="column"
          >
            <LabelShop>Collaborative Projec</LabelShop>
            <SelectComponent
              options={""}
              name="tag"
              onChange={() => {}}
              style={{ minWidth: 180 }}
            />
          </CreateShopFormCol>
        </CreateShopFormCol>
      </CreateShopFormRow>
      <ShopHeaderControle style={{ marginTop: 10 }}>
        <SearchShopContainer>
          <BiSearchAlt
            spaceLeft="18px"
            style={{ position: "absolute", top: 11, left: 18 }}
            color={"#707070"}
          />
          <SearchShop
            type="text"
            value={searchShopInput}
            placeholder="Search Item"
            onChange={(e) => onSearchInputChanged(e)}
          />
        </SearchShopContainer>

        {haveAccess && (
          <ShopButton
            style={{
              paddingRight: 4,
              paddingTop: 6,
              paddingBottom: 6,
              fontSize: 16,
            }}
            spaceLeft="50px"
            onClick={onAddShopItemClick}
          >
            <MdAddCircle size={32} />
            <span style={{ paddingRight: 18 }}>Item</span>
          </ShopButton>
        )}
        <PrizeShopButton spaceLeft="30px">
          <MdAddCircle size={32} />
          <span style={{ paddingRight: 25, whiteSpace: "nowrap" }}>
            Add Prize Wheel
          </span>
        </PrizeShopButton>
      </ShopHeaderControle>
    </>
  );
};

export default ShopHeader;
