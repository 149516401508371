import {
  SET_RAFFLE_TICKET_PAGE_STATUS,
  ADD_RAFFLE_IN_RAFFLELIST,
  SET_RAFFLE_TICKET_LIST,
  RAFFLE_TICKET_TOAST_MESSAGE,
} from "constant";
import store from "Store";
import { gradify } from "axios.config";

export const addRaffleItemAction = (raffleItemInfo) => async (dispatch) => {
  console.log(raffleItemInfo)
  try {
    const token = store.getState().auth.token;
    const selectedOrganizationId = store.getState().app.selectedOrganizationId;
    const selectedProjectId = store.getState().app.selectedProjectId;

    const { organization, project } = raffleItemInfo;
    if (organization && project) {
      dispatch({
        type: SET_RAFFLE_TICKET_PAGE_STATUS,
        payload: "ADD_RAFFLE_TICKET_LOADING",
      });
      // api add raffle item
      const { data: addedRaffleItem } = await gradify.post(
        "raffletickets/",
        raffleItemInfo,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: ADD_RAFFLE_IN_RAFFLELIST,
        payload: addedRaffleItem,
      });

      dispatch({
        type: SET_RAFFLE_TICKET_PAGE_STATUS,
        payload: "RAFFLE_TICKET_MESSAGE_POPUP",
      });
      dispatch({
        type: RAFFLE_TICKET_TOAST_MESSAGE,
        payload: {
          message: "RAFFLE TICKET ADDED SUCCESSFULY",
          hasError: false,
        },
      });
      setTimeout(() => {
        dispatch({
          type: SET_RAFFLE_TICKET_PAGE_STATUS,
          payload: "DEFAULT",
        });
        dispatch({
          type: RAFFLE_TICKET_TOAST_MESSAGE,
          payload: { message: "", hasError: false },
        });
      }, 2000);

      //api All raffle
      const { data } = await gradify.get(
        `raffletickets?limit=1000${
          selectedOrganizationId
            ? `&organization=${selectedOrganizationId}`
            : ""
        }${selectedProjectId ? `&project=${selectedProjectId}` : ""}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: SET_RAFFLE_TICKET_LIST,
        payload: data,
      });
    } else {
      dispatch({
        type: SET_RAFFLE_TICKET_PAGE_STATUS,
        payload: "RAFFLE_TICKET_MESSAGE_POPUP",
      });
      dispatch({
        type: RAFFLE_TICKET_TOAST_MESSAGE,
        payload: {
          message: "SELECT ORGANIZATION AND PROJECT FIRST",
          hasError: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: SET_RAFFLE_TICKET_PAGE_STATUS,
          payload: "DEFAULT",
        });
        dispatch({
          type: RAFFLE_TICKET_TOAST_MESSAGE,
          payload: { message: "", hasError: false },
        });
      }, 2000);
    }
  } catch (error) {
    dispatch({
      type: RAFFLE_TICKET_TOAST_MESSAGE,
      payload: {
        message: "RAFFLE TICKET ADDED FAILED",
        hasError: true,
      },
    });
    console.log(error);
    const oldRaffleList = store.getState().raffle.raffleItemList;
    dispatch({
      type: SET_RAFFLE_TICKET_LIST,
      payload: oldRaffleList,
    });
    setTimeout(() => {
      dispatch({
        type: SET_RAFFLE_TICKET_PAGE_STATUS,
        payload: "DEFAULT",
      });
      dispatch({
        type: RAFFLE_TICKET_TOAST_MESSAGE,
        payload: { message: "", hasError: false },
      });
    }, 2000);
  }
};
