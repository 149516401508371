import React from "react";
import { TableRow, TableCell, Checkbox, IconButton } from "@material-ui/core";
import { AiFillPlusCircle } from "react-icons/ai";
import "components/students/student.style";
import { withRouter, Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addXpToUserAction } from "actions/userListAction";
import { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { selectedAppProjectIdSelector } from "store/selectors";
import ExclamationIcon from "assets/image/ex-user.svg";
const StudentItem = ({
  student,
  selectedAll,
  ChagneSelectedUser,
  organizationId,
  projectId,
  category,
  subCategory,
}) => {
  const selectedProjectId = useSelector(selectedAppProjectIdSelector);

  const today = new Date();
  const dispatch = useDispatch();
  const [xpPanel, setXpPanel] = useState(false);
  const [xpInfo, setXpInfo] = useState({
    description: "",
    amount: "",
    addByAdmin: true,
    user: student?.user?._id,
  });
  const [selectedItem, setSelectedItem] = useState(false);
  const addXpHandler = () => {
    const xpDetails = {
      ...xpInfo,
      organization: { _id: organizationId },
      project: { _id: projectId },
      category: category,
      subCategory: subCategory,
    };
    dispatch(addXpToUserAction(xpDetails));
    setXpPanel(false);
  };

  const changeHandler = (e) => {
    setXpInfo({ ...xpInfo, [e.target.name]: e.target.value });
  };
  const changeXpHandler = (e) => {
    setXpInfo({ ...xpInfo, amount: parseInt(e.target.value) });
  };
  useEffect(() => {
    setSelectedItem(selectedAll);
  }, [selectedAll]);

  const changeSelectedHandler = (e) => {
    setSelectedItem(!selectedItem);
    ChagneSelectedUser(student?.user?._id);
  };
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <TableRow>
      <TableCell style={{ borderBottom: 0 }}>
        <CustomColorCheckbox
          color="#CED4DA"
          size="small"
          checked={selectedItem}
          onChange={changeSelectedHandler}
          value={student?.user?._id}
        />
      </TableCell>
      <TableCell style={{ borderBottom: 0, position: "relative" }}>
        <img
          src={student?.user?.profilePicture}
          alt="asternuts"
          width="50"
          height="50"
          style={{ borderRadius: 50 }}
        />
        {student?.scores?.change && (
          <div className="exclamation-icon">
            <img src={ExclamationIcon} style={{}} />
          </div>
        )}
      </TableCell>
      <TableCell className="student-name-table">
        <Link
          className="student-name-list"
          to={`/admin/students/details/${student?.user?._id}`}
        >
          {student.fullName}
        </Link>
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {student?.user?.active ? "Active" : "InActive"}
      </TableCell>
      <TableCell style={{ color: "#F70606", borderBottom: 0 }}>
        {isNaN(parseInt(student?.scores?.studentScore))
          ? 0
          : parseInt(student?.scores?.studentScore)}
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {student?.totalNotComplete}
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {student?.totalPassDue}
      </TableCell>
      <TableCell style={{ color: "##797E83", borderBottom: 0 }}>
        {console.log("student nan", student.projectXp)}
        {student.projectXp
          ? isNaN(student.projectXp[[selectedProjectId]])
            ? 0
            : Math.floor(student.projectXp[selectedProjectId] / 3500)
          : 0}
      </TableCell>
      <TableCell style={{ color: "##797E83", borderBottom: 0 }}>
        {console.log("xp project", student.projectXp)}
        {student.projectXp ? student.projectXp[selectedProjectId] : 0}
      </TableCell>
      <TableCell style={{ borderBottom: 0 }} className="addXp">
        <IconButton onClick={() => setXpPanel(!xpPanel)}>
          <AiFillPlusCircle color="#34c38f" size={30} />
        </IconButton>
        {xpPanel ? (
          <>
            <div
              className="xpContainer"
              onClick={() => setXpPanel(false)}
            ></div>{" "}
            <div className="xpPanle">
              <div className="xpPanel_header">Add XP</div>
              <div className="xp_description">
                <input
                  type="text"
                  name="description"
                  placeholder="Description"
                  className="xpDescription"
                  value={xpInfo.description}
                  onChange={changeHandler}
                />
              </div>
              <div className="xp_description">
                <input
                  type="text"
                  name="amount"
                  placeholder="Xp Amount"
                  className="xpDescription"
                  value={xpInfo.amount}
                  onChange={changeXpHandler}
                />
                <button className="addxpbutton" onClick={addXpHandler}>
                  +
                </button>
              </div>
              <div className="xpDate">
                <p className="date_text">Date</p>
                <p className="date_content">
                  {today.getMonth() + 1}/{today.getDate()}/{today.getFullYear()}
                </p>
              </div>
            </div>
          </>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default withRouter(StudentItem);
