
import React from "react";
import {
    ContentHeader,
    ContentHeaderTitle,
  } from "components/styled.components";

const SettingHeader = ({titleName})=> {
    return (
        <>
          <ContentHeader style={{padding:0,height:0}}>
            <ContentHeaderTitle>{titleName}</ContentHeaderTitle>
          </ContentHeader>
       </>
    )
}

export default SettingHeader