import React, { useMemo, memo, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import "./adminTable.style.css";
import { withStyles } from "@material-ui/core/styles";
import AdminItem from "./AdminItem";
import { useSelector } from "react-redux";
import { adminListLoadingSelector } from "store/selectors";

import Spinner from "components/spinner/spinner";

const AdminTable = ({ adminList }) => {
  const isAdminListLoading = useSelector(adminListLoadingSelector);
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#CED4DA",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  useEffect(() => {}, []);
  const renderAdmin = useMemo(
    () =>
      adminList &&
      adminList.map((admin) => {
        const {
          _id,
          adminPhoto,
          firstName,
          lastName,
          adminRole,
          organizationName,
          projectName,
          categoryName,
          subCategoryName,
        } = admin;
        return (
          <AdminItem
            key={_id}
            adminId={_id}
            adminPhoto={adminPhoto}
            adminFirstName={firstName}
            adminLastName={lastName}
            adminRole={adminRole}
            organizationName={organizationName}
            projectName={projectName}
            categoryName={categoryName}
            subCategoryName={subCategoryName}
          />
        );
      }),
    [adminList]
  );
  return (
    <Table className="admin-table-container">
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }}>
            <CustomColorCheckbox
              color="default"
              size="small"
              checked={""}
              onChange={""}
            />
          </TableCell>
          <TableCell style={{ width: 14 }}></TableCell>
          <TableCell className="admin-table-name-title">Name</TableCell>
          <TableCell>Admin Level</TableCell>
          <TableCell>Organization</TableCell>
          <TableCell>Project</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Subcategory</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isAdminListLoading ? (
          <Spinner style={{ width: 50, height: 50, top: 20 }} />
        ) : (
          renderAdmin
        )}
      </TableBody>
    </Table>
  );
};

export default memo(AdminTable);
